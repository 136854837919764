/* tslint:disable */
/* eslint-disable */
/**
 * Champ Admin
 * API for champ admin apps
 *
 * The version of the OpenAPI document: 1.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface ARPlayerRequestModel
 */
export interface ARPlayerRequestModel {
  /**
   *
   * @type {ARPlayerRequestModelPrimaryContactInfo}
   * @memberof ARPlayerRequestModel
   */
  primaryContactInfo?: ARPlayerRequestModelPrimaryContactInfo;
  /**
   *
   * @type {string}
   * @memberof ARPlayerRequestModel
   */
  dob?: string;
  /**
   *
   * @type {string}
   * @memberof ARPlayerRequestModel
   */
  eligibleReinstatementDate?: string;
  /**
   *
   * @type {PlayerStatusEnum}
   * @memberof ARPlayerRequestModel
   */
  playerStatus?: PlayerStatusEnum;
}
/**
 *
 * @export
 * @interface ARPlayerRequestModelPrimaryContactInfo
 */
export interface ARPlayerRequestModelPrimaryContactInfo {
  /**
   *
   * @type {string}
   * @memberof ARPlayerRequestModelPrimaryContactInfo
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof ARPlayerRequestModelPrimaryContactInfo
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof ARPlayerRequestModelPrimaryContactInfo
   */
  email?: string;
}
/**
 *
 * @export
 * @interface ActivityLogAdminModel
 */
export interface ActivityLogAdminModel {
  /**
   *
   * @type {number}
   * @memberof ActivityLogAdminModel
   */
  id: number;
  /**
   * Naming of user who made action
   * @type {string}
   * @memberof ActivityLogAdminModel
   */
  changeBy: string;
  /**
   * Auth0Id of user who made action, not available when made by system
   * @type {string}
   * @memberof ActivityLogAdminModel
   */
  changeByAuth0Id?: string;
  /**
   * nvarchar(max)
   * @type {string}
   * @memberof ActivityLogAdminModel
   */
  action?: string;
  /**
   *
   * @type {string}
   * @memberof ActivityLogAdminModel
   */
  changeNote: string;
  /**
   *
   * @type {string}
   * @memberof ActivityLogAdminModel
   */
  threadName?: string;
  /**
   *
   * @type {string}
   * @memberof ActivityLogAdminModel
   */
  stackTrace?: string;
  /**
   *
   * @type {RequestReceivedByEnum}
   * @memberof ActivityLogAdminModel
   */
  changeReceivedBy?: RequestReceivedByEnum;
  /**
   *
   * @type {string}
   * @memberof ActivityLogAdminModel
   */
  dateModified: string;
  /**
   *
   * @type {NoteSubjectEnum}
   * @memberof ActivityLogAdminModel
   */
  changeSubject?: NoteSubjectEnum;
}
/**
 * Represents Adaptive Player Appliance Component
 * @export
 * @interface AdaptiveAppliancePlayerModel
 */
export interface AdaptiveAppliancePlayerModel {
  /**
   *
   * @type {ApplicationHomeStepPlayerApplianceModel}
   * @memberof AdaptiveAppliancePlayerModel
   */
  applicationHome?: ApplicationHomeStepPlayerApplianceModel;
  /**
   *
   * @type {QualifyingExemptionStepPlayerApplianceModel}
   * @memberof AdaptiveAppliancePlayerModel
   */
  qualifyingExemption?: QualifyingExemptionStepPlayerApplianceModel;
  /**
   *
   * @type {QualifyingLocationStepPlayerApplianceModel}
   * @memberof AdaptiveAppliancePlayerModel
   */
  qualifyingLocation?: QualifyingLocationStepPlayerApplianceModel;
  /**
   *
   * @type {OpenEligibilityPlayerApplianceModel}
   * @memberof AdaptiveAppliancePlayerModel
   */
  eligibility?: OpenEligibilityPlayerApplianceModel;
  /**
   *
   * @type {ReviewStepPlayerApplianceModel}
   * @memberof AdaptiveAppliancePlayerModel
   */
  review?: ReviewStepPlayerApplianceModel;
  /**
   *
   * @type {IdModel}
   * @memberof AdaptiveAppliancePlayerModel
   */
  championship: IdModel;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum AdditionalHeaders {
  XImpersonation = 'X-Impersonation',
}

/**
 *
 * @export
 * @enum {string}
 */
export enum AemWfTypeEnum {
  AUTOCHARGE = 'AUTOCHARGE',
  PAYMENT = 'PAYMENT',
}

/**
 *
 * @export
 * @interface AgaAdminListModel
 */
export interface AgaAdminListModel {
  /**
   *
   * @type {Array<AgaEditModel>}
   * @memberof AgaAdminListModel
   */
  items: Array<AgaEditModel>;
  /**
   *
   * @type {number}
   * @memberof AgaAdminListModel
   */
  limit: number;
  /**
   *
   * @type {number}
   * @memberof AgaAdminListModel
   */
  start: number;
  /**
   *
   * @type {number}
   * @memberof AgaAdminListModel
   */
  total: number;
}
/**
 *
 * @export
 * @interface AgaBaseModel
 */
export interface AgaBaseModel {
  /**
   *
   * @type {string}
   * @memberof AgaBaseModel
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof AgaBaseModel
   */
  address: string;
  /**
   *
   * @type {string}
   * @memberof AgaBaseModel
   */
  address2?: string;
  /**
   *
   * @type {CountryAddModel}
   * @memberof AgaBaseModel
   */
  country: CountryAddModel;
  /**
   *
   * @type {string}
   * @memberof AgaBaseModel
   */
  city: string;
  /**
   *
   * @type {StateAddModel}
   * @memberof AgaBaseModel
   */
  state?: StateAddModel;
  /**
   *
   * @type {string}
   * @memberof AgaBaseModel
   */
  postcode: string;
  /**
   *
   * @type {string}
   * @memberof AgaBaseModel
   */
  website?: string;
  /**
   *
   * @type {string}
   * @memberof AgaBaseModel
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof AgaBaseModel
   */
  phoneNumber: string;
  /**
   *
   * @type {string}
   * @memberof AgaBaseModel
   */
  comment?: string;
  /**
   *
   * @type {string}
   * @memberof AgaBaseModel
   */
  mobileNumber?: string;
}
/**
 *
 * @export
 * @interface AgaEditModel
 */
export interface AgaEditModel {
  /**
   *
   * @type {string}
   * @memberof AgaEditModel
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof AgaEditModel
   */
  address: string;
  /**
   *
   * @type {string}
   * @memberof AgaEditModel
   */
  address2?: string;
  /**
   *
   * @type {CountryAddModel}
   * @memberof AgaEditModel
   */
  country: CountryAddModel;
  /**
   *
   * @type {string}
   * @memberof AgaEditModel
   */
  city: string;
  /**
   *
   * @type {StateAddModel}
   * @memberof AgaEditModel
   */
  state?: StateAddModel;
  /**
   *
   * @type {string}
   * @memberof AgaEditModel
   */
  postcode: string;
  /**
   *
   * @type {string}
   * @memberof AgaEditModel
   */
  website?: string;
  /**
   *
   * @type {string}
   * @memberof AgaEditModel
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof AgaEditModel
   */
  phoneNumber: string;
  /**
   *
   * @type {string}
   * @memberof AgaEditModel
   */
  comment?: string;
  /**
   *
   * @type {string}
   * @memberof AgaEditModel
   */
  mobileNumber?: string;
  /**
   *
   * @type {number}
   * @memberof AgaEditModel
   */
  id: number;
}
/**
 *
 * @export
 * @interface AgaFetchModel
 */
export interface AgaFetchModel {
  /**
   *
   * @type {number}
   * @memberof AgaFetchModel
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof AgaFetchModel
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof AgaFetchModel
   */
  address?: string;
  /**
   *
   * @type {string}
   * @memberof AgaFetchModel
   */
  address2?: string;
  /**
   *
   * @type {CountryAddModel}
   * @memberof AgaFetchModel
   */
  country?: CountryAddModel;
  /**
   *
   * @type {string}
   * @memberof AgaFetchModel
   */
  city?: string;
  /**
   *
   * @type {StateAddModel}
   * @memberof AgaFetchModel
   */
  state?: StateAddModel;
  /**
   *
   * @type {string}
   * @memberof AgaFetchModel
   */
  postcode?: string;
  /**
   *
   * @type {string}
   * @memberof AgaFetchModel
   */
  website?: string;
  /**
   *
   * @type {string}
   * @memberof AgaFetchModel
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof AgaFetchModel
   */
  phoneNumber?: string;
  /**
   *
   * @type {string}
   * @memberof AgaFetchModel
   */
  comment?: string;
  /**
   *
   * @type {string}
   * @memberof AgaFetchModel
   */
  mobileNumber?: string;
}
/**
 *
 * @export
 * @interface AgaUserAdminListModel
 */
export interface AgaUserAdminListModel {
  /**
   *
   * @type {Array<AgaUserModel>}
   * @memberof AgaUserAdminListModel
   */
  items: Array<AgaUserModel>;
  /**
   *
   * @type {number}
   * @memberof AgaUserAdminListModel
   */
  limit: number;
  /**
   *
   * @type {number}
   * @memberof AgaUserAdminListModel
   */
  start: number;
  /**
   *
   * @type {number}
   * @memberof AgaUserAdminListModel
   */
  total: number;
}
/**
 *
 * @export
 * @interface AgaUserModel
 */
export interface AgaUserModel {
  /**
   *
   * @type {string}
   * @memberof AgaUserModel
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof AgaUserModel
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof AgaUserModel
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof AgaUserModel
   */
  agaName: string;
  /**
   *
   * @type {string}
   * @memberof AgaUserModel
   */
  ccEmails?: string;
}
/**
 * Represents Amateur Player Appliance Component
 * @export
 * @interface AmateurAppliancePlayerModel
 */
export interface AmateurAppliancePlayerModel {
  /**
   *
   * @type {ApplicationHomeStepPlayerApplianceModel}
   * @memberof AmateurAppliancePlayerModel
   */
  applicationHome?: ApplicationHomeStepPlayerApplianceModel;
  /**
   *
   * @type {AmateurStatusStepPlayerApplianceModel}
   * @memberof AmateurAppliancePlayerModel
   */
  amateurStatus?: AmateurStatusStepPlayerApplianceModel;
  /**
   *
   * @type {QualifyingExemptionStepPlayerApplianceModel}
   * @memberof AmateurAppliancePlayerModel
   */
  qualifyingExemption?: QualifyingExemptionStepPlayerApplianceModel;
  /**
   *
   * @type {QualifyingLocationStepPlayerApplianceModel}
   * @memberof AmateurAppliancePlayerModel
   */
  qualifyingLocation?: QualifyingLocationStepPlayerApplianceModel;
  /**
   *
   * @type {EligibilityStepPlayerApplianceModel}
   * @memberof AmateurAppliancePlayerModel
   */
  eligibility?: EligibilityStepPlayerApplianceModel;
  /**
   *
   * @type {ReviewStepPlayerApplianceModel}
   * @memberof AmateurAppliancePlayerModel
   */
  review?: ReviewStepPlayerApplianceModel;
  /**
   *
   * @type {IdModel}
   * @memberof AmateurAppliancePlayerModel
   */
  championship: IdModel;
}
/**
 * Represents Amateur Status Step Player Appliance Model
 * @export
 * @interface AmateurStatusStepPlayerApplianceModel
 */
export interface AmateurStatusStepPlayerApplianceModel {
  /**
   *
   * @type {string}
   * @memberof AmateurStatusStepPlayerApplianceModel
   */
  stepTitle?: string;
  /**
   *
   * @type {string}
   * @memberof AmateurStatusStepPlayerApplianceModel
   */
  notificationMessage?: string;
  /**
   *
   * @type {string}
   * @memberof AmateurStatusStepPlayerApplianceModel
   */
  checkboxText?: string;
}
/**
 *
 * @export
 * @interface AppADAReportFetchModel
 */
export interface AppADAReportFetchModel {
  /**
   *
   * @type {number}
   * @memberof AppADAReportFetchModel
   */
  id?: number;
  /**
   *
   * @type {AppWLReportFetchModelChampionship}
   * @memberof AppADAReportFetchModel
   */
  championship?: AppWLReportFetchModelChampionship;
  /**
   *
   * @type {IdModel}
   * @memberof AppADAReportFetchModel
   */
  player?: IdModel;
  /**
   *
   * @type {AppADAReportFetchModelPrimaryContactInfo}
   * @memberof AppADAReportFetchModel
   */
  primaryContactInfo?: AppADAReportFetchModelPrimaryContactInfo;
  /**
   *
   * @type {AppADAReportFetchModelGuardianContactInfo}
   * @memberof AppADAReportFetchModel
   */
  guardianContactInfo?: AppADAReportFetchModelGuardianContactInfo;
  /**
   *
   * @type {string}
   * @memberof AppADAReportFetchModel
   */
  dob?: string;
  /**
   *
   * @type {Array<AppWLReportFetchModelQualifyingSites>}
   * @memberof AppADAReportFetchModel
   */
  qualifyingSites?: Array<AppWLReportFetchModelQualifyingSites>;
}
/**
 *
 * @export
 * @interface AppADAReportFetchModelGuardianContactInfo
 */
export interface AppADAReportFetchModelGuardianContactInfo {
  /**
   * guardian parent agent email
   * @type {string}
   * @memberof AppADAReportFetchModelGuardianContactInfo
   */
  email?: string;
}
/**
 *
 * @export
 * @interface AppADAReportFetchModelPrimaryContactInfo
 */
export interface AppADAReportFetchModelPrimaryContactInfo {
  /**
   *
   * @type {string}
   * @memberof AppADAReportFetchModelPrimaryContactInfo
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof AppADAReportFetchModelPrimaryContactInfo
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof AppADAReportFetchModelPrimaryContactInfo
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof AppADAReportFetchModelPrimaryContactInfo
   */
  phoneNumber?: string;
  /**
   *
   * @type {string}
   * @memberof AppADAReportFetchModelPrimaryContactInfo
   */
  mobileNumber?: string;
  /**
   *
   * @type {string}
   * @memberof AppADAReportFetchModelPrimaryContactInfo
   */
  businessNumber?: string;
}
/**
 *
 * @export
 * @interface AppAntiDopingReportFetchModel
 */
export interface AppAntiDopingReportFetchModel {
  /**
   *
   * @type {number}
   * @memberof AppAntiDopingReportFetchModel
   */
  id?: number;
  /**
   *
   * @type {AppAntiDopingReportFetchModelChampionship}
   * @memberof AppAntiDopingReportFetchModel
   */
  championship?: AppAntiDopingReportFetchModelChampionship;
  /**
   *
   * @type {ApplicationFetchModelPrimaryContactInfo}
   * @memberof AppAntiDopingReportFetchModel
   */
  primaryContactInfo?: ApplicationFetchModelPrimaryContactInfo;
  /**
   *
   * @type {AppAntiDopingReportFetchModelGuardianContactInfo}
   * @memberof AppAntiDopingReportFetchModel
   */
  guardianContactInfo?: AppAntiDopingReportFetchModelGuardianContactInfo;
  /**
   *
   * @type {string}
   * @memberof AppAntiDopingReportFetchModel
   */
  dob?: string;
  /**
   *
   * @type {Array<AppWLReportFetchModelQualifyingSites>}
   * @memberof AppAntiDopingReportFetchModel
   */
  qualifyingSites?: Array<AppWLReportFetchModelQualifyingSites>;
}
/**
 *
 * @export
 * @interface AppAntiDopingReportFetchModelChampionship
 */
export interface AppAntiDopingReportFetchModelChampionship {
  /**
   *
   * @type {string}
   * @memberof AppAntiDopingReportFetchModelChampionship
   */
  title?: string;
  /**
   *
   * @type {number}
   * @memberof AppAntiDopingReportFetchModelChampionship
   */
  year?: number;
  /**
   *
   * @type {ChampionshipSyncFetchModelChampionshipType}
   * @memberof AppAntiDopingReportFetchModelChampionship
   */
  championshipType?: ChampionshipSyncFetchModelChampionshipType;
}
/**
 *
 * @export
 * @interface AppAntiDopingReportFetchModelGuardianContactInfo
 */
export interface AppAntiDopingReportFetchModelGuardianContactInfo {
  /**
   * parent or guardian email
   * @type {string}
   * @memberof AppAntiDopingReportFetchModelGuardianContactInfo
   */
  email?: string;
}
/**
 *
 * @export
 * @interface AppBLReportFetchModel
 */
export interface AppBLReportFetchModel {
  /**
   *
   * @type {number}
   * @memberof AppBLReportFetchModel
   */
  id?: number;
  /**
   *
   * @type {AppBLReportFetchModelChampionship}
   * @memberof AppBLReportFetchModel
   */
  championship?: AppBLReportFetchModelChampionship;
  /**
   *
   * @type {IdModel}
   * @memberof AppBLReportFetchModel
   */
  player?: IdModel;
  /**
   *
   * @type {ApplicationFetchModelPrimaryContactInfo}
   * @memberof AppBLReportFetchModel
   */
  primaryContactInfo?: ApplicationFetchModelPrimaryContactInfo;
  /**
   *
   * @type {AppBLReportFetchModelGuardianContactInfo}
   * @memberof AppBLReportFetchModel
   */
  guardianContactInfo?: AppBLReportFetchModelGuardianContactInfo;
  /**
   *
   * @type {string}
   * @memberof AppBLReportFetchModel
   */
  dob?: string;
  /**
   *
   * @type {BlacklistStatusEnum}
   * @memberof AppBLReportFetchModel
   */
  blacklistStatus?: BlacklistStatusEnum;
  /**
   *
   * @type {string}
   * @memberof AppBLReportFetchModel
   */
  blacklistStatusDate?: string;
  /**
   *
   * @type {string}
   * @memberof AppBLReportFetchModel
   */
  blacklistStatusDateCleared?: string;
}
/**
 *
 * @export
 * @interface AppBLReportFetchModelChampionship
 */
export interface AppBLReportFetchModelChampionship {
  /**
   *
   * @type {string}
   * @memberof AppBLReportFetchModelChampionship
   */
  title?: string;
  /**
   *
   * @type {number}
   * @memberof AppBLReportFetchModelChampionship
   */
  year?: number;
}
/**
 *
 * @export
 * @interface AppBLReportFetchModelGuardianContactInfo
 */
export interface AppBLReportFetchModelGuardianContactInfo {
  /**
   *
   * @type {string}
   * @memberof AppBLReportFetchModelGuardianContactInfo
   */
  email?: string;
}
/**
 *
 * @export
 * @interface AppBadgeModel
 */
export interface AppBadgeModel {
  /**
   *
   * @type {string}
   * @memberof AppBadgeModel
   */
  identifier?: string;
  /**
   *
   * @type {string}
   * @memberof AppBadgeModel
   */
  cropMode?: AppBadgeModelCropModeEnum;
  /**
   *
   * @type {string}
   * @memberof AppBadgeModel
   */
  gravity?: AppBadgeModelGravityEnum;
  /**
   *
   * @type {string}
   * @memberof AppBadgeModel
   */
  cloudName?: string;
  /**
   *
   * @type {string}
   * @memberof AppBadgeModel
   */
  url: string;
}

/**
 * @export
 * @enum {string}
 */
export enum AppBadgeModelCropModeEnum {
  Crop = 'crop',
  Pad = 'pad',
  Fill = 'fill',
  Fit = 'fit',
}
/**
 * @export
 * @enum {string}
 */
export enum AppBadgeModelGravityEnum {
  Center = 'center',
  Face = 'face',
}

/**
 *
 * @export
 * @interface AppBadgeModelAllOf
 */
export interface AppBadgeModelAllOf {
  /**
   *
   * @type {string}
   * @memberof AppBadgeModelAllOf
   */
  url: string;
}
/**
 *
 * @export
 * @interface AppCloseOfEntriesReportFetchModel
 */
export interface AppCloseOfEntriesReportFetchModel {
  /**
   *
   * @type {number}
   * @memberof AppCloseOfEntriesReportFetchModel
   */
  id?: number;
  /**
   *
   * @type {boolean}
   * @memberof AppCloseOfEntriesReportFetchModel
   */
  active?: boolean;
  /**
   *
   * @type {AppNotablePlayersReportFetchModelChampionship}
   * @memberof AppCloseOfEntriesReportFetchModel
   */
  championship?: AppNotablePlayersReportFetchModelChampionship;
  /**
   *
   * @type {boolean}
   * @memberof AppCloseOfEntriesReportFetchModel
   */
  adaRequest?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AppCloseOfEntriesReportFetchModel
   */
  antiDopingViolation?: boolean;
  /**
   *
   * @type {BlacklistStatusEnum}
   * @memberof AppCloseOfEntriesReportFetchModel
   */
  blacklistStatus?: BlacklistStatusEnum;
  /**
   *
   * @type {string}
   * @memberof AppCloseOfEntriesReportFetchModel
   */
  blacklistStatusDate?: string;
  /**
   *
   * @type {string}
   * @memberof AppCloseOfEntriesReportFetchModel
   */
  blacklistStatusDateCleared?: string;
  /**
   *
   * @type {AuditModel}
   * @memberof AppCloseOfEntriesReportFetchModel
   */
  audit?: AuditModel;
  /**
   *
   * @type {string}
   * @memberof AppCloseOfEntriesReportFetchModel
   */
  dob?: string;
  /**
   *
   * @type {string}
   * @memberof AppCloseOfEntriesReportFetchModel
   */
  eligibleReinstatementDate?: string;
  /**
   *
   * @type {EthnicityEnum}
   * @memberof AppCloseOfEntriesReportFetchModel
   */
  ethnicity?: EthnicityEnum;
  /**
   *
   * @type {GenderEnum}
   * @memberof AppCloseOfEntriesReportFetchModel
   */
  gender?: GenderEnum;
  /**
   *
   * @type {ApplicationFetchModelPrimaryContactInfo}
   * @memberof AppCloseOfEntriesReportFetchModel
   */
  primaryContactInfo?: ApplicationFetchModelPrimaryContactInfo;
  /**
   *
   * @type {AppCloseOfEntriesReportFetchModelGuardianContactInfo}
   * @memberof AppCloseOfEntriesReportFetchModel
   */
  guardianContactInfo?: AppCloseOfEntriesReportFetchModelGuardianContactInfo;
  /**
   *
   * @type {number}
   * @memberof AppCloseOfEntriesReportFetchModel
   */
  highSchoolGraduationYear?: number;
  /**
   *
   * @type {boolean}
   * @memberof AppCloseOfEntriesReportFetchModel
   */
  notablePlayer?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AppCloseOfEntriesReportFetchModel
   */
  optInPrivacy?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AppCloseOfEntriesReportFetchModel
   */
  optInWagr?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AppCloseOfEntriesReportFetchModel
   */
  playerMinor?: boolean;
  /**
   *
   * @type {PlayerStatusEnum}
   * @memberof AppCloseOfEntriesReportFetchModel
   */
  playerStatus?: PlayerStatusEnum;
  /**
   *
   * @type {CountryAddModel}
   * @memberof AppCloseOfEntriesReportFetchModel
   */
  preferredCountry?: CountryAddModel;
  /**
   *
   * @type {string}
   * @memberof AppCloseOfEntriesReportFetchModel
   */
  preferredCity?: string;
  /**
   *
   * @type {StateAddModel}
   * @memberof AppCloseOfEntriesReportFetchModel
   */
  preferredState?: StateAddModel;
  /**
   *
   * @type {AppCloseOfEntriesReportFetchModelHandicap}
   * @memberof AppCloseOfEntriesReportFetchModel
   */
  handicap?: AppCloseOfEntriesReportFetchModelHandicap;
  /**
   *
   * @type {ApplicationProgressionEnum}
   * @memberof AppCloseOfEntriesReportFetchModel
   */
  progression?: ApplicationProgressionEnum;
  /**
   *
   * @type {string}
   * @memberof AppCloseOfEntriesReportFetchModel
   */
  wagrRank?: string;
  /**
   *
   * @type {boolean}
   * @memberof AppCloseOfEntriesReportFetchModel
   */
  watchlist?: boolean;
  /**
   *
   * @type {string}
   * @memberof AppCloseOfEntriesReportFetchModel
   */
  watchlistDate?: string;
  /**
   *
   * @type {string}
   * @memberof AppCloseOfEntriesReportFetchModel
   */
  watchlistReason?: string;
  /**
   *
   * @type {IdModel}
   * @memberof AppCloseOfEntriesReportFetchModel
   */
  player?: IdModel;
  /**
   *
   * @type {IdModel}
   * @memberof AppCloseOfEntriesReportFetchModel
   */
  team?: IdModel;
  /**
   *
   * @type {QualifyingStatusEnum}
   * @memberof AppCloseOfEntriesReportFetchModel
   */
  qualifyingStatus?: QualifyingStatusEnum;
  /**
   *
   * @type {IdModel}
   * @memberof AppCloseOfEntriesReportFetchModel
   */
  pair?: IdModel;
  /**
   *
   * @type {number}
   * @memberof AppCloseOfEntriesReportFetchModel
   */
  pairingNumber?: number;
  /**
   *
   * @type {CountryAddModel}
   * @memberof AppCloseOfEntriesReportFetchModel
   */
  countryOfCitizenship?: CountryAddModel;
  /**
   *
   * @type {Array<AppWDReportFetchModelQualifyingSites>}
   * @memberof AppCloseOfEntriesReportFetchModel
   */
  qualifyingSites?: Array<AppWDReportFetchModelQualifyingSites>;
  /**
   *
   * @type {Array<AppNotablePlayersReportFetchModelExemption>}
   * @memberof AppCloseOfEntriesReportFetchModel
   */
  exemption?: Array<AppNotablePlayersReportFetchModelExemption>;
  /**
   *
   * @type {ProfilePlayerModelAllOfEmergencyContact}
   * @memberof AppCloseOfEntriesReportFetchModel
   */
  emergencyContact?: ProfilePlayerModelAllOfEmergencyContact;
  /**
   *
   * @type {ApplicationDisabilityTypeEnum}
   * @memberof AppCloseOfEntriesReportFetchModel
   */
  disabilityType?: ApplicationDisabilityTypeEnum;
  /**
   *
   * @type {string}
   * @memberof AppCloseOfEntriesReportFetchModel
   */
  wR4GDPass?: string;
  /**
   *
   * @type {string}
   * @memberof AppCloseOfEntriesReportFetchModel
   */
  disabilityDesc?: string;
  /**
   *
   * @type {boolean}
   * @memberof AppCloseOfEntriesReportFetchModel
   */
  legalGuardian?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AppCloseOfEntriesReportFetchModel
   */
  playGolfInCart?: boolean;
  /**
   *
   * @type {string}
   * @memberof AppCloseOfEntriesReportFetchModel
   */
  virtusII1Number?: string;
  /**
   *
   * @type {string}
   * @memberof AppCloseOfEntriesReportFetchModel
   */
  adaptiveEquipDesc?: string;
  /**
   *
   * @type {string}
   * @memberof AppCloseOfEntriesReportFetchModel
   */
  compPlayingRecord?: string;
  /**
   *
   * @type {Array<number>}
   * @memberof AppCloseOfEntriesReportFetchModel
   */
  fileIds?: Array<number>;
  /**
   *
   * @type {PaymentDataRequestModel}
   * @memberof AppCloseOfEntriesReportFetchModel
   */
  payment?: PaymentDataRequestModel;
  /**
   *
   * @type {ApplicationBaseAdminModelScoring}
   * @memberof AppCloseOfEntriesReportFetchModel
   */
  scoring?: ApplicationBaseAdminModelScoring;
  /**
   *
   * @type {WithdrawalResponseAdminModel}
   * @memberof AppCloseOfEntriesReportFetchModel
   */
  withdrawal?: WithdrawalResponseAdminModel;
}
/**
 *
 * @export
 * @interface AppCloseOfEntriesReportFetchModelGuardianContactInfo
 */
export interface AppCloseOfEntriesReportFetchModelGuardianContactInfo {
  /**
   *
   * @type {boolean}
   * @memberof AppCloseOfEntriesReportFetchModelGuardianContactInfo
   */
  sameAddress?: boolean;
  /**
   *
   * @type {string}
   * @memberof AppCloseOfEntriesReportFetchModelGuardianContactInfo
   */
  relation?: string;
  /**
   *
   * @type {string}
   * @memberof AppCloseOfEntriesReportFetchModelGuardianContactInfo
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof AppCloseOfEntriesReportFetchModelGuardianContactInfo
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof AppCloseOfEntriesReportFetchModelGuardianContactInfo
   */
  address?: string;
  /**
   *
   * @type {string}
   * @memberof AppCloseOfEntriesReportFetchModelGuardianContactInfo
   */
  address2?: string;
  /**
   *
   * @type {CountryAddModel}
   * @memberof AppCloseOfEntriesReportFetchModelGuardianContactInfo
   */
  country?: CountryAddModel;
  /**
   *
   * @type {string}
   * @memberof AppCloseOfEntriesReportFetchModelGuardianContactInfo
   */
  city?: string;
  /**
   *
   * @type {StateAddModel}
   * @memberof AppCloseOfEntriesReportFetchModelGuardianContactInfo
   */
  state?: StateAddModel;
  /**
   *
   * @type {string}
   * @memberof AppCloseOfEntriesReportFetchModelGuardianContactInfo
   */
  postcode?: string;
  /**
   *
   * @type {string}
   * @memberof AppCloseOfEntriesReportFetchModelGuardianContactInfo
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof AppCloseOfEntriesReportFetchModelGuardianContactInfo
   */
  phoneNumber?: string;
  /**
   *
   * @type {string}
   * @memberof AppCloseOfEntriesReportFetchModelGuardianContactInfo
   */
  mobileNumber?: string;
  /**
   *
   * @type {string}
   * @memberof AppCloseOfEntriesReportFetchModelGuardianContactInfo
   */
  businessNumber?: string;
}
/**
 *
 * @export
 * @interface AppCloseOfEntriesReportFetchModelHandicap
 */
export interface AppCloseOfEntriesReportFetchModelHandicap {
  /**
   *
   * @type {string}
   * @memberof AppCloseOfEntriesReportFetchModelHandicap
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof AppCloseOfEntriesReportFetchModelHandicap
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof AppCloseOfEntriesReportFetchModelHandicap
   */
  number?: string;
  /**
   *
   * @type {string}
   * @memberof AppCloseOfEntriesReportFetchModelHandicap
   */
  governingAssociation?: string;
  /**
   *
   * @type {string}
   * @memberof AppCloseOfEntriesReportFetchModelHandicap
   */
  golfAssociation?: string;
  /**
   *
   * @type {string}
   * @memberof AppCloseOfEntriesReportFetchModelHandicap
   */
  index?: string;
  /**
   *
   * @type {boolean}
   * @memberof AppCloseOfEntriesReportFetchModelHandicap
   */
  betterThanScratch?: boolean;
  /**
   *
   * @type {ApplicationFetchModelHandicapVerifier}
   * @memberof AppCloseOfEntriesReportFetchModelHandicap
   */
  verifier?: ApplicationFetchModelHandicapVerifier;
  /**
   *
   * @type {string}
   * @memberof AppCloseOfEntriesReportFetchModelHandicap
   */
  clubName?: string;
  /**
   *
   * @type {CountryAddModel}
   * @memberof AppCloseOfEntriesReportFetchModelHandicap
   */
  clubCountry?: CountryAddModel;
  /**
   *
   * @type {StateAddModel}
   * @memberof AppCloseOfEntriesReportFetchModelHandicap
   */
  clubState?: StateAddModel;
  /**
   *
   * @type {string}
   * @memberof AppCloseOfEntriesReportFetchModelHandicap
   */
  clubCity?: string;
  /**
   *
   * @type {string}
   * @memberof AppCloseOfEntriesReportFetchModelHandicap
   */
  memberNumber?: string;
}
/**
 *
 * @export
 * @interface AppCloseQSReportFetchModel
 */
export interface AppCloseQSReportFetchModel {
  /**
   *
   * @type {number}
   * @memberof AppCloseQSReportFetchModel
   */
  id?: number;
  /**
   *
   * @type {IdModel}
   * @memberof AppCloseQSReportFetchModel
   */
  team?: IdModel;
  /**
   *
   * @type {number}
   * @memberof AppCloseQSReportFetchModel
   */
  pairingNumber?: number;
  /**
   *
   * @type {ApplicationProgressionEnum}
   * @memberof AppCloseQSReportFetchModel
   */
  progression?: ApplicationProgressionEnum;
  /**
   *
   * @type {ApplicationFetchModelPrimaryContactInfo}
   * @memberof AppCloseQSReportFetchModel
   */
  primaryContactInfo?: ApplicationFetchModelPrimaryContactInfo;
  /**
   *
   * @type {AppCloseQSReportFetchModelHandicap}
   * @memberof AppCloseQSReportFetchModel
   */
  handicap?: AppCloseQSReportFetchModelHandicap;
  /**
   *
   * @type {string}
   * @memberof AppCloseQSReportFetchModel
   */
  dob?: string;
  /**
   *
   * @type {PlayerStatusEnum}
   * @memberof AppCloseQSReportFetchModel
   */
  playerStatus?: PlayerStatusEnum;
  /**
   *
   * @type {CountryAddModel}
   * @memberof AppCloseQSReportFetchModel
   */
  preferredCountry?: CountryAddModel;
  /**
   *
   * @type {string}
   * @memberof AppCloseQSReportFetchModel
   */
  preferredCity?: string;
  /**
   *
   * @type {StateAddModel}
   * @memberof AppCloseQSReportFetchModel
   */
  preferredState?: StateAddModel;
  /**
   *
   * @type {GenderEnum}
   * @memberof AppCloseQSReportFetchModel
   */
  gender?: GenderEnum;
  /**
   *
   * @type {ApplicationFetchModelGuardianContactInfo}
   * @memberof AppCloseQSReportFetchModel
   */
  guardianContactInfo?: ApplicationFetchModelGuardianContactInfo;
}
/**
 *
 * @export
 * @interface AppCloseQSReportFetchModelHandicap
 */
export interface AppCloseQSReportFetchModelHandicap {
  /**
   *
   * @type {string}
   * @memberof AppCloseQSReportFetchModelHandicap
   */
  number?: string;
  /**
   *
   * @type {string}
   * @memberof AppCloseQSReportFetchModelHandicap
   */
  clubName?: string;
  /**
   *
   * @type {CountryAddModel}
   * @memberof AppCloseQSReportFetchModelHandicap
   */
  clubCountry?: CountryAddModel;
  /**
   *
   * @type {string}
   * @memberof AppCloseQSReportFetchModelHandicap
   */
  index?: string;
  /**
   *
   * @type {StateAddModel}
   * @memberof AppCloseQSReportFetchModelHandicap
   */
  clubState?: StateAddModel;
  /**
   *
   * @type {string}
   * @memberof AppCloseQSReportFetchModelHandicap
   */
  clubCity?: string;
}
/**
 *
 * @export
 * @interface AppCustomerServiceReportFetchModel
 */
export interface AppCustomerServiceReportFetchModel {
  /**
   *
   * @type {number}
   * @memberof AppCustomerServiceReportFetchModel
   */
  id?: number;
  /**
   *
   * @type {ExemptionDeleteFetchModelChampionship}
   * @memberof AppCustomerServiceReportFetchModel
   */
  championship?: ExemptionDeleteFetchModelChampionship;
  /**
   *
   * @type {IdModel}
   * @memberof AppCustomerServiceReportFetchModel
   */
  player?: IdModel;
  /**
   *
   * @type {AppCustomerServiceReportFetchModelPrimaryContactInfo}
   * @memberof AppCustomerServiceReportFetchModel
   */
  primaryContactInfo?: AppCustomerServiceReportFetchModelPrimaryContactInfo;
}
/**
 *
 * @export
 * @interface AppCustomerServiceReportFetchModelPrimaryContactInfo
 */
export interface AppCustomerServiceReportFetchModelPrimaryContactInfo {
  /**
   *
   * @type {string}
   * @memberof AppCustomerServiceReportFetchModelPrimaryContactInfo
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof AppCustomerServiceReportFetchModelPrimaryContactInfo
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof AppCustomerServiceReportFetchModelPrimaryContactInfo
   */
  email?: string;
}
/**
 *
 * @export
 * @interface AppDuplicateReportFetchModel
 */
export interface AppDuplicateReportFetchModel {
  /**
   *
   * @type {number}
   * @memberof AppDuplicateReportFetchModel
   */
  id?: number;
  /**
   *
   * @type {AppDuplicateReportFetchModelChampionship}
   * @memberof AppDuplicateReportFetchModel
   */
  championship?: AppDuplicateReportFetchModelChampionship;
  /**
   *
   * @type {IdModel}
   * @memberof AppDuplicateReportFetchModel
   */
  player?: IdModel;
  /**
   *
   * @type {ApplicationFetchModelPrimaryContactInfo}
   * @memberof AppDuplicateReportFetchModel
   */
  primaryContactInfo?: ApplicationFetchModelPrimaryContactInfo;
  /**
   *
   * @type {AppDuplicateReportFetchModelGuardianContactInfo}
   * @memberof AppDuplicateReportFetchModel
   */
  guardianContactInfo?: AppDuplicateReportFetchModelGuardianContactInfo;
  /**
   *
   * @type {string}
   * @memberof AppDuplicateReportFetchModel
   */
  dob?: string;
  /**
   *
   * @type {Array<AppWLReportFetchModelQualifyingSites>}
   * @memberof AppDuplicateReportFetchModel
   */
  qualifyingSites?: Array<AppWLReportFetchModelQualifyingSites>;
}
/**
 *
 * @export
 * @interface AppDuplicateReportFetchModelChampionship
 */
export interface AppDuplicateReportFetchModelChampionship {
  /**
   *
   * @type {number}
   * @memberof AppDuplicateReportFetchModelChampionship
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof AppDuplicateReportFetchModelChampionship
   */
  title?: string;
  /**
   *
   * @type {ChampionshipSyncFetchModelChampionshipType}
   * @memberof AppDuplicateReportFetchModelChampionship
   */
  championshipType?: ChampionshipSyncFetchModelChampionshipType;
}
/**
 *
 * @export
 * @interface AppDuplicateReportFetchModelGuardianContactInfo
 */
export interface AppDuplicateReportFetchModelGuardianContactInfo {
  /**
   * guardian email
   * @type {string}
   * @memberof AppDuplicateReportFetchModelGuardianContactInfo
   */
  email?: string;
}
/**
 *
 * @export
 * @interface AppExemptChosenReportFetchModel
 */
export interface AppExemptChosenReportFetchModel {
  /**
   *
   * @type {number}
   * @memberof AppExemptChosenReportFetchModel
   */
  id?: number;
  /**
   *
   * @type {PlayerStatusEnum}
   * @memberof AppExemptChosenReportFetchModel
   */
  playerStatus?: PlayerStatusEnum;
  /**
   *
   * @type {AppNotablePlayersReportFetchModelChampionship1}
   * @memberof AppExemptChosenReportFetchModel
   */
  championship?: AppNotablePlayersReportFetchModelChampionship1;
  /**
   *
   * @type {IdModel}
   * @memberof AppExemptChosenReportFetchModel
   */
  player?: IdModel;
  /**
   *
   * @type {ApplicationFetchModelPrimaryContactInfo}
   * @memberof AppExemptChosenReportFetchModel
   */
  primaryContactInfo?: ApplicationFetchModelPrimaryContactInfo;
  /**
   *
   * @type {AppExemptChosenReportFetchModelGuardianContactInfo}
   * @memberof AppExemptChosenReportFetchModel
   */
  guardianContactInfo?: AppExemptChosenReportFetchModelGuardianContactInfo;
  /**
   *
   * @type {string}
   * @memberof AppExemptChosenReportFetchModel
   */
  dob?: string;
  /**
   *
   * @type {Array<AppExemptChosenReportFetchModelExemption>}
   * @memberof AppExemptChosenReportFetchModel
   */
  exemption?: Array<AppExemptChosenReportFetchModelExemption>;
}
/**
 *
 * @export
 * @interface AppExemptChosenReportFetchModelExemption
 */
export interface AppExemptChosenReportFetchModelExemption {
  /**
   *
   * @type {number}
   * @memberof AppExemptChosenReportFetchModelExemption
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof AppExemptChosenReportFetchModelExemption
   */
  number?: number;
  /**
   *
   * @type {QualifyingLevelEnum}
   * @memberof AppExemptChosenReportFetchModelExemption
   */
  level?: QualifyingLevelEnum;
}
/**
 *
 * @export
 * @interface AppExemptChosenReportFetchModelGuardianContactInfo
 */
export interface AppExemptChosenReportFetchModelGuardianContactInfo {
  /**
   * parent or agent email
   * @type {string}
   * @memberof AppExemptChosenReportFetchModelGuardianContactInfo
   */
  email?: string;
}
/**
 *
 * @export
 * @interface AppNotSettledPaymentsReportFetchModel
 */
export interface AppNotSettledPaymentsReportFetchModel {
  /**
   *
   * @type {number}
   * @memberof AppNotSettledPaymentsReportFetchModel
   */
  id?: number;
  /**
   *
   * @type {AppCustomerServiceReportFetchModelPrimaryContactInfo}
   * @memberof AppNotSettledPaymentsReportFetchModel
   */
  primaryContactInfo?: AppCustomerServiceReportFetchModelPrimaryContactInfo;
  /**
   *
   * @type {AppCustomerServiceReportFetchModelPrimaryContactInfo}
   * @memberof AppNotSettledPaymentsReportFetchModel
   */
  secondaryContactInfo?: AppCustomerServiceReportFetchModelPrimaryContactInfo;
}
/**
 *
 * @export
 * @interface AppNotablePlayersReportFetchModel
 */
export interface AppNotablePlayersReportFetchModel {
  /**
   *
   * @type {number}
   * @memberof AppNotablePlayersReportFetchModel
   */
  id?: number;
  /**
   *
   * @type {AppNotablePlayersReportFetchModelChampionship}
   * @memberof AppNotablePlayersReportFetchModel
   */
  championship?: AppNotablePlayersReportFetchModelChampionship;
  /**
   *
   * @type {AppNotablePlayersReportFetchModelPrimaryContactInfo}
   * @memberof AppNotablePlayersReportFetchModel
   */
  primaryContactInfo?: AppNotablePlayersReportFetchModelPrimaryContactInfo;
  /**
   *
   * @type {AppNotablePlayersReportFetchModelGuardianContactInfo}
   * @memberof AppNotablePlayersReportFetchModel
   */
  guardianContactInfo?: AppNotablePlayersReportFetchModelGuardianContactInfo;
  /**
   *
   * @type {string}
   * @memberof AppNotablePlayersReportFetchModel
   */
  dob?: string;
  /**
   *
   * @type {string}
   * @memberof AppNotablePlayersReportFetchModel
   */
  wagrRank?: string;
  /**
   *
   * @type {Array<AppWLReportFetchModelQualifyingSites>}
   * @memberof AppNotablePlayersReportFetchModel
   */
  qualifyingSites?: Array<AppWLReportFetchModelQualifyingSites>;
  /**
   *
   * @type {Array<AppNotablePlayersReportFetchModelExemption>}
   * @memberof AppNotablePlayersReportFetchModel
   */
  exemption?: Array<AppNotablePlayersReportFetchModelExemption>;
}
/**
 *
 * @export
 * @interface AppNotablePlayersReportFetchModelChampionship
 */
export interface AppNotablePlayersReportFetchModelChampionship {
  /**
   *
   * @type {number}
   * @memberof AppNotablePlayersReportFetchModelChampionship
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof AppNotablePlayersReportFetchModelChampionship
   */
  title?: string;
}
/**
 *
 * @export
 * @interface AppNotablePlayersReportFetchModelChampionship1
 */
export interface AppNotablePlayersReportFetchModelChampionship1 {
  /**
   *
   * @type {number}
   * @memberof AppNotablePlayersReportFetchModelChampionship1
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof AppNotablePlayersReportFetchModelChampionship1
   */
  title?: string;
  /**
   *
   * @type {number}
   * @memberof AppNotablePlayersReportFetchModelChampionship1
   */
  year?: number;
  /**
   *
   * @type {string}
   * @memberof AppNotablePlayersReportFetchModelChampionship1
   */
  startDate?: string;
}
/**
 *
 * @export
 * @interface AppNotablePlayersReportFetchModelExemption
 */
export interface AppNotablePlayersReportFetchModelExemption {
  /**
   *
   * @type {number}
   * @memberof AppNotablePlayersReportFetchModelExemption
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof AppNotablePlayersReportFetchModelExemption
   */
  title?: string;
  /**
   *
   * @type {number}
   * @memberof AppNotablePlayersReportFetchModelExemption
   */
  number?: number;
  /**
   *
   * @type {QualifyingLevelEnum}
   * @memberof AppNotablePlayersReportFetchModelExemption
   */
  level?: QualifyingLevelEnum;
  /**
   *
   * @type {AppNotablePlayersReportFetchModelChampionship1}
   * @memberof AppNotablePlayersReportFetchModelExemption
   */
  championship?: AppNotablePlayersReportFetchModelChampionship1;
}
/**
 *
 * @export
 * @interface AppNotablePlayersReportFetchModelGuardianContactInfo
 */
export interface AppNotablePlayersReportFetchModelGuardianContactInfo {
  /**
   * email of guardian or agent
   * @type {string}
   * @memberof AppNotablePlayersReportFetchModelGuardianContactInfo
   */
  email?: string;
}
/**
 *
 * @export
 * @interface AppNotablePlayersReportFetchModelPrimaryContactInfo
 */
export interface AppNotablePlayersReportFetchModelPrimaryContactInfo {
  /**
   *
   * @type {string}
   * @memberof AppNotablePlayersReportFetchModelPrimaryContactInfo
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof AppNotablePlayersReportFetchModelPrimaryContactInfo
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof AppNotablePlayersReportFetchModelPrimaryContactInfo
   */
  address?: string;
  /**
   *
   * @type {string}
   * @memberof AppNotablePlayersReportFetchModelPrimaryContactInfo
   */
  address2?: string;
  /**
   *
   * @type {CountryAddModel}
   * @memberof AppNotablePlayersReportFetchModelPrimaryContactInfo
   */
  country?: CountryAddModel;
  /**
   *
   * @type {string}
   * @memberof AppNotablePlayersReportFetchModelPrimaryContactInfo
   */
  city?: string;
  /**
   *
   * @type {StateAddModel}
   * @memberof AppNotablePlayersReportFetchModelPrimaryContactInfo
   */
  state?: StateAddModel;
  /**
   *
   * @type {string}
   * @memberof AppNotablePlayersReportFetchModelPrimaryContactInfo
   */
  postcode?: string;
  /**
   *
   * @type {string}
   * @memberof AppNotablePlayersReportFetchModelPrimaryContactInfo
   */
  email?: string;
}
/**
 *
 * @export
 * @interface AppRefundReportFetchModel
 */
export interface AppRefundReportFetchModel {
  /**
   *
   * @type {number}
   * @memberof AppRefundReportFetchModel
   */
  id?: number;
  /**
   *
   * @type {ApplicationProgressionEnum}
   * @memberof AppRefundReportFetchModel
   */
  progression?: ApplicationProgressionEnum;
  /**
   *
   * @type {WithdrawalResponseAdminModel}
   * @memberof AppRefundReportFetchModel
   */
  withdrawal?: WithdrawalResponseAdminModel;
  /**
   *
   * @type {ExemptionDeleteFetchModelChampionship}
   * @memberof AppRefundReportFetchModel
   */
  championship?: ExemptionDeleteFetchModelChampionship;
  /**
   *
   * @type {PaymentDataRequestModel}
   * @memberof AppRefundReportFetchModel
   */
  payment?: PaymentDataRequestModel;
  /**
   *
   * @type {AppCustomerServiceReportFetchModelPrimaryContactInfo}
   * @memberof AppRefundReportFetchModel
   */
  primaryContactInfo?: AppCustomerServiceReportFetchModelPrimaryContactInfo;
  /**
   *
   * @type {AppRefundReportFetchModelGuardianContactInfo}
   * @memberof AppRefundReportFetchModel
   */
  guardianContactInfo?: AppRefundReportFetchModelGuardianContactInfo;
}
/**
 *
 * @export
 * @interface AppRefundReportFetchModelGuardianContactInfo
 */
export interface AppRefundReportFetchModelGuardianContactInfo {
  /**
   * parent, agent, guardian email
   * @type {string}
   * @memberof AppRefundReportFetchModelGuardianContactInfo
   */
  email?: string;
}
/**
 *
 * @export
 * @interface AppWDReportFetchModel
 */
export interface AppWDReportFetchModel {
  /**
   *
   * @type {number}
   * @memberof AppWDReportFetchModel
   */
  id?: number;
  /**
   *
   * @type {AppWDReportFetchModelChampionship}
   * @memberof AppWDReportFetchModel
   */
  championship: AppWDReportFetchModelChampionship;
  /**
   *
   * @type {AppCustomerServiceReportFetchModelPrimaryContactInfo}
   * @memberof AppWDReportFetchModel
   */
  primaryContactInfo?: AppCustomerServiceReportFetchModelPrimaryContactInfo;
  /**
   *
   * @type {AppBLReportFetchModelGuardianContactInfo}
   * @memberof AppWDReportFetchModel
   */
  guardianContactInfo?: AppBLReportFetchModelGuardianContactInfo;
  /**
   *
   * @type {Array<AppWDReportFetchModelQualifyingSites>}
   * @memberof AppWDReportFetchModel
   */
  qualifyingSites?: Array<AppWDReportFetchModelQualifyingSites>;
  /**
   *
   * @type {Array<AppWDReportFetchModelExemption>}
   * @memberof AppWDReportFetchModel
   */
  exemption?: Array<AppWDReportFetchModelExemption>;
  /**
   *
   * @type {AppWDReportFetchModelWithdrawal}
   * @memberof AppWDReportFetchModel
   */
  withdrawal?: AppWDReportFetchModelWithdrawal;
  /**
   *
   * @type {IdModel}
   * @memberof AppWDReportFetchModel
   */
  team?: IdModel;
}
/**
 *
 * @export
 * @interface AppWDReportFetchModelChampionship
 */
export interface AppWDReportFetchModelChampionship {
  /**
   *
   * @type {number}
   * @memberof AppWDReportFetchModelChampionship
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof AppWDReportFetchModelChampionship
   */
  title?: string;
  /**
   *
   * @type {number}
   * @memberof AppWDReportFetchModelChampionship
   */
  year?: number;
  /**
   *
   * @type {ChampionshipSyncFetchModelChampionshipType}
   * @memberof AppWDReportFetchModelChampionship
   */
  championshipType?: ChampionshipSyncFetchModelChampionshipType;
}
/**
 *
 * @export
 * @interface AppWDReportFetchModelExemption
 */
export interface AppWDReportFetchModelExemption {
  /**
   *
   * @type {string}
   * @memberof AppWDReportFetchModelExemption
   */
  title?: string;
  /**
   *
   * @type {QualifyingLevelEnum}
   * @memberof AppWDReportFetchModelExemption
   */
  level?: QualifyingLevelEnum;
}
/**
 *
 * @export
 * @interface AppWDReportFetchModelQualifyingSite
 */
export interface AppWDReportFetchModelQualifyingSite {
  /**
   *
   * @type {QualifyingLevelEnum}
   * @memberof AppWDReportFetchModelQualifyingSite
   */
  level?: QualifyingLevelEnum;
  /**
   *
   * @type {number}
   * @memberof AppWDReportFetchModelQualifyingSite
   */
  number?: number;
  /**
   *
   * @type {AppWDReportFetchModelQualifyingSitePointOfContact}
   * @memberof AppWDReportFetchModelQualifyingSite
   */
  pointOfContact?: AppWDReportFetchModelQualifyingSitePointOfContact;
  /**
   *
   * @type {Array<ApplicationQualifyingLocationResponseFetchModelQualifyingSiteState>}
   * @memberof AppWDReportFetchModelQualifyingSite
   */
  club?: Array<ApplicationQualifyingLocationResponseFetchModelQualifyingSiteState>;
}
/**
 *
 * @export
 * @interface AppWDReportFetchModelQualifyingSitePointOfContact
 */
export interface AppWDReportFetchModelQualifyingSitePointOfContact {
  /**
   *
   * @type {AppWDReportFetchModelQualifyingSitePointOfContactAga}
   * @memberof AppWDReportFetchModelQualifyingSitePointOfContact
   */
  aga?: AppWDReportFetchModelQualifyingSitePointOfContactAga;
}
/**
 *
 * @export
 * @interface AppWDReportFetchModelQualifyingSitePointOfContactAga
 */
export interface AppWDReportFetchModelQualifyingSitePointOfContactAga {
  /**
   *
   * @type {string}
   * @memberof AppWDReportFetchModelQualifyingSitePointOfContactAga
   */
  email: string;
}
/**
 *
 * @export
 * @interface AppWDReportFetchModelQualifyingSites
 */
export interface AppWDReportFetchModelQualifyingSites {
  /**
   *
   * @type {AppWDReportFetchModelQualifyingSite}
   * @memberof AppWDReportFetchModelQualifyingSites
   */
  qualifyingSite?: AppWDReportFetchModelQualifyingSite;
}
/**
 *
 * @export
 * @interface AppWDReportFetchModelWithdrawal
 */
export interface AppWDReportFetchModelWithdrawal {
  /**
   *
   * @type {string}
   * @memberof AppWDReportFetchModelWithdrawal
   */
  dateCreated?: string;
  /**
   *
   * @type {WithdrawalReasonEnum}
   * @memberof AppWDReportFetchModelWithdrawal
   */
  withdrawalReason?: WithdrawalReasonEnum;
}
/**
 *
 * @export
 * @interface AppWLReportFetchModel
 */
export interface AppWLReportFetchModel {
  /**
   *
   * @type {number}
   * @memberof AppWLReportFetchModel
   */
  id?: number;
  /**
   *
   * @type {AppWLReportFetchModelChampionship}
   * @memberof AppWLReportFetchModel
   */
  championship?: AppWLReportFetchModelChampionship;
  /**
   *
   * @type {IdModel}
   * @memberof AppWLReportFetchModel
   */
  player?: IdModel;
  /**
   *
   * @type {ApplicationFetchModelPrimaryContactInfo}
   * @memberof AppWLReportFetchModel
   */
  primaryContactInfo?: ApplicationFetchModelPrimaryContactInfo;
  /**
   *
   * @type {AppWLReportFetchModelGuardianContactInfo}
   * @memberof AppWLReportFetchModel
   */
  guardianContactInfo?: AppWLReportFetchModelGuardianContactInfo;
  /**
   *
   * @type {string}
   * @memberof AppWLReportFetchModel
   */
  dob?: string;
  /**
   *
   * @type {string}
   * @memberof AppWLReportFetchModel
   */
  blacklistStatusDate?: string;
  /**
   *
   * @type {string}
   * @memberof AppWLReportFetchModel
   */
  blacklistStatusDateCleared?: string;
  /**
   *
   * @type {Array<AppWLReportFetchModelQualifyingSites>}
   * @memberof AppWLReportFetchModel
   */
  qualifyingSites?: Array<AppWLReportFetchModelQualifyingSites>;
  /**
   *
   * @type {string}
   * @memberof AppWLReportFetchModel
   */
  watchlistReason?: string;
  /**
   *
   * @type {string}
   * @memberof AppWLReportFetchModel
   */
  watchlistDate?: string;
}
/**
 *
 * @export
 * @interface AppWLReportFetchModelChampionship
 */
export interface AppWLReportFetchModelChampionship {
  /**
   *
   * @type {string}
   * @memberof AppWLReportFetchModelChampionship
   */
  title?: string;
  /**
   *
   * @type {ChampionshipSyncFetchModelChampionshipType}
   * @memberof AppWLReportFetchModelChampionship
   */
  championshipType?: ChampionshipSyncFetchModelChampionshipType;
  /**
   *
   * @type {number}
   * @memberof AppWLReportFetchModelChampionship
   */
  year?: number;
}
/**
 *
 * @export
 * @interface AppWLReportFetchModelGuardianContactInfo
 */
export interface AppWLReportFetchModelGuardianContactInfo {
  /**
   * parent or agent or guardian email
   * @type {string}
   * @memberof AppWLReportFetchModelGuardianContactInfo
   */
  email?: string;
}
/**
 *
 * @export
 * @interface AppWLReportFetchModelQualifyingSite
 */
export interface AppWLReportFetchModelQualifyingSite {
  /**
   *
   * @type {QualifyingLevelEnum}
   * @memberof AppWLReportFetchModelQualifyingSite
   */
  level?: QualifyingLevelEnum;
  /**
   *
   * @type {Array<ApplicationQualifyingLocationResponseFetchModelQualifyingSiteState>}
   * @memberof AppWLReportFetchModelQualifyingSite
   */
  club?: Array<ApplicationQualifyingLocationResponseFetchModelQualifyingSiteState>;
}
/**
 *
 * @export
 * @interface AppWLReportFetchModelQualifyingSites
 */
export interface AppWLReportFetchModelQualifyingSites {
  /**
   *
   * @type {AppWLReportFetchModelQualifyingSite}
   * @memberof AppWLReportFetchModelQualifyingSites
   */
  qualifyingSite?: AppWLReportFetchModelQualifyingSite;
}
/**
 *
 * @export
 * @interface ApplicationAdminListModel
 */
export interface ApplicationAdminListModel {
  /**
   *
   * @type {Array<ApplicationQualifyingLocationResponseModel>}
   * @memberof ApplicationAdminListModel
   */
  items: Array<ApplicationQualifyingLocationResponseModel>;
  /**
   *
   * @type {number}
   * @memberof ApplicationAdminListModel
   */
  limit: number;
  /**
   *
   * @type {number}
   * @memberof ApplicationAdminListModel
   */
  start: number;
  /**
   *
   * @type {number}
   * @memberof ApplicationAdminListModel
   */
  total: number;
}
/**
 *
 * @export
 * @interface ApplicationAdminRequestModel
 */
export interface ApplicationAdminRequestModel {
  /**
   *
   * @type {IdModel}
   * @memberof ApplicationAdminRequestModel
   */
  championship: IdModel;
  /**
   *
   * @type {ApplicationBasePlayerModelPrimaryContactInfo}
   * @memberof ApplicationAdminRequestModel
   */
  primaryContactInfo: ApplicationBasePlayerModelPrimaryContactInfo;
  /**
   *
   * @type {ApplicationBasePlayerModelGuardianContactInfo}
   * @memberof ApplicationAdminRequestModel
   */
  guardianContactInfo?: ApplicationBasePlayerModelGuardianContactInfo;
  /**
   *
   * @type {ProfilePlayerModelAllOfEmergencyContact}
   * @memberof ApplicationAdminRequestModel
   */
  emergencyContact?: ProfilePlayerModelAllOfEmergencyContact;
  /**
   *
   * @type {GenderEnum}
   * @memberof ApplicationAdminRequestModel
   */
  gender: GenderEnum;
  /**
   *
   * @type {string}
   * @memberof ApplicationAdminRequestModel
   */
  dob: string;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationAdminRequestModel
   */
  playerMinor?: boolean;
  /**
   *
   * @type {PlayerStatusEnum}
   * @memberof ApplicationAdminRequestModel
   */
  playerStatus: PlayerStatusEnum;
  /**
   *
   * @type {CountryAddModel}
   * @memberof ApplicationAdminRequestModel
   */
  countryOfCitizenship?: CountryAddModel;
  /**
   *
   * @type {EthnicityEnum}
   * @memberof ApplicationAdminRequestModel
   */
  ethnicity?: EthnicityEnum;
  /**
   *
   * @type {number}
   * @memberof ApplicationAdminRequestModel
   */
  highSchoolGraduationYear?: number;
  /**
   *
   * @type {CountryAddModel}
   * @memberof ApplicationAdminRequestModel
   */
  preferredCountry?: CountryAddModel;
  /**
   *
   * @type {string}
   * @memberof ApplicationAdminRequestModel
   */
  preferredCity?: string;
  /**
   *
   * @type {StateAddModel}
   * @memberof ApplicationAdminRequestModel
   */
  preferredState?: StateAddModel;
  /**
   *
   * @type {ApplicationBasePlayerModelHandicap}
   * @memberof ApplicationAdminRequestModel
   */
  handicap?: ApplicationBasePlayerModelHandicap;
  /**
   *
   * @type {Array<IdModel>}
   * @memberof ApplicationAdminRequestModel
   */
  exemption?: Array<IdModel>;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationAdminRequestModel
   */
  optInPrivacy?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationAdminRequestModel
   */
  unknownHandicapID?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationAdminRequestModel
   */
  optInWagr?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationAdminRequestModel
   */
  antiDopingViolation?: boolean;
  /**
   *
   * @type {ApplicationDisabilityTypeEnum}
   * @memberof ApplicationAdminRequestModel
   */
  disabilityType?: ApplicationDisabilityTypeEnum;
  /**
   *
   * @type {string}
   * @memberof ApplicationAdminRequestModel
   */
  wR4GDPass?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationAdminRequestModel
   */
  disabilityDesc?: string;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationAdminRequestModel
   */
  legalGuardian?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationAdminRequestModel
   */
  playGolfInCart?: boolean;
  /**
   *
   * @type {string}
   * @memberof ApplicationAdminRequestModel
   */
  virtusII1Number?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationAdminRequestModel
   */
  adaptiveEquipDesc?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationAdminRequestModel
   */
  compPlayingRecord?: string;
  /**
   *
   * @type {Array<number>}
   * @memberof ApplicationAdminRequestModel
   */
  fileIds?: Array<number>;
  /**
   *
   * @type {string}
   * @memberof ApplicationAdminRequestModel
   */
  entryDate?: string;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationAdminRequestModel
   */
  surveyEmailSent?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationAdminRequestModel
   */
  declinedPaymentEmailSent?: boolean;
  /**
   *
   * @type {Array<ApplicationBaseAdminModelAllOfQualifyingSites>}
   * @memberof ApplicationAdminRequestModel
   */
  qualifyingSites?: Array<ApplicationBaseAdminModelAllOfQualifyingSites>;
  /**
   *
   * @type {Array<ApplicationPlayerModelAllOfWaitList>}
   * @memberof ApplicationAdminRequestModel
   */
  waitList?: Array<ApplicationPlayerModelAllOfWaitList>;
  /**
   *
   * @type {string}
   * @memberof ApplicationAdminRequestModel
   */
  wagrRank?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationAdminRequestModel
   */
  rank?: string;
  /**
   *
   * @type {QualifyingStatusEnum}
   * @memberof ApplicationAdminRequestModel
   */
  qualifyingStatus?: QualifyingStatusEnum;
  /**
   *
   * @type {string}
   * @memberof ApplicationAdminRequestModel
   */
  eligibleReinstatementDate?: string;
  /**
   *
   * @type {number}
   * @memberof ApplicationAdminRequestModel
   */
  id: number;
  /**
   *
   * @type {PaymentDataRequestModel}
   * @memberof ApplicationAdminRequestModel
   */
  payment?: PaymentDataRequestModel;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationAdminRequestModel
   */
  adaRequest?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationAdminRequestModel
   */
  notablePlayer?: boolean;
  /**
   *
   * @type {Array<ApplicationBaseAdminModelAllOfQueueReasons>}
   * @memberof ApplicationAdminRequestModel
   */
  queueReasons?: Array<ApplicationBaseAdminModelAllOfQueueReasons>;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationAdminRequestModel
   */
  markAsReviewed?: boolean;
  /**
   *
   * @type {ApplicationBaseAdminModelScoring}
   * @memberof ApplicationAdminRequestModel
   */
  scoring?: ApplicationBaseAdminModelScoring;
  /**
   *
   * @type {BlacklistStatusEnum}
   * @memberof ApplicationAdminRequestModel
   */
  blacklistStatus?: BlacklistStatusEnum;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationAdminRequestModel
   */
  blacklistSendEmail?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationAdminRequestModel
   */
  watchlist?: boolean;
  /**
   *
   * @type {string}
   * @memberof ApplicationAdminRequestModel
   */
  watchlistReason?: string;
  /**
   *
   * @type {ContactInfoModel}
   * @memberof ApplicationAdminRequestModel
   */
  secondaryContactInfo?: ContactInfoModel;
  /**
   *
   * @type {IdModel}
   * @memberof ApplicationAdminRequestModel
   */
  submittedByPlayer?: IdModel;
  /**
   *
   * @type {AuditNewAdminModel}
   * @memberof ApplicationAdminRequestModel
   */
  audit: AuditNewAdminModel;
  /**
   * FE flag, not persisted
   * @type {boolean}
   * @memberof ApplicationAdminRequestModel
   */
  sendMail?: boolean;
}
/**
 *
 * @export
 * @interface ApplicationAdminRequestModelAllOf
 */
export interface ApplicationAdminRequestModelAllOf {
  /**
   *
   * @type {AuditNewAdminModel}
   * @memberof ApplicationAdminRequestModelAllOf
   */
  audit: AuditNewAdminModel;
  /**
   * FE flag, not persisted
   * @type {boolean}
   * @memberof ApplicationAdminRequestModelAllOf
   */
  sendMail?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationAdminRequestModelAllOf
   */
  playerMinor?: boolean;
}
/**
 *
 * @export
 * @interface ApplicationAdminResponseModel
 */
export interface ApplicationAdminResponseModel {
  /**
   *
   * @type {IdModel}
   * @memberof ApplicationAdminResponseModel
   */
  championship: IdModel;
  /**
   *
   * @type {ApplicationBasePlayerModelPrimaryContactInfo}
   * @memberof ApplicationAdminResponseModel
   */
  primaryContactInfo: ApplicationBasePlayerModelPrimaryContactInfo;
  /**
   *
   * @type {ApplicationBasePlayerModelGuardianContactInfo}
   * @memberof ApplicationAdminResponseModel
   */
  guardianContactInfo?: ApplicationBasePlayerModelGuardianContactInfo;
  /**
   *
   * @type {ProfilePlayerModelAllOfEmergencyContact}
   * @memberof ApplicationAdminResponseModel
   */
  emergencyContact?: ProfilePlayerModelAllOfEmergencyContact;
  /**
   *
   * @type {GenderEnum}
   * @memberof ApplicationAdminResponseModel
   */
  gender: GenderEnum;
  /**
   *
   * @type {string}
   * @memberof ApplicationAdminResponseModel
   */
  dob: string;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationAdminResponseModel
   */
  playerMinor?: boolean;
  /**
   *
   * @type {PlayerStatusEnum}
   * @memberof ApplicationAdminResponseModel
   */
  playerStatus: PlayerStatusEnum;
  /**
   *
   * @type {CountryAddModel}
   * @memberof ApplicationAdminResponseModel
   */
  countryOfCitizenship?: CountryAddModel;
  /**
   *
   * @type {EthnicityEnum}
   * @memberof ApplicationAdminResponseModel
   */
  ethnicity?: EthnicityEnum;
  /**
   *
   * @type {number}
   * @memberof ApplicationAdminResponseModel
   */
  highSchoolGraduationYear?: number;
  /**
   *
   * @type {CountryAddModel}
   * @memberof ApplicationAdminResponseModel
   */
  preferredCountry?: CountryAddModel;
  /**
   *
   * @type {string}
   * @memberof ApplicationAdminResponseModel
   */
  preferredCity?: string;
  /**
   *
   * @type {StateAddModel}
   * @memberof ApplicationAdminResponseModel
   */
  preferredState?: StateAddModel;
  /**
   *
   * @type {ApplicationBasePlayerModelHandicap}
   * @memberof ApplicationAdminResponseModel
   */
  handicap?: ApplicationBasePlayerModelHandicap;
  /**
   *
   * @type {Array<IdModel>}
   * @memberof ApplicationAdminResponseModel
   */
  exemption?: Array<IdModel>;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationAdminResponseModel
   */
  optInPrivacy?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationAdminResponseModel
   */
  unknownHandicapID?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationAdminResponseModel
   */
  optInWagr?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationAdminResponseModel
   */
  antiDopingViolation?: boolean;
  /**
   *
   * @type {ApplicationDisabilityTypeEnum}
   * @memberof ApplicationAdminResponseModel
   */
  disabilityType?: ApplicationDisabilityTypeEnum;
  /**
   *
   * @type {string}
   * @memberof ApplicationAdminResponseModel
   */
  wR4GDPass?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationAdminResponseModel
   */
  disabilityDesc?: string;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationAdminResponseModel
   */
  legalGuardian?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationAdminResponseModel
   */
  playGolfInCart?: boolean;
  /**
   *
   * @type {string}
   * @memberof ApplicationAdminResponseModel
   */
  virtusII1Number?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationAdminResponseModel
   */
  adaptiveEquipDesc?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationAdminResponseModel
   */
  compPlayingRecord?: string;
  /**
   *
   * @type {Array<number>}
   * @memberof ApplicationAdminResponseModel
   */
  fileIds?: Array<number>;
  /**
   *
   * @type {string}
   * @memberof ApplicationAdminResponseModel
   */
  entryDate?: string;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationAdminResponseModel
   */
  surveyEmailSent?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationAdminResponseModel
   */
  declinedPaymentEmailSent?: boolean;
  /**
   *
   * @type {Array<ApplicationBaseAdminModelAllOfQualifyingSites>}
   * @memberof ApplicationAdminResponseModel
   */
  qualifyingSites?: Array<ApplicationBaseAdminModelAllOfQualifyingSites>;
  /**
   *
   * @type {Array<ApplicationPlayerModelAllOfWaitList>}
   * @memberof ApplicationAdminResponseModel
   */
  waitList?: Array<ApplicationPlayerModelAllOfWaitList>;
  /**
   *
   * @type {string}
   * @memberof ApplicationAdminResponseModel
   */
  wagrRank?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationAdminResponseModel
   */
  rank?: string;
  /**
   *
   * @type {QualifyingStatusEnum}
   * @memberof ApplicationAdminResponseModel
   */
  qualifyingStatus?: QualifyingStatusEnum;
  /**
   *
   * @type {string}
   * @memberof ApplicationAdminResponseModel
   */
  eligibleReinstatementDate?: string;
  /**
   *
   * @type {number}
   * @memberof ApplicationAdminResponseModel
   */
  id: number;
  /**
   *
   * @type {PaymentDataRequestModel}
   * @memberof ApplicationAdminResponseModel
   */
  payment?: PaymentDataRequestModel;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationAdminResponseModel
   */
  adaRequest?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationAdminResponseModel
   */
  notablePlayer?: boolean;
  /**
   *
   * @type {Array<ApplicationBaseAdminModelAllOfQueueReasons>}
   * @memberof ApplicationAdminResponseModel
   */
  queueReasons?: Array<ApplicationBaseAdminModelAllOfQueueReasons>;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationAdminResponseModel
   */
  markAsReviewed?: boolean;
  /**
   *
   * @type {ApplicationBaseAdminModelScoring}
   * @memberof ApplicationAdminResponseModel
   */
  scoring?: ApplicationBaseAdminModelScoring;
  /**
   *
   * @type {BlacklistStatusEnum}
   * @memberof ApplicationAdminResponseModel
   */
  blacklistStatus?: BlacklistStatusEnum;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationAdminResponseModel
   */
  blacklistSendEmail?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationAdminResponseModel
   */
  watchlist?: boolean;
  /**
   *
   * @type {string}
   * @memberof ApplicationAdminResponseModel
   */
  watchlistReason?: string;
  /**
   *
   * @type {ContactInfoModel}
   * @memberof ApplicationAdminResponseModel
   */
  secondaryContactInfo?: ContactInfoModel;
  /**
   *
   * @type {IdModel}
   * @memberof ApplicationAdminResponseModel
   */
  submittedByPlayer?: IdModel;
  /**
   *
   * @type {ApplicationAdminResponseModelAllOfTeam}
   * @memberof ApplicationAdminResponseModel
   */
  team?: ApplicationAdminResponseModelAllOfTeam;
  /**
   *
   * @type {ApplicationAdminResponseModelAllOfPair}
   * @memberof ApplicationAdminResponseModel
   */
  pair?: ApplicationAdminResponseModelAllOfPair;
  /**
   *
   * @type {DeclinedPaymentModel}
   * @memberof ApplicationAdminResponseModel
   */
  declinedPayment?: DeclinedPaymentModel;
  /**
   *
   * @type {number}
   * @memberof ApplicationAdminResponseModel
   */
  pairingNumber?: number;
  /**
   *
   * @type {WithdrawalResponseAdminModel}
   * @memberof ApplicationAdminResponseModel
   */
  withdrawal?: WithdrawalResponseAdminModel;
  /**
   *
   * @type {ApplicationProgressionEnum}
   * @memberof ApplicationAdminResponseModel
   */
  progression: ApplicationProgressionEnum;
  /**
   *
   * @type {string}
   * @memberof ApplicationAdminResponseModel
   */
  reviewDate?: string;
  /**
   * Auth0 id of user who last made transition to reviewed state (enabled reviewDate field)
   * @type {string}
   * @memberof ApplicationAdminResponseModel
   */
  reviewedBy?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationAdminResponseModel
   */
  blacklistStatusDate?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationAdminResponseModel
   */
  blacklistStatusDateCleared?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationAdminResponseModel
   */
  watchlistDate?: string;
  /**
   *
   * @type {IdModel}
   * @memberof ApplicationAdminResponseModel
   */
  player: IdModel;
  /**
   *
   * @type {AuditModel}
   * @memberof ApplicationAdminResponseModel
   */
  audit: AuditModel;
  /**
   *
   * @type {NotificationModel}
   * @memberof ApplicationAdminResponseModel
   */
  notifications?: NotificationModel;
  /**
   *
   * @type {number}
   * @memberof ApplicationAdminResponseModel
   */
  transfersQuantity?: number;
  /**
   *
   * @type {number}
   * @memberof ApplicationAdminResponseModel
   */
  adminTransfersQuantity?: number;
}
/**
 *
 * @export
 * @interface ApplicationAdminResponseModelAllOf
 */
export interface ApplicationAdminResponseModelAllOf {
  /**
   *
   * @type {ApplicationAdminResponseModelAllOfTeam}
   * @memberof ApplicationAdminResponseModelAllOf
   */
  team?: ApplicationAdminResponseModelAllOfTeam;
  /**
   *
   * @type {ApplicationAdminResponseModelAllOfPair}
   * @memberof ApplicationAdminResponseModelAllOf
   */
  pair?: ApplicationAdminResponseModelAllOfPair;
  /**
   *
   * @type {DeclinedPaymentModel}
   * @memberof ApplicationAdminResponseModelAllOf
   */
  declinedPayment?: DeclinedPaymentModel;
  /**
   *
   * @type {number}
   * @memberof ApplicationAdminResponseModelAllOf
   */
  pairingNumber?: number;
  /**
   *
   * @type {WithdrawalResponseAdminModel}
   * @memberof ApplicationAdminResponseModelAllOf
   */
  withdrawal?: WithdrawalResponseAdminModel;
  /**
   *
   * @type {ApplicationProgressionEnum}
   * @memberof ApplicationAdminResponseModelAllOf
   */
  progression: ApplicationProgressionEnum;
  /**
   *
   * @type {string}
   * @memberof ApplicationAdminResponseModelAllOf
   */
  reviewDate?: string;
  /**
   * Auth0 id of user who last made transition to reviewed state (enabled reviewDate field)
   * @type {string}
   * @memberof ApplicationAdminResponseModelAllOf
   */
  reviewedBy?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationAdminResponseModelAllOf
   */
  blacklistStatusDate?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationAdminResponseModelAllOf
   */
  blacklistStatusDateCleared?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationAdminResponseModelAllOf
   */
  watchlistDate?: string;
  /**
   *
   * @type {IdModel}
   * @memberof ApplicationAdminResponseModelAllOf
   */
  player: IdModel;
  /**
   *
   * @type {AuditModel}
   * @memberof ApplicationAdminResponseModelAllOf
   */
  audit: AuditModel;
  /**
   *
   * @type {NotificationModel}
   * @memberof ApplicationAdminResponseModelAllOf
   */
  notifications?: NotificationModel;
  /**
   *
   * @type {number}
   * @memberof ApplicationAdminResponseModelAllOf
   */
  transfersQuantity?: number;
  /**
   *
   * @type {number}
   * @memberof ApplicationAdminResponseModelAllOf
   */
  adminTransfersQuantity?: number;
}
/**
 *
 * @export
 * @interface ApplicationAdminResponseModelAllOfPair
 */
export interface ApplicationAdminResponseModelAllOfPair {
  /**
   *
   * @type {number}
   * @memberof ApplicationAdminResponseModelAllOfPair
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof ApplicationAdminResponseModelAllOfPair
   */
  position: number;
}
/**
 *
 * @export
 * @interface ApplicationAdminResponseModelAllOfTeam
 */
export interface ApplicationAdminResponseModelAllOfTeam {
  /**
   *
   * @type {Array<ApplicationResponseModel>}
   * @memberof ApplicationAdminResponseModelAllOfTeam
   */
  applications?: Array<ApplicationResponseModel>;
  /**
   *
   * @type {number}
   * @memberof ApplicationAdminResponseModelAllOfTeam
   */
  id: number;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationAdminResponseModelAllOfTeam
   */
  complete: boolean;
  /**
   *
   * @type {string}
   * @memberof ApplicationAdminResponseModelAllOfTeam
   */
  substitutionDate?: string;
  /**
   *
   * @type {ApplicationAdminResponseModelAllOfTeamAudit}
   * @memberof ApplicationAdminResponseModelAllOfTeam
   */
  audit?: ApplicationAdminResponseModelAllOfTeamAudit;
}
/**
 *
 * @export
 * @interface ApplicationAdminResponseModelAllOfTeamAudit
 */
export interface ApplicationAdminResponseModelAllOfTeamAudit {
  /**
   *
   * @type {string}
   * @memberof ApplicationAdminResponseModelAllOfTeamAudit
   */
  dateCreated?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationAdminResponseModelAllOfTeamAudit
   */
  dateModified?: string;
}
/**
 *
 * @export
 * @interface ApplicationAdminSummaryModel
 */
export interface ApplicationAdminSummaryModel {
  /**
   *
   * @type {Array<ApplicationAdminSummaryModelItems>}
   * @memberof ApplicationAdminSummaryModel
   */
  items?: Array<ApplicationAdminSummaryModelItems>;
}
/**
 *
 * @export
 * @interface ApplicationAdminSummaryModelItems
 */
export interface ApplicationAdminSummaryModelItems {
  /**
   *
   * @type {ApplicationFiltersEnum}
   * @memberof ApplicationAdminSummaryModelItems
   */
  filter?: ApplicationFiltersEnum;
  /**
   *
   * @type {number}
   * @memberof ApplicationAdminSummaryModelItems
   */
  totalCount?: number;
}
/**
 *
 * @export
 * @interface ApplicationAnonResponseModel
 */
export interface ApplicationAnonResponseModel {
  /**
   *
   * @type {number}
   * @memberof ApplicationAnonResponseModel
   */
  id: number;
  /**
   *
   * @type {ApplicationProgressionEnum}
   * @memberof ApplicationAnonResponseModel
   */
  progression?: ApplicationProgressionEnum;
  /**
   *
   * @type {ApplicationAnonResponseModelPrimaryContactInfo}
   * @memberof ApplicationAnonResponseModel
   */
  primaryContactInfo: ApplicationAnonResponseModelPrimaryContactInfo;
  /**
   *
   * @type {Array<ApplicationAnonResponseModelQualifyingSites>}
   * @memberof ApplicationAnonResponseModel
   */
  qualifyingSites?: Array<ApplicationAnonResponseModelQualifyingSites>;
  /**
   *
   * @type {Array<ApplicationPlayerModelAllOfWaitList>}
   * @memberof ApplicationAnonResponseModel
   */
  waitList?: Array<ApplicationPlayerModelAllOfWaitList>;
  /**
   *
   * @type {Array<IdModel>}
   * @memberof ApplicationAnonResponseModel
   */
  exemption?: Array<IdModel>;
}
/**
 *
 * @export
 * @interface ApplicationAnonResponseModelPrimaryContactInfo
 */
export interface ApplicationAnonResponseModelPrimaryContactInfo {
  /**
   *
   * @type {string}
   * @memberof ApplicationAnonResponseModelPrimaryContactInfo
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationAnonResponseModelPrimaryContactInfo
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationAnonResponseModelPrimaryContactInfo
   */
  email?: string;
}
/**
 *
 * @export
 * @interface ApplicationAnonResponseModelQualifyingSites
 */
export interface ApplicationAnonResponseModelQualifyingSites {
  /**
   *
   * @type {IdModel}
   * @memberof ApplicationAnonResponseModelQualifyingSites
   */
  qualifyingSite?: IdModel;
}
/**
 *
 * @export
 * @interface ApplicationAppliedListModel
 */
export interface ApplicationAppliedListModel {
  /**
   *
   * @type {boolean}
   * @memberof ApplicationAppliedListModel
   */
  isAutoWd?: boolean;
  /**
   *
   * @type {Array<ApplicationAppliedResponseModel>}
   * @memberof ApplicationAppliedListModel
   */
  items: Array<ApplicationAppliedResponseModel>;
}
/**
 *
 * @export
 * @interface ApplicationAppliedResponseFetchModel
 */
export interface ApplicationAppliedResponseFetchModel {
  /**
   *
   * @type {ExemptionDeleteFetchModelChampionship}
   * @memberof ApplicationAppliedResponseFetchModel
   */
  championship?: ExemptionDeleteFetchModelChampionship;
  /**
   *
   * @type {number}
   * @memberof ApplicationAppliedResponseFetchModel
   */
  applicationId?: number;
  /**
   *
   * @type {string}
   * @memberof ApplicationAppliedResponseFetchModel
   */
  championshipName?: string;
  /**
   *
   * @type {Array<QualifyingLocationResponseModel>}
   * @memberof ApplicationAppliedResponseFetchModel
   */
  qualifyingLocations?: Array<QualifyingLocationResponseModel>;
  /**
   *
   * @type {string}
   * @memberof ApplicationAppliedResponseFetchModel
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationAppliedResponseFetchModel
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationAppliedResponseFetchModel
   */
  phone?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationAppliedResponseFetchModel
   */
  email?: string;
  /**
   *
   * @type {Array<ApplicationAppliedResponseFetchModelQualifyingSites>}
   * @memberof ApplicationAppliedResponseFetchModel
   */
  qualifyingSites?: Array<ApplicationAppliedResponseFetchModelQualifyingSites>;
  /**
   *
   * @type {Array<ExemptionAppliedAppsModel>}
   * @memberof ApplicationAppliedResponseFetchModel
   */
  exemption?: Array<ExemptionAppliedAppsModel>;
  /**
   *
   * @type {Array<ApplicationFetchModelQueueReasons>}
   * @memberof ApplicationAppliedResponseFetchModel
   */
  queueReasons?: Array<ApplicationFetchModelQueueReasons>;
  /**
   *
   * @type {PaymentDataRequestModel}
   * @memberof ApplicationAppliedResponseFetchModel
   */
  payment?: PaymentDataRequestModel;
  /**
   *
   * @type {Array<ApplicationAppliedResponseFetchModelWaitList>}
   * @memberof ApplicationAppliedResponseFetchModel
   */
  waitList?: Array<ApplicationAppliedResponseFetchModelWaitList>;
}
/**
 *
 * @export
 * @interface ApplicationAppliedResponseFetchModelQualifyingSite
 */
export interface ApplicationAppliedResponseFetchModelQualifyingSite {
  /**
   *
   * @type {Array<ApplicationQualifyingLocationResponseFetchModelQualifyingSiteState>}
   * @memberof ApplicationAppliedResponseFetchModelQualifyingSite
   */
  club?: Array<ApplicationQualifyingLocationResponseFetchModelQualifyingSiteState>;
}
/**
 *
 * @export
 * @interface ApplicationAppliedResponseFetchModelQualifyingSites
 */
export interface ApplicationAppliedResponseFetchModelQualifyingSites {
  /**
   *
   * @type {ApplicationAppliedResponseFetchModelQualifyingSite}
   * @memberof ApplicationAppliedResponseFetchModelQualifyingSites
   */
  qualifyingSite?: ApplicationAppliedResponseFetchModelQualifyingSite;
}
/**
 *
 * @export
 * @interface ApplicationAppliedResponseFetchModelWaitList
 */
export interface ApplicationAppliedResponseFetchModelWaitList {
  /**
   *
   * @type {QualifyingSitePlayerModel}
   * @memberof ApplicationAppliedResponseFetchModelWaitList
   */
  qualifyingSite?: QualifyingSitePlayerModel;
  /**
   *
   * @type {WaitListAlternateTypeEnum}
   * @memberof ApplicationAppliedResponseFetchModelWaitList
   */
  alternateType?: WaitListAlternateTypeEnum;
}
/**
 *
 * @export
 * @interface ApplicationAppliedResponseModel
 */
export interface ApplicationAppliedResponseModel {
  /**
   *
   * @type {number}
   * @memberof ApplicationAppliedResponseModel
   */
  applicationId: number;
  /**
   *
   * @type {number}
   * @memberof ApplicationAppliedResponseModel
   */
  championshipId: number;
  /**
   *
   * @type {string}
   * @memberof ApplicationAppliedResponseModel
   */
  championshipName: string;
  /**
   *
   * @type {Array<QualifyingLocationResponseModel>}
   * @memberof ApplicationAppliedResponseModel
   */
  qualifyingLocations?: Array<QualifyingLocationResponseModel>;
  /**
   *
   * @type {string}
   * @memberof ApplicationAppliedResponseModel
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationAppliedResponseModel
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationAppliedResponseModel
   */
  phone?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationAppliedResponseModel
   */
  email?: string;
  /**
   *
   * @type {Array<ExemptionAppliedAppsModel>}
   * @memberof ApplicationAppliedResponseModel
   */
  exemptions?: Array<ExemptionAppliedAppsModel>;
  /**
   *
   * @type {Array<ApplicationAppliedResponseModelWaitList>}
   * @memberof ApplicationAppliedResponseModel
   */
  waitList?: Array<ApplicationAppliedResponseModelWaitList>;
}
/**
 *
 * @export
 * @interface ApplicationAppliedResponseModelWaitList
 */
export interface ApplicationAppliedResponseModelWaitList {
  /**
   *
   * @type {QualifyingSitePlayerModel}
   * @memberof ApplicationAppliedResponseModelWaitList
   */
  qualifyingSite: QualifyingSitePlayerModel;
  /**
   *
   * @type {WaitListAlternateTypeEnum}
   * @memberof ApplicationAppliedResponseModelWaitList
   */
  alternateType?: WaitListAlternateTypeEnum;
}
/**
 *
 * @export
 * @interface ApplicationBaseAdminModel
 */
export interface ApplicationBaseAdminModel {
  /**
   *
   * @type {IdModel}
   * @memberof ApplicationBaseAdminModel
   */
  championship: IdModel;
  /**
   *
   * @type {ApplicationBasePlayerModelPrimaryContactInfo}
   * @memberof ApplicationBaseAdminModel
   */
  primaryContactInfo: ApplicationBasePlayerModelPrimaryContactInfo;
  /**
   *
   * @type {ApplicationBasePlayerModelGuardianContactInfo}
   * @memberof ApplicationBaseAdminModel
   */
  guardianContactInfo?: ApplicationBasePlayerModelGuardianContactInfo;
  /**
   *
   * @type {ProfilePlayerModelAllOfEmergencyContact}
   * @memberof ApplicationBaseAdminModel
   */
  emergencyContact?: ProfilePlayerModelAllOfEmergencyContact;
  /**
   *
   * @type {GenderEnum}
   * @memberof ApplicationBaseAdminModel
   */
  gender: GenderEnum;
  /**
   *
   * @type {string}
   * @memberof ApplicationBaseAdminModel
   */
  dob: string;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationBaseAdminModel
   */
  playerMinor?: boolean;
  /**
   *
   * @type {PlayerStatusEnum}
   * @memberof ApplicationBaseAdminModel
   */
  playerStatus: PlayerStatusEnum;
  /**
   *
   * @type {CountryAddModel}
   * @memberof ApplicationBaseAdminModel
   */
  countryOfCitizenship?: CountryAddModel;
  /**
   *
   * @type {EthnicityEnum}
   * @memberof ApplicationBaseAdminModel
   */
  ethnicity?: EthnicityEnum;
  /**
   *
   * @type {number}
   * @memberof ApplicationBaseAdminModel
   */
  highSchoolGraduationYear?: number;
  /**
   *
   * @type {CountryAddModel}
   * @memberof ApplicationBaseAdminModel
   */
  preferredCountry?: CountryAddModel;
  /**
   *
   * @type {string}
   * @memberof ApplicationBaseAdminModel
   */
  preferredCity?: string;
  /**
   *
   * @type {StateAddModel}
   * @memberof ApplicationBaseAdminModel
   */
  preferredState?: StateAddModel;
  /**
   *
   * @type {ApplicationBasePlayerModelHandicap}
   * @memberof ApplicationBaseAdminModel
   */
  handicap?: ApplicationBasePlayerModelHandicap;
  /**
   *
   * @type {Array<IdModel>}
   * @memberof ApplicationBaseAdminModel
   */
  exemption?: Array<IdModel>;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationBaseAdminModel
   */
  optInPrivacy?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationBaseAdminModel
   */
  unknownHandicapID?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationBaseAdminModel
   */
  optInWagr?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationBaseAdminModel
   */
  antiDopingViolation?: boolean;
  /**
   *
   * @type {ApplicationDisabilityTypeEnum}
   * @memberof ApplicationBaseAdminModel
   */
  disabilityType?: ApplicationDisabilityTypeEnum;
  /**
   *
   * @type {string}
   * @memberof ApplicationBaseAdminModel
   */
  wR4GDPass?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationBaseAdminModel
   */
  disabilityDesc?: string;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationBaseAdminModel
   */
  legalGuardian?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationBaseAdminModel
   */
  playGolfInCart?: boolean;
  /**
   *
   * @type {string}
   * @memberof ApplicationBaseAdminModel
   */
  virtusII1Number?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationBaseAdminModel
   */
  adaptiveEquipDesc?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationBaseAdminModel
   */
  compPlayingRecord?: string;
  /**
   *
   * @type {Array<number>}
   * @memberof ApplicationBaseAdminModel
   */
  fileIds?: Array<number>;
  /**
   *
   * @type {string}
   * @memberof ApplicationBaseAdminModel
   */
  entryDate?: string;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationBaseAdminModel
   */
  surveyEmailSent?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationBaseAdminModel
   */
  declinedPaymentEmailSent?: boolean;
  /**
   *
   * @type {Array<ApplicationBaseAdminModelAllOfQualifyingSites>}
   * @memberof ApplicationBaseAdminModel
   */
  qualifyingSites?: Array<ApplicationBaseAdminModelAllOfQualifyingSites>;
  /**
   *
   * @type {Array<ApplicationPlayerModelAllOfWaitList>}
   * @memberof ApplicationBaseAdminModel
   */
  waitList?: Array<ApplicationPlayerModelAllOfWaitList>;
  /**
   *
   * @type {string}
   * @memberof ApplicationBaseAdminModel
   */
  wagrRank?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationBaseAdminModel
   */
  rank?: string;
  /**
   *
   * @type {QualifyingStatusEnum}
   * @memberof ApplicationBaseAdminModel
   */
  qualifyingStatus?: QualifyingStatusEnum;
  /**
   *
   * @type {string}
   * @memberof ApplicationBaseAdminModel
   */
  eligibleReinstatementDate?: string;
  /**
   *
   * @type {number}
   * @memberof ApplicationBaseAdminModel
   */
  id: number;
  /**
   *
   * @type {PaymentDataRequestModel}
   * @memberof ApplicationBaseAdminModel
   */
  payment?: PaymentDataRequestModel;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationBaseAdminModel
   */
  adaRequest?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationBaseAdminModel
   */
  notablePlayer?: boolean;
  /**
   *
   * @type {Array<ApplicationBaseAdminModelAllOfQueueReasons>}
   * @memberof ApplicationBaseAdminModel
   */
  queueReasons?: Array<ApplicationBaseAdminModelAllOfQueueReasons>;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationBaseAdminModel
   */
  markAsReviewed?: boolean;
  /**
   *
   * @type {ApplicationBaseAdminModelScoring}
   * @memberof ApplicationBaseAdminModel
   */
  scoring?: ApplicationBaseAdminModelScoring;
  /**
   *
   * @type {BlacklistStatusEnum}
   * @memberof ApplicationBaseAdminModel
   */
  blacklistStatus?: BlacklistStatusEnum;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationBaseAdminModel
   */
  blacklistSendEmail?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationBaseAdminModel
   */
  watchlist?: boolean;
  /**
   *
   * @type {string}
   * @memberof ApplicationBaseAdminModel
   */
  watchlistReason?: string;
  /**
   *
   * @type {ContactInfoModel}
   * @memberof ApplicationBaseAdminModel
   */
  secondaryContactInfo?: ContactInfoModel;
  /**
   *
   * @type {IdModel}
   * @memberof ApplicationBaseAdminModel
   */
  submittedByPlayer?: IdModel;
}
/**
 *
 * @export
 * @interface ApplicationBaseAdminModelAllOf
 */
export interface ApplicationBaseAdminModelAllOf {
  /**
   *
   * @type {Array<ApplicationBaseAdminModelAllOfQualifyingSites>}
   * @memberof ApplicationBaseAdminModelAllOf
   */
  qualifyingSites?: Array<ApplicationBaseAdminModelAllOfQualifyingSites>;
  /**
   *
   * @type {Array<ApplicationPlayerModelAllOfWaitList>}
   * @memberof ApplicationBaseAdminModelAllOf
   */
  waitList?: Array<ApplicationPlayerModelAllOfWaitList>;
  /**
   *
   * @type {string}
   * @memberof ApplicationBaseAdminModelAllOf
   */
  wagrRank?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationBaseAdminModelAllOf
   */
  rank?: string;
  /**
   *
   * @type {QualifyingStatusEnum}
   * @memberof ApplicationBaseAdminModelAllOf
   */
  qualifyingStatus?: QualifyingStatusEnum;
  /**
   *
   * @type {string}
   * @memberof ApplicationBaseAdminModelAllOf
   */
  eligibleReinstatementDate?: string;
  /**
   *
   * @type {number}
   * @memberof ApplicationBaseAdminModelAllOf
   */
  id: number;
  /**
   *
   * @type {PaymentDataRequestModel}
   * @memberof ApplicationBaseAdminModelAllOf
   */
  payment?: PaymentDataRequestModel;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationBaseAdminModelAllOf
   */
  adaRequest?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationBaseAdminModelAllOf
   */
  notablePlayer?: boolean;
  /**
   *
   * @type {Array<ApplicationBaseAdminModelAllOfQueueReasons>}
   * @memberof ApplicationBaseAdminModelAllOf
   */
  queueReasons?: Array<ApplicationBaseAdminModelAllOfQueueReasons>;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationBaseAdminModelAllOf
   */
  markAsReviewed?: boolean;
  /**
   *
   * @type {ApplicationBaseAdminModelScoring}
   * @memberof ApplicationBaseAdminModelAllOf
   */
  scoring?: ApplicationBaseAdminModelScoring;
  /**
   *
   * @type {BlacklistStatusEnum}
   * @memberof ApplicationBaseAdminModelAllOf
   */
  blacklistStatus?: BlacklistStatusEnum;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationBaseAdminModelAllOf
   */
  blacklistSendEmail?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationBaseAdminModelAllOf
   */
  watchlist?: boolean;
  /**
   *
   * @type {string}
   * @memberof ApplicationBaseAdminModelAllOf
   */
  watchlistReason?: string;
  /**
   *
   * @type {ContactInfoModel}
   * @memberof ApplicationBaseAdminModelAllOf
   */
  secondaryContactInfo?: ContactInfoModel;
  /**
   *
   * @type {IdModel}
   * @memberof ApplicationBaseAdminModelAllOf
   */
  submittedByPlayer?: IdModel;
}
/**
 *
 * @export
 * @interface ApplicationBaseAdminModelAllOfQualifyingSites
 */
export interface ApplicationBaseAdminModelAllOfQualifyingSites {
  /**
   *
   * @type {number}
   * @memberof ApplicationBaseAdminModelAllOfQualifyingSites
   */
  position?: number;
  /**
   *
   * @type {QualifyingSiteFinalizationEnum}
   * @memberof ApplicationBaseAdminModelAllOfQualifyingSites
   */
  finalizedFinalQSite?: QualifyingSiteFinalizationEnum;
  /**
   *
   * @type {IdModel}
   * @memberof ApplicationBaseAdminModelAllOfQualifyingSites
   */
  qualifyingSite?: IdModel;
}
/**
 *
 * @export
 * @interface ApplicationBaseAdminModelAllOfQueueReasons
 */
export interface ApplicationBaseAdminModelAllOfQueueReasons {
  /**
   *
   * @type {string}
   * @memberof ApplicationBaseAdminModelAllOfQueueReasons
   */
  comment: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationBaseAdminModelAllOfQueueReasons
   */
  adminUserId?: string;
  /**
   *
   * @type {IdModel}
   * @memberof ApplicationBaseAdminModelAllOfQueueReasons
   */
  queueReason: IdModel;
}
/**
 *
 * @export
 * @interface ApplicationBaseAdminModelScoring
 */
export interface ApplicationBaseAdminModelScoring {
  /**
   *
   * @type {number}
   * @memberof ApplicationBaseAdminModelScoring
   */
  appId?: number;
  /**
   *
   * @type {string}
   * @memberof ApplicationBaseAdminModelScoring
   */
  championshipFinalPlace?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationBaseAdminModelScoring
   */
  championshipRound1?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationBaseAdminModelScoring
   */
  championshipRound2?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationBaseAdminModelScoring
   */
  championshipRound3?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationBaseAdminModelScoring
   */
  championshipRound4?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationBaseAdminModelScoring
   */
  championshipTotalScore?: string;
  /**
   *
   * @type {PlayerAwardEnum}
   * @memberof ApplicationBaseAdminModelScoring
   */
  finalQualifyingPlayerAward1?: PlayerAwardEnum;
  /**
   *
   * @type {PlayerAwardEnum}
   * @memberof ApplicationBaseAdminModelScoring
   */
  finalQualifyingPlayerAward2?: PlayerAwardEnum;
  /**
   *
   * @type {string}
   * @memberof ApplicationBaseAdminModelScoring
   */
  finalQualifyingRound1?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationBaseAdminModelScoring
   */
  finalQualifyingRound2?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationBaseAdminModelScoring
   */
  finalQualifyingTotalScore?: string;
  /**
   *
   * @type {PlayerAwardEnum}
   * @memberof ApplicationBaseAdminModelScoring
   */
  qualifyingPlayerAward1?: PlayerAwardEnum;
  /**
   *
   * @type {PlayerAwardEnum}
   * @memberof ApplicationBaseAdminModelScoring
   */
  qualifyingPlayerAward2?: PlayerAwardEnum;
  /**
   *
   * @type {string}
   * @memberof ApplicationBaseAdminModelScoring
   */
  qualifyingRound1?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationBaseAdminModelScoring
   */
  qualifyingRound2?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationBaseAdminModelScoring
   */
  qualifyingTotalScore?: string;
  /**
   *
   * @type {PlayerAwardEnum}
   * @memberof ApplicationBaseAdminModelScoring
   */
  localQualifyingPlayerAward?: PlayerAwardEnum;
  /**
   *
   * @type {string}
   * @memberof ApplicationBaseAdminModelScoring
   */
  localQualifyingTotalScore?: string;
  /**
   *
   * @type {number}
   * @memberof ApplicationBaseAdminModelScoring
   */
  fileId?: number;
  /**
   *
   * @type {string}
   * @memberof ApplicationBaseAdminModelScoring
   */
  playerStatus?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationBaseAdminModelScoring
   */
  qualifyingStatus?: string;
  /**
   *
   * @type {UploadFileProgressionEnum}
   * @memberof ApplicationBaseAdminModelScoring
   */
  progression?: UploadFileProgressionEnum;
  /**
   *
   * @type {ApplicationBaseAdminModelScoringPrimaryContactInfo}
   * @memberof ApplicationBaseAdminModelScoring
   */
  primaryContactInfo?: ApplicationBaseAdminModelScoringPrimaryContactInfo;
}
/**
 *
 * @export
 * @interface ApplicationBaseAdminModelScoringPrimaryContactInfo
 */
export interface ApplicationBaseAdminModelScoringPrimaryContactInfo {
  /**
   *
   * @type {string}
   * @memberof ApplicationBaseAdminModelScoringPrimaryContactInfo
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationBaseAdminModelScoringPrimaryContactInfo
   */
  lastName?: string;
}
/**
 *
 * @export
 * @interface ApplicationBasePlayerModel
 */
export interface ApplicationBasePlayerModel {
  /**
   *
   * @type {IdModel}
   * @memberof ApplicationBasePlayerModel
   */
  championship: IdModel;
  /**
   *
   * @type {ApplicationBasePlayerModelPrimaryContactInfo}
   * @memberof ApplicationBasePlayerModel
   */
  primaryContactInfo: ApplicationBasePlayerModelPrimaryContactInfo;
  /**
   *
   * @type {ApplicationBasePlayerModelGuardianContactInfo}
   * @memberof ApplicationBasePlayerModel
   */
  guardianContactInfo?: ApplicationBasePlayerModelGuardianContactInfo;
  /**
   *
   * @type {ProfilePlayerModelAllOfEmergencyContact}
   * @memberof ApplicationBasePlayerModel
   */
  emergencyContact?: ProfilePlayerModelAllOfEmergencyContact;
  /**
   *
   * @type {GenderEnum}
   * @memberof ApplicationBasePlayerModel
   */
  gender: GenderEnum;
  /**
   *
   * @type {string}
   * @memberof ApplicationBasePlayerModel
   */
  dob: string;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationBasePlayerModel
   */
  playerMinor?: boolean;
  /**
   *
   * @type {PlayerStatusEnum}
   * @memberof ApplicationBasePlayerModel
   */
  playerStatus: PlayerStatusEnum;
  /**
   *
   * @type {CountryAddModel}
   * @memberof ApplicationBasePlayerModel
   */
  countryOfCitizenship?: CountryAddModel;
  /**
   *
   * @type {EthnicityEnum}
   * @memberof ApplicationBasePlayerModel
   */
  ethnicity?: EthnicityEnum;
  /**
   *
   * @type {number}
   * @memberof ApplicationBasePlayerModel
   */
  highSchoolGraduationYear?: number;
  /**
   *
   * @type {CountryAddModel}
   * @memberof ApplicationBasePlayerModel
   */
  preferredCountry?: CountryAddModel;
  /**
   *
   * @type {string}
   * @memberof ApplicationBasePlayerModel
   */
  preferredCity?: string;
  /**
   *
   * @type {StateAddModel}
   * @memberof ApplicationBasePlayerModel
   */
  preferredState?: StateAddModel;
  /**
   *
   * @type {ApplicationBasePlayerModelHandicap}
   * @memberof ApplicationBasePlayerModel
   */
  handicap?: ApplicationBasePlayerModelHandicap;
  /**
   *
   * @type {Array<IdModel>}
   * @memberof ApplicationBasePlayerModel
   */
  exemption?: Array<IdModel>;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationBasePlayerModel
   */
  optInPrivacy?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationBasePlayerModel
   */
  unknownHandicapID?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationBasePlayerModel
   */
  optInWagr?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationBasePlayerModel
   */
  antiDopingViolation?: boolean;
  /**
   *
   * @type {ApplicationDisabilityTypeEnum}
   * @memberof ApplicationBasePlayerModel
   */
  disabilityType?: ApplicationDisabilityTypeEnum;
  /**
   *
   * @type {string}
   * @memberof ApplicationBasePlayerModel
   */
  wR4GDPass?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationBasePlayerModel
   */
  disabilityDesc?: string;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationBasePlayerModel
   */
  legalGuardian?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationBasePlayerModel
   */
  playGolfInCart?: boolean;
  /**
   *
   * @type {string}
   * @memberof ApplicationBasePlayerModel
   */
  virtusII1Number?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationBasePlayerModel
   */
  adaptiveEquipDesc?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationBasePlayerModel
   */
  compPlayingRecord?: string;
  /**
   *
   * @type {Array<number>}
   * @memberof ApplicationBasePlayerModel
   */
  fileIds?: Array<number>;
  /**
   *
   * @type {string}
   * @memberof ApplicationBasePlayerModel
   */
  entryDate?: string;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationBasePlayerModel
   */
  surveyEmailSent?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationBasePlayerModel
   */
  declinedPaymentEmailSent?: boolean;
}
/**
 *
 * @export
 * @interface ApplicationBasePlayerModelGuardianContactInfo
 */
export interface ApplicationBasePlayerModelGuardianContactInfo {
  /**
   *
   * @type {string}
   * @memberof ApplicationBasePlayerModelGuardianContactInfo
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationBasePlayerModelGuardianContactInfo
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationBasePlayerModelGuardianContactInfo
   */
  address?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationBasePlayerModelGuardianContactInfo
   */
  address2?: string;
  /**
   *
   * @type {CountryAddModel}
   * @memberof ApplicationBasePlayerModelGuardianContactInfo
   */
  country?: CountryAddModel;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationBasePlayerModelGuardianContactInfo
   */
  sameAddress?: boolean;
  /**
   *
   * @type {string}
   * @memberof ApplicationBasePlayerModelGuardianContactInfo
   */
  relation?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationBasePlayerModelGuardianContactInfo
   */
  city?: string;
  /**
   *
   * @type {StateAddModel}
   * @memberof ApplicationBasePlayerModelGuardianContactInfo
   */
  state?: StateAddModel;
  /**
   *
   * @type {string}
   * @memberof ApplicationBasePlayerModelGuardianContactInfo
   */
  postcode?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationBasePlayerModelGuardianContactInfo
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationBasePlayerModelGuardianContactInfo
   */
  phoneNumber?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationBasePlayerModelGuardianContactInfo
   */
  mobileNumber?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationBasePlayerModelGuardianContactInfo
   */
  businessNumber?: string;
}
/**
 *
 * @export
 * @interface ApplicationBasePlayerModelHandicap
 */
export interface ApplicationBasePlayerModelHandicap {
  /**
   *
   * @type {string}
   * @memberof ApplicationBasePlayerModelHandicap
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationBasePlayerModelHandicap
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationBasePlayerModelHandicap
   */
  number?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationBasePlayerModelHandicap
   */
  clubName?: string;
  /**
   *
   * @type {CountryAddModel}
   * @memberof ApplicationBasePlayerModelHandicap
   */
  clubCountry?: CountryAddModel;
  /**
   *
   * @type {string}
   * @memberof ApplicationBasePlayerModelHandicap
   */
  governingAssociation?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationBasePlayerModelHandicap
   */
  golfAssociation?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationBasePlayerModelHandicap
   */
  index?: string;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationBasePlayerModelHandicap
   */
  betterThanScratch?: boolean;
  /**
   *
   * @type {ApplicationBasePlayerModelHandicapVerifier}
   * @memberof ApplicationBasePlayerModelHandicap
   */
  verifier?: ApplicationBasePlayerModelHandicapVerifier;
  /**
   *
   * @type {StateAddModel}
   * @memberof ApplicationBasePlayerModelHandicap
   */
  clubState?: StateAddModel;
  /**
   *
   * @type {string}
   * @memberof ApplicationBasePlayerModelHandicap
   */
  clubCity?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationBasePlayerModelHandicap
   */
  memberNumber?: string;
}
/**
 *
 * @export
 * @interface ApplicationBasePlayerModelHandicapVerifier
 */
export interface ApplicationBasePlayerModelHandicapVerifier {
  /**
   *
   * @type {string}
   * @memberof ApplicationBasePlayerModelHandicapVerifier
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationBasePlayerModelHandicapVerifier
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationBasePlayerModelHandicapVerifier
   */
  phoneNumber?: string;
}
/**
 *
 * @export
 * @interface ApplicationBasePlayerModelPrimaryContactInfo
 */
export interface ApplicationBasePlayerModelPrimaryContactInfo {
  /**
   *
   * @type {string}
   * @memberof ApplicationBasePlayerModelPrimaryContactInfo
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationBasePlayerModelPrimaryContactInfo
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationBasePlayerModelPrimaryContactInfo
   */
  address: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationBasePlayerModelPrimaryContactInfo
   */
  address2?: string;
  /**
   *
   * @type {CountryAddModel}
   * @memberof ApplicationBasePlayerModelPrimaryContactInfo
   */
  country: CountryAddModel;
  /**
   *
   * @type {string}
   * @memberof ApplicationBasePlayerModelPrimaryContactInfo
   */
  city: string;
  /**
   *
   * @type {StateAddModel}
   * @memberof ApplicationBasePlayerModelPrimaryContactInfo
   */
  state?: StateAddModel;
  /**
   *
   * @type {string}
   * @memberof ApplicationBasePlayerModelPrimaryContactInfo
   */
  postcode: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationBasePlayerModelPrimaryContactInfo
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationBasePlayerModelPrimaryContactInfo
   */
  phoneNumber?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationBasePlayerModelPrimaryContactInfo
   */
  mobileNumber?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationBasePlayerModelPrimaryContactInfo
   */
  businessNumber?: string;
}
/**
 *
 * @export
 * @interface ApplicationBaseRequestAdminModelScoring
 */
export interface ApplicationBaseRequestAdminModelScoring {
  /**
   *
   * @type {number}
   * @memberof ApplicationBaseRequestAdminModelScoring
   */
  appId?: number;
  /**
   *
   * @type {string}
   * @memberof ApplicationBaseRequestAdminModelScoring
   */
  championshipFinalPlace?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationBaseRequestAdminModelScoring
   */
  championshipRound1?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationBaseRequestAdminModelScoring
   */
  championshipRound2?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationBaseRequestAdminModelScoring
   */
  championshipRound3?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationBaseRequestAdminModelScoring
   */
  championshipRound4?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationBaseRequestAdminModelScoring
   */
  championshipTotalScore?: string;
  /**
   *
   * @type {PlayerAwardEnum}
   * @memberof ApplicationBaseRequestAdminModelScoring
   */
  finalQualifyingPlayerAward1?: PlayerAwardEnum;
  /**
   *
   * @type {PlayerAwardEnum}
   * @memberof ApplicationBaseRequestAdminModelScoring
   */
  finalQualifyingPlayerAward2?: PlayerAwardEnum;
  /**
   *
   * @type {string}
   * @memberof ApplicationBaseRequestAdminModelScoring
   */
  finalQualifyingRound1?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationBaseRequestAdminModelScoring
   */
  finalQualifyingRound2?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationBaseRequestAdminModelScoring
   */
  finalQualifyingTotalScore?: string;
  /**
   *
   * @type {PlayerAwardEnum}
   * @memberof ApplicationBaseRequestAdminModelScoring
   */
  qualifyingPlayerAward1?: PlayerAwardEnum;
  /**
   *
   * @type {PlayerAwardEnum}
   * @memberof ApplicationBaseRequestAdminModelScoring
   */
  qualifyingPlayerAward2?: PlayerAwardEnum;
  /**
   *
   * @type {string}
   * @memberof ApplicationBaseRequestAdminModelScoring
   */
  qualifyingRound1?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationBaseRequestAdminModelScoring
   */
  qualifyingRound2?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationBaseRequestAdminModelScoring
   */
  qualifyingTotalScore?: string;
  /**
   *
   * @type {PlayerAwardEnum}
   * @memberof ApplicationBaseRequestAdminModelScoring
   */
  localQualifyingPlayerAward?: PlayerAwardEnum;
  /**
   *
   * @type {string}
   * @memberof ApplicationBaseRequestAdminModelScoring
   */
  localQualifyingTotalScore?: string;
  /**
   *
   * @type {number}
   * @memberof ApplicationBaseRequestAdminModelScoring
   */
  fileId?: number;
  /**
   *
   * @type {PlayerStatusEnum}
   * @memberof ApplicationBaseRequestAdminModelScoring
   */
  playerStatus?: PlayerStatusEnum;
  /**
   *
   * @type {string}
   * @memberof ApplicationBaseRequestAdminModelScoring
   */
  qualifyingStatus?: string;
  /**
   *
   * @type {UploadFileProgressionEnum}
   * @memberof ApplicationBaseRequestAdminModelScoring
   */
  progression?: UploadFileProgressionEnum;
  /**
   *
   * @type {ApplicationBaseAdminModelScoringPrimaryContactInfo}
   * @memberof ApplicationBaseRequestAdminModelScoring
   */
  primaryContactInfo?: ApplicationBaseAdminModelScoringPrimaryContactInfo;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum ApplicationDisabilityTypeEnum {
  ARMORSPINEIMPAIRMENT = 'ARM_OR_SPINE_IMPAIRMENT',
  LEGIMPAIRMENT = 'LEG_IMPAIRMENT',
  MULTIPLELIMBAMPUTEEIMPAIRMENT = 'MULTIPLE_LIMB_AMPUTEE_IMPAIRMENT',
  VISIONIMPAIRMENT = 'VISION_IMPAIRMENT',
  INTELLECTUALIMPAIRMENT = 'INTELLECTUAL_IMPAIRMENT',
  NEUROLOGICALIMPAIRMENT = 'NEUROLOGICAL_IMPAIRMENT',
  SEATEDPLAYERS = 'SEATED_PLAYERS',
  SHORTINSTATUREPLAYERS = 'SHORT_IN_STATURE_PLAYERS',
}

/**
 *
 * @export
 * @interface ApplicationFetchModel
 */
export interface ApplicationFetchModel {
  /**
   *
   * @type {number}
   * @memberof ApplicationFetchModel
   */
  id?: number;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationFetchModel
   */
  active?: boolean;
  /**
   *
   * @type {IdModel}
   * @memberof ApplicationFetchModel
   */
  championship?: IdModel;
  /**
   *
   * @type {ApplicationFetchModelPrimaryContactInfo}
   * @memberof ApplicationFetchModel
   */
  primaryContactInfo?: ApplicationFetchModelPrimaryContactInfo;
  /**
   *
   * @type {ApplicationFetchModelGuardianContactInfo}
   * @memberof ApplicationFetchModel
   */
  guardianContactInfo?: ApplicationFetchModelGuardianContactInfo;
  /**
   *
   * @type {GenderEnum}
   * @memberof ApplicationFetchModel
   */
  gender?: GenderEnum;
  /**
   *
   * @type {string}
   * @memberof ApplicationFetchModel
   */
  dob?: string;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationFetchModel
   */
  playerMinor?: boolean;
  /**
   *
   * @type {PlayerStatusEnum}
   * @memberof ApplicationFetchModel
   */
  playerStatus?: PlayerStatusEnum;
  /**
   *
   * @type {CountryAddModel}
   * @memberof ApplicationFetchModel
   */
  countryOfCitizenship?: CountryAddModel;
  /**
   *
   * @type {EthnicityEnum}
   * @memberof ApplicationFetchModel
   */
  ethnicity?: EthnicityEnum;
  /**
   *
   * @type {number}
   * @memberof ApplicationFetchModel
   */
  highSchoolGraduationYear?: number;
  /**
   *
   * @type {CountryAddModel}
   * @memberof ApplicationFetchModel
   */
  preferredCountry?: CountryAddModel;
  /**
   *
   * @type {string}
   * @memberof ApplicationFetchModel
   */
  preferredCity?: string;
  /**
   *
   * @type {StateAddModel}
   * @memberof ApplicationFetchModel
   */
  preferredState?: StateAddModel;
  /**
   *
   * @type {ApplicationFetchModelHandicap}
   * @memberof ApplicationFetchModel
   */
  handicap?: ApplicationFetchModelHandicap;
  /**
   *
   * @type {Array<IdModel>}
   * @memberof ApplicationFetchModel
   */
  exemption?: Array<IdModel>;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationFetchModel
   */
  optInPrivacy?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationFetchModel
   */
  unknownHandicapID?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationFetchModel
   */
  optInWagr?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationFetchModel
   */
  antiDopingViolation?: boolean;
  /**
   *
   * @type {Array<ApplicationFetchModelQualifyingSites>}
   * @memberof ApplicationFetchModel
   */
  qualifyingSites?: Array<ApplicationFetchModelQualifyingSites>;
  /**
   *
   * @type {Array<ApplicationFetchModelWaitList>}
   * @memberof ApplicationFetchModel
   */
  waitList?: Array<ApplicationFetchModelWaitList>;
  /**
   *
   * @type {string}
   * @memberof ApplicationFetchModel
   */
  wagrRank?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationFetchModel
   */
  rank?: string;
  /**
   *
   * @type {QualifyingStatusEnum}
   * @memberof ApplicationFetchModel
   */
  qualifyingStatus?: QualifyingStatusEnum;
  /**
   *
   * @type {string}
   * @memberof ApplicationFetchModel
   */
  eligibleReinstatementDate?: string;
  /**
   *
   * @type {PaymentDataRequestModel}
   * @memberof ApplicationFetchModel
   */
  payment?: PaymentDataRequestModel;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationFetchModel
   */
  adaRequest?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationFetchModel
   */
  notablePlayer?: boolean;
  /**
   *
   * @type {Array<ApplicationFetchModelQueueReasons>}
   * @memberof ApplicationFetchModel
   */
  queueReasons?: Array<ApplicationFetchModelQueueReasons>;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationFetchModel
   */
  markAsReviewed?: boolean;
  /**
   *
   * @type {ApplicationBaseAdminModelScoring}
   * @memberof ApplicationFetchModel
   */
  scoring?: ApplicationBaseAdminModelScoring;
  /**
   *
   * @type {BlacklistStatusEnum}
   * @memberof ApplicationFetchModel
   */
  blacklistStatus?: BlacklistStatusEnum;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationFetchModel
   */
  blacklistSendEmail?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationFetchModel
   */
  watchlist?: boolean;
  /**
   *
   * @type {string}
   * @memberof ApplicationFetchModel
   */
  watchlistReason?: string;
  /**
   *
   * @type {ContactInfoModel}
   * @memberof ApplicationFetchModel
   */
  secondaryContactInfo?: ContactInfoModel;
  /**
   *
   * @type {IdModel}
   * @memberof ApplicationFetchModel
   */
  submittedByPlayer?: IdModel;
  /**
   *
   * @type {ApplicationFetchModelTeam}
   * @memberof ApplicationFetchModel
   */
  team?: ApplicationFetchModelTeam;
  /**
   *
   * @type {ApplicationFetchModelPair}
   * @memberof ApplicationFetchModel
   */
  pair?: ApplicationFetchModelPair;
  /**
   *
   * @type {number}
   * @memberof ApplicationFetchModel
   */
  pairingNumber?: number;
  /**
   *
   * @type {WithdrawalResponseAdminModel}
   * @memberof ApplicationFetchModel
   */
  withdrawal?: WithdrawalResponseAdminModel;
  /**
   *
   * @type {ApplicationProgressionEnum}
   * @memberof ApplicationFetchModel
   */
  progression?: ApplicationProgressionEnum;
  /**
   *
   * @type {string}
   * @memberof ApplicationFetchModel
   */
  reviewDate?: string;
  /**
   * Auth0 id of user who last made transition to reviewed state (enabled reviewDate field)
   * @type {string}
   * @memberof ApplicationFetchModel
   */
  reviewedBy?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationFetchModel
   */
  blacklistStatusDate?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationFetchModel
   */
  blacklistStatusDateCleared?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationFetchModel
   */
  watchlistDate?: string;
  /**
   *
   * @type {IdModel}
   * @memberof ApplicationFetchModel
   */
  player?: IdModel;
  /**
   *
   * @type {AuditModel}
   * @memberof ApplicationFetchModel
   */
  audit?: AuditModel;
  /**
   *
   * @type {NotificationModel}
   * @memberof ApplicationFetchModel
   */
  notifications?: NotificationModel;
  /**
   *
   * @type {number}
   * @memberof ApplicationFetchModel
   */
  transfersQuantity?: number;
}
/**
 *
 * @export
 * @interface ApplicationFetchModelGuardianContactInfo
 */
export interface ApplicationFetchModelGuardianContactInfo {
  /**
   *
   * @type {string}
   * @memberof ApplicationFetchModelGuardianContactInfo
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationFetchModelGuardianContactInfo
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationFetchModelGuardianContactInfo
   */
  address?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationFetchModelGuardianContactInfo
   */
  address2?: string;
  /**
   *
   * @type {CountryAddModel}
   * @memberof ApplicationFetchModelGuardianContactInfo
   */
  country?: CountryAddModel;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationFetchModelGuardianContactInfo
   */
  sameAddress?: boolean;
  /**
   *
   * @type {string}
   * @memberof ApplicationFetchModelGuardianContactInfo
   */
  relation?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationFetchModelGuardianContactInfo
   */
  city?: string;
  /**
   *
   * @type {StateAddModel}
   * @memberof ApplicationFetchModelGuardianContactInfo
   */
  state?: StateAddModel;
  /**
   *
   * @type {string}
   * @memberof ApplicationFetchModelGuardianContactInfo
   */
  postcode?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationFetchModelGuardianContactInfo
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationFetchModelGuardianContactInfo
   */
  phoneNumber?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationFetchModelGuardianContactInfo
   */
  mobileNumber?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationFetchModelGuardianContactInfo
   */
  businessNumber?: string;
}
/**
 *
 * @export
 * @interface ApplicationFetchModelHandicap
 */
export interface ApplicationFetchModelHandicap {
  /**
   *
   * @type {string}
   * @memberof ApplicationFetchModelHandicap
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationFetchModelHandicap
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationFetchModelHandicap
   */
  number?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationFetchModelHandicap
   */
  clubName?: string;
  /**
   *
   * @type {CountryAddModel}
   * @memberof ApplicationFetchModelHandicap
   */
  clubCountry?: CountryAddModel;
  /**
   *
   * @type {string}
   * @memberof ApplicationFetchModelHandicap
   */
  governingAssociation?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationFetchModelHandicap
   */
  golfAssociation?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationFetchModelHandicap
   */
  index?: string;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationFetchModelHandicap
   */
  betterThanScratch?: boolean;
  /**
   *
   * @type {ApplicationFetchModelHandicapVerifier}
   * @memberof ApplicationFetchModelHandicap
   */
  verifier?: ApplicationFetchModelHandicapVerifier;
  /**
   *
   * @type {StateAddModel}
   * @memberof ApplicationFetchModelHandicap
   */
  clubState?: StateAddModel;
  /**
   *
   * @type {string}
   * @memberof ApplicationFetchModelHandicap
   */
  clubCity?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationFetchModelHandicap
   */
  memberNumber?: string;
}
/**
 *
 * @export
 * @interface ApplicationFetchModelHandicapVerifier
 */
export interface ApplicationFetchModelHandicapVerifier {
  /**
   *
   * @type {string}
   * @memberof ApplicationFetchModelHandicapVerifier
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationFetchModelHandicapVerifier
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationFetchModelHandicapVerifier
   */
  phoneNumber?: string;
}
/**
 *
 * @export
 * @interface ApplicationFetchModelPair
 */
export interface ApplicationFetchModelPair {
  /**
   *
   * @type {number}
   * @memberof ApplicationFetchModelPair
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof ApplicationFetchModelPair
   */
  position?: number;
}
/**
 *
 * @export
 * @interface ApplicationFetchModelPrimaryContactInfo
 */
export interface ApplicationFetchModelPrimaryContactInfo {
  /**
   *
   * @type {string}
   * @memberof ApplicationFetchModelPrimaryContactInfo
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationFetchModelPrimaryContactInfo
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationFetchModelPrimaryContactInfo
   */
  address?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationFetchModelPrimaryContactInfo
   */
  address2?: string;
  /**
   *
   * @type {CountryAddModel}
   * @memberof ApplicationFetchModelPrimaryContactInfo
   */
  country?: CountryAddModel;
  /**
   *
   * @type {string}
   * @memberof ApplicationFetchModelPrimaryContactInfo
   */
  city?: string;
  /**
   *
   * @type {StateAddModel}
   * @memberof ApplicationFetchModelPrimaryContactInfo
   */
  state?: StateAddModel;
  /**
   *
   * @type {string}
   * @memberof ApplicationFetchModelPrimaryContactInfo
   */
  postcode?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationFetchModelPrimaryContactInfo
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationFetchModelPrimaryContactInfo
   */
  phoneNumber?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationFetchModelPrimaryContactInfo
   */
  mobileNumber?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationFetchModelPrimaryContactInfo
   */
  businessNumber?: string;
}
/**
 *
 * @export
 * @interface ApplicationFetchModelQualifyingSites
 */
export interface ApplicationFetchModelQualifyingSites {
  /**
   *
   * @type {number}
   * @memberof ApplicationFetchModelQualifyingSites
   */
  position?: number;
  /**
   *
   * @type {QualifyingSiteFinalizationEnum}
   * @memberof ApplicationFetchModelQualifyingSites
   */
  finalizedFinalQSite?: QualifyingSiteFinalizationEnum;
  /**
   *
   * @type {IdModel}
   * @memberof ApplicationFetchModelQualifyingSites
   */
  qualifyingSite?: IdModel;
}
/**
 *
 * @export
 * @interface ApplicationFetchModelQueueReasons
 */
export interface ApplicationFetchModelQueueReasons {
  /**
   *
   * @type {string}
   * @memberof ApplicationFetchModelQueueReasons
   */
  comment?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationFetchModelQueueReasons
   */
  adminUserId?: string;
  /**
   *
   * @type {IdModel}
   * @memberof ApplicationFetchModelQueueReasons
   */
  queueReason?: IdModel;
}
/**
 *
 * @export
 * @interface ApplicationFetchModelTeam
 */
export interface ApplicationFetchModelTeam {
  /**
   *
   * @type {Array<ApplicationResponseModel>}
   * @memberof ApplicationFetchModelTeam
   */
  applications?: Array<ApplicationResponseModel>;
  /**
   *
   * @type {number}
   * @memberof ApplicationFetchModelTeam
   */
  id?: number;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationFetchModelTeam
   */
  complete?: boolean;
  /**
   *
   * @type {ApplicationAdminResponseModelAllOfTeamAudit}
   * @memberof ApplicationFetchModelTeam
   */
  audit?: ApplicationAdminResponseModelAllOfTeamAudit;
}
/**
 *
 * @export
 * @interface ApplicationFetchModelWaitList
 */
export interface ApplicationFetchModelWaitList {
  /**
   *
   * @type {IdModel}
   * @memberof ApplicationFetchModelWaitList
   */
  qualifyingSite?: IdModel;
  /**
   *
   * @type {WaitListAlternateTypeEnum}
   * @memberof ApplicationFetchModelWaitList
   */
  alternateType?: WaitListAlternateTypeEnum;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum ApplicationFilters {
  TOTALENTRIES = 'TOTAL_ENTRIES',
  REVIEWNEEDED = 'REVIEW_NEEDED',
  REVIEWED = 'REVIEWED',
  Q = 'Q',
  ACTIVEAPPLICATIONS = 'ACTIVE_APPLICATIONS',
  TOTALWITHDRAWN = 'TOTAL_WITHDRAWN',
  TOTALREDBOXES = 'TOTAL_REDBOXES',
  COMPLETEDSIDES = 'COMPLETED_SIDES',
  INCOMPLETESIDES = 'INCOMPLETE_SIDES',
  FINALQALTERNATES = 'FINAL_Q_ALTERNATES',
  EXEMPTSIDES = 'EXEMPT_SIDES',
  FINALALTERNATES = 'FINAL_ALTERNATES',
  LOCALEXEMPT = 'LOCAL_EXEMPT',
  EXEMPT = 'EXEMPT',
  LOCALQUALIFYING = 'LOCAL_QUALIFYING',
  FINALQUALIFYING = 'FINAL_QUALIFYING',
  FINALISTALTERNATES = 'FINALIST_ALTERNATES',
  FINALEXEMPT = 'FINAL_EXEMPT',
  INQUALIFYING = 'IN_QUALIFYING',
  QUALIFYINGEXEMPT = 'QUALIFYING_EXEMPT',
  QUALIFYINGALTERNATES = 'QUALIFYING_ALTERNATES',
  FINALISTS = 'FINALISTS',
  ACCEPTEDFINALISTS = 'ACCEPTED_FINALISTS',
  DUPLICATEMEDALISTS = 'DUPLICATE_MEDALISTS',
  BLACKLIST = 'BLACKLIST',
  ADA = 'ADA',
  DUPLICATEAPPLICATIONS = 'DUPLICATE_APPLICATIONS',
  ANTIDOPINGVIOLATION = 'ANTI_DOPING_VIOLATION',
  CLOSEOFENTRIESDATASNAPSHOT = 'CLOSE_OF_ENTRIES_DATA_SNAPSHOT',
  PLAYEREXEMPT = 'PLAYER_EXEMPT',
  WATCHLIST = 'WATCHLIST',
  NOTABLEPLAYERS = 'NOTABLE_PLAYERS',
  PAIRINGS = 'PAIRINGS',
  WAITLISTDECLINEDAUTOCHARGE = 'WAITLIST_DECLINED_AUTOCHARGE',
  TOTALWAITLISTED = 'TOTAL_WAITLISTED',
  CURRENTPLAYER = 'CURRENT_PLAYER',
  QUALIFYINGSITEIDS = 'QUALIFYING_SITE_IDS',
  FAILEDREFUND = 'FAILED_REFUND',
}

/**
 *
 * @export
 * @enum {string}
 */
export enum ApplicationFiltersEnum {
  TOTALENTRIES = 'TOTAL_ENTRIES',
  REVIEWNEEDED = 'REVIEW_NEEDED',
  REVIEWED = 'REVIEWED',
  Q = 'Q',
  ACTIVEAPPLICATIONS = 'ACTIVE_APPLICATIONS',
  TOTALWITHDRAWN = 'TOTAL_WITHDRAWN',
  TOTALREDBOXES = 'TOTAL_REDBOXES',
  COMPLETEDSIDES = 'COMPLETED_SIDES',
  INCOMPLETESIDES = 'INCOMPLETE_SIDES',
  EXEMPTSIDES = 'EXEMPT_SIDES',
  FINALALTERNATES = 'FINAL_ALTERNATES',
  LOCALEXEMPT = 'LOCAL_EXEMPT',
  EXEMPT = 'EXEMPT',
  LOCALQUALIFYING = 'LOCAL_QUALIFYING',
  FINALQUALIFYING = 'FINAL_QUALIFYING',
  FINALISTALTERNATES = 'FINALIST_ALTERNATES',
  FINALEXEMPT = 'FINAL_EXEMPT',
  FINALQALTERNATES = 'FINAL_Q_ALTERNATES',
  INQUALIFYING = 'IN_QUALIFYING',
  QUALIFYINGEXEMPT = 'QUALIFYING_EXEMPT',
  QUALIFYINGALTERNATES = 'QUALIFYING_ALTERNATES',
  FINALISTS = 'FINALISTS',
  PAIRINGS = 'PAIRINGS',
  DISABILITYTYPE = 'DISABILITY_TYPE',
  QUALIFYINGSITEIDS = 'QUALIFYING_SITE_IDS',
}

/**
 *
 * @export
 * @enum {string}
 */
export enum ApplicationFinalConfirmationBulkFilterEnum {
  QUALIFYINGBULK = 'QUALIFYING_BULK',
  FINALISTSBULK = 'FINALISTS_BULK',
  LOCALEXEMPTBULK = 'LOCAL_EXEMPT_BULK',
  WAITLISTBULK = 'WAITLIST_BULK',
}

/**
 *
 * @export
 * @interface ApplicationHandicapFetchModel
 */
export interface ApplicationHandicapFetchModel {
  /**
   *
   * @type {number}
   * @memberof ApplicationHandicapFetchModel
   */
  id?: number;
  /**
   *
   * @type {ApplicationHandicapFetchModelPrimaryContactInfo}
   * @memberof ApplicationHandicapFetchModel
   */
  primaryContactInfo?: ApplicationHandicapFetchModelPrimaryContactInfo;
  /**
   *
   * @type {ApplicationFetchModelHandicap}
   * @memberof ApplicationHandicapFetchModel
   */
  handicap?: ApplicationFetchModelHandicap;
}
/**
 *
 * @export
 * @interface ApplicationHandicapFetchModelPrimaryContactInfo
 */
export interface ApplicationHandicapFetchModelPrimaryContactInfo {
  /**
   *
   * @type {string}
   * @memberof ApplicationHandicapFetchModelPrimaryContactInfo
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationHandicapFetchModelPrimaryContactInfo
   */
  businessNumber?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationHandicapFetchModelPrimaryContactInfo
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationHandicapFetchModelPrimaryContactInfo
   */
  address?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationHandicapFetchModelPrimaryContactInfo
   */
  address2?: string;
  /**
   *
   * @type {CountryAddModel}
   * @memberof ApplicationHandicapFetchModelPrimaryContactInfo
   */
  country?: CountryAddModel;
  /**
   *
   * @type {string}
   * @memberof ApplicationHandicapFetchModelPrimaryContactInfo
   */
  city?: string;
  /**
   *
   * @type {StateAddModel}
   * @memberof ApplicationHandicapFetchModelPrimaryContactInfo
   */
  state?: StateAddModel;
  /**
   *
   * @type {string}
   * @memberof ApplicationHandicapFetchModelPrimaryContactInfo
   */
  postcode?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationHandicapFetchModelPrimaryContactInfo
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationHandicapFetchModelPrimaryContactInfo
   */
  phoneNumber?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationHandicapFetchModelPrimaryContactInfo
   */
  mobileNumber?: string;
}
/**
 *
 * @export
 * @interface ApplicationHistoryListModel
 */
export interface ApplicationHistoryListModel {
  /**
   *
   * @type {Array<ApplicationHistoryResponseModel>}
   * @memberof ApplicationHistoryListModel
   */
  items: Array<ApplicationHistoryResponseModel>;
  /**
   *
   * @type {number}
   * @memberof ApplicationHistoryListModel
   */
  limit: number;
  /**
   *
   * @type {number}
   * @memberof ApplicationHistoryListModel
   */
  start: number;
  /**
   *
   * @type {number}
   * @memberof ApplicationHistoryListModel
   */
  total: number;
}
/**
 *
 * @export
 * @interface ApplicationHistoryResponseModel
 */
export interface ApplicationHistoryResponseModel {
  /**
   *
   * @type {ChampionshipHistoryModel}
   * @memberof ApplicationHistoryResponseModel
   */
  championship: ChampionshipHistoryModel;
  /**
   *
   * @type {Array<UpcomingEventModelExemption>}
   * @memberof ApplicationHistoryResponseModel
   */
  exemption?: Array<UpcomingEventModelExemption>;
  /**
   *
   * @type {Array<ApplicationHistoryResponseModelQualifyingSites>}
   * @memberof ApplicationHistoryResponseModel
   */
  qualifyingSites?: Array<ApplicationHistoryResponseModelQualifyingSites>;
  /**
   *
   * @type {Array<ApplicationAppliedResponseModelWaitList>}
   * @memberof ApplicationHistoryResponseModel
   */
  waitList?: Array<ApplicationAppliedResponseModelWaitList>;
  /**
   *
   * @type {QualifyingStatusEnum}
   * @memberof ApplicationHistoryResponseModel
   */
  qualifyingStatus?: QualifyingStatusEnum;
  /**
   *
   * @type {number}
   * @memberof ApplicationHistoryResponseModel
   */
  id: number;
  /**
   *
   * @type {ApplicationBaseAdminModelScoring}
   * @memberof ApplicationHistoryResponseModel
   */
  scoring?: ApplicationBaseAdminModelScoring;
  /**
   *
   * @type {ApplicationProgressionEnum}
   * @memberof ApplicationHistoryResponseModel
   */
  progression: ApplicationProgressionEnum;
}
/**
 *
 * @export
 * @interface ApplicationHistoryResponseModelQualifyingSite
 */
export interface ApplicationHistoryResponseModelQualifyingSite {
  /**
   *
   * @type {QualifyingLevelEnum}
   * @memberof ApplicationHistoryResponseModelQualifyingSite
   */
  level: QualifyingLevelEnum;
  /**
   *
   * @type {string}
   * @memberof ApplicationHistoryResponseModelQualifyingSite
   */
  qualifyingDate: string;
  /**
   *
   * @type {number}
   * @memberof ApplicationHistoryResponseModelQualifyingSite
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof ApplicationHistoryResponseModelQualifyingSite
   */
  city?: string;
  /**
   *
   * @type {ApplicationQualifyingLocationResponseFetchModelQualifyingSiteState}
   * @memberof ApplicationHistoryResponseModelQualifyingSite
   */
  country?: ApplicationQualifyingLocationResponseFetchModelQualifyingSiteState;
  /**
   *
   * @type {ApplicationQualifyingLocationResponseFetchModelQualifyingSiteState}
   * @memberof ApplicationHistoryResponseModelQualifyingSite
   */
  state?: ApplicationQualifyingLocationResponseFetchModelQualifyingSiteState;
  /**
   *
   * @type {Array<ApplicationHistoryResponseModelQualifyingSiteClub>}
   * @memberof ApplicationHistoryResponseModelQualifyingSite
   */
  club: Array<ApplicationHistoryResponseModelQualifyingSiteClub>;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationHistoryResponseModelQualifyingSite
   */
  qualifyingDateIsTwoDays?: boolean;
}
/**
 *
 * @export
 * @interface ApplicationHistoryResponseModelQualifyingSiteClub
 */
export interface ApplicationHistoryResponseModelQualifyingSiteClub {
  /**
   *
   * @type {number}
   * @memberof ApplicationHistoryResponseModelQualifyingSiteClub
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof ApplicationHistoryResponseModelQualifyingSiteClub
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationHistoryResponseModelQualifyingSiteClub
   */
  courseName?: string;
}
/**
 *
 * @export
 * @interface ApplicationHistoryResponseModelQualifyingSites
 */
export interface ApplicationHistoryResponseModelQualifyingSites {
  /**
   *
   * @type {number}
   * @memberof ApplicationHistoryResponseModelQualifyingSites
   */
  position?: number;
  /**
   *
   * @type {QualifyingSiteFinalizationEnum}
   * @memberof ApplicationHistoryResponseModelQualifyingSites
   */
  finalizedFinalQSite?: QualifyingSiteFinalizationEnum;
  /**
   *
   * @type {ApplicationHistoryResponseModelQualifyingSite}
   * @memberof ApplicationHistoryResponseModelQualifyingSites
   */
  qualifyingSite: ApplicationHistoryResponseModelQualifyingSite;
}
/**
 * Represents Application Home Step Player Appliance Model
 * @export
 * @interface ApplicationHomeStepPlayerApplianceModel
 */
export interface ApplicationHomeStepPlayerApplianceModel {
  /**
   *
   * @type {string}
   * @memberof ApplicationHomeStepPlayerApplianceModel
   */
  headText?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationHomeStepPlayerApplianceModel
   */
  bottomText?: string;
  /**
   *
   * @type {BirthdaySectionModel}
   * @memberof ApplicationHomeStepPlayerApplianceModel
   */
  birthdaySection: BirthdaySectionModel;
}
/**
 *
 * @export
 * @interface ApplicationLightweightAdminListModel
 */
export interface ApplicationLightweightAdminListModel {
  /**
   *
   * @type {Array<ApplicationQualifyingLocationLightweightResponseModel>}
   * @memberof ApplicationLightweightAdminListModel
   */
  items: Array<ApplicationQualifyingLocationLightweightResponseModel>;
  /**
   *
   * @type {number}
   * @memberof ApplicationLightweightAdminListModel
   */
  limit: number;
  /**
   *
   * @type {number}
   * @memberof ApplicationLightweightAdminListModel
   */
  start: number;
  /**
   *
   * @type {number}
   * @memberof ApplicationLightweightAdminListModel
   */
  total: number;
}
/**
 *
 * @export
 * @interface ApplicationMergeFetchModel
 */
export interface ApplicationMergeFetchModel {
  /**
   *
   * @type {number}
   * @memberof ApplicationMergeFetchModel
   */
  id?: number;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationMergeFetchModel
   */
  active?: boolean;
  /**
   *
   * @type {ChampionshipFetchModel}
   * @memberof ApplicationMergeFetchModel
   */
  championship?: ChampionshipFetchModel;
  /**
   *
   * @type {ApplicationFetchModelPrimaryContactInfo}
   * @memberof ApplicationMergeFetchModel
   */
  primaryContactInfo?: ApplicationFetchModelPrimaryContactInfo;
  /**
   *
   * @type {ApplicationFetchModelGuardianContactInfo}
   * @memberof ApplicationMergeFetchModel
   */
  guardianContactInfo?: ApplicationFetchModelGuardianContactInfo;
  /**
   *
   * @type {GenderEnum}
   * @memberof ApplicationMergeFetchModel
   */
  gender?: GenderEnum;
  /**
   *
   * @type {string}
   * @memberof ApplicationMergeFetchModel
   */
  dob?: string;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationMergeFetchModel
   */
  playerMinor?: boolean;
  /**
   *
   * @type {PlayerStatusEnum}
   * @memberof ApplicationMergeFetchModel
   */
  playerStatus?: PlayerStatusEnum;
  /**
   *
   * @type {CountryAddModel}
   * @memberof ApplicationMergeFetchModel
   */
  countryOfCitizenship?: CountryAddModel;
  /**
   *
   * @type {EthnicityEnum}
   * @memberof ApplicationMergeFetchModel
   */
  ethnicity?: EthnicityEnum;
  /**
   *
   * @type {number}
   * @memberof ApplicationMergeFetchModel
   */
  highSchoolGraduationYear?: number;
  /**
   *
   * @type {CountryAddModel}
   * @memberof ApplicationMergeFetchModel
   */
  preferredCountry?: CountryAddModel;
  /**
   *
   * @type {string}
   * @memberof ApplicationMergeFetchModel
   */
  preferredCity?: string;
  /**
   *
   * @type {StateAddModel}
   * @memberof ApplicationMergeFetchModel
   */
  preferredState?: StateAddModel;
  /**
   *
   * @type {ApplicationFetchModelHandicap}
   * @memberof ApplicationMergeFetchModel
   */
  handicap?: ApplicationFetchModelHandicap;
  /**
   *
   * @type {Array<IdModel>}
   * @memberof ApplicationMergeFetchModel
   */
  exemption?: Array<IdModel>;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationMergeFetchModel
   */
  optInPrivacy?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationMergeFetchModel
   */
  unknownHandicapID?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationMergeFetchModel
   */
  optInWagr?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationMergeFetchModel
   */
  antiDopingViolation?: boolean;
  /**
   *
   * @type {Array<ApplicationFetchModelQualifyingSites>}
   * @memberof ApplicationMergeFetchModel
   */
  qualifyingSites?: Array<ApplicationFetchModelQualifyingSites>;
  /**
   *
   * @type {Array<ApplicationFetchModelWaitList>}
   * @memberof ApplicationMergeFetchModel
   */
  waitList?: Array<ApplicationFetchModelWaitList>;
  /**
   *
   * @type {string}
   * @memberof ApplicationMergeFetchModel
   */
  wagrRank?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationMergeFetchModel
   */
  rank?: string;
  /**
   *
   * @type {QualifyingStatusEnum}
   * @memberof ApplicationMergeFetchModel
   */
  qualifyingStatus?: QualifyingStatusEnum;
  /**
   *
   * @type {string}
   * @memberof ApplicationMergeFetchModel
   */
  eligibleReinstatementDate?: string;
  /**
   *
   * @type {PaymentDataRequestModel}
   * @memberof ApplicationMergeFetchModel
   */
  payment?: PaymentDataRequestModel;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationMergeFetchModel
   */
  adaRequest?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationMergeFetchModel
   */
  notablePlayer?: boolean;
  /**
   *
   * @type {Array<ApplicationFetchModelQueueReasons>}
   * @memberof ApplicationMergeFetchModel
   */
  queueReasons?: Array<ApplicationFetchModelQueueReasons>;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationMergeFetchModel
   */
  markAsReviewed?: boolean;
  /**
   *
   * @type {ApplicationBaseAdminModelScoring}
   * @memberof ApplicationMergeFetchModel
   */
  scoring?: ApplicationBaseAdminModelScoring;
  /**
   *
   * @type {BlacklistStatusEnum}
   * @memberof ApplicationMergeFetchModel
   */
  blacklistStatus?: BlacklistStatusEnum;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationMergeFetchModel
   */
  blacklistSendEmail?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationMergeFetchModel
   */
  watchlist?: boolean;
  /**
   *
   * @type {string}
   * @memberof ApplicationMergeFetchModel
   */
  watchlistReason?: string;
  /**
   *
   * @type {ContactInfoModel}
   * @memberof ApplicationMergeFetchModel
   */
  secondaryContactInfo?: ContactInfoModel;
  /**
   *
   * @type {IdModel}
   * @memberof ApplicationMergeFetchModel
   */
  submittedByPlayer?: IdModel;
  /**
   *
   * @type {ApplicationFetchModelTeam}
   * @memberof ApplicationMergeFetchModel
   */
  team?: ApplicationFetchModelTeam;
  /**
   *
   * @type {ApplicationFetchModelPair}
   * @memberof ApplicationMergeFetchModel
   */
  pair?: ApplicationFetchModelPair;
  /**
   *
   * @type {number}
   * @memberof ApplicationMergeFetchModel
   */
  pairingNumber?: number;
  /**
   *
   * @type {WithdrawalResponseAdminModel}
   * @memberof ApplicationMergeFetchModel
   */
  withdrawal?: WithdrawalResponseAdminModel;
  /**
   *
   * @type {ApplicationProgressionEnum}
   * @memberof ApplicationMergeFetchModel
   */
  progression?: ApplicationProgressionEnum;
  /**
   *
   * @type {string}
   * @memberof ApplicationMergeFetchModel
   */
  reviewDate?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationMergeFetchModel
   */
  reviewedBy?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationMergeFetchModel
   */
  blacklistStatusDate?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationMergeFetchModel
   */
  blacklistStatusDateCleared?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationMergeFetchModel
   */
  watchlistDate?: string;
  /**
   *
   * @type {ProfileAdminModel}
   * @memberof ApplicationMergeFetchModel
   */
  player?: ProfileAdminModel;
  /**
   *
   * @type {AuditModel}
   * @memberof ApplicationMergeFetchModel
   */
  audit?: AuditModel;
  /**
   *
   * @type {NotificationModel}
   * @memberof ApplicationMergeFetchModel
   */
  notifications?: NotificationModel;
  /**
   *
   * @type {number}
   * @memberof ApplicationMergeFetchModel
   */
  transfersQuantity?: number;
}
/**
 *
 * @export
 * @interface ApplicationMultiFilterParamBeanModel
 */
export interface ApplicationMultiFilterParamBeanModel {
  /**
   *
   * @type {number}
   * @memberof ApplicationMultiFilterParamBeanModel
   */
  limit?: number;
  /**
   *
   * @type {number}
   * @memberof ApplicationMultiFilterParamBeanModel
   */
  start?: number;
  /**
   *
   * @type {number}
   * @memberof ApplicationMultiFilterParamBeanModel
   */
  champId: number;
  /**
   *
   * @type {string}
   * @memberof ApplicationMultiFilterParamBeanModel
   */
  query?: string;
  /**
   *
   * @type {Array<number>}
   * @memberof ApplicationMultiFilterParamBeanModel
   */
  qualifyingSiteIds?: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof ApplicationMultiFilterParamBeanModel
   */
  exemptionIds?: Array<number>;
  /**
   *
   * @type {QualifyingStatusEnum}
   * @memberof ApplicationMultiFilterParamBeanModel
   */
  qualifyingStatus?: QualifyingStatusEnum;
  /**
   *
   * @type {Array<ApplicationProgressionEnum>}
   * @memberof ApplicationMultiFilterParamBeanModel
   */
  applicationStatus?: Array<ApplicationProgressionEnum>;
  /**
   *
   * @type {Array<BlacklistStatusEnum>}
   * @memberof ApplicationMultiFilterParamBeanModel
   */
  blacklistStatus?: Array<BlacklistStatusEnum>;
  /**
   *
   * @type {SortingFilterEnum}
   * @memberof ApplicationMultiFilterParamBeanModel
   */
  sortingFilter?: SortingFilterEnum;
}
/**
 * Represents Application Notifications Component
 * @export
 * @interface ApplicationNotificationsChampModel
 */
export interface ApplicationNotificationsChampModel {
  /**
   *
   * @type {Array<string>}
   * @memberof ApplicationNotificationsChampModel
   */
  notifications?: Array<string>;
}
/**
 *
 * @export
 * @interface ApplicationPaymentHistoryAdminResponseModel
 */
export interface ApplicationPaymentHistoryAdminResponseModel {
  /**
   *
   * @type {IdModel}
   * @memberof ApplicationPaymentHistoryAdminResponseModel
   */
  championship: IdModel;
  /**
   *
   * @type {ApplicationBasePlayerModelPrimaryContactInfo}
   * @memberof ApplicationPaymentHistoryAdminResponseModel
   */
  primaryContactInfo: ApplicationBasePlayerModelPrimaryContactInfo;
  /**
   *
   * @type {ApplicationBasePlayerModelGuardianContactInfo}
   * @memberof ApplicationPaymentHistoryAdminResponseModel
   */
  guardianContactInfo?: ApplicationBasePlayerModelGuardianContactInfo;
  /**
   *
   * @type {ProfilePlayerModelAllOfEmergencyContact}
   * @memberof ApplicationPaymentHistoryAdminResponseModel
   */
  emergencyContact?: ProfilePlayerModelAllOfEmergencyContact;
  /**
   *
   * @type {GenderEnum}
   * @memberof ApplicationPaymentHistoryAdminResponseModel
   */
  gender: GenderEnum;
  /**
   *
   * @type {string}
   * @memberof ApplicationPaymentHistoryAdminResponseModel
   */
  dob: string;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationPaymentHistoryAdminResponseModel
   */
  playerMinor?: boolean;
  /**
   *
   * @type {PlayerStatusEnum}
   * @memberof ApplicationPaymentHistoryAdminResponseModel
   */
  playerStatus: PlayerStatusEnum;
  /**
   *
   * @type {CountryAddModel}
   * @memberof ApplicationPaymentHistoryAdminResponseModel
   */
  countryOfCitizenship?: CountryAddModel;
  /**
   *
   * @type {EthnicityEnum}
   * @memberof ApplicationPaymentHistoryAdminResponseModel
   */
  ethnicity?: EthnicityEnum;
  /**
   *
   * @type {number}
   * @memberof ApplicationPaymentHistoryAdminResponseModel
   */
  highSchoolGraduationYear?: number;
  /**
   *
   * @type {CountryAddModel}
   * @memberof ApplicationPaymentHistoryAdminResponseModel
   */
  preferredCountry?: CountryAddModel;
  /**
   *
   * @type {string}
   * @memberof ApplicationPaymentHistoryAdminResponseModel
   */
  preferredCity?: string;
  /**
   *
   * @type {StateAddModel}
   * @memberof ApplicationPaymentHistoryAdminResponseModel
   */
  preferredState?: StateAddModel;
  /**
   *
   * @type {ApplicationBasePlayerModelHandicap}
   * @memberof ApplicationPaymentHistoryAdminResponseModel
   */
  handicap?: ApplicationBasePlayerModelHandicap;
  /**
   *
   * @type {Array<IdModel>}
   * @memberof ApplicationPaymentHistoryAdminResponseModel
   */
  exemption?: Array<IdModel>;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationPaymentHistoryAdminResponseModel
   */
  optInPrivacy?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationPaymentHistoryAdminResponseModel
   */
  unknownHandicapID?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationPaymentHistoryAdminResponseModel
   */
  optInWagr?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationPaymentHistoryAdminResponseModel
   */
  antiDopingViolation?: boolean;
  /**
   *
   * @type {ApplicationDisabilityTypeEnum}
   * @memberof ApplicationPaymentHistoryAdminResponseModel
   */
  disabilityType?: ApplicationDisabilityTypeEnum;
  /**
   *
   * @type {string}
   * @memberof ApplicationPaymentHistoryAdminResponseModel
   */
  wR4GDPass?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationPaymentHistoryAdminResponseModel
   */
  disabilityDesc?: string;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationPaymentHistoryAdminResponseModel
   */
  legalGuardian?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationPaymentHistoryAdminResponseModel
   */
  playGolfInCart?: boolean;
  /**
   *
   * @type {string}
   * @memberof ApplicationPaymentHistoryAdminResponseModel
   */
  virtusII1Number?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationPaymentHistoryAdminResponseModel
   */
  adaptiveEquipDesc?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationPaymentHistoryAdminResponseModel
   */
  compPlayingRecord?: string;
  /**
   *
   * @type {Array<number>}
   * @memberof ApplicationPaymentHistoryAdminResponseModel
   */
  fileIds?: Array<number>;
  /**
   *
   * @type {string}
   * @memberof ApplicationPaymentHistoryAdminResponseModel
   */
  entryDate?: string;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationPaymentHistoryAdminResponseModel
   */
  surveyEmailSent?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationPaymentHistoryAdminResponseModel
   */
  declinedPaymentEmailSent?: boolean;
  /**
   *
   * @type {Array<ApplicationBaseAdminModelAllOfQualifyingSites>}
   * @memberof ApplicationPaymentHistoryAdminResponseModel
   */
  qualifyingSites?: Array<ApplicationBaseAdminModelAllOfQualifyingSites>;
  /**
   *
   * @type {Array<ApplicationPlayerModelAllOfWaitList>}
   * @memberof ApplicationPaymentHistoryAdminResponseModel
   */
  waitList?: Array<ApplicationPlayerModelAllOfWaitList>;
  /**
   *
   * @type {string}
   * @memberof ApplicationPaymentHistoryAdminResponseModel
   */
  wagrRank?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationPaymentHistoryAdminResponseModel
   */
  rank?: string;
  /**
   *
   * @type {QualifyingStatusEnum}
   * @memberof ApplicationPaymentHistoryAdminResponseModel
   */
  qualifyingStatus?: QualifyingStatusEnum;
  /**
   *
   * @type {string}
   * @memberof ApplicationPaymentHistoryAdminResponseModel
   */
  eligibleReinstatementDate?: string;
  /**
   *
   * @type {number}
   * @memberof ApplicationPaymentHistoryAdminResponseModel
   */
  id: number;
  /**
   *
   * @type {PaymentDataRequestModel}
   * @memberof ApplicationPaymentHistoryAdminResponseModel
   */
  payment?: PaymentDataRequestModel;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationPaymentHistoryAdminResponseModel
   */
  adaRequest?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationPaymentHistoryAdminResponseModel
   */
  notablePlayer?: boolean;
  /**
   *
   * @type {Array<ApplicationBaseAdminModelAllOfQueueReasons>}
   * @memberof ApplicationPaymentHistoryAdminResponseModel
   */
  queueReasons?: Array<ApplicationBaseAdminModelAllOfQueueReasons>;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationPaymentHistoryAdminResponseModel
   */
  markAsReviewed?: boolean;
  /**
   *
   * @type {ApplicationBaseAdminModelScoring}
   * @memberof ApplicationPaymentHistoryAdminResponseModel
   */
  scoring?: ApplicationBaseAdminModelScoring;
  /**
   *
   * @type {BlacklistStatusEnum}
   * @memberof ApplicationPaymentHistoryAdminResponseModel
   */
  blacklistStatus?: BlacklistStatusEnum;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationPaymentHistoryAdminResponseModel
   */
  blacklistSendEmail?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationPaymentHistoryAdminResponseModel
   */
  watchlist?: boolean;
  /**
   *
   * @type {string}
   * @memberof ApplicationPaymentHistoryAdminResponseModel
   */
  watchlistReason?: string;
  /**
   *
   * @type {ContactInfoModel}
   * @memberof ApplicationPaymentHistoryAdminResponseModel
   */
  secondaryContactInfo?: ContactInfoModel;
  /**
   *
   * @type {IdModel}
   * @memberof ApplicationPaymentHistoryAdminResponseModel
   */
  submittedByPlayer?: IdModel;
  /**
   *
   * @type {ApplicationPaymentHistoryAdminResponseModelAllOfTeam}
   * @memberof ApplicationPaymentHistoryAdminResponseModel
   */
  team?: ApplicationPaymentHistoryAdminResponseModelAllOfTeam;
  /**
   *
   * @type {ApplicationAdminResponseModelAllOfPair}
   * @memberof ApplicationPaymentHistoryAdminResponseModel
   */
  pair?: ApplicationAdminResponseModelAllOfPair;
  /**
   *
   * @type {DeclinedPaymentModel}
   * @memberof ApplicationPaymentHistoryAdminResponseModel
   */
  declinedPayment?: DeclinedPaymentModel;
  /**
   *
   * @type {number}
   * @memberof ApplicationPaymentHistoryAdminResponseModel
   */
  pairingNumber?: number;
  /**
   *
   * @type {WithdrawalResponseAdminModel}
   * @memberof ApplicationPaymentHistoryAdminResponseModel
   */
  withdrawal?: WithdrawalResponseAdminModel;
  /**
   *
   * @type {ApplicationProgressionEnum}
   * @memberof ApplicationPaymentHistoryAdminResponseModel
   */
  progression: ApplicationProgressionEnum;
  /**
   *
   * @type {string}
   * @memberof ApplicationPaymentHistoryAdminResponseModel
   */
  reviewDate?: string;
  /**
   * Auth0 id of user who last made transition to reviewed state (enabled reviewDate field)
   * @type {string}
   * @memberof ApplicationPaymentHistoryAdminResponseModel
   */
  reviewedBy?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationPaymentHistoryAdminResponseModel
   */
  blacklistStatusDate?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationPaymentHistoryAdminResponseModel
   */
  blacklistStatusDateCleared?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationPaymentHistoryAdminResponseModel
   */
  watchlistDate?: string;
  /**
   *
   * @type {IdModel}
   * @memberof ApplicationPaymentHistoryAdminResponseModel
   */
  player: IdModel;
  /**
   *
   * @type {AuditModel}
   * @memberof ApplicationPaymentHistoryAdminResponseModel
   */
  audit: AuditModel;
  /**
   *
   * @type {NotificationModel}
   * @memberof ApplicationPaymentHistoryAdminResponseModel
   */
  notifications?: NotificationModel;
  /**
   *
   * @type {number}
   * @memberof ApplicationPaymentHistoryAdminResponseModel
   */
  transfersQuantity?: number;
  /**
   *
   * @type {number}
   * @memberof ApplicationPaymentHistoryAdminResponseModel
   */
  adminTransfersQuantity?: number;
  /**
   *
   * @type {Array<WithdrawPaymentHistoryModel>}
   * @memberof ApplicationPaymentHistoryAdminResponseModel
   */
  paymentHistory?: Array<WithdrawPaymentHistoryModel>;
}
/**
 *
 * @export
 * @interface ApplicationPaymentHistoryAdminResponseModelAllOf
 */
export interface ApplicationPaymentHistoryAdminResponseModelAllOf {
  /**
   *
   * @type {ApplicationPaymentHistoryAdminResponseModelAllOfTeam}
   * @memberof ApplicationPaymentHistoryAdminResponseModelAllOf
   */
  team?: ApplicationPaymentHistoryAdminResponseModelAllOfTeam;
  /**
   *
   * @type {ApplicationAdminResponseModelAllOfPair}
   * @memberof ApplicationPaymentHistoryAdminResponseModelAllOf
   */
  pair?: ApplicationAdminResponseModelAllOfPair;
  /**
   *
   * @type {DeclinedPaymentModel}
   * @memberof ApplicationPaymentHistoryAdminResponseModelAllOf
   */
  declinedPayment?: DeclinedPaymentModel;
  /**
   *
   * @type {number}
   * @memberof ApplicationPaymentHistoryAdminResponseModelAllOf
   */
  pairingNumber?: number;
  /**
   *
   * @type {WithdrawalResponseAdminModel}
   * @memberof ApplicationPaymentHistoryAdminResponseModelAllOf
   */
  withdrawal?: WithdrawalResponseAdminModel;
  /**
   *
   * @type {ApplicationProgressionEnum}
   * @memberof ApplicationPaymentHistoryAdminResponseModelAllOf
   */
  progression: ApplicationProgressionEnum;
  /**
   *
   * @type {string}
   * @memberof ApplicationPaymentHistoryAdminResponseModelAllOf
   */
  reviewDate?: string;
  /**
   * Auth0 id of user who last made transition to reviewed state (enabled reviewDate field)
   * @type {string}
   * @memberof ApplicationPaymentHistoryAdminResponseModelAllOf
   */
  reviewedBy?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationPaymentHistoryAdminResponseModelAllOf
   */
  blacklistStatusDate?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationPaymentHistoryAdminResponseModelAllOf
   */
  blacklistStatusDateCleared?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationPaymentHistoryAdminResponseModelAllOf
   */
  watchlistDate?: string;
  /**
   *
   * @type {IdModel}
   * @memberof ApplicationPaymentHistoryAdminResponseModelAllOf
   */
  player: IdModel;
  /**
   *
   * @type {AuditModel}
   * @memberof ApplicationPaymentHistoryAdminResponseModelAllOf
   */
  audit: AuditModel;
  /**
   *
   * @type {NotificationModel}
   * @memberof ApplicationPaymentHistoryAdminResponseModelAllOf
   */
  notifications?: NotificationModel;
  /**
   *
   * @type {number}
   * @memberof ApplicationPaymentHistoryAdminResponseModelAllOf
   */
  transfersQuantity?: number;
  /**
   *
   * @type {number}
   * @memberof ApplicationPaymentHistoryAdminResponseModelAllOf
   */
  adminTransfersQuantity?: number;
  /**
   *
   * @type {Array<WithdrawPaymentHistoryModel>}
   * @memberof ApplicationPaymentHistoryAdminResponseModelAllOf
   */
  paymentHistory?: Array<WithdrawPaymentHistoryModel>;
}
/**
 *
 * @export
 * @interface ApplicationPaymentHistoryAdminResponseModelAllOfTeam
 */
export interface ApplicationPaymentHistoryAdminResponseModelAllOfTeam {
  /**
   *
   * @type {Array<ApplicationPaymentHistoryResponseModel>}
   * @memberof ApplicationPaymentHistoryAdminResponseModelAllOfTeam
   */
  applications?: Array<ApplicationPaymentHistoryResponseModel>;
  /**
   *
   * @type {number}
   * @memberof ApplicationPaymentHistoryAdminResponseModelAllOfTeam
   */
  id: number;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationPaymentHistoryAdminResponseModelAllOfTeam
   */
  complete: boolean;
  /**
   *
   * @type {ApplicationAdminResponseModelAllOfTeamAudit}
   * @memberof ApplicationPaymentHistoryAdminResponseModelAllOfTeam
   */
  audit?: ApplicationAdminResponseModelAllOfTeamAudit;
}
/**
 *
 * @export
 * @interface ApplicationPaymentHistoryModel
 */
export interface ApplicationPaymentHistoryModel {
  /**
   *
   * @type {IdModel}
   * @memberof ApplicationPaymentHistoryModel
   */
  championship: IdModel;
  /**
   *
   * @type {ApplicationBasePlayerModelPrimaryContactInfo}
   * @memberof ApplicationPaymentHistoryModel
   */
  primaryContactInfo: ApplicationBasePlayerModelPrimaryContactInfo;
  /**
   *
   * @type {ApplicationBasePlayerModelGuardianContactInfo}
   * @memberof ApplicationPaymentHistoryModel
   */
  guardianContactInfo?: ApplicationBasePlayerModelGuardianContactInfo;
  /**
   *
   * @type {ProfilePlayerModelAllOfEmergencyContact}
   * @memberof ApplicationPaymentHistoryModel
   */
  emergencyContact?: ProfilePlayerModelAllOfEmergencyContact;
  /**
   *
   * @type {GenderEnum}
   * @memberof ApplicationPaymentHistoryModel
   */
  gender: GenderEnum;
  /**
   *
   * @type {string}
   * @memberof ApplicationPaymentHistoryModel
   */
  dob: string;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationPaymentHistoryModel
   */
  playerMinor?: boolean;
  /**
   *
   * @type {PlayerStatusEnum}
   * @memberof ApplicationPaymentHistoryModel
   */
  playerStatus: PlayerStatusEnum;
  /**
   *
   * @type {CountryAddModel}
   * @memberof ApplicationPaymentHistoryModel
   */
  countryOfCitizenship?: CountryAddModel;
  /**
   *
   * @type {EthnicityEnum}
   * @memberof ApplicationPaymentHistoryModel
   */
  ethnicity?: EthnicityEnum;
  /**
   *
   * @type {number}
   * @memberof ApplicationPaymentHistoryModel
   */
  highSchoolGraduationYear?: number;
  /**
   *
   * @type {CountryAddModel}
   * @memberof ApplicationPaymentHistoryModel
   */
  preferredCountry?: CountryAddModel;
  /**
   *
   * @type {string}
   * @memberof ApplicationPaymentHistoryModel
   */
  preferredCity?: string;
  /**
   *
   * @type {StateAddModel}
   * @memberof ApplicationPaymentHistoryModel
   */
  preferredState?: StateAddModel;
  /**
   *
   * @type {ApplicationBasePlayerModelHandicap}
   * @memberof ApplicationPaymentHistoryModel
   */
  handicap?: ApplicationBasePlayerModelHandicap;
  /**
   *
   * @type {Array<IdModel>}
   * @memberof ApplicationPaymentHistoryModel
   */
  exemption?: Array<IdModel>;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationPaymentHistoryModel
   */
  optInPrivacy?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationPaymentHistoryModel
   */
  unknownHandicapID?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationPaymentHistoryModel
   */
  optInWagr?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationPaymentHistoryModel
   */
  antiDopingViolation?: boolean;
  /**
   *
   * @type {ApplicationDisabilityTypeEnum}
   * @memberof ApplicationPaymentHistoryModel
   */
  disabilityType?: ApplicationDisabilityTypeEnum;
  /**
   *
   * @type {string}
   * @memberof ApplicationPaymentHistoryModel
   */
  wR4GDPass?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationPaymentHistoryModel
   */
  disabilityDesc?: string;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationPaymentHistoryModel
   */
  legalGuardian?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationPaymentHistoryModel
   */
  playGolfInCart?: boolean;
  /**
   *
   * @type {string}
   * @memberof ApplicationPaymentHistoryModel
   */
  virtusII1Number?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationPaymentHistoryModel
   */
  adaptiveEquipDesc?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationPaymentHistoryModel
   */
  compPlayingRecord?: string;
  /**
   *
   * @type {Array<number>}
   * @memberof ApplicationPaymentHistoryModel
   */
  fileIds?: Array<number>;
  /**
   *
   * @type {string}
   * @memberof ApplicationPaymentHistoryModel
   */
  entryDate?: string;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationPaymentHistoryModel
   */
  surveyEmailSent?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationPaymentHistoryModel
   */
  declinedPaymentEmailSent?: boolean;
  /**
   *
   * @type {Array<ApplicationBaseAdminModelAllOfQualifyingSites>}
   * @memberof ApplicationPaymentHistoryModel
   */
  qualifyingSites?: Array<ApplicationBaseAdminModelAllOfQualifyingSites>;
  /**
   *
   * @type {Array<ApplicationPlayerModelAllOfWaitList>}
   * @memberof ApplicationPaymentHistoryModel
   */
  waitList?: Array<ApplicationPlayerModelAllOfWaitList>;
  /**
   *
   * @type {string}
   * @memberof ApplicationPaymentHistoryModel
   */
  wagrRank?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationPaymentHistoryModel
   */
  rank?: string;
  /**
   *
   * @type {QualifyingStatusEnum}
   * @memberof ApplicationPaymentHistoryModel
   */
  qualifyingStatus?: QualifyingStatusEnum;
  /**
   *
   * @type {string}
   * @memberof ApplicationPaymentHistoryModel
   */
  eligibleReinstatementDate?: string;
  /**
   *
   * @type {number}
   * @memberof ApplicationPaymentHistoryModel
   */
  id: number;
  /**
   *
   * @type {PaymentDataRequestModel}
   * @memberof ApplicationPaymentHistoryModel
   */
  payment?: PaymentDataRequestModel;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationPaymentHistoryModel
   */
  adaRequest?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationPaymentHistoryModel
   */
  notablePlayer?: boolean;
  /**
   *
   * @type {Array<ApplicationBaseAdminModelAllOfQueueReasons>}
   * @memberof ApplicationPaymentHistoryModel
   */
  queueReasons?: Array<ApplicationBaseAdminModelAllOfQueueReasons>;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationPaymentHistoryModel
   */
  markAsReviewed?: boolean;
  /**
   *
   * @type {ApplicationBaseAdminModelScoring}
   * @memberof ApplicationPaymentHistoryModel
   */
  scoring?: ApplicationBaseAdminModelScoring;
  /**
   *
   * @type {BlacklistStatusEnum}
   * @memberof ApplicationPaymentHistoryModel
   */
  blacklistStatus?: BlacklistStatusEnum;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationPaymentHistoryModel
   */
  blacklistSendEmail?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationPaymentHistoryModel
   */
  watchlist?: boolean;
  /**
   *
   * @type {string}
   * @memberof ApplicationPaymentHistoryModel
   */
  watchlistReason?: string;
  /**
   *
   * @type {ContactInfoModel}
   * @memberof ApplicationPaymentHistoryModel
   */
  secondaryContactInfo?: ContactInfoModel;
  /**
   *
   * @type {IdModel}
   * @memberof ApplicationPaymentHistoryModel
   */
  submittedByPlayer?: IdModel;
  /**
   *
   * @type {ApplicationAdminResponseModelAllOfTeam}
   * @memberof ApplicationPaymentHistoryModel
   */
  team?: ApplicationAdminResponseModelAllOfTeam;
  /**
   *
   * @type {ApplicationAdminResponseModelAllOfPair}
   * @memberof ApplicationPaymentHistoryModel
   */
  pair?: ApplicationAdminResponseModelAllOfPair;
  /**
   *
   * @type {DeclinedPaymentModel}
   * @memberof ApplicationPaymentHistoryModel
   */
  declinedPayment?: DeclinedPaymentModel;
  /**
   *
   * @type {number}
   * @memberof ApplicationPaymentHistoryModel
   */
  pairingNumber?: number;
  /**
   *
   * @type {WithdrawalResponseAdminModel}
   * @memberof ApplicationPaymentHistoryModel
   */
  withdrawal?: WithdrawalResponseAdminModel;
  /**
   *
   * @type {ApplicationProgressionEnum}
   * @memberof ApplicationPaymentHistoryModel
   */
  progression: ApplicationProgressionEnum;
  /**
   *
   * @type {string}
   * @memberof ApplicationPaymentHistoryModel
   */
  reviewDate?: string;
  /**
   * Auth0 id of user who last made transition to reviewed state (enabled reviewDate field)
   * @type {string}
   * @memberof ApplicationPaymentHistoryModel
   */
  reviewedBy?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationPaymentHistoryModel
   */
  blacklistStatusDate?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationPaymentHistoryModel
   */
  blacklistStatusDateCleared?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationPaymentHistoryModel
   */
  watchlistDate?: string;
  /**
   *
   * @type {IdModel}
   * @memberof ApplicationPaymentHistoryModel
   */
  player: IdModel;
  /**
   *
   * @type {AuditModel}
   * @memberof ApplicationPaymentHistoryModel
   */
  audit: AuditModel;
  /**
   *
   * @type {NotificationModel}
   * @memberof ApplicationPaymentHistoryModel
   */
  notifications?: NotificationModel;
  /**
   *
   * @type {number}
   * @memberof ApplicationPaymentHistoryModel
   */
  transfersQuantity?: number;
  /**
   *
   * @type {number}
   * @memberof ApplicationPaymentHistoryModel
   */
  adminTransfersQuantity?: number;
  /**
   *
   * @type {Array<PaymentHistoryModel>}
   * @memberof ApplicationPaymentHistoryModel
   */
  paymentHistory?: Array<PaymentHistoryModel>;
}
/**
 *
 * @export
 * @interface ApplicationPaymentHistoryModelAllOf
 */
export interface ApplicationPaymentHistoryModelAllOf {
  /**
   *
   * @type {Array<PaymentHistoryModel>}
   * @memberof ApplicationPaymentHistoryModelAllOf
   */
  paymentHistory?: Array<PaymentHistoryModel>;
}
/**
 *
 * @export
 * @interface ApplicationPaymentHistoryResponseModel
 */
export interface ApplicationPaymentHistoryResponseModel {
  /**
   *
   * @type {IdModel}
   * @memberof ApplicationPaymentHistoryResponseModel
   */
  championship: IdModel;
  /**
   *
   * @type {ApplicationBasePlayerModelPrimaryContactInfo}
   * @memberof ApplicationPaymentHistoryResponseModel
   */
  primaryContactInfo: ApplicationBasePlayerModelPrimaryContactInfo;
  /**
   *
   * @type {ApplicationBasePlayerModelGuardianContactInfo}
   * @memberof ApplicationPaymentHistoryResponseModel
   */
  guardianContactInfo?: ApplicationBasePlayerModelGuardianContactInfo;
  /**
   *
   * @type {ProfilePlayerModelAllOfEmergencyContact}
   * @memberof ApplicationPaymentHistoryResponseModel
   */
  emergencyContact?: ProfilePlayerModelAllOfEmergencyContact;
  /**
   *
   * @type {GenderEnum}
   * @memberof ApplicationPaymentHistoryResponseModel
   */
  gender: GenderEnum;
  /**
   *
   * @type {string}
   * @memberof ApplicationPaymentHistoryResponseModel
   */
  dob: string;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationPaymentHistoryResponseModel
   */
  playerMinor?: boolean;
  /**
   *
   * @type {PlayerStatusEnum}
   * @memberof ApplicationPaymentHistoryResponseModel
   */
  playerStatus: PlayerStatusEnum;
  /**
   *
   * @type {CountryAddModel}
   * @memberof ApplicationPaymentHistoryResponseModel
   */
  countryOfCitizenship?: CountryAddModel;
  /**
   *
   * @type {EthnicityEnum}
   * @memberof ApplicationPaymentHistoryResponseModel
   */
  ethnicity?: EthnicityEnum;
  /**
   *
   * @type {number}
   * @memberof ApplicationPaymentHistoryResponseModel
   */
  highSchoolGraduationYear?: number;
  /**
   *
   * @type {CountryAddModel}
   * @memberof ApplicationPaymentHistoryResponseModel
   */
  preferredCountry?: CountryAddModel;
  /**
   *
   * @type {string}
   * @memberof ApplicationPaymentHistoryResponseModel
   */
  preferredCity?: string;
  /**
   *
   * @type {StateAddModel}
   * @memberof ApplicationPaymentHistoryResponseModel
   */
  preferredState?: StateAddModel;
  /**
   *
   * @type {ApplicationBasePlayerModelHandicap}
   * @memberof ApplicationPaymentHistoryResponseModel
   */
  handicap?: ApplicationBasePlayerModelHandicap;
  /**
   *
   * @type {Array<IdModel>}
   * @memberof ApplicationPaymentHistoryResponseModel
   */
  exemption?: Array<IdModel>;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationPaymentHistoryResponseModel
   */
  optInPrivacy?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationPaymentHistoryResponseModel
   */
  unknownHandicapID?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationPaymentHistoryResponseModel
   */
  optInWagr?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationPaymentHistoryResponseModel
   */
  antiDopingViolation?: boolean;
  /**
   *
   * @type {ApplicationDisabilityTypeEnum}
   * @memberof ApplicationPaymentHistoryResponseModel
   */
  disabilityType?: ApplicationDisabilityTypeEnum;
  /**
   *
   * @type {string}
   * @memberof ApplicationPaymentHistoryResponseModel
   */
  wR4GDPass?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationPaymentHistoryResponseModel
   */
  disabilityDesc?: string;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationPaymentHistoryResponseModel
   */
  legalGuardian?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationPaymentHistoryResponseModel
   */
  playGolfInCart?: boolean;
  /**
   *
   * @type {string}
   * @memberof ApplicationPaymentHistoryResponseModel
   */
  virtusII1Number?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationPaymentHistoryResponseModel
   */
  adaptiveEquipDesc?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationPaymentHistoryResponseModel
   */
  compPlayingRecord?: string;
  /**
   *
   * @type {Array<number>}
   * @memberof ApplicationPaymentHistoryResponseModel
   */
  fileIds?: Array<number>;
  /**
   *
   * @type {string}
   * @memberof ApplicationPaymentHistoryResponseModel
   */
  entryDate?: string;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationPaymentHistoryResponseModel
   */
  surveyEmailSent?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationPaymentHistoryResponseModel
   */
  declinedPaymentEmailSent?: boolean;
  /**
   *
   * @type {Array<ApplicationPlayerModelResponseAllOfQualifyingSites>}
   * @memberof ApplicationPaymentHistoryResponseModel
   */
  qualifyingSites?: Array<ApplicationPlayerModelResponseAllOfQualifyingSites>;
  /**
   *
   * @type {Array<ApplicationPlayerModelAllOfWaitList>}
   * @memberof ApplicationPaymentHistoryResponseModel
   */
  waitList?: Array<ApplicationPlayerModelAllOfWaitList>;
  /**
   *
   * @type {string}
   * @memberof ApplicationPaymentHistoryResponseModel
   */
  confirmationPagePath?: string;
  /**
   *
   * @type {number}
   * @memberof ApplicationPaymentHistoryResponseModel
   */
  id: number;
  /**
   *
   * @type {ApplicationProgressionEnum}
   * @memberof ApplicationPaymentHistoryResponseModel
   */
  progression: ApplicationProgressionEnum;
  /**
   *
   * @type {Array<ApplicationResponseModelAllOfQueueReasons>}
   * @memberof ApplicationPaymentHistoryResponseModel
   */
  queueReasons?: Array<ApplicationResponseModelAllOfQueueReasons>;
  /**
   *
   * @type {PaymentDataPlayerModel}
   * @memberof ApplicationPaymentHistoryResponseModel
   */
  payment?: PaymentDataPlayerModel;
  /**
   *
   * @type {ApplicationResponseModelAllOfTeam}
   * @memberof ApplicationPaymentHistoryResponseModel
   */
  team?: ApplicationResponseModelAllOfTeam;
  /**
   *
   * @type {IdModel}
   * @memberof ApplicationPaymentHistoryResponseModel
   */
  player: IdModel;
  /**
   *
   * @type {number}
   * @memberof ApplicationPaymentHistoryResponseModel
   */
  pairingNumber?: number;
  /**
   *
   * @type {number}
   * @memberof ApplicationPaymentHistoryResponseModel
   */
  transfersQuantity?: number;
  /**
   *
   * @type {NotificationModel}
   * @memberof ApplicationPaymentHistoryResponseModel
   */
  notifications?: NotificationModel;
  /**
   *
   * @type {Array<ApplicationResponseModelAllOfFiles>}
   * @memberof ApplicationPaymentHistoryResponseModel
   */
  files?: Array<ApplicationResponseModelAllOfFiles>;
  /**
   *
   * @type {DeclinedPaymentModel}
   * @memberof ApplicationPaymentHistoryResponseModel
   */
  declinedPayment?: DeclinedPaymentModel;
  /**
   *
   * @type {Array<WithdrawPaymentHistoryModel>}
   * @memberof ApplicationPaymentHistoryResponseModel
   */
  paymentHistory?: Array<WithdrawPaymentHistoryModel>;
}
/**
 *
 * @export
 * @interface ApplicationPaymentHistoryResponseModelAllOf
 */
export interface ApplicationPaymentHistoryResponseModelAllOf {
  /**
   *
   * @type {Array<WithdrawPaymentHistoryModel>}
   * @memberof ApplicationPaymentHistoryResponseModelAllOf
   */
  paymentHistory?: Array<WithdrawPaymentHistoryModel>;
}
/**
 *
 * @export
 * @interface ApplicationPaymentProcessFetchModel
 */
export interface ApplicationPaymentProcessFetchModel {
  /**
   *
   * @type {number}
   * @memberof ApplicationPaymentProcessFetchModel
   */
  id?: number;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationPaymentProcessFetchModel
   */
  active?: boolean;
  /**
   *
   * @type {ChampionshipFetchModel}
   * @memberof ApplicationPaymentProcessFetchModel
   */
  championship?: ChampionshipFetchModel;
  /**
   *
   * @type {ApplicationFetchModelPrimaryContactInfo}
   * @memberof ApplicationPaymentProcessFetchModel
   */
  primaryContactInfo?: ApplicationFetchModelPrimaryContactInfo;
  /**
   *
   * @type {ApplicationFetchModelGuardianContactInfo}
   * @memberof ApplicationPaymentProcessFetchModel
   */
  guardianContactInfo?: ApplicationFetchModelGuardianContactInfo;
  /**
   *
   * @type {GenderEnum}
   * @memberof ApplicationPaymentProcessFetchModel
   */
  gender?: GenderEnum;
  /**
   *
   * @type {string}
   * @memberof ApplicationPaymentProcessFetchModel
   */
  dob?: string;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationPaymentProcessFetchModel
   */
  playerMinor?: boolean;
  /**
   *
   * @type {PlayerStatusEnum}
   * @memberof ApplicationPaymentProcessFetchModel
   */
  playerStatus?: PlayerStatusEnum;
  /**
   *
   * @type {CountryAddModel}
   * @memberof ApplicationPaymentProcessFetchModel
   */
  countryOfCitizenship?: CountryAddModel;
  /**
   *
   * @type {EthnicityEnum}
   * @memberof ApplicationPaymentProcessFetchModel
   */
  ethnicity?: EthnicityEnum;
  /**
   *
   * @type {number}
   * @memberof ApplicationPaymentProcessFetchModel
   */
  highSchoolGraduationYear?: number;
  /**
   *
   * @type {CountryAddModel}
   * @memberof ApplicationPaymentProcessFetchModel
   */
  preferredCountry?: CountryAddModel;
  /**
   *
   * @type {string}
   * @memberof ApplicationPaymentProcessFetchModel
   */
  preferredCity?: string;
  /**
   *
   * @type {StateAddModel}
   * @memberof ApplicationPaymentProcessFetchModel
   */
  preferredState?: StateAddModel;
  /**
   *
   * @type {ApplicationPaymentProcessFetchModelHandicap}
   * @memberof ApplicationPaymentProcessFetchModel
   */
  handicap?: ApplicationPaymentProcessFetchModelHandicap;
  /**
   *
   * @type {Array<ExemptionDeleteFetchModelChampionship>}
   * @memberof ApplicationPaymentProcessFetchModel
   */
  exemption?: Array<ExemptionDeleteFetchModelChampionship>;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationPaymentProcessFetchModel
   */
  optInPrivacy?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationPaymentProcessFetchModel
   */
  unknownHandicapID?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationPaymentProcessFetchModel
   */
  optInWagr?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationPaymentProcessFetchModel
   */
  antiDopingViolation?: boolean;
  /**
   *
   * @type {Array<ApplicationPaymentProcessFetchModelQualifyingSites>}
   * @memberof ApplicationPaymentProcessFetchModel
   */
  qualifyingSites?: Array<ApplicationPaymentProcessFetchModelQualifyingSites>;
  /**
   *
   * @type {Array<ApplicationFetchModelWaitList>}
   * @memberof ApplicationPaymentProcessFetchModel
   */
  waitList?: Array<ApplicationFetchModelWaitList>;
  /**
   *
   * @type {string}
   * @memberof ApplicationPaymentProcessFetchModel
   */
  wagrRank?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationPaymentProcessFetchModel
   */
  rank?: string;
  /**
   *
   * @type {QualifyingStatusEnum}
   * @memberof ApplicationPaymentProcessFetchModel
   */
  qualifyingStatus?: QualifyingStatusEnum;
  /**
   *
   * @type {string}
   * @memberof ApplicationPaymentProcessFetchModel
   */
  eligibleReinstatementDate?: string;
  /**
   *
   * @type {PaymentDataRequestModel}
   * @memberof ApplicationPaymentProcessFetchModel
   */
  payment?: PaymentDataRequestModel;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationPaymentProcessFetchModel
   */
  adaRequest?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationPaymentProcessFetchModel
   */
  notablePlayer?: boolean;
  /**
   *
   * @type {Array<ApplicationPaymentProcessFetchModelQueueReasons>}
   * @memberof ApplicationPaymentProcessFetchModel
   */
  queueReasons?: Array<ApplicationPaymentProcessFetchModelQueueReasons>;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationPaymentProcessFetchModel
   */
  markAsReviewed?: boolean;
  /**
   *
   * @type {ApplicationBaseAdminModelScoring}
   * @memberof ApplicationPaymentProcessFetchModel
   */
  scoring?: ApplicationBaseAdminModelScoring;
  /**
   *
   * @type {BlacklistStatusEnum}
   * @memberof ApplicationPaymentProcessFetchModel
   */
  blacklistStatus?: BlacklistStatusEnum;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationPaymentProcessFetchModel
   */
  blacklistSendEmail?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationPaymentProcessFetchModel
   */
  watchlist?: boolean;
  /**
   *
   * @type {string}
   * @memberof ApplicationPaymentProcessFetchModel
   */
  watchlistReason?: string;
  /**
   *
   * @type {ContactInfoModel}
   * @memberof ApplicationPaymentProcessFetchModel
   */
  secondaryContactInfo?: ContactInfoModel;
  /**
   *
   * @type {IdModel}
   * @memberof ApplicationPaymentProcessFetchModel
   */
  submittedByPlayer?: IdModel;
  /**
   *
   * @type {ApplicationFetchModelTeam}
   * @memberof ApplicationPaymentProcessFetchModel
   */
  team?: ApplicationFetchModelTeam;
  /**
   *
   * @type {ApplicationFetchModelPair}
   * @memberof ApplicationPaymentProcessFetchModel
   */
  pair?: ApplicationFetchModelPair;
  /**
   *
   * @type {number}
   * @memberof ApplicationPaymentProcessFetchModel
   */
  pairingNumber?: number;
  /**
   *
   * @type {WithdrawalResponseAdminModel}
   * @memberof ApplicationPaymentProcessFetchModel
   */
  withdrawal?: WithdrawalResponseAdminModel;
  /**
   *
   * @type {ApplicationProgressionEnum}
   * @memberof ApplicationPaymentProcessFetchModel
   */
  progression?: ApplicationProgressionEnum;
  /**
   *
   * @type {string}
   * @memberof ApplicationPaymentProcessFetchModel
   */
  reviewDate?: string;
  /**
   * Auth0 id of user who last made transition to reviewed state (enabled reviewDate field)
   * @type {string}
   * @memberof ApplicationPaymentProcessFetchModel
   */
  reviewedBy?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationPaymentProcessFetchModel
   */
  blacklistStatusDate?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationPaymentProcessFetchModel
   */
  blacklistStatusDateCleared?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationPaymentProcessFetchModel
   */
  watchlistDate?: string;
  /**
   *
   * @type {ProfileAdminModel}
   * @memberof ApplicationPaymentProcessFetchModel
   */
  player?: ProfileAdminModel;
  /**
   *
   * @type {AuditModel}
   * @memberof ApplicationPaymentProcessFetchModel
   */
  audit?: AuditModel;
  /**
   *
   * @type {NotificationModel}
   * @memberof ApplicationPaymentProcessFetchModel
   */
  notifications?: NotificationModel;
  /**
   *
   * @type {number}
   * @memberof ApplicationPaymentProcessFetchModel
   */
  transfersQuantity?: number;
}
/**
 *
 * @export
 * @interface ApplicationPaymentProcessFetchModelHandicap
 */
export interface ApplicationPaymentProcessFetchModelHandicap {
  /**
   *
   * @type {string}
   * @memberof ApplicationPaymentProcessFetchModelHandicap
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationPaymentProcessFetchModelHandicap
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationPaymentProcessFetchModelHandicap
   */
  number?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationPaymentProcessFetchModelHandicap
   */
  clubName?: string;
  /**
   *
   * @type {CountryAddModel}
   * @memberof ApplicationPaymentProcessFetchModelHandicap
   */
  clubCountry?: CountryAddModel;
  /**
   *
   * @type {string}
   * @memberof ApplicationPaymentProcessFetchModelHandicap
   */
  governingAssociation?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationPaymentProcessFetchModelHandicap
   */
  golfAssociation?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationPaymentProcessFetchModelHandicap
   */
  index?: string;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationPaymentProcessFetchModelHandicap
   */
  betterThanScratch?: boolean;
  /**
   *
   * @type {ApplicationPaymentProcessFetchModelHandicapVerifier}
   * @memberof ApplicationPaymentProcessFetchModelHandicap
   */
  verifier?: ApplicationPaymentProcessFetchModelHandicapVerifier;
  /**
   *
   * @type {StateAddModel}
   * @memberof ApplicationPaymentProcessFetchModelHandicap
   */
  clubState?: StateAddModel;
  /**
   *
   * @type {string}
   * @memberof ApplicationPaymentProcessFetchModelHandicap
   */
  clubCity?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationPaymentProcessFetchModelHandicap
   */
  memberNumber?: string;
}
/**
 *
 * @export
 * @interface ApplicationPaymentProcessFetchModelHandicapVerifier
 */
export interface ApplicationPaymentProcessFetchModelHandicapVerifier {
  /**
   *
   * @type {string}
   * @memberof ApplicationPaymentProcessFetchModelHandicapVerifier
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationPaymentProcessFetchModelHandicapVerifier
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationPaymentProcessFetchModelHandicapVerifier
   */
  phoneNumber?: string;
}
/**
 *
 * @export
 * @interface ApplicationPaymentProcessFetchModelQualifyingSite
 */
export interface ApplicationPaymentProcessFetchModelQualifyingSite {
  /**
   *
   * @type {QualifyingSiteSpotsAvailable}
   * @memberof ApplicationPaymentProcessFetchModelQualifyingSite
   */
  spotsAvailability?: QualifyingSiteSpotsAvailable;
}
/**
 *
 * @export
 * @interface ApplicationPaymentProcessFetchModelQualifyingSites
 */
export interface ApplicationPaymentProcessFetchModelQualifyingSites {
  /**
   *
   * @type {number}
   * @memberof ApplicationPaymentProcessFetchModelQualifyingSites
   */
  position?: number;
  /**
   *
   * @type {QualifyingSiteFinalizationEnum}
   * @memberof ApplicationPaymentProcessFetchModelQualifyingSites
   */
  finalizedFinalQSite?: QualifyingSiteFinalizationEnum;
  /**
   *
   * @type {ApplicationPaymentProcessFetchModelQualifyingSite}
   * @memberof ApplicationPaymentProcessFetchModelQualifyingSites
   */
  qualifyingSite?: ApplicationPaymentProcessFetchModelQualifyingSite;
}
/**
 *
 * @export
 * @interface ApplicationPaymentProcessFetchModelQueueReasons
 */
export interface ApplicationPaymentProcessFetchModelQueueReasons {
  /**
   *
   * @type {string}
   * @memberof ApplicationPaymentProcessFetchModelQueueReasons
   */
  comment?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationPaymentProcessFetchModelQueueReasons
   */
  adminUserId?: string;
  /**
   *
   * @type {IdModel}
   * @memberof ApplicationPaymentProcessFetchModelQueueReasons
   */
  queueReason?: IdModel;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum ApplicationPlayerFilters {
  PLAYERACTIVEAPPLICATIONS = 'PLAYER_ACTIVE_APPLICATIONS',
}

/**
 *
 * @export
 * @interface ApplicationPlayerModel
 */
export interface ApplicationPlayerModel {
  /**
   *
   * @type {IdModel}
   * @memberof ApplicationPlayerModel
   */
  championship: IdModel;
  /**
   *
   * @type {ApplicationBasePlayerModelPrimaryContactInfo}
   * @memberof ApplicationPlayerModel
   */
  primaryContactInfo: ApplicationBasePlayerModelPrimaryContactInfo;
  /**
   *
   * @type {ApplicationBasePlayerModelGuardianContactInfo}
   * @memberof ApplicationPlayerModel
   */
  guardianContactInfo?: ApplicationBasePlayerModelGuardianContactInfo;
  /**
   *
   * @type {ProfilePlayerModelAllOfEmergencyContact}
   * @memberof ApplicationPlayerModel
   */
  emergencyContact?: ProfilePlayerModelAllOfEmergencyContact;
  /**
   *
   * @type {GenderEnum}
   * @memberof ApplicationPlayerModel
   */
  gender: GenderEnum;
  /**
   *
   * @type {string}
   * @memberof ApplicationPlayerModel
   */
  dob: string;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationPlayerModel
   */
  playerMinor?: boolean;
  /**
   *
   * @type {PlayerStatusEnum}
   * @memberof ApplicationPlayerModel
   */
  playerStatus: PlayerStatusEnum;
  /**
   *
   * @type {CountryAddModel}
   * @memberof ApplicationPlayerModel
   */
  countryOfCitizenship?: CountryAddModel;
  /**
   *
   * @type {EthnicityEnum}
   * @memberof ApplicationPlayerModel
   */
  ethnicity?: EthnicityEnum;
  /**
   *
   * @type {number}
   * @memberof ApplicationPlayerModel
   */
  highSchoolGraduationYear?: number;
  /**
   *
   * @type {CountryAddModel}
   * @memberof ApplicationPlayerModel
   */
  preferredCountry?: CountryAddModel;
  /**
   *
   * @type {string}
   * @memberof ApplicationPlayerModel
   */
  preferredCity?: string;
  /**
   *
   * @type {StateAddModel}
   * @memberof ApplicationPlayerModel
   */
  preferredState?: StateAddModel;
  /**
   *
   * @type {ApplicationBasePlayerModelHandicap}
   * @memberof ApplicationPlayerModel
   */
  handicap?: ApplicationBasePlayerModelHandicap;
  /**
   *
   * @type {Array<IdModel>}
   * @memberof ApplicationPlayerModel
   */
  exemption?: Array<IdModel>;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationPlayerModel
   */
  optInPrivacy?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationPlayerModel
   */
  unknownHandicapID?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationPlayerModel
   */
  optInWagr?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationPlayerModel
   */
  antiDopingViolation?: boolean;
  /**
   *
   * @type {ApplicationDisabilityTypeEnum}
   * @memberof ApplicationPlayerModel
   */
  disabilityType?: ApplicationDisabilityTypeEnum;
  /**
   *
   * @type {string}
   * @memberof ApplicationPlayerModel
   */
  wR4GDPass?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationPlayerModel
   */
  disabilityDesc?: string;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationPlayerModel
   */
  legalGuardian?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationPlayerModel
   */
  playGolfInCart?: boolean;
  /**
   *
   * @type {string}
   * @memberof ApplicationPlayerModel
   */
  virtusII1Number?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationPlayerModel
   */
  adaptiveEquipDesc?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationPlayerModel
   */
  compPlayingRecord?: string;
  /**
   *
   * @type {Array<number>}
   * @memberof ApplicationPlayerModel
   */
  fileIds?: Array<number>;
  /**
   *
   * @type {string}
   * @memberof ApplicationPlayerModel
   */
  entryDate?: string;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationPlayerModel
   */
  surveyEmailSent?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationPlayerModel
   */
  declinedPaymentEmailSent?: boolean;
  /**
   *
   * @type {Array<ApplicationPlayerModelAllOfQualifyingSites>}
   * @memberof ApplicationPlayerModel
   */
  qualifyingSites?: Array<ApplicationPlayerModelAllOfQualifyingSites>;
  /**
   *
   * @type {Array<ApplicationPlayerModelAllOfWaitList>}
   * @memberof ApplicationPlayerModel
   */
  waitList?: Array<ApplicationPlayerModelAllOfWaitList>;
}
/**
 *
 * @export
 * @interface ApplicationPlayerModelAllOf
 */
export interface ApplicationPlayerModelAllOf {
  /**
   *
   * @type {Array<ApplicationPlayerModelAllOfQualifyingSites>}
   * @memberof ApplicationPlayerModelAllOf
   */
  qualifyingSites?: Array<ApplicationPlayerModelAllOfQualifyingSites>;
  /**
   *
   * @type {Array<ApplicationPlayerModelAllOfWaitList>}
   * @memberof ApplicationPlayerModelAllOf
   */
  waitList?: Array<ApplicationPlayerModelAllOfWaitList>;
}
/**
 *
 * @export
 * @interface ApplicationPlayerModelAllOfQualifyingSites
 */
export interface ApplicationPlayerModelAllOfQualifyingSites {
  /**
   *
   * @type {number}
   * @memberof ApplicationPlayerModelAllOfQualifyingSites
   */
  position?: number;
  /**
   *
   * @type {IdModel}
   * @memberof ApplicationPlayerModelAllOfQualifyingSites
   */
  qualifyingSite?: IdModel;
}
/**
 *
 * @export
 * @interface ApplicationPlayerModelAllOfWaitList
 */
export interface ApplicationPlayerModelAllOfWaitList {
  /**
   *
   * @type {IdModel}
   * @memberof ApplicationPlayerModelAllOfWaitList
   */
  qualifyingSite: IdModel;
  /**
   *
   * @type {WaitListAlternateTypeEnum}
   * @memberof ApplicationPlayerModelAllOfWaitList
   */
  alternateType?: WaitListAlternateTypeEnum;
}
/**
 *
 * @export
 * @interface ApplicationPlayerModelResponse
 */
export interface ApplicationPlayerModelResponse {
  /**
   *
   * @type {IdModel}
   * @memberof ApplicationPlayerModelResponse
   */
  championship: IdModel;
  /**
   *
   * @type {ApplicationBasePlayerModelPrimaryContactInfo}
   * @memberof ApplicationPlayerModelResponse
   */
  primaryContactInfo: ApplicationBasePlayerModelPrimaryContactInfo;
  /**
   *
   * @type {ApplicationBasePlayerModelGuardianContactInfo}
   * @memberof ApplicationPlayerModelResponse
   */
  guardianContactInfo?: ApplicationBasePlayerModelGuardianContactInfo;
  /**
   *
   * @type {ProfilePlayerModelAllOfEmergencyContact}
   * @memberof ApplicationPlayerModelResponse
   */
  emergencyContact?: ProfilePlayerModelAllOfEmergencyContact;
  /**
   *
   * @type {GenderEnum}
   * @memberof ApplicationPlayerModelResponse
   */
  gender: GenderEnum;
  /**
   *
   * @type {string}
   * @memberof ApplicationPlayerModelResponse
   */
  dob: string;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationPlayerModelResponse
   */
  playerMinor?: boolean;
  /**
   *
   * @type {PlayerStatusEnum}
   * @memberof ApplicationPlayerModelResponse
   */
  playerStatus: PlayerStatusEnum;
  /**
   *
   * @type {CountryAddModel}
   * @memberof ApplicationPlayerModelResponse
   */
  countryOfCitizenship?: CountryAddModel;
  /**
   *
   * @type {EthnicityEnum}
   * @memberof ApplicationPlayerModelResponse
   */
  ethnicity?: EthnicityEnum;
  /**
   *
   * @type {number}
   * @memberof ApplicationPlayerModelResponse
   */
  highSchoolGraduationYear?: number;
  /**
   *
   * @type {CountryAddModel}
   * @memberof ApplicationPlayerModelResponse
   */
  preferredCountry?: CountryAddModel;
  /**
   *
   * @type {string}
   * @memberof ApplicationPlayerModelResponse
   */
  preferredCity?: string;
  /**
   *
   * @type {StateAddModel}
   * @memberof ApplicationPlayerModelResponse
   */
  preferredState?: StateAddModel;
  /**
   *
   * @type {ApplicationBasePlayerModelHandicap}
   * @memberof ApplicationPlayerModelResponse
   */
  handicap?: ApplicationBasePlayerModelHandicap;
  /**
   *
   * @type {Array<IdModel>}
   * @memberof ApplicationPlayerModelResponse
   */
  exemption?: Array<IdModel>;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationPlayerModelResponse
   */
  optInPrivacy?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationPlayerModelResponse
   */
  unknownHandicapID?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationPlayerModelResponse
   */
  optInWagr?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationPlayerModelResponse
   */
  antiDopingViolation?: boolean;
  /**
   *
   * @type {ApplicationDisabilityTypeEnum}
   * @memberof ApplicationPlayerModelResponse
   */
  disabilityType?: ApplicationDisabilityTypeEnum;
  /**
   *
   * @type {string}
   * @memberof ApplicationPlayerModelResponse
   */
  wR4GDPass?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationPlayerModelResponse
   */
  disabilityDesc?: string;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationPlayerModelResponse
   */
  legalGuardian?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationPlayerModelResponse
   */
  playGolfInCart?: boolean;
  /**
   *
   * @type {string}
   * @memberof ApplicationPlayerModelResponse
   */
  virtusII1Number?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationPlayerModelResponse
   */
  adaptiveEquipDesc?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationPlayerModelResponse
   */
  compPlayingRecord?: string;
  /**
   *
   * @type {Array<number>}
   * @memberof ApplicationPlayerModelResponse
   */
  fileIds?: Array<number>;
  /**
   *
   * @type {string}
   * @memberof ApplicationPlayerModelResponse
   */
  entryDate?: string;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationPlayerModelResponse
   */
  surveyEmailSent?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationPlayerModelResponse
   */
  declinedPaymentEmailSent?: boolean;
  /**
   *
   * @type {Array<ApplicationPlayerModelResponseAllOfQualifyingSites>}
   * @memberof ApplicationPlayerModelResponse
   */
  qualifyingSites?: Array<ApplicationPlayerModelResponseAllOfQualifyingSites>;
  /**
   *
   * @type {Array<ApplicationPlayerModelAllOfWaitList>}
   * @memberof ApplicationPlayerModelResponse
   */
  waitList?: Array<ApplicationPlayerModelAllOfWaitList>;
}
/**
 *
 * @export
 * @interface ApplicationPlayerModelResponseAllOf
 */
export interface ApplicationPlayerModelResponseAllOf {
  /**
   *
   * @type {Array<ApplicationPlayerModelResponseAllOfQualifyingSites>}
   * @memberof ApplicationPlayerModelResponseAllOf
   */
  qualifyingSites?: Array<ApplicationPlayerModelResponseAllOfQualifyingSites>;
  /**
   *
   * @type {Array<ApplicationPlayerModelAllOfWaitList>}
   * @memberof ApplicationPlayerModelResponseAllOf
   */
  waitList?: Array<ApplicationPlayerModelAllOfWaitList>;
}
/**
 *
 * @export
 * @interface ApplicationPlayerModelResponseAllOfQualifyingSites
 */
export interface ApplicationPlayerModelResponseAllOfQualifyingSites {
  /**
   *
   * @type {number}
   * @memberof ApplicationPlayerModelResponseAllOfQualifyingSites
   */
  position?: number;
  /**
   *
   * @type {QualifyingSiteLazyModel}
   * @memberof ApplicationPlayerModelResponseAllOfQualifyingSites
   */
  qualifyingSite?: QualifyingSiteLazyModel;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum ApplicationProgressionEnum {
  DRAFT = 'DRAFT',
  NEEDPARTNER = 'NEED_PARTNER',
  WAITLIST = 'WAITLIST',
  NEEDSREVIEW = 'NEEDS_REVIEW',
  Q = 'Q',
  WITHDRAWN = 'WITHDRAWN',
  ACCEPTED = 'ACCEPTED',
}

/**
 *
 * @export
 * @interface ApplicationQualifyingLocationLightweightResponseModel
 */
export interface ApplicationQualifyingLocationLightweightResponseModel {
  /**
   *
   * @type {IdModel}
   * @memberof ApplicationQualifyingLocationLightweightResponseModel
   */
  championship: IdModel;
  /**
   *
   * @type {ApplicationBasePlayerModelPrimaryContactInfo}
   * @memberof ApplicationQualifyingLocationLightweightResponseModel
   */
  primaryContactInfo: ApplicationBasePlayerModelPrimaryContactInfo;
  /**
   *
   * @type {GenderEnum}
   * @memberof ApplicationQualifyingLocationLightweightResponseModel
   */
  gender: GenderEnum;
  /**
   *
   * @type {string}
   * @memberof ApplicationQualifyingLocationLightweightResponseModel
   */
  dob: string;
  /**
   *
   * @type {PlayerStatusEnum}
   * @memberof ApplicationQualifyingLocationLightweightResponseModel
   */
  playerStatus: PlayerStatusEnum;
  /**
   *
   * @type {number}
   * @memberof ApplicationQualifyingLocationLightweightResponseModel
   */
  highSchoolGraduationYear?: number;
  /**
   *
   * @type {CountryAddModel}
   * @memberof ApplicationQualifyingLocationLightweightResponseModel
   */
  preferredCountry?: CountryAddModel;
  /**
   *
   * @type {string}
   * @memberof ApplicationQualifyingLocationLightweightResponseModel
   */
  preferredCity?: string;
  /**
   *
   * @type {StateAddModel}
   * @memberof ApplicationQualifyingLocationLightweightResponseModel
   */
  preferredState?: StateAddModel;
  /**
   *
   * @type {ApplicationBasePlayerModelHandicap}
   * @memberof ApplicationQualifyingLocationLightweightResponseModel
   */
  handicap?: ApplicationBasePlayerModelHandicap;
  /**
   *
   * @type {Array<IdModel>}
   * @memberof ApplicationQualifyingLocationLightweightResponseModel
   */
  exemption?: Array<IdModel>;
  /**
   *
   * @type {Array<ApplicationBaseAdminModelAllOfQualifyingSites>}
   * @memberof ApplicationQualifyingLocationLightweightResponseModel
   */
  qualifyingSites?: Array<ApplicationBaseAdminModelAllOfQualifyingSites>;
  /**
   *
   * @type {Array<ApplicationPlayerModelAllOfWaitList>}
   * @memberof ApplicationQualifyingLocationLightweightResponseModel
   */
  waitList?: Array<ApplicationPlayerModelAllOfWaitList>;
  /**
   *
   * @type {QualifyingStatusEnum}
   * @memberof ApplicationQualifyingLocationLightweightResponseModel
   */
  qualifyingStatus?: QualifyingStatusEnum;
  /**
   *
   * @type {number}
   * @memberof ApplicationQualifyingLocationLightweightResponseModel
   */
  id: number;
  /**
   *
   * @type {Array<ApplicationBaseAdminModelAllOfQueueReasons>}
   * @memberof ApplicationQualifyingLocationLightweightResponseModel
   */
  queueReasons?: Array<ApplicationBaseAdminModelAllOfQueueReasons>;
  /**
   *
   * @type {ApplicationQualifyingLocationLightweightResponseModelTeam}
   * @memberof ApplicationQualifyingLocationLightweightResponseModel
   */
  team?: ApplicationQualifyingLocationLightweightResponseModelTeam;
  /**
   *
   * @type {ApplicationAdminResponseModelAllOfPair}
   * @memberof ApplicationQualifyingLocationLightweightResponseModel
   */
  pair?: ApplicationAdminResponseModelAllOfPair;
  /**
   *
   * @type {number}
   * @memberof ApplicationQualifyingLocationLightweightResponseModel
   */
  pairingNumber?: number;
  /**
   *
   * @type {WithdrawalResponseAdminModel}
   * @memberof ApplicationQualifyingLocationLightweightResponseModel
   */
  withdrawal?: WithdrawalResponseAdminModel;
  /**
   *
   * @type {ApplicationProgressionEnum}
   * @memberof ApplicationQualifyingLocationLightweightResponseModel
   */
  progression: ApplicationProgressionEnum;
  /**
   *
   * @type {string}
   * @memberof ApplicationQualifyingLocationLightweightResponseModel
   */
  entryDate?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationQualifyingLocationLightweightResponseModel
   */
  reviewDate?: string;
  /**
   *
   * @type {IdModel}
   * @memberof ApplicationQualifyingLocationLightweightResponseModel
   */
  player: IdModel;
  /**
   *
   * @type {AuditModel}
   * @memberof ApplicationQualifyingLocationLightweightResponseModel
   */
  audit: AuditModel;
  /**
   *
   * @type {string}
   * @memberof ApplicationQualifyingLocationLightweightResponseModel
   */
  firstStageExemption?: string;
  /**
   *
   * @type {number}
   * @memberof ApplicationQualifyingLocationLightweightResponseModel
   */
  firstStageExemptionNumber?: number;
  /**
   *
   * @type {string}
   * @memberof ApplicationQualifyingLocationLightweightResponseModel
   */
  firstQualifyingLocation?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationQualifyingLocationLightweightResponseModel
   */
  firstQualifyingLocationCity?: string;
  /**
   *
   * @type {CountryAddModel}
   * @memberof ApplicationQualifyingLocationLightweightResponseModel
   */
  firstQualifyingLocationCountry?: CountryAddModel;
  /**
   *
   * @type {StateAddModel}
   * @memberof ApplicationQualifyingLocationLightweightResponseModel
   */
  firstQualifyingLocationState?: StateAddModel;
  /**
   *
   * @type {string}
   * @memberof ApplicationQualifyingLocationLightweightResponseModel
   */
  finalStageExemption?: string;
  /**
   *
   * @type {number}
   * @memberof ApplicationQualifyingLocationLightweightResponseModel
   */
  finalStageExemptionNumber?: number;
  /**
   *
   * @type {string}
   * @memberof ApplicationQualifyingLocationLightweightResponseModel
   */
  finalQualifyingLocation?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationQualifyingLocationLightweightResponseModel
   */
  finalQualifyingLocationCity?: string;
  /**
   *
   * @type {CountryAddModel}
   * @memberof ApplicationQualifyingLocationLightweightResponseModel
   */
  finalQualifyingLocationCountry?: CountryAddModel;
  /**
   *
   * @type {StateAddModel}
   * @memberof ApplicationQualifyingLocationLightweightResponseModel
   */
  finalQualifyingLocationState?: StateAddModel;
  /**
   *
   * @type {string}
   * @memberof ApplicationQualifyingLocationLightweightResponseModel
   */
  singleStageExemption?: string;
  /**
   *
   * @type {number}
   * @memberof ApplicationQualifyingLocationLightweightResponseModel
   */
  singleStageExemptionNumber?: number;
  /**
   *
   * @type {string}
   * @memberof ApplicationQualifyingLocationLightweightResponseModel
   */
  singleQualifyingLocation?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationQualifyingLocationLightweightResponseModel
   */
  singleQualifyingLocationCity?: string;
  /**
   *
   * @type {CountryAddModel}
   * @memberof ApplicationQualifyingLocationLightweightResponseModel
   */
  singleQualifyingLocationCountry?: CountryAddModel;
  /**
   *
   * @type {StateAddModel}
   * @memberof ApplicationQualifyingLocationLightweightResponseModel
   */
  singleQualifyingLocationState?: StateAddModel;
}
/**
 *
 * @export
 * @interface ApplicationQualifyingLocationLightweightResponseModelTeam
 */
export interface ApplicationQualifyingLocationLightweightResponseModelTeam {
  /**
   *
   * @type {Array<ApplicationQualifyingLocationLightweightResponseModelTeamApplications>}
   * @memberof ApplicationQualifyingLocationLightweightResponseModelTeam
   */
  applications?: Array<ApplicationQualifyingLocationLightweightResponseModelTeamApplications>;
  /**
   *
   * @type {number}
   * @memberof ApplicationQualifyingLocationLightweightResponseModelTeam
   */
  id: number;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationQualifyingLocationLightweightResponseModelTeam
   */
  complete: boolean;
  /**
   *
   * @type {ApplicationAdminResponseModelAllOfTeamAudit}
   * @memberof ApplicationQualifyingLocationLightweightResponseModelTeam
   */
  audit?: ApplicationAdminResponseModelAllOfTeamAudit;
}
/**
 *
 * @export
 * @interface ApplicationQualifyingLocationLightweightResponseModelTeamApplications
 */
export interface ApplicationQualifyingLocationLightweightResponseModelTeamApplications {
  /**
   *
   * @type {number}
   * @memberof ApplicationQualifyingLocationLightweightResponseModelTeamApplications
   */
  id: number;
  /**
   *
   * @type {ApplicationProgressionEnum}
   * @memberof ApplicationQualifyingLocationLightweightResponseModelTeamApplications
   */
  progression: ApplicationProgressionEnum;
  /**
   *
   * @type {ApplicationQualifyingLocationLightweightResponseModelTeamPrimaryContactInfo}
   * @memberof ApplicationQualifyingLocationLightweightResponseModelTeamApplications
   */
  primaryContactInfo: ApplicationQualifyingLocationLightweightResponseModelTeamPrimaryContactInfo;
}
/**
 *
 * @export
 * @interface ApplicationQualifyingLocationLightweightResponseModelTeamPrimaryContactInfo
 */
export interface ApplicationQualifyingLocationLightweightResponseModelTeamPrimaryContactInfo {
  /**
   *
   * @type {string}
   * @memberof ApplicationQualifyingLocationLightweightResponseModelTeamPrimaryContactInfo
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationQualifyingLocationLightweightResponseModelTeamPrimaryContactInfo
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationQualifyingLocationLightweightResponseModelTeamPrimaryContactInfo
   */
  address: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationQualifyingLocationLightweightResponseModelTeamPrimaryContactInfo
   */
  address2?: string;
  /**
   *
   * @type {CountryAddModel}
   * @memberof ApplicationQualifyingLocationLightweightResponseModelTeamPrimaryContactInfo
   */
  country: CountryAddModel;
  /**
   *
   * @type {string}
   * @memberof ApplicationQualifyingLocationLightweightResponseModelTeamPrimaryContactInfo
   */
  city: string;
  /**
   *
   * @type {StateAddModel}
   * @memberof ApplicationQualifyingLocationLightweightResponseModelTeamPrimaryContactInfo
   */
  state?: StateAddModel;
  /**
   *
   * @type {string}
   * @memberof ApplicationQualifyingLocationLightweightResponseModelTeamPrimaryContactInfo
   */
  postcode: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationQualifyingLocationLightweightResponseModelTeamPrimaryContactInfo
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationQualifyingLocationLightweightResponseModelTeamPrimaryContactInfo
   */
  phoneNumber?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationQualifyingLocationLightweightResponseModelTeamPrimaryContactInfo
   */
  mobileNumber?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationQualifyingLocationLightweightResponseModelTeamPrimaryContactInfo
   */
  businessNumber?: string;
}
/**
 *
 * @export
 * @interface ApplicationQualifyingLocationResponseFetchModel
 */
export interface ApplicationQualifyingLocationResponseFetchModel {
  /**
   *
   * @type {ApplicationQualifyingLocationResponseFetchModelTeam}
   * @memberof ApplicationQualifyingLocationResponseFetchModel
   */
  team?: ApplicationQualifyingLocationResponseFetchModelTeam;
  /**
   *
   * @type {ApplicationFetchModelPair}
   * @memberof ApplicationQualifyingLocationResponseFetchModel
   */
  pair?: ApplicationFetchModelPair;
  /**
   *
   * @type {WithdrawalResponseAdminModel}
   * @memberof ApplicationQualifyingLocationResponseFetchModel
   */
  withdrawal?: WithdrawalResponseAdminModel;
  /**
   *
   * @type {IdModel}
   * @memberof ApplicationQualifyingLocationResponseFetchModel
   */
  player?: IdModel;
  /**
   *
   * @type {NotificationModel}
   * @memberof ApplicationQualifyingLocationResponseFetchModel
   */
  notifications?: NotificationModel;
  /**
   *
   * @type {Array<ApplicationQualifyingLocationResponseFetchModelQualifyingSites>}
   * @memberof ApplicationQualifyingLocationResponseFetchModel
   */
  qualifyingSites?: Array<ApplicationQualifyingLocationResponseFetchModelQualifyingSites>;
  /**
   *
   * @type {Array<ApplicationAnonResponseModelQualifyingSites>}
   * @memberof ApplicationQualifyingLocationResponseFetchModel
   */
  waitList?: Array<ApplicationAnonResponseModelQualifyingSites>;
  /**
   *
   * @type {PaymentDataRequestModel}
   * @memberof ApplicationQualifyingLocationResponseFetchModel
   */
  payment?: PaymentDataRequestModel;
  /**
   *
   * @type {Array<ApplicationQualifyingLocationResponseFetchModelQueueReasons>}
   * @memberof ApplicationQualifyingLocationResponseFetchModel
   */
  queueReasons?: Array<ApplicationQualifyingLocationResponseFetchModelQueueReasons>;
  /**
   *
   * @type {ApplicationBaseAdminModelScoring}
   * @memberof ApplicationQualifyingLocationResponseFetchModel
   */
  scoring?: ApplicationBaseAdminModelScoring;
  /**
   *
   * @type {ContactInfoModel}
   * @memberof ApplicationQualifyingLocationResponseFetchModel
   */
  secondaryContactInfo?: ContactInfoModel;
  /**
   *
   * @type {IdModel}
   * @memberof ApplicationQualifyingLocationResponseFetchModel
   */
  submittedByPlayer?: IdModel;
  /**
   *
   * @type {ApplicationQualifyingLocationResponseFetchModelChampionship}
   * @memberof ApplicationQualifyingLocationResponseFetchModel
   */
  championship?: ApplicationQualifyingLocationResponseFetchModelChampionship;
  /**
   *
   * @type {ApplicationQualifyingLocationResponseFetchModelPrimaryContactInfo}
   * @memberof ApplicationQualifyingLocationResponseFetchModel
   */
  primaryContactInfo?: ApplicationQualifyingLocationResponseFetchModelPrimaryContactInfo;
  /**
   *
   * @type {ApplicationQualifyingLocationResponseFetchModelGuardianContactInfo}
   * @memberof ApplicationQualifyingLocationResponseFetchModel
   */
  guardianContactInfo?: ApplicationQualifyingLocationResponseFetchModelGuardianContactInfo;
  /**
   *
   * @type {CountryAddModel}
   * @memberof ApplicationQualifyingLocationResponseFetchModel
   */
  countryOfCitizenship?: CountryAddModel;
  /**
   *
   * @type {CountryAddModel}
   * @memberof ApplicationQualifyingLocationResponseFetchModel
   */
  preferredCountry?: CountryAddModel;
  /**
   *
   * @type {StateAddModel}
   * @memberof ApplicationQualifyingLocationResponseFetchModel
   */
  preferredState?: StateAddModel;
  /**
   *
   * @type {ApplicationQualifyingLocationResponseFetchModelHandicap}
   * @memberof ApplicationQualifyingLocationResponseFetchModel
   */
  handicap?: ApplicationQualifyingLocationResponseFetchModelHandicap;
  /**
   *
   * @type {Array<IdModel>}
   * @memberof ApplicationQualifyingLocationResponseFetchModel
   */
  exemption?: Array<IdModel>;
}
/**
 *
 * @export
 * @interface ApplicationQualifyingLocationResponseFetchModelChampionship
 */
export interface ApplicationQualifyingLocationResponseFetchModelChampionship {
  /**
   *
   * @type {ApplicationQualifyingLocationResponseFetchModelChampionshipChampionshipType}
   * @memberof ApplicationQualifyingLocationResponseFetchModelChampionship
   */
  championshipType?: ApplicationQualifyingLocationResponseFetchModelChampionshipChampionshipType;
}
/**
 *
 * @export
 * @interface ApplicationQualifyingLocationResponseFetchModelChampionshipChampionshipType
 */
export interface ApplicationQualifyingLocationResponseFetchModelChampionshipChampionshipType {
  /**
   *
   * @type {WorkflowTypeEnum}
   * @memberof ApplicationQualifyingLocationResponseFetchModelChampionshipChampionshipType
   */
  workflowType?: WorkflowTypeEnum;
}
/**
 *
 * @export
 * @interface ApplicationQualifyingLocationResponseFetchModelGuardianContactInfo
 */
export interface ApplicationQualifyingLocationResponseFetchModelGuardianContactInfo {
  /**
   *
   * @type {CountryAddModel}
   * @memberof ApplicationQualifyingLocationResponseFetchModelGuardianContactInfo
   */
  country?: CountryAddModel;
  /**
   *
   * @type {StateAddModel}
   * @memberof ApplicationQualifyingLocationResponseFetchModelGuardianContactInfo
   */
  state?: StateAddModel;
}
/**
 *
 * @export
 * @interface ApplicationQualifyingLocationResponseFetchModelHandicap
 */
export interface ApplicationQualifyingLocationResponseFetchModelHandicap {
  /**
   *
   * @type {CountryAddModel}
   * @memberof ApplicationQualifyingLocationResponseFetchModelHandicap
   */
  clubCountry?: CountryAddModel;
  /**
   *
   * @type {StateAddModel}
   * @memberof ApplicationQualifyingLocationResponseFetchModelHandicap
   */
  clubState?: StateAddModel;
}
/**
 *
 * @export
 * @interface ApplicationQualifyingLocationResponseFetchModelPrimaryContactInfo
 */
export interface ApplicationQualifyingLocationResponseFetchModelPrimaryContactInfo {
  /**
   *
   * @type {string}
   * @memberof ApplicationQualifyingLocationResponseFetchModelPrimaryContactInfo
   */
  email?: string;
  /**
   *
   * @type {CountryAddModel}
   * @memberof ApplicationQualifyingLocationResponseFetchModelPrimaryContactInfo
   */
  country?: CountryAddModel;
  /**
   *
   * @type {StateAddModel}
   * @memberof ApplicationQualifyingLocationResponseFetchModelPrimaryContactInfo
   */
  state?: StateAddModel;
}
/**
 *
 * @export
 * @interface ApplicationQualifyingLocationResponseFetchModelQualifyingSite
 */
export interface ApplicationQualifyingLocationResponseFetchModelQualifyingSite {
  /**
   *
   * @type {ApplicationQualifyingLocationResponseFetchModelQualifyingSitePointOfContact}
   * @memberof ApplicationQualifyingLocationResponseFetchModelQualifyingSite
   */
  pointOfContact?: ApplicationQualifyingLocationResponseFetchModelQualifyingSitePointOfContact;
  /**
   *
   * @type {ApplicationQualifyingLocationResponseFetchModelQualifyingSiteState}
   * @memberof ApplicationQualifyingLocationResponseFetchModelQualifyingSite
   */
  state?: ApplicationQualifyingLocationResponseFetchModelQualifyingSiteState;
  /**
   *
   * @type {ApplicationQualifyingLocationResponseFetchModelQualifyingSiteState}
   * @memberof ApplicationQualifyingLocationResponseFetchModelQualifyingSite
   */
  country?: ApplicationQualifyingLocationResponseFetchModelQualifyingSiteState;
  /**
   *
   * @type {Array<ApplicationQualifyingLocationResponseFetchModelQualifyingSiteState>}
   * @memberof ApplicationQualifyingLocationResponseFetchModelQualifyingSite
   */
  club?: Array<ApplicationQualifyingLocationResponseFetchModelQualifyingSiteState>;
}
/**
 *
 * @export
 * @interface ApplicationQualifyingLocationResponseFetchModelQualifyingSitePointOfContact
 */
export interface ApplicationQualifyingLocationResponseFetchModelQualifyingSitePointOfContact {
  /**
   *
   * @type {string}
   * @memberof ApplicationQualifyingLocationResponseFetchModelQualifyingSitePointOfContact
   */
  email?: string;
}
/**
 *
 * @export
 * @interface ApplicationQualifyingLocationResponseFetchModelQualifyingSiteState
 */
export interface ApplicationQualifyingLocationResponseFetchModelQualifyingSiteState {
  /**
   *
   * @type {string}
   * @memberof ApplicationQualifyingLocationResponseFetchModelQualifyingSiteState
   */
  name?: string;
}
/**
 *
 * @export
 * @interface ApplicationQualifyingLocationResponseFetchModelQualifyingSites
 */
export interface ApplicationQualifyingLocationResponseFetchModelQualifyingSites {
  /**
   *
   * @type {ApplicationQualifyingLocationResponseFetchModelQualifyingSite}
   * @memberof ApplicationQualifyingLocationResponseFetchModelQualifyingSites
   */
  qualifyingSite?: ApplicationQualifyingLocationResponseFetchModelQualifyingSite;
}
/**
 *
 * @export
 * @interface ApplicationQualifyingLocationResponseFetchModelQueueReasons
 */
export interface ApplicationQualifyingLocationResponseFetchModelQueueReasons {
  /**
   *
   * @type {IdModel}
   * @memberof ApplicationQualifyingLocationResponseFetchModelQueueReasons
   */
  queueReason?: IdModel;
}
/**
 *
 * @export
 * @interface ApplicationQualifyingLocationResponseFetchModelTeam
 */
export interface ApplicationQualifyingLocationResponseFetchModelTeam {
  /**
   *
   * @type {Array<ApplicationResponseModel>}
   * @memberof ApplicationQualifyingLocationResponseFetchModelTeam
   */
  applications?: Array<ApplicationResponseModel>;
  /**
   *
   * @type {number}
   * @memberof ApplicationQualifyingLocationResponseFetchModelTeam
   */
  id?: number;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationQualifyingLocationResponseFetchModelTeam
   */
  complete?: boolean;
}
/**
 *
 * @export
 * @interface ApplicationQualifyingLocationResponseModel
 */
export interface ApplicationQualifyingLocationResponseModel {
  /**
   *
   * @type {IdModel}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  championship: IdModel;
  /**
   *
   * @type {ApplicationBasePlayerModelPrimaryContactInfo}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  primaryContactInfo: ApplicationBasePlayerModelPrimaryContactInfo;
  /**
   *
   * @type {ApplicationBasePlayerModelGuardianContactInfo}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  guardianContactInfo?: ApplicationBasePlayerModelGuardianContactInfo;
  /**
   *
   * @type {ProfilePlayerModelAllOfEmergencyContact}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  emergencyContact?: ProfilePlayerModelAllOfEmergencyContact;
  /**
   *
   * @type {GenderEnum}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  gender: GenderEnum;
  /**
   *
   * @type {string}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  dob: string;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  playerMinor?: boolean;
  /**
   *
   * @type {PlayerStatusEnum}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  playerStatus: PlayerStatusEnum;
  /**
   *
   * @type {CountryAddModel}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  countryOfCitizenship?: CountryAddModel;
  /**
   *
   * @type {EthnicityEnum}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  ethnicity?: EthnicityEnum;
  /**
   *
   * @type {number}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  highSchoolGraduationYear?: number;
  /**
   *
   * @type {CountryAddModel}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  preferredCountry?: CountryAddModel;
  /**
   *
   * @type {string}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  preferredCity?: string;
  /**
   *
   * @type {StateAddModel}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  preferredState?: StateAddModel;
  /**
   *
   * @type {ApplicationBasePlayerModelHandicap}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  handicap?: ApplicationBasePlayerModelHandicap;
  /**
   *
   * @type {Array<IdModel>}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  exemption?: Array<IdModel>;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  optInPrivacy?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  unknownHandicapID?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  optInWagr?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  antiDopingViolation?: boolean;
  /**
   *
   * @type {ApplicationDisabilityTypeEnum}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  disabilityType?: ApplicationDisabilityTypeEnum;
  /**
   *
   * @type {string}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  wR4GDPass?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  disabilityDesc?: string;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  legalGuardian?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  playGolfInCart?: boolean;
  /**
   *
   * @type {string}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  virtusII1Number?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  adaptiveEquipDesc?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  compPlayingRecord?: string;
  /**
   *
   * @type {Array<number>}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  fileIds?: Array<number>;
  /**
   *
   * @type {string}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  entryDate?: string;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  surveyEmailSent?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  declinedPaymentEmailSent?: boolean;
  /**
   *
   * @type {Array<ApplicationBaseAdminModelAllOfQualifyingSites>}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  qualifyingSites?: Array<ApplicationBaseAdminModelAllOfQualifyingSites>;
  /**
   *
   * @type {Array<ApplicationPlayerModelAllOfWaitList>}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  waitList?: Array<ApplicationPlayerModelAllOfWaitList>;
  /**
   *
   * @type {string}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  wagrRank?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  rank?: string;
  /**
   *
   * @type {QualifyingStatusEnum}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  qualifyingStatus?: QualifyingStatusEnum;
  /**
   *
   * @type {string}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  eligibleReinstatementDate?: string;
  /**
   *
   * @type {number}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  id: number;
  /**
   *
   * @type {PaymentDataRequestModel}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  payment?: PaymentDataRequestModel;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  adaRequest?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  notablePlayer?: boolean;
  /**
   *
   * @type {Array<ApplicationBaseAdminModelAllOfQueueReasons>}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  queueReasons?: Array<ApplicationBaseAdminModelAllOfQueueReasons>;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  markAsReviewed?: boolean;
  /**
   *
   * @type {ApplicationBaseAdminModelScoring}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  scoring?: ApplicationBaseAdminModelScoring;
  /**
   *
   * @type {BlacklistStatusEnum}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  blacklistStatus?: BlacklistStatusEnum;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  blacklistSendEmail?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  watchlist?: boolean;
  /**
   *
   * @type {string}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  watchlistReason?: string;
  /**
   *
   * @type {ContactInfoModel}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  secondaryContactInfo?: ContactInfoModel;
  /**
   *
   * @type {IdModel}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  submittedByPlayer?: IdModel;
  /**
   *
   * @type {ApplicationAdminResponseModelAllOfTeam}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  team?: ApplicationAdminResponseModelAllOfTeam;
  /**
   *
   * @type {ApplicationAdminResponseModelAllOfPair}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  pair?: ApplicationAdminResponseModelAllOfPair;
  /**
   *
   * @type {DeclinedPaymentModel}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  declinedPayment?: DeclinedPaymentModel;
  /**
   *
   * @type {number}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  pairingNumber?: number;
  /**
   *
   * @type {WithdrawalResponseAdminModel}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  withdrawal?: WithdrawalResponseAdminModel;
  /**
   *
   * @type {ApplicationProgressionEnum}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  progression: ApplicationProgressionEnum;
  /**
   *
   * @type {string}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  reviewDate?: string;
  /**
   * Auth0 id of user who last made transition to reviewed state (enabled reviewDate field)
   * @type {string}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  reviewedBy?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  blacklistStatusDate?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  blacklistStatusDateCleared?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  watchlistDate?: string;
  /**
   *
   * @type {IdModel}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  player: IdModel;
  /**
   *
   * @type {AuditModel}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  audit: AuditModel;
  /**
   *
   * @type {NotificationModel}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  notifications?: NotificationModel;
  /**
   *
   * @type {number}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  transfersQuantity?: number;
  /**
   *
   * @type {number}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  adminTransfersQuantity?: number;
  /**
   *
   * @type {string}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  firstStageExemption?: string;
  /**
   *
   * @type {number}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  firstStageExemptionNumber?: number;
  /**
   *
   * @type {number}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  firstQualifyingLocationNumber?: number;
  /**
   *
   * @type {string}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  firstQualifyingLocation?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  firstQualifyingLocationDate?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  firstQualifyingLocationCity?: string;
  /**
   *
   * @type {CountryAddModel}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  firstQualifyingLocationCountry?: CountryAddModel;
  /**
   *
   * @type {StateAddModel}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  firstQualifyingLocationState?: StateAddModel;
  /**
   *
   * @type {string}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  firstQualifyingLocationEmail?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  finalStageExemption?: string;
  /**
   *
   * @type {number}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  finalStageExemptionNumber?: number;
  /**
   *
   * @type {number}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  finalQualifyingLocationNumber?: number;
  /**
   *
   * @type {string}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  finalQualifyingLocation?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  finalQualifyingLocationDate?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  finalQualifyingLocationCity?: string;
  /**
   *
   * @type {CountryAddModel}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  finalQualifyingLocationCountry?: CountryAddModel;
  /**
   *
   * @type {StateAddModel}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  finalQualifyingLocationState?: StateAddModel;
  /**
   *
   * @type {string}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  finalQualifyingLocationEmail?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  singleStageExemption?: string;
  /**
   *
   * @type {number}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  singleStageExemptionNumber?: number;
  /**
   *
   * @type {number}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  singleQualifyingLocationNumber?: number;
  /**
   *
   * @type {string}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  singleQualifyingLocation?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  singleQualifyingLocationDate?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  singleQualifyingLocationCity?: string;
  /**
   *
   * @type {CountryAddModel}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  singleQualifyingLocationCountry?: CountryAddModel;
  /**
   *
   * @type {StateAddModel}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  singleQualifyingLocationState?: StateAddModel;
  /**
   *
   * @type {string}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  singleQualifyingLocationEmail?: string;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationQualifyingLocationResponseModel
   */
  qualificationDateIsTwoDays?: boolean;
}
/**
 *
 * @export
 * @interface ApplicationQualifyingLocationResponseModelAllOf
 */
export interface ApplicationQualifyingLocationResponseModelAllOf {
  /**
   *
   * @type {string}
   * @memberof ApplicationQualifyingLocationResponseModelAllOf
   */
  firstStageExemption?: string;
  /**
   *
   * @type {number}
   * @memberof ApplicationQualifyingLocationResponseModelAllOf
   */
  firstStageExemptionNumber?: number;
  /**
   *
   * @type {number}
   * @memberof ApplicationQualifyingLocationResponseModelAllOf
   */
  firstQualifyingLocationNumber?: number;
  /**
   *
   * @type {string}
   * @memberof ApplicationQualifyingLocationResponseModelAllOf
   */
  firstQualifyingLocation?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationQualifyingLocationResponseModelAllOf
   */
  firstQualifyingLocationDate?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationQualifyingLocationResponseModelAllOf
   */
  firstQualifyingLocationCity?: string;
  /**
   *
   * @type {CountryAddModel}
   * @memberof ApplicationQualifyingLocationResponseModelAllOf
   */
  firstQualifyingLocationCountry?: CountryAddModel;
  /**
   *
   * @type {StateAddModel}
   * @memberof ApplicationQualifyingLocationResponseModelAllOf
   */
  firstQualifyingLocationState?: StateAddModel;
  /**
   *
   * @type {string}
   * @memberof ApplicationQualifyingLocationResponseModelAllOf
   */
  firstQualifyingLocationEmail?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationQualifyingLocationResponseModelAllOf
   */
  finalStageExemption?: string;
  /**
   *
   * @type {number}
   * @memberof ApplicationQualifyingLocationResponseModelAllOf
   */
  finalStageExemptionNumber?: number;
  /**
   *
   * @type {number}
   * @memberof ApplicationQualifyingLocationResponseModelAllOf
   */
  finalQualifyingLocationNumber?: number;
  /**
   *
   * @type {string}
   * @memberof ApplicationQualifyingLocationResponseModelAllOf
   */
  finalQualifyingLocation?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationQualifyingLocationResponseModelAllOf
   */
  finalQualifyingLocationDate?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationQualifyingLocationResponseModelAllOf
   */
  finalQualifyingLocationCity?: string;
  /**
   *
   * @type {CountryAddModel}
   * @memberof ApplicationQualifyingLocationResponseModelAllOf
   */
  finalQualifyingLocationCountry?: CountryAddModel;
  /**
   *
   * @type {StateAddModel}
   * @memberof ApplicationQualifyingLocationResponseModelAllOf
   */
  finalQualifyingLocationState?: StateAddModel;
  /**
   *
   * @type {string}
   * @memberof ApplicationQualifyingLocationResponseModelAllOf
   */
  finalQualifyingLocationEmail?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationQualifyingLocationResponseModelAllOf
   */
  singleStageExemption?: string;
  /**
   *
   * @type {number}
   * @memberof ApplicationQualifyingLocationResponseModelAllOf
   */
  singleStageExemptionNumber?: number;
  /**
   *
   * @type {number}
   * @memberof ApplicationQualifyingLocationResponseModelAllOf
   */
  singleQualifyingLocationNumber?: number;
  /**
   *
   * @type {string}
   * @memberof ApplicationQualifyingLocationResponseModelAllOf
   */
  singleQualifyingLocation?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationQualifyingLocationResponseModelAllOf
   */
  singleQualifyingLocationDate?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationQualifyingLocationResponseModelAllOf
   */
  singleQualifyingLocationCity?: string;
  /**
   *
   * @type {CountryAddModel}
   * @memberof ApplicationQualifyingLocationResponseModelAllOf
   */
  singleQualifyingLocationCountry?: CountryAddModel;
  /**
   *
   * @type {StateAddModel}
   * @memberof ApplicationQualifyingLocationResponseModelAllOf
   */
  singleQualifyingLocationState?: StateAddModel;
  /**
   *
   * @type {string}
   * @memberof ApplicationQualifyingLocationResponseModelAllOf
   */
  singleQualifyingLocationEmail?: string;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationQualifyingLocationResponseModelAllOf
   */
  qualificationDateIsTwoDays?: boolean;
}
/**
 *
 * @export
 * @interface ApplicationRefundListModel
 */
export interface ApplicationRefundListModel {
  /**
   *
   * @type {Array<ApplicationRefundResponseModel>}
   * @memberof ApplicationRefundListModel
   */
  items: Array<ApplicationRefundResponseModel>;
}
/**
 *
 * @export
 * @interface ApplicationRefundResponseModel
 */
export interface ApplicationRefundResponseModel {
  /**
   *
   * @type {number}
   * @memberof ApplicationRefundResponseModel
   */
  applicationId?: number;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationRefundResponseModel
   */
  successRefund?: boolean;
  /**
   *
   * @type {string}
   * @memberof ApplicationRefundResponseModel
   */
  errorMessage?: string;
  /**
   *
   * @type {WithdrawalResponseAdminModel}
   * @memberof ApplicationRefundResponseModel
   */
  withdrawModel?: WithdrawalResponseAdminModel;
  /**
   *
   * @type {number}
   * @memberof ApplicationRefundResponseModel
   */
  eventCode?: number;
}
/**
 *
 * @export
 * @interface ApplicationResponseModel
 */
export interface ApplicationResponseModel {
  /**
   *
   * @type {IdModel}
   * @memberof ApplicationResponseModel
   */
  championship: IdModel;
  /**
   *
   * @type {ApplicationBasePlayerModelPrimaryContactInfo}
   * @memberof ApplicationResponseModel
   */
  primaryContactInfo: ApplicationBasePlayerModelPrimaryContactInfo;
  /**
   *
   * @type {ApplicationBasePlayerModelGuardianContactInfo}
   * @memberof ApplicationResponseModel
   */
  guardianContactInfo?: ApplicationBasePlayerModelGuardianContactInfo;
  /**
   *
   * @type {ProfilePlayerModelAllOfEmergencyContact}
   * @memberof ApplicationResponseModel
   */
  emergencyContact?: ProfilePlayerModelAllOfEmergencyContact;
  /**
   *
   * @type {GenderEnum}
   * @memberof ApplicationResponseModel
   */
  gender: GenderEnum;
  /**
   *
   * @type {string}
   * @memberof ApplicationResponseModel
   */
  dob: string;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationResponseModel
   */
  playerMinor?: boolean;
  /**
   *
   * @type {PlayerStatusEnum}
   * @memberof ApplicationResponseModel
   */
  playerStatus: PlayerStatusEnum;
  /**
   *
   * @type {CountryAddModel}
   * @memberof ApplicationResponseModel
   */
  countryOfCitizenship?: CountryAddModel;
  /**
   *
   * @type {EthnicityEnum}
   * @memberof ApplicationResponseModel
   */
  ethnicity?: EthnicityEnum;
  /**
   *
   * @type {number}
   * @memberof ApplicationResponseModel
   */
  highSchoolGraduationYear?: number;
  /**
   *
   * @type {CountryAddModel}
   * @memberof ApplicationResponseModel
   */
  preferredCountry?: CountryAddModel;
  /**
   *
   * @type {string}
   * @memberof ApplicationResponseModel
   */
  preferredCity?: string;
  /**
   *
   * @type {StateAddModel}
   * @memberof ApplicationResponseModel
   */
  preferredState?: StateAddModel;
  /**
   *
   * @type {ApplicationBasePlayerModelHandicap}
   * @memberof ApplicationResponseModel
   */
  handicap?: ApplicationBasePlayerModelHandicap;
  /**
   *
   * @type {Array<IdModel>}
   * @memberof ApplicationResponseModel
   */
  exemption?: Array<IdModel>;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationResponseModel
   */
  optInPrivacy?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationResponseModel
   */
  unknownHandicapID?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationResponseModel
   */
  optInWagr?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationResponseModel
   */
  antiDopingViolation?: boolean;
  /**
   *
   * @type {ApplicationDisabilityTypeEnum}
   * @memberof ApplicationResponseModel
   */
  disabilityType?: ApplicationDisabilityTypeEnum;
  /**
   *
   * @type {string}
   * @memberof ApplicationResponseModel
   */
  wR4GDPass?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationResponseModel
   */
  disabilityDesc?: string;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationResponseModel
   */
  legalGuardian?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationResponseModel
   */
  playGolfInCart?: boolean;
  /**
   *
   * @type {string}
   * @memberof ApplicationResponseModel
   */
  virtusII1Number?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationResponseModel
   */
  adaptiveEquipDesc?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationResponseModel
   */
  compPlayingRecord?: string;
  /**
   *
   * @type {Array<number>}
   * @memberof ApplicationResponseModel
   */
  fileIds?: Array<number>;
  /**
   *
   * @type {string}
   * @memberof ApplicationResponseModel
   */
  entryDate?: string;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationResponseModel
   */
  surveyEmailSent?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationResponseModel
   */
  declinedPaymentEmailSent?: boolean;
  /**
   *
   * @type {Array<ApplicationPlayerModelResponseAllOfQualifyingSites>}
   * @memberof ApplicationResponseModel
   */
  qualifyingSites?: Array<ApplicationPlayerModelResponseAllOfQualifyingSites>;
  /**
   *
   * @type {Array<ApplicationPlayerModelAllOfWaitList>}
   * @memberof ApplicationResponseModel
   */
  waitList?: Array<ApplicationPlayerModelAllOfWaitList>;
  /**
   *
   * @type {string}
   * @memberof ApplicationResponseModel
   */
  confirmationPagePath?: string;
  /**
   *
   * @type {number}
   * @memberof ApplicationResponseModel
   */
  id: number;
  /**
   *
   * @type {ApplicationProgressionEnum}
   * @memberof ApplicationResponseModel
   */
  progression: ApplicationProgressionEnum;
  /**
   *
   * @type {Array<ApplicationResponseModelAllOfQueueReasons>}
   * @memberof ApplicationResponseModel
   */
  queueReasons?: Array<ApplicationResponseModelAllOfQueueReasons>;
  /**
   *
   * @type {PaymentDataPlayerModel}
   * @memberof ApplicationResponseModel
   */
  payment?: PaymentDataPlayerModel;
  /**
   *
   * @type {ApplicationResponseModelAllOfTeam}
   * @memberof ApplicationResponseModel
   */
  team?: ApplicationResponseModelAllOfTeam;
  /**
   *
   * @type {IdModel}
   * @memberof ApplicationResponseModel
   */
  player: IdModel;
  /**
   *
   * @type {number}
   * @memberof ApplicationResponseModel
   */
  pairingNumber?: number;
  /**
   *
   * @type {number}
   * @memberof ApplicationResponseModel
   */
  transfersQuantity?: number;
  /**
   *
   * @type {NotificationModel}
   * @memberof ApplicationResponseModel
   */
  notifications?: NotificationModel;
  /**
   *
   * @type {Array<ApplicationResponseModelAllOfFiles>}
   * @memberof ApplicationResponseModel
   */
  files?: Array<ApplicationResponseModelAllOfFiles>;
  /**
   *
   * @type {DeclinedPaymentModel}
   * @memberof ApplicationResponseModel
   */
  declinedPayment?: DeclinedPaymentModel;
}
/**
 *
 * @export
 * @interface ApplicationResponseModelAllOf
 */
export interface ApplicationResponseModelAllOf {
  /**
   *
   * @type {string}
   * @memberof ApplicationResponseModelAllOf
   */
  confirmationPagePath?: string;
  /**
   *
   * @type {number}
   * @memberof ApplicationResponseModelAllOf
   */
  id: number;
  /**
   *
   * @type {ApplicationProgressionEnum}
   * @memberof ApplicationResponseModelAllOf
   */
  progression: ApplicationProgressionEnum;
  /**
   *
   * @type {Array<ApplicationResponseModelAllOfQueueReasons>}
   * @memberof ApplicationResponseModelAllOf
   */
  queueReasons?: Array<ApplicationResponseModelAllOfQueueReasons>;
  /**
   *
   * @type {PaymentDataPlayerModel}
   * @memberof ApplicationResponseModelAllOf
   */
  payment?: PaymentDataPlayerModel;
  /**
   *
   * @type {ApplicationResponseModelAllOfTeam}
   * @memberof ApplicationResponseModelAllOf
   */
  team?: ApplicationResponseModelAllOfTeam;
  /**
   *
   * @type {IdModel}
   * @memberof ApplicationResponseModelAllOf
   */
  player: IdModel;
  /**
   *
   * @type {number}
   * @memberof ApplicationResponseModelAllOf
   */
  pairingNumber?: number;
  /**
   *
   * @type {number}
   * @memberof ApplicationResponseModelAllOf
   */
  transfersQuantity?: number;
  /**
   *
   * @type {NotificationModel}
   * @memberof ApplicationResponseModelAllOf
   */
  notifications?: NotificationModel;
  /**
   *
   * @type {Array<ApplicationResponseModelAllOfFiles>}
   * @memberof ApplicationResponseModelAllOf
   */
  files?: Array<ApplicationResponseModelAllOfFiles>;
  /**
   *
   * @type {DeclinedPaymentModel}
   * @memberof ApplicationResponseModelAllOf
   */
  declinedPayment?: DeclinedPaymentModel;
}
/**
 *
 * @export
 * @interface ApplicationResponseModelAllOfFiles
 */
export interface ApplicationResponseModelAllOfFiles {
  /**
   *
   * @type {number}
   * @memberof ApplicationResponseModelAllOfFiles
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof ApplicationResponseModelAllOfFiles
   */
  filename?: string;
}
/**
 *
 * @export
 * @interface ApplicationResponseModelAllOfQueueReason
 */
export interface ApplicationResponseModelAllOfQueueReason {
  /**
   *
   * @type {number}
   * @memberof ApplicationResponseModelAllOfQueueReason
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof ApplicationResponseModelAllOfQueueReason
   */
  name: string;
}
/**
 *
 * @export
 * @interface ApplicationResponseModelAllOfQueueReasons
 */
export interface ApplicationResponseModelAllOfQueueReasons {
  /**
   *
   * @type {ApplicationResponseModelAllOfQueueReason}
   * @memberof ApplicationResponseModelAllOfQueueReasons
   */
  queueReason: ApplicationResponseModelAllOfQueueReason;
}
/**
 *
 * @export
 * @interface ApplicationResponseModelAllOfTeam
 */
export interface ApplicationResponseModelAllOfTeam {
  /**
   *
   * @type {Array<ApplicationAnonResponseModel>}
   * @memberof ApplicationResponseModelAllOfTeam
   */
  applications?: Array<ApplicationAnonResponseModel>;
  /**
   *
   * @type {number}
   * @memberof ApplicationResponseModelAllOfTeam
   */
  id: number;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationResponseModelAllOfTeam
   */
  complete: boolean;
}
/**
 *
 * @export
 * @interface ApplicationWaitlistPositionListModel
 */
export interface ApplicationWaitlistPositionListModel {
  /**
   *
   * @type {Array<ApplicationWaitlistPositionModel>}
   * @memberof ApplicationWaitlistPositionListModel
   */
  items: Array<ApplicationWaitlistPositionModel>;
}
/**
 *
 * @export
 * @interface ApplicationWaitlistPositionModel
 */
export interface ApplicationWaitlistPositionModel {
  /**
   *
   * @type {number}
   * @memberof ApplicationWaitlistPositionModel
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof ApplicationWaitlistPositionModel
   */
  position?: number;
}
/**
 *
 * @export
 * @interface ApplicationWithChampFetchModel
 */
export interface ApplicationWithChampFetchModel {
  /**
   *
   * @type {ChampionshipBaseModel}
   * @memberof ApplicationWithChampFetchModel
   */
  championship?: ChampionshipBaseModel;
  /**
   *
   * @type {ApplicationQualifyingLocationResponseFetchModelPrimaryContactInfo}
   * @memberof ApplicationWithChampFetchModel
   */
  primaryContactInfo?: ApplicationQualifyingLocationResponseFetchModelPrimaryContactInfo;
  /**
   *
   * @type {ApplicationQualifyingLocationResponseFetchModelGuardianContactInfo}
   * @memberof ApplicationWithChampFetchModel
   */
  guardianContactInfo?: ApplicationQualifyingLocationResponseFetchModelGuardianContactInfo;
  /**
   *
   * @type {CountryAddModel}
   * @memberof ApplicationWithChampFetchModel
   */
  countryOfCitizenship?: CountryAddModel;
  /**
   *
   * @type {CountryAddModel}
   * @memberof ApplicationWithChampFetchModel
   */
  preferredCountry?: CountryAddModel;
  /**
   *
   * @type {StateAddModel}
   * @memberof ApplicationWithChampFetchModel
   */
  preferredState?: StateAddModel;
  /**
   *
   * @type {ApplicationQualifyingLocationResponseFetchModelHandicap}
   * @memberof ApplicationWithChampFetchModel
   */
  handicap?: ApplicationQualifyingLocationResponseFetchModelHandicap;
  /**
   *
   * @type {Array<IdModel>}
   * @memberof ApplicationWithChampFetchModel
   */
  exemption?: Array<IdModel>;
  /**
   *
   * @type {Array<ApplicationPlayerModelAllOfQualifyingSites>}
   * @memberof ApplicationWithChampFetchModel
   */
  qualifyingSites?: Array<ApplicationPlayerModelAllOfQualifyingSites>;
  /**
   *
   * @type {Array<ApplicationFetchModelWaitList>}
   * @memberof ApplicationWithChampFetchModel
   */
  waitList?: Array<ApplicationFetchModelWaitList>;
  /**
   *
   * @type {PaymentDataRequestModel}
   * @memberof ApplicationWithChampFetchModel
   */
  payment?: PaymentDataRequestModel;
  /**
   *
   * @type {Array<ApplicationQualifyingLocationResponseFetchModelQueueReasons>}
   * @memberof ApplicationWithChampFetchModel
   */
  queueReasons?: Array<ApplicationQualifyingLocationResponseFetchModelQueueReasons>;
  /**
   *
   * @type {ApplicationBaseAdminModelScoring}
   * @memberof ApplicationWithChampFetchModel
   */
  scoring?: ApplicationBaseAdminModelScoring;
  /**
   *
   * @type {BlacklistStatusEnum}
   * @memberof ApplicationWithChampFetchModel
   */
  blacklistStatus?: BlacklistStatusEnum;
  /**
   *
   * @type {ContactInfoModel}
   * @memberof ApplicationWithChampFetchModel
   */
  secondaryContactInfo?: ContactInfoModel;
  /**
   *
   * @type {IdModel}
   * @memberof ApplicationWithChampFetchModel
   */
  submittedByPlayer?: IdModel;
  /**
   *
   * @type {ApplicationFetchModelTeam}
   * @memberof ApplicationWithChampFetchModel
   */
  team?: ApplicationFetchModelTeam;
  /**
   *
   * @type {ApplicationFetchModelPair}
   * @memberof ApplicationWithChampFetchModel
   */
  pair?: ApplicationFetchModelPair;
  /**
   *
   * @type {WithdrawalResponseAdminModel}
   * @memberof ApplicationWithChampFetchModel
   */
  withdrawal?: WithdrawalResponseAdminModel;
  /**
   *
   * @type {ApplicationProgressionEnum}
   * @memberof ApplicationWithChampFetchModel
   */
  progression?: ApplicationProgressionEnum;
  /**
   *
   * @type {IdModel}
   * @memberof ApplicationWithChampFetchModel
   */
  player?: IdModel;
  /**
   *
   * @type {AuditModel}
   * @memberof ApplicationWithChampFetchModel
   */
  audit?: AuditModel;
  /**
   *
   * @type {NotificationModel}
   * @memberof ApplicationWithChampFetchModel
   */
  notifications?: NotificationModel;
}
/**
 *
 * @export
 * @interface ApplicationWithQSFetchModel
 */
export interface ApplicationWithQSFetchModel {
  /**
   *
   * @type {number}
   * @memberof ApplicationWithQSFetchModel
   */
  id?: number;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationWithQSFetchModel
   */
  active?: boolean;
  /**
   *
   * @type {IdModel}
   * @memberof ApplicationWithQSFetchModel
   */
  championship?: IdModel;
  /**
   *
   * @type {ApplicationFetchModelPrimaryContactInfo}
   * @memberof ApplicationWithQSFetchModel
   */
  primaryContactInfo?: ApplicationFetchModelPrimaryContactInfo;
  /**
   *
   * @type {ApplicationFetchModelGuardianContactInfo}
   * @memberof ApplicationWithQSFetchModel
   */
  guardianContactInfo?: ApplicationFetchModelGuardianContactInfo;
  /**
   *
   * @type {GenderEnum}
   * @memberof ApplicationWithQSFetchModel
   */
  gender?: GenderEnum;
  /**
   *
   * @type {string}
   * @memberof ApplicationWithQSFetchModel
   */
  dob?: string;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationWithQSFetchModel
   */
  playerMinor?: boolean;
  /**
   *
   * @type {PlayerStatusEnum}
   * @memberof ApplicationWithQSFetchModel
   */
  playerStatus?: PlayerStatusEnum;
  /**
   *
   * @type {CountryAddModel}
   * @memberof ApplicationWithQSFetchModel
   */
  countryOfCitizenship?: CountryAddModel;
  /**
   *
   * @type {EthnicityEnum}
   * @memberof ApplicationWithQSFetchModel
   */
  ethnicity?: EthnicityEnum;
  /**
   *
   * @type {number}
   * @memberof ApplicationWithQSFetchModel
   */
  highSchoolGraduationYear?: number;
  /**
   *
   * @type {CountryAddModel}
   * @memberof ApplicationWithQSFetchModel
   */
  preferredCountry?: CountryAddModel;
  /**
   *
   * @type {string}
   * @memberof ApplicationWithQSFetchModel
   */
  preferredCity?: string;
  /**
   *
   * @type {StateAddModel}
   * @memberof ApplicationWithQSFetchModel
   */
  preferredState?: StateAddModel;
  /**
   *
   * @type {ApplicationPaymentProcessFetchModelHandicap}
   * @memberof ApplicationWithQSFetchModel
   */
  handicap?: ApplicationPaymentProcessFetchModelHandicap;
  /**
   *
   * @type {Array<IdModel>}
   * @memberof ApplicationWithQSFetchModel
   */
  exemption?: Array<IdModel>;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationWithQSFetchModel
   */
  optInPrivacy?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationWithQSFetchModel
   */
  unknownHandicapID?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationWithQSFetchModel
   */
  optInWagr?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationWithQSFetchModel
   */
  antiDopingViolation?: boolean;
  /**
   *
   * @type {Array<ApplicationWithQSFetchModelQualifyingSites>}
   * @memberof ApplicationWithQSFetchModel
   */
  qualifyingSites?: Array<ApplicationWithQSFetchModelQualifyingSites>;
  /**
   *
   * @type {Array<ApplicationFetchModelWaitList>}
   * @memberof ApplicationWithQSFetchModel
   */
  waitList?: Array<ApplicationFetchModelWaitList>;
  /**
   *
   * @type {string}
   * @memberof ApplicationWithQSFetchModel
   */
  wagrRank?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationWithQSFetchModel
   */
  rank?: string;
  /**
   *
   * @type {QualifyingStatusEnum}
   * @memberof ApplicationWithQSFetchModel
   */
  qualifyingStatus?: QualifyingStatusEnum;
  /**
   *
   * @type {string}
   * @memberof ApplicationWithQSFetchModel
   */
  eligibleReinstatementDate?: string;
  /**
   *
   * @type {PaymentDataRequestModel}
   * @memberof ApplicationWithQSFetchModel
   */
  payment?: PaymentDataRequestModel;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationWithQSFetchModel
   */
  adaRequest?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationWithQSFetchModel
   */
  notablePlayer?: boolean;
  /**
   *
   * @type {Array<ApplicationPaymentProcessFetchModelQueueReasons>}
   * @memberof ApplicationWithQSFetchModel
   */
  queueReasons?: Array<ApplicationPaymentProcessFetchModelQueueReasons>;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationWithQSFetchModel
   */
  markAsReviewed?: boolean;
  /**
   *
   * @type {ApplicationBaseAdminModelScoring}
   * @memberof ApplicationWithQSFetchModel
   */
  scoring?: ApplicationBaseAdminModelScoring;
  /**
   *
   * @type {BlacklistStatusEnum}
   * @memberof ApplicationWithQSFetchModel
   */
  blacklistStatus?: BlacklistStatusEnum;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationWithQSFetchModel
   */
  blacklistSendEmail?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationWithQSFetchModel
   */
  watchlist?: boolean;
  /**
   *
   * @type {string}
   * @memberof ApplicationWithQSFetchModel
   */
  watchlistReason?: string;
  /**
   *
   * @type {ContactInfoModel}
   * @memberof ApplicationWithQSFetchModel
   */
  secondaryContactInfo?: ContactInfoModel;
  /**
   *
   * @type {IdModel}
   * @memberof ApplicationWithQSFetchModel
   */
  submittedByPlayer?: IdModel;
  /**
   *
   * @type {ApplicationFetchModelTeam}
   * @memberof ApplicationWithQSFetchModel
   */
  team?: ApplicationFetchModelTeam;
  /**
   *
   * @type {ApplicationFetchModelPair}
   * @memberof ApplicationWithQSFetchModel
   */
  pair?: ApplicationFetchModelPair;
  /**
   *
   * @type {number}
   * @memberof ApplicationWithQSFetchModel
   */
  pairingNumber?: number;
  /**
   *
   * @type {WithdrawalResponseAdminModel}
   * @memberof ApplicationWithQSFetchModel
   */
  withdrawal?: WithdrawalResponseAdminModel;
  /**
   *
   * @type {ApplicationProgressionEnum}
   * @memberof ApplicationWithQSFetchModel
   */
  progression?: ApplicationProgressionEnum;
  /**
   *
   * @type {string}
   * @memberof ApplicationWithQSFetchModel
   */
  reviewDate?: string;
  /**
   * Auth0 id of user who last made transition to reviewed state (enabled reviewDate field)
   * @type {string}
   * @memberof ApplicationWithQSFetchModel
   */
  reviewedBy?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationWithQSFetchModel
   */
  blacklistStatusDate?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationWithQSFetchModel
   */
  blacklistStatusDateCleared?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationWithQSFetchModel
   */
  watchlistDate?: string;
  /**
   *
   * @type {IdModel}
   * @memberof ApplicationWithQSFetchModel
   */
  player?: IdModel;
  /**
   *
   * @type {AuditModel}
   * @memberof ApplicationWithQSFetchModel
   */
  audit?: AuditModel;
  /**
   *
   * @type {NotificationModel}
   * @memberof ApplicationWithQSFetchModel
   */
  notifications?: NotificationModel;
  /**
   *
   * @type {number}
   * @memberof ApplicationWithQSFetchModel
   */
  transfersQuantity?: number;
}
/**
 *
 * @export
 * @interface ApplicationWithQSFetchModelQualifyingSite
 */
export interface ApplicationWithQSFetchModelQualifyingSite {
  /**
   *
   * @type {string}
   * @memberof ApplicationWithQSFetchModelQualifyingSite
   */
  comments?: string;
}
/**
 *
 * @export
 * @interface ApplicationWithQSFetchModelQualifyingSites
 */
export interface ApplicationWithQSFetchModelQualifyingSites {
  /**
   *
   * @type {number}
   * @memberof ApplicationWithQSFetchModelQualifyingSites
   */
  position?: number;
  /**
   *
   * @type {QualifyingSiteFinalizationEnum}
   * @memberof ApplicationWithQSFetchModelQualifyingSites
   */
  finalizedFinalQSite?: QualifyingSiteFinalizationEnum;
  /**
   *
   * @type {ApplicationWithQSFetchModelQualifyingSite}
   * @memberof ApplicationWithQSFetchModelQualifyingSites
   */
  qualifyingSite?: ApplicationWithQSFetchModelQualifyingSite;
}
/**
 *
 * @export
 * @interface ApplicationWithdrawRequestModel
 */
export interface ApplicationWithdrawRequestModel {
  /**
   *
   * @type {number}
   * @memberof ApplicationWithdrawRequestModel
   */
  appId: number;
  /**
   *
   * @type {string}
   * @memberof ApplicationWithdrawRequestModel
   */
  comment: string;
  /**
   *
   * @type {PlayerWithdrawalReasonEnum}
   * @memberof ApplicationWithdrawRequestModel
   */
  withdrawalReason: PlayerWithdrawalReasonEnum;
  /**
   *
   * @type {number}
   * @memberof ApplicationWithdrawRequestModel
   */
  amount: number;
}
/**
 *
 * @export
 * @interface ApplicationWithdrawResponseModel
 */
export interface ApplicationWithdrawResponseModel {
  /**
   *
   * @type {number}
   * @memberof ApplicationWithdrawResponseModel
   */
  applicationId: number;
  /**
   *
   * @type {string}
   * @memberof ApplicationWithdrawResponseModel
   */
  comment: string;
  /**
   *
   * @type {number}
   * @memberof ApplicationWithdrawResponseModel
   */
  queueReasonId: number;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationWithdrawResponseModel
   */
  isAutoWdFailed?: boolean;
  /**
   *
   * @type {string}
   * @memberof ApplicationWithdrawResponseModel
   */
  autoWdErrorMessage?: string;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum AuditEntityType {
  APPLICATION = 'APPLICATION',
  CHAMPIONSHIP = 'CHAMPIONSHIP',
  PLAYER = 'PLAYER',
  TEAM = 'TEAM',
}

/**
 *
 * @export
 * @interface AuditModel
 */
export interface AuditModel {
  /**
   *
   * @type {string}
   * @memberof AuditModel
   */
  dateCreated: string;
  /**
   *
   * @type {string}
   * @memberof AuditModel
   */
  dateModified: string;
}
/**
 *
 * @export
 * @interface AuditNewAdminModel
 */
export interface AuditNewAdminModel {
  /**
   *
   * @type {string}
   * @memberof AuditNewAdminModel
   */
  changeNote: string;
  /**
   *
   * @type {RequestReceivedByEnum}
   * @memberof AuditNewAdminModel
   */
  changeReceivedBy: RequestReceivedByEnum;
}
/**
 *
 * @export
 * @interface AuditNewChampAdminModel
 */
export interface AuditNewChampAdminModel {
  /**
   *
   * @type {string}
   * @memberof AuditNewChampAdminModel
   */
  changeNote: string;
  /**
   *
   * @type {NoteSubjectEnum}
   * @memberof AuditNewChampAdminModel
   */
  changeSubject?: NoteSubjectEnum;
  /**
   *
   * @type {RequestReceivedByEnum}
   * @memberof AuditNewChampAdminModel
   */
  changeReceivedBy?: RequestReceivedByEnum;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum Auth0UserRolesEnum {
  Admin = 'champ-admin-admin',
  Official = 'champ-admin-official',
  Player = 'champ-admin-player',
  Usgastaff = 'champ-admin-usgastaff',
}

/**
 * Represents Back To List Component
 * @export
 * @interface BackToListOfficialHomeModel
 */
export interface BackToListOfficialHomeModel {
  /**
   *
   * @type {string}
   * @memberof BackToListOfficialHomeModel
   */
  officialPagePath?: string;
}
/**
 * Represents Back To List Component
 * @export
 * @interface BackToListPlayerHomeModel
 */
export interface BackToListPlayerHomeModel {
  /**
   *
   * @type {string}
   * @memberof BackToListPlayerHomeModel
   */
  playerHomePath?: string;
}
/**
 * Represents Banner Component
 * @export
 * @interface BannerPlayerHomeModel
 */
export interface BannerPlayerHomeModel {
  /**
   *
   * @type {string}
   * @memberof BannerPlayerHomeModel
   */
  text?: string;
  /**
   *
   * @type {CloudinaryImageModel}
   * @memberof BannerPlayerHomeModel
   */
  backgroundImage?: CloudinaryImageModel;
}
/**
 *
 * @export
 * @interface BaseEventModel
 */
export interface BaseEventModel {
  /**
   *
   * @type {string}
   * @memberof BaseEventModel
   */
  eventId: string;
  /**
   *
   * @type {string}
   * @memberof BaseEventModel
   */
  eventType: string;
}
/**
 *
 * @export
 * @interface BirthdaySectionModel
 */
export interface BirthdaySectionModel {
  /**
   *
   * @type {string}
   * @memberof BirthdaySectionModel
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof BirthdaySectionModel
   */
  generalPrescription: string;
  /**
   *
   * @type {string}
   * @memberof BirthdaySectionModel
   */
  minorBlockPreamble: string;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum BlacklistStatusEnum {
  NOSHOW1 = 'NO_SHOW1',
  NOSHOW2 = 'NO_SHOW2',
  NOSHOW3 = 'NO_SHOW3',
  PERFORMANCEVIOLATION1 = 'PERFORMANCE_VIOLATION_1',
  PERFORMANCEVIOLATION2 = 'PERFORMANCE_VIOLATION_2',
  KEEP = 'KEEP',
  MONEY = 'MONEY',
}

/**
 *
 * @export
 * @interface BulkEntryFeeRequestAdminModel
 */
export interface BulkEntryFeeRequestAdminModel {
  /**
   *
   * @type {Array<number>}
   * @memberof BulkEntryFeeRequestAdminModel
   */
  appIds?: Array<number>;
  /**
   *
   * @type {number}
   * @memberof BulkEntryFeeRequestAdminModel
   */
  champId: number;
  /**
   *
   * @type {Array<number>}
   * @memberof BulkEntryFeeRequestAdminModel
   */
  qsIds?: Array<number>;
  /**
   *
   * @type {Array<ApplicationFiltersEnum>}
   * @memberof BulkEntryFeeRequestAdminModel
   */
  filters?: Array<ApplicationFiltersEnum>;
  /**
   *
   * @type {string}
   * @memberof BulkEntryFeeRequestAdminModel
   */
  query?: string;
  /**
   *
   * @type {BulkEntryFeeTypeEnum}
   * @memberof BulkEntryFeeRequestAdminModel
   */
  actionType: BulkEntryFeeTypeEnum;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum BulkEntryFeeTypeEnum {
  ALL = 'ALL',
  SELECTED = 'SELECTED',
}

/**
 *
 * @export
 * @enum {string}
 */
export enum BulkQualifyingStatusEnum {
  LOCALTOFINAL = 'QUALIFYING_LOCAL_TO_FINAL',
  FINALTOFINALIST = 'QUALIFYING_FINAL_TO_FINALIST',
}

/**
 *
 * @export
 * @interface ChampAdditionalQualifyingInfoModel
 */
export interface ChampAdditionalQualifyingInfoModel {
  /**
   *
   * @type {string}
   * @memberof ChampAdditionalQualifyingInfoModel
   */
  info1?: string;
  /**
   *
   * @type {string}
   * @memberof ChampAdditionalQualifyingInfoModel
   */
  info2?: string;
  /**
   *
   * @type {string}
   * @memberof ChampAdditionalQualifyingInfoModel
   */
  info3?: string;
}
/**
 * Represents championship
 * @export
 * @interface ChampCustomEmailPlFetchModel
 */
export interface ChampCustomEmailPlFetchModel {
  /**
   *
   * @type {number}
   * @memberof ChampCustomEmailPlFetchModel
   */
  id?: number;
  /**
   *
   * @type {ChampionshipAgeLimitModel}
   * @memberof ChampCustomEmailPlFetchModel
   */
  ageLimit?: ChampionshipAgeLimitModel;
  /**
   *
   * @type {string}
   * @memberof ChampCustomEmailPlFetchModel
   */
  ageLimitValidateDate?: string;
  /**
   *
   * @type {GenderEnum}
   * @memberof ChampCustomEmailPlFetchModel
   */
  gender?: GenderEnum;
  /**
   *
   * @type {ChampionshipOppositeGenderModel}
   * @memberof ChampCustomEmailPlFetchModel
   */
  oppositeGender?: ChampionshipOppositeGenderModel;
  /**
   *
   * @type {ChampionshipTypeModel}
   * @memberof ChampCustomEmailPlFetchModel
   */
  championshipType?: ChampionshipTypeModel;
  /**
   *
   * @type {Array<ChampCustomEmailPlFetchModelBlacklists>}
   * @memberof ChampCustomEmailPlFetchModel
   */
  blacklists?: Array<ChampCustomEmailPlFetchModelBlacklists>;
}
/**
 *
 * @export
 * @interface ChampCustomEmailPlFetchModelBlacklists
 */
export interface ChampCustomEmailPlFetchModelBlacklists {
  /**
   *
   * @type {number}
   * @memberof ChampCustomEmailPlFetchModelBlacklists
   */
  id?: number;
  /**
   *
   * @type {BlacklistStatusEnum}
   * @memberof ChampCustomEmailPlFetchModelBlacklists
   */
  blacklistStatus?: BlacklistStatusEnum;
}
/**
 * Represents Championship Age Limit Model
 * @export
 * @interface ChampOverviewInfoModel
 */
export interface ChampOverviewInfoModel {
  /**
   *
   * @type {string}
   * @memberof ChampOverviewInfoModel
   */
  applyToPlay?: string;
  /**
   *
   * @type {string}
   * @memberof ChampOverviewInfoModel
   */
  informationalPDF?: string;
}
/**
 *
 * @export
 * @interface ChampTypeListModel
 */
export interface ChampTypeListModel {
  /**
   *
   * @type {Array<ChampionshipTypeModel>}
   * @memberof ChampTypeListModel
   */
  items: Array<ChampionshipTypeModel>;
  /**
   *
   * @type {number}
   * @memberof ChampTypeListModel
   */
  limit: number;
  /**
   *
   * @type {number}
   * @memberof ChampTypeListModel
   */
  start: number;
}
/**
 *
 * @export
 * @interface ChampTypeTitleIdListModel
 */
export interface ChampTypeTitleIdListModel {
  /**
   *
   * @type {Array<ApplicationResponseModelAllOfQueueReason>}
   * @memberof ChampTypeTitleIdListModel
   */
  items?: Array<ApplicationResponseModelAllOfQueueReason>;
  /**
   *
   * @type {number}
   * @memberof ChampTypeTitleIdListModel
   */
  limit: number;
  /**
   *
   * @type {number}
   * @memberof ChampTypeTitleIdListModel
   */
  start: number;
}
/**
 *
 * @export
 * @interface ChampionshipAddModel
 */
export interface ChampionshipAddModel {
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipAddModel
   */
  active: boolean;
  /**
   *
   * @type {ChampionshipRootModelFinalSiteControl}
   * @memberof ChampionshipAddModel
   */
  finalSiteControl?: ChampionshipRootModelFinalSiteControl;
  /**
   *
   * @type {InfoPDF}
   * @memberof ChampionshipAddModel
   */
  infoPDF?: InfoPDF;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipAddModel
   */
  antiDoping?: boolean;
  /**
   *
   * @type {string}
   * @memberof ChampionshipAddModel
   */
  backgroundImage?: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipAddModel
   */
  logo?: string;
  /**
   *
   * @type {number}
   * @memberof ChampionshipAddModel
   */
  championshipEntryFee?: number;
  /**
   *
   * @type {number}
   * @memberof ChampionshipAddModel
   */
  qualifyingEntryFee?: number;
  /**
   *
   * @type {number}
   * @memberof ChampionshipAddModel
   */
  waitlistFee?: number;
  /**
   *
   * @type {ChampionshipAgeLimitModel}
   * @memberof ChampionshipAddModel
   */
  ageLimit?: ChampionshipAgeLimitModel;
  /**
   *
   * @type {string}
   * @memberof ChampionshipAddModel
   */
  ageLimitBannerText?: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipAddModel
   */
  ageLimitValidateDate: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipAddModel
   */
  applicationStartDate?: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipAddModel
   */
  applicationEndDate?: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipAddModel
   */
  endDate: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipAddModel
   */
  startDate: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipAddModel
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipAddModel
   */
  overviewUrl?: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipAddModel
   */
  qualifyingStartDate?: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipAddModel
   */
  qualifyingEndDate?: string;
  /**
   *
   * @type {GenderEnum}
   * @memberof ChampionshipAddModel
   */
  gender: GenderEnum;
  /**
   *
   * @type {number}
   * @memberof ChampionshipAddModel
   */
  maxHandicapIndex: number;
  /**
   *
   * @type {string}
   * @memberof ChampionshipAddModel
   */
  maxHandicapIndexOverflowMessage: string;
  /**
   *
   * @type {ChampionshipMinorityModel}
   * @memberof ChampionshipAddModel
   */
  minority: ChampionshipMinorityModel;
  /**
   *
   * @type {ChampionshipOppositeGenderModel}
   * @memberof ChampionshipAddModel
   */
  oppositeGender: ChampionshipOppositeGenderModel;
  /**
   *
   * @type {string}
   * @memberof ChampionshipAddModel
   */
  localQualifyingStartDate?: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipAddModel
   */
  localQualifyingEndDate?: string;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipAddModel
   */
  stubTextEnabled?: boolean;
  /**
   *
   * @type {string}
   * @memberof ChampionshipAddModel
   */
  stubText?: string;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipAddModel
   */
  allExempt?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipAddModel
   */
  localQualifyingDisabled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipAddModel
   */
  localExemptionDisabled?: boolean;
  /**
   *
   * @type {ChampAdditionalQualifyingInfoModel}
   * @memberof ChampionshipAddModel
   */
  additionalQualifyingInfo?: ChampAdditionalQualifyingInfoModel;
  /**
   *
   * @type {ChampOverviewInfoModel}
   * @memberof ChampionshipAddModel
   */
  overviewInfo?: ChampOverviewInfoModel;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipAddModel
   */
  sendSurveyEmail?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipAddModel
   */
  showWaitlistedPlayersCTA?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipAddModel
   */
  showReplacementAfterCloseCTA?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipAddModel
   */
  hideQualifyingSites?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipAddModel
   */
  hideSneakPeek?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipAddModel
   */
  autoNotificationsToAGAsEnabled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipAddModel
   */
  autoWDWhenPerfViolation?: boolean;
  /**
   *
   * @type {IdModel}
   * @memberof ChampionshipAddModel
   */
  championshipType: IdModel;
  /**
   *
   * @type {IdModel}
   * @memberof ChampionshipAddModel
   */
  club: IdModel;
  /**
   *
   * @type {Array<IdModel>}
   * @memberof ChampionshipAddModel
   */
  eligibleLateApplyPlayers?: Array<IdModel>;
  /**
   *
   * @type {string}
   * @memberof ChampionshipAddModel
   */
  lateApplyDeadlineDate?: string;
}
/**
 *
 * @export
 * @interface ChampionshipAddModelAllOf
 */
export interface ChampionshipAddModelAllOf {
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipAddModelAllOf
   */
  hideQualifyingSites?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipAddModelAllOf
   */
  hideSneakPeek?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipAddModelAllOf
   */
  autoNotificationsToAGAsEnabled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipAddModelAllOf
   */
  autoWDWhenPerfViolation?: boolean;
  /**
   *
   * @type {IdModel}
   * @memberof ChampionshipAddModelAllOf
   */
  championshipType: IdModel;
  /**
   *
   * @type {IdModel}
   * @memberof ChampionshipAddModelAllOf
   */
  club: IdModel;
  /**
   *
   * @type {Array<IdModel>}
   * @memberof ChampionshipAddModelAllOf
   */
  eligibleLateApplyPlayers?: Array<IdModel>;
  /**
   *
   * @type {string}
   * @memberof ChampionshipAddModelAllOf
   */
  lateApplyDeadlineDate?: string;
}
/**
 *
 * @export
 * @interface ChampionshipAdminListModel
 */
export interface ChampionshipAdminListModel {
  /**
   *
   * @type {Array<ChampionshipAdminModel>}
   * @memberof ChampionshipAdminListModel
   */
  items: Array<ChampionshipAdminModel>;
  /**
   *
   * @type {number}
   * @memberof ChampionshipAdminListModel
   */
  limit: number;
  /**
   *
   * @type {number}
   * @memberof ChampionshipAdminListModel
   */
  start: number;
  /**
   *
   * @type {number}
   * @memberof ChampionshipAdminListModel
   */
  total: number;
}
/**
 *
 * @export
 * @interface ChampionshipAdminModel
 */
export interface ChampionshipAdminModel {
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipAdminModel
   */
  active: boolean;
  /**
   *
   * @type {ChampionshipRootModelFinalSiteControl}
   * @memberof ChampionshipAdminModel
   */
  finalSiteControl?: ChampionshipRootModelFinalSiteControl;
  /**
   *
   * @type {InfoPDF}
   * @memberof ChampionshipAdminModel
   */
  infoPDF?: InfoPDF;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipAdminModel
   */
  antiDoping?: boolean;
  /**
   *
   * @type {string}
   * @memberof ChampionshipAdminModel
   */
  backgroundImage?: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipAdminModel
   */
  logo?: string;
  /**
   *
   * @type {number}
   * @memberof ChampionshipAdminModel
   */
  championshipEntryFee?: number;
  /**
   *
   * @type {number}
   * @memberof ChampionshipAdminModel
   */
  qualifyingEntryFee?: number;
  /**
   *
   * @type {number}
   * @memberof ChampionshipAdminModel
   */
  waitlistFee?: number;
  /**
   *
   * @type {ChampionshipAgeLimitModel}
   * @memberof ChampionshipAdminModel
   */
  ageLimit?: ChampionshipAgeLimitModel;
  /**
   *
   * @type {string}
   * @memberof ChampionshipAdminModel
   */
  ageLimitBannerText?: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipAdminModel
   */
  ageLimitValidateDate: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipAdminModel
   */
  applicationStartDate?: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipAdminModel
   */
  applicationEndDate?: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipAdminModel
   */
  endDate: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipAdminModel
   */
  startDate: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipAdminModel
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipAdminModel
   */
  overviewUrl?: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipAdminModel
   */
  qualifyingStartDate?: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipAdminModel
   */
  qualifyingEndDate?: string;
  /**
   *
   * @type {GenderEnum}
   * @memberof ChampionshipAdminModel
   */
  gender: GenderEnum;
  /**
   *
   * @type {number}
   * @memberof ChampionshipAdminModel
   */
  maxHandicapIndex: number;
  /**
   *
   * @type {string}
   * @memberof ChampionshipAdminModel
   */
  maxHandicapIndexOverflowMessage: string;
  /**
   *
   * @type {ChampionshipMinorityModel}
   * @memberof ChampionshipAdminModel
   */
  minority: ChampionshipMinorityModel;
  /**
   *
   * @type {ChampionshipOppositeGenderModel}
   * @memberof ChampionshipAdminModel
   */
  oppositeGender: ChampionshipOppositeGenderModel;
  /**
   *
   * @type {string}
   * @memberof ChampionshipAdminModel
   */
  localQualifyingStartDate?: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipAdminModel
   */
  localQualifyingEndDate?: string;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipAdminModel
   */
  stubTextEnabled?: boolean;
  /**
   *
   * @type {string}
   * @memberof ChampionshipAdminModel
   */
  stubText?: string;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipAdminModel
   */
  allExempt?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipAdminModel
   */
  localQualifyingDisabled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipAdminModel
   */
  localExemptionDisabled?: boolean;
  /**
   *
   * @type {ChampAdditionalQualifyingInfoModel}
   * @memberof ChampionshipAdminModel
   */
  additionalQualifyingInfo?: ChampAdditionalQualifyingInfoModel;
  /**
   *
   * @type {ChampOverviewInfoModel}
   * @memberof ChampionshipAdminModel
   */
  overviewInfo?: ChampOverviewInfoModel;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipAdminModel
   */
  sendSurveyEmail?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipAdminModel
   */
  showWaitlistedPlayersCTA?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipAdminModel
   */
  showReplacementAfterCloseCTA?: boolean;
  /**
   *
   * @type {number}
   * @memberof ChampionshipAdminModel
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof ChampionshipAdminModel
   */
  year: number;
  /**
   *
   * @type {ClubAdminModel}
   * @memberof ChampionshipAdminModel
   */
  club: ClubAdminModel;
  /**
   *
   * @type {ChampionshipTypeModel}
   * @memberof ChampionshipAdminModel
   */
  championshipType: ChampionshipTypeModel;
  /**
   *
   * @type {ChampionshipButtonControlModel}
   * @memberof ChampionshipAdminModel
   */
  currentPlayerListControl?: ChampionshipButtonControlModel;
  /**
   *
   * @type {ChampionshipButtonControlModel}
   * @memberof ChampionshipAdminModel
   */
  playersWithdrawnControl?: ChampionshipButtonControlModel;
  /**
   *
   * @type {ChampionshipButtonControlModel}
   * @memberof ChampionshipAdminModel
   */
  uploadResultsControl?: ChampionshipButtonControlModel;
  /**
   *
   * @type {ChampionshipButtonControlModel}
   * @memberof ChampionshipAdminModel
   */
  staffCurrentChampField?: ChampionshipButtonControlModel;
  /**
   *
   * @type {ChampionshipButtonControlModel}
   * @memberof ChampionshipAdminModel
   */
  staffChampReports?: ChampionshipButtonControlModel;
  /**
   *
   * @type {ChampionshipButtonControlModel}
   * @memberof ChampionshipAdminModel
   */
  staffCurrentAlternates?: ChampionshipButtonControlModel;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipAdminModel
   */
  allEntriesClosed: boolean;
  /**
   *
   * @type {Array<QualifyingSiteAdminModel>}
   * @memberof ChampionshipAdminModel
   */
  qualifyingSites?: Array<QualifyingSiteAdminModel>;
  /**
   *
   * @type {Array<ChampionshipAdminModelAllOfEligibleLateApplyPlayers>}
   * @memberof ChampionshipAdminModel
   */
  eligibleLateApplyPlayers?: Array<ChampionshipAdminModelAllOfEligibleLateApplyPlayers>;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipAdminModel
   */
  hideQualifyingSites?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipAdminModel
   */
  hideSneakPeek?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipAdminModel
   */
  autoNotificationsToAGAsEnabled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipAdminModel
   */
  autoWDWhenPerfViolation?: boolean;
  /**
   *
   * @type {string}
   * @memberof ChampionshipAdminModel
   */
  lateApplyDeadlineDate?: string;
  /**
   *
   * @type {UploadFileProgressionEnum}
   * @memberof ChampionshipAdminModel
   */
  qrProgression?: UploadFileProgressionEnum;
}
/**
 *
 * @export
 * @interface ChampionshipAdminModelAllOf
 */
export interface ChampionshipAdminModelAllOf {
  /**
   *
   * @type {Array<QualifyingSiteAdminModel>}
   * @memberof ChampionshipAdminModelAllOf
   */
  qualifyingSites?: Array<QualifyingSiteAdminModel>;
  /**
   *
   * @type {Array<ChampionshipAdminModelAllOfEligibleLateApplyPlayers>}
   * @memberof ChampionshipAdminModelAllOf
   */
  eligibleLateApplyPlayers?: Array<ChampionshipAdminModelAllOfEligibleLateApplyPlayers>;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipAdminModelAllOf
   */
  hideQualifyingSites?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipAdminModelAllOf
   */
  hideSneakPeek?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipAdminModelAllOf
   */
  autoNotificationsToAGAsEnabled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipAdminModelAllOf
   */
  autoWDWhenPerfViolation?: boolean;
  /**
   *
   * @type {string}
   * @memberof ChampionshipAdminModelAllOf
   */
  lateApplyDeadlineDate?: string;
  /**
   *
   * @type {UploadFileProgressionEnum}
   * @memberof ChampionshipAdminModelAllOf
   */
  qrProgression?: UploadFileProgressionEnum;
}
/**
 *
 * @export
 * @interface ChampionshipAdminModelAllOfEligibleLateApplyPlayers
 */
export interface ChampionshipAdminModelAllOfEligibleLateApplyPlayers {
  /**
   *
   * @type {number}
   * @memberof ChampionshipAdminModelAllOfEligibleLateApplyPlayers
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof ChampionshipAdminModelAllOfEligibleLateApplyPlayers
   */
  dob?: string;
  /**
   *
   * @type {PlayerStatusEnum}
   * @memberof ChampionshipAdminModelAllOfEligibleLateApplyPlayers
   */
  playerStatus?: PlayerStatusEnum;
  /**
   *
   * @type {ChampionshipAdminModelAllOfPrimaryContactInfo}
   * @memberof ChampionshipAdminModelAllOfEligibleLateApplyPlayers
   */
  primaryContactInfo?: ChampionshipAdminModelAllOfPrimaryContactInfo;
}
/**
 * use this as read-only
 * @export
 * @interface ChampionshipAdminModelAllOfPrimaryContactInfo
 */
export interface ChampionshipAdminModelAllOfPrimaryContactInfo {
  /**
   *
   * @type {string}
   * @memberof ChampionshipAdminModelAllOfPrimaryContactInfo
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipAdminModelAllOfPrimaryContactInfo
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipAdminModelAllOfPrimaryContactInfo
   */
  email?: string;
}
/**
 * Represents Championship Age Limit Model
 * @export
 * @interface ChampionshipAgeLimitModel
 */
export interface ChampionshipAgeLimitModel {
  /**
   *
   * @type {number}
   * @memberof ChampionshipAgeLimitModel
   */
  min?: number;
  /**
   *
   * @type {number}
   * @memberof ChampionshipAgeLimitModel
   */
  max?: number;
  /**
   *
   * @type {string}
   * @memberof ChampionshipAgeLimitModel
   */
  minMessage?: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipAgeLimitModel
   */
  maxMessage?: string;
}
/**
 *
 * @export
 * @interface ChampionshipBaseModel
 */
export interface ChampionshipBaseModel {
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipBaseModel
   */
  active: boolean;
  /**
   *
   * @type {ChampionshipRootModelFinalSiteControl}
   * @memberof ChampionshipBaseModel
   */
  finalSiteControl?: ChampionshipRootModelFinalSiteControl;
  /**
   *
   * @type {InfoPDF}
   * @memberof ChampionshipBaseModel
   */
  infoPDF?: InfoPDF;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipBaseModel
   */
  antiDoping?: boolean;
  /**
   *
   * @type {string}
   * @memberof ChampionshipBaseModel
   */
  backgroundImage?: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipBaseModel
   */
  logo?: string;
  /**
   *
   * @type {number}
   * @memberof ChampionshipBaseModel
   */
  championshipEntryFee?: number;
  /**
   *
   * @type {number}
   * @memberof ChampionshipBaseModel
   */
  qualifyingEntryFee?: number;
  /**
   *
   * @type {number}
   * @memberof ChampionshipBaseModel
   */
  waitlistFee?: number;
  /**
   *
   * @type {ChampionshipAgeLimitModel}
   * @memberof ChampionshipBaseModel
   */
  ageLimit?: ChampionshipAgeLimitModel;
  /**
   *
   * @type {string}
   * @memberof ChampionshipBaseModel
   */
  ageLimitBannerText?: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipBaseModel
   */
  ageLimitValidateDate: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipBaseModel
   */
  applicationStartDate?: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipBaseModel
   */
  applicationEndDate?: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipBaseModel
   */
  endDate: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipBaseModel
   */
  startDate: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipBaseModel
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipBaseModel
   */
  overviewUrl?: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipBaseModel
   */
  qualifyingStartDate?: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipBaseModel
   */
  qualifyingEndDate?: string;
  /**
   *
   * @type {GenderEnum}
   * @memberof ChampionshipBaseModel
   */
  gender: GenderEnum;
  /**
   *
   * @type {number}
   * @memberof ChampionshipBaseModel
   */
  maxHandicapIndex: number;
  /**
   *
   * @type {string}
   * @memberof ChampionshipBaseModel
   */
  maxHandicapIndexOverflowMessage: string;
  /**
   *
   * @type {ChampionshipMinorityModel}
   * @memberof ChampionshipBaseModel
   */
  minority: ChampionshipMinorityModel;
  /**
   *
   * @type {ChampionshipOppositeGenderModel}
   * @memberof ChampionshipBaseModel
   */
  oppositeGender: ChampionshipOppositeGenderModel;
  /**
   *
   * @type {string}
   * @memberof ChampionshipBaseModel
   */
  localQualifyingStartDate?: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipBaseModel
   */
  localQualifyingEndDate?: string;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipBaseModel
   */
  stubTextEnabled?: boolean;
  /**
   *
   * @type {string}
   * @memberof ChampionshipBaseModel
   */
  stubText?: string;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipBaseModel
   */
  allExempt?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipBaseModel
   */
  localQualifyingDisabled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipBaseModel
   */
  localExemptionDisabled?: boolean;
  /**
   *
   * @type {ChampAdditionalQualifyingInfoModel}
   * @memberof ChampionshipBaseModel
   */
  additionalQualifyingInfo?: ChampAdditionalQualifyingInfoModel;
  /**
   *
   * @type {ChampOverviewInfoModel}
   * @memberof ChampionshipBaseModel
   */
  overviewInfo?: ChampOverviewInfoModel;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipBaseModel
   */
  sendSurveyEmail?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipBaseModel
   */
  showWaitlistedPlayersCTA?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipBaseModel
   */
  showReplacementAfterCloseCTA?: boolean;
  /**
   *
   * @type {number}
   * @memberof ChampionshipBaseModel
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof ChampionshipBaseModel
   */
  year: number;
  /**
   *
   * @type {ClubAdminModel}
   * @memberof ChampionshipBaseModel
   */
  club: ClubAdminModel;
  /**
   *
   * @type {ChampionshipTypeModel}
   * @memberof ChampionshipBaseModel
   */
  championshipType: ChampionshipTypeModel;
  /**
   *
   * @type {ChampionshipButtonControlModel}
   * @memberof ChampionshipBaseModel
   */
  currentPlayerListControl?: ChampionshipButtonControlModel;
  /**
   *
   * @type {ChampionshipButtonControlModel}
   * @memberof ChampionshipBaseModel
   */
  playersWithdrawnControl?: ChampionshipButtonControlModel;
  /**
   *
   * @type {ChampionshipButtonControlModel}
   * @memberof ChampionshipBaseModel
   */
  uploadResultsControl?: ChampionshipButtonControlModel;
  /**
   *
   * @type {ChampionshipButtonControlModel}
   * @memberof ChampionshipBaseModel
   */
  staffCurrentChampField?: ChampionshipButtonControlModel;
  /**
   *
   * @type {ChampionshipButtonControlModel}
   * @memberof ChampionshipBaseModel
   */
  staffChampReports?: ChampionshipButtonControlModel;
  /**
   *
   * @type {ChampionshipButtonControlModel}
   * @memberof ChampionshipBaseModel
   */
  staffCurrentAlternates?: ChampionshipButtonControlModel;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipBaseModel
   */
  allEntriesClosed: boolean;
}
/**
 *
 * @export
 * @interface ChampionshipBaseModelAllOf
 */
export interface ChampionshipBaseModelAllOf {
  /**
   *
   * @type {number}
   * @memberof ChampionshipBaseModelAllOf
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof ChampionshipBaseModelAllOf
   */
  year: number;
  /**
   *
   * @type {ClubAdminModel}
   * @memberof ChampionshipBaseModelAllOf
   */
  club: ClubAdminModel;
  /**
   *
   * @type {ChampionshipTypeModel}
   * @memberof ChampionshipBaseModelAllOf
   */
  championshipType: ChampionshipTypeModel;
  /**
   *
   * @type {ChampionshipButtonControlModel}
   * @memberof ChampionshipBaseModelAllOf
   */
  currentPlayerListControl?: ChampionshipButtonControlModel;
  /**
   *
   * @type {ChampionshipButtonControlModel}
   * @memberof ChampionshipBaseModelAllOf
   */
  playersWithdrawnControl?: ChampionshipButtonControlModel;
  /**
   *
   * @type {ChampionshipButtonControlModel}
   * @memberof ChampionshipBaseModelAllOf
   */
  uploadResultsControl?: ChampionshipButtonControlModel;
  /**
   *
   * @type {ChampionshipButtonControlModel}
   * @memberof ChampionshipBaseModelAllOf
   */
  staffCurrentChampField?: ChampionshipButtonControlModel;
  /**
   *
   * @type {ChampionshipButtonControlModel}
   * @memberof ChampionshipBaseModelAllOf
   */
  staffChampReports?: ChampionshipButtonControlModel;
  /**
   *
   * @type {ChampionshipButtonControlModel}
   * @memberof ChampionshipBaseModelAllOf
   */
  staffCurrentAlternates?: ChampionshipButtonControlModel;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipBaseModelAllOf
   */
  allEntriesClosed: boolean;
}
/**
 *
 * @export
 * @interface ChampionshipButtonControlModel
 */
export interface ChampionshipButtonControlModel {
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipButtonControlModel
   */
  auto?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipButtonControlModel
   */
  buttonEnabled?: boolean;
}
/**
 *
 * @export
 * @interface ChampionshipEditModel
 */
export interface ChampionshipEditModel {
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipEditModel
   */
  active: boolean;
  /**
   *
   * @type {ChampionshipRootModelFinalSiteControl}
   * @memberof ChampionshipEditModel
   */
  finalSiteControl?: ChampionshipRootModelFinalSiteControl;
  /**
   *
   * @type {InfoPDF}
   * @memberof ChampionshipEditModel
   */
  infoPDF?: InfoPDF;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipEditModel
   */
  antiDoping?: boolean;
  /**
   *
   * @type {string}
   * @memberof ChampionshipEditModel
   */
  backgroundImage?: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipEditModel
   */
  logo?: string;
  /**
   *
   * @type {number}
   * @memberof ChampionshipEditModel
   */
  championshipEntryFee?: number;
  /**
   *
   * @type {number}
   * @memberof ChampionshipEditModel
   */
  qualifyingEntryFee?: number;
  /**
   *
   * @type {number}
   * @memberof ChampionshipEditModel
   */
  waitlistFee?: number;
  /**
   *
   * @type {ChampionshipAgeLimitModel}
   * @memberof ChampionshipEditModel
   */
  ageLimit?: ChampionshipAgeLimitModel;
  /**
   *
   * @type {string}
   * @memberof ChampionshipEditModel
   */
  ageLimitBannerText?: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipEditModel
   */
  ageLimitValidateDate: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipEditModel
   */
  applicationStartDate?: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipEditModel
   */
  applicationEndDate?: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipEditModel
   */
  endDate: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipEditModel
   */
  startDate: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipEditModel
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipEditModel
   */
  overviewUrl?: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipEditModel
   */
  qualifyingStartDate?: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipEditModel
   */
  qualifyingEndDate?: string;
  /**
   *
   * @type {GenderEnum}
   * @memberof ChampionshipEditModel
   */
  gender: GenderEnum;
  /**
   *
   * @type {number}
   * @memberof ChampionshipEditModel
   */
  maxHandicapIndex: number;
  /**
   *
   * @type {string}
   * @memberof ChampionshipEditModel
   */
  maxHandicapIndexOverflowMessage: string;
  /**
   *
   * @type {ChampionshipMinorityModel}
   * @memberof ChampionshipEditModel
   */
  minority: ChampionshipMinorityModel;
  /**
   *
   * @type {ChampionshipOppositeGenderModel}
   * @memberof ChampionshipEditModel
   */
  oppositeGender: ChampionshipOppositeGenderModel;
  /**
   *
   * @type {string}
   * @memberof ChampionshipEditModel
   */
  localQualifyingStartDate?: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipEditModel
   */
  localQualifyingEndDate?: string;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipEditModel
   */
  stubTextEnabled?: boolean;
  /**
   *
   * @type {string}
   * @memberof ChampionshipEditModel
   */
  stubText?: string;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipEditModel
   */
  allExempt?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipEditModel
   */
  localQualifyingDisabled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipEditModel
   */
  localExemptionDisabled?: boolean;
  /**
   *
   * @type {ChampAdditionalQualifyingInfoModel}
   * @memberof ChampionshipEditModel
   */
  additionalQualifyingInfo?: ChampAdditionalQualifyingInfoModel;
  /**
   *
   * @type {ChampOverviewInfoModel}
   * @memberof ChampionshipEditModel
   */
  overviewInfo?: ChampOverviewInfoModel;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipEditModel
   */
  sendSurveyEmail?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipEditModel
   */
  showWaitlistedPlayersCTA?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipEditModel
   */
  showReplacementAfterCloseCTA?: boolean;
  /**
   *
   * @type {number}
   * @memberof ChampionshipEditModel
   */
  id: number;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipEditModel
   */
  hideQualifyingSites?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipEditModel
   */
  hideSneakPeek?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipEditModel
   */
  autoNotificationsToAGAsEnabled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipEditModel
   */
  autoWDWhenPerfViolation?: boolean;
  /**
   *
   * @type {IdModel}
   * @memberof ChampionshipEditModel
   */
  club: IdModel;
  /**
   *
   * @type {AuditNewChampAdminModel}
   * @memberof ChampionshipEditModel
   */
  audit: AuditNewChampAdminModel;
  /**
   *
   * @type {ChampionshipButtonControlModel}
   * @memberof ChampionshipEditModel
   */
  currentPlayerListControl: ChampionshipButtonControlModel;
  /**
   *
   * @type {ChampionshipButtonControlModel}
   * @memberof ChampionshipEditModel
   */
  playersWithdrawnControl: ChampionshipButtonControlModel;
  /**
   *
   * @type {ChampionshipButtonControlModel}
   * @memberof ChampionshipEditModel
   */
  uploadResultsControl: ChampionshipButtonControlModel;
  /**
   *
   * @type {ChampionshipButtonControlModel}
   * @memberof ChampionshipEditModel
   */
  staffCurrentChampField: ChampionshipButtonControlModel;
  /**
   *
   * @type {ChampionshipButtonControlModel}
   * @memberof ChampionshipEditModel
   */
  staffChampReports: ChampionshipButtonControlModel;
  /**
   *
   * @type {ChampionshipButtonControlModel}
   * @memberof ChampionshipEditModel
   */
  staffCurrentAlternates: ChampionshipButtonControlModel;
  /**
   *
   * @type {Array<IdModel>}
   * @memberof ChampionshipEditModel
   */
  eligibleLateApplyPlayers?: Array<IdModel>;
  /**
   *
   * @type {string}
   * @memberof ChampionshipEditModel
   */
  lateApplyDeadlineDate?: string;
}
/**
 *
 * @export
 * @interface ChampionshipEditModelAllOf
 */
export interface ChampionshipEditModelAllOf {
  /**
   *
   * @type {number}
   * @memberof ChampionshipEditModelAllOf
   */
  id: number;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipEditModelAllOf
   */
  hideQualifyingSites?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipEditModelAllOf
   */
  hideSneakPeek?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipEditModelAllOf
   */
  autoNotificationsToAGAsEnabled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipEditModelAllOf
   */
  autoWDWhenPerfViolation?: boolean;
  /**
   *
   * @type {IdModel}
   * @memberof ChampionshipEditModelAllOf
   */
  club: IdModel;
  /**
   *
   * @type {AuditNewChampAdminModel}
   * @memberof ChampionshipEditModelAllOf
   */
  audit: AuditNewChampAdminModel;
  /**
   *
   * @type {ChampionshipButtonControlModel}
   * @memberof ChampionshipEditModelAllOf
   */
  currentPlayerListControl: ChampionshipButtonControlModel;
  /**
   *
   * @type {ChampionshipButtonControlModel}
   * @memberof ChampionshipEditModelAllOf
   */
  playersWithdrawnControl: ChampionshipButtonControlModel;
  /**
   *
   * @type {ChampionshipButtonControlModel}
   * @memberof ChampionshipEditModelAllOf
   */
  uploadResultsControl: ChampionshipButtonControlModel;
  /**
   *
   * @type {ChampionshipButtonControlModel}
   * @memberof ChampionshipEditModelAllOf
   */
  staffCurrentChampField: ChampionshipButtonControlModel;
  /**
   *
   * @type {ChampionshipButtonControlModel}
   * @memberof ChampionshipEditModelAllOf
   */
  staffChampReports: ChampionshipButtonControlModel;
  /**
   *
   * @type {ChampionshipButtonControlModel}
   * @memberof ChampionshipEditModelAllOf
   */
  staffCurrentAlternates: ChampionshipButtonControlModel;
  /**
   *
   * @type {Array<IdModel>}
   * @memberof ChampionshipEditModelAllOf
   */
  eligibleLateApplyPlayers?: Array<IdModel>;
  /**
   *
   * @type {string}
   * @memberof ChampionshipEditModelAllOf
   */
  lateApplyDeadlineDate?: string;
}
/**
 *
 * @export
 * @interface ChampionshipEntriesQuantity
 */
export interface ChampionshipEntriesQuantity {
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipEntriesQuantity
   */
  allEntriesClosed?: boolean;
  /**
   *
   * @type {number}
   * @memberof ChampionshipEntriesQuantity
   */
  totalApps?: number;
  /**
   *
   * @type {number}
   * @memberof ChampionshipEntriesQuantity
   */
  activeApps?: number;
  /**
   *
   * @type {number}
   * @memberof ChampionshipEntriesQuantity
   */
  totalWithdrawn?: number;
  /**
   *
   * @type {number}
   * @memberof ChampionshipEntriesQuantity
   */
  totalRedboxes?: number;
  /**
   *
   * @type {number}
   * @memberof ChampionshipEntriesQuantity
   */
  totalQs?: number;
  /**
   *
   * @type {number}
   * @memberof ChampionshipEntriesQuantity
   */
  totalNeedsReview?: number;
  /**
   *
   * @type {number}
   * @memberof ChampionshipEntriesQuantity
   */
  totalReviewed?: number;
}
/**
 * Represents championship
 * @export
 * @interface ChampionshipFetchModel
 */
export interface ChampionshipFetchModel {
  /**
   *
   * @type {number}
   * @memberof ChampionshipFetchModel
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof ChampionshipFetchModel
   */
  position?: number;
  /**
   *
   * @type {AuditNewChampAdminModel}
   * @memberof ChampionshipFetchModel
   */
  audit?: AuditNewChampAdminModel;
  /**
   *
   * @type {number}
   * @memberof ChampionshipFetchModel
   */
  year?: number;
  /**
   *
   * @type {ClubAdminModel}
   * @memberof ChampionshipFetchModel
   */
  club?: ClubAdminModel;
  /**
   *
   * @type {ChampionshipTypeModel}
   * @memberof ChampionshipFetchModel
   */
  championshipType?: ChampionshipTypeModel;
  /**
   *
   * @type {ChampionshipButtonControlModel}
   * @memberof ChampionshipFetchModel
   */
  currentPlayerListControl?: ChampionshipButtonControlModel;
  /**
   *
   * @type {ChampionshipButtonControlModel}
   * @memberof ChampionshipFetchModel
   */
  playersWithdrawnControl?: ChampionshipButtonControlModel;
  /**
   *
   * @type {ChampionshipButtonControlModel}
   * @memberof ChampionshipFetchModel
   */
  uploadResultsControl?: ChampionshipButtonControlModel;
  /**
   *
   * @type {ChampionshipButtonControlModel}
   * @memberof ChampionshipFetchModel
   */
  staffCurrentChampField?: ChampionshipButtonControlModel;
  /**
   *
   * @type {ChampionshipButtonControlModel}
   * @memberof ChampionshipFetchModel
   */
  staffChampReports?: ChampionshipButtonControlModel;
  /**
   *
   * @type {ChampionshipButtonControlModel}
   * @memberof ChampionshipFetchModel
   */
  staffCurrentAlternates?: ChampionshipButtonControlModel;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipFetchModel
   */
  active?: boolean;
  /**
   *
   * @type {ChampionshipRootModelFinalSiteControl}
   * @memberof ChampionshipFetchModel
   */
  finalSiteControl?: ChampionshipRootModelFinalSiteControl;
  /**
   *
   * @type {InfoPDF}
   * @memberof ChampionshipFetchModel
   */
  infoPDF?: InfoPDF;
  /**
   *
   * @type {ChampionshipEntriesQuantity}
   * @memberof ChampionshipFetchModel
   */
  counters?: ChampionshipEntriesQuantity;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipFetchModel
   */
  antiDoping?: boolean;
  /**
   *
   * @type {string}
   * @memberof ChampionshipFetchModel
   */
  backgroundImage?: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipFetchModel
   */
  logo?: string;
  /**
   *
   * @type {number}
   * @memberof ChampionshipFetchModel
   */
  championshipEntryFee?: number;
  /**
   *
   * @type {number}
   * @memberof ChampionshipFetchModel
   */
  qualifyingEntryFee?: number;
  /**
   *
   * @type {number}
   * @memberof ChampionshipFetchModel
   */
  waitlistFee?: number;
  /**
   *
   * @type {ChampionshipAgeLimitModel}
   * @memberof ChampionshipFetchModel
   */
  ageLimit?: ChampionshipAgeLimitModel;
  /**
   *
   * @type {string}
   * @memberof ChampionshipFetchModel
   */
  ageLimitBannerText?: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipFetchModel
   */
  ageLimitValidateDate?: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipFetchModel
   */
  applicationStartDate?: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipFetchModel
   */
  applicationEndDate?: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipFetchModel
   */
  endDate?: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipFetchModel
   */
  startDate?: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipFetchModel
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipFetchModel
   */
  overviewUrl?: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipFetchModel
   */
  qualifyingStartDate?: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipFetchModel
   */
  qualifyingEndDate?: string;
  /**
   *
   * @type {GenderEnum}
   * @memberof ChampionshipFetchModel
   */
  gender?: GenderEnum;
  /**
   *
   * @type {number}
   * @memberof ChampionshipFetchModel
   */
  maxHandicapIndex?: number;
  /**
   *
   * @type {string}
   * @memberof ChampionshipFetchModel
   */
  maxHandicapIndexOverflowMessage?: string;
  /**
   *
   * @type {ChampionshipMinorityModel}
   * @memberof ChampionshipFetchModel
   */
  minority?: ChampionshipMinorityModel;
  /**
   *
   * @type {ChampionshipOppositeGenderModel}
   * @memberof ChampionshipFetchModel
   */
  oppositeGender?: ChampionshipOppositeGenderModel;
  /**
   *
   * @type {string}
   * @memberof ChampionshipFetchModel
   */
  localQualifyingStartDate?: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipFetchModel
   */
  localQualifyingEndDate?: string;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipFetchModel
   */
  stubTextEnabled?: boolean;
  /**
   *
   * @type {string}
   * @memberof ChampionshipFetchModel
   */
  stubText?: string;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipFetchModel
   */
  allExempt?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipFetchModel
   */
  localQualifyingDisabled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipFetchModel
   */
  localExemptionDisabled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipFetchModel
   */
  processingPairs?: boolean;
  /**
   *
   * @type {ChampAdditionalQualifyingInfoModel}
   * @memberof ChampionshipFetchModel
   */
  additionalQualifyingInfo?: ChampAdditionalQualifyingInfoModel;
  /**
   *
   * @type {ChampOverviewInfoModel}
   * @memberof ChampionshipFetchModel
   */
  overviewInfo?: ChampOverviewInfoModel;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipFetchModel
   */
  hideQualifyingSites?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipFetchModel
   */
  hideSneakPeek?: boolean;
  /**
   *
   * @type {Array<IdModel>}
   * @memberof ChampionshipFetchModel
   */
  eligibleLateApplyPlayers?: Array<IdModel>;
}
/**
 *
 * @export
 * @interface ChampionshipHistoryModel
 */
export interface ChampionshipHistoryModel {
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipHistoryModel
   */
  active: boolean;
  /**
   *
   * @type {ChampionshipRootModelFinalSiteControl}
   * @memberof ChampionshipHistoryModel
   */
  finalSiteControl?: ChampionshipRootModelFinalSiteControl;
  /**
   *
   * @type {InfoPDF}
   * @memberof ChampionshipHistoryModel
   */
  infoPDF?: InfoPDF;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipHistoryModel
   */
  antiDoping?: boolean;
  /**
   *
   * @type {string}
   * @memberof ChampionshipHistoryModel
   */
  backgroundImage?: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipHistoryModel
   */
  logo?: string;
  /**
   *
   * @type {number}
   * @memberof ChampionshipHistoryModel
   */
  championshipEntryFee?: number;
  /**
   *
   * @type {number}
   * @memberof ChampionshipHistoryModel
   */
  qualifyingEntryFee?: number;
  /**
   *
   * @type {number}
   * @memberof ChampionshipHistoryModel
   */
  waitlistFee?: number;
  /**
   *
   * @type {ChampionshipAgeLimitModel}
   * @memberof ChampionshipHistoryModel
   */
  ageLimit?: ChampionshipAgeLimitModel;
  /**
   *
   * @type {string}
   * @memberof ChampionshipHistoryModel
   */
  ageLimitBannerText?: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipHistoryModel
   */
  ageLimitValidateDate: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipHistoryModel
   */
  applicationStartDate?: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipHistoryModel
   */
  applicationEndDate?: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipHistoryModel
   */
  endDate: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipHistoryModel
   */
  startDate: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipHistoryModel
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipHistoryModel
   */
  overviewUrl?: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipHistoryModel
   */
  qualifyingStartDate?: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipHistoryModel
   */
  qualifyingEndDate?: string;
  /**
   *
   * @type {GenderEnum}
   * @memberof ChampionshipHistoryModel
   */
  gender: GenderEnum;
  /**
   *
   * @type {number}
   * @memberof ChampionshipHistoryModel
   */
  maxHandicapIndex: number;
  /**
   *
   * @type {string}
   * @memberof ChampionshipHistoryModel
   */
  maxHandicapIndexOverflowMessage: string;
  /**
   *
   * @type {ChampionshipMinorityModel}
   * @memberof ChampionshipHistoryModel
   */
  minority: ChampionshipMinorityModel;
  /**
   *
   * @type {ChampionshipOppositeGenderModel}
   * @memberof ChampionshipHistoryModel
   */
  oppositeGender: ChampionshipOppositeGenderModel;
  /**
   *
   * @type {string}
   * @memberof ChampionshipHistoryModel
   */
  localQualifyingStartDate?: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipHistoryModel
   */
  localQualifyingEndDate?: string;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipHistoryModel
   */
  stubTextEnabled?: boolean;
  /**
   *
   * @type {string}
   * @memberof ChampionshipHistoryModel
   */
  stubText?: string;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipHistoryModel
   */
  allExempt?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipHistoryModel
   */
  localQualifyingDisabled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipHistoryModel
   */
  localExemptionDisabled?: boolean;
  /**
   *
   * @type {ChampAdditionalQualifyingInfoModel}
   * @memberof ChampionshipHistoryModel
   */
  additionalQualifyingInfo?: ChampAdditionalQualifyingInfoModel;
  /**
   *
   * @type {ChampOverviewInfoModel}
   * @memberof ChampionshipHistoryModel
   */
  overviewInfo?: ChampOverviewInfoModel;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipHistoryModel
   */
  sendSurveyEmail?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipHistoryModel
   */
  showWaitlistedPlayersCTA?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipHistoryModel
   */
  showReplacementAfterCloseCTA?: boolean;
  /**
   *
   * @type {number}
   * @memberof ChampionshipHistoryModel
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof ChampionshipHistoryModel
   */
  year: number;
  /**
   *
   * @type {ChampionshipTypeModel}
   * @memberof ChampionshipHistoryModel
   */
  championshipType: ChampionshipTypeModel;
  /**
   *
   * @type {ClubAdminHistoryModel}
   * @memberof ChampionshipHistoryModel
   */
  club: ClubAdminHistoryModel;
}
/**
 *
 * @export
 * @interface ChampionshipHistoryModelAllOf
 */
export interface ChampionshipHistoryModelAllOf {
  /**
   *
   * @type {number}
   * @memberof ChampionshipHistoryModelAllOf
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof ChampionshipHistoryModelAllOf
   */
  year: number;
  /**
   *
   * @type {ChampionshipTypeModel}
   * @memberof ChampionshipHistoryModelAllOf
   */
  championshipType: ChampionshipTypeModel;
  /**
   *
   * @type {ClubAdminHistoryModel}
   * @memberof ChampionshipHistoryModelAllOf
   */
  club: ClubAdminHistoryModel;
}
/**
 *
 * @export
 * @interface ChampionshipLightweightListModel
 */
export interface ChampionshipLightweightListModel {
  /**
   *
   * @type {Array<ChampionshipRootLightweightModel>}
   * @memberof ChampionshipLightweightListModel
   */
  items: Array<ChampionshipRootLightweightModel>;
  /**
   *
   * @type {number}
   * @memberof ChampionshipLightweightListModel
   */
  limit: number;
  /**
   *
   * @type {number}
   * @memberof ChampionshipLightweightListModel
   */
  start: number;
  /**
   *
   * @type {number}
   * @memberof ChampionshipLightweightListModel
   */
  total: number;
}
/**
 * Represents Minority Model
 * @export
 * @interface ChampionshipMinorityModel
 */
export interface ChampionshipMinorityModel {
  /**
   *
   * @type {number}
   * @memberof ChampionshipMinorityModel
   */
  transitionBorderFromMinorToAdult?: number;
  /**
   *
   * @type {string}
   * @memberof ChampionshipMinorityModel
   */
  minorCheckedButOlder?: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipMinorityModel
   */
  adultCheckedButYounger?: string;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipMinorityModel
   */
  enabled: boolean;
}
/**
 * Represents Opposite Gender Model
 * @export
 * @interface ChampionshipOppositeGenderModel
 */
export interface ChampionshipOppositeGenderModel {
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipOppositeGenderModel
   */
  allowWithMHI: boolean;
  /**
   *
   * @type {string}
   * @memberof ChampionshipOppositeGenderModel
   */
  message: string;
}
/**
 *
 * @export
 * @interface ChampionshipPlayerInfoModel
 */
export interface ChampionshipPlayerInfoModel {
  /**
   *
   * @type {Array<number>}
   * @memberof ChampionshipPlayerInfoModel
   */
  paid: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof ChampionshipPlayerInfoModel
   */
  blacklisted: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof ChampionshipPlayerInfoModel
   */
  waitlisted: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof ChampionshipPlayerInfoModel
   */
  lateSubstituted?: Array<number>;
}
/**
 *
 * @export
 * @interface ChampionshipPlayerListModel
 */
export interface ChampionshipPlayerListModel {
  /**
   *
   * @type {Array<ChampionshipPlayerModel>}
   * @memberof ChampionshipPlayerListModel
   */
  items: Array<ChampionshipPlayerModel>;
  /**
   *
   * @type {number}
   * @memberof ChampionshipPlayerListModel
   */
  limit: number;
  /**
   *
   * @type {number}
   * @memberof ChampionshipPlayerListModel
   */
  start: number;
  /**
   *
   * @type {number}
   * @memberof ChampionshipPlayerListModel
   */
  total: number;
}
/**
 *
 * @export
 * @interface ChampionshipPlayerModel
 */
export interface ChampionshipPlayerModel {
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipPlayerModel
   */
  active: boolean;
  /**
   *
   * @type {ChampionshipRootModelFinalSiteControl}
   * @memberof ChampionshipPlayerModel
   */
  finalSiteControl?: ChampionshipRootModelFinalSiteControl;
  /**
   *
   * @type {InfoPDF}
   * @memberof ChampionshipPlayerModel
   */
  infoPDF?: InfoPDF;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipPlayerModel
   */
  antiDoping?: boolean;
  /**
   *
   * @type {string}
   * @memberof ChampionshipPlayerModel
   */
  backgroundImage?: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipPlayerModel
   */
  logo?: string;
  /**
   *
   * @type {number}
   * @memberof ChampionshipPlayerModel
   */
  championshipEntryFee?: number;
  /**
   *
   * @type {number}
   * @memberof ChampionshipPlayerModel
   */
  qualifyingEntryFee?: number;
  /**
   *
   * @type {number}
   * @memberof ChampionshipPlayerModel
   */
  waitlistFee?: number;
  /**
   *
   * @type {ChampionshipAgeLimitModel}
   * @memberof ChampionshipPlayerModel
   */
  ageLimit?: ChampionshipAgeLimitModel;
  /**
   *
   * @type {string}
   * @memberof ChampionshipPlayerModel
   */
  ageLimitBannerText?: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipPlayerModel
   */
  ageLimitValidateDate: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipPlayerModel
   */
  applicationStartDate?: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipPlayerModel
   */
  applicationEndDate?: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipPlayerModel
   */
  endDate: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipPlayerModel
   */
  startDate: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipPlayerModel
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipPlayerModel
   */
  overviewUrl?: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipPlayerModel
   */
  qualifyingStartDate?: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipPlayerModel
   */
  qualifyingEndDate?: string;
  /**
   *
   * @type {GenderEnum}
   * @memberof ChampionshipPlayerModel
   */
  gender: GenderEnum;
  /**
   *
   * @type {number}
   * @memberof ChampionshipPlayerModel
   */
  maxHandicapIndex: number;
  /**
   *
   * @type {string}
   * @memberof ChampionshipPlayerModel
   */
  maxHandicapIndexOverflowMessage: string;
  /**
   *
   * @type {ChampionshipMinorityModel}
   * @memberof ChampionshipPlayerModel
   */
  minority: ChampionshipMinorityModel;
  /**
   *
   * @type {ChampionshipOppositeGenderModel}
   * @memberof ChampionshipPlayerModel
   */
  oppositeGender: ChampionshipOppositeGenderModel;
  /**
   *
   * @type {string}
   * @memberof ChampionshipPlayerModel
   */
  localQualifyingStartDate?: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipPlayerModel
   */
  localQualifyingEndDate?: string;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipPlayerModel
   */
  stubTextEnabled?: boolean;
  /**
   *
   * @type {string}
   * @memberof ChampionshipPlayerModel
   */
  stubText?: string;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipPlayerModel
   */
  allExempt?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipPlayerModel
   */
  localQualifyingDisabled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipPlayerModel
   */
  localExemptionDisabled?: boolean;
  /**
   *
   * @type {ChampAdditionalQualifyingInfoModel}
   * @memberof ChampionshipPlayerModel
   */
  additionalQualifyingInfo?: ChampAdditionalQualifyingInfoModel;
  /**
   *
   * @type {ChampOverviewInfoModel}
   * @memberof ChampionshipPlayerModel
   */
  overviewInfo?: ChampOverviewInfoModel;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipPlayerModel
   */
  sendSurveyEmail?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipPlayerModel
   */
  showWaitlistedPlayersCTA?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipPlayerModel
   */
  showReplacementAfterCloseCTA?: boolean;
  /**
   *
   * @type {number}
   * @memberof ChampionshipPlayerModel
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof ChampionshipPlayerModel
   */
  year: number;
  /**
   *
   * @type {ClubAdminModel}
   * @memberof ChampionshipPlayerModel
   */
  club: ClubAdminModel;
  /**
   *
   * @type {ChampionshipTypeModel}
   * @memberof ChampionshipPlayerModel
   */
  championshipType: ChampionshipTypeModel;
  /**
   *
   * @type {ChampionshipButtonControlModel}
   * @memberof ChampionshipPlayerModel
   */
  currentPlayerListControl?: ChampionshipButtonControlModel;
  /**
   *
   * @type {ChampionshipButtonControlModel}
   * @memberof ChampionshipPlayerModel
   */
  playersWithdrawnControl?: ChampionshipButtonControlModel;
  /**
   *
   * @type {ChampionshipButtonControlModel}
   * @memberof ChampionshipPlayerModel
   */
  uploadResultsControl?: ChampionshipButtonControlModel;
  /**
   *
   * @type {ChampionshipButtonControlModel}
   * @memberof ChampionshipPlayerModel
   */
  staffCurrentChampField?: ChampionshipButtonControlModel;
  /**
   *
   * @type {ChampionshipButtonControlModel}
   * @memberof ChampionshipPlayerModel
   */
  staffChampReports?: ChampionshipButtonControlModel;
  /**
   *
   * @type {ChampionshipButtonControlModel}
   * @memberof ChampionshipPlayerModel
   */
  staffCurrentAlternates?: ChampionshipButtonControlModel;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipPlayerModel
   */
  allEntriesClosed: boolean;
  /**
   *
   * @type {Array<QualifyingSiteModel>}
   * @memberof ChampionshipPlayerModel
   */
  qualifyingSites?: Array<QualifyingSiteModel>;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipPlayerModel
   */
  hideQualifyingSites?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipPlayerModel
   */
  hideSneakPeek?: boolean;
  /**
   *
   * @type {Array<IdModel>}
   * @memberof ChampionshipPlayerModel
   */
  eligibleLateApplyPlayers?: Array<IdModel>;
  /**
   *
   * @type {string}
   * @memberof ChampionshipPlayerModel
   */
  lateApplyDeadlineDate?: string;
}
/**
 *
 * @export
 * @interface ChampionshipPlayerModelAllOf
 */
export interface ChampionshipPlayerModelAllOf {
  /**
   *
   * @type {Array<QualifyingSiteModel>}
   * @memberof ChampionshipPlayerModelAllOf
   */
  qualifyingSites?: Array<QualifyingSiteModel>;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipPlayerModelAllOf
   */
  hideQualifyingSites?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipPlayerModelAllOf
   */
  hideSneakPeek?: boolean;
  /**
   *
   * @type {Array<IdModel>}
   * @memberof ChampionshipPlayerModelAllOf
   */
  eligibleLateApplyPlayers?: Array<IdModel>;
  /**
   *
   * @type {string}
   * @memberof ChampionshipPlayerModelAllOf
   */
  lateApplyDeadlineDate?: string;
}
/**
 *
 * @export
 * @interface ChampionshipRootLightweightModel
 */
export interface ChampionshipRootLightweightModel {
  /**
   *
   * @type {number}
   * @memberof ChampionshipRootLightweightModel
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof ChampionshipRootLightweightModel
   */
  year: number;
  /**
   *
   * @type {string}
   * @memberof ChampionshipRootLightweightModel
   */
  startDate: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipRootLightweightModel
   */
  title: string;
  /**
   *
   * @type {ChampionshipEntriesQuantity}
   * @memberof ChampionshipRootLightweightModel
   */
  counters?: ChampionshipEntriesQuantity;
  /**
   *
   * @type {ChampionshipRootLightweightModelChampionshipType}
   * @memberof ChampionshipRootLightweightModel
   */
  championshipType: ChampionshipRootLightweightModelChampionshipType;
}
/**
 *
 * @export
 * @interface ChampionshipRootLightweightModelChampionshipType
 */
export interface ChampionshipRootLightweightModelChampionshipType {
  /**
   *
   * @type {number}
   * @memberof ChampionshipRootLightweightModelChampionshipType
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof ChampionshipRootLightweightModelChampionshipType
   */
  name: string;
  /**
   *
   * @type {WorkflowTypeEnum}
   * @memberof ChampionshipRootLightweightModelChampionshipType
   */
  workflowType: WorkflowTypeEnum;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipRootLightweightModelChampionshipType
   */
  qualifyingEnabled?: boolean;
}
/**
 * Represents championship
 * @export
 * @interface ChampionshipRootModel
 */
export interface ChampionshipRootModel {
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipRootModel
   */
  active: boolean;
  /**
   *
   * @type {ChampionshipRootModelFinalSiteControl}
   * @memberof ChampionshipRootModel
   */
  finalSiteControl?: ChampionshipRootModelFinalSiteControl;
  /**
   *
   * @type {InfoPDF}
   * @memberof ChampionshipRootModel
   */
  infoPDF?: InfoPDF;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipRootModel
   */
  antiDoping?: boolean;
  /**
   *
   * @type {string}
   * @memberof ChampionshipRootModel
   */
  backgroundImage?: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipRootModel
   */
  logo?: string;
  /**
   *
   * @type {number}
   * @memberof ChampionshipRootModel
   */
  championshipEntryFee?: number;
  /**
   *
   * @type {number}
   * @memberof ChampionshipRootModel
   */
  qualifyingEntryFee?: number;
  /**
   *
   * @type {number}
   * @memberof ChampionshipRootModel
   */
  waitlistFee?: number;
  /**
   *
   * @type {ChampionshipAgeLimitModel}
   * @memberof ChampionshipRootModel
   */
  ageLimit?: ChampionshipAgeLimitModel;
  /**
   *
   * @type {string}
   * @memberof ChampionshipRootModel
   */
  ageLimitBannerText?: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipRootModel
   */
  ageLimitValidateDate: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipRootModel
   */
  applicationStartDate?: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipRootModel
   */
  applicationEndDate?: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipRootModel
   */
  endDate: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipRootModel
   */
  startDate: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipRootModel
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipRootModel
   */
  overviewUrl?: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipRootModel
   */
  qualifyingStartDate?: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipRootModel
   */
  qualifyingEndDate?: string;
  /**
   *
   * @type {GenderEnum}
   * @memberof ChampionshipRootModel
   */
  gender: GenderEnum;
  /**
   *
   * @type {number}
   * @memberof ChampionshipRootModel
   */
  maxHandicapIndex: number;
  /**
   *
   * @type {string}
   * @memberof ChampionshipRootModel
   */
  maxHandicapIndexOverflowMessage: string;
  /**
   *
   * @type {ChampionshipMinorityModel}
   * @memberof ChampionshipRootModel
   */
  minority: ChampionshipMinorityModel;
  /**
   *
   * @type {ChampionshipOppositeGenderModel}
   * @memberof ChampionshipRootModel
   */
  oppositeGender: ChampionshipOppositeGenderModel;
  /**
   *
   * @type {string}
   * @memberof ChampionshipRootModel
   */
  localQualifyingStartDate?: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipRootModel
   */
  localQualifyingEndDate?: string;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipRootModel
   */
  stubTextEnabled?: boolean;
  /**
   *
   * @type {string}
   * @memberof ChampionshipRootModel
   */
  stubText?: string;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipRootModel
   */
  allExempt?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipRootModel
   */
  localQualifyingDisabled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipRootModel
   */
  localExemptionDisabled?: boolean;
  /**
   *
   * @type {ChampAdditionalQualifyingInfoModel}
   * @memberof ChampionshipRootModel
   */
  additionalQualifyingInfo?: ChampAdditionalQualifyingInfoModel;
  /**
   *
   * @type {ChampOverviewInfoModel}
   * @memberof ChampionshipRootModel
   */
  overviewInfo?: ChampOverviewInfoModel;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipRootModel
   */
  sendSurveyEmail?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipRootModel
   */
  showWaitlistedPlayersCTA?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipRootModel
   */
  showReplacementAfterCloseCTA?: boolean;
}
/**
 *
 * @export
 * @interface ChampionshipRootModelFinalSiteControl
 */
export interface ChampionshipRootModelFinalSiteControl {
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipRootModelFinalSiteControl
   */
  finalQSitesAutoControl?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipRootModelFinalSiteControl
   */
  finalQSitesEnabled?: boolean;
}
/**
 * Represents championship
 * @export
 * @interface ChampionshipSyncFetchModel
 */
export interface ChampionshipSyncFetchModel {
  /**
   *
   * @type {number}
   * @memberof ChampionshipSyncFetchModel
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof ChampionshipSyncFetchModel
   */
  year?: number;
  /**
   *
   * @type {ChampionshipSyncFetchModelChampionshipType}
   * @memberof ChampionshipSyncFetchModel
   */
  championshipType?: ChampionshipSyncFetchModelChampionshipType;
  /**
   *
   * @type {string}
   * @memberof ChampionshipSyncFetchModel
   */
  title?: string;
}
/**
 *
 * @export
 * @interface ChampionshipSyncFetchModelChampionshipType
 */
export interface ChampionshipSyncFetchModelChampionshipType {
  /**
   *
   * @type {WorkflowTypeEnum}
   * @memberof ChampionshipSyncFetchModelChampionshipType
   */
  workflowType?: WorkflowTypeEnum;
  /**
   *
   * @type {number}
   * @memberof ChampionshipSyncFetchModelChampionshipType
   */
  id?: number;
}
/**
 *
 * @export
 * @interface ChampionshipTypeModel
 */
export interface ChampionshipTypeModel {
  /**
   *
   * @type {number}
   * @memberof ChampionshipTypeModel
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof ChampionshipTypeModel
   */
  name: string;
  /**
   *
   * @type {WorkflowTypeEnum}
   * @memberof ChampionshipTypeModel
   */
  workflowType: WorkflowTypeEnum;
  /**
   *
   * @type {string}
   * @memberof ChampionshipTypeModel
   */
  note?: string;
  /**
   *
   * @type {number}
   * @memberof ChampionshipTypeModel
   */
  minQsCapacity?: number;
  /**
   *
   * @type {ChampionshipTypeModelInitData}
   * @memberof ChampionshipTypeModel
   */
  initData?: ChampionshipTypeModelInitData;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipTypeModel
   */
  qualifyingEnabled?: boolean;
}
/**
 *
 * @export
 * @interface ChampionshipTypeModelInitData
 */
export interface ChampionshipTypeModelInitData {
  /**
   *
   * @type {number}
   * @memberof ChampionshipTypeModelInitData
   */
  maxHandicapIndex?: number;
  /**
   *
   * @type {boolean}
   * @memberof ChampionshipTypeModelInitData
   */
  antiDoping?: boolean;
  /**
   *
   * @type {GenderEnum}
   * @memberof ChampionshipTypeModelInitData
   */
  gender?: GenderEnum;
  /**
   *
   * @type {ChampionshipOppositeGenderModel}
   * @memberof ChampionshipTypeModelInitData
   */
  oppositeGender?: ChampionshipOppositeGenderModel;
  /**
   *
   * @type {ChampionshipAgeLimitModel}
   * @memberof ChampionshipTypeModelInitData
   */
  ageLimit?: ChampionshipAgeLimitModel;
  /**
   *
   * @type {ChampionshipMinorityModel}
   * @memberof ChampionshipTypeModelInitData
   */
  minority?: ChampionshipMinorityModel;
  /**
   *
   * @type {string}
   * @memberof ChampionshipTypeModelInitData
   */
  ageLimitBannerText?: string;
  /**
   *
   * @type {string}
   * @memberof ChampionshipTypeModelInitData
   */
  maxHandicapIndexOverflowMessage: string;
}
/**
 *
 * @export
 * @interface CheckoutSessionEventModel
 */
export interface CheckoutSessionEventModel {
  /**
   *
   * @type {string}
   * @memberof CheckoutSessionEventModel
   */
  eventId: string;
  /**
   *
   * @type {string}
   * @memberof CheckoutSessionEventModel
   */
  eventType: string;
  /**
   *
   * @type {string}
   * @memberof CheckoutSessionEventModel
   */
  paymentIntentId?: string;
  /**
   *
   * @type {string}
   * @memberof CheckoutSessionEventModel
   */
  phoneNumber?: string;
  /**
   *
   * @type {number}
   * @memberof CheckoutSessionEventModel
   */
  applicationId?: number;
}
/**
 *
 * @export
 * @interface CheckoutSessionEventModelAllOf
 */
export interface CheckoutSessionEventModelAllOf {
  /**
   *
   * @type {string}
   * @memberof CheckoutSessionEventModelAllOf
   */
  paymentIntentId?: string;
  /**
   *
   * @type {string}
   * @memberof CheckoutSessionEventModelAllOf
   */
  phoneNumber?: string;
  /**
   *
   * @type {number}
   * @memberof CheckoutSessionEventModelAllOf
   */
  applicationId?: number;
}
/**
 *
 * @export
 * @interface CloudinaryImageAdminModel
 */
export interface CloudinaryImageAdminModel {
  /**
   *
   * @type {string}
   * @memberof CloudinaryImageAdminModel
   */
  public_id: string;
  /**
   *
   * @type {string}
   * @memberof CloudinaryImageAdminModel
   */
  filename: string;
  /**
   *
   * @type {string}
   * @memberof CloudinaryImageAdminModel
   */
  secure_url: string;
  /**
   *
   * @type {number}
   * @memberof CloudinaryImageAdminModel
   */
  width: number;
  /**
   *
   * @type {number}
   * @memberof CloudinaryImageAdminModel
   */
  height: number;
}
/**
 * Represents Cloudinary Image Model
 * @export
 * @interface CloudinaryImageModel
 */
export interface CloudinaryImageModel {
  /**
   *
   * @type {string}
   * @memberof CloudinaryImageModel
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CloudinaryImageModel
   */
  crop?: CloudinaryImageModelCropEnum;
  /**
   *
   * @type {string}
   * @memberof CloudinaryImageModel
   */
  gravity?: CloudinaryImageModelGravityEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum CloudinaryImageModelCropEnum {
  Fill = 'fill',
  Crop = 'crop',
  Thumb = 'thumb',
}
/**
 * @export
 * @enum {string}
 */
export enum CloudinaryImageModelGravityEnum {
  Center = 'center',
  Face = 'face',
}

/**
 *
 * @export
 * @interface CloudinaryImagesAdminListModel
 */
export interface CloudinaryImagesAdminListModel {
  /**
   *
   * @type {Array<CloudinaryImageAdminModel>}
   * @memberof CloudinaryImagesAdminListModel
   */
  resources: Array<CloudinaryImageAdminModel>;
  /**
   *
   * @type {string}
   * @memberof CloudinaryImagesAdminListModel
   */
  next_cursor?: string;
  /**
   *
   * @type {number}
   * @memberof CloudinaryImagesAdminListModel
   */
  total_count: number;
}
/**
 *
 * @export
 * @interface CloudinarySearchParamBean
 */
export interface CloudinarySearchParamBean {
  /**
   *
   * @type {number}
   * @memberof CloudinarySearchParamBean
   */
  limit: number;
  /**
   *
   * @type {string}
   * @memberof CloudinarySearchParamBean
   */
  start?: string;
  /**
   *
   * @type {string}
   * @memberof CloudinarySearchParamBean
   */
  query?: string;
}
/**
 *
 * @export
 * @interface ClubAdminAddModel
 */
export interface ClubAdminAddModel {
  /**
   *
   * @type {string}
   * @memberof ClubAdminAddModel
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof ClubAdminAddModel
   */
  phoneNumber: string;
  /**
   *
   * @type {string}
   * @memberof ClubAdminAddModel
   */
  courseName?: string;
  /**
   *
   * @type {string}
   * @memberof ClubAdminAddModel
   */
  contactFirstName: string;
  /**
   *
   * @type {string}
   * @memberof ClubAdminAddModel
   */
  contactLastName: string;
  /**
   *
   * @type {string}
   * @memberof ClubAdminAddModel
   */
  contactTitle?: string;
  /**
   *
   * @type {string}
   * @memberof ClubAdminAddModel
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof ClubAdminAddModel
   */
  address: string;
  /**
   *
   * @type {string}
   * @memberof ClubAdminAddModel
   */
  address2?: string;
  /**
   *
   * @type {string}
   * @memberof ClubAdminAddModel
   */
  city: string;
  /**
   *
   * @type {StateAddModel}
   * @memberof ClubAdminAddModel
   */
  state?: StateAddModel;
  /**
   *
   * @type {string}
   * @memberof ClubAdminAddModel
   */
  postcode: string;
  /**
   *
   * @type {CountryAddModel}
   * @memberof ClubAdminAddModel
   */
  country: CountryAddModel;
  /**
   *
   * @type {string}
   * @memberof ClubAdminAddModel
   */
  salutation?: string;
  /**
   *
   * @type {boolean}
   * @memberof ClubAdminAddModel
   */
  spikeless: boolean;
}
/**
 *
 * @export
 * @interface ClubAdminAddModelAllOf
 */
export interface ClubAdminAddModelAllOf {
  /**
   *
   * @type {string}
   * @memberof ClubAdminAddModelAllOf
   */
  contactFirstName: string;
  /**
   *
   * @type {string}
   * @memberof ClubAdminAddModelAllOf
   */
  contactLastName: string;
  /**
   *
   * @type {string}
   * @memberof ClubAdminAddModelAllOf
   */
  contactTitle?: string;
  /**
   *
   * @type {string}
   * @memberof ClubAdminAddModelAllOf
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof ClubAdminAddModelAllOf
   */
  address: string;
  /**
   *
   * @type {string}
   * @memberof ClubAdminAddModelAllOf
   */
  address2?: string;
  /**
   *
   * @type {string}
   * @memberof ClubAdminAddModelAllOf
   */
  city: string;
  /**
   *
   * @type {StateAddModel}
   * @memberof ClubAdminAddModelAllOf
   */
  state?: StateAddModel;
  /**
   *
   * @type {string}
   * @memberof ClubAdminAddModelAllOf
   */
  postcode: string;
  /**
   *
   * @type {CountryAddModel}
   * @memberof ClubAdminAddModelAllOf
   */
  country: CountryAddModel;
  /**
   *
   * @type {string}
   * @memberof ClubAdminAddModelAllOf
   */
  salutation?: string;
  /**
   *
   * @type {boolean}
   * @memberof ClubAdminAddModelAllOf
   */
  spikeless: boolean;
}
/**
 *
 * @export
 * @interface ClubAdminHistoryModel
 */
export interface ClubAdminHistoryModel {
  /**
   *
   * @type {number}
   * @memberof ClubAdminHistoryModel
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof ClubAdminHistoryModel
   */
  contactFirstName: string;
  /**
   *
   * @type {string}
   * @memberof ClubAdminHistoryModel
   */
  contactLastName: string;
  /**
   *
   * @type {string}
   * @memberof ClubAdminHistoryModel
   */
  contactTitle?: string;
  /**
   *
   * @type {string}
   * @memberof ClubAdminHistoryModel
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof ClubAdminHistoryModel
   */
  address: string;
  /**
   *
   * @type {string}
   * @memberof ClubAdminHistoryModel
   */
  address2?: string;
  /**
   *
   * @type {string}
   * @memberof ClubAdminHistoryModel
   */
  city: string;
  /**
   *
   * @type {StateAddModel}
   * @memberof ClubAdminHistoryModel
   */
  state?: StateAddModel;
  /**
   *
   * @type {string}
   * @memberof ClubAdminHistoryModel
   */
  postcode: string;
  /**
   *
   * @type {CountryAddModel}
   * @memberof ClubAdminHistoryModel
   */
  country: CountryAddModel;
  /**
   *
   * @type {string}
   * @memberof ClubAdminHistoryModel
   */
  salutation?: string;
  /**
   *
   * @type {boolean}
   * @memberof ClubAdminHistoryModel
   */
  spikeless: boolean;
  /**
   *
   * @type {string}
   * @memberof ClubAdminHistoryModel
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof ClubAdminHistoryModel
   */
  phoneNumber: string;
  /**
   *
   * @type {string}
   * @memberof ClubAdminHistoryModel
   */
  courseName?: string;
}
/**
 *
 * @export
 * @interface ClubAdminListModel
 */
export interface ClubAdminListModel {
  /**
   *
   * @type {Array<ClubAdminModel>}
   * @memberof ClubAdminListModel
   */
  items: Array<ClubAdminModel>;
  /**
   *
   * @type {number}
   * @memberof ClubAdminListModel
   */
  limit: number;
  /**
   *
   * @type {number}
   * @memberof ClubAdminListModel
   */
  start: number;
  /**
   *
   * @type {number}
   * @memberof ClubAdminListModel
   */
  total: number;
}
/**
 *
 * @export
 * @interface ClubAdminModel
 */
export interface ClubAdminModel {
  /**
   *
   * @type {string}
   * @memberof ClubAdminModel
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof ClubAdminModel
   */
  phoneNumber: string;
  /**
   *
   * @type {string}
   * @memberof ClubAdminModel
   */
  courseName?: string;
  /**
   *
   * @type {string}
   * @memberof ClubAdminModel
   */
  contactFirstName: string;
  /**
   *
   * @type {string}
   * @memberof ClubAdminModel
   */
  contactLastName: string;
  /**
   *
   * @type {string}
   * @memberof ClubAdminModel
   */
  contactTitle?: string;
  /**
   *
   * @type {string}
   * @memberof ClubAdminModel
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof ClubAdminModel
   */
  address: string;
  /**
   *
   * @type {string}
   * @memberof ClubAdminModel
   */
  address2?: string;
  /**
   *
   * @type {string}
   * @memberof ClubAdminModel
   */
  city: string;
  /**
   *
   * @type {StateAddModel}
   * @memberof ClubAdminModel
   */
  state?: StateAddModel;
  /**
   *
   * @type {string}
   * @memberof ClubAdminModel
   */
  postcode: string;
  /**
   *
   * @type {CountryAddModel}
   * @memberof ClubAdminModel
   */
  country: CountryAddModel;
  /**
   *
   * @type {string}
   * @memberof ClubAdminModel
   */
  salutation?: string;
  /**
   *
   * @type {boolean}
   * @memberof ClubAdminModel
   */
  spikeless: boolean;
  /**
   *
   * @type {number}
   * @memberof ClubAdminModel
   */
  id: number;
}
/**
 *
 * @export
 * @interface ClubBaseModel
 */
export interface ClubBaseModel {
  /**
   *
   * @type {string}
   * @memberof ClubBaseModel
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof ClubBaseModel
   */
  phoneNumber: string;
  /**
   *
   * @type {string}
   * @memberof ClubBaseModel
   */
  courseName?: string;
}
/**
 *
 * @export
 * @interface ClubFetchModel
 */
export interface ClubFetchModel {
  /**
   *
   * @type {number}
   * @memberof ClubFetchModel
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof ClubFetchModel
   */
  contactFirstName?: string;
  /**
   *
   * @type {string}
   * @memberof ClubFetchModel
   */
  contactLastName?: string;
  /**
   *
   * @type {string}
   * @memberof ClubFetchModel
   */
  contactTitle?: string;
  /**
   *
   * @type {string}
   * @memberof ClubFetchModel
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof ClubFetchModel
   */
  address?: string;
  /**
   *
   * @type {string}
   * @memberof ClubFetchModel
   */
  address2?: string;
  /**
   *
   * @type {string}
   * @memberof ClubFetchModel
   */
  city?: string;
  /**
   *
   * @type {StateAddModel}
   * @memberof ClubFetchModel
   */
  state?: StateAddModel;
  /**
   *
   * @type {string}
   * @memberof ClubFetchModel
   */
  postcode?: string;
  /**
   *
   * @type {CountryAddModel}
   * @memberof ClubFetchModel
   */
  country?: CountryAddModel;
  /**
   *
   * @type {string}
   * @memberof ClubFetchModel
   */
  salutation?: string;
  /**
   *
   * @type {boolean}
   * @memberof ClubFetchModel
   */
  spikeless?: boolean;
  /**
   *
   * @type {string}
   * @memberof ClubFetchModel
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof ClubFetchModel
   */
  phoneNumber?: string;
  /**
   *
   * @type {string}
   * @memberof ClubFetchModel
   */
  courseName?: string;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum ClubFilters {
  STATE = 'STATE',
  COUNTRY = 'COUNTRY',
  CITY = 'CITY',
}

/**
 *
 * @export
 * @interface ColumnModel
 */
export interface ColumnModel {
  /**
   *
   * @type {string}
   * @memberof ColumnModel
   */
  heading?: string;
  /**
   *
   * @type {Array<SubNavigationLinkModel>}
   * @memberof ColumnModel
   */
  subNavigationItems?: Array<SubNavigationLinkModel>;
  /**
   *
   * @type {string}
   * @memberof ColumnModel
   */
  thirdLayerNavTextLink?: string;
  /**
   *
   * @type {Array<LinkModel>}
   * @memberof ColumnModel
   */
  thirdLayerNavItems?: Array<LinkModel>;
  /**
   *
   * @type {ImageModel}
   * @memberof ColumnModel
   */
  image?: ImageModel;
}
/**
 *
 * @export
 * @interface CommonChampionshipBaseModel
 */
export interface CommonChampionshipBaseModel {
  /**
   *
   * @type {boolean}
   * @memberof CommonChampionshipBaseModel
   */
  active: boolean;
  /**
   *
   * @type {ChampionshipRootModelFinalSiteControl}
   * @memberof CommonChampionshipBaseModel
   */
  finalSiteControl?: ChampionshipRootModelFinalSiteControl;
  /**
   *
   * @type {InfoPDF}
   * @memberof CommonChampionshipBaseModel
   */
  infoPDF?: InfoPDF;
  /**
   *
   * @type {boolean}
   * @memberof CommonChampionshipBaseModel
   */
  antiDoping?: boolean;
  /**
   *
   * @type {string}
   * @memberof CommonChampionshipBaseModel
   */
  backgroundImage?: string;
  /**
   *
   * @type {string}
   * @memberof CommonChampionshipBaseModel
   */
  logo?: string;
  /**
   *
   * @type {number}
   * @memberof CommonChampionshipBaseModel
   */
  championshipEntryFee?: number;
  /**
   *
   * @type {number}
   * @memberof CommonChampionshipBaseModel
   */
  qualifyingEntryFee?: number;
  /**
   *
   * @type {number}
   * @memberof CommonChampionshipBaseModel
   */
  waitlistFee?: number;
  /**
   *
   * @type {ChampionshipAgeLimitModel}
   * @memberof CommonChampionshipBaseModel
   */
  ageLimit?: ChampionshipAgeLimitModel;
  /**
   *
   * @type {string}
   * @memberof CommonChampionshipBaseModel
   */
  ageLimitBannerText?: string;
  /**
   *
   * @type {string}
   * @memberof CommonChampionshipBaseModel
   */
  ageLimitValidateDate: string;
  /**
   *
   * @type {string}
   * @memberof CommonChampionshipBaseModel
   */
  applicationStartDate?: string;
  /**
   *
   * @type {string}
   * @memberof CommonChampionshipBaseModel
   */
  applicationEndDate?: string;
  /**
   *
   * @type {string}
   * @memberof CommonChampionshipBaseModel
   */
  endDate: string;
  /**
   *
   * @type {string}
   * @memberof CommonChampionshipBaseModel
   */
  startDate: string;
  /**
   *
   * @type {string}
   * @memberof CommonChampionshipBaseModel
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof CommonChampionshipBaseModel
   */
  overviewUrl?: string;
  /**
   *
   * @type {string}
   * @memberof CommonChampionshipBaseModel
   */
  qualifyingStartDate?: string;
  /**
   *
   * @type {string}
   * @memberof CommonChampionshipBaseModel
   */
  qualifyingEndDate?: string;
  /**
   *
   * @type {GenderEnum}
   * @memberof CommonChampionshipBaseModel
   */
  gender: GenderEnum;
  /**
   *
   * @type {number}
   * @memberof CommonChampionshipBaseModel
   */
  maxHandicapIndex: number;
  /**
   *
   * @type {string}
   * @memberof CommonChampionshipBaseModel
   */
  maxHandicapIndexOverflowMessage: string;
  /**
   *
   * @type {ChampionshipMinorityModel}
   * @memberof CommonChampionshipBaseModel
   */
  minority: ChampionshipMinorityModel;
  /**
   *
   * @type {ChampionshipOppositeGenderModel}
   * @memberof CommonChampionshipBaseModel
   */
  oppositeGender: ChampionshipOppositeGenderModel;
  /**
   *
   * @type {string}
   * @memberof CommonChampionshipBaseModel
   */
  localQualifyingStartDate?: string;
  /**
   *
   * @type {string}
   * @memberof CommonChampionshipBaseModel
   */
  localQualifyingEndDate?: string;
  /**
   *
   * @type {boolean}
   * @memberof CommonChampionshipBaseModel
   */
  stubTextEnabled?: boolean;
  /**
   *
   * @type {string}
   * @memberof CommonChampionshipBaseModel
   */
  stubText?: string;
  /**
   *
   * @type {boolean}
   * @memberof CommonChampionshipBaseModel
   */
  allExempt?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CommonChampionshipBaseModel
   */
  localQualifyingDisabled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CommonChampionshipBaseModel
   */
  localExemptionDisabled?: boolean;
  /**
   *
   * @type {ChampAdditionalQualifyingInfoModel}
   * @memberof CommonChampionshipBaseModel
   */
  additionalQualifyingInfo?: ChampAdditionalQualifyingInfoModel;
  /**
   *
   * @type {ChampOverviewInfoModel}
   * @memberof CommonChampionshipBaseModel
   */
  overviewInfo?: ChampOverviewInfoModel;
  /**
   *
   * @type {boolean}
   * @memberof CommonChampionshipBaseModel
   */
  sendSurveyEmail?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CommonChampionshipBaseModel
   */
  showWaitlistedPlayersCTA?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CommonChampionshipBaseModel
   */
  showReplacementAfterCloseCTA?: boolean;
  /**
   *
   * @type {number}
   * @memberof CommonChampionshipBaseModel
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof CommonChampionshipBaseModel
   */
  year: number;
  /**
   *
   * @type {ClubAdminModel}
   * @memberof CommonChampionshipBaseModel
   */
  club: ClubAdminModel;
  /**
   *
   * @type {ChampionshipTypeModel}
   * @memberof CommonChampionshipBaseModel
   */
  championshipType: ChampionshipTypeModel;
  /**
   *
   * @type {ChampionshipButtonControlModel}
   * @memberof CommonChampionshipBaseModel
   */
  currentPlayerListControl?: ChampionshipButtonControlModel;
  /**
   *
   * @type {ChampionshipButtonControlModel}
   * @memberof CommonChampionshipBaseModel
   */
  playersWithdrawnControl?: ChampionshipButtonControlModel;
  /**
   *
   * @type {ChampionshipButtonControlModel}
   * @memberof CommonChampionshipBaseModel
   */
  uploadResultsControl?: ChampionshipButtonControlModel;
  /**
   *
   * @type {ChampionshipButtonControlModel}
   * @memberof CommonChampionshipBaseModel
   */
  staffCurrentChampField?: ChampionshipButtonControlModel;
  /**
   *
   * @type {ChampionshipButtonControlModel}
   * @memberof CommonChampionshipBaseModel
   */
  staffChampReports?: ChampionshipButtonControlModel;
  /**
   *
   * @type {ChampionshipButtonControlModel}
   * @memberof CommonChampionshipBaseModel
   */
  staffCurrentAlternates?: ChampionshipButtonControlModel;
  /**
   *
   * @type {boolean}
   * @memberof CommonChampionshipBaseModel
   */
  allEntriesClosed: boolean;
  /**
   *
   * @type {Array<QualifyingSiteCommonModel>}
   * @memberof CommonChampionshipBaseModel
   */
  qualifyingSites?: Array<QualifyingSiteCommonModel>;
}
/**
 *
 * @export
 * @interface CommonChampionshipBaseModelAllOf
 */
export interface CommonChampionshipBaseModelAllOf {
  /**
   *
   * @type {Array<QualifyingSiteCommonModel>}
   * @memberof CommonChampionshipBaseModelAllOf
   */
  qualifyingSites?: Array<QualifyingSiteCommonModel>;
}
/**
 *
 * @export
 * @interface CommonChampionshipListModel
 */
export interface CommonChampionshipListModel {
  /**
   *
   * @type {Array<CommonChampionshipBaseModel>}
   * @memberof CommonChampionshipListModel
   */
  items: Array<CommonChampionshipBaseModel>;
  /**
   *
   * @type {number}
   * @memberof CommonChampionshipListModel
   */
  limit: number;
  /**
   *
   * @type {number}
   * @memberof CommonChampionshipListModel
   */
  start: number;
  /**
   *
   * @type {number}
   * @memberof CommonChampionshipListModel
   */
  total: number;
}
/**
 *
 * @export
 * @interface CommonEventCardsChampModel
 */
export interface CommonEventCardsChampModel {
  /**
   *
   * @type {boolean}
   * @memberof CommonEventCardsChampModel
   */
  active: boolean;
  /**
   *
   * @type {ChampionshipRootModelFinalSiteControl}
   * @memberof CommonEventCardsChampModel
   */
  finalSiteControl?: ChampionshipRootModelFinalSiteControl;
  /**
   *
   * @type {InfoPDF}
   * @memberof CommonEventCardsChampModel
   */
  infoPDF?: InfoPDF;
  /**
   *
   * @type {boolean}
   * @memberof CommonEventCardsChampModel
   */
  antiDoping?: boolean;
  /**
   *
   * @type {string}
   * @memberof CommonEventCardsChampModel
   */
  backgroundImage?: string;
  /**
   *
   * @type {string}
   * @memberof CommonEventCardsChampModel
   */
  logo?: string;
  /**
   *
   * @type {number}
   * @memberof CommonEventCardsChampModel
   */
  championshipEntryFee?: number;
  /**
   *
   * @type {number}
   * @memberof CommonEventCardsChampModel
   */
  qualifyingEntryFee?: number;
  /**
   *
   * @type {number}
   * @memberof CommonEventCardsChampModel
   */
  waitlistFee?: number;
  /**
   *
   * @type {ChampionshipAgeLimitModel}
   * @memberof CommonEventCardsChampModel
   */
  ageLimit?: ChampionshipAgeLimitModel;
  /**
   *
   * @type {string}
   * @memberof CommonEventCardsChampModel
   */
  ageLimitBannerText?: string;
  /**
   *
   * @type {string}
   * @memberof CommonEventCardsChampModel
   */
  ageLimitValidateDate: string;
  /**
   *
   * @type {string}
   * @memberof CommonEventCardsChampModel
   */
  applicationStartDate?: string;
  /**
   *
   * @type {string}
   * @memberof CommonEventCardsChampModel
   */
  applicationEndDate?: string;
  /**
   *
   * @type {string}
   * @memberof CommonEventCardsChampModel
   */
  endDate: string;
  /**
   *
   * @type {string}
   * @memberof CommonEventCardsChampModel
   */
  startDate: string;
  /**
   *
   * @type {string}
   * @memberof CommonEventCardsChampModel
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof CommonEventCardsChampModel
   */
  overviewUrl?: string;
  /**
   *
   * @type {string}
   * @memberof CommonEventCardsChampModel
   */
  qualifyingStartDate?: string;
  /**
   *
   * @type {string}
   * @memberof CommonEventCardsChampModel
   */
  qualifyingEndDate?: string;
  /**
   *
   * @type {GenderEnum}
   * @memberof CommonEventCardsChampModel
   */
  gender: GenderEnum;
  /**
   *
   * @type {number}
   * @memberof CommonEventCardsChampModel
   */
  maxHandicapIndex: number;
  /**
   *
   * @type {string}
   * @memberof CommonEventCardsChampModel
   */
  maxHandicapIndexOverflowMessage: string;
  /**
   *
   * @type {ChampionshipMinorityModel}
   * @memberof CommonEventCardsChampModel
   */
  minority: ChampionshipMinorityModel;
  /**
   *
   * @type {ChampionshipOppositeGenderModel}
   * @memberof CommonEventCardsChampModel
   */
  oppositeGender: ChampionshipOppositeGenderModel;
  /**
   *
   * @type {string}
   * @memberof CommonEventCardsChampModel
   */
  localQualifyingStartDate?: string;
  /**
   *
   * @type {string}
   * @memberof CommonEventCardsChampModel
   */
  localQualifyingEndDate?: string;
  /**
   *
   * @type {boolean}
   * @memberof CommonEventCardsChampModel
   */
  stubTextEnabled?: boolean;
  /**
   *
   * @type {string}
   * @memberof CommonEventCardsChampModel
   */
  stubText?: string;
  /**
   *
   * @type {boolean}
   * @memberof CommonEventCardsChampModel
   */
  allExempt?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CommonEventCardsChampModel
   */
  localQualifyingDisabled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CommonEventCardsChampModel
   */
  localExemptionDisabled?: boolean;
  /**
   *
   * @type {ChampAdditionalQualifyingInfoModel}
   * @memberof CommonEventCardsChampModel
   */
  additionalQualifyingInfo?: ChampAdditionalQualifyingInfoModel;
  /**
   *
   * @type {ChampOverviewInfoModel}
   * @memberof CommonEventCardsChampModel
   */
  overviewInfo?: ChampOverviewInfoModel;
  /**
   *
   * @type {boolean}
   * @memberof CommonEventCardsChampModel
   */
  sendSurveyEmail?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CommonEventCardsChampModel
   */
  showWaitlistedPlayersCTA?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CommonEventCardsChampModel
   */
  showReplacementAfterCloseCTA?: boolean;
  /**
   *
   * @type {number}
   * @memberof CommonEventCardsChampModel
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof CommonEventCardsChampModel
   */
  year: number;
  /**
   *
   * @type {ClubAdminModel}
   * @memberof CommonEventCardsChampModel
   */
  club: ClubAdminModel;
  /**
   *
   * @type {ChampionshipTypeModel}
   * @memberof CommonEventCardsChampModel
   */
  championshipType: ChampionshipTypeModel;
  /**
   *
   * @type {ChampionshipButtonControlModel}
   * @memberof CommonEventCardsChampModel
   */
  currentPlayerListControl?: ChampionshipButtonControlModel;
  /**
   *
   * @type {ChampionshipButtonControlModel}
   * @memberof CommonEventCardsChampModel
   */
  playersWithdrawnControl?: ChampionshipButtonControlModel;
  /**
   *
   * @type {ChampionshipButtonControlModel}
   * @memberof CommonEventCardsChampModel
   */
  uploadResultsControl?: ChampionshipButtonControlModel;
  /**
   *
   * @type {ChampionshipButtonControlModel}
   * @memberof CommonEventCardsChampModel
   */
  staffCurrentChampField?: ChampionshipButtonControlModel;
  /**
   *
   * @type {ChampionshipButtonControlModel}
   * @memberof CommonEventCardsChampModel
   */
  staffChampReports?: ChampionshipButtonControlModel;
  /**
   *
   * @type {ChampionshipButtonControlModel}
   * @memberof CommonEventCardsChampModel
   */
  staffCurrentAlternates?: ChampionshipButtonControlModel;
  /**
   *
   * @type {boolean}
   * @memberof CommonEventCardsChampModel
   */
  allEntriesClosed: boolean;
  /**
   *
   * @type {Array<QualifyingSiteCommonModel>}
   * @memberof CommonEventCardsChampModel
   */
  qualifyingSites?: Array<QualifyingSiteCommonModel>;
  /**
   *
   * @type {string}
   * @memberof CommonEventCardsChampModel
   */
  pagePath: string;
}
/**
 *
 * @export
 * @interface CommonEventCardsChampModelAllOf
 */
export interface CommonEventCardsChampModelAllOf {
  /**
   *
   * @type {string}
   * @memberof CommonEventCardsChampModelAllOf
   */
  pagePath: string;
  /**
   *
   * @type {boolean}
   * @memberof CommonEventCardsChampModelAllOf
   */
  allEntriesClosed?: boolean;
}
/**
 * Represents Player Confirmation Component
 * @export
 * @interface ConfirmationPlayerModel
 */
export interface ConfirmationPlayerModel {
  /**
   *
   * @type {string}
   * @memberof ConfirmationPlayerModel
   */
  applicationPagePath: string;
  /**
   *
   * @type {number}
   * @memberof ConfirmationPlayerModel
   */
  authorizationPaymentAmount: number;
  /**
   *
   * @type {IdModel}
   * @memberof ConfirmationPlayerModel
   */
  championship: IdModel;
}
/**
 *
 * @export
 * @interface ContactInfoModel
 */
export interface ContactInfoModel {
  /**
   *
   * @type {string}
   * @memberof ContactInfoModel
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof ContactInfoModel
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof ContactInfoModel
   */
  address?: string;
  /**
   *
   * @type {string}
   * @memberof ContactInfoModel
   */
  address2?: string;
  /**
   *
   * @type {CountryAddModel}
   * @memberof ContactInfoModel
   */
  country?: CountryAddModel;
  /**
   *
   * @type {string}
   * @memberof ContactInfoModel
   */
  city?: string;
  /**
   *
   * @type {StateAddModel}
   * @memberof ContactInfoModel
   */
  state?: StateAddModel;
  /**
   *
   * @type {string}
   * @memberof ContactInfoModel
   */
  postcode?: string;
  /**
   *
   * @type {string}
   * @memberof ContactInfoModel
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof ContactInfoModel
   */
  phoneNumber?: string;
  /**
   *
   * @type {string}
   * @memberof ContactInfoModel
   */
  mobileNumber?: string;
  /**
   *
   * @type {string}
   * @memberof ContactInfoModel
   */
  businessNumber?: string;
}
/**
 *
 * @export
 * @interface CountryAddModel
 */
export interface CountryAddModel {
  /**
   *
   * @type {number}
   * @memberof CountryAddModel
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof CountryAddModel
   */
  codeIOC?: string;
  /**
   *
   * @type {string}
   * @memberof CountryAddModel
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof CountryAddModel
   */
  codeISO?: string;
}
/**
 *
 * @export
 * @interface CountryListModel
 */
export interface CountryListModel {
  /**
   *
   * @type {Array<CountryModel>}
   * @memberof CountryListModel
   */
  items: Array<CountryModel>;
  /**
   *
   * @type {number}
   * @memberof CountryListModel
   */
  limit: number;
  /**
   *
   * @type {number}
   * @memberof CountryListModel
   */
  start: number;
  /**
   *
   * @type {number}
   * @memberof CountryListModel
   */
  total: number;
}
/**
 *
 * @export
 * @interface CountryModel
 */
export interface CountryModel {
  /**
   *
   * @type {number}
   * @memberof CountryModel
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof CountryModel
   */
  codeIOC: string;
  /**
   *
   * @type {string}
   * @memberof CountryModel
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof CountryModel
   */
  codeISO: string;
}
/**
 *
 * @export
 * @interface CreateTeamAdminModel
 */
export interface CreateTeamAdminModel {
  /**
   *
   * @type {Array<CreateTeamAdminModelApplications>}
   * @memberof CreateTeamAdminModel
   */
  applications: Array<CreateTeamAdminModelApplications>;
}
/**
 *
 * @export
 * @interface CreateTeamAdminModelApplications
 */
export interface CreateTeamAdminModelApplications {
  /**
   *
   * @type {number}
   * @memberof CreateTeamAdminModelApplications
   */
  id: number;
  /**
   *
   * @type {Array<IdModel>}
   * @memberof CreateTeamAdminModelApplications
   */
  exemption?: Array<IdModel>;
  /**
   *
   * @type {Array<ApplicationPlayerModelAllOfQualifyingSites>}
   * @memberof CreateTeamAdminModelApplications
   */
  qualifyingSites?: Array<ApplicationPlayerModelAllOfQualifyingSites>;
  /**
   *
   * @type {Array<ApplicationPlayerModelAllOfWaitList>}
   * @memberof CreateTeamAdminModelApplications
   */
  waitList?: Array<ApplicationPlayerModelAllOfWaitList>;
}
/**
 *
 * @export
 * @interface CustomBulkEmailProfileRequestModel
 */
export interface CustomBulkEmailProfileRequestModel {
  /**
   *
   * @type {Array<number>}
   * @memberof CustomBulkEmailProfileRequestModel
   */
  idList: Array<number>;
  /**
   *
   * @type {number}
   * @memberof CustomBulkEmailProfileRequestModel
   */
  templateId: number;
  /**
   *
   * @type {string}
   * @memberof CustomBulkEmailProfileRequestModel
   */
  championshipTitle?: string;
}
/**
 *
 * @export
 * @interface CustomBulkEmailProfileRequestModelAllOf
 */
export interface CustomBulkEmailProfileRequestModelAllOf {
  /**
   *
   * @type {number}
   * @memberof CustomBulkEmailProfileRequestModelAllOf
   */
  templateId: number;
  /**
   *
   * @type {string}
   * @memberof CustomBulkEmailProfileRequestModelAllOf
   */
  championshipTitle?: string;
}
/**
 *
 * @export
 * @interface CustomBulkEmailRequestModel
 */
export interface CustomBulkEmailRequestModel {
  /**
   *
   * @type {Array<number>}
   * @memberof CustomBulkEmailRequestModel
   */
  idList: Array<number>;
  /**
   *
   * @type {number}
   * @memberof CustomBulkEmailRequestModel
   */
  templateId: number;
}
/**
 *
 * @export
 * @interface CustomBulkEmailRequestModelAllOf
 */
export interface CustomBulkEmailRequestModelAllOf {
  /**
   *
   * @type {number}
   * @memberof CustomBulkEmailRequestModelAllOf
   */
  templateId: number;
}
/**
 *
 * @export
 * @interface DeclinedPaymentModel
 */
export interface DeclinedPaymentModel {
  /**
   *
   * @type {string}
   * @memberof DeclinedPaymentModel
   */
  declinedPaymentType: string;
  /**
   *
   * @type {number}
   * @memberof DeclinedPaymentModel
   */
  reasonCode: number;
  /**
   *
   * @type {string}
   * @memberof DeclinedPaymentModel
   */
  message: string;
  /**
   *
   * @type {string}
   * @memberof DeclinedPaymentModel
   */
  declinedPaymentDate: string;
}
/**
 *
 * @export
 * @interface DeleteEntitiesModel
 */
export interface DeleteEntitiesModel {
  /**
   *
   * @type {boolean}
   * @memberof DeleteEntitiesModel
   */
  provideUserErrorProcessing: boolean;
  /**
   *
   * @type {Array<DeleteEntitiesModelDependencies>}
   * @memberof DeleteEntitiesModel
   */
  dependencies?: Array<DeleteEntitiesModelDependencies>;
}
/**
 *
 * @export
 * @interface DeleteEntitiesModelDependencies
 */
export interface DeleteEntitiesModelDependencies {
  /**
   *
   * @type {EntitiesTypeEnum}
   * @memberof DeleteEntitiesModelDependencies
   */
  entity: EntitiesTypeEnum;
  /**
   *
   * @type {number}
   * @memberof DeleteEntitiesModelDependencies
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof DeleteEntitiesModelDependencies
   */
  title: string;
  /**
   *
   * @type {number}
   * @memberof DeleteEntitiesModelDependencies
   */
  champId?: number;
  /**
   *
   * @type {number}
   * @memberof DeleteEntitiesModelDependencies
   */
  year?: number;
}
/**
 * Represents Download Component
 * @export
 * @interface DownloadPlayerModel
 */
export interface DownloadPlayerModel {
  /**
   *
   * @type {string}
   * @memberof DownloadPlayerModel
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof DownloadPlayerModel
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof DownloadPlayerModel
   */
  url?: string;
  /**
   *
   * @type {string}
   * @memberof DownloadPlayerModel
   */
  actionText?: string;
  /**
   *
   * @type {string}
   * @memberof DownloadPlayerModel
   */
  titleType?: string;
  /**
   *
   * @type {string}
   * @memberof DownloadPlayerModel
   */
  size?: string;
  /**
   *
   * @type {string}
   * @memberof DownloadPlayerModel
   */
  extension?: string;
  /**
   *
   * @type {boolean}
   * @memberof DownloadPlayerModel
   */
  displaySize?: boolean;
  /**
   *
   * @type {string}
   * @memberof DownloadPlayerModel
   */
  format?: string;
  /**
   *
   * @type {boolean}
   * @memberof DownloadPlayerModel
   */
  displayFormat?: boolean;
  /**
   *
   * @type {string}
   * @memberof DownloadPlayerModel
   */
  filename?: string;
  /**
   *
   * @type {boolean}
   * @memberof DownloadPlayerModel
   */
  displayFilename?: boolean;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum EligibilityOptionDBRelationEnum {
  OptInPrivacy = 'optInPrivacy',
  OptInWagr = 'optInWagr',
}

/**
 * Represents Eligibility Option Model
 * @export
 * @interface EligibilityOptionModel
 */
export interface EligibilityOptionModel {
  /**
   *
   * @type {OptionViewTypeEnum}
   * @memberof EligibilityOptionModel
   */
  viewType: OptionViewTypeEnum;
  /**
   *
   * @type {string}
   * @memberof EligibilityOptionModel
   */
  text: string;
  /**
   *
   * @type {string}
   * @memberof EligibilityOptionModel
   */
  title?: string;
  /**
   *
   * @type {EligibilityOptionDBRelationEnum}
   * @memberof EligibilityOptionModel
   */
  dbRelation?: EligibilityOptionDBRelationEnum;
  /**
   *
   * @type {string}
   * @memberof EligibilityOptionModel
   */
  optOutWarning?: string;
}
/**
 * Represents Eligibility Step Player Appliance Model
 * @export
 * @interface EligibilityStepPlayerApplianceModel
 */
export interface EligibilityStepPlayerApplianceModel {
  /**
   *
   * @type {string}
   * @memberof EligibilityStepPlayerApplianceModel
   */
  stepTitle?: string;
  /**
   *
   * @type {string}
   * @memberof EligibilityStepPlayerApplianceModel
   */
  headText?: string;
  /**
   *
   * @type {string}
   * @memberof EligibilityStepPlayerApplianceModel
   */
  bottomText?: string;
  /**
   *
   * @type {Array<EligibilityOptionModel>}
   * @memberof EligibilityStepPlayerApplianceModel
   */
  options?: Array<EligibilityOptionModel>;
  /**
   *
   * @type {Array<LinksBlockItemPlayerHomeModel>}
   * @memberof EligibilityStepPlayerApplianceModel
   */
  links?: Array<LinksBlockItemPlayerHomeModel>;
}
/**
 *
 * @export
 * @interface EmailModel
 */
export interface EmailModel {
  /**
   *
   * @type {string}
   * @memberof EmailModel
   */
  email: string;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum EmailSubscriptionType {
  MARKETING = 'MARKETING',
}

/**
 *
 * @export
 * @interface EmailSubscriptionsRequestModel
 */
export interface EmailSubscriptionsRequestModel {
  /**
   *
   * @type {Array<EmailSubscriptionsStatus>}
   * @memberof EmailSubscriptionsRequestModel
   */
  items?: Array<EmailSubscriptionsStatus>;
  /**
   *
   * @type {string}
   * @memberof EmailSubscriptionsRequestModel
   */
  email: string;
}
/**
 *
 * @export
 * @interface EmailSubscriptionsStatus
 */
export interface EmailSubscriptionsStatus {
  /**
   *
   * @type {EmailSubscriptionType}
   * @memberof EmailSubscriptionsStatus
   */
  subscriptionType?: EmailSubscriptionType;
  /**
   *
   * @type {boolean}
   * @memberof EmailSubscriptionsStatus
   */
  enabled?: boolean;
}
/**
 *
 * @export
 * @interface EmailSubscriptionsStatusList
 */
export interface EmailSubscriptionsStatusList {
  /**
   *
   * @type {Array<EmailSubscriptionsStatus>}
   * @memberof EmailSubscriptionsStatusList
   */
  items?: Array<EmailSubscriptionsStatus>;
}
/**
 *
 * @export
 * @interface EmailTemplateAttachmentBaseModel
 */
export interface EmailTemplateAttachmentBaseModel {
  /**
   *
   * @type {string}
   * @memberof EmailTemplateAttachmentBaseModel
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof EmailTemplateAttachmentBaseModel
   */
  mimeType: string;
}
/**
 *
 * @export
 * @interface EmailTemplateAttachmentRequestModel
 */
export interface EmailTemplateAttachmentRequestModel {
  /**
   *
   * @type {string}
   * @memberof EmailTemplateAttachmentRequestModel
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof EmailTemplateAttachmentRequestModel
   */
  mimeType: string;
  /**
   *
   * @type {string}
   * @memberof EmailTemplateAttachmentRequestModel
   */
  data: string;
}
/**
 *
 * @export
 * @interface EmailTemplateAttachmentResponseModel
 */
export interface EmailTemplateAttachmentResponseModel {
  /**
   *
   * @type {string}
   * @memberof EmailTemplateAttachmentResponseModel
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof EmailTemplateAttachmentResponseModel
   */
  mimeType: string;
  /**
   *
   * @type {number}
   * @memberof EmailTemplateAttachmentResponseModel
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof EmailTemplateAttachmentResponseModel
   */
  url: string;
}
/**
 *
 * @export
 * @interface EmailTemplateAttachmentResponseModelAllOf
 */
export interface EmailTemplateAttachmentResponseModelAllOf {
  /**
   *
   * @type {number}
   * @memberof EmailTemplateAttachmentResponseModelAllOf
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof EmailTemplateAttachmentResponseModelAllOf
   */
  url: string;
}
/**
 *
 * @export
 * @interface EmailTemplateBaseModel
 */
export interface EmailTemplateBaseModel {
  /**
   *
   * @type {string}
   * @memberof EmailTemplateBaseModel
   */
  name: string;
}
/**
 *
 * @export
 * @interface EmailTemplateEditModel
 */
export interface EmailTemplateEditModel {
  /**
   *
   * @type {string}
   * @memberof EmailTemplateEditModel
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof EmailTemplateEditModel
   */
  text: string;
  /**
   *
   * @type {string}
   * @memberof EmailTemplateEditModel
   */
  subject: string;
  /**
   *
   * @type {string}
   * @memberof EmailTemplateEditModel
   */
  fromEmail?: string;
  /**
   *
   * @type {Array<EmailTemplateAttachmentRequestModel>}
   * @memberof EmailTemplateEditModel
   */
  attachments?: Array<EmailTemplateAttachmentRequestModel>;
  /**
   *
   * @type {EmailTemplateTypeEnum}
   * @memberof EmailTemplateEditModel
   */
  type: EmailTemplateTypeEnum;
  /**
   *
   * @type {EmailTemplateServiceTypeEnum}
   * @memberof EmailTemplateEditModel
   */
  serviceType?: EmailTemplateServiceTypeEnum;
  /**
   *
   * @type {IdModel}
   * @memberof EmailTemplateEditModel
   */
  championship?: IdModel;
  /**
   *
   * @type {number}
   * @memberof EmailTemplateEditModel
   */
  id: number;
  /**
   *
   * @type {Array<number>}
   * @memberof EmailTemplateEditModel
   */
  attachmentsRemove?: Array<number>;
}
/**
 *
 * @export
 * @interface EmailTemplateEditModelAllOf
 */
export interface EmailTemplateEditModelAllOf {
  /**
   *
   * @type {number}
   * @memberof EmailTemplateEditModelAllOf
   */
  id: number;
  /**
   *
   * @type {Array<number>}
   * @memberof EmailTemplateEditModelAllOf
   */
  attachmentsRemove?: Array<number>;
}
/**
 *
 * @export
 * @interface EmailTemplateFetchModel
 */
export interface EmailTemplateFetchModel {
  /**
   *
   * @type {number}
   * @memberof EmailTemplateFetchModel
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof EmailTemplateFetchModel
   */
  text?: string;
  /**
   *
   * @type {string}
   * @memberof EmailTemplateFetchModel
   */
  subject?: string;
  /**
   *
   * @type {Array<EmailTemplateAttachmentRequestModel>}
   * @memberof EmailTemplateFetchModel
   */
  attachments?: Array<EmailTemplateAttachmentRequestModel>;
  /**
   *
   * @type {EmailTemplateTypeEnum}
   * @memberof EmailTemplateFetchModel
   */
  type?: EmailTemplateTypeEnum;
  /**
   *
   * @type {EmailTemplateServiceTypeEnum}
   * @memberof EmailTemplateFetchModel
   */
  serviceType?: EmailTemplateServiceTypeEnum;
  /**
   *
   * @type {IdTitleModel}
   * @memberof EmailTemplateFetchModel
   */
  championship?: IdTitleModel;
  /**
   *
   * @type {string}
   * @memberof EmailTemplateFetchModel
   */
  name?: string;
}
/**
 *
 * @export
 * @interface EmailTemplateFullResponseModel
 */
export interface EmailTemplateFullResponseModel {
  /**
   *
   * @type {string}
   * @memberof EmailTemplateFullResponseModel
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof EmailTemplateFullResponseModel
   */
  id: number;
  /**
   *
   * @type {EmailTemplateTypeEnum}
   * @memberof EmailTemplateFullResponseModel
   */
  type: EmailTemplateTypeEnum;
  /**
   *
   * @type {EmailTemplateServiceTypeEnum}
   * @memberof EmailTemplateFullResponseModel
   */
  serviceType?: EmailTemplateServiceTypeEnum;
  /**
   *
   * @type {IdTitleModel}
   * @memberof EmailTemplateFullResponseModel
   */
  championship?: IdTitleModel;
  /**
   *
   * @type {string}
   * @memberof EmailTemplateFullResponseModel
   */
  text: string;
  /**
   *
   * @type {string}
   * @memberof EmailTemplateFullResponseModel
   */
  subject: string;
  /**
   *
   * @type {Array<EmailTemplateAttachmentResponseModel>}
   * @memberof EmailTemplateFullResponseModel
   */
  attachments?: Array<EmailTemplateAttachmentResponseModel>;
  /**
   *
   * @type {string}
   * @memberof EmailTemplateFullResponseModel
   */
  fromEmail?: string;
}
/**
 *
 * @export
 * @interface EmailTemplateFullResponseModelAllOf
 */
export interface EmailTemplateFullResponseModelAllOf {
  /**
   *
   * @type {string}
   * @memberof EmailTemplateFullResponseModelAllOf
   */
  text: string;
  /**
   *
   * @type {string}
   * @memberof EmailTemplateFullResponseModelAllOf
   */
  subject: string;
  /**
   *
   * @type {Array<EmailTemplateAttachmentResponseModel>}
   * @memberof EmailTemplateFullResponseModelAllOf
   */
  attachments?: Array<EmailTemplateAttachmentResponseModel>;
  /**
   *
   * @type {string}
   * @memberof EmailTemplateFullResponseModelAllOf
   */
  fromEmail?: string;
}
/**
 *
 * @export
 * @interface EmailTemplateKeyList
 */
export interface EmailTemplateKeyList {
  /**
   *
   * @type {Array<EmailTemplateKeyValuesEnum>}
   * @memberof EmailTemplateKeyList
   */
  items: Array<EmailTemplateKeyValuesEnum>;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum EmailTemplateKeyValuesEnum {
  PLAYERID = '{PLAYER_ID}',
  PLAYERSTATUS = '{PLAYER_STATUS}',
  ENTRYID = '{ENTRY_ID}',
  FIRSTNAME = '{FIRST_NAME}',
  LASTNAME = '{LAST_NAME}',
  CHAMPIONSHIPTITLE = '{CHAMPIONSHIP_TITLE}',
  CHAMPIONSHIPLOCATION = '{CHAMPIONSHIP_LOCATION}',
  CHAMPIONSHIPSTARTDATE = '{CHAMPIONSHIP_START_DATE}',
  CHAMPIONSHIPENDDATE = '{CHAMPIONSHIP_END_DATE}',
  TEAMID = '{TEAM_ID}',
  TEAMMATEFIRSTNAME = '{TEAMMATE_FIRST_NAME}',
  TEAMMATELASTNAME = '{TEAMMATE_LAST_NAME}',
  APPLICATIONPAGE = '{APPLICATION_PAGE}',
  APPLICATIONSTARTDATE = '{APPLICATION_START_DATE}',
  APPLICATIONENDDATE = '{APPLICATION_END_DATE}',
  EXEMPTIONTITLE = '{EXEMPTION_TITLE}',
  LOCALEXEMPTIONTITLE = '{LOCAL_EXEMPTION_TITLE}',
  FINALEXEMPTIONTITLE = '{FINAL_EXEMPTION_TITLE}',
  CLUBTITLE = '{CLUB_TITLE}',
  EMAIL = '{EMAIL}',
  CLUBADRESS = '{CLUB_ADRESS}',
  CLUBPHONENUMBER = '{CLUB_PHONE_NUMBER}',
  CLUBCITY = '{CLUB_CITY}',
  CLUBCOUNTRY = '{CLUB_COUNTRY}',
  CLUBPOSTCODE = '{CLUB_POSTCODE}',
  CLUBSALUTATION = '{CLUB_SALUTATION}',
  AGANAME = '{AGA_NAME}',
  QREASON = '{Q_REASON}',
  QNOTE = '{Q_NOTE}',
  REFUNDAMOUNT = '{REFUND_AMOUNT}',
  PARTNERREFUNDAMOUNT = '{PARTNER_REFUND_AMOUNT}',
  CHAMPIONSHIPENTRYFEE = '{CHAMPIONSHIP_ENTRY_FEE}',
  QUALIFYINGENTRYFEE = '{QUALIFYING_ENTRY_FEE}',
  ENTRYFEEBALANCEAMOUNT = '{ENTRY_FEE_BALANCE_AMOUNT}',
  QUALIFYINGLOCATIONNUMBER = '{QUALIFYING_LOCATION_NUMBER}',
  LOCALQUALIFYINGLOCATIONNUMBER = '{LOCAL_QUALIFYING_LOCATION_NUMBER}',
  FINALQUALIFYINGLOCATION1NUMBER = '{FINAL_QUALIFYING_LOCATION_1_NUMBER}',
  FINALQUALIFYINGLOCATION2NUMBER = '{FINAL_QUALIFYING_LOCATION_2_NUMBER}',
  FINALQUALIFYINGLOCATION3NUMBER = '{FINAL_QUALIFYING_LOCATION_3_NUMBER}',
  FINALQUALIFYINGLOCATIONFINALIZEDNUMBER = '{FINAL_QUALIFYING_LOCATION_FINALIZED_NUMBER}',
  ALTERNATEQUALIFYINGLOCATION1NUMBER = '{ALTERNATE_QUALIFYING_LOCATION_1_NUMBER}',
  ALTERNATEQUALIFYINGLOCATION2NUMBER = '{ALTERNATE_QUALIFYING_LOCATION_2_NUMBER}',
  ALTERNATEQUALIFYINGLOCATION3NUMBER = '{ALTERNATE_QUALIFYING_LOCATION_3_NUMBER}',
  QUALIFYINGLOCATIONNAME = '{QUALIFYING_LOCATION_NAME}',
  LOCALQUALIFYINGLOCATIONNAME = '{LOCAL_QUALIFYING_LOCATION_NAME}',
  FINALQUALIFYINGLOCATION1NAME = '{FINAL_QUALIFYING_LOCATION_1_NAME}',
  FINALQUALIFYINGLOCATION2NAME = '{FINAL_QUALIFYING_LOCATION_2_NAME}',
  FINALQUALIFYINGLOCATION3NAME = '{FINAL_QUALIFYING_LOCATION_3_NAME}',
  FINALQUALIFYINGLOCATIONFINALIZEDNAME = '{FINAL_QUALIFYING_LOCATION_FINALIZED_NAME}',
  ALTERNATEQUALIFYINGLOCATION1NAME = '{ALTERNATE_QUALIFYING_LOCATION_1_NAME}',
  ALTERNATEQUALIFYINGLOCATION2NAME = '{ALTERNATE_QUALIFYING_LOCATION_2_NAME}',
  ALTERNATEQUALIFYINGLOCATION3NAME = '{ALTERNATE_QUALIFYING_LOCATION_3_NAME}',
  QUALIFYINGLOCATIONCITY = '{QUALIFYING_LOCATION_CITY}',
  LOCALQUALIFYINGLOCATIONCITY = '{LOCAL_QUALIFYING_LOCATION_CITY}',
  FINALQUALIFYINGLOCATION1CITY = '{FINAL_QUALIFYING_LOCATION_1_CITY}',
  FINALQUALIFYINGLOCATION2CITY = '{FINAL_QUALIFYING_LOCATION_2_CITY}',
  FINALQUALIFYINGLOCATION3CITY = '{FINAL_QUALIFYING_LOCATION_3_CITY}',
  FINALQUALIFYINGLOCATIONFINALIZEDCITY = '{FINAL_QUALIFYING_LOCATION_FINALIZED_CITY}',
  ALTERNATEQUALIFYINGLOCATION1CITY = '{ALTERNATE_QUALIFYING_LOCATION_1_CITY}',
  ALTERNATEQUALIFYINGLOCATION2CITY = '{ALTERNATE_QUALIFYING_LOCATION_2_CITY}',
  ALTERNATEQUALIFYINGLOCATION3CITY = '{ALTERNATE_QUALIFYING_LOCATION_3_CITY}',
  QUALIFYINGLOCATIONSTATE = '{QUALIFYING_LOCATION_STATE}',
  LOCALQUALIFYINGLOCATIONSTATE = '{LOCAL_QUALIFYING_LOCATION_STATE}',
  FINALQUALIFYINGLOCATION1STATE = '{FINAL_QUALIFYING_LOCATION_1_STATE}',
  FINALQUALIFYINGLOCATION2STATE = '{FINAL_QUALIFYING_LOCATION_2_STATE}',
  FINALQUALIFYINGLOCATION3STATE = '{FINAL_QUALIFYING_LOCATION_3_STATE}',
  FINALQUALIFYINGLOCATIONFINALIZEDSTATE = '{FINAL_QUALIFYING_LOCATION_FINALIZED_STATE}',
  ALTERNATEQUALIFYINGLOCATION1STATE = '{ALTERNATE_QUALIFYING_LOCATION_1_STATE}',
  ALTERNATEQUALIFYINGLOCATION2STATE = '{ALTERNATE_QUALIFYING_LOCATION_2_STATE}',
  ALTERNATEQUALIFYINGLOCATION3STATE = '{ALTERNATE_QUALIFYING_LOCATION_3_STATE}',
  QUALIFYINGLOCATIONCOUNTRY = '{QUALIFYING_LOCATION_COUNTRY}',
  LOCALQUALIFYINGLOCATIONCOUNTRY = '{LOCAL_QUALIFYING_LOCATION_COUNTRY}',
  FINALQUALIFYINGLOCATION1COUNTRY = '{FINAL_QUALIFYING_LOCATION_1_COUNTRY}',
  FINALQUALIFYINGLOCATION2COUNTRY = '{FINAL_QUALIFYING_LOCATION_2_COUNTRY}',
  FINALQUALIFYINGLOCATION3COUNTRY = '{FINAL_QUALIFYING_LOCATION_3_COUNTRY}',
  FINALQUALIFYINGLOCATIONFINALIZEDCOUNTRY = '{FINAL_QUALIFYING_LOCATION_FINALIZED_COUNTRY}',
  ALTERNATEQUALIFYINGLOCATION1COUNTRY = '{ALTERNATE_QUALIFYING_LOCATION_1_COUNTRY}',
  ALTERNATEQUALIFYINGLOCATION2COUNTRY = '{ALTERNATE_QUALIFYING_LOCATION_2_COUNTRY}',
  ALTERNATEQUALIFYINGLOCATION3COUNTRY = '{ALTERNATE_QUALIFYING_LOCATION_3_COUNTRY}',
  QUALIFYINGLOCATIONDATE = '{QUALIFYING_LOCATION_DATE}',
  LOCALQUALIFYINGLOCATIONDATE = '{LOCAL_QUALIFYING_LOCATION_DATE}',
  LOCALQUALIFYINGLOCATIONRAINDATE = '{LOCAL_QUALIFYING_LOCATION_RAIN_DATE}',
  FINALQUALIFYINGLOCATION1DATE = '{FINAL_QUALIFYING_LOCATION_1_DATE}',
  FINALQUALIFYINGLOCATION2DATE = '{FINAL_QUALIFYING_LOCATION_2_DATE}',
  FINALQUALIFYINGLOCATION3DATE = '{FINAL_QUALIFYING_LOCATION_3_DATE}',
  FINALQUALIFYINGLOCATION1RAINDATE = '{FINAL_QUALIFYING_LOCATION_1_RAIN_DATE}',
  FINALQUALIFYINGLOCATION2RAINDATE = '{FINAL_QUALIFYING_LOCATION_2_RAIN_DATE}',
  FINALQUALIFYINGLOCATION3RAINDATE = '{FINAL_QUALIFYING_LOCATION_3_RAIN_DATE}',
  FINALQUALIFYINGLOCATIONFINALIZEDDATE = '{FINAL_QUALIFYING_LOCATION_FINALIZED_DATE}',
  FINALQUALIFYINGLOCATIONFINALIZEDRAINDATE = '{FINAL_QUALIFYING_LOCATION_FINALIZED_RAIN_DATE}',
  ALTERNATEQUALIFYINGLOCATION1DATE = '{ALTERNATE_QUALIFYING_LOCATION_1_DATE}',
  ALTERNATEQUALIFYINGLOCATION2DATE = '{ALTERNATE_QUALIFYING_LOCATION_2_DATE}',
  ALTERNATEQUALIFYINGLOCATION3DATE = '{ALTERNATE_QUALIFYING_LOCATION_3_DATE}',
  ALTERNATEQUALIFYINGLOCATION1RAINDATE = '{ALTERNATE_QUALIFYING_LOCATION_1_RAIN_DATE}',
  ALTERNATEQUALIFYINGLOCATION2RAINDATE = '{ALTERNATE_QUALIFYING_LOCATION_2_RAIN_DATE}',
  ALTERNATEQUALIFYINGLOCATION3RAINDATE = '{ALTERNATE_QUALIFYING_LOCATION_3_RAIN_DATE}',
  QUALIFYINGLOCATIONCLUBPHONE = '{QUALIFYING_LOCATION_CLUB_PHONE}',
  LOCALQUALIFYINGLOCATIONCLUBPHONE = '{LOCAL_QUALIFYING_LOCATION_CLUB_PHONE}',
  FINALQUALIFYINGLOCATION1CLUBPHONE = '{FINAL_QUALIFYING_LOCATION_1_CLUB_PHONE}',
  FINALQUALIFYINGLOCATION2CLUBPHONE = '{FINAL_QUALIFYING_LOCATION_2_CLUB_PHONE}',
  FINALQUALIFYINGLOCATION3CLUBPHONE = '{FINAL_QUALIFYING_LOCATION_3_CLUB_PHONE}',
  FINALQUALIFYINGLOCATIONFINALIZEDCLUBPHONE = '{FINAL_QUALIFYING_LOCATION_FINALIZED_CLUB_PHONE}',
  ALTERNATEQUALIFYINGLOCATION1CLUBPHONE = '{ALTERNATE_QUALIFYING_LOCATION_1_CLUB_PHONE}',
  ALTERNATEQUALIFYINGLOCATION2CLUBPHONE = '{ALTERNATE_QUALIFYING_LOCATION_2_CLUB_PHONE}',
  ALTERNATEQUALIFYINGLOCATION3CLUBPHONE = '{ALTERNATE_QUALIFYING_LOCATION_3_CLUB_PHONE}',
  QUALIFYINGLOCATIONPRACTICEINFO = '{QUALIFYING_LOCATION_PRACTICE_INFO}',
  LOCALQUALIFYINGLOCATIONPRACTICEINFO = '{LOCAL_QUALIFYING_LOCATION_PRACTICE_INFO}',
  FINALQUALIFYINGLOCATION1PRACTICEINFO = '{FINAL_QUALIFYING_LOCATION_1_PRACTICE_INFO}',
  FINALQUALIFYINGLOCATION2PRACTICEINFO = '{FINAL_QUALIFYING_LOCATION_2_PRACTICE_INFO}',
  FINALQUALIFYINGLOCATION3PRACTICEINFO = '{FINAL_QUALIFYING_LOCATION_3_PRACTICE_INFO}',
  FINALQUALIFYINGLOCATIONFINALIZEDPRACTICEINFO = '{FINAL_QUALIFYING_LOCATION_FINALIZED_PRACTICE_INFO}',
  ALTERNATEQUALIFYINGLOCATION1PRACTICEINFO = '{ALTERNATE_QUALIFYING_LOCATION_1_PRACTICE_INFO}',
  ALTERNATEQUALIFYINGLOCATION2PRACTICEINFO = '{ALTERNATE_QUALIFYING_LOCATION_2_PRACTICE_INFO}',
  ALTERNATEQUALIFYINGLOCATION3PRACTICEINFO = '{ALTERNATE_QUALIFYING_LOCATION_3_PRACTICE_INFO}',
  QUALIFYINGLOCATIONAGAWEBSITE = '{QUALIFYING_LOCATION_AGA_WEBSITE}',
  LOCALQUALIFYINGLOCATIONAGAWEBSITE = '{LOCAL_QUALIFYING_LOCATION_AGA_WEBSITE}',
  FINALQUALIFYINGLOCATION1AGAWEBSITE = '{FINAL_QUALIFYING_LOCATION_1_AGA_WEBSITE}',
  FINALQUALIFYINGLOCATION2AGAWEBSITE = '{FINAL_QUALIFYING_LOCATION_2_AGA_WEBSITE}',
  FINALQUALIFYINGLOCATION3AGAWEBSITE = '{FINAL_QUALIFYING_LOCATION_3_AGA_WEBSITE}',
  FINALQUALIFYINGLOCATIONFINALIZEDAGAWEBSITE = '{FINAL_QUALIFYING_LOCATION_FINALIZED_AGA_WEBSITE}',
  ALTERNATEQUALIFYINGLOCATION1AGAWEBSITE = '{ALTERNATE_QUALIFYING_LOCATION_1_AGA_WEBSITE}',
  ALTERNATEQUALIFYINGLOCATION2AGAWEBSITE = '{ALTERNATE_QUALIFYING_LOCATION_2_AGA_WEBSITE}',
  ALTERNATEQUALIFYINGLOCATION3AGAWEBSITE = '{ALTERNATE_QUALIFYING_LOCATION_3_AGA_WEBSITE}',
  QUALIFYINGLOCATIONPOCFIRSTNAME = '{QUALIFYING_LOCATION_POC_FIRST_NAME}',
  LOCALQUALIFYINGLOCATIONPOCFIRSTNAME = '{LOCAL_QUALIFYING_LOCATION_POC_FIRST_NAME}',
  FINALQUALIFYINGLOCATION1POCFIRSTNAME = '{FINAL_QUALIFYING_LOCATION_1_POC_FIRST_NAME}',
  FINALQUALIFYINGLOCATION2POCFIRSTNAME = '{FINAL_QUALIFYING_LOCATION_2_POC_FIRST_NAME}',
  FINALQUALIFYINGLOCATION3POCFIRSTNAME = '{FINAL_QUALIFYING_LOCATION_3_POC_FIRST_NAME}',
  FINALQUALIFYINGLOCATIONFINALIZEDPOCFIRSTNAME = '{FINAL_QUALIFYING_LOCATION_FINALIZED_POC_FIRST_NAME}',
  ALTERNATEQUALIFYINGLOCATION1POCFIRSTNAME = '{ALTERNATE_QUALIFYING_LOCATION_1_POC_FIRST_NAME}',
  ALTERNATEQUALIFYINGLOCATION2POCFIRSTNAME = '{ALTERNATE_QUALIFYING_LOCATION_2_POC_FIRST_NAME}',
  ALTERNATEQUALIFYINGLOCATION3POCFIRSTNAME = '{ALTERNATE_QUALIFYING_LOCATION_3_POC_FIRST_NAME}',
  QUALIFYINGLOCATIONPOCLASTNAME = '{QUALIFYING_LOCATION_POC_LAST_NAME}',
  LOCALQUALIFYINGLOCATIONPOCLASTNAME = '{LOCAL_QUALIFYING_LOCATION_POC_LAST_NAME}',
  FINALQUALIFYINGLOCATION1POCLASTNAME = '{FINAL_QUALIFYING_LOCATION_1_POC_LAST_NAME}',
  FINALQUALIFYINGLOCATION2POCLASTNAME = '{FINAL_QUALIFYING_LOCATION_2_POC_LAST_NAME}',
  FINALQUALIFYINGLOCATION3POCLASTNAME = '{FINAL_QUALIFYING_LOCATION_3_POC_LAST_NAME}',
  FINALQUALIFYINGLOCATIONFINALIZEDPOCLASTNAME = '{FINAL_QUALIFYING_LOCATION_FINALIZED_POC_LAST_NAME}',
  ALTERNATEQUALIFYINGLOCATION1POCLASTNAME = '{ALTERNATE_QUALIFYING_LOCATION_1_POC_LAST_NAME}',
  ALTERNATEQUALIFYINGLOCATION2POCLASTNAME = '{ALTERNATE_QUALIFYING_LOCATION_2_POC_LAST_NAME}',
  ALTERNATEQUALIFYINGLOCATION3POCLASTNAME = '{ALTERNATE_QUALIFYING_LOCATION_3_POC_LAST_NAME}',
  LOCALQUALIFYINGLOCATIONPOCAGA = '{LOCAL_QUALIFYING_LOCATION_POC_AGA}',
  QUALIFYINGLOCATIONPOCAGA = '{QUALIFYING_LOCATION_POC_AGA}',
  FINALQUALIFYINGLOCATION1POCAGA = '{FINAL_QUALIFYING_LOCATION_1_POC_AGA}',
  FINALQUALIFYINGLOCATION2POCAGA = '{FINAL_QUALIFYING_LOCATION_2_POC_AGA}',
  FINALQUALIFYINGLOCATION3POCAGA = '{FINAL_QUALIFYING_LOCATION_3_POC_AGA}',
  FINALQUALIFYINGLOCATIONFINALIZEDPOCAGA = '{FINAL_QUALIFYING_LOCATION_FINALIZED_POC_AGA}',
  ALTERNATEQUALIFYINGLOCATION1POCAGA = '{ALTERNATE_QUALIFYING_LOCATION_1_POC_AGA}',
  ALTERNATEQUALIFYINGLOCATION2POCAGA = '{ALTERNATE_QUALIFYING_LOCATION_2_POC_AGA}',
  ALTERNATEQUALIFYINGLOCATION3POCAGA = '{ALTERNATE_QUALIFYING_LOCATION_3_POC_AGA}',
  CREDITCARD = '{CREDIT_CARD}',
  CREDITCARDNUMBER = '{CREDIT_CARD_NUMBER}',
  CREDITCARDEXPIRATIONDATE = '{CREDIT_CARD_EXPIRATION_DATE}',
  REFUNDEDPARTNERCREDITCARD = '{REFUNDED_PARTNER_CREDIT_CARD}',
  REFUNDEDPARTNERCREDITCARDNUMBER = '{REFUNDED_PARTNER_CREDIT_CARD_NUMBER}',
  REFUNDEDPARTNERCREDITCARDEXPIRATIONDATE = '{REFUNDED_PARTNER_CREDIT_CARD_EXPIRATION_DATE}',
  PAYMENTPAGEURL = '{PAYMENT_PAGE_URL}',
  WAITLISTFEE = '{WAIT_LIST_FEE}',
  WAITLISTPOSITION = '{WAIT_LIST_POSITION}',
  WAITLISTQLOCNAME = '{WAIT_LIST_Q_LOC_NAME}',
  WAITLISTQLOCCITY = '{WAIT_LIST_Q_LOC_CITY}',
  WAITLISTQLOCSTATE = '{WAIT_LIST_Q_LOC_STATE}',
  WAITLISTQLOCCOUNTRY = '{WAIT_LIST_Q_LOC_COUNTRY}',
  WAITLISTQLOCDATE = '{WAIT_LIST_Q_LOC_DATE}',
  WAITLISTQLOCCLUBPHONE = '{WAIT_LIST_Q_LOC_CLUB_PHONE}',
  WAITLISTQLOCPRACTICEINFO = '{WAIT_LIST_Q_LOC_PRACTICE_INFO}',
  WAITLISTQLOCAGAWEBSITE = '{WAIT_LIST_Q_LOC_AGA_WEBSITE}',
  WAITLISTQLOCPOCFIRSTNAME = '{WAIT_LIST_Q_LOC_POC_FIRST_NAME}',
  WAITLISTQLOCPOCLASTNAME = '{WAIT_LIST_Q_LOC_POC_LAST_NAME}',
  WAITLISTQLOCATIONPOCAGA = '{WAIT_LIST_Q_LOCATION_POC_AGA}',
  MANAGERFIRSTNAME = '{MANAGER_FIRST_NAME}',
  MANAGERLASTNAME = '{MANAGER_LAST_NAME}',
  CONFIRMATIONPAGELINK = '{CONFIRMATION_PAGE_LINK}',
  CHAMPID = '{CHAMP_ID}',
  CCEMAILS = '{CC_EMAILS}',
  BLACKLISTTYPE = '{BLACKLIST_TYPE}',
  CLOSEENTRIESWDNUM = '{CLOSE_ENTRIES_WD_NUM}',
  QSYEAR = '{QS_YEAR}',
  QUALIFYINGLOCATIONMAXCAPACITY = '{QUALIFYING_LOCATION_MAX_CAPACITY}',
  QUALIFYINGLOCATIONRAINDATE = '{QUALIFYING_LOCATION_RAIN_DATE}',
  QUALIFYINGLOCATIONSCORINGURL = '{QUALIFYING_LOCATION_SCORING_URL}',
  QUALIFYINGLOCATIONCLUBCONTACTFIRSTNAME = '{QUALIFYING_LOCATION_CLUB_CONTACT_FIRST_NAME}',
  QUALIFYINGLOCATIONCLUBCONTACTLASTNAME = '{QUALIFYING_LOCATION_CLUB_CONTACT_LAST_NAME}',
  QUALIFYINGLOCATIONCONTACTCLUBTITLE = '{QUALIFYING_LOCATION_CONTACT_CLUB_TITLE}',
  QUALIFYINGLOCATIONCLUBADDRESS = '{QUALIFYING_LOCATION_CLUB_ADDRESS}',
  QUALIFYINGLOCATIONCLUBADDRESS2 = '{QUALIFYING_LOCATION_CLUB_ADDRESS_2}',
  QUALIFYINGLOCATIONCLUBSTATEPROVINCE = '{QUALIFYING_LOCATION_CLUB_STATE/PROVINCE}',
  QUALIFYINGLOCATIONCLUBPOSTALCODE = '{QUALIFYING_LOCATION_CLUB_POSTAL_CODE}',
  QUALIFYINGLOCATIONCLUBEMAIL = '{QUALIFYING_LOCATION_CLUB_EMAIL}',
  SPIKELESS = '{SPIKELESS}',
  CARTSAVAILABLE = '{CARTS_AVAILABLE}',
  PUSHCARTS = '{PUSH_CARTS}',
  COURSEACCESS = '{COURSE_ACCESS}',
  QUALIFYINGLOCATIONPOCTITLE = '{QUALIFYING_LOCATION_POC_TITLE}',
  QUALIFYINGLOCATIONPOCPHONENUMBER = '{QUALIFYING_LOCATION_POC_PHONE_NUMBER}',
  QUALIFYINGLOCATIONPOCMOBILENUMBER = '{QUALIFYING_LOCATION_POC_MOBILE_NUMBER}',
  QUALIFYINGLOCATIONPOCEMAIL = '{QUALIFYING_LOCATION_POC_EMAIL}',
  AGAADDRESS = '{AGA_ADDRESS}',
  AGAADDRESS2 = '{AGA_ADDRESS_2}',
  AGACITY = '{AGA_CITY}',
  AGASTATE = '{AGA_STATE}',
  AGAPOSTALCODE = '{AGA_POSTAL_CODE}',
  AGACOUNTRY = '{AGA_COUNTRY}',
  AGAPHONENUMBER = '{AGA_PHONE_NUMBER}',
  AGAWEBSITE = '{AGA_WEBSITE}',
  AGAEMAIL = '{AGA_EMAIL}',
  EMAILSSHOULDBENOTIFIED = '{EMAILS_SHOULD_BE_NOTIFIED}',
  QUALIFYINGLOCATIONPOCDELIVERYADDRESS = '{QUALIFYING_LOCATION_POC_DELIVERY_ADDRESS}',
  QUALIFYINGLOCATIONPOCDELIVERYADDRESS2 = '{QUALIFYING_LOCATION_POC_DELIVERY_ADDRESS_2}',
  QUALIFYINGLOCATIONPOCDELIVERYCITY = '{QUALIFYING_LOCATION_POC_DELIVERY_CITY}',
  QUALIFYINGLOCATIONPOCDELIVERYSTATE = '{QUALIFYING_LOCATION_POC_DELIVERY_STATE}',
  QUALIFYINGLOCATIONPOCDELIVERYPOSTALCODE = '{QUALIFYING_LOCATION_POC_DELIVERY_POSTAL_CODE}',
  QUALIFYINGLOCATIONPOCCOUNTRY = '{QUALIFYING_LOCATION_POC_COUNTRY}',
  SUBMITTEDFIRSTNAME = '{SUBMITTED_FIRST_NAME}',
  SUBMITTEDLASTNAME = '{SUBMITTED_LAST_NAME}',
  SUBMITTEDBYAGA = '{SUBMITTED_BY_AGA}',
  POCCOMMENTS = '{POC_COMMENTS}',
  QUALIFYINGTYPE = '{QUALIFYING_TYPE}',
  ADAPTIVEWR4GDPASS = '{ADAPTIVE_WR4GD_PASS}',
  ADAPTIVEDISABILITYDESCRIPTION = '{ADAPTIVE_DISABILITY_DESCRIPTION}',
  ADAPTIVEDISABILITYTYPE = '{ADAPTIVE_DISABILITY_TYPE}',
  ADAPTIVELEGALGUARDIAN = '{ADAPTIVE_LEGAL_GUARDIAN}',
  ADAPTIVEVIRTUSII1NUMBER = '{ADAPTIVE_VIRTUSII1_NUMBER}',
  ADAPTIVEPLAYGOLFINCART = '{ADAPTIVE_PLAY_GOLF_IN_CART}',
  ADAPTIVEEQUIPMENTDESCRIPTION = '{ADAPTIVE_EQUIPMENT_DESCRIPTION}',
  ADAPTIVECOMPPLAYINGRECORD = '{ADAPTIVE_COMP_PLAYING_RECORD}',
  DATEOFBIRTH = '{DATE_OF_BIRTH}',
  REINSTATEMENTDATE = '{REINSTATEMENT_DATE}',
  WAITLISTCLOSEDATE = '{WAITLIST_CLOSE_DATE}',
  REPLACEDPLAYERSDATA = '{REPLACED_PLAYERS_DATA}',
  ADDEDPLAYERSDATA = '{ADDED_PLAYERS_DATA}',
}

/**
 *
 * @export
 * @interface EmailTemplateListModel
 */
export interface EmailTemplateListModel {
  /**
   *
   * @type {Array<EmailTemplateResponseModel>}
   * @memberof EmailTemplateListModel
   */
  items: Array<EmailTemplateResponseModel>;
  /**
   *
   * @type {number}
   * @memberof EmailTemplateListModel
   */
  limit: number;
  /**
   *
   * @type {number}
   * @memberof EmailTemplateListModel
   */
  start: number;
  /**
   *
   * @type {number}
   * @memberof EmailTemplateListModel
   */
  total: number;
}
/**
 *
 * @export
 * @interface EmailTemplateQueryParamBean
 */
export interface EmailTemplateQueryParamBean {
  /**
   *
   * @type {number}
   * @memberof EmailTemplateQueryParamBean
   */
  limit: number;
  /**
   *
   * @type {number}
   * @memberof EmailTemplateQueryParamBean
   */
  start: number;
  /**
   *
   * @type {SortingFilterEnum}
   * @memberof EmailTemplateQueryParamBean
   */
  sortingFilter?: SortingFilterEnum;
  /**
   *
   * @type {string}
   * @memberof EmailTemplateQueryParamBean
   */
  query?: string;
  /**
   *
   * @type {EmailTemplateTypeEnum}
   * @memberof EmailTemplateQueryParamBean
   */
  type?: EmailTemplateTypeEnum;
  /**
   *
   * @type {EmailTemplateServiceTypeEnum}
   * @memberof EmailTemplateQueryParamBean
   */
  serviceType?: EmailTemplateServiceTypeEnum;
  /**
   *
   * @type {number}
   * @memberof EmailTemplateQueryParamBean
   */
  champId?: number;
}
/**
 *
 * @export
 * @interface EmailTemplateQueryParamBeanAllOf
 */
export interface EmailTemplateQueryParamBeanAllOf {
  /**
   *
   * @type {SortingFilterEnum}
   * @memberof EmailTemplateQueryParamBeanAllOf
   */
  sortingFilter?: SortingFilterEnum;
  /**
   *
   * @type {string}
   * @memberof EmailTemplateQueryParamBeanAllOf
   */
  query?: string;
  /**
   *
   * @type {EmailTemplateTypeEnum}
   * @memberof EmailTemplateQueryParamBeanAllOf
   */
  type?: EmailTemplateTypeEnum;
  /**
   *
   * @type {EmailTemplateServiceTypeEnum}
   * @memberof EmailTemplateQueryParamBeanAllOf
   */
  serviceType?: EmailTemplateServiceTypeEnum;
  /**
   *
   * @type {number}
   * @memberof EmailTemplateQueryParamBeanAllOf
   */
  champId?: number;
}
/**
 *
 * @export
 * @interface EmailTemplateRequestModel
 */
export interface EmailTemplateRequestModel {
  /**
   *
   * @type {string}
   * @memberof EmailTemplateRequestModel
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof EmailTemplateRequestModel
   */
  text: string;
  /**
   *
   * @type {string}
   * @memberof EmailTemplateRequestModel
   */
  subject: string;
  /**
   *
   * @type {string}
   * @memberof EmailTemplateRequestModel
   */
  fromEmail?: string;
  /**
   *
   * @type {Array<EmailTemplateAttachmentRequestModel>}
   * @memberof EmailTemplateRequestModel
   */
  attachments?: Array<EmailTemplateAttachmentRequestModel>;
  /**
   *
   * @type {EmailTemplateTypeEnum}
   * @memberof EmailTemplateRequestModel
   */
  type: EmailTemplateTypeEnum;
  /**
   *
   * @type {EmailTemplateServiceTypeEnum}
   * @memberof EmailTemplateRequestModel
   */
  serviceType?: EmailTemplateServiceTypeEnum;
  /**
   *
   * @type {IdModel}
   * @memberof EmailTemplateRequestModel
   */
  championship?: IdModel;
}
/**
 *
 * @export
 * @interface EmailTemplateRequestModelAllOf
 */
export interface EmailTemplateRequestModelAllOf {
  /**
   *
   * @type {string}
   * @memberof EmailTemplateRequestModelAllOf
   */
  text: string;
  /**
   *
   * @type {string}
   * @memberof EmailTemplateRequestModelAllOf
   */
  subject: string;
  /**
   *
   * @type {string}
   * @memberof EmailTemplateRequestModelAllOf
   */
  fromEmail?: string;
  /**
   *
   * @type {Array<EmailTemplateAttachmentRequestModel>}
   * @memberof EmailTemplateRequestModelAllOf
   */
  attachments?: Array<EmailTemplateAttachmentRequestModel>;
  /**
   *
   * @type {EmailTemplateTypeEnum}
   * @memberof EmailTemplateRequestModelAllOf
   */
  type: EmailTemplateTypeEnum;
  /**
   *
   * @type {EmailTemplateServiceTypeEnum}
   * @memberof EmailTemplateRequestModelAllOf
   */
  serviceType?: EmailTemplateServiceTypeEnum;
  /**
   *
   * @type {IdModel}
   * @memberof EmailTemplateRequestModelAllOf
   */
  championship?: IdModel;
}
/**
 *
 * @export
 * @interface EmailTemplateResponseModel
 */
export interface EmailTemplateResponseModel {
  /**
   *
   * @type {string}
   * @memberof EmailTemplateResponseModel
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof EmailTemplateResponseModel
   */
  id: number;
  /**
   *
   * @type {EmailTemplateTypeEnum}
   * @memberof EmailTemplateResponseModel
   */
  type: EmailTemplateTypeEnum;
  /**
   *
   * @type {EmailTemplateServiceTypeEnum}
   * @memberof EmailTemplateResponseModel
   */
  serviceType?: EmailTemplateServiceTypeEnum;
  /**
   *
   * @type {IdTitleModel}
   * @memberof EmailTemplateResponseModel
   */
  championship?: IdTitleModel;
}
/**
 *
 * @export
 * @interface EmailTemplateResponseModelAllOf
 */
export interface EmailTemplateResponseModelAllOf {
  /**
   *
   * @type {number}
   * @memberof EmailTemplateResponseModelAllOf
   */
  id: number;
  /**
   *
   * @type {EmailTemplateTypeEnum}
   * @memberof EmailTemplateResponseModelAllOf
   */
  type: EmailTemplateTypeEnum;
  /**
   *
   * @type {EmailTemplateServiceTypeEnum}
   * @memberof EmailTemplateResponseModelAllOf
   */
  serviceType?: EmailTemplateServiceTypeEnum;
  /**
   *
   * @type {IdTitleModel}
   * @memberof EmailTemplateResponseModelAllOf
   */
  championship?: IdTitleModel;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum EmailTemplateServiceTypeEnum {
  EXEMPTTEAM = 'EXEMPT_TEAM',
  EXEMPTQUALIFYTEAM = 'EXEMPT_QUALIFY_TEAM',
  EXEMPTAPP = 'EXEMPT_APP',
  EXEMPTPLAYER = 'EXEMPT_PLAYER',
  NOTREFUNDEDTEAMMATEAPPLICATIONLINK = 'NOT_REFUNDED_TEAMMATE_APPLICATION_LINK',
  REFUNDEDAPPLICATION = 'REFUNDED_APPLICATION',
  REFUNDEDWLAPPLICATION = 'REFUNDED_WL_APPLICATION',
  SECONDAPPLICANTLINK = 'SECOND_APPLICANT_LINK',
  TEAMAPPLICATION = 'TEAM_APPLICATION',
  WELCOMEAPPLICANT = 'WELCOME_APPLICANT',
  WELCOMEAPPPLAYERA = 'WELCOME_APP_PLAYER_A',
  WELCOMEEXEMPTAPP = 'WELCOME_EXEMPT_APP',
  WELCOMELOCEXEMPTFINALQAPP = 'WELCOME_LOC_EXEMPT_FINAL_Q_APP',
  WELCOMELOCEXEMPTLOCFINALQAPP = 'WELCOME_LOC_EXEMPT_LOC_FINAL_Q_APP',
  WELCOMEEXEMPTQUALIFYAPP = 'WELCOME_EXEMPT_QUALIFY_APP',
  INCOMPLETESIDE = 'INCOMPLETE_SIDE',
  BLPROFILEPERFORMANCEVIOLATION1 = 'BL_PROFILE_PERFORMANCE_VIOLATION_1',
  BLPROFILEPERFORMANCEVIOLATION2 = 'BL_PROFILE_PERFORMANCE_VIOLATION_2',
  BLPROFILENOSHOW1 = 'BL_PROFILE_NO_SHOW_1',
  BLPROFILENOSHOW2 = 'BL_PROFILE_NO_SHOW_2',
  BLPROFILENOSHOW3 = 'BL_PROFILE_NO_SHOW_3',
  WLPROFILEINFOCONFIRMATION = 'WL_PROFILE_INFO_CONFIRMATION',
  FINALCONFIRMATIONQUALIFYING = 'FINAL_CONFIRMATION_QUALIFYING',
  FINALCONFIRMATIONFINALISTS = 'FINAL_CONFIRMATION_FINALISTS',
  FINALCONFIRMATIONLOCALEXEMPT = 'FINAL_CONFIRMATION_LOCAL_EXEMPT',
  FINALCONFIRMATIONWAITLIST = 'FINAL_CONFIRMATION_WAITLIST',
  BULKPARTNERLINK = 'BULK_PARTNER_LINK',
  QASSIGNEENOTIFICATION = 'Q_ASSIGNEE_NOTIFICATION',
  CHAMPIONSHIPQUALIFIEDEMAIL = 'CHAMPIONSHIP_QUALIFIED_EMAIL',
  ALTERNATEINFOEMAIL = 'ALTERNATE_INFO_EMAIL',
  LOCALTOSECTIONALQUALIFIEDEMAIL = 'LOCAL_TO_SECTIONAL_QUALIFIED_EMAIL',
  LOCALTOSECTIONALALTERNATEEMAIL = 'LOCAL_TO_SECTIONAL_ALTERNATE_EMAIL',
  WDINCOMPLETESIDE = 'WD_INCOMPLETE_SIDE',
  NOTIFYAPPUPDATE = 'NOTIFY_APP_UPDATE',
  NOTIFYPLAYERUPDATE = 'NOTIFY_PLAYER_UPDATE',
  DECLINEDPAYMENT = 'DECLINED_PAYMENT',
  WAITLISTTOQUALIFYINGDECLINEDPAYMENTFEENOTPAID = 'WAITLIST_TO_QUALIFYING_DECLINED_PAYMENT_FEE_NOT_PAID',
  ENTRYFEEBALANCEDECLINEDPAYMENT = 'ENTRY_FEE_BALANCE_DECLINED_PAYMENT',
  DECLINEDPAYMENTPARTNERNOTIFICATION = 'DECLINED_PAYMENT_PARTNER_NOTIFICATION',
  WAITLISTTOQUALIFYINGSUCCESSPAYMENT = 'WAITLIST_TO_QUALIFYING_SUCCESS_PAYMENT',
  WAITLISTTOQUALIFYINGSUCCESSPAYMENTAFTERCLOSEOFENTRIES = 'WAITLIST_TO_QUALIFYING_SUCCESS_PAYMENT_AFTER_CLOSE_OF_ENTRIES',
  WELCOMEWAITLISTAPPLICANT = 'WELCOME_WAITLIST_APPLICANT',
  WELCOMEWAITLISTTEAMAPPLICANT = 'WELCOME_WAITLIST_TEAM_APPLICANT',
  WELCOMEEXEMPTWAITLISTTEAMAPPLICANT = 'WELCOME_EXEMPT_WAITLIST_TEAM_APPLICANT',
  WLTOWLTRANSFER = 'WL_TO_WL_TRANSFER',
  WLTOQSTRANSFER = 'WL_TO_QS_TRANSFER',
  QSTOQSTRANSFER = 'QS_TO_QS_TRANSFER',
  LINKPROFILESCONFIRMATION = 'LINK_PROFILES_CONFIRMATION',
  MANAGERADDEDNOTIFICATION = 'MANAGER_ADDED_NOTIFICATION',
  MERGESIDESCONFIRMATION = 'MERGE_SIDES_CONFIRMATION',
  AGASWDNOTIFICATION = 'AGAS_WD_NOTIFICATION',
  AGASWAITLISTNOTIFICATION = 'AGAS_WAITLIST_NOTIFICATION',
  AGASQSNOTIFICATION = 'AGAS_QS_NOTIFICATION',
  BLCLEARED = 'BL_CLEARED',
  PLAYERSURVEYEMAILFORQUALIFYING = 'PLAYER_SURVEY_EMAIL_FOR_QUALIFYING',
  PLAYERREINSTATEDREQUEST = 'PLAYER_REINSTATED_REQUEST',
  PLAYERREINSTATEDREQUESTNOTFOUND = 'PLAYER_REINSTATED_REQUEST_NOT_FOUND',
  PLAYERREINSTATED = 'PLAYER_REINSTATED',
  PLAYERREINSTATEDNOTFOUND = 'PLAYER_REINSTATED_NOT_FOUND',
  ENTRYFEEBALANCE = 'ENTRY_FEE_BALANCE',
  WDFORPERFORMANCEVIOLATION = 'WD_FOR_PERFORMANCE_VIOLATION',
}

/**
 *
 * @export
 * @enum {string}
 */
export enum EmailTemplateTypeEnum {
  SERVICE = 'SERVICE',
  CUSTOM = 'CUSTOM',
  CUSTOMMARKETING = 'CUSTOM_MARKETING',
  OFFICIAL = 'OFFICIAL',
  CLUB = 'CLUB',
}

/**
 *
 * @export
 * @interface EmailTemplateWithAttachedFilesModel
 */
export interface EmailTemplateWithAttachedFilesModel {
  /**
   *
   * @type {string}
   * @memberof EmailTemplateWithAttachedFilesModel
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof EmailTemplateWithAttachedFilesModel
   */
  id: number;
  /**
   *
   * @type {EmailTemplateTypeEnum}
   * @memberof EmailTemplateWithAttachedFilesModel
   */
  type: EmailTemplateTypeEnum;
  /**
   *
   * @type {EmailTemplateServiceTypeEnum}
   * @memberof EmailTemplateWithAttachedFilesModel
   */
  serviceType?: EmailTemplateServiceTypeEnum;
  /**
   *
   * @type {IdTitleModel}
   * @memberof EmailTemplateWithAttachedFilesModel
   */
  championship?: IdTitleModel;
  /**
   *
   * @type {string}
   * @memberof EmailTemplateWithAttachedFilesModel
   */
  text: string;
  /**
   *
   * @type {string}
   * @memberof EmailTemplateWithAttachedFilesModel
   */
  subject: string;
  /**
   *
   * @type {string}
   * @memberof EmailTemplateWithAttachedFilesModel
   */
  fromEmail?: string;
  /**
   *
   * @type {Array<EmailTemplateAttachmentRequestModel>}
   * @memberof EmailTemplateWithAttachedFilesModel
   */
  attachments?: Array<EmailTemplateAttachmentRequestModel>;
}
/**
 *
 * @export
 * @interface EmailTemplateWithAttachedFilesModelAllOf
 */
export interface EmailTemplateWithAttachedFilesModelAllOf {
  /**
   *
   * @type {string}
   * @memberof EmailTemplateWithAttachedFilesModelAllOf
   */
  text: string;
  /**
   *
   * @type {string}
   * @memberof EmailTemplateWithAttachedFilesModelAllOf
   */
  subject: string;
  /**
   *
   * @type {string}
   * @memberof EmailTemplateWithAttachedFilesModelAllOf
   */
  fromEmail?: string;
  /**
   *
   * @type {Array<EmailTemplateAttachmentRequestModel>}
   * @memberof EmailTemplateWithAttachedFilesModelAllOf
   */
  attachments?: Array<EmailTemplateAttachmentRequestModel>;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum EntitiesTypeEnum {
  SITE = 'SITE',
  CLUB = 'CLUB',
  POC = 'POC',
  APPLICATION = 'APPLICATION',
  CHAMPIONSHIP = 'CHAMPIONSHIP',
  EXEMPTION = 'EXEMPTION',
  AGA = 'AGA',
  BLACKLIST = 'BLACKLIST',
  EMAILTEMPLATE = 'EMAIL_TEMPLATE',
  MESSAGE = 'MESSAGE',
  PAIR = 'PAIR',
  TEAM = 'TEAM',
  PLAYER = 'PLAYER',
  PENDINGPAYMENT = 'PENDING_PAYMENT',
  RESERVESPOT = 'RESERVE_SPOT',
  UPLOADEDFILES = 'UPLOADED_FILES',
}

/**
 *
 * @export
 * @enum {string}
 */
export enum EthnicityEnum {
  AMERICANINDIANALASKANATIVE = 'AMERICAN_INDIAN_ALASKA_NATIVE',
  ASIAN = 'ASIAN',
  BLACKAFRICANAMERICAN = 'BLACK_AFRICAN_AMERICAN',
  HISPANICLATINO = 'HISPANIC_LATINO',
  NATIVEHAWAIIANPACIFICISLANDER = 'NATIVE_HAWAIIAN_PACIFIC_ISLANDER',
  WHITE = 'WHITE',
  MULTIRACIAL = 'MULTI_RACIAL',
  NOANSWER = 'NO_ANSWER',
}

/**
 *
 * @export
 * @interface EventCardsPlayerChampModel
 */
export interface EventCardsPlayerChampModel {
  /**
   *
   * @type {boolean}
   * @memberof EventCardsPlayerChampModel
   */
  active: boolean;
  /**
   *
   * @type {ChampionshipRootModelFinalSiteControl}
   * @memberof EventCardsPlayerChampModel
   */
  finalSiteControl?: ChampionshipRootModelFinalSiteControl;
  /**
   *
   * @type {InfoPDF}
   * @memberof EventCardsPlayerChampModel
   */
  infoPDF?: InfoPDF;
  /**
   *
   * @type {boolean}
   * @memberof EventCardsPlayerChampModel
   */
  antiDoping?: boolean;
  /**
   *
   * @type {string}
   * @memberof EventCardsPlayerChampModel
   */
  backgroundImage?: string;
  /**
   *
   * @type {string}
   * @memberof EventCardsPlayerChampModel
   */
  logo?: string;
  /**
   *
   * @type {number}
   * @memberof EventCardsPlayerChampModel
   */
  championshipEntryFee?: number;
  /**
   *
   * @type {number}
   * @memberof EventCardsPlayerChampModel
   */
  qualifyingEntryFee?: number;
  /**
   *
   * @type {number}
   * @memberof EventCardsPlayerChampModel
   */
  waitlistFee?: number;
  /**
   *
   * @type {ChampionshipAgeLimitModel}
   * @memberof EventCardsPlayerChampModel
   */
  ageLimit?: ChampionshipAgeLimitModel;
  /**
   *
   * @type {string}
   * @memberof EventCardsPlayerChampModel
   */
  ageLimitBannerText?: string;
  /**
   *
   * @type {string}
   * @memberof EventCardsPlayerChampModel
   */
  ageLimitValidateDate: string;
  /**
   *
   * @type {string}
   * @memberof EventCardsPlayerChampModel
   */
  applicationStartDate?: string;
  /**
   *
   * @type {string}
   * @memberof EventCardsPlayerChampModel
   */
  applicationEndDate?: string;
  /**
   *
   * @type {string}
   * @memberof EventCardsPlayerChampModel
   */
  endDate: string;
  /**
   *
   * @type {string}
   * @memberof EventCardsPlayerChampModel
   */
  startDate: string;
  /**
   *
   * @type {string}
   * @memberof EventCardsPlayerChampModel
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof EventCardsPlayerChampModel
   */
  overviewUrl?: string;
  /**
   *
   * @type {string}
   * @memberof EventCardsPlayerChampModel
   */
  qualifyingStartDate?: string;
  /**
   *
   * @type {string}
   * @memberof EventCardsPlayerChampModel
   */
  qualifyingEndDate?: string;
  /**
   *
   * @type {GenderEnum}
   * @memberof EventCardsPlayerChampModel
   */
  gender: GenderEnum;
  /**
   *
   * @type {number}
   * @memberof EventCardsPlayerChampModel
   */
  maxHandicapIndex: number;
  /**
   *
   * @type {string}
   * @memberof EventCardsPlayerChampModel
   */
  maxHandicapIndexOverflowMessage: string;
  /**
   *
   * @type {ChampionshipMinorityModel}
   * @memberof EventCardsPlayerChampModel
   */
  minority: ChampionshipMinorityModel;
  /**
   *
   * @type {ChampionshipOppositeGenderModel}
   * @memberof EventCardsPlayerChampModel
   */
  oppositeGender: ChampionshipOppositeGenderModel;
  /**
   *
   * @type {string}
   * @memberof EventCardsPlayerChampModel
   */
  localQualifyingStartDate?: string;
  /**
   *
   * @type {string}
   * @memberof EventCardsPlayerChampModel
   */
  localQualifyingEndDate?: string;
  /**
   *
   * @type {boolean}
   * @memberof EventCardsPlayerChampModel
   */
  stubTextEnabled?: boolean;
  /**
   *
   * @type {string}
   * @memberof EventCardsPlayerChampModel
   */
  stubText?: string;
  /**
   *
   * @type {boolean}
   * @memberof EventCardsPlayerChampModel
   */
  allExempt?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof EventCardsPlayerChampModel
   */
  localQualifyingDisabled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof EventCardsPlayerChampModel
   */
  localExemptionDisabled?: boolean;
  /**
   *
   * @type {ChampAdditionalQualifyingInfoModel}
   * @memberof EventCardsPlayerChampModel
   */
  additionalQualifyingInfo?: ChampAdditionalQualifyingInfoModel;
  /**
   *
   * @type {ChampOverviewInfoModel}
   * @memberof EventCardsPlayerChampModel
   */
  overviewInfo?: ChampOverviewInfoModel;
  /**
   *
   * @type {boolean}
   * @memberof EventCardsPlayerChampModel
   */
  sendSurveyEmail?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof EventCardsPlayerChampModel
   */
  showWaitlistedPlayersCTA?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof EventCardsPlayerChampModel
   */
  showReplacementAfterCloseCTA?: boolean;
  /**
   *
   * @type {number}
   * @memberof EventCardsPlayerChampModel
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof EventCardsPlayerChampModel
   */
  year: number;
  /**
   *
   * @type {ClubAdminModel}
   * @memberof EventCardsPlayerChampModel
   */
  club: ClubAdminModel;
  /**
   *
   * @type {ChampionshipTypeModel}
   * @memberof EventCardsPlayerChampModel
   */
  championshipType: ChampionshipTypeModel;
  /**
   *
   * @type {ChampionshipButtonControlModel}
   * @memberof EventCardsPlayerChampModel
   */
  currentPlayerListControl?: ChampionshipButtonControlModel;
  /**
   *
   * @type {ChampionshipButtonControlModel}
   * @memberof EventCardsPlayerChampModel
   */
  playersWithdrawnControl?: ChampionshipButtonControlModel;
  /**
   *
   * @type {ChampionshipButtonControlModel}
   * @memberof EventCardsPlayerChampModel
   */
  uploadResultsControl?: ChampionshipButtonControlModel;
  /**
   *
   * @type {ChampionshipButtonControlModel}
   * @memberof EventCardsPlayerChampModel
   */
  staffCurrentChampField?: ChampionshipButtonControlModel;
  /**
   *
   * @type {ChampionshipButtonControlModel}
   * @memberof EventCardsPlayerChampModel
   */
  staffChampReports?: ChampionshipButtonControlModel;
  /**
   *
   * @type {ChampionshipButtonControlModel}
   * @memberof EventCardsPlayerChampModel
   */
  staffCurrentAlternates?: ChampionshipButtonControlModel;
  /**
   *
   * @type {boolean}
   * @memberof EventCardsPlayerChampModel
   */
  allEntriesClosed: boolean;
  /**
   *
   * @type {Array<QualifyingSiteModel>}
   * @memberof EventCardsPlayerChampModel
   */
  qualifyingSites?: Array<QualifyingSiteModel>;
  /**
   *
   * @type {boolean}
   * @memberof EventCardsPlayerChampModel
   */
  hideQualifyingSites?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof EventCardsPlayerChampModel
   */
  hideSneakPeek?: boolean;
  /**
   *
   * @type {Array<IdModel>}
   * @memberof EventCardsPlayerChampModel
   */
  eligibleLateApplyPlayers?: Array<IdModel>;
  /**
   *
   * @type {string}
   * @memberof EventCardsPlayerChampModel
   */
  lateApplyDeadlineDate?: string;
  /**
   *
   * @type {string}
   * @memberof EventCardsPlayerChampModel
   */
  pagePath: string;
  /**
   *
   * @type {string}
   * @memberof EventCardsPlayerChampModel
   */
  withdrawPagePath?: string;
  /**
   *
   * @type {string}
   * @memberof EventCardsPlayerChampModel
   */
  additionalInfo?: string;
  /**
   *
   * @type {EventCardsPlayerChampModelAllOfSneakPeek}
   * @memberof EventCardsPlayerChampModel
   */
  sneakPeek?: EventCardsPlayerChampModelAllOfSneakPeek;
}
/**
 *
 * @export
 * @interface EventCardsPlayerChampModelAllOf
 */
export interface EventCardsPlayerChampModelAllOf {
  /**
   *
   * @type {string}
   * @memberof EventCardsPlayerChampModelAllOf
   */
  pagePath: string;
  /**
   *
   * @type {string}
   * @memberof EventCardsPlayerChampModelAllOf
   */
  withdrawPagePath?: string;
  /**
   *
   * @type {string}
   * @memberof EventCardsPlayerChampModelAllOf
   */
  additionalInfo?: string;
  /**
   *
   * @type {EventCardsPlayerChampModelAllOfSneakPeek}
   * @memberof EventCardsPlayerChampModelAllOf
   */
  sneakPeek?: EventCardsPlayerChampModelAllOfSneakPeek;
}
/**
 *
 * @export
 * @interface EventCardsPlayerChampModelAllOfSneakPeek
 */
export interface EventCardsPlayerChampModelAllOfSneakPeek {
  /**
   *
   * @type {string}
   * @memberof EventCardsPlayerChampModelAllOfSneakPeek
   */
  topText?: string;
  /**
   *
   * @type {string}
   * @memberof EventCardsPlayerChampModelAllOfSneakPeek
   */
  bottomText?: string;
}
/**
 * Represents Selection Tabs Component
 * @export
 * @interface EventCardsPlayerHomeModel
 */
export interface EventCardsPlayerHomeModel {
  /**
   *
   * @type {Array<EventCardsPlayerChampModel>}
   * @memberof EventCardsPlayerHomeModel
   */
  items?: Array<EventCardsPlayerChampModel>;
}
/**
 *
 * @export
 * @interface ExemptionAdminAddModel
 */
export interface ExemptionAdminAddModel extends ExemptionBaseModel {}
/**
 *
 * @export
 * @interface ExemptionAdminEditModel
 */
export interface ExemptionAdminEditModel extends ExemptionModel {}
/**
 *
 * @export
 * @interface ExemptionAdminLightWeightModel
 */
export interface ExemptionAdminLightWeightModel {
  /**
   *
   * @type {number}
   * @memberof ExemptionAdminLightWeightModel
   */
  id: number;
  /**
   *
   * @type {QualifyingLevelEnum}
   * @memberof ExemptionAdminLightWeightModel
   */
  level: QualifyingLevelEnum;
  /**
   *
   * @type {number}
   * @memberof ExemptionAdminLightWeightModel
   */
  number: number;
  /**
   *
   * @type {string}
   * @memberof ExemptionAdminLightWeightModel
   */
  title: string;
  /**
   *
   * @type {SpecificExemptionsEnum}
   * @memberof ExemptionAdminLightWeightModel
   */
  specific?: SpecificExemptionsEnum;
  /**
   *
   * @type {Array<string>}
   * @memberof ExemptionAdminLightWeightModel
   */
  players?: Array<string>;
  /**
   *
   * @type {Array<ExemptionAdminLightWeightModelProfiles>}
   * @memberof ExemptionAdminLightWeightModel
   */
  profiles?: Array<ExemptionAdminLightWeightModelProfiles>;
}
/**
 *
 * @export
 * @interface ExemptionAdminLightWeightModelProfiles
 */
export interface ExemptionAdminLightWeightModelProfiles {
  /**
   *
   * @type {MessageAdminModelAllOfExemptionChampionship}
   * @memberof ExemptionAdminLightWeightModelProfiles
   */
  player: MessageAdminModelAllOfExemptionChampionship;
}
/**
 *
 * @export
 * @interface ExemptionAdminListLightWeightModel
 */
export interface ExemptionAdminListLightWeightModel {
  /**
   *
   * @type {Array<ExemptionAdminLightWeightModel>}
   * @memberof ExemptionAdminListLightWeightModel
   */
  items: Array<ExemptionAdminLightWeightModel>;
  /**
   *
   * @type {number}
   * @memberof ExemptionAdminListLightWeightModel
   */
  limit: number;
  /**
   *
   * @type {number}
   * @memberof ExemptionAdminListLightWeightModel
   */
  start: number;
  /**
   *
   * @type {number}
   * @memberof ExemptionAdminListLightWeightModel
   */
  total: number;
}
/**
 *
 * @export
 * @interface ExemptionAdminListModel
 */
export interface ExemptionAdminListModel {
  /**
   *
   * @type {Array<ExemptionModel>}
   * @memberof ExemptionAdminListModel
   */
  items: Array<ExemptionModel>;
  /**
   *
   * @type {number}
   * @memberof ExemptionAdminListModel
   */
  limit: number;
  /**
   *
   * @type {number}
   * @memberof ExemptionAdminListModel
   */
  start: number;
  /**
   *
   * @type {number}
   * @memberof ExemptionAdminListModel
   */
  total: number;
}
/**
 *
 * @export
 * @interface ExemptionAppliedAppsModel
 */
export interface ExemptionAppliedAppsModel {
  /**
   *
   * @type {number}
   * @memberof ExemptionAppliedAppsModel
   */
  id: number;
  /**
   *
   * @type {QualifyingLevelEnum}
   * @memberof ExemptionAppliedAppsModel
   */
  level: QualifyingLevelEnum;
  /**
   *
   * @type {number}
   * @memberof ExemptionAppliedAppsModel
   */
  number: number;
  /**
   *
   * @type {string}
   * @memberof ExemptionAppliedAppsModel
   */
  title: string;
  /**
   *
   * @type {boolean}
   * @memberof ExemptionAppliedAppsModel
   */
  requireQualifyingSelection: boolean;
}
/**
 *
 * @export
 * @interface ExemptionBaseModel
 */
export interface ExemptionBaseModel {
  /**
   *
   * @type {Array<string>}
   * @memberof ExemptionBaseModel
   */
  players?: Array<string>;
  /**
   *
   * @type {QualifyingLevelEnum}
   * @memberof ExemptionBaseModel
   */
  level: QualifyingLevelEnum;
  /**
   *
   * @type {number}
   * @memberof ExemptionBaseModel
   */
  number: number;
  /**
   *
   * @type {string}
   * @memberof ExemptionBaseModel
   */
  title: string;
  /**
   *
   * @type {SpecificExemptionsEnum}
   * @memberof ExemptionBaseModel
   */
  specific?: SpecificExemptionsEnum;
  /**
   *
   * @type {boolean}
   * @memberof ExemptionBaseModel
   */
  requireQualifyingSelection: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ExemptionBaseModel
   */
  hidePlayers?: boolean;
  /**
   *
   * @type {IdModel}
   * @memberof ExemptionBaseModel
   */
  championship?: IdModel;
  /**
   *
   * @type {Array<MessageAdminModelAllOfExemptionProfiles>}
   * @memberof ExemptionBaseModel
   */
  profiles?: Array<MessageAdminModelAllOfExemptionProfiles>;
}
/**
 *
 * @export
 * @interface ExemptionDeleteFetchModel
 */
export interface ExemptionDeleteFetchModel {
  /**
   *
   * @type {ExemptionDeleteFetchModelChampionship}
   * @memberof ExemptionDeleteFetchModel
   */
  championship?: ExemptionDeleteFetchModelChampionship;
  /**
   *
   * @type {Array<ExemptionDeleteFetchModelApplication>}
   * @memberof ExemptionDeleteFetchModel
   */
  application?: Array<ExemptionDeleteFetchModelApplication>;
}
/**
 *
 * @export
 * @interface ExemptionDeleteFetchModelApplication
 */
export interface ExemptionDeleteFetchModelApplication {
  /**
   *
   * @type {number}
   * @memberof ExemptionDeleteFetchModelApplication
   */
  id?: number;
}
/**
 *
 * @export
 * @interface ExemptionDeleteFetchModelChampionship
 */
export interface ExemptionDeleteFetchModelChampionship {
  /**
   *
   * @type {string}
   * @memberof ExemptionDeleteFetchModelChampionship
   */
  title?: string;
}
/**
 *
 * @export
 * @interface ExemptionModel
 */
export interface ExemptionModel {
  /**
   *
   * @type {Array<string>}
   * @memberof ExemptionModel
   */
  players?: Array<string>;
  /**
   *
   * @type {QualifyingLevelEnum}
   * @memberof ExemptionModel
   */
  level: QualifyingLevelEnum;
  /**
   *
   * @type {number}
   * @memberof ExemptionModel
   */
  number: number;
  /**
   *
   * @type {string}
   * @memberof ExemptionModel
   */
  title: string;
  /**
   *
   * @type {SpecificExemptionsEnum}
   * @memberof ExemptionModel
   */
  specific?: SpecificExemptionsEnum;
  /**
   *
   * @type {boolean}
   * @memberof ExemptionModel
   */
  requireQualifyingSelection: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ExemptionModel
   */
  hidePlayers?: boolean;
  /**
   *
   * @type {IdModel}
   * @memberof ExemptionModel
   */
  championship?: IdModel;
  /**
   *
   * @type {Array<MessageAdminModelAllOfExemptionProfiles>}
   * @memberof ExemptionModel
   */
  profiles?: Array<MessageAdminModelAllOfExemptionProfiles>;
  /**
   *
   * @type {number}
   * @memberof ExemptionModel
   */
  id: number;
}
/**
 *
 * @export
 * @interface ExemptionPlayerListModel
 */
export interface ExemptionPlayerListModel {
  /**
   *
   * @type {Array<ExemptionModel>}
   * @memberof ExemptionPlayerListModel
   */
  items: Array<ExemptionModel>;
  /**
   *
   * @type {number}
   * @memberof ExemptionPlayerListModel
   */
  limit: number;
  /**
   *
   * @type {number}
   * @memberof ExemptionPlayerListModel
   */
  start: number;
  /**
   *
   * @type {number}
   * @memberof ExemptionPlayerListModel
   */
  total: number;
}
/**
 *
 * @export
 * @interface ExemptionReportProfileFetchListModel
 */
export interface ExemptionReportProfileFetchListModel {
  /**
   *
   * @type {Array<ExemptionReportProfileFetchModel>}
   * @memberof ExemptionReportProfileFetchListModel
   */
  items?: Array<ExemptionReportProfileFetchModel>;
  /**
   *
   * @type {number}
   * @memberof ExemptionReportProfileFetchListModel
   */
  limit?: number;
  /**
   *
   * @type {number}
   * @memberof ExemptionReportProfileFetchListModel
   */
  start?: number;
  /**
   *
   * @type {number}
   * @memberof ExemptionReportProfileFetchListModel
   */
  total?: number;
}
/**
 *
 * @export
 * @interface ExemptionReportProfileFetchModel
 */
export interface ExemptionReportProfileFetchModel {
  /**
   *
   * @type {number}
   * @memberof ExemptionReportProfileFetchModel
   */
  id?: number;
  /**
   *
   * @type {ApplicationFetchModelPrimaryContactInfo}
   * @memberof ExemptionReportProfileFetchModel
   */
  primaryContactInfo?: ApplicationFetchModelPrimaryContactInfo;
  /**
   *
   * @type {ExemptionReportProfileFetchModelGuardianContactInfo}
   * @memberof ExemptionReportProfileFetchModel
   */
  guardianContactInfo?: ExemptionReportProfileFetchModelGuardianContactInfo;
  /**
   *
   * @type {string}
   * @memberof ExemptionReportProfileFetchModel
   */
  dob?: string;
  /**
   *
   * @type {PlayerStatusEnum}
   * @memberof ExemptionReportProfileFetchModel
   */
  playerStatus?: PlayerStatusEnum;
  /**
   *
   * @type {Array<ExemptionReportProfileFetchModelExemptions>}
   * @memberof ExemptionReportProfileFetchModel
   */
  exemptions?: Array<ExemptionReportProfileFetchModelExemptions>;
}
/**
 *
 * @export
 * @interface ExemptionReportProfileFetchModelExemptions
 */
export interface ExemptionReportProfileFetchModelExemptions {
  /**
   *
   * @type {number}
   * @memberof ExemptionReportProfileFetchModelExemptions
   */
  id?: number;
  /**
   *
   * @type {AppNotablePlayersReportFetchModelExemption}
   * @memberof ExemptionReportProfileFetchModelExemptions
   */
  exemption?: AppNotablePlayersReportFetchModelExemption;
}
/**
 *
 * @export
 * @interface ExemptionReportProfileFetchModelGuardianContactInfo
 */
export interface ExemptionReportProfileFetchModelGuardianContactInfo {
  /**
   * parent email
   * @type {string}
   * @memberof ExemptionReportProfileFetchModelGuardianContactInfo
   */
  email?: string;
}
/**
 *
 * @export
 * @interface ExeptChosenFetchModel
 */
export interface ExeptChosenFetchModel {
  /**
   *
   * @type {number}
   * @memberof ExeptChosenFetchModel
   */
  id?: number;
  /**
   *
   * @type {IdModel}
   * @memberof ExeptChosenFetchModel
   */
  championship?: IdModel;
  /**
   *
   * @type {Array<ExeptChosenFetchModelProfiles>}
   * @memberof ExeptChosenFetchModel
   */
  profiles?: Array<ExeptChosenFetchModelProfiles>;
  /**
   *
   * @type {QualifyingLevelEnum}
   * @memberof ExeptChosenFetchModel
   */
  level?: QualifyingLevelEnum;
  /**
   *
   * @type {number}
   * @memberof ExeptChosenFetchModel
   */
  number?: number;
}
/**
 *
 * @export
 * @interface ExeptChosenFetchModelProfiles
 */
export interface ExeptChosenFetchModelProfiles {
  /**
   *
   * @type {IdModel}
   * @memberof ExeptChosenFetchModelProfiles
   */
  player: IdModel;
}
/**
 *
 * @export
 * @interface FeaturedCardModel
 */
export interface FeaturedCardModel {
  /**
   *
   * @type {boolean}
   * @memberof FeaturedCardModel
   */
  video?: boolean;
  /**
   *
   * @type {USGACloudinaryImageModel}
   * @memberof FeaturedCardModel
   */
  image?: USGACloudinaryImageModel;
  /**
   *
   * @type {LinkModel}
   * @memberof FeaturedCardModel
   */
  link?: LinkModel;
  /**
   *
   * @type {string}
   * @memberof FeaturedCardModel
   */
  type?: FeaturedCardModelTypeEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum FeaturedCardModelTypeEnum {
  Featured = 'featured',
}

/**
 *
 * @export
 * @interface FeaturedCardsModel
 */
export interface FeaturedCardsModel {
  /**
   *
   * @type {string}
   * @memberof FeaturedCardsModel
   */
  text?: string;
  /**
   *
   * @type {FeaturedCardModel}
   * @memberof FeaturedCardsModel
   */
  firstCard?: FeaturedCardModel;
  /**
   *
   * @type {FeaturedCardModel}
   * @memberof FeaturedCardsModel
   */
  secondCard?: FeaturedCardModel;
  /**
   *
   * @type {string}
   * @memberof FeaturedCardsModel
   */
  heading?: string;
}
/**
 *
 * @export
 * @interface FinalConfirmationEmailRequestModel
 */
export interface FinalConfirmationEmailRequestModel {
  /**
   *
   * @type {Array<FinalConfirmationEmailRequestModelItems>}
   * @memberof FinalConfirmationEmailRequestModel
   */
  items: Array<FinalConfirmationEmailRequestModelItems>;
}
/**
 *
 * @export
 * @interface FinalConfirmationEmailRequestModelItems
 */
export interface FinalConfirmationEmailRequestModelItems {
  /**
   *
   * @type {number}
   * @memberof FinalConfirmationEmailRequestModelItems
   */
  appId: number;
  /**
   *
   * @type {ApplicationFinalConfirmationBulkFilterEnum}
   * @memberof FinalConfirmationEmailRequestModelItems
   */
  appFilter: ApplicationFinalConfirmationBulkFilterEnum;
}
/**
 *
 * @export
 * @interface FooterModel
 */
export interface FooterModel {
  /**
   *
   * @type {Array<PartnerModel>}
   * @memberof FooterModel
   */
  partners?: Array<PartnerModel>;
  /**
   *
   * @type {Array<SocialNetworkModel>}
   * @memberof FooterModel
   */
  socialNetworks?: Array<SocialNetworkModel>;
  /**
   *
   * @type {Array<MobileAppModel>}
   * @memberof FooterModel
   */
  mobileApps?: Array<MobileAppModel>;
  /**
   *
   * @type {Array<LinkModel>}
   * @memberof FooterModel
   */
  links?: Array<LinkModel>;
  /**
   *
   * @type {string}
   * @memberof FooterModel
   */
  copyright?: string;
}
/**
 * Represents Four Ball Player Appliance Component
 * @export
 * @interface FourBallAppliancePlayerModel
 */
export interface FourBallAppliancePlayerModel {
  /**
   *
   * @type {ApplicationHomeStepPlayerApplianceModel}
   * @memberof FourBallAppliancePlayerModel
   */
  applicationHome?: ApplicationHomeStepPlayerApplianceModel;
  /**
   *
   * @type {AmateurStatusStepPlayerApplianceModel}
   * @memberof FourBallAppliancePlayerModel
   */
  amateurStatus?: AmateurStatusStepPlayerApplianceModel;
  /**
   *
   * @type {QualifyingExemptionStepPlayerApplianceModel}
   * @memberof FourBallAppliancePlayerModel
   */
  qualifyingExemption?: QualifyingExemptionStepPlayerApplianceModel;
  /**
   *
   * @type {QualifyingLocationStepPlayerApplianceModel}
   * @memberof FourBallAppliancePlayerModel
   */
  qualifyingLocation?: QualifyingLocationStepPlayerApplianceModel;
  /**
   *
   * @type {EligibilityStepPlayerApplianceModel}
   * @memberof FourBallAppliancePlayerModel
   */
  eligibility?: EligibilityStepPlayerApplianceModel;
  /**
   *
   * @type {ReviewStepPlayerApplianceModel}
   * @memberof FourBallAppliancePlayerModel
   */
  review?: ReviewStepPlayerApplianceModel;
  /**
   *
   * @type {IdModel}
   * @memberof FourBallAppliancePlayerModel
   */
  championship: IdModel;
  /**
   *
   * @type {number}
   * @memberof FourBallAppliancePlayerModel
   */
  authorizationPaymentAmount: number;
}
/**
 *
 * @export
 * @interface FourBallAppliancePlayerModelAllOf
 */
export interface FourBallAppliancePlayerModelAllOf {
  /**
   *
   * @type {number}
   * @memberof FourBallAppliancePlayerModelAllOf
   */
  authorizationPaymentAmount: number;
}
/**
 *
 * @export
 * @interface FourBallRefundPaymentAdminModel
 */
export interface FourBallRefundPaymentAdminModel {
  /**
   *
   * @type {Array<RefundPaymentBaseModel>}
   * @memberof FourBallRefundPaymentAdminModel
   */
  withdrawApplications: Array<RefundPaymentBaseModel>;
  /**
   *
   * @type {RefundPaymentBaseModel}
   * @memberof FourBallRefundPaymentAdminModel
   */
  activeApplication?: RefundPaymentBaseModel;
  /**
   *
   * @type {WithdrawalReasonEnum}
   * @memberof FourBallRefundPaymentAdminModel
   */
  withdrawalReason: WithdrawalReasonEnum;
  /**
   *
   * @type {RequestReceivedByEnum}
   * @memberof FourBallRefundPaymentAdminModel
   */
  requestReceivedBy: RequestReceivedByEnum;
  /**
   *
   * @type {string}
   * @memberof FourBallRefundPaymentAdminModel
   */
  comment: string;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum GenderEnum {
  Male = 'Male',
  Female = 'Female',
}

/**
 *
 * @export
 * @interface GlobalNavigationItemModel
 */
export interface GlobalNavigationItemModel {
  /**
   *
   * @type {NavigationItemTypeEnum}
   * @memberof GlobalNavigationItemModel
   */
  type: NavigationItemTypeEnum;
  /**
   *
   * @type {GlobalNavigationTabModel}
   * @memberof GlobalNavigationItemModel
   */
  tab: GlobalNavigationTabModel;
}
/**
 *
 * @export
 * @interface GlobalNavigationModel
 */
export interface GlobalNavigationModel {
  /**
   *
   * @type {Array<GlobalNavigationItemModel>}
   * @memberof GlobalNavigationModel
   */
  items: Array<GlobalNavigationItemModel>;
}
/**
 *
 * @export
 * @interface GlobalNavigationTabModel
 */
export interface GlobalNavigationTabModel {
  /**
   *
   * @type {string}
   * @memberof GlobalNavigationTabModel
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof GlobalNavigationTabModel
   */
  text?: string;
  /**
   *
   * @type {string}
   * @memberof GlobalNavigationTabModel
   */
  url?: string;
  /**
   *
   * @type {USGATargetEnum}
   * @memberof GlobalNavigationTabModel
   */
  target?: USGATargetEnum;
  /**
   *
   * @type {ColumnModel}
   * @memberof GlobalNavigationTabModel
   */
  column?: ColumnModel;
  /**
   *
   * @type {FeaturedCardsModel}
   * @memberof GlobalNavigationTabModel
   */
  featuredCards?: FeaturedCardsModel;
  /**
   *
   * @type {PromoModel}
   * @memberof GlobalNavigationTabModel
   */
  firstPromo?: PromoModel;
  /**
   *
   * @type {PromoModel}
   * @memberof GlobalNavigationTabModel
   */
  secondPromo?: PromoModel;
  /**
   *
   * @type {ColumnModel}
   * @memberof GlobalNavigationTabModel
   */
  firstColumn?: ColumnModel;
  /**
   *
   * @type {ColumnModel}
   * @memberof GlobalNavigationTabModel
   */
  secondColumn?: ColumnModel;
  /**
   *
   * @type {ColumnModel}
   * @memberof GlobalNavigationTabModel
   */
  thirdColumn?: ColumnModel;
  /**
   *
   * @type {ColumnModel}
   * @memberof GlobalNavigationTabModel
   */
  fourthColumn?: ColumnModel;
  /**
   *
   * @type {ColumnModel}
   * @memberof GlobalNavigationTabModel
   */
  fifthColumn?: ColumnModel;
}
/**
 *
 * @export
 * @interface HandicapPlayerModel
 */
export interface HandicapPlayerModel {
  /**
   *
   * @type {string}
   * @memberof HandicapPlayerModel
   */
  ghin: string;
  /**
   *
   * @type {string}
   * @memberof HandicapPlayerModel
   */
  status?: string;
  /**
   *
   * @type {string}
   * @memberof HandicapPlayerModel
   */
  handicap_index: string;
  /**
   *
   * @type {string}
   * @memberof HandicapPlayerModel
   */
  first_name?: string;
  /**
   *
   * @type {string}
   * @memberof HandicapPlayerModel
   */
  last_name?: string;
  /**
   *
   * @type {string}
   * @memberof HandicapPlayerModel
   */
  club_name: string;
  /**
   *
   * @type {string}
   * @memberof HandicapPlayerModel
   */
  country?: string;
  /**
   *
   * @type {string}
   * @memberof HandicapPlayerModel
   */
  state?: string;
  /**
   *
   * @type {number}
   * @memberof HandicapPlayerModel
   */
  association_id?: number;
  /**
   *
   * @type {string}
   * @memberof HandicapPlayerModel
   */
  association_name?: string;
  /**
   *
   * @type {number}
   * @memberof HandicapPlayerModel
   */
  club_id?: number;
  /**
   *
   * @type {boolean}
   * @memberof HandicapPlayerModel
   */
  is_test?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof HandicapPlayerModel
   */
  is_home_club?: boolean;
}
/**
 *
 * @export
 * @interface HandicapServiceResponseModel
 */
export interface HandicapServiceResponseModel {
  /**
   *
   * @type {number}
   * @memberof HandicapServiceResponseModel
   */
  statusCode?: number;
  /**
   *
   * @type {boolean}
   * @memberof HandicapServiceResponseModel
   */
  success?: boolean;
  /**
   *
   * @type {Array<HandicapPlayerModel>}
   * @memberof HandicapServiceResponseModel
   */
  golfers: Array<HandicapPlayerModel>;
}
/**
 * Represents Head Article Component
 * @export
 * @interface HeadPlayerArticleModel
 */
export interface HeadPlayerArticleModel {
  /**
   *
   * @type {string}
   * @memberof HeadPlayerArticleModel
   */
  text?: string;
}
/**
 *
 * @export
 * @interface IdListModel
 */
export interface IdListModel {
  /**
   *
   * @type {Array<number>}
   * @memberof IdListModel
   */
  idList: Array<number>;
}
/**
 *
 * @export
 * @interface IdModel
 */
export interface IdModel {
  /**
   *
   * @type {number}
   * @memberof IdModel
   */
  id: number;
}
/**
 *
 * @export
 * @interface IdTitleModel
 */
export interface IdTitleModel {
  /**
   *
   * @type {number}
   * @memberof IdTitleModel
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof IdTitleModel
   */
  title?: string;
}
/**
 *
 * @export
 * @interface IdTitleModelAllOf
 */
export interface IdTitleModelAllOf {
  /**
   *
   * @type {string}
   * @memberof IdTitleModelAllOf
   */
  title?: string;
}
/**
 *
 * @export
 * @interface ImageModel
 */
export interface ImageModel {
  /**
   *
   * @type {string}
   * @memberof ImageModel
   */
  text?: string;
  /**
   *
   * @type {USGATargetEnum}
   * @memberof ImageModel
   */
  target?: USGATargetEnum;
  /**
   *
   * @type {USGACloudinaryImageModel}
   * @memberof ImageModel
   */
  image?: USGACloudinaryImageModel;
  /**
   *
   * @type {string}
   * @memberof ImageModel
   */
  url?: string;
}
/**
 * Represents cookie value
 * @export
 * @interface ImpersonationCookieModel
 */
export interface ImpersonationCookieModel {
  /**
   *
   * @type {string}
   * @memberof ImpersonationCookieModel
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof ImpersonationCookieModel
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof ImpersonationCookieModel
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof ImpersonationCookieModel
   */
  auth0Id: string;
  /**
   *
   * @type {string}
   * @memberof ImpersonationCookieModel
   */
  managerId: string;
}
/**
 *
 * @export
 * @interface IncompleteSideListModel
 */
export interface IncompleteSideListModel {
  /**
   *
   * @type {Array<IncompleteSideModel>}
   * @memberof IncompleteSideListModel
   */
  items: Array<IncompleteSideModel>;
  /**
   *
   * @type {number}
   * @memberof IncompleteSideListModel
   */
  limit: number;
  /**
   *
   * @type {number}
   * @memberof IncompleteSideListModel
   */
  start: number;
  /**
   *
   * @type {number}
   * @memberof IncompleteSideListModel
   */
  total: number;
}
/**
 *
 * @export
 * @interface IncompleteSideModel
 */
export interface IncompleteSideModel {
  /**
   *
   * @type {number}
   * @memberof IncompleteSideModel
   */
  teamId: number;
  /**
   *
   * @type {number}
   * @memberof IncompleteSideModel
   */
  appId: number;
  /**
   *
   * @type {string}
   * @memberof IncompleteSideModel
   */
  email: string;
  /**
   *
   * @type {SideApplicantModel}
   * @memberof IncompleteSideModel
   */
  applicant: SideApplicantModel;
  /**
   *
   * @type {ApplicationProgressionEnum}
   * @memberof IncompleteSideModel
   */
  appStatus: ApplicationProgressionEnum;
  /**
   *
   * @type {SideApplicantModel}
   * @memberof IncompleteSideModel
   */
  teammate?: SideApplicantModel;
  /**
   *
   * @type {number}
   * @memberof IncompleteSideModel
   */
  daysIncompleteStatus?: number;
  /**
   *
   * @type {number}
   * @memberof IncompleteSideModel
   */
  daysSinceLastWithdraw?: number;
}
/**
 *
 * @export
 * @interface IncompleteSidesEmailRequestModel
 */
export interface IncompleteSidesEmailRequestModel {
  /**
   *
   * @type {Array<IncompleteSideModel>}
   * @memberof IncompleteSidesEmailRequestModel
   */
  items: Array<IncompleteSideModel>;
}
/**
 *
 * @export
 * @interface InfoPDF
 */
export interface InfoPDF {
  /**
   *
   * @type {boolean}
   * @memberof InfoPDF
   */
  hasInfoPdf?: boolean;
}
/**
 *
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
  /**
   *
   * @type {any}
   * @memberof InlineObject
   */
  file?: any;
}
/**
 *
 * @export
 * @interface InlineObject1
 */
export interface InlineObject1 {
  /**
   *
   * @type {any}
   * @memberof InlineObject1
   */
  file?: any;
}
/**
 *
 * @export
 * @interface InlineObject2
 */
export interface InlineObject2 {
  /**
   *
   * @type {any}
   * @memberof InlineObject2
   */
  file?: any;
}
/**
 *
 * @export
 * @interface InlineObject3
 */
export interface InlineObject3 {
  /**
   *
   * @type {any}
   * @memberof InlineObject3
   */
  file?: any;
}
/**
 *
 * @export
 * @interface InlineObject4
 */
export interface InlineObject4 {
  /**
   *
   * @type {any}
   * @memberof InlineObject4
   */
  file?: any;
}
/**
 *
 * @export
 * @interface InlineObject5
 */
export interface InlineObject5 {
  /**
   *
   * @type {any}
   * @memberof InlineObject5
   */
  file?: any;
}
/**
 *
 * @export
 * @interface InlineObject6
 */
export interface InlineObject6 {
  /**
   *
   * @type {any}
   * @memberof InlineObject6
   */
  file?: any;
}
/**
 *
 * @export
 * @interface InlineObject7
 */
export interface InlineObject7 {
  /**
   *
   * @type {any}
   * @memberof InlineObject7
   */
  file?: any;
}
/**
 *
 * @export
 * @interface InlineObject8
 */
export interface InlineObject8 {
  /**
   *
   * @type {any}
   * @memberof InlineObject8
   */
  file?: any;
}
/**
 *
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
  /**
   *
   * @type {ChampionshipPlayerListModel}
   * @memberof InlineResponse200
   */
  a1?: ChampionshipPlayerListModel;
  /**
   *
   * @type {ProfileAuth0ListModel}
   * @memberof InlineResponse200
   */
  a2?: ProfileAuth0ListModel;
  /**
   *
   * @type {CommonChampionshipListModel}
   * @memberof InlineResponse200
   */
  a3?: CommonChampionshipListModel;
  /**
   *
   * @type {HandicapServiceResponseModel}
   * @memberof InlineResponse200
   */
  a4?: HandicapServiceResponseModel;
  /**
   *
   * @type {UploadedFileWithDataAdminModel}
   * @memberof InlineResponse200
   */
  a5?: UploadedFileWithDataAdminModel;
  /**
   *
   * @type {UploadedFileModel}
   * @memberof InlineResponse200
   */
  a6?: UploadedFileModel;
  /**
   *
   * @type {UploadedFileWithDataAdminListModel}
   * @memberof InlineResponse200
   */
  a7?: UploadedFileWithDataAdminListModel;
  /**
   *
   * @type {PaymentTypesEnum}
   * @memberof InlineResponse200
   */
  a8?: PaymentTypesEnum;
  /**
   *
   * @type {ProfilePlayerModel}
   * @memberof InlineResponse200
   */
  a9?: ProfilePlayerModel;
  /**
   *
   * @type {PageResouceTypeEnum}
   * @memberof InlineResponse200
   */
  a10?: PageResouceTypeEnum;
  /**
   *
   * @type {AemWfTypeEnum}
   * @memberof InlineResponse200
   */
  a11?: AemWfTypeEnum;
  /**
   *
   * @type {PendingPaymentModel}
   * @memberof InlineResponse200
   */
  a12?: PendingPaymentModel;
  /**
   *
   * @type {ApplicationPaymentHistoryModel}
   * @memberof InlineResponse200
   */
  a13?: ApplicationPaymentHistoryModel;
  /**
   *
   * @type {StripePaymentDataModel}
   * @memberof InlineResponse200
   */
  a14?: StripePaymentDataModel;
  /**
   *
   * @type {StripePaymentDataEventModel}
   * @memberof InlineResponse200
   */
  a15?: StripePaymentDataEventModel;
  /**
   *
   * @type {StripeRefundDataEventModel}
   * @memberof InlineResponse200
   */
  a16?: StripeRefundDataEventModel;
  /**
   *
   * @type {CheckoutSessionEventModel}
   * @memberof InlineResponse200
   */
  a17?: CheckoutSessionEventModel;
}
/**
 *
 * @export
 * @interface InlineResponse2001
 */
export interface InlineResponse2001 {
  /**
   *
   * @type {EmailTemplateQueryParamBean}
   * @memberof InlineResponse2001
   */
  a1?: EmailTemplateQueryParamBean;
  /**
   *
   * @type {CloudinarySearchParamBean}
   * @memberof InlineResponse2001
   */
  a2?: CloudinarySearchParamBean;
  /**
   *
   * @type {PairHistoryParamBean}
   * @memberof InlineResponse2001
   */
  a3?: PairHistoryParamBean;
  /**
   *
   * @type {ServiceEmailTemplateParamBean}
   * @memberof InlineResponse2001
   */
  a4?: ServiceEmailTemplateParamBean;
  /**
   *
   * @type {LastModifiedParamBean}
   * @memberof InlineResponse2001
   */
  a5?: LastModifiedParamBean;
}
/**
 *
 * @export
 * @interface InlineResponse2002
 */
export interface InlineResponse2002 {
  /**
   *
   * @type {ApplicationFetchModel}
   * @memberof InlineResponse2002
   */
  a1?: ApplicationFetchModel;
  /**
   *
   * @type {ChampionshipFetchModel}
   * @memberof InlineResponse2002
   */
  a2?: ChampionshipFetchModel;
  /**
   *
   * @type {QualifyingSiteFetchModel}
   * @memberof InlineResponse2002
   */
  a3?: QualifyingSiteFetchModel;
  /**
   *
   * @type {AgaFetchModel}
   * @memberof InlineResponse2002
   */
  a4?: AgaFetchModel;
  /**
   *
   * @type {ClubFetchModel}
   * @memberof InlineResponse2002
   */
  a5?: ClubFetchModel;
  /**
   *
   * @type {EmailTemplateFetchModel}
   * @memberof InlineResponse2002
   */
  a6?: EmailTemplateFetchModel;
  /**
   *
   * @type {ApplicationQualifyingLocationResponseFetchModel}
   * @memberof InlineResponse2002
   */
  a7?: ApplicationQualifyingLocationResponseFetchModel;
  /**
   *
   * @type {ExemptionDeleteFetchModel}
   * @memberof InlineResponse2002
   */
  a8?: ExemptionDeleteFetchModel;
  /**
   *
   * @type {ApplicationMergeFetchModel}
   * @memberof InlineResponse2002
   */
  a9?: ApplicationMergeFetchModel;
  /**
   *
   * @type {QualifyingSiteWithAppsFetchModel}
   * @memberof InlineResponse2002
   */
  a10?: QualifyingSiteWithAppsFetchModel;
  /**
   *
   * @type {ProfileReportFetchListModel}
   * @memberof InlineResponse2002
   */
  a11?: ProfileReportFetchListModel;
  /**
   *
   * @type {ApplicationPaymentProcessFetchModel}
   * @memberof InlineResponse2002
   */
  a12?: ApplicationPaymentProcessFetchModel;
  /**
   *
   * @type {ApplicationAppliedResponseFetchModel}
   * @memberof InlineResponse2002
   */
  a13?: ApplicationAppliedResponseFetchModel;
  /**
   *
   * @type {ApplicationWithChampFetchModel}
   * @memberof InlineResponse2002
   */
  a14?: ApplicationWithChampFetchModel;
  /**
   *
   * @type {ChampionshipSyncFetchModel}
   * @memberof InlineResponse2002
   */
  a15?: ChampionshipSyncFetchModel;
  /**
   *
   * @type {ApplicationWithQSFetchModel}
   * @memberof InlineResponse2002
   */
  a16?: ApplicationWithQSFetchModel;
  /**
   *
   * @type {AppBLReportFetchModel}
   * @memberof InlineResponse2002
   */
  a17?: AppBLReportFetchModel;
  /**
   *
   * @type {AppWLReportFetchModel}
   * @memberof InlineResponse2002
   */
  a18?: AppWLReportFetchModel;
  /**
   *
   * @type {ApplicationHandicapFetchModel}
   * @memberof InlineResponse2002
   */
  a19?: ApplicationHandicapFetchModel;
  /**
   *
   * @type {QSReportFetchModel}
   * @memberof InlineResponse2002
   */
  a20?: QSReportFetchModel;
  /**
   *
   * @type {AppADAReportFetchModel}
   * @memberof InlineResponse2002
   */
  a21?: AppADAReportFetchModel;
  /**
   *
   * @type {AppCustomerServiceReportFetchModel}
   * @memberof InlineResponse2002
   */
  a22?: AppCustomerServiceReportFetchModel;
  /**
   *
   * @type {ExemptionReportProfileFetchListModel}
   * @memberof InlineResponse2002
   */
  a23?: ExemptionReportProfileFetchListModel;
  /**
   *
   * @type {AppWDReportFetchModel}
   * @memberof InlineResponse2002
   */
  a24?: AppWDReportFetchModel;
  /**
   *
   * @type {AppDuplicateReportFetchModel}
   * @memberof InlineResponse2002
   */
  a25?: AppDuplicateReportFetchModel;
  /**
   *
   * @type {AppNotablePlayersReportFetchModel}
   * @memberof InlineResponse2002
   */
  a26?: AppNotablePlayersReportFetchModel;
  /**
   *
   * @type {AppExemptChosenReportFetchModel}
   * @memberof InlineResponse2002
   */
  a27?: AppExemptChosenReportFetchModel;
  /**
   *
   * @type {ExeptChosenFetchModel}
   * @memberof InlineResponse2002
   */
  a28?: ExeptChosenFetchModel;
  /**
   *
   * @type {AppRefundReportFetchModel}
   * @memberof InlineResponse2002
   */
  a29?: AppRefundReportFetchModel;
  /**
   *
   * @type {QSDownloadReportFetchModel}
   * @memberof InlineResponse2002
   */
  a30?: QSDownloadReportFetchModel;
  /**
   *
   * @type {AppAntiDopingReportFetchModel}
   * @memberof InlineResponse2002
   */
  a31?: AppAntiDopingReportFetchModel;
  /**
   *
   * @type {AppCloseQSReportFetchModel}
   * @memberof InlineResponse2002
   */
  a32?: AppCloseQSReportFetchModel;
  /**
   *
   * @type {QSCloseReportFetchModel}
   * @memberof InlineResponse2002
   */
  a33?: QSCloseReportFetchModel;
  /**
   *
   * @type {ChampCustomEmailPlFetchModel}
   * @memberof InlineResponse2002
   */
  a34?: ChampCustomEmailPlFetchModel;
  /**
   *
   * @type {AppCloseOfEntriesReportFetchModel}
   * @memberof InlineResponse2002
   */
  a35?: AppCloseOfEntriesReportFetchModel;
  /**
   *
   * @type {AppNotSettledPaymentsReportFetchModel}
   * @memberof InlineResponse2002
   */
  a36?: AppNotSettledPaymentsReportFetchModel;
}
/**
 *
 * @export
 * @interface LastModifiedParamBean
 */
export interface LastModifiedParamBean {
  /**
   *
   * @type {Array<number>}
   * @memberof LastModifiedParamBean
   */
  idList: Array<number>;
  /**
   *
   * @type {EntitiesTypeEnum}
   * @memberof LastModifiedParamBean
   */
  entityName: EntitiesTypeEnum;
}
/**
 *
 * @export
 * @interface LastModifiedParamBeanAllOf
 */
export interface LastModifiedParamBeanAllOf {
  /**
   *
   * @type {EntitiesTypeEnum}
   * @memberof LastModifiedParamBeanAllOf
   */
  entityName: EntitiesTypeEnum;
}
/**
 *
 * @export
 * @interface LastModifiedResponseModel
 */
export interface LastModifiedResponseModel {
  /**
   *
   * @type {number}
   * @memberof LastModifiedResponseModel
   */
  id: number;
  /**
   *
   * @type {AuditModel}
   * @memberof LastModifiedResponseModel
   */
  audit: AuditModel;
}
/**
 * Represents Link Button Component
 * @export
 * @interface LinkButtonModel
 */
export interface LinkButtonModel {
  /**
   *
   * @type {string}
   * @memberof LinkButtonModel
   */
  url?: string;
  /**
   *
   * @type {string}
   * @memberof LinkButtonModel
   */
  text?: string;
  /**
   *
   * @type {LinkTargetEnum}
   * @memberof LinkButtonModel
   */
  target?: LinkTargetEnum;
}
/**
 *
 * @export
 * @interface LinkModel
 */
export interface LinkModel {
  /**
   *
   * @type {string}
   * @memberof LinkModel
   */
  text?: string;
  /**
   *
   * @type {string}
   * @memberof LinkModel
   */
  url?: string;
  /**
   *
   * @type {USGATargetEnum}
   * @memberof LinkModel
   */
  target?: USGATargetEnum;
}
/**
 * Represents Link Profiles Player Confirmation Component
 * @export
 * @interface LinkProfilesConfirmationPlayerModel
 */
export interface LinkProfilesConfirmationPlayerModel {
  /**
   *
   * @type {number}
   * @memberof LinkProfilesConfirmationPlayerModel
   */
  managedPlayerId?: number;
  /**
   *
   * @type {string}
   * @memberof LinkProfilesConfirmationPlayerModel
   */
  pagePath?: string;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum LinkTargetEnum {
  Blank = '_blank',
  Self = '_self',
}

/**
 *
 * @export
 * @interface LinkedProfilePlayerResponseModel
 */
export interface LinkedProfilePlayerResponseModel {
  /**
   *
   * @type {ProfilePersonalDataModelPrimaryContactInfo}
   * @memberof LinkedProfilePlayerResponseModel
   */
  primaryContactInfo?: ProfilePersonalDataModelPrimaryContactInfo;
  /**
   *
   * @type {ApplicationBasePlayerModelGuardianContactInfo}
   * @memberof LinkedProfilePlayerResponseModel
   */
  guardianContactInfo?: ApplicationBasePlayerModelGuardianContactInfo;
  /**
   *
   * @type {GenderEnum}
   * @memberof LinkedProfilePlayerResponseModel
   */
  gender?: GenderEnum;
  /**
   *
   * @type {string}
   * @memberof LinkedProfilePlayerResponseModel
   */
  dob?: string;
  /**
   *
   * @type {CountryAddModel}
   * @memberof LinkedProfilePlayerResponseModel
   */
  countryOfCitizenship?: CountryAddModel;
  /**
   *
   * @type {EthnicityEnum}
   * @memberof LinkedProfilePlayerResponseModel
   */
  ethnicity?: EthnicityEnum;
  /**
   *
   * @type {number}
   * @memberof LinkedProfilePlayerResponseModel
   */
  highSchoolGraduationYear?: number;
  /**
   *
   * @type {CountryAddModel}
   * @memberof LinkedProfilePlayerResponseModel
   */
  preferredCountry?: CountryAddModel;
  /**
   *
   * @type {string}
   * @memberof LinkedProfilePlayerResponseModel
   */
  preferredCity?: string;
  /**
   *
   * @type {StateAddModel}
   * @memberof LinkedProfilePlayerResponseModel
   */
  preferredState?: StateAddModel;
  /**
   *
   * @type {ApplicationPaymentProcessFetchModelHandicap}
   * @memberof LinkedProfilePlayerResponseModel
   */
  handicap?: ApplicationPaymentProcessFetchModelHandicap;
  /**
   *
   * @type {string}
   * @memberof LinkedProfilePlayerResponseModel
   */
  agency?: string;
  /**
   *
   * @type {ApplicationDisabilityTypeEnum}
   * @memberof LinkedProfilePlayerResponseModel
   */
  disabilityType?: ApplicationDisabilityTypeEnum;
  /**
   *
   * @type {string}
   * @memberof LinkedProfilePlayerResponseModel
   */
  wR4GDPass?: string;
  /**
   *
   * @type {string}
   * @memberof LinkedProfilePlayerResponseModel
   */
  disabilityDesc?: string;
  /**
   *
   * @type {boolean}
   * @memberof LinkedProfilePlayerResponseModel
   */
  legalGuardian?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof LinkedProfilePlayerResponseModel
   */
  playGolfInCart?: boolean;
  /**
   *
   * @type {string}
   * @memberof LinkedProfilePlayerResponseModel
   */
  virtusII1Number?: string;
  /**
   *
   * @type {string}
   * @memberof LinkedProfilePlayerResponseModel
   */
  adaptiveEquipDesc?: string;
  /**
   *
   * @type {string}
   * @memberof LinkedProfilePlayerResponseModel
   */
  compPlayingRecord?: string;
  /**
   *
   * @type {PlayerStatusEnum}
   * @memberof LinkedProfilePlayerResponseModel
   */
  playerStatus?: PlayerStatusEnum;
  /**
   *
   * @type {number}
   * @memberof LinkedProfilePlayerResponseModel
   */
  id: number;
  /**
   *
   * @type {ProfilePlayerModelAllOfEmergencyContact}
   * @memberof LinkedProfilePlayerResponseModel
   */
  emergencyContact?: ProfilePlayerModelAllOfEmergencyContact;
  /**
   *
   * @type {AuditModel}
   * @memberof LinkedProfilePlayerResponseModel
   */
  audit: AuditModel;
  /**
   *
   * @type {Array<LinkedProfilePlayerResponseModelAllOfManagerPlayer>}
   * @memberof LinkedProfilePlayerResponseModel
   */
  managerPlayer?: Array<LinkedProfilePlayerResponseModelAllOfManagerPlayer>;
  /**
   *
   * @type {Array<LinkedProfilePlayerResponseModelAllOfManagedPlayer>}
   * @memberof LinkedProfilePlayerResponseModel
   */
  managedPlayer?: Array<LinkedProfilePlayerResponseModelAllOfManagedPlayer>;
}
/**
 *
 * @export
 * @interface LinkedProfilePlayerResponseModelAllOf
 */
export interface LinkedProfilePlayerResponseModelAllOf {
  /**
   *
   * @type {AuditModel}
   * @memberof LinkedProfilePlayerResponseModelAllOf
   */
  audit: AuditModel;
  /**
   *
   * @type {Array<LinkedProfilePlayerResponseModelAllOfManagerPlayer>}
   * @memberof LinkedProfilePlayerResponseModelAllOf
   */
  managerPlayer?: Array<LinkedProfilePlayerResponseModelAllOfManagerPlayer>;
  /**
   *
   * @type {Array<LinkedProfilePlayerResponseModelAllOfManagedPlayer>}
   * @memberof LinkedProfilePlayerResponseModelAllOf
   */
  managedPlayer?: Array<LinkedProfilePlayerResponseModelAllOfManagedPlayer>;
}
/**
 *
 * @export
 * @interface LinkedProfilePlayerResponseModelAllOfManagedPlayer
 */
export interface LinkedProfilePlayerResponseModelAllOfManagedPlayer {
  /**
   *
   * @type {ProfilePlayerPersonalDataWithIdResponseModel}
   * @memberof LinkedProfilePlayerResponseModelAllOfManagedPlayer
   */
  managedPlayer?: ProfilePlayerPersonalDataWithIdResponseModel;
}
/**
 *
 * @export
 * @interface LinkedProfilePlayerResponseModelAllOfManagerPlayer
 */
export interface LinkedProfilePlayerResponseModelAllOfManagerPlayer {
  /**
   *
   * @type {ProfilePlayerPersonalDataWithIdResponseModel}
   * @memberof LinkedProfilePlayerResponseModelAllOfManagerPlayer
   */
  managerPlayer?: ProfilePlayerPersonalDataWithIdResponseModel;
}
/**
 * Represents Links Block Item
 * @export
 * @interface LinksBlockItemPlayerHomeModel
 */
export interface LinksBlockItemPlayerHomeModel {
  /**
   *
   * @type {string}
   * @memberof LinksBlockItemPlayerHomeModel
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof LinksBlockItemPlayerHomeModel
   */
  url?: string;
  /**
   *
   * @type {string}
   * @memberof LinksBlockItemPlayerHomeModel
   */
  text?: string;
  /**
   *
   * @type {LinkTargetEnum}
   * @memberof LinksBlockItemPlayerHomeModel
   */
  target?: LinkTargetEnum;
}
/**
 * Represents Links Block Component
 * @export
 * @interface LinksBlockPlayerHomeModel
 */
export interface LinksBlockPlayerHomeModel {
  /**
   *
   * @type {Array<LinksBlockItemPlayerHomeModel>}
   * @memberof LinksBlockPlayerHomeModel
   */
  links?: Array<LinksBlockItemPlayerHomeModel>;
  /**
   *
   * @type {string}
   * @memberof LinksBlockPlayerHomeModel
   */
  title?: string;
}
/**
 *
 * @export
 * @interface MessageAddModel
 */
export interface MessageAddModel {
  /**
   *
   * @type {Array<number>}
   * @memberof MessageAddModel
   */
  years?: Array<number>;
  /**
   *
   * @type {string}
   * @memberof MessageAddModel
   */
  startDate?: string;
  /**
   *
   * @type {string}
   * @memberof MessageAddModel
   */
  expiryDate?: string;
  /**
   *
   * @type {boolean}
   * @memberof MessageAddModel
   */
  allPlayers?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof MessageAddModel
   */
  dateRangeEnabled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof MessageAddModel
   */
  dismissible?: boolean;
  /**
   *
   * @type {string}
   * @memberof MessageAddModel
   */
  message: string;
  /**
   *
   * @type {Array<MessageBaseModelChampionships>}
   * @memberof MessageAddModel
   */
  championships?: Array<MessageBaseModelChampionships>;
  /**
   *
   * @type {Array<ApplicationAnonResponseModelQualifyingSites>}
   * @memberof MessageAddModel
   */
  qualifyingSites?: Array<ApplicationAnonResponseModelQualifyingSites>;
  /**
   *
   * @type {IdModel}
   * @memberof MessageAddModel
   */
  championshipType?: IdModel;
  /**
   *
   * @type {BlacklistStatusEnum}
   * @memberof MessageAddModel
   */
  blacklistStatus?: BlacklistStatusEnum;
  /**
   *
   * @type {QualifyingLevelEnum}
   * @memberof MessageAddModel
   */
  exemptionLevel?: QualifyingLevelEnum;
  /**
   *
   * @type {MessageTypeEnum}
   * @memberof MessageAddModel
   */
  messageType: MessageTypeEnum;
  /**
   *
   * @type {IdModel}
   * @memberof MessageAddModel
   */
  exemption?: IdModel;
  /**
   *
   * @type {Array<MessageAddModelAllOfPlayers>}
   * @memberof MessageAddModel
   */
  players?: Array<MessageAddModelAllOfPlayers>;
}
/**
 *
 * @export
 * @interface MessageAddModelAllOf
 */
export interface MessageAddModelAllOf {
  /**
   *
   * @type {IdModel}
   * @memberof MessageAddModelAllOf
   */
  exemption?: IdModel;
  /**
   *
   * @type {Array<MessageAddModelAllOfPlayers>}
   * @memberof MessageAddModelAllOf
   */
  players?: Array<MessageAddModelAllOfPlayers>;
}
/**
 *
 * @export
 * @interface MessageAddModelAllOfPlayers
 */
export interface MessageAddModelAllOfPlayers {
  /**
   *
   * @type {IdModel}
   * @memberof MessageAddModelAllOfPlayers
   */
  player?: IdModel;
}
/**
 *
 * @export
 * @interface MessageAdminModel
 */
export interface MessageAdminModel {
  /**
   *
   * @type {Array<number>}
   * @memberof MessageAdminModel
   */
  years?: Array<number>;
  /**
   *
   * @type {string}
   * @memberof MessageAdminModel
   */
  startDate?: string;
  /**
   *
   * @type {string}
   * @memberof MessageAdminModel
   */
  expiryDate?: string;
  /**
   *
   * @type {boolean}
   * @memberof MessageAdminModel
   */
  allPlayers?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof MessageAdminModel
   */
  dateRangeEnabled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof MessageAdminModel
   */
  dismissible?: boolean;
  /**
   *
   * @type {string}
   * @memberof MessageAdminModel
   */
  message: string;
  /**
   *
   * @type {Array<MessageBaseModelChampionships>}
   * @memberof MessageAdminModel
   */
  championships?: Array<MessageBaseModelChampionships>;
  /**
   *
   * @type {Array<ApplicationAnonResponseModelQualifyingSites>}
   * @memberof MessageAdminModel
   */
  qualifyingSites?: Array<ApplicationAnonResponseModelQualifyingSites>;
  /**
   *
   * @type {IdModel}
   * @memberof MessageAdminModel
   */
  championshipType?: IdModel;
  /**
   *
   * @type {BlacklistStatusEnum}
   * @memberof MessageAdminModel
   */
  blacklistStatus?: BlacklistStatusEnum;
  /**
   *
   * @type {QualifyingLevelEnum}
   * @memberof MessageAdminModel
   */
  exemptionLevel?: QualifyingLevelEnum;
  /**
   *
   * @type {MessageTypeEnum}
   * @memberof MessageAdminModel
   */
  messageType: MessageTypeEnum;
  /**
   *
   * @type {number}
   * @memberof MessageAdminModel
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof MessageAdminModel
   */
  dateCreated?: string;
  /**
   *
   * @type {string}
   * @memberof MessageAdminModel
   */
  dateModified?: string;
  /**
   *
   * @type {Array<MessageAdminModelAllOfPlayers>}
   * @memberof MessageAdminModel
   */
  players?: Array<MessageAdminModelAllOfPlayers>;
  /**
   *
   * @type {MessageAdminModelAllOfExemption}
   * @memberof MessageAdminModel
   */
  exemption?: MessageAdminModelAllOfExemption;
}
/**
 *
 * @export
 * @interface MessageAdminModelAllOf
 */
export interface MessageAdminModelAllOf {
  /**
   *
   * @type {number}
   * @memberof MessageAdminModelAllOf
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof MessageAdminModelAllOf
   */
  dateCreated?: string;
  /**
   *
   * @type {string}
   * @memberof MessageAdminModelAllOf
   */
  dateModified?: string;
  /**
   *
   * @type {Array<MessageAdminModelAllOfPlayers>}
   * @memberof MessageAdminModelAllOf
   */
  players?: Array<MessageAdminModelAllOfPlayers>;
  /**
   *
   * @type {MessageAdminModelAllOfExemption}
   * @memberof MessageAdminModelAllOf
   */
  exemption?: MessageAdminModelAllOfExemption;
}
/**
 *
 * @export
 * @interface MessageAdminModelAllOfExemption
 */
export interface MessageAdminModelAllOfExemption {
  /**
   *
   * @type {number}
   * @memberof MessageAdminModelAllOfExemption
   */
  id: number;
  /**
   *
   * @type {Array<MessageAdminModelAllOfExemptionProfiles>}
   * @memberof MessageAdminModelAllOfExemption
   */
  profiles?: Array<MessageAdminModelAllOfExemptionProfiles>;
  /**
   *
   * @type {QualifyingLevelEnum}
   * @memberof MessageAdminModelAllOfExemption
   */
  level: QualifyingLevelEnum;
  /**
   *
   * @type {number}
   * @memberof MessageAdminModelAllOfExemption
   */
  number: number;
  /**
   *
   * @type {string}
   * @memberof MessageAdminModelAllOfExemption
   */
  title: string;
  /**
   *
   * @type {SpecificExemptionsEnum}
   * @memberof MessageAdminModelAllOfExemption
   */
  specific?: SpecificExemptionsEnum;
  /**
   *
   * @type {boolean}
   * @memberof MessageAdminModelAllOfExemption
   */
  requireQualifyingSelection: boolean;
  /**
   *
   * @type {Array<MessageAdminModelAllOfExemptionChampionships>}
   * @memberof MessageAdminModelAllOfExemption
   */
  championships?: Array<MessageAdminModelAllOfExemptionChampionships>;
  /**
   *
   * @type {Array<MessageAdminModelAllOfExemptionQualifyingSites>}
   * @memberof MessageAdminModelAllOfExemption
   */
  qualifyingSites?: Array<MessageAdminModelAllOfExemptionQualifyingSites>;
}
/**
 *
 * @export
 * @interface MessageAdminModelAllOfExemptionChampionship
 */
export interface MessageAdminModelAllOfExemptionChampionship {
  /**
   *
   * @type {number}
   * @memberof MessageAdminModelAllOfExemptionChampionship
   */
  id: number;
}
/**
 *
 * @export
 * @interface MessageAdminModelAllOfExemptionChampionships
 */
export interface MessageAdminModelAllOfExemptionChampionships {
  /**
   *
   * @type {MessageAdminModelAllOfExemptionChampionship}
   * @memberof MessageAdminModelAllOfExemptionChampionships
   */
  championship?: MessageAdminModelAllOfExemptionChampionship;
}
/**
 *
 * @export
 * @interface MessageAdminModelAllOfExemptionPlayer
 */
export interface MessageAdminModelAllOfExemptionPlayer {
  /**
   *
   * @type {number}
   * @memberof MessageAdminModelAllOfExemptionPlayer
   */
  id: number;
  /**
   *
   * @type {QualifyingSiteFetchModelApplicationPrimaryContactInfo}
   * @memberof MessageAdminModelAllOfExemptionPlayer
   */
  primaryContactInfo?: QualifyingSiteFetchModelApplicationPrimaryContactInfo;
}
/**
 *
 * @export
 * @interface MessageAdminModelAllOfExemptionProfiles
 */
export interface MessageAdminModelAllOfExemptionProfiles {
  /**
   *
   * @type {number}
   * @memberof MessageAdminModelAllOfExemptionProfiles
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof MessageAdminModelAllOfExemptionProfiles
   */
  position?: number;
  /**
   *
   * @type {number}
   * @memberof MessageAdminModelAllOfExemptionProfiles
   */
  year?: number;
  /**
   *
   * @type {MessageAdminModelAllOfExemptionPlayer}
   * @memberof MessageAdminModelAllOfExemptionProfiles
   */
  player: MessageAdminModelAllOfExemptionPlayer;
}
/**
 *
 * @export
 * @interface MessageAdminModelAllOfExemptionQualifyingSites
 */
export interface MessageAdminModelAllOfExemptionQualifyingSites {
  /**
   *
   * @type {MessageAdminModelAllOfExemptionChampionship}
   * @memberof MessageAdminModelAllOfExemptionQualifyingSites
   */
  qualifyingSite?: MessageAdminModelAllOfExemptionChampionship;
}
/**
 *
 * @export
 * @interface MessageAdminModelAllOfPlayer
 */
export interface MessageAdminModelAllOfPlayer {
  /**
   *
   * @type {number}
   * @memberof MessageAdminModelAllOfPlayer
   */
  id: number;
  /**
   *
   * @type {QualifyingSiteFetchModelApplicationPrimaryContactInfo}
   * @memberof MessageAdminModelAllOfPlayer
   */
  primaryContactInfo?: QualifyingSiteFetchModelApplicationPrimaryContactInfo;
  /**
   *
   * @type {string}
   * @memberof MessageAdminModelAllOfPlayer
   */
  dob?: string;
}
/**
 *
 * @export
 * @interface MessageAdminModelAllOfPlayers
 */
export interface MessageAdminModelAllOfPlayers {
  /**
   *
   * @type {MessageAdminModelAllOfPlayer}
   * @memberof MessageAdminModelAllOfPlayers
   */
  player?: MessageAdminModelAllOfPlayer;
}
/**
 *
 * @export
 * @interface MessageBaseModel
 */
export interface MessageBaseModel {
  /**
   *
   * @type {Array<number>}
   * @memberof MessageBaseModel
   */
  years?: Array<number>;
  /**
   *
   * @type {string}
   * @memberof MessageBaseModel
   */
  startDate?: string;
  /**
   *
   * @type {string}
   * @memberof MessageBaseModel
   */
  expiryDate?: string;
  /**
   *
   * @type {boolean}
   * @memberof MessageBaseModel
   */
  allPlayers?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof MessageBaseModel
   */
  dateRangeEnabled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof MessageBaseModel
   */
  dismissible?: boolean;
  /**
   *
   * @type {string}
   * @memberof MessageBaseModel
   */
  message: string;
  /**
   *
   * @type {Array<MessageBaseModelChampionships>}
   * @memberof MessageBaseModel
   */
  championships?: Array<MessageBaseModelChampionships>;
  /**
   *
   * @type {Array<ApplicationAnonResponseModelQualifyingSites>}
   * @memberof MessageBaseModel
   */
  qualifyingSites?: Array<ApplicationAnonResponseModelQualifyingSites>;
  /**
   *
   * @type {IdModel}
   * @memberof MessageBaseModel
   */
  championshipType?: IdModel;
  /**
   *
   * @type {BlacklistStatusEnum}
   * @memberof MessageBaseModel
   */
  blacklistStatus?: BlacklistStatusEnum;
  /**
   *
   * @type {QualifyingLevelEnum}
   * @memberof MessageBaseModel
   */
  exemptionLevel?: QualifyingLevelEnum;
  /**
   *
   * @type {MessageTypeEnum}
   * @memberof MessageBaseModel
   */
  messageType: MessageTypeEnum;
}
/**
 *
 * @export
 * @interface MessageBaseModelChampionships
 */
export interface MessageBaseModelChampionships {
  /**
   *
   * @type {IdModel}
   * @memberof MessageBaseModelChampionships
   */
  championship?: IdModel;
}
/**
 *
 * @export
 * @interface MessageEditModel
 */
export interface MessageEditModel {
  /**
   *
   * @type {Array<number>}
   * @memberof MessageEditModel
   */
  years?: Array<number>;
  /**
   *
   * @type {string}
   * @memberof MessageEditModel
   */
  startDate?: string;
  /**
   *
   * @type {string}
   * @memberof MessageEditModel
   */
  expiryDate?: string;
  /**
   *
   * @type {boolean}
   * @memberof MessageEditModel
   */
  allPlayers?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof MessageEditModel
   */
  dateRangeEnabled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof MessageEditModel
   */
  dismissible?: boolean;
  /**
   *
   * @type {string}
   * @memberof MessageEditModel
   */
  message: string;
  /**
   *
   * @type {Array<MessageBaseModelChampionships>}
   * @memberof MessageEditModel
   */
  championships?: Array<MessageBaseModelChampionships>;
  /**
   *
   * @type {Array<ApplicationAnonResponseModelQualifyingSites>}
   * @memberof MessageEditModel
   */
  qualifyingSites?: Array<ApplicationAnonResponseModelQualifyingSites>;
  /**
   *
   * @type {IdModel}
   * @memberof MessageEditModel
   */
  championshipType?: IdModel;
  /**
   *
   * @type {BlacklistStatusEnum}
   * @memberof MessageEditModel
   */
  blacklistStatus?: BlacklistStatusEnum;
  /**
   *
   * @type {QualifyingLevelEnum}
   * @memberof MessageEditModel
   */
  exemptionLevel?: QualifyingLevelEnum;
  /**
   *
   * @type {MessageTypeEnum}
   * @memberof MessageEditModel
   */
  messageType: MessageTypeEnum;
  /**
   *
   * @type {IdModel}
   * @memberof MessageEditModel
   */
  exemption?: IdModel;
  /**
   *
   * @type {Array<MessageAddModelAllOfPlayers>}
   * @memberof MessageEditModel
   */
  players?: Array<MessageAddModelAllOfPlayers>;
  /**
   *
   * @type {number}
   * @memberof MessageEditModel
   */
  id: number;
}
/**
 *
 * @export
 * @interface MessageHideRequestModel
 */
export interface MessageHideRequestModel {
  /**
   *
   * @type {number}
   * @memberof MessageHideRequestModel
   */
  messageId: number;
}
/**
 *
 * @export
 * @interface MessageKeyList
 */
export interface MessageKeyList {
  /**
   *
   * @type {Array<MessageKeyValuesEnum>}
   * @memberof MessageKeyList
   */
  items: Array<MessageKeyValuesEnum>;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum MessageKeyValuesEnum {
  CHAMPIONSHIP = '{CHAMPIONSHIP}',
  CHAMPIONSHIPSITE = '{CHAMPIONSHIP_SITE}',
  CHAMPIONSHIPSITECOURSE = '{CHAMPIONSHIP_SITE_COURSE}',
  CHAMPIONSHIPSTARTDATE = '{CHAMPIONSHIP_START_DATE}',
  CHAMPIONSHIPENDDATE = '{CHAMPIONSHIP_END_DATE}',
  CHAMPIONSHIPOPENDATE = '{CHAMPIONSHIP_OPEN_DATE}',
  CHAMPIONSHIPCLOSEDATE = '{CHAMPIONSHIP_CLOSE_DATE}',
  CHAMPIONSHIPCITY = '{CHAMPIONSHIP_CITY}',
  CHAMPIONSHIPSTATE = '{CHAMPIONSHIP_STATE}',
  CHAMPIONSHIPCOUNTRY = '{CHAMPIONSHIP_COUNTRY}',
  EXEMPTION = '{EXEMPTION}',
  ENTRYNUMBER = '{ENTRY_NUMBER}',
  QUALIFYINGSITE = '{QUALIFYING_SITE}',
  QUALIFYINGSITECOURSE = '{QUALIFYING_SITE_COURSE}',
  QUALIFYINGDATE = '{QUALIFYING_DATE}',
  QUALIFYINGRAINDATE = '{QUALIFYING_RAIN_DATE}',
  QUALIFYINGSITESTATE = '{QUALIFYING_SITE_STATE}',
  QUALIFYINGSITECITY = '{QUALIFYING_SITE_CITY}',
  QUALIFYINGSITECOUNTRY = '{QUALIFYING_SITE_COUNTRY}',
  QUALIFYINGAGA = '{QUALIFYING_AGA}',
  QUALIFYINGAGACITY = '{QUALIFYING_AGA_CITY}',
  QUALIFYINGAGASTATE = '{QUALIFYING_AGA_STATE}',
  QUALIFYINGAGACOUNTRY = '{QUALIFYING_AGA_COUNTRY}',
  QUALIFYINGAGAPHONENUMBER = '{QUALIFYING_AGA_PHONE_NUMBER}',
  QUALIFYINGAGAEMAIL = '{QUALIFYING_AGA_EMAIL}',
  QUALIFYINGPOCFIRSTNAME = '{QUALIFYING_POC_FIRST_NAME}',
  QUALIFYINGPOCLASTNAME = '{QUALIFYING_POC_LAST_NAME}',
  QUALIFYINGPOCPHONENUMBER = '{QUALIFYING_POC_PHONE_NUMBER}',
  QUALIFYINGPOCEMAIL = '{QUALIFYING_POC_EMAIL}',
  LOCALQUALIFYINGSITE = '{LOCAL_QUALIFYING_SITE}',
  LOCALQUALIFYINGSITECOURSE = '{LOCAL_QUALIFYING_SITE_COURSE}',
  LOCALQUALIFYINGDATE = '{LOCAL_QUALIFYING_DATE}',
  LOCALQUALIFYINGRAINDATE = '{LOCAL_QUALIFYING_RAIN_DATE}',
  LOCALQUALIFYINGSITESTATE = '{LOCAL_QUALIFYING_SITE_STATE}',
  LOCALQUALIFYINGSITECITY = '{LOCAL_QUALIFYING_SITE_CITY}',
  LOCALQUALIFYINGSITECOUNTRY = '{LOCAL_QUALIFYING_SITE_COUNTRY}',
  LOCALQUALIFYINGAGA = '{LOCAL_QUALIFYING_AGA}',
  LOCALQUALIFYINGAGACITY = '{LOCAL_QUALIFYING_AGA_CITY}',
  LOCALQUALIFYINGAGASTATE = '{LOCAL_QUALIFYING_AGA_STATE}',
  LOCALQUALIFYINGAGACOUNTRY = '{LOCAL_QUALIFYING_AGA_COUNTRY}',
  LOCALQUALIFYINGAGAPHONENUMBER = '{LOCAL_QUALIFYING_AGA_PHONE_NUMBER}',
  LOCALQUALIFYINGAGAEMAIL = '{LOCAL_QUALIFYING_AGA_EMAIL}',
  LOCALQUALIFYINGPOCFIRSTNAME = '{LOCAL_QUALIFYING_POC_FIRST_NAME}',
  LOCALQUALIFYINGPOCLASTNAME = '{LOCAL_QUALIFYING_POC_LAST_NAME}',
  LOCALQUALIFYINGPOCPHONENUMBER = '{LOCAL_QUALIFYING_POC_PHONE_NUMBER}',
  LOCALQUALIFYINGPOCEMAIL = '{LOCAL_QUALIFYING_POC_EMAIL}',
  FINALQUALIFYINGSITE = '{FINAL_QUALIFYING_SITE}',
  FINALQUALIFYINGSITECOURSE = '{FINAL_QUALIFYING_SITE_COURSE}',
  FINALQUALIFYINGDATE = '{FINAL_QUALIFYING_DATE}',
  FINALQUALIFYINGRAINDATE = '{FINAL_QUALIFYING_RAIN_DATE}',
  FINALQUALIFYINGSITESTATE = '{FINAL_QUALIFYING_SITE_STATE}',
  FINALQUALIFYINGSITECITY = '{FINAL_QUALIFYING_SITE_CITY}',
  FINALQUALIFYINGSITECOUNTRY = '{FINAL_QUALIFYING_SITE_COUNTRY}',
  FINALQUALIFYINGAGA = '{FINAL_QUALIFYING_AGA}',
  FINALQUALIFYINGAGACITY = '{FINAL_QUALIFYING_AGA_CITY}',
  FINALQUALIFYINGAGASTATE = '{FINAL_QUALIFYING_AGA_STATE}',
  FINALQUALIFYINGAGACOUNTRY = '{FINAL_QUALIFYING_AGA_COUNTRY}',
  FINALQUALIFYINGAGAPHONENUMBER = '{FINAL_QUALIFYING_AGA_PHONE_NUMBER}',
  FINALQUALIFYINGAGAEMAIL = '{FINAL_QUALIFYING_AGA_EMAIL}',
  FINALQUALIFYINGPOCFIRSTNAME = '{FINAL_QUALIFYING_POC_FIRST_NAME}',
  FINALQUALIFYINGPOCLASTNAME = '{FINAL_QUALIFYING_POC_LAST_NAME}',
  FINALQUALIFYINGPOCPHONENUMBER = '{FINAL_QUALIFYING_POC_PHONE_NUMBER}',
  FINALQUALIFYINGPOCEMAIL = '{FINAL_QUALIFYING_POC_EMAIL}',
  FINALQUALIFYINGSITE1 = '{FINAL_QUALIFYING_SITE_1}',
  FINALQUALIFYINGSITE1COURSE = '{FINAL_QUALIFYING_SITE_1_COURSE}',
  FINALQUALIFYINGSITE1DATE = '{FINAL_QUALIFYING_SITE_1_DATE}',
  FINALQUALIFYINGSITE1RAINDATE = '{FINAL_QUALIFYING_SITE_1_RAIN_DATE}',
  FINALQUALIFYINGSITE1STATE = '{FINAL_QUALIFYING_SITE_1_STATE}',
  FINALQUALIFYINGSITE1CITY = '{FINAL_QUALIFYING_SITE_1_CITY}',
  FINALQUALIFYINGSITE1COUNTRY = '{FINAL_QUALIFYING_SITE_1_COUNTRY}',
  FINALQUALIFYINGSITE2 = '{FINAL_QUALIFYING_SITE_2}',
  FINALQUALIFYINGSITE2COURSE = '{FINAL_QUALIFYING_SITE_2_COURSE}',
  FINALQUALIFYINGSITE2DATE = '{FINAL_QUALIFYING_SITE_2_DATE}',
  FINALQUALIFYINGSITE2RAINDATE = '{FINAL_QUALIFYING_SITE_2_RAIN_DATE}',
  FINALQUALIFYINGSITE2STATE = '{FINAL_QUALIFYING_SITE_2_STATE}',
  FINALQUALIFYINGSITE2CITY = '{FINAL_QUALIFYING_SITE_2_CITY}',
  FINALQUALIFYINGSITE2COUNTRY = '{FINAL_QUALIFYING_SITE_2_COUNTRY}',
  FINALQUALIFYINGSITE3 = '{FINAL_QUALIFYING_SITE_3}',
  FINALQUALIFYINGSITE3COURSE = '{FINAL_QUALIFYING_SITE_3_COURSE}',
  FINALQUALIFYINGSITE3DATE = '{FINAL_QUALIFYING_SITE_3_DATE}',
  FINALQUALIFYINGSITE3RAINDATE = '{FINAL_QUALIFYING_SITE_3_RAIN_DATE}',
  FINALQUALIFYINGSITE3STATE = '{FINAL_QUALIFYING_SITE_3_STATE}',
  FINALQUALIFYINGSITE3CITY = '{FINAL_QUALIFYING_SITE_3_CITY}',
  FINALQUALIFYINGSITE3COUNTRY = '{FINAL_QUALIFYING_SITE_3_COUNTRY}',
  WAITLISTPRIORITY = '{WAIT_LIST_PRIORITY}',
  WAITLISTSITE = '{WAIT_LIST_SITE}',
  WAITLISTSITECOURSE = '{WAIT_LIST_SITE_COURSE}',
  WAITLISTSITEDATE = '{WAIT_LIST_SITE_DATE}',
  WAITLISTSITERAINDATE = '{WAIT_LIST_SITE_RAIN_DATE}',
  WAITLISTCITY = '{WAIT_LIST_CITY}',
  WAITLISTSTATE = '{WAIT_LIST_STATE}',
  WAITLISTSITECOUNTRY = '{WAIT_LIST_SITE_COUNTRY}',
  CREDITCARDTYPE = '{CREDIT_CARD_TYPE}',
  CREDITCARDNUMBER = '{CREDIT_CARD_NUMBER}',
  EXPIRATIONDATE = '{EXPIRATION_DATE}',
  CHAMPIONSHIPENTRYFEE = '{CHAMPIONSHIP_ENTRY_FEE}',
  QUALIFYINGENTRYFEE = '{QUALIFYING_ENTRY_FEE}',
  PERFORMANCEVIOLATIONCHAMPIONSHIP = '{PERFORMANCE_VIOLATION_CHAMPIONSHIP}',
  PERFORMANCEVIOLATIONCLEAREDDATE = '{PERFORMANCE_VIOLATION_CLEARED_DATE}',
  PAYMENTPAGEURL = '{PAYMENT_PAGE_URL}',
  WAITLISTCLOSEDATE = '{WAITLIST_CLOSE_DATE}',
}

/**
 *
 * @export
 * @interface MessageListModel
 */
export interface MessageListModel {
  /**
   *
   * @type {Array<MessageAdminModel>}
   * @memberof MessageListModel
   */
  items: Array<MessageAdminModel>;
  /**
   *
   * @type {number}
   * @memberof MessageListModel
   */
  limit: number;
  /**
   *
   * @type {number}
   * @memberof MessageListModel
   */
  start: number;
  /**
   *
   * @type {number}
   * @memberof MessageListModel
   */
  total: number;
}
/**
 *
 * @export
 * @interface MessagePlayerListModel
 */
export interface MessagePlayerListModel {
  /**
   *
   * @type {Array<MessagePlayerModel>}
   * @memberof MessagePlayerListModel
   */
  items: Array<MessagePlayerModel>;
}
/**
 *
 * @export
 * @interface MessagePlayerModel
 */
export interface MessagePlayerModel {
  /**
   *
   * @type {number}
   * @memberof MessagePlayerModel
   */
  id?: number;
  /**
   *
   * @type {boolean}
   * @memberof MessagePlayerModel
   */
  allPlayers?: boolean;
  /**
   *
   * @type {string}
   * @memberof MessagePlayerModel
   */
  message?: string;
  /**
   *
   * @type {boolean}
   * @memberof MessagePlayerModel
   */
  dismissible?: boolean;
  /**
   *
   * @type {MessageTypeEnum}
   * @memberof MessagePlayerModel
   */
  messageType?: MessageTypeEnum;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum MessageTypeEnum {
  EXEMPTION = 'EXEMPTION',
  CUSTOM = 'CUSTOM',
  STROKEPOLICY = 'STROKE_POLICY',
}

/**
 *
 * @export
 * @interface MobileAppModel
 */
export interface MobileAppModel {
  /**
   *
   * @type {string}
   * @memberof MobileAppModel
   */
  name?: string;
  /**
   *
   * @type {USGACloudinaryImageModel}
   * @memberof MobileAppModel
   */
  image?: USGACloudinaryImageModel;
  /**
   *
   * @type {AppBadgeModel}
   * @memberof MobileAppModel
   */
  iosBadge?: AppBadgeModel;
  /**
   *
   * @type {AppBadgeModel}
   * @memberof MobileAppModel
   */
  androidBadge?: AppBadgeModel;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum NavigationItemTypeEnum {
  SimpleLink = 'usga/components/hbs/navigation/items/simple-link',
  ColumnFeatured = 'usga/components/hbs/navigation/items/column-featured',
  ColumnTwoPromos = 'usga/components/hbs/navigation/items/column-two-promos',
  FourColumns = 'usga/components/hbs/navigation/items/four-columns',
  FiveColumns = 'usga/components/hbs/navigation/items/five-columns',
}

/**
 *
 * @export
 * @interface NewCustomBulkEmailRequestModel
 */
export interface NewCustomBulkEmailRequestModel {
  /**
   *
   * @type {Array<number>}
   * @memberof NewCustomBulkEmailRequestModel
   */
  idList: Array<number>;
  /**
   *
   * @type {EmailTemplateRequestModel}
   * @memberof NewCustomBulkEmailRequestModel
   */
  template?: EmailTemplateRequestModel;
}
/**
 *
 * @export
 * @interface NewCustomBulkEmailRequestModelAllOf
 */
export interface NewCustomBulkEmailRequestModelAllOf {
  /**
   *
   * @type {EmailTemplateRequestModel}
   * @memberof NewCustomBulkEmailRequestModelAllOf
   */
  template?: EmailTemplateRequestModel;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum NoteSubjectEnum {
  PUSHPULLCARTS = 'PUSH_PULL_CARTS',
  AGA = 'AGA',
  COMPLAINTS = 'COMPLAINTS',
  GHINHANDICAP = 'GHIN_HANDICAP',
  HOWTOAPPLY = 'HOW_TO_APPLY',
  MISDIRECT = 'MISDIRECT',
  PLAYERQUALIFYINGINFORMATION = 'PLAYER_QUALIFYING_INFORMATION',
  QUALIFYINGSITES = 'QUALIFYING_SITES',
  REALLOTMENT = 'RE_ALLOTMENT',
  VOLUNTEERS = 'VOLUNTEERS',
  OTHER = 'OTHER',
}

/**
 *
 * @export
 * @interface NotificationModel
 */
export interface NotificationModel {
  /**
   *
   * @type {boolean}
   * @memberof NotificationModel
   */
  exemptApp?: boolean;
  /**
   *
   * @type {string}
   * @memberof NotificationModel
   */
  exemptAppProcessStart?: string;
  /**
   *
   * @type {boolean}
   * @memberof NotificationModel
   */
  qualifiedAndAlternate?: boolean;
  /**
   *
   * @type {string}
   * @memberof NotificationModel
   */
  qualifiedAndAlternateProcessStart?: string;
  /**
   *
   * @type {boolean}
   * @memberof NotificationModel
   */
  lateSubstituted?: boolean;
}
/**
 *
 * @export
 * @interface OfficialsCalendarBaseModel
 */
export interface OfficialsCalendarBaseModel {
  /**
   *
   * @type {string}
   * @memberof OfficialsCalendarBaseModel
   */
  startDate: string;
  /**
   *
   * @type {string}
   * @memberof OfficialsCalendarBaseModel
   */
  title: string;
}
/**
 * Represents Officials Calendar Component
 * @export
 * @interface OfficialsCalendarHomeModel
 */
export interface OfficialsCalendarHomeModel {
  /**
   *
   * @type {string}
   * @memberof OfficialsCalendarHomeModel
   */
  title?: string;
}
/**
 *
 * @export
 * @interface OfficialsCalendarListModel
 */
export interface OfficialsCalendarListModel {
  /**
   *
   * @type {Array<OfficialsCalendarBaseModel>}
   * @memberof OfficialsCalendarListModel
   */
  items: Array<OfficialsCalendarBaseModel>;
  /**
   *
   * @type {number}
   * @memberof OfficialsCalendarListModel
   */
  limit: number;
  /**
   *
   * @type {number}
   * @memberof OfficialsCalendarListModel
   */
  start: number;
  /**
   *
   * @type {number}
   * @memberof OfficialsCalendarListModel
   */
  total: number;
}
/**
 *
 * @export
 * @interface OfficialsListEmailRequestModel
 */
export interface OfficialsListEmailRequestModel {
  /**
   *
   * @type {number}
   * @memberof OfficialsListEmailRequestModel
   */
  templateId: number;
  /**
   *
   * @type {Array<AgaUserModel>}
   * @memberof OfficialsListEmailRequestModel
   */
  items: Array<AgaUserModel>;
}
/**
 * Represents Officials Qualifying Site Component
 * @export
 * @interface OfficialsQualifyingSiteModel
 */
export interface OfficialsQualifyingSiteModel {
  /**
   *
   * @type {string}
   * @memberof OfficialsQualifyingSiteModel
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof OfficialsQualifyingSiteModel
   */
  text?: string;
  /**
   *
   * @type {string}
   * @memberof OfficialsQualifyingSiteModel
   */
  backgroundImageId?: string;
  /**
   *
   * @type {string}
   * @memberof OfficialsQualifyingSiteModel
   */
  officialPagePath?: string;
}
/**
 * Represents Open Player Appliance Component
 * @export
 * @interface OpenAppliancePlayerModel
 */
export interface OpenAppliancePlayerModel {
  /**
   *
   * @type {ApplicationHomeStepPlayerApplianceModel}
   * @memberof OpenAppliancePlayerModel
   */
  applicationHome?: ApplicationHomeStepPlayerApplianceModel;
  /**
   *
   * @type {QualifyingExemptionStepPlayerApplianceModel}
   * @memberof OpenAppliancePlayerModel
   */
  qualifyingExemption?: QualifyingExemptionStepPlayerApplianceModel;
  /**
   *
   * @type {QualifyingLocationStepPlayerApplianceModel}
   * @memberof OpenAppliancePlayerModel
   */
  qualifyingLocation?: QualifyingLocationStepPlayerApplianceModel;
  /**
   *
   * @type {OpenEligibilityPlayerApplianceModel}
   * @memberof OpenAppliancePlayerModel
   */
  eligibility?: OpenEligibilityPlayerApplianceModel;
  /**
   *
   * @type {ReviewStepPlayerApplianceModel}
   * @memberof OpenAppliancePlayerModel
   */
  review?: ReviewStepPlayerApplianceModel;
  /**
   *
   * @type {IdModel}
   * @memberof OpenAppliancePlayerModel
   */
  championship: IdModel;
}
/**
 * Represents Open Eligibility Option Model
 * @export
 * @interface OpenEligibilityOptionModel
 */
export interface OpenEligibilityOptionModel {
  /**
   *
   * @type {OptionViewTypeEnum}
   * @memberof OpenEligibilityOptionModel
   */
  viewType: OptionViewTypeEnum;
  /**
   *
   * @type {string}
   * @memberof OpenEligibilityOptionModel
   */
  text: string;
  /**
   *
   * @type {string}
   * @memberof OpenEligibilityOptionModel
   */
  title?: string;
  /**
   *
   * @type {EligibilityOptionDBRelationEnum}
   * @memberof OpenEligibilityOptionModel
   */
  dbRelation?: EligibilityOptionDBRelationEnum;
  /**
   *
   * @type {string}
   * @memberof OpenEligibilityOptionModel
   */
  optOutWarning?: string;
  /**
   *
   * @type {Array<PlayerStatusEnum>}
   * @memberof OpenEligibilityOptionModel
   */
  playerStatus: Array<PlayerStatusEnum>;
}
/**
 *
 * @export
 * @interface OpenEligibilityOptionModelAllOf
 */
export interface OpenEligibilityOptionModelAllOf {
  /**
   *
   * @type {Array<PlayerStatusEnum>}
   * @memberof OpenEligibilityOptionModelAllOf
   */
  playerStatus: Array<PlayerStatusEnum>;
}
/**
 * Represents Open Eligibility Step Player Appliance Model
 * @export
 * @interface OpenEligibilityPlayerApplianceModel
 */
export interface OpenEligibilityPlayerApplianceModel {
  /**
   *
   * @type {string}
   * @memberof OpenEligibilityPlayerApplianceModel
   */
  stepTitle?: string;
  /**
   *
   * @type {string}
   * @memberof OpenEligibilityPlayerApplianceModel
   */
  headText?: string;
  /**
   *
   * @type {string}
   * @memberof OpenEligibilityPlayerApplianceModel
   */
  bottomText?: string;
  /**
   *
   * @type {Array<OpenEligibilityOptionModel>}
   * @memberof OpenEligibilityPlayerApplianceModel
   */
  options?: Array<OpenEligibilityOptionModel>;
  /**
   *
   * @type {Array<LinksBlockItemPlayerHomeModel>}
   * @memberof OpenEligibilityPlayerApplianceModel
   */
  links?: Array<LinksBlockItemPlayerHomeModel>;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum OptionViewTypeEnum {
  CHECKBOX = 'CHECKBOX',
  RADIO = 'RADIO',
}

/**
 *
 * @export
 * @interface PagePathModel
 */
export interface PagePathModel {
  /**
   *
   * @type {string}
   * @memberof PagePathModel
   */
  pagePath?: string;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum PageResouceTypeEnum {
  PlayerPage = 'champAdmin/components/structure/playerPage',
  ChampionshipEventPage = 'champAdmin/components/structure/championshipEventPage',
  Page = 'champAdmin/components/structure/page',
}

/**
 *
 * @export
 * @interface PaginationParamBean
 */
export interface PaginationParamBean {
  /**
   *
   * @type {number}
   * @memberof PaginationParamBean
   */
  limit: number;
  /**
   *
   * @type {number}
   * @memberof PaginationParamBean
   */
  start: number;
}
/**
 *
 * @export
 * @interface PairChampionshipInfo
 */
export interface PairChampionshipInfo {
  /**
   *
   * @type {string}
   * @memberof PairChampionshipInfo
   */
  championshipTitle?: string;
  /**
   *
   * @type {number}
   * @memberof PairChampionshipInfo
   */
  championshipYear?: number;
}
/**
 *
 * @export
 * @interface PairHistoryListModel
 */
export interface PairHistoryListModel {
  /**
   *
   * @type {Array<PairHistoryResponseModel>}
   * @memberof PairHistoryListModel
   */
  items: Array<PairHistoryResponseModel>;
  /**
   *
   * @type {number}
   * @memberof PairHistoryListModel
   */
  limit: number;
  /**
   *
   * @type {number}
   * @memberof PairHistoryListModel
   */
  start: number;
  /**
   *
   * @type {number}
   * @memberof PairHistoryListModel
   */
  total: number;
}
/**
 *
 * @export
 * @interface PairHistoryParamBean
 */
export interface PairHistoryParamBean {
  /**
   *
   * @type {number}
   * @memberof PairHistoryParamBean
   */
  limit: number;
  /**
   *
   * @type {number}
   * @memberof PairHistoryParamBean
   */
  start: number;
  /**
   *
   * @type {PairSortingFilterEnum}
   * @memberof PairHistoryParamBean
   */
  sortingFilter?: PairSortingFilterEnum;
  /**
   *
   * @type {string}
   * @memberof PairHistoryParamBean
   */
  query?: string;
  /**
   *
   * @type {number}
   * @memberof PairHistoryParamBean
   */
  playerId: number;
}
/**
 *
 * @export
 * @interface PairHistoryParamBeanAllOf
 */
export interface PairHistoryParamBeanAllOf {
  /**
   *
   * @type {PairSortingFilterEnum}
   * @memberof PairHistoryParamBeanAllOf
   */
  sortingFilter?: PairSortingFilterEnum;
  /**
   *
   * @type {string}
   * @memberof PairHistoryParamBeanAllOf
   */
  query?: string;
  /**
   *
   * @type {number}
   * @memberof PairHistoryParamBeanAllOf
   */
  playerId: number;
}
/**
 *
 * @export
 * @interface PairHistoryResponseModel
 */
export interface PairHistoryResponseModel {
  /**
   *
   * @type {Array<ApplicationAnonResponseModel>}
   * @memberof PairHistoryResponseModel
   */
  applications: Array<ApplicationAnonResponseModel>;
  /**
   *
   * @type {PairChampionshipInfo}
   * @memberof PairHistoryResponseModel
   */
  pairInfo?: PairChampionshipInfo;
}
/**
 *
 * @export
 * @interface PairListRequestModel
 */
export interface PairListRequestModel {
  /**
   *
   * @type {Array<PairRequestModel>}
   * @memberof PairListRequestModel
   */
  pairs: Array<PairRequestModel>;
  /**
   *
   * @type {number}
   * @memberof PairListRequestModel
   */
  champId: number;
}
/**
 *
 * @export
 * @interface PairListResponseModel
 */
export interface PairListResponseModel {
  /**
   *
   * @type {Array<PairResponseModel>}
   * @memberof PairListResponseModel
   */
  pairs?: Array<PairResponseModel>;
}
/**
 *
 * @export
 * @interface PairRequestModel
 */
export interface PairRequestModel {
  /**
   *
   * @type {Array<PairRequestModelApplications>}
   * @memberof PairRequestModel
   */
  applications: Array<PairRequestModelApplications>;
  /**
   *
   * @type {string}
   * @memberof PairRequestModel
   */
  startingTime1Title?: string;
  /**
   *
   * @type {string}
   * @memberof PairRequestModel
   */
  startingTime1?: string;
  /**
   *
   * @type {string}
   * @memberof PairRequestModel
   */
  startingTime2Title?: string;
  /**
   *
   * @type {string}
   * @memberof PairRequestModel
   */
  startingTime2?: string;
}
/**
 *
 * @export
 * @interface PairRequestModelApplications
 */
export interface PairRequestModelApplications {
  /**
   *
   * @type {number}
   * @memberof PairRequestModelApplications
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof PairRequestModelApplications
   */
  pairingNumber: number;
}
/**
 *
 * @export
 * @interface PairResponseModel
 */
export interface PairResponseModel {
  /**
   *
   * @type {Array<ApplicationAdminResponseModel>}
   * @memberof PairResponseModel
   */
  applications: Array<ApplicationAdminResponseModel>;
  /**
   *
   * @type {PairChampionshipInfo}
   * @memberof PairResponseModel
   */
  pairInfo?: PairChampionshipInfo;
  /**
   *
   * @type {string}
   * @memberof PairResponseModel
   */
  startingTime1Title?: string;
  /**
   *
   * @type {string}
   * @memberof PairResponseModel
   */
  startingTime1?: string;
  /**
   *
   * @type {string}
   * @memberof PairResponseModel
   */
  startingTime2Title?: string;
  /**
   *
   * @type {string}
   * @memberof PairResponseModel
   */
  startingTime2?: string;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum PairSortingFilterEnum {
  CHAMPIONSHIPNAMEASC = 'CHAMPIONSHIP_NAME_ASC',
  CHAMPIONSHIPNAMEDESC = 'CHAMPIONSHIP_NAME_DESC',
  CHAMPIONSHIPYEARASC = 'CHAMPIONSHIP_YEAR_ASC',
  CHAMPIONSHIPYEARDESC = 'CHAMPIONSHIP_YEAR_DESC',
  UNKNOWN = 'UNKNOWN',
}

/**
 *
 * @export
 * @interface PartnerLinkEmailRequestModel
 */
export interface PartnerLinkEmailRequestModel {
  /**
   *
   * @type {number}
   * @memberof PartnerLinkEmailRequestModel
   */
  teamId: number;
  /**
   *
   * @type {string}
   * @memberof PartnerLinkEmailRequestModel
   */
  email: string;
}
/**
 *
 * @export
 * @interface PartnerModel
 */
export interface PartnerModel {
  /**
   *
   * @type {string}
   * @memberof PartnerModel
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof PartnerModel
   */
  description?: string;
  /**
   *
   * @type {USGACloudinaryImageModel}
   * @memberof PartnerModel
   */
  image?: USGACloudinaryImageModel;
}
/**
 *
 * @export
 * @interface PaymentApplicationModel
 */
export interface PaymentApplicationModel {
  /**
   *
   * @type {string}
   * @memberof PaymentApplicationModel
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentApplicationModel
   */
  status?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentApplicationModel
   */
  rcode?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentApplicationModel
   */
  rmessage?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentApplicationModel
   */
  authDate?: string;
}
/**
 *
 * @export
 * @interface PaymentDataPlayerModel
 */
export interface PaymentDataPlayerModel {
  /**
   *
   * @type {string}
   * @memberof PaymentDataPlayerModel
   */
  req_card_type?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentDataPlayerModel
   */
  req_card_number?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentDataPlayerModel
   */
  req_card_expiry_date?: string;
  /**
   *
   * @type {PaymentDecisionEnum}
   * @memberof PaymentDataPlayerModel
   */
  decision: PaymentDecisionEnum;
  /**
   *
   * @type {number}
   * @memberof PaymentDataPlayerModel
   */
  reason_code: number;
  /**
   *
   * @type {number}
   * @memberof PaymentDataPlayerModel
   */
  req_amount?: number;
  /**
   *
   * @type {string}
   * @memberof PaymentDataPlayerModel
   */
  auth_amount?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentDataPlayerModel
   */
  auth_time?: string;
  /**
   *
   * @type {PaymentTypesEnum}
   * @memberof PaymentDataPlayerModel
   */
  req_merchant_defined_data4?: PaymentTypesEnum;
}
/**
 *
 * @export
 * @interface PaymentDataPlayerResponseModel
 */
export interface PaymentDataPlayerResponseModel {
  /**
   * Payment API path to make POST
   * @type {string}
   * @memberof PaymentDataPlayerResponseModel
   */
  apiPath: string;
  /**
   * Form fields
   * @type {Array<PaymentDataPlayerResponseModelFields>}
   * @memberof PaymentDataPlayerResponseModel
   */
  fields: Array<PaymentDataPlayerResponseModelFields>;
}
/**
 *
 * @export
 * @interface PaymentDataPlayerResponseModelFields
 */
export interface PaymentDataPlayerResponseModelFields {
  /**
   *
   * @type {string}
   * @memberof PaymentDataPlayerResponseModelFields
   */
  key: string;
  /**
   *
   * @type {string}
   * @memberof PaymentDataPlayerResponseModelFields
   */
  value: string;
}
/**
 *
 * @export
 * @interface PaymentDataRequestModel
 */
export interface PaymentDataRequestModel {
  /**
   *
   * @type {string}
   * @memberof PaymentDataRequestModel
   */
  req_bill_to_forename?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentDataRequestModel
   */
  req_bill_to_surname?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentDataRequestModel
   */
  req_bill_to_address_line1?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentDataRequestModel
   */
  req_bill_to_address_line2?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentDataRequestModel
   */
  req_bill_to_address_country?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentDataRequestModel
   */
  req_bill_to_address_city?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentDataRequestModel
   */
  req_bill_to_address_state?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentDataRequestModel
   */
  req_bill_to_address_postal_code?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentDataRequestModel
   */
  req_bill_to_phone?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentDataRequestModel
   */
  req_bill_to_email?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentDataRequestModel
   */
  request_token?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentDataRequestModel
   */
  transaction_id?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentDataRequestModel
   */
  message?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentDataRequestModel
   */
  req_transaction_uuid?: string;
  /**
   *
   * @type {number}
   * @memberof PaymentDataRequestModel
   */
  req_merchant_defined_data1?: number;
  /**
   *
   * @type {PaymentTypesEnum}
   * @memberof PaymentDataRequestModel
   */
  req_merchant_defined_data4?: PaymentTypesEnum;
  /**
   *
   * @type {string}
   * @memberof PaymentDataRequestModel
   */
  req_reference_number?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentDataRequestModel
   */
  req_card_type_selection_indicator?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentDataRequestModel
   */
  signed_field_names?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentDataRequestModel
   */
  raw?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentDataRequestModel
   */
  req_card_type?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentDataRequestModel
   */
  req_card_number?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentDataRequestModel
   */
  req_card_expiry_date?: string;
  /**
   *
   * @type {PaymentDecisionEnum}
   * @memberof PaymentDataRequestModel
   */
  decision: PaymentDecisionEnum;
  /**
   *
   * @type {number}
   * @memberof PaymentDataRequestModel
   */
  reason_code: number;
  /**
   *
   * @type {number}
   * @memberof PaymentDataRequestModel
   */
  req_amount?: number;
  /**
   *
   * @type {string}
   * @memberof PaymentDataRequestModel
   */
  auth_amount?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentDataRequestModel
   */
  auth_time?: string;
  /**
   *
   * @type {PaymentApplicationModel}
   * @memberof PaymentDataRequestModel
   */
  ics_bill_application?: PaymentApplicationModel;
  /**
   *
   * @type {PaymentApplicationModel}
   * @memberof PaymentDataRequestModel
   */
  ics_auth_application?: PaymentApplicationModel;
  /**
   *
   * @type {PaymentProcessorEnum}
   * @memberof PaymentDataRequestModel
   */
  paymentProcessor?: PaymentProcessorEnum;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum PaymentDecisionEnum {
  ACCEPT = 'ACCEPT',
  REVIEW = 'REVIEW',
  DECLINE = 'DECLINE',
  ERROR = 'ERROR',
  CANCEL = 'CANCEL',
}

/**
 *
 * @export
 * @interface PaymentHistoryModel
 */
export interface PaymentHistoryModel {
  /**
   *
   * @type {IdModel}
   * @memberof PaymentHistoryModel
   */
  application: IdModel;
  /**
   *
   * @type {string}
   * @memberof PaymentHistoryModel
   */
  authTime?: string;
  /**
   *
   * @type {PaymentTypesEnum}
   * @memberof PaymentHistoryModel
   */
  reqMerchantDefinedData4?: PaymentTypesEnum;
  /**
   *
   * @type {string}
   * @memberof PaymentHistoryModel
   */
  decision?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentHistoryModel
   */
  dateCreated?: string;
}
/**
 * Represents Payment Issues Component
 * @export
 * @interface PaymentIssuesModel
 */
export interface PaymentIssuesModel {
  /**
   *
   * @type {string}
   * @memberof PaymentIssuesModel
   */
  playerHomePath?: string;
  /**
   *
   * @type {Array<PaymentIssuesModelOptions>}
   * @memberof PaymentIssuesModel
   */
  options: Array<PaymentIssuesModelOptions>;
}
/**
 *
 * @export
 * @interface PaymentIssuesModelOptions
 */
export interface PaymentIssuesModelOptions {
  /**
   *
   * @type {string}
   * @memberof PaymentIssuesModelOptions
   */
  errorCode: string;
  /**
   *
   * @type {string}
   * @memberof PaymentIssuesModelOptions
   */
  errorMessage: string;
}
/**
 * Represents Payment Component
 * @export
 * @interface PaymentModel
 */
export interface PaymentModel {
  /**
   *
   * @type {string}
   * @memberof PaymentModel
   */
  confirmationPage: string;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum PaymentProcessorEnum {
  CS = 'CS',
  STRIPE = 'STRIPE',
}

/**
 *
 * @export
 * @enum {string}
 */
export enum PaymentTypesEnum {
  PAYMENT = 'PAYMENT',
  WAITLISTFEE = 'WAITLIST_FEE',
  REFUND = 'REFUND',
  CREDIT = 'CREDIT',
  AUTHORIZATIONPAYMENT = 'AUTHORIZATION_PAYMENT',
  ENTRYFEEBALANCE = 'ENTRY_FEE_BALANCE',
  QUALIFYINGENTRYFEE = 'QUALIFYING_ENTRY_FEE',
  CHAMPIONSHIPENTRYFEE = 'CHAMPIONSHIP_ENTRY_FEE',
}

/**
 *
 * @export
 * @interface PendingPaymentModel
 */
export interface PendingPaymentModel {
  /**
   *
   * @type {number}
   * @memberof PendingPaymentModel
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof PendingPaymentModel
   */
  payload: string;
  /**
   *
   * @type {AemWfTypeEnum}
   * @memberof PendingPaymentModel
   */
  type: AemWfTypeEnum;
  /**
   *
   * @type {string}
   * @memberof PendingPaymentModel
   */
  dateCreated?: string;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum PlayerAwardEnum {
  LOWSCORERMEDAL = 'LOW_SCORER_MEDAL',
  COLOWSCORERMEDAL = 'CO_LOW_SCORER_MEDAL',
  LOWAMATEURMEDAL = 'LOW_AMATEUR_MEDAL',
  COLOWAMATEURMEDAL = 'CO_LOW_AMATEUR_MEDAL',
}

/**
 *
 * @export
 * @interface PlayerEmailSubscriptionsStatus
 */
export interface PlayerEmailSubscriptionsStatus {
  /**
   *
   * @type {Array<EmailSubscriptionsStatus>}
   * @memberof PlayerEmailSubscriptionsStatus
   */
  subscriptions: Array<EmailSubscriptionsStatus>;
  /**
   *
   * @type {number}
   * @memberof PlayerEmailSubscriptionsStatus
   */
  playerId: number;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum PlayerFilters {
  ADAREQUEST = 'ADA_REQUEST',
  WATCHLIST = 'WATCHLIST',
  NOSHOW1 = 'NO_SHOW1',
  NOSHOW2 = 'NO_SHOW2',
  NOSHOW3 = 'NO_SHOW3',
  NOSHOWSUSPENDED = 'NO_SHOW_SUSPENDED',
  PERFORMANCEVIOLATION1 = 'PERFORMANCE_VIOLATION_1',
  PERFORMANCEVIOLATION2 = 'PERFORMANCE_VIOLATION_2',
  KEEP = 'KEEP',
  MONEY = 'MONEY',
  DUPLICATE = 'DUPLICATE',
  COMBINEDBLACKLIST = 'COMBINED_BLACKLIST',
  CLEARED = 'CLEARED',
  MINOR = 'MINOR',
}

/**
 *
 * @export
 * @enum {string}
 */
export enum PlayerRelationTypeEnum {
  MANAGERPLAYER = 'MANAGER_PLAYER',
  MANAGEDPLAYER = 'MANAGED_PLAYER',
}

/**
 *
 * @export
 * @enum {string}
 */
export enum PlayerStatusEnum {
  AMATEUR = 'AMATEUR',
  PROFESSIONAL = 'PROFESSIONAL',
  APPLICANTFORREINSTATEMENT = 'APPLICANT_FOR_REINSTATEMENT',
}

/**
 *
 * @export
 * @enum {string}
 */
export enum PlayerWithdrawalReasonEnum {
  ILLNESS = 'ILLNESS',
  INJURY = 'INJURY',
  GOLFCONFLICT = 'GOLF_CONFLICT',
  WORKCONFLICT = 'WORK_CONFLICT',
  SCHOOLCONFLICT = 'SCHOOL_CONFLICT',
  OTHER = 'OTHER',
}

/**
 *
 * @export
 * @interface PointOfContactAdminAddModel
 */
export interface PointOfContactAdminAddModel {
  /**
   *
   * @type {string}
   * @memberof PointOfContactAdminAddModel
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof PointOfContactAdminAddModel
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof PointOfContactAdminAddModel
   */
  phoneNumber: string;
  /**
   *
   * @type {string}
   * @memberof PointOfContactAdminAddModel
   */
  mobileNumber?: string;
  /**
   *
   * @type {string}
   * @memberof PointOfContactAdminAddModel
   */
  salutation?: string;
  /**
   *
   * @type {string}
   * @memberof PointOfContactAdminAddModel
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof PointOfContactAdminAddModel
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof PointOfContactAdminAddModel
   */
  watchers?: string;
  /**
   *
   * @type {boolean}
   * @memberof PointOfContactAdminAddModel
   */
  sameAsAGA: boolean;
  /**
   *
   * @type {string}
   * @memberof PointOfContactAdminAddModel
   */
  deliveryAddress?: string;
  /**
   *
   * @type {string}
   * @memberof PointOfContactAdminAddModel
   */
  deliveryAddress2?: string;
  /**
   *
   * @type {string}
   * @memberof PointOfContactAdminAddModel
   */
  deliveryCity?: string;
  /**
   *
   * @type {StateAddModel}
   * @memberof PointOfContactAdminAddModel
   */
  deliveryState?: StateAddModel;
  /**
   *
   * @type {string}
   * @memberof PointOfContactAdminAddModel
   */
  deliveryPostcode?: string;
  /**
   *
   * @type {CountryAddModel}
   * @memberof PointOfContactAdminAddModel
   */
  deliveryCountry?: CountryAddModel;
  /**
   *
   * @type {string}
   * @memberof PointOfContactAdminAddModel
   */
  deliveryComments?: string;
  /**
   *
   * @type {IdModel}
   * @memberof PointOfContactAdminAddModel
   */
  aga: IdModel;
}
/**
 *
 * @export
 * @interface PointOfContactAdminAddModelAllOf
 */
export interface PointOfContactAdminAddModelAllOf {
  /**
   *
   * @type {IdModel}
   * @memberof PointOfContactAdminAddModelAllOf
   */
  aga: IdModel;
}
/**
 *
 * @export
 * @interface PointOfContactAdminEditModel
 */
export interface PointOfContactAdminEditModel {
  /**
   *
   * @type {string}
   * @memberof PointOfContactAdminEditModel
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof PointOfContactAdminEditModel
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof PointOfContactAdminEditModel
   */
  phoneNumber: string;
  /**
   *
   * @type {string}
   * @memberof PointOfContactAdminEditModel
   */
  mobileNumber?: string;
  /**
   *
   * @type {string}
   * @memberof PointOfContactAdminEditModel
   */
  salutation?: string;
  /**
   *
   * @type {string}
   * @memberof PointOfContactAdminEditModel
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof PointOfContactAdminEditModel
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof PointOfContactAdminEditModel
   */
  watchers?: string;
  /**
   *
   * @type {boolean}
   * @memberof PointOfContactAdminEditModel
   */
  sameAsAGA: boolean;
  /**
   *
   * @type {string}
   * @memberof PointOfContactAdminEditModel
   */
  deliveryAddress?: string;
  /**
   *
   * @type {string}
   * @memberof PointOfContactAdminEditModel
   */
  deliveryAddress2?: string;
  /**
   *
   * @type {string}
   * @memberof PointOfContactAdminEditModel
   */
  deliveryCity?: string;
  /**
   *
   * @type {StateAddModel}
   * @memberof PointOfContactAdminEditModel
   */
  deliveryState?: StateAddModel;
  /**
   *
   * @type {string}
   * @memberof PointOfContactAdminEditModel
   */
  deliveryPostcode?: string;
  /**
   *
   * @type {CountryAddModel}
   * @memberof PointOfContactAdminEditModel
   */
  deliveryCountry?: CountryAddModel;
  /**
   *
   * @type {string}
   * @memberof PointOfContactAdminEditModel
   */
  deliveryComments?: string;
  /**
   *
   * @type {IdModel}
   * @memberof PointOfContactAdminEditModel
   */
  aga: IdModel;
  /**
   *
   * @type {number}
   * @memberof PointOfContactAdminEditModel
   */
  id: number;
}
/**
 *
 * @export
 * @interface PointOfContactAdminListModel
 */
export interface PointOfContactAdminListModel {
  /**
   *
   * @type {Array<PointOfContactAdminModel>}
   * @memberof PointOfContactAdminListModel
   */
  items: Array<PointOfContactAdminModel>;
  /**
   *
   * @type {number}
   * @memberof PointOfContactAdminListModel
   */
  limit: number;
  /**
   *
   * @type {number}
   * @memberof PointOfContactAdminListModel
   */
  start: number;
  /**
   *
   * @type {number}
   * @memberof PointOfContactAdminListModel
   */
  total: number;
}
/**
 *
 * @export
 * @interface PointOfContactAdminModel
 */
export interface PointOfContactAdminModel {
  /**
   *
   * @type {string}
   * @memberof PointOfContactAdminModel
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof PointOfContactAdminModel
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof PointOfContactAdminModel
   */
  phoneNumber: string;
  /**
   *
   * @type {string}
   * @memberof PointOfContactAdminModel
   */
  mobileNumber?: string;
  /**
   *
   * @type {string}
   * @memberof PointOfContactAdminModel
   */
  salutation?: string;
  /**
   *
   * @type {string}
   * @memberof PointOfContactAdminModel
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof PointOfContactAdminModel
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof PointOfContactAdminModel
   */
  watchers?: string;
  /**
   *
   * @type {boolean}
   * @memberof PointOfContactAdminModel
   */
  sameAsAGA: boolean;
  /**
   *
   * @type {string}
   * @memberof PointOfContactAdminModel
   */
  deliveryAddress?: string;
  /**
   *
   * @type {string}
   * @memberof PointOfContactAdminModel
   */
  deliveryAddress2?: string;
  /**
   *
   * @type {string}
   * @memberof PointOfContactAdminModel
   */
  deliveryCity?: string;
  /**
   *
   * @type {StateAddModel}
   * @memberof PointOfContactAdminModel
   */
  deliveryState?: StateAddModel;
  /**
   *
   * @type {string}
   * @memberof PointOfContactAdminModel
   */
  deliveryPostcode?: string;
  /**
   *
   * @type {CountryAddModel}
   * @memberof PointOfContactAdminModel
   */
  deliveryCountry?: CountryAddModel;
  /**
   *
   * @type {string}
   * @memberof PointOfContactAdminModel
   */
  deliveryComments?: string;
  /**
   *
   * @type {number}
   * @memberof PointOfContactAdminModel
   */
  id: number;
  /**
   *
   * @type {AgaEditModel}
   * @memberof PointOfContactAdminModel
   */
  aga: AgaEditModel;
}
/**
 *
 * @export
 * @interface PointOfContactAdminModelAllOf
 */
export interface PointOfContactAdminModelAllOf {
  /**
   *
   * @type {number}
   * @memberof PointOfContactAdminModelAllOf
   */
  id: number;
  /**
   *
   * @type {AgaEditModel}
   * @memberof PointOfContactAdminModelAllOf
   */
  aga: AgaEditModel;
}
/**
 *
 * @export
 * @interface PointOfContactBaseModel
 */
export interface PointOfContactBaseModel {
  /**
   *
   * @type {string}
   * @memberof PointOfContactBaseModel
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof PointOfContactBaseModel
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof PointOfContactBaseModel
   */
  phoneNumber: string;
  /**
   *
   * @type {string}
   * @memberof PointOfContactBaseModel
   */
  mobileNumber?: string;
  /**
   *
   * @type {string}
   * @memberof PointOfContactBaseModel
   */
  salutation?: string;
  /**
   *
   * @type {string}
   * @memberof PointOfContactBaseModel
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof PointOfContactBaseModel
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof PointOfContactBaseModel
   */
  watchers?: string;
  /**
   *
   * @type {boolean}
   * @memberof PointOfContactBaseModel
   */
  sameAsAGA: boolean;
  /**
   *
   * @type {string}
   * @memberof PointOfContactBaseModel
   */
  deliveryAddress?: string;
  /**
   *
   * @type {string}
   * @memberof PointOfContactBaseModel
   */
  deliveryAddress2?: string;
  /**
   *
   * @type {string}
   * @memberof PointOfContactBaseModel
   */
  deliveryCity?: string;
  /**
   *
   * @type {StateAddModel}
   * @memberof PointOfContactBaseModel
   */
  deliveryState?: StateAddModel;
  /**
   *
   * @type {string}
   * @memberof PointOfContactBaseModel
   */
  deliveryPostcode?: string;
  /**
   *
   * @type {CountryAddModel}
   * @memberof PointOfContactBaseModel
   */
  deliveryCountry?: CountryAddModel;
  /**
   *
   * @type {string}
   * @memberof PointOfContactBaseModel
   */
  deliveryComments?: string;
}
/**
 *
 * @export
 * @interface ProfileAdminBaseModel
 */
export interface ProfileAdminBaseModel {
  /**
   *
   * @type {ProfilePersonalDataModelPrimaryContactInfo}
   * @memberof ProfileAdminBaseModel
   */
  primaryContactInfo?: ProfilePersonalDataModelPrimaryContactInfo;
  /**
   *
   * @type {ApplicationBasePlayerModelGuardianContactInfo}
   * @memberof ProfileAdminBaseModel
   */
  guardianContactInfo?: ApplicationBasePlayerModelGuardianContactInfo;
  /**
   *
   * @type {GenderEnum}
   * @memberof ProfileAdminBaseModel
   */
  gender?: GenderEnum;
  /**
   *
   * @type {string}
   * @memberof ProfileAdminBaseModel
   */
  dob?: string;
  /**
   *
   * @type {CountryAddModel}
   * @memberof ProfileAdminBaseModel
   */
  countryOfCitizenship?: CountryAddModel;
  /**
   *
   * @type {EthnicityEnum}
   * @memberof ProfileAdminBaseModel
   */
  ethnicity?: EthnicityEnum;
  /**
   *
   * @type {number}
   * @memberof ProfileAdminBaseModel
   */
  highSchoolGraduationYear?: number;
  /**
   *
   * @type {CountryAddModel}
   * @memberof ProfileAdminBaseModel
   */
  preferredCountry?: CountryAddModel;
  /**
   *
   * @type {string}
   * @memberof ProfileAdminBaseModel
   */
  preferredCity?: string;
  /**
   *
   * @type {StateAddModel}
   * @memberof ProfileAdminBaseModel
   */
  preferredState?: StateAddModel;
  /**
   *
   * @type {ApplicationPaymentProcessFetchModelHandicap}
   * @memberof ProfileAdminBaseModel
   */
  handicap?: ApplicationPaymentProcessFetchModelHandicap;
  /**
   *
   * @type {string}
   * @memberof ProfileAdminBaseModel
   */
  agency?: string;
  /**
   *
   * @type {ApplicationDisabilityTypeEnum}
   * @memberof ProfileAdminBaseModel
   */
  disabilityType?: ApplicationDisabilityTypeEnum;
  /**
   *
   * @type {string}
   * @memberof ProfileAdminBaseModel
   */
  wR4GDPass?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileAdminBaseModel
   */
  disabilityDesc?: string;
  /**
   *
   * @type {boolean}
   * @memberof ProfileAdminBaseModel
   */
  legalGuardian?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProfileAdminBaseModel
   */
  playGolfInCart?: boolean;
  /**
   *
   * @type {string}
   * @memberof ProfileAdminBaseModel
   */
  virtusII1Number?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileAdminBaseModel
   */
  adaptiveEquipDesc?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileAdminBaseModel
   */
  compPlayingRecord?: string;
  /**
   *
   * @type {PlayerStatusEnum}
   * @memberof ProfileAdminBaseModel
   */
  playerStatus?: PlayerStatusEnum;
  /**
   *
   * @type {ProfileAdminRequestModelAllOfAgentInfo}
   * @memberof ProfileAdminBaseModel
   */
  agentInfo?: ProfileAdminRequestModelAllOfAgentInfo;
  /**
   *
   * @type {ProfilePlayerModelAllOfEmergencyContact}
   * @memberof ProfileAdminBaseModel
   */
  emergencyContact?: ProfilePlayerModelAllOfEmergencyContact;
  /**
   *
   * @type {string}
   * @memberof ProfileAdminBaseModel
   */
  eligibleReinstatementDate?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileAdminBaseModel
   */
  wagrRank?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileAdminBaseModel
   */
  auth0Id: string;
  /**
   *
   * @type {number}
   * @memberof ProfileAdminBaseModel
   */
  id: number;
  /**
   *
   * @type {boolean}
   * @memberof ProfileAdminBaseModel
   */
  adaRequest?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProfileAdminBaseModel
   */
  watchlist?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProfileAdminBaseModel
   */
  watchlistSendEmail?: boolean;
  /**
   *
   * @type {string}
   * @memberof ProfileAdminBaseModel
   */
  watchlistReason?: string;
  /**
   *
   * @type {IdModel}
   * @memberof ProfileAdminBaseModel
   */
  mainProfile?: IdModel;
  /**
   *
   * @type {boolean}
   * @memberof ProfileAdminBaseModel
   */
  isNotDuplicate?: boolean;
  /**
   *
   * @type {Array<ProfileAdminBaseModelAllOfBlacklist>}
   * @memberof ProfileAdminBaseModel
   */
  blacklist?: Array<ProfileAdminBaseModelAllOfBlacklist>;
  /**
   *
   * @type {AuditModel}
   * @memberof ProfileAdminBaseModel
   */
  audit: AuditModel;
  /**
   *
   * @type {Array<ProfileAdminBaseModelAllOfExemptions>}
   * @memberof ProfileAdminBaseModel
   */
  exemptions: Array<ProfileAdminBaseModelAllOfExemptions>;
  /**
   *
   * @type {Array<IdModel>}
   * @memberof ProfileAdminBaseModel
   */
  mergedProfiles?: Array<IdModel>;
  /**
   *
   * @type {Array<LinkedProfilePlayerResponseModelAllOfManagerPlayer>}
   * @memberof ProfileAdminBaseModel
   */
  managerPlayer?: Array<LinkedProfilePlayerResponseModelAllOfManagerPlayer>;
  /**
   *
   * @type {Array<ProfileAdminBaseModelAllOfManagedPlayer>}
   * @memberof ProfileAdminBaseModel
   */
  managedPlayer?: Array<ProfileAdminBaseModelAllOfManagedPlayer>;
}
/**
 *
 * @export
 * @interface ProfileAdminBaseModelAllOf
 */
export interface ProfileAdminBaseModelAllOf {
  /**
   *
   * @type {PlayerStatusEnum}
   * @memberof ProfileAdminBaseModelAllOf
   */
  playerStatus?: PlayerStatusEnum;
  /**
   *
   * @type {ProfileAdminRequestModelAllOfAgentInfo}
   * @memberof ProfileAdminBaseModelAllOf
   */
  agentInfo?: ProfileAdminRequestModelAllOfAgentInfo;
  /**
   *
   * @type {ProfilePlayerModelAllOfEmergencyContact}
   * @memberof ProfileAdminBaseModelAllOf
   */
  emergencyContact?: ProfilePlayerModelAllOfEmergencyContact;
  /**
   *
   * @type {string}
   * @memberof ProfileAdminBaseModelAllOf
   */
  eligibleReinstatementDate?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileAdminBaseModelAllOf
   */
  wagrRank?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileAdminBaseModelAllOf
   */
  auth0Id: string;
  /**
   *
   * @type {number}
   * @memberof ProfileAdminBaseModelAllOf
   */
  id: number;
  /**
   *
   * @type {boolean}
   * @memberof ProfileAdminBaseModelAllOf
   */
  adaRequest?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProfileAdminBaseModelAllOf
   */
  watchlist?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProfileAdminBaseModelAllOf
   */
  watchlistSendEmail?: boolean;
  /**
   *
   * @type {string}
   * @memberof ProfileAdminBaseModelAllOf
   */
  watchlistReason?: string;
  /**
   *
   * @type {IdModel}
   * @memberof ProfileAdminBaseModelAllOf
   */
  mainProfile?: IdModel;
  /**
   *
   * @type {boolean}
   * @memberof ProfileAdminBaseModelAllOf
   */
  isNotDuplicate?: boolean;
  /**
   *
   * @type {Array<ProfileAdminBaseModelAllOfBlacklist>}
   * @memberof ProfileAdminBaseModelAllOf
   */
  blacklist?: Array<ProfileAdminBaseModelAllOfBlacklist>;
  /**
   *
   * @type {AuditModel}
   * @memberof ProfileAdminBaseModelAllOf
   */
  audit: AuditModel;
  /**
   *
   * @type {Array<ProfileAdminBaseModelAllOfExemptions>}
   * @memberof ProfileAdminBaseModelAllOf
   */
  exemptions: Array<ProfileAdminBaseModelAllOfExemptions>;
  /**
   *
   * @type {Array<IdModel>}
   * @memberof ProfileAdminBaseModelAllOf
   */
  mergedProfiles?: Array<IdModel>;
  /**
   *
   * @type {Array<LinkedProfilePlayerResponseModelAllOfManagerPlayer>}
   * @memberof ProfileAdminBaseModelAllOf
   */
  managerPlayer?: Array<LinkedProfilePlayerResponseModelAllOfManagerPlayer>;
  /**
   *
   * @type {Array<ProfileAdminBaseModelAllOfManagedPlayer>}
   * @memberof ProfileAdminBaseModelAllOf
   */
  managedPlayer?: Array<ProfileAdminBaseModelAllOfManagedPlayer>;
}
/**
 *
 * @export
 * @interface ProfileAdminBaseModelAllOfBlacklist
 */
export interface ProfileAdminBaseModelAllOfBlacklist {
  /**
   *
   * @type {ProfileAdminBaseModelAllOfChampionship}
   * @memberof ProfileAdminBaseModelAllOfBlacklist
   */
  championship: ProfileAdminBaseModelAllOfChampionship;
  /**
   *
   * @type {number}
   * @memberof ProfileAdminBaseModelAllOfBlacklist
   */
  id?: number;
  /**
   *
   * @type {BlacklistStatusEnum}
   * @memberof ProfileAdminBaseModelAllOfBlacklist
   */
  blacklistStatus?: BlacklistStatusEnum;
  /**
   *
   * @type {BlacklistStatusEnum}
   * @memberof ProfileAdminBaseModelAllOfBlacklist
   */
  blacklistStatusOriginal?: BlacklistStatusEnum;
  /**
   *
   * @type {boolean}
   * @memberof ProfileAdminBaseModelAllOfBlacklist
   */
  blacklistSendEmail?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProfileAdminBaseModelAllOfBlacklist
   */
  clearedSendEmail?: boolean;
  /**
   *
   * @type {string}
   * @memberof ProfileAdminBaseModelAllOfBlacklist
   */
  blacklistStatusDate?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileAdminBaseModelAllOfBlacklist
   */
  blacklistStatusDateCleared?: string;
}
/**
 *
 * @export
 * @interface ProfileAdminBaseModelAllOfChampionship
 */
export interface ProfileAdminBaseModelAllOfChampionship {
  /**
   *
   * @type {number}
   * @memberof ProfileAdminBaseModelAllOfChampionship
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof ProfileAdminBaseModelAllOfChampionship
   */
  year: number;
  /**
   *
   * @type {string}
   * @memberof ProfileAdminBaseModelAllOfChampionship
   */
  title: string;
  /**
   *
   * @type {ExemptionDeleteFetchModelApplication}
   * @memberof ProfileAdminBaseModelAllOfChampionship
   */
  championshipType?: ExemptionDeleteFetchModelApplication;
}
/**
 *
 * @export
 * @interface ProfileAdminBaseModelAllOfExemption
 */
export interface ProfileAdminBaseModelAllOfExemption {
  /**
   *
   * @type {number}
   * @memberof ProfileAdminBaseModelAllOfExemption
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof ProfileAdminBaseModelAllOfExemption
   */
  number?: number;
  /**
   *
   * @type {QualifyingLevelEnum}
   * @memberof ProfileAdminBaseModelAllOfExemption
   */
  level?: QualifyingLevelEnum;
  /**
   *
   * @type {IdModel}
   * @memberof ProfileAdminBaseModelAllOfExemption
   */
  championship: IdModel;
}
/**
 *
 * @export
 * @interface ProfileAdminBaseModelAllOfExemptions
 */
export interface ProfileAdminBaseModelAllOfExemptions {
  /**
   *
   * @type {number}
   * @memberof ProfileAdminBaseModelAllOfExemptions
   */
  year?: number;
  /**
   *
   * @type {number}
   * @memberof ProfileAdminBaseModelAllOfExemptions
   */
  position?: number;
  /**
   *
   * @type {number}
   * @memberof ProfileAdminBaseModelAllOfExemptions
   */
  id: number;
  /**
   *
   * @type {ProfileAdminBaseModelAllOfExemption}
   * @memberof ProfileAdminBaseModelAllOfExemptions
   */
  exemption: ProfileAdminBaseModelAllOfExemption;
}
/**
 *
 * @export
 * @interface ProfileAdminBaseModelAllOfManagedPlayer
 */
export interface ProfileAdminBaseModelAllOfManagedPlayer {
  /**
   *
   * @type {ProfilePlayerPersonalDataWithIdResponseModel}
   * @memberof ProfileAdminBaseModelAllOfManagedPlayer
   */
  managedPlayer?: ProfilePlayerPersonalDataWithIdResponseModel;
  /**
   *
   * @type {boolean}
   * @memberof ProfileAdminBaseModelAllOfManagedPlayer
   */
  accepted?: boolean;
}
/**
 *
 * @export
 * @interface ProfileAdminListModel
 */
export interface ProfileAdminListModel {
  /**
   *
   * @type {Array<ProfileAdminModel>}
   * @memberof ProfileAdminListModel
   */
  items: Array<ProfileAdminModel>;
  /**
   *
   * @type {number}
   * @memberof ProfileAdminListModel
   */
  limit: number;
  /**
   *
   * @type {number}
   * @memberof ProfileAdminListModel
   */
  start: number;
  /**
   *
   * @type {number}
   * @memberof ProfileAdminListModel
   */
  total: number;
}
/**
 *
 * @export
 * @interface ProfileAdminModel
 */
export interface ProfileAdminModel {
  /**
   *
   * @type {ProfilePersonalDataModelPrimaryContactInfo}
   * @memberof ProfileAdminModel
   */
  primaryContactInfo?: ProfilePersonalDataModelPrimaryContactInfo;
  /**
   *
   * @type {ApplicationBasePlayerModelGuardianContactInfo}
   * @memberof ProfileAdminModel
   */
  guardianContactInfo?: ApplicationBasePlayerModelGuardianContactInfo;
  /**
   *
   * @type {GenderEnum}
   * @memberof ProfileAdminModel
   */
  gender?: GenderEnum;
  /**
   *
   * @type {string}
   * @memberof ProfileAdminModel
   */
  dob?: string;
  /**
   *
   * @type {CountryAddModel}
   * @memberof ProfileAdminModel
   */
  countryOfCitizenship?: CountryAddModel;
  /**
   *
   * @type {EthnicityEnum}
   * @memberof ProfileAdminModel
   */
  ethnicity?: EthnicityEnum;
  /**
   *
   * @type {number}
   * @memberof ProfileAdminModel
   */
  highSchoolGraduationYear?: number;
  /**
   *
   * @type {CountryAddModel}
   * @memberof ProfileAdminModel
   */
  preferredCountry?: CountryAddModel;
  /**
   *
   * @type {string}
   * @memberof ProfileAdminModel
   */
  preferredCity?: string;
  /**
   *
   * @type {StateAddModel}
   * @memberof ProfileAdminModel
   */
  preferredState?: StateAddModel;
  /**
   *
   * @type {ApplicationPaymentProcessFetchModelHandicap}
   * @memberof ProfileAdminModel
   */
  handicap?: ApplicationPaymentProcessFetchModelHandicap;
  /**
   *
   * @type {string}
   * @memberof ProfileAdminModel
   */
  agency?: string;
  /**
   *
   * @type {ApplicationDisabilityTypeEnum}
   * @memberof ProfileAdminModel
   */
  disabilityType?: ApplicationDisabilityTypeEnum;
  /**
   *
   * @type {string}
   * @memberof ProfileAdminModel
   */
  wR4GDPass?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileAdminModel
   */
  disabilityDesc?: string;
  /**
   *
   * @type {boolean}
   * @memberof ProfileAdminModel
   */
  legalGuardian?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProfileAdminModel
   */
  playGolfInCart?: boolean;
  /**
   *
   * @type {string}
   * @memberof ProfileAdminModel
   */
  virtusII1Number?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileAdminModel
   */
  adaptiveEquipDesc?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileAdminModel
   */
  compPlayingRecord?: string;
  /**
   *
   * @type {PlayerStatusEnum}
   * @memberof ProfileAdminModel
   */
  playerStatus?: PlayerStatusEnum;
  /**
   *
   * @type {ProfileAdminRequestModelAllOfAgentInfo}
   * @memberof ProfileAdminModel
   */
  agentInfo?: ProfileAdminRequestModelAllOfAgentInfo;
  /**
   *
   * @type {ProfilePlayerModelAllOfEmergencyContact}
   * @memberof ProfileAdminModel
   */
  emergencyContact?: ProfilePlayerModelAllOfEmergencyContact;
  /**
   *
   * @type {string}
   * @memberof ProfileAdminModel
   */
  eligibleReinstatementDate?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileAdminModel
   */
  wagrRank?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileAdminModel
   */
  auth0Id: string;
  /**
   *
   * @type {number}
   * @memberof ProfileAdminModel
   */
  id: number;
  /**
   *
   * @type {boolean}
   * @memberof ProfileAdminModel
   */
  adaRequest?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProfileAdminModel
   */
  watchlist?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProfileAdminModel
   */
  watchlistSendEmail?: boolean;
  /**
   *
   * @type {string}
   * @memberof ProfileAdminModel
   */
  watchlistReason?: string;
  /**
   *
   * @type {IdModel}
   * @memberof ProfileAdminModel
   */
  mainProfile?: IdModel;
  /**
   *
   * @type {boolean}
   * @memberof ProfileAdminModel
   */
  isNotDuplicate?: boolean;
  /**
   *
   * @type {Array<ProfileAdminBaseModelAllOfBlacklist>}
   * @memberof ProfileAdminModel
   */
  blacklist?: Array<ProfileAdminBaseModelAllOfBlacklist>;
  /**
   *
   * @type {AuditModel}
   * @memberof ProfileAdminModel
   */
  audit: AuditModel;
  /**
   *
   * @type {Array<ProfileAdminBaseModelAllOfExemptions>}
   * @memberof ProfileAdminModel
   */
  exemptions: Array<ProfileAdminBaseModelAllOfExemptions>;
  /**
   *
   * @type {Array<IdModel>}
   * @memberof ProfileAdminModel
   */
  mergedProfiles?: Array<IdModel>;
  /**
   *
   * @type {Array<LinkedProfilePlayerResponseModelAllOfManagerPlayer>}
   * @memberof ProfileAdminModel
   */
  managerPlayer?: Array<LinkedProfilePlayerResponseModelAllOfManagerPlayer>;
  /**
   *
   * @type {Array<ProfileAdminBaseModelAllOfManagedPlayer>}
   * @memberof ProfileAdminModel
   */
  managedPlayer?: Array<ProfileAdminBaseModelAllOfManagedPlayer>;
  /**
   *
   * @type {string}
   * @memberof ProfileAdminModel
   */
  watchlistDate?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileAdminModel
   */
  auth0Email?: string;
  /**
   *
   * @type {boolean}
   * @memberof ProfileAdminModel
   */
  auth0EmailVerified?: boolean;
  /**
   *
   * @type {Array<EmailSubscriptionType>}
   * @memberof ProfileAdminModel
   */
  disabledSubscriptions?: Array<EmailSubscriptionType>;
}
/**
 *
 * @export
 * @interface ProfileAdminModelAllOf
 */
export interface ProfileAdminModelAllOf {
  /**
   *
   * @type {string}
   * @memberof ProfileAdminModelAllOf
   */
  watchlistDate?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileAdminModelAllOf
   */
  auth0Email?: string;
  /**
   *
   * @type {boolean}
   * @memberof ProfileAdminModelAllOf
   */
  auth0EmailVerified?: boolean;
  /**
   *
   * @type {Array<EmailSubscriptionType>}
   * @memberof ProfileAdminModelAllOf
   */
  disabledSubscriptions?: Array<EmailSubscriptionType>;
}
/**
 *
 * @export
 * @interface ProfileAdminRequestModel
 */
export interface ProfileAdminRequestModel {
  /**
   *
   * @type {ProfilePersonalDataModelPrimaryContactInfo}
   * @memberof ProfileAdminRequestModel
   */
  primaryContactInfo?: ProfilePersonalDataModelPrimaryContactInfo;
  /**
   *
   * @type {ApplicationBasePlayerModelGuardianContactInfo}
   * @memberof ProfileAdminRequestModel
   */
  guardianContactInfo?: ApplicationBasePlayerModelGuardianContactInfo;
  /**
   *
   * @type {GenderEnum}
   * @memberof ProfileAdminRequestModel
   */
  gender?: GenderEnum;
  /**
   *
   * @type {string}
   * @memberof ProfileAdminRequestModel
   */
  dob?: string;
  /**
   *
   * @type {CountryAddModel}
   * @memberof ProfileAdminRequestModel
   */
  countryOfCitizenship?: CountryAddModel;
  /**
   *
   * @type {EthnicityEnum}
   * @memberof ProfileAdminRequestModel
   */
  ethnicity?: EthnicityEnum;
  /**
   *
   * @type {number}
   * @memberof ProfileAdminRequestModel
   */
  highSchoolGraduationYear?: number;
  /**
   *
   * @type {CountryAddModel}
   * @memberof ProfileAdminRequestModel
   */
  preferredCountry?: CountryAddModel;
  /**
   *
   * @type {string}
   * @memberof ProfileAdminRequestModel
   */
  preferredCity?: string;
  /**
   *
   * @type {StateAddModel}
   * @memberof ProfileAdminRequestModel
   */
  preferredState?: StateAddModel;
  /**
   *
   * @type {ApplicationPaymentProcessFetchModelHandicap}
   * @memberof ProfileAdminRequestModel
   */
  handicap?: ApplicationPaymentProcessFetchModelHandicap;
  /**
   *
   * @type {string}
   * @memberof ProfileAdminRequestModel
   */
  agency?: string;
  /**
   *
   * @type {ApplicationDisabilityTypeEnum}
   * @memberof ProfileAdminRequestModel
   */
  disabilityType?: ApplicationDisabilityTypeEnum;
  /**
   *
   * @type {string}
   * @memberof ProfileAdminRequestModel
   */
  wR4GDPass?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileAdminRequestModel
   */
  disabilityDesc?: string;
  /**
   *
   * @type {boolean}
   * @memberof ProfileAdminRequestModel
   */
  legalGuardian?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProfileAdminRequestModel
   */
  playGolfInCart?: boolean;
  /**
   *
   * @type {string}
   * @memberof ProfileAdminRequestModel
   */
  virtusII1Number?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileAdminRequestModel
   */
  adaptiveEquipDesc?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileAdminRequestModel
   */
  compPlayingRecord?: string;
  /**
   * FE flag, not persisted
   * @type {boolean}
   * @memberof ProfileAdminRequestModel
   */
  sendMail?: boolean;
  /**
   *
   * @type {PlayerStatusEnum}
   * @memberof ProfileAdminRequestModel
   */
  playerStatus?: PlayerStatusEnum;
  /**
   *
   * @type {string}
   * @memberof ProfileAdminRequestModel
   */
  dateCreated?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileAdminRequestModel
   */
  dateModified?: string;
  /**
   *
   * @type {ProfileAdminRequestModelAllOfAgentInfo}
   * @memberof ProfileAdminRequestModel
   */
  agentInfo?: ProfileAdminRequestModelAllOfAgentInfo;
  /**
   *
   * @type {ProfilePlayerModelAllOfEmergencyContact}
   * @memberof ProfileAdminRequestModel
   */
  emergencyContact?: ProfilePlayerModelAllOfEmergencyContact;
  /**
   *
   * @type {string}
   * @memberof ProfileAdminRequestModel
   */
  eligibleReinstatementDate?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileAdminRequestModel
   */
  wagrRank?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileAdminRequestModel
   */
  auth0Id: string;
  /**
   *
   * @type {boolean}
   * @memberof ProfileAdminRequestModel
   */
  auth0EmailVerified: boolean;
  /**
   *
   * @type {number}
   * @memberof ProfileAdminRequestModel
   */
  id: number;
  /**
   *
   * @type {boolean}
   * @memberof ProfileAdminRequestModel
   */
  adaRequest?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProfileAdminRequestModel
   */
  watchlist?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProfileAdminRequestModel
   */
  watchlistSendEmail?: boolean;
  /**
   *
   * @type {string}
   * @memberof ProfileAdminRequestModel
   */
  watchlistReason?: string;
  /**
   *
   * @type {AuditNewAdminModel}
   * @memberof ProfileAdminRequestModel
   */
  audit: AuditNewAdminModel;
  /**
   *
   * @type {boolean}
   * @memberof ProfileAdminRequestModel
   */
  isNotDuplicate?: boolean;
  /**
   *
   * @type {Array<ProfileAdminRequestModelAllOfBlacklist>}
   * @memberof ProfileAdminRequestModel
   */
  blacklist?: Array<ProfileAdminRequestModelAllOfBlacklist>;
  /**
   *
   * @type {Array<ProfileAdminRequestModelAllOfExemptions>}
   * @memberof ProfileAdminRequestModel
   */
  exemptions?: Array<ProfileAdminRequestModelAllOfExemptions>;
}
/**
 *
 * @export
 * @interface ProfileAdminRequestModelAllOf
 */
export interface ProfileAdminRequestModelAllOf {
  /**
   * FE flag, not persisted
   * @type {boolean}
   * @memberof ProfileAdminRequestModelAllOf
   */
  sendMail?: boolean;
  /**
   *
   * @type {PlayerStatusEnum}
   * @memberof ProfileAdminRequestModelAllOf
   */
  playerStatus?: PlayerStatusEnum;
  /**
   *
   * @type {string}
   * @memberof ProfileAdminRequestModelAllOf
   */
  dateCreated?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileAdminRequestModelAllOf
   */
  dateModified?: string;
  /**
   *
   * @type {ProfileAdminRequestModelAllOfAgentInfo}
   * @memberof ProfileAdminRequestModelAllOf
   */
  agentInfo?: ProfileAdminRequestModelAllOfAgentInfo;
  /**
   *
   * @type {ProfilePlayerModelAllOfEmergencyContact}
   * @memberof ProfileAdminRequestModelAllOf
   */
  emergencyContact?: ProfilePlayerModelAllOfEmergencyContact;
  /**
   *
   * @type {string}
   * @memberof ProfileAdminRequestModelAllOf
   */
  eligibleReinstatementDate?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileAdminRequestModelAllOf
   */
  wagrRank?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileAdminRequestModelAllOf
   */
  auth0Id: string;
  /**
   *
   * @type {boolean}
   * @memberof ProfileAdminRequestModelAllOf
   */
  auth0EmailVerified: boolean;
  /**
   *
   * @type {number}
   * @memberof ProfileAdminRequestModelAllOf
   */
  id: number;
  /**
   *
   * @type {boolean}
   * @memberof ProfileAdminRequestModelAllOf
   */
  adaRequest?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProfileAdminRequestModelAllOf
   */
  watchlist?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProfileAdminRequestModelAllOf
   */
  watchlistSendEmail?: boolean;
  /**
   *
   * @type {string}
   * @memberof ProfileAdminRequestModelAllOf
   */
  watchlistReason?: string;
  /**
   *
   * @type {AuditNewAdminModel}
   * @memberof ProfileAdminRequestModelAllOf
   */
  audit: AuditNewAdminModel;
  /**
   *
   * @type {boolean}
   * @memberof ProfileAdminRequestModelAllOf
   */
  isNotDuplicate?: boolean;
  /**
   *
   * @type {Array<ProfileAdminRequestModelAllOfBlacklist>}
   * @memberof ProfileAdminRequestModelAllOf
   */
  blacklist?: Array<ProfileAdminRequestModelAllOfBlacklist>;
  /**
   *
   * @type {Array<ProfileAdminRequestModelAllOfExemptions>}
   * @memberof ProfileAdminRequestModelAllOf
   */
  exemptions?: Array<ProfileAdminRequestModelAllOfExemptions>;
}
/**
 *
 * @export
 * @interface ProfileAdminRequestModelAllOfAgentInfo
 */
export interface ProfileAdminRequestModelAllOfAgentInfo {
  /**
   *
   * @type {string}
   * @memberof ProfileAdminRequestModelAllOfAgentInfo
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileAdminRequestModelAllOfAgentInfo
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileAdminRequestModelAllOfAgentInfo
   */
  agency?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileAdminRequestModelAllOfAgentInfo
   */
  address?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileAdminRequestModelAllOfAgentInfo
   */
  address2?: string;
  /**
   *
   * @type {CountryAddModel}
   * @memberof ProfileAdminRequestModelAllOfAgentInfo
   */
  country?: CountryAddModel;
  /**
   *
   * @type {string}
   * @memberof ProfileAdminRequestModelAllOfAgentInfo
   */
  city?: string;
  /**
   *
   * @type {StateAddModel}
   * @memberof ProfileAdminRequestModelAllOfAgentInfo
   */
  state?: StateAddModel;
  /**
   *
   * @type {string}
   * @memberof ProfileAdminRequestModelAllOfAgentInfo
   */
  postcode?: string;
  /**
   *
   * @type {string}
   * @memberof ProfileAdminRequestModelAllOfAgentInfo
   */
  phoneNumber?: string;
}
/**
 *
 * @export
 * @interface ProfileAdminRequestModelAllOfBlacklist
 */
export interface ProfileAdminRequestModelAllOfBlacklist {
  /**
   *
   * @type {IdModel}
   * @memberof ProfileAdminRequestModelAllOfBlacklist
   */
  championship: IdModel;
  /**
   *
   * @type {number}
   * @memberof ProfileAdminRequestModelAllOfBlacklist
   */
  id?: number;
  /**
   *
   * @type {BlacklistStatusEnum}
   * @memberof ProfileAdminRequestModelAllOfBlacklist
   */
  blacklistStatus?: BlacklistStatusEnum;
  /**
   *
   * @type {BlacklistStatusEnum}
   * @memberof ProfileAdminRequestModelAllOfBlacklist
   */
  blacklistStatusOriginal?: BlacklistStatusEnum;
  /**
   *
   * @type {boolean}
   * @memberof ProfileAdminRequestModelAllOfBlacklist
   */
  blacklistSendEmail?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProfileAdminRequestModelAllOfBlacklist
   */
  clearedSendEmail?: boolean;
  /**
   *
   * @type {string}
   * @memberof ProfileAdminRequestModelAllOfBlacklist
   */
  blacklistStatusDate?: string;
}
/**
 *
 * @export
 * @interface ProfileAdminRequestModelAllOfExemptions
 */
export interface ProfileAdminRequestModelAllOfExemptions {
  /**
   *
   * @type {number}
   * @memberof ProfileAdminRequestModelAllOfExemptions
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof ProfileAdminRequestModelAllOfExemptions
   */
  year?: number;
  /**
   *
   * @type {number}
   * @memberof ProfileAdminRequestModelAllOfExemptions
   */
  position?: number;
  /**
   *
   * @type {IdModel}
   * @memberof ProfileAdminRequestModelAllOfExemptions
   */
  exemption: IdModel;
}
/**
 *
 * @export
 * @interface ProfileAuth0ListModel
 */
export interface ProfileAuth0ListModel {
  /**
   *
   * @type {Array<UserAuth0Model>}
   * @memberof ProfileAuth0ListModel
   */
  items: Array<UserAuth0Model>;
  /**
   *
   * @type {number}
   * @memberof ProfileAuth0ListModel
   */
  limit: number;
  /**
   *
   * @type {number}
   * @memberof ProfileAuth0ListModel
   */
  start: number;
  /**
   *
   * @type {number}
   * @memberof ProfileAuth0ListModel
   */
  total: number;
}
/**
 *
 * @export
 * @interface ProfileLinkRequestModel
 */
export interface ProfileLinkRequestModel {
  /**
   *
   * @type {number}
   * @memberof ProfileLinkRequestModel
   */
  profileId: number;
}
/**
 *
 * @export
 * @interface ProfileManagedPlayerAdminModel
 */
export interface ProfileManagedPlayerAdminModel {
  /**
   *
   * @type {number}
   * @memberof ProfileManagedPlayerAdminModel
   */
  id: number;
  /**
   *
   * @type {Array<ProfileManagedPlayerAdminModelManagedPlayer>}
   * @memberof ProfileManagedPlayerAdminModel
   */
  managedPlayer?: Array<ProfileManagedPlayerAdminModelManagedPlayer>;
}
/**
 *
 * @export
 * @interface ProfileManagedPlayerAdminModelManagedPlayer
 */
export interface ProfileManagedPlayerAdminModelManagedPlayer {
  /**
   *
   * @type {IdModel}
   * @memberof ProfileManagedPlayerAdminModelManagedPlayer
   */
  managedPlayer?: IdModel;
  /**
   *
   * @type {boolean}
   * @memberof ProfileManagedPlayerAdminModelManagedPlayer
   */
  accepted?: boolean;
}
/**
 *
 * @export
 * @interface ProfilePersonalDataModel
 */
export interface ProfilePersonalDataModel {
  /**
   *
   * @type {ProfilePersonalDataModelPrimaryContactInfo}
   * @memberof ProfilePersonalDataModel
   */
  primaryContactInfo?: ProfilePersonalDataModelPrimaryContactInfo;
  /**
   *
   * @type {ApplicationBasePlayerModelGuardianContactInfo}
   * @memberof ProfilePersonalDataModel
   */
  guardianContactInfo?: ApplicationBasePlayerModelGuardianContactInfo;
  /**
   *
   * @type {GenderEnum}
   * @memberof ProfilePersonalDataModel
   */
  gender?: GenderEnum;
  /**
   *
   * @type {string}
   * @memberof ProfilePersonalDataModel
   */
  dob?: string;
  /**
   *
   * @type {CountryAddModel}
   * @memberof ProfilePersonalDataModel
   */
  countryOfCitizenship?: CountryAddModel;
  /**
   *
   * @type {EthnicityEnum}
   * @memberof ProfilePersonalDataModel
   */
  ethnicity?: EthnicityEnum;
  /**
   *
   * @type {number}
   * @memberof ProfilePersonalDataModel
   */
  highSchoolGraduationYear?: number;
  /**
   *
   * @type {CountryAddModel}
   * @memberof ProfilePersonalDataModel
   */
  preferredCountry?: CountryAddModel;
  /**
   *
   * @type {string}
   * @memberof ProfilePersonalDataModel
   */
  preferredCity?: string;
  /**
   *
   * @type {StateAddModel}
   * @memberof ProfilePersonalDataModel
   */
  preferredState?: StateAddModel;
  /**
   *
   * @type {ApplicationPaymentProcessFetchModelHandicap}
   * @memberof ProfilePersonalDataModel
   */
  handicap?: ApplicationPaymentProcessFetchModelHandicap;
  /**
   *
   * @type {string}
   * @memberof ProfilePersonalDataModel
   */
  agency?: string;
  /**
   *
   * @type {ApplicationDisabilityTypeEnum}
   * @memberof ProfilePersonalDataModel
   */
  disabilityType?: ApplicationDisabilityTypeEnum;
  /**
   *
   * @type {string}
   * @memberof ProfilePersonalDataModel
   */
  wR4GDPass?: string;
  /**
   *
   * @type {string}
   * @memberof ProfilePersonalDataModel
   */
  disabilityDesc?: string;
  /**
   *
   * @type {boolean}
   * @memberof ProfilePersonalDataModel
   */
  legalGuardian?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProfilePersonalDataModel
   */
  playGolfInCart?: boolean;
  /**
   *
   * @type {string}
   * @memberof ProfilePersonalDataModel
   */
  virtusII1Number?: string;
  /**
   *
   * @type {string}
   * @memberof ProfilePersonalDataModel
   */
  adaptiveEquipDesc?: string;
  /**
   *
   * @type {string}
   * @memberof ProfilePersonalDataModel
   */
  compPlayingRecord?: string;
}
/**
 *
 * @export
 * @interface ProfilePersonalDataModelPrimaryContactInfo
 */
export interface ProfilePersonalDataModelPrimaryContactInfo {
  /**
   *
   * @type {string}
   * @memberof ProfilePersonalDataModelPrimaryContactInfo
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof ProfilePersonalDataModelPrimaryContactInfo
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof ProfilePersonalDataModelPrimaryContactInfo
   */
  address?: string;
  /**
   *
   * @type {string}
   * @memberof ProfilePersonalDataModelPrimaryContactInfo
   */
  address2?: string;
  /**
   *
   * @type {CountryAddModel}
   * @memberof ProfilePersonalDataModelPrimaryContactInfo
   */
  country?: CountryAddModel;
  /**
   *
   * @type {string}
   * @memberof ProfilePersonalDataModelPrimaryContactInfo
   */
  city?: string;
  /**
   *
   * @type {StateAddModel}
   * @memberof ProfilePersonalDataModelPrimaryContactInfo
   */
  state?: StateAddModel;
  /**
   *
   * @type {string}
   * @memberof ProfilePersonalDataModelPrimaryContactInfo
   */
  postcode?: string;
  /**
   *
   * @type {string}
   * @memberof ProfilePersonalDataModelPrimaryContactInfo
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof ProfilePersonalDataModelPrimaryContactInfo
   */
  phoneNumber?: string;
  /**
   *
   * @type {string}
   * @memberof ProfilePersonalDataModelPrimaryContactInfo
   */
  mobileNumber?: string;
  /**
   *
   * @type {string}
   * @memberof ProfilePersonalDataModelPrimaryContactInfo
   */
  businessNumber?: string;
}
/**
 *
 * @export
 * @interface ProfilePlayerModel
 */
export interface ProfilePlayerModel {
  /**
   *
   * @type {ProfilePersonalDataModelPrimaryContactInfo}
   * @memberof ProfilePlayerModel
   */
  primaryContactInfo?: ProfilePersonalDataModelPrimaryContactInfo;
  /**
   *
   * @type {ApplicationBasePlayerModelGuardianContactInfo}
   * @memberof ProfilePlayerModel
   */
  guardianContactInfo?: ApplicationBasePlayerModelGuardianContactInfo;
  /**
   *
   * @type {GenderEnum}
   * @memberof ProfilePlayerModel
   */
  gender?: GenderEnum;
  /**
   *
   * @type {string}
   * @memberof ProfilePlayerModel
   */
  dob?: string;
  /**
   *
   * @type {CountryAddModel}
   * @memberof ProfilePlayerModel
   */
  countryOfCitizenship?: CountryAddModel;
  /**
   *
   * @type {EthnicityEnum}
   * @memberof ProfilePlayerModel
   */
  ethnicity?: EthnicityEnum;
  /**
   *
   * @type {number}
   * @memberof ProfilePlayerModel
   */
  highSchoolGraduationYear?: number;
  /**
   *
   * @type {CountryAddModel}
   * @memberof ProfilePlayerModel
   */
  preferredCountry?: CountryAddModel;
  /**
   *
   * @type {string}
   * @memberof ProfilePlayerModel
   */
  preferredCity?: string;
  /**
   *
   * @type {StateAddModel}
   * @memberof ProfilePlayerModel
   */
  preferredState?: StateAddModel;
  /**
   *
   * @type {ApplicationPaymentProcessFetchModelHandicap}
   * @memberof ProfilePlayerModel
   */
  handicap?: ApplicationPaymentProcessFetchModelHandicap;
  /**
   *
   * @type {string}
   * @memberof ProfilePlayerModel
   */
  agency?: string;
  /**
   *
   * @type {ApplicationDisabilityTypeEnum}
   * @memberof ProfilePlayerModel
   */
  disabilityType?: ApplicationDisabilityTypeEnum;
  /**
   *
   * @type {string}
   * @memberof ProfilePlayerModel
   */
  wR4GDPass?: string;
  /**
   *
   * @type {string}
   * @memberof ProfilePlayerModel
   */
  disabilityDesc?: string;
  /**
   *
   * @type {boolean}
   * @memberof ProfilePlayerModel
   */
  legalGuardian?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProfilePlayerModel
   */
  playGolfInCart?: boolean;
  /**
   *
   * @type {string}
   * @memberof ProfilePlayerModel
   */
  virtusII1Number?: string;
  /**
   *
   * @type {string}
   * @memberof ProfilePlayerModel
   */
  adaptiveEquipDesc?: string;
  /**
   *
   * @type {string}
   * @memberof ProfilePlayerModel
   */
  compPlayingRecord?: string;
  /**
   *
   * @type {PlayerStatusEnum}
   * @memberof ProfilePlayerModel
   */
  playerStatus?: PlayerStatusEnum;
  /**
   *
   * @type {AuditModel}
   * @memberof ProfilePlayerModel
   */
  audit: AuditModel;
  /**
   *
   * @type {ProfilePlayerModelAllOfEmergencyContact}
   * @memberof ProfilePlayerModel
   */
  emergencyContact?: ProfilePlayerModelAllOfEmergencyContact;
}
/**
 *
 * @export
 * @interface ProfilePlayerModelAllOf
 */
export interface ProfilePlayerModelAllOf {
  /**
   *
   * @type {AuditModel}
   * @memberof ProfilePlayerModelAllOf
   */
  audit: AuditModel;
  /**
   *
   * @type {ProfilePlayerModelAllOfEmergencyContact}
   * @memberof ProfilePlayerModelAllOf
   */
  emergencyContact?: ProfilePlayerModelAllOfEmergencyContact;
}
/**
 *
 * @export
 * @interface ProfilePlayerModelAllOfEmergencyContact
 */
export interface ProfilePlayerModelAllOfEmergencyContact {
  /**
   *
   * @type {string}
   * @memberof ProfilePlayerModelAllOfEmergencyContact
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof ProfilePlayerModelAllOfEmergencyContact
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof ProfilePlayerModelAllOfEmergencyContact
   */
  phoneNumber?: string;
}
/**
 *
 * @export
 * @interface ProfilePlayerPersonalDataWithIdResponseModel
 */
export interface ProfilePlayerPersonalDataWithIdResponseModel {
  /**
   *
   * @type {ProfilePersonalDataModelPrimaryContactInfo}
   * @memberof ProfilePlayerPersonalDataWithIdResponseModel
   */
  primaryContactInfo?: ProfilePersonalDataModelPrimaryContactInfo;
  /**
   *
   * @type {ApplicationBasePlayerModelGuardianContactInfo}
   * @memberof ProfilePlayerPersonalDataWithIdResponseModel
   */
  guardianContactInfo?: ApplicationBasePlayerModelGuardianContactInfo;
  /**
   *
   * @type {GenderEnum}
   * @memberof ProfilePlayerPersonalDataWithIdResponseModel
   */
  gender?: GenderEnum;
  /**
   *
   * @type {string}
   * @memberof ProfilePlayerPersonalDataWithIdResponseModel
   */
  dob?: string;
  /**
   *
   * @type {CountryAddModel}
   * @memberof ProfilePlayerPersonalDataWithIdResponseModel
   */
  countryOfCitizenship?: CountryAddModel;
  /**
   *
   * @type {EthnicityEnum}
   * @memberof ProfilePlayerPersonalDataWithIdResponseModel
   */
  ethnicity?: EthnicityEnum;
  /**
   *
   * @type {number}
   * @memberof ProfilePlayerPersonalDataWithIdResponseModel
   */
  highSchoolGraduationYear?: number;
  /**
   *
   * @type {CountryAddModel}
   * @memberof ProfilePlayerPersonalDataWithIdResponseModel
   */
  preferredCountry?: CountryAddModel;
  /**
   *
   * @type {string}
   * @memberof ProfilePlayerPersonalDataWithIdResponseModel
   */
  preferredCity?: string;
  /**
   *
   * @type {StateAddModel}
   * @memberof ProfilePlayerPersonalDataWithIdResponseModel
   */
  preferredState?: StateAddModel;
  /**
   *
   * @type {ApplicationPaymentProcessFetchModelHandicap}
   * @memberof ProfilePlayerPersonalDataWithIdResponseModel
   */
  handicap?: ApplicationPaymentProcessFetchModelHandicap;
  /**
   *
   * @type {string}
   * @memberof ProfilePlayerPersonalDataWithIdResponseModel
   */
  agency?: string;
  /**
   *
   * @type {ApplicationDisabilityTypeEnum}
   * @memberof ProfilePlayerPersonalDataWithIdResponseModel
   */
  disabilityType?: ApplicationDisabilityTypeEnum;
  /**
   *
   * @type {string}
   * @memberof ProfilePlayerPersonalDataWithIdResponseModel
   */
  wR4GDPass?: string;
  /**
   *
   * @type {string}
   * @memberof ProfilePlayerPersonalDataWithIdResponseModel
   */
  disabilityDesc?: string;
  /**
   *
   * @type {boolean}
   * @memberof ProfilePlayerPersonalDataWithIdResponseModel
   */
  legalGuardian?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProfilePlayerPersonalDataWithIdResponseModel
   */
  playGolfInCart?: boolean;
  /**
   *
   * @type {string}
   * @memberof ProfilePlayerPersonalDataWithIdResponseModel
   */
  virtusII1Number?: string;
  /**
   *
   * @type {string}
   * @memberof ProfilePlayerPersonalDataWithIdResponseModel
   */
  adaptiveEquipDesc?: string;
  /**
   *
   * @type {string}
   * @memberof ProfilePlayerPersonalDataWithIdResponseModel
   */
  compPlayingRecord?: string;
  /**
   *
   * @type {number}
   * @memberof ProfilePlayerPersonalDataWithIdResponseModel
   */
  id: number;
  /**
   *
   * @type {PlayerStatusEnum}
   * @memberof ProfilePlayerPersonalDataWithIdResponseModel
   */
  playerStatus: PlayerStatusEnum;
}
/**
 *
 * @export
 * @interface ProfilePlayerPersonalDataWithIdResponseModelAllOf
 */
export interface ProfilePlayerPersonalDataWithIdResponseModelAllOf {
  /**
   *
   * @type {number}
   * @memberof ProfilePlayerPersonalDataWithIdResponseModelAllOf
   */
  id: number;
  /**
   *
   * @type {PlayerStatusEnum}
   * @memberof ProfilePlayerPersonalDataWithIdResponseModelAllOf
   */
  playerStatus: PlayerStatusEnum;
}
/**
 *
 * @export
 * @interface ProfilePlayerRequestModel
 */
export interface ProfilePlayerRequestModel {
  /**
   *
   * @type {ProfilePersonalDataModelPrimaryContactInfo}
   * @memberof ProfilePlayerRequestModel
   */
  primaryContactInfo?: ProfilePersonalDataModelPrimaryContactInfo;
  /**
   *
   * @type {ApplicationBasePlayerModelGuardianContactInfo}
   * @memberof ProfilePlayerRequestModel
   */
  guardianContactInfo?: ApplicationBasePlayerModelGuardianContactInfo;
  /**
   *
   * @type {GenderEnum}
   * @memberof ProfilePlayerRequestModel
   */
  gender?: GenderEnum;
  /**
   *
   * @type {string}
   * @memberof ProfilePlayerRequestModel
   */
  dob?: string;
  /**
   *
   * @type {CountryAddModel}
   * @memberof ProfilePlayerRequestModel
   */
  countryOfCitizenship?: CountryAddModel;
  /**
   *
   * @type {EthnicityEnum}
   * @memberof ProfilePlayerRequestModel
   */
  ethnicity?: EthnicityEnum;
  /**
   *
   * @type {number}
   * @memberof ProfilePlayerRequestModel
   */
  highSchoolGraduationYear?: number;
  /**
   *
   * @type {CountryAddModel}
   * @memberof ProfilePlayerRequestModel
   */
  preferredCountry?: CountryAddModel;
  /**
   *
   * @type {string}
   * @memberof ProfilePlayerRequestModel
   */
  preferredCity?: string;
  /**
   *
   * @type {StateAddModel}
   * @memberof ProfilePlayerRequestModel
   */
  preferredState?: StateAddModel;
  /**
   *
   * @type {ApplicationPaymentProcessFetchModelHandicap}
   * @memberof ProfilePlayerRequestModel
   */
  handicap?: ApplicationPaymentProcessFetchModelHandicap;
  /**
   *
   * @type {string}
   * @memberof ProfilePlayerRequestModel
   */
  agency?: string;
  /**
   *
   * @type {ApplicationDisabilityTypeEnum}
   * @memberof ProfilePlayerRequestModel
   */
  disabilityType?: ApplicationDisabilityTypeEnum;
  /**
   *
   * @type {string}
   * @memberof ProfilePlayerRequestModel
   */
  wR4GDPass?: string;
  /**
   *
   * @type {string}
   * @memberof ProfilePlayerRequestModel
   */
  disabilityDesc?: string;
  /**
   *
   * @type {boolean}
   * @memberof ProfilePlayerRequestModel
   */
  legalGuardian?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProfilePlayerRequestModel
   */
  playGolfInCart?: boolean;
  /**
   *
   * @type {string}
   * @memberof ProfilePlayerRequestModel
   */
  virtusII1Number?: string;
  /**
   *
   * @type {string}
   * @memberof ProfilePlayerRequestModel
   */
  adaptiveEquipDesc?: string;
  /**
   *
   * @type {string}
   * @memberof ProfilePlayerRequestModel
   */
  compPlayingRecord?: string;
  /**
   *
   * @type {PlayerStatusEnum}
   * @memberof ProfilePlayerRequestModel
   */
  playerStatus?: PlayerStatusEnum;
}
/**
 *
 * @export
 * @interface ProfilePlayerRequestModelAllOf
 */
export interface ProfilePlayerRequestModelAllOf {
  /**
   *
   * @type {PlayerStatusEnum}
   * @memberof ProfilePlayerRequestModelAllOf
   */
  playerStatus?: PlayerStatusEnum;
}
/**
 *
 * @export
 * @interface ProfilePlayerResponseModel
 */
export interface ProfilePlayerResponseModel {
  /**
   *
   * @type {ProfilePersonalDataModelPrimaryContactInfo}
   * @memberof ProfilePlayerResponseModel
   */
  primaryContactInfo?: ProfilePersonalDataModelPrimaryContactInfo;
  /**
   *
   * @type {ApplicationBasePlayerModelGuardianContactInfo}
   * @memberof ProfilePlayerResponseModel
   */
  guardianContactInfo?: ApplicationBasePlayerModelGuardianContactInfo;
  /**
   *
   * @type {GenderEnum}
   * @memberof ProfilePlayerResponseModel
   */
  gender?: GenderEnum;
  /**
   *
   * @type {string}
   * @memberof ProfilePlayerResponseModel
   */
  dob?: string;
  /**
   *
   * @type {CountryAddModel}
   * @memberof ProfilePlayerResponseModel
   */
  countryOfCitizenship?: CountryAddModel;
  /**
   *
   * @type {EthnicityEnum}
   * @memberof ProfilePlayerResponseModel
   */
  ethnicity?: EthnicityEnum;
  /**
   *
   * @type {number}
   * @memberof ProfilePlayerResponseModel
   */
  highSchoolGraduationYear?: number;
  /**
   *
   * @type {CountryAddModel}
   * @memberof ProfilePlayerResponseModel
   */
  preferredCountry?: CountryAddModel;
  /**
   *
   * @type {string}
   * @memberof ProfilePlayerResponseModel
   */
  preferredCity?: string;
  /**
   *
   * @type {StateAddModel}
   * @memberof ProfilePlayerResponseModel
   */
  preferredState?: StateAddModel;
  /**
   *
   * @type {ApplicationPaymentProcessFetchModelHandicap}
   * @memberof ProfilePlayerResponseModel
   */
  handicap?: ApplicationPaymentProcessFetchModelHandicap;
  /**
   *
   * @type {string}
   * @memberof ProfilePlayerResponseModel
   */
  agency?: string;
  /**
   *
   * @type {ApplicationDisabilityTypeEnum}
   * @memberof ProfilePlayerResponseModel
   */
  disabilityType?: ApplicationDisabilityTypeEnum;
  /**
   *
   * @type {string}
   * @memberof ProfilePlayerResponseModel
   */
  wR4GDPass?: string;
  /**
   *
   * @type {string}
   * @memberof ProfilePlayerResponseModel
   */
  disabilityDesc?: string;
  /**
   *
   * @type {boolean}
   * @memberof ProfilePlayerResponseModel
   */
  legalGuardian?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProfilePlayerResponseModel
   */
  playGolfInCart?: boolean;
  /**
   *
   * @type {string}
   * @memberof ProfilePlayerResponseModel
   */
  virtusII1Number?: string;
  /**
   *
   * @type {string}
   * @memberof ProfilePlayerResponseModel
   */
  adaptiveEquipDesc?: string;
  /**
   *
   * @type {string}
   * @memberof ProfilePlayerResponseModel
   */
  compPlayingRecord?: string;
  /**
   *
   * @type {PlayerStatusEnum}
   * @memberof ProfilePlayerResponseModel
   */
  playerStatus?: PlayerStatusEnum;
  /**
   *
   * @type {number}
   * @memberof ProfilePlayerResponseModel
   */
  id: number;
  /**
   *
   * @type {ProfilePlayerModelAllOfEmergencyContact}
   * @memberof ProfilePlayerResponseModel
   */
  emergencyContact?: ProfilePlayerModelAllOfEmergencyContact;
}
/**
 *
 * @export
 * @interface ProfilePlayerResponseModelAllOf
 */
export interface ProfilePlayerResponseModelAllOf {
  /**
   *
   * @type {ProfilePlayerModelAllOfEmergencyContact}
   * @memberof ProfilePlayerResponseModelAllOf
   */
  emergencyContact?: ProfilePlayerModelAllOfEmergencyContact;
}
/**
 *
 * @export
 * @interface ProfileReportFetchListModel
 */
export interface ProfileReportFetchListModel {
  /**
   *
   * @type {Array<ProfileReportFetchModel>}
   * @memberof ProfileReportFetchListModel
   */
  items?: Array<ProfileReportFetchModel>;
  /**
   *
   * @type {number}
   * @memberof ProfileReportFetchListModel
   */
  limit?: number;
  /**
   *
   * @type {number}
   * @memberof ProfileReportFetchListModel
   */
  start?: number;
  /**
   *
   * @type {number}
   * @memberof ProfileReportFetchListModel
   */
  total?: number;
}
/**
 *
 * @export
 * @interface ProfileReportFetchModel
 */
export interface ProfileReportFetchModel {
  /**
   *
   * @type {number}
   * @memberof ProfileReportFetchModel
   */
  id?: number;
  /**
   *
   * @type {AppCustomerServiceReportFetchModelPrimaryContactInfo}
   * @memberof ProfileReportFetchModel
   */
  primaryContactInfo?: AppCustomerServiceReportFetchModelPrimaryContactInfo;
  /**
   *
   * @type {ProfileReportFetchModelGuardianContactInfo}
   * @memberof ProfileReportFetchModel
   */
  guardianContactInfo?: ProfileReportFetchModelGuardianContactInfo;
  /**
   *
   * @type {string}
   * @memberof ProfileReportFetchModel
   */
  dob?: string;
  /**
   *
   * @type {PlayerStatusEnum}
   * @memberof ProfileReportFetchModel
   */
  playerStatus?: PlayerStatusEnum;
}
/**
 *
 * @export
 * @interface ProfileReportFetchModelGuardianContactInfo
 */
export interface ProfileReportFetchModelGuardianContactInfo {
  /**
   * agent, parent, guardian email
   * @type {string}
   * @memberof ProfileReportFetchModelGuardianContactInfo
   */
  email?: string;
}
/**
 *
 * @export
 * @interface ProfileUnlinkRequestModel
 */
export interface ProfileUnlinkRequestModel {
  /**
   *
   * @type {number}
   * @memberof ProfileUnlinkRequestModel
   */
  profileId: number;
  /**
   *
   * @type {PlayerRelationTypeEnum}
   * @memberof ProfileUnlinkRequestModel
   */
  relationType: PlayerRelationTypeEnum;
}
/**
 *
 * @export
 * @interface ProfileUnlinkRequestModelAllOf
 */
export interface ProfileUnlinkRequestModelAllOf {
  /**
   *
   * @type {PlayerRelationTypeEnum}
   * @memberof ProfileUnlinkRequestModelAllOf
   */
  relationType: PlayerRelationTypeEnum;
}
/**
 *
 * @export
 * @interface PromoModel
 */
export interface PromoModel {
  /**
   *
   * @type {string}
   * @memberof PromoModel
   */
  type?: PromoModelTypeEnum;
  /**
   *
   * @type {string}
   * @memberof PromoModel
   */
  text?: string;
  /**
   *
   * @type {USGACloudinaryImageModel}
   * @memberof PromoModel
   */
  image?: USGACloudinaryImageModel;
  /**
   *
   * @type {LinkModel}
   * @memberof PromoModel
   */
  link?: LinkModel;
}

/**
 * @export
 * @enum {string}
 */
export enum PromoModelTypeEnum {
  Promo = 'promo',
  Ad = 'ad',
}

/**
 *
 * @export
 * @interface QSCloseReportFetchModel
 */
export interface QSCloseReportFetchModel {
  /**
   *
   * @type {number}
   * @memberof QSCloseReportFetchModel
   */
  id?: number;
  /**
   *
   * @type {Array<QSCloseReportFetchModelApplications>}
   * @memberof QSCloseReportFetchModel
   */
  applications?: Array<QSCloseReportFetchModelApplications>;
  /**
   *
   * @type {Array<QSCloseReportFetchModelWaitList>}
   * @memberof QSCloseReportFetchModel
   */
  waitList?: Array<QSCloseReportFetchModelWaitList>;
  /**
   *
   * @type {QSCloseReportFetchModelChampionship}
   * @memberof QSCloseReportFetchModel
   */
  championship?: QSCloseReportFetchModelChampionship;
  /**
   *
   * @type {QualifyingLevelEnum}
   * @memberof QSCloseReportFetchModel
   */
  level?: QualifyingLevelEnum;
  /**
   *
   * @type {number}
   * @memberof QSCloseReportFetchModel
   */
  number?: number;
  /**
   *
   * @type {Array<ApplicationQualifyingLocationResponseFetchModelQualifyingSiteState>}
   * @memberof QSCloseReportFetchModel
   */
  club?: Array<ApplicationQualifyingLocationResponseFetchModelQualifyingSiteState>;
  /**
   *
   * @type {CountryAddModel}
   * @memberof QSCloseReportFetchModel
   */
  country?: CountryAddModel;
  /**
   *
   * @type {string}
   * @memberof QSCloseReportFetchModel
   */
  city?: string;
  /**
   *
   * @type {StateAddModel}
   * @memberof QSCloseReportFetchModel
   */
  state?: StateAddModel;
}
/**
 *
 * @export
 * @interface QSCloseReportFetchModelApplication
 */
export interface QSCloseReportFetchModelApplication {
  /**
   *
   * @type {number}
   * @memberof QSCloseReportFetchModelApplication
   */
  id: number;
  /**
   *
   * @type {ApplicationProgressionEnum}
   * @memberof QSCloseReportFetchModelApplication
   */
  progression?: ApplicationProgressionEnum;
}
/**
 *
 * @export
 * @interface QSCloseReportFetchModelApplications
 */
export interface QSCloseReportFetchModelApplications {
  /**
   *
   * @type {QSCloseReportFetchModelApplication}
   * @memberof QSCloseReportFetchModelApplications
   */
  application?: QSCloseReportFetchModelApplication;
  /**
   *
   * @type {QualifyingSiteFinalizationEnum}
   * @memberof QSCloseReportFetchModelApplications
   */
  finalizedFinalQSite?: QualifyingSiteFinalizationEnum;
}
/**
 *
 * @export
 * @interface QSCloseReportFetchModelChampionship
 */
export interface QSCloseReportFetchModelChampionship {
  /**
   *
   * @type {string}
   * @memberof QSCloseReportFetchModelChampionship
   */
  title?: string;
  /**
   *
   * @type {ApplicationQualifyingLocationResponseFetchModelChampionshipChampionshipType}
   * @memberof QSCloseReportFetchModelChampionship
   */
  championshipType?: ApplicationQualifyingLocationResponseFetchModelChampionshipChampionshipType;
}
/**
 *
 * @export
 * @interface QSCloseReportFetchModelWaitList
 */
export interface QSCloseReportFetchModelWaitList {
  /**
   *
   * @type {IdModel}
   * @memberof QSCloseReportFetchModelWaitList
   */
  application?: IdModel;
  /**
   *
   * @type {WaitListAlternateTypeEnum}
   * @memberof QSCloseReportFetchModelWaitList
   */
  alternateType?: WaitListAlternateTypeEnum;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum QSCourseAccessEnum {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE',
  SEMIPRIVATE = 'SEMI_PRIVATE',
}

/**
 *
 * @export
 * @interface QSDownloadReportFetchModel
 */
export interface QSDownloadReportFetchModel {
  /**
   *
   * @type {number}
   * @memberof QSDownloadReportFetchModel
   */
  id?: number;
  /**
   *
   * @type {QualifyingLevelEnum}
   * @memberof QSDownloadReportFetchModel
   */
  level?: QualifyingLevelEnum;
  /**
   *
   * @type {number}
   * @memberof QSDownloadReportFetchModel
   */
  number?: number;
  /**
   *
   * @type {Array<ApplicationQualifyingLocationResponseFetchModelQualifyingSiteState>}
   * @memberof QSDownloadReportFetchModel
   */
  club?: Array<ApplicationQualifyingLocationResponseFetchModelQualifyingSiteState>;
  /**
   *
   * @type {string}
   * @memberof QSDownloadReportFetchModel
   */
  city?: string;
  /**
   *
   * @type {CountryAddModel}
   * @memberof QSDownloadReportFetchModel
   */
  country?: CountryAddModel;
  /**
   *
   * @type {StateAddModel}
   * @memberof QSDownloadReportFetchModel
   */
  state?: StateAddModel;
  /**
   *
   * @type {string}
   * @memberof QSDownloadReportFetchModel
   */
  qualifyingDate?: string;
  /**
   *
   * @type {boolean}
   * @memberof QSDownloadReportFetchModel
   */
  qualifyingDateIsTwoDays?: boolean;
  /**
   *
   * @type {QSDownloadReportFetchModelPointOfContact}
   * @memberof QSDownloadReportFetchModel
   */
  pointOfContact?: QSDownloadReportFetchModelPointOfContact;
  /**
   *
   * @type {number}
   * @memberof QSDownloadReportFetchModel
   */
  capacity?: number;
  /**
   *
   * @type {QualifyingSiteSpotsAvailable}
   * @memberof QSDownloadReportFetchModel
   */
  spotsAvailability?: QualifyingSiteSpotsAvailable;
  /**
   *
   * @type {QualifyingSiteStatusEnum}
   * @memberof QSDownloadReportFetchModel
   */
  status?: QualifyingSiteStatusEnum;
  /**
   *
   * @type {ExemptionDeleteFetchModelChampionship}
   * @memberof QSDownloadReportFetchModel
   */
  championship?: ExemptionDeleteFetchModelChampionship;
}
/**
 *
 * @export
 * @interface QSDownloadReportFetchModelPointOfContact
 */
export interface QSDownloadReportFetchModelPointOfContact {
  /**
   *
   * @type {QSDownloadReportFetchModelPointOfContactAga}
   * @memberof QSDownloadReportFetchModelPointOfContact
   */
  aga?: QSDownloadReportFetchModelPointOfContactAga;
}
/**
 *
 * @export
 * @interface QSDownloadReportFetchModelPointOfContactAga
 */
export interface QSDownloadReportFetchModelPointOfContactAga {
  /**
   *
   * @type {string}
   * @memberof QSDownloadReportFetchModelPointOfContactAga
   */
  name?: string;
}
/**
 *
 * @export
 * @interface QSReportFetchModel
 */
export interface QSReportFetchModel {
  /**
   *
   * @type {number}
   * @memberof QSReportFetchModel
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof QSReportFetchModel
   */
  capacity?: number;
  /**
   *
   * @type {QualifyingSiteSpotsAvailable}
   * @memberof QSReportFetchModel
   */
  spotsAvailability?: QualifyingSiteSpotsAvailable;
  /**
   *
   * @type {QSiteCartsAvailableEnum}
   * @memberof QSReportFetchModel
   */
  cartsAvailable?: QSiteCartsAvailableEnum;
  /**
   *
   * @type {string}
   * @memberof QSReportFetchModel
   */
  closedDate?: string;
  /**
   *
   * @type {string}
   * @memberof QSReportFetchModel
   */
  comments?: string;
  /**
   *
   * @type {string}
   * @memberof QSReportFetchModel
   */
  createdDate?: string;
  /**
   *
   * @type {QualifyingLevelEnum}
   * @memberof QSReportFetchModel
   */
  level?: QualifyingLevelEnum;
  /**
   *
   * @type {number}
   * @memberof QSReportFetchModel
   */
  number?: number;
  /**
   *
   * @type {string}
   * @memberof QSReportFetchModel
   */
  practiceInfo?: string;
  /**
   *
   * @type {string}
   * @memberof QSReportFetchModel
   */
  qualifyingDate?: string;
  /**
   *
   * @type {boolean}
   * @memberof QSReportFetchModel
   */
  qualifyingDateIsTwoDays?: boolean;
  /**
   *
   * @type {string}
   * @memberof QSReportFetchModel
   */
  qualifyingWebsite?: string;
  /**
   *
   * @type {string}
   * @memberof QSReportFetchModel
   */
  rainDate?: string;
  /**
   *
   * @type {boolean}
   * @memberof QSReportFetchModel
   */
  rainDateIsTwoDays?: boolean;
  /**
   *
   * @type {string}
   * @memberof QSReportFetchModel
   */
  scoringUrl?: string;
  /**
   *
   * @type {QualifyingSiteStatusEnum}
   * @memberof QSReportFetchModel
   */
  status?: QualifyingSiteStatusEnum;
  /**
   *
   * @type {string}
   * @memberof QSReportFetchModel
   */
  submittedByAGA?: string;
  /**
   *
   * @type {string}
   * @memberof QSReportFetchModel
   */
  submittedByFirstName?: string;
  /**
   *
   * @type {string}
   * @memberof QSReportFetchModel
   */
  submittedByLastName?: string;
  /**
   *
   * @type {ExemptionDeleteFetchModelChampionship}
   * @memberof QSReportFetchModel
   */
  championship?: ExemptionDeleteFetchModelChampionship;
  /**
   *
   * @type {PointOfContactAdminModel}
   * @memberof QSReportFetchModel
   */
  pointOfContact?: PointOfContactAdminModel;
  /**
   *
   * @type {CountryAddModel}
   * @memberof QSReportFetchModel
   */
  country?: CountryAddModel;
  /**
   *
   * @type {string}
   * @memberof QSReportFetchModel
   */
  city?: string;
  /**
   *
   * @type {StateAddModel}
   * @memberof QSReportFetchModel
   */
  state?: StateAddModel;
  /**
   *
   * @type {Array<ClubAdminModel>}
   * @memberof QSReportFetchModel
   */
  club?: Array<ClubAdminModel>;
  /**
   *
   * @type {boolean}
   * @memberof QSReportFetchModel
   */
  pushCart?: boolean;
  /**
   *
   * @type {string}
   * @memberof QSReportFetchModel
   */
  courseAccess?: string;
  /**
   *
   * @type {string}
   * @memberof QSReportFetchModel
   */
  agaAddress?: string;
  /**
   *
   * @type {string}
   * @memberof QSReportFetchModel
   */
  agaAddress2?: string;
  /**
   *
   * @type {string}
   * @memberof QSReportFetchModel
   */
  agaCity?: string;
  /**
   *
   * @type {string}
   * @memberof QSReportFetchModel
   */
  agaState?: string;
  /**
   *
   * @type {string}
   * @memberof QSReportFetchModel
   */
  agaPostCode?: string;
  /**
   *
   * @type {string}
   * @memberof QSReportFetchModel
   */
  agaCountry?: string;
  /**
   *
   * @type {boolean}
   * @memberof QSReportFetchModel
   */
  expertCertifiedRules?: boolean;
  /**
   *
   * @type {string}
   * @memberof QSReportFetchModel
   */
  expertCertifiedRulesName?: string;
  /**
   *
   * @type {string}
   * @memberof QSReportFetchModel
   */
  courseRating?: string;
  /**
   *
   * @type {boolean}
   * @memberof QSReportFetchModel
   */
  strokePolicyViolations?: boolean;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum QSiteCartsAvailableEnum {
  POSITIVE = 'POSITIVE',
  NEGATIVE = 'NEGATIVE',
  CADDIECARTSONLY = 'CADDIE_CARTS_ONLY',
}

/**
 *
 * @export
 * @interface QualifierModel
 */
export interface QualifierModel {
  /**
   *
   * @type {string}
   * @memberof QualifierModel
   */
  status: string;
  /**
   *
   * @type {string}
   * @memberof QualifierModel
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof QualifierModel
   */
  aga?: string;
  /**
   *
   * @type {string}
   * @memberof QualifierModel
   */
  city?: string;
  /**
   *
   * @type {string}
   * @memberof QualifierModel
   */
  phone?: string;
  /**
   *
   * @type {string}
   * @memberof QualifierModel
   */
  cellPhone?: string;
  /**
   *
   * @type {string}
   * @memberof QualifierModel
   */
  businessPhone?: string;
  /**
   *
   * @type {string}
   * @memberof QualifierModel
   */
  email?: string;
}
/**
 * Represents Qualifying Exemption Step Player Appliance Model
 * @export
 * @interface QualifyingExemptionStepPlayerApplianceModel
 */
export interface QualifyingExemptionStepPlayerApplianceModel {
  /**
   *
   * @type {string}
   * @memberof QualifyingExemptionStepPlayerApplianceModel
   */
  stepTitle?: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingExemptionStepPlayerApplianceModel
   */
  buttonText?: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingExemptionStepPlayerApplianceModel
   */
  firstParagraph?: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingExemptionStepPlayerApplianceModel
   */
  secondParagraph?: string;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum QualifyingLevelEnum {
  FIRSTSTAGE = 'FIRST_STAGE',
  FINALSTAGE = 'FINAL_STAGE',
  SINGLESTAGE = 'SINGLE_STAGE',
}

/**
 *
 * @export
 * @interface QualifyingLocationBaseModel
 */
export interface QualifyingLocationBaseModel {
  /**
   *
   * @type {number}
   * @memberof QualifyingLocationBaseModel
   */
  qualifyingSiteId: number;
  /**
   *
   * @type {string}
   * @memberof QualifyingLocationBaseModel
   */
  qualifyingSiteClubName: string;
}
/**
 *
 * @export
 * @interface QualifyingLocationResponseModel
 */
export interface QualifyingLocationResponseModel {
  /**
   *
   * @type {number}
   * @memberof QualifyingLocationResponseModel
   */
  qualifyingSiteId: number;
  /**
   *
   * @type {string}
   * @memberof QualifyingLocationResponseModel
   */
  qualifyingSiteClubName: string;
  /**
   *
   * @type {QualifyingLevelEnum}
   * @memberof QualifyingLocationResponseModel
   */
  level: QualifyingLevelEnum;
  /**
   *
   * @type {number}
   * @memberof QualifyingLocationResponseModel
   */
  position: number;
  /**
   *
   * @type {QualifyingSiteFinalizationEnum}
   * @memberof QualifyingLocationResponseModel
   */
  finalizedFinalQSite?: QualifyingSiteFinalizationEnum;
}
/**
 *
 * @export
 * @interface QualifyingLocationResponseModelAllOf
 */
export interface QualifyingLocationResponseModelAllOf {
  /**
   *
   * @type {QualifyingLevelEnum}
   * @memberof QualifyingLocationResponseModelAllOf
   */
  level: QualifyingLevelEnum;
  /**
   *
   * @type {number}
   * @memberof QualifyingLocationResponseModelAllOf
   */
  position: number;
  /**
   *
   * @type {QualifyingSiteFinalizationEnum}
   * @memberof QualifyingLocationResponseModelAllOf
   */
  finalizedFinalQSite?: QualifyingSiteFinalizationEnum;
}
/**
 * Represents Qualifying Location Step Player Appliance Model
 * @export
 * @interface QualifyingLocationStepPlayerApplianceModel
 */
export interface QualifyingLocationStepPlayerApplianceModel {
  /**
   *
   * @type {string}
   * @memberof QualifyingLocationStepPlayerApplianceModel
   */
  stepTitle?: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingLocationStepPlayerApplianceModel
   */
  text?: string;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum QualifyingResultActionEnum {
  READYTOPIBLISHFILE = 'READY_TO_PIBLISH_FILE',
  DELETEFILE = 'DELETE_FILE',
  PUBLISHHTMLFILE = 'PUBLISH_HTML_FILE',
}

/**
 *
 * @export
 * @interface QualifyingSiteAddModel
 */
export interface QualifyingSiteAddModel {
  /**
   *
   * @type {QualifyingLevelEnum}
   * @memberof QualifyingSiteAddModel
   */
  level: QualifyingLevelEnum;
  /**
   *
   * @type {number}
   * @memberof QualifyingSiteAddModel
   */
  number?: number;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteAddModel
   */
  city: string;
  /**
   *
   * @type {CountryAddModel}
   * @memberof QualifyingSiteAddModel
   */
  country: CountryAddModel;
  /**
   *
   * @type {StateAddModel}
   * @memberof QualifyingSiteAddModel
   */
  state?: StateAddModel;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteAddModel
   */
  createdDate: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteAddModel
   */
  fullCapacityDate?: string;
  /**
   *
   * @type {number}
   * @memberof QualifyingSiteAddModel
   */
  capacity: number;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteAddModel
   */
  practiceInfo: string;
  /**
   *
   * @type {QualifyingSiteSpotsAvailable}
   * @memberof QualifyingSiteAddModel
   */
  spotsAvailability?: QualifyingSiteSpotsAvailable;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteAddModel
   */
  qualifyingDate: string;
  /**
   *
   * @type {boolean}
   * @memberof QualifyingSiteAddModel
   */
  qualifyingDateIsTwoDays?: boolean;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteAddModel
   */
  qualifyingWebsite: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteAddModel
   */
  rainDate: string;
  /**
   *
   * @type {boolean}
   * @memberof QualifyingSiteAddModel
   */
  rainDateIsTwoDays?: boolean;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteAddModel
   */
  closedDate?: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteAddModel
   */
  submittedByFirstName: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteAddModel
   */
  submittedByLastName: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteAddModel
   */
  submittedByAGA: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteAddModel
   */
  comments?: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteAddModel
   */
  scoringUrl?: string;
  /**
   *
   * @type {QSiteCartsAvailableEnum}
   * @memberof QualifyingSiteAddModel
   */
  cartsAvailable?: QSiteCartsAvailableEnum;
  /**
   *
   * @type {boolean}
   * @memberof QualifyingSiteAddModel
   */
  pushCart?: boolean;
  /**
   *
   * @type {QSCourseAccessEnum}
   * @memberof QualifyingSiteAddModel
   */
  courseAccess?: QSCourseAccessEnum;
  /**
   *
   * @type {boolean}
   * @memberof QualifyingSiteAddModel
   */
  expertCertifiedRules?: boolean;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteAddModel
   */
  expertCertifiedRulesName?: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteAddModel
   */
  courseRating?: string;
  /**
   *
   * @type {boolean}
   * @memberof QualifyingSiteAddModel
   */
  strokePolicyViolations?: boolean;
  /**
   *
   * @type {number}
   * @memberof QualifyingSiteAddModel
   */
  waitListCapacity: number;
  /**
   *
   * @type {IdModel}
   * @memberof QualifyingSiteAddModel
   */
  championship: IdModel;
  /**
   *
   * @type {Array<IdModel>}
   * @memberof QualifyingSiteAddModel
   */
  club: Array<IdModel>;
  /**
   *
   * @type {IdModel}
   * @memberof QualifyingSiteAddModel
   */
  pointOfContact: IdModel;
}
/**
 *
 * @export
 * @interface QualifyingSiteAddModelAllOf
 */
export interface QualifyingSiteAddModelAllOf {
  /**
   *
   * @type {IdModel}
   * @memberof QualifyingSiteAddModelAllOf
   */
  championship: IdModel;
  /**
   *
   * @type {Array<IdModel>}
   * @memberof QualifyingSiteAddModelAllOf
   */
  club: Array<IdModel>;
  /**
   *
   * @type {IdModel}
   * @memberof QualifyingSiteAddModelAllOf
   */
  pointOfContact: IdModel;
}
/**
 *
 * @export
 * @interface QualifyingSiteAdminFullModel
 */
export interface QualifyingSiteAdminFullModel {
  /**
   *
   * @type {QualifyingLevelEnum}
   * @memberof QualifyingSiteAdminFullModel
   */
  level: QualifyingLevelEnum;
  /**
   *
   * @type {number}
   * @memberof QualifyingSiteAdminFullModel
   */
  number?: number;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteAdminFullModel
   */
  city: string;
  /**
   *
   * @type {CountryAddModel}
   * @memberof QualifyingSiteAdminFullModel
   */
  country: CountryAddModel;
  /**
   *
   * @type {StateAddModel}
   * @memberof QualifyingSiteAdminFullModel
   */
  state?: StateAddModel;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteAdminFullModel
   */
  createdDate: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteAdminFullModel
   */
  fullCapacityDate?: string;
  /**
   *
   * @type {number}
   * @memberof QualifyingSiteAdminFullModel
   */
  capacity: number;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteAdminFullModel
   */
  practiceInfo: string;
  /**
   *
   * @type {QualifyingSiteSpotsAvailable}
   * @memberof QualifyingSiteAdminFullModel
   */
  spotsAvailability?: QualifyingSiteSpotsAvailable;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteAdminFullModel
   */
  qualifyingDate: string;
  /**
   *
   * @type {boolean}
   * @memberof QualifyingSiteAdminFullModel
   */
  qualifyingDateIsTwoDays?: boolean;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteAdminFullModel
   */
  qualifyingWebsite: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteAdminFullModel
   */
  rainDate: string;
  /**
   *
   * @type {boolean}
   * @memberof QualifyingSiteAdminFullModel
   */
  rainDateIsTwoDays?: boolean;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteAdminFullModel
   */
  closedDate?: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteAdminFullModel
   */
  submittedByFirstName: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteAdminFullModel
   */
  submittedByLastName: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteAdminFullModel
   */
  submittedByAGA: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteAdminFullModel
   */
  comments?: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteAdminFullModel
   */
  scoringUrl?: string;
  /**
   *
   * @type {QSiteCartsAvailableEnum}
   * @memberof QualifyingSiteAdminFullModel
   */
  cartsAvailable?: QSiteCartsAvailableEnum;
  /**
   *
   * @type {boolean}
   * @memberof QualifyingSiteAdminFullModel
   */
  pushCart?: boolean;
  /**
   *
   * @type {QSCourseAccessEnum}
   * @memberof QualifyingSiteAdminFullModel
   */
  courseAccess?: QSCourseAccessEnum;
  /**
   *
   * @type {boolean}
   * @memberof QualifyingSiteAdminFullModel
   */
  expertCertifiedRules?: boolean;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteAdminFullModel
   */
  expertCertifiedRulesName?: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteAdminFullModel
   */
  courseRating?: string;
  /**
   *
   * @type {boolean}
   * @memberof QualifyingSiteAdminFullModel
   */
  strokePolicyViolations?: boolean;
  /**
   *
   * @type {number}
   * @memberof QualifyingSiteAdminFullModel
   */
  waitListCapacity: number;
  /**
   *
   * @type {number}
   * @memberof QualifyingSiteAdminFullModel
   */
  id: number;
  /**
   *
   * @type {QualifyingSiteStatusEnum}
   * @memberof QualifyingSiteAdminFullModel
   */
  status: QualifyingSiteStatusEnum;
  /**
   *
   * @type {Array<ClubAdminModel>}
   * @memberof QualifyingSiteAdminFullModel
   */
  club: Array<ClubAdminModel>;
  /**
   *
   * @type {PointOfContactAdminModel}
   * @memberof QualifyingSiteAdminFullModel
   */
  pointOfContact: PointOfContactAdminModel;
  /**
   *
   * @type {QualifyingSiteAdminModelAllOfChampionship}
   * @memberof QualifyingSiteAdminFullModel
   */
  championship: QualifyingSiteAdminModelAllOfChampionship;
  /**
   *
   * @type {QualifyingSiteWaitListAvailable}
   * @memberof QualifyingSiteAdminFullModel
   */
  waitListAvailability?: QualifyingSiteWaitListAvailable;
  /**
   *
   * @type {Array<QualifyingSiteAdminFullModelAllOfWaitList>}
   * @memberof QualifyingSiteAdminFullModel
   */
  waitList?: Array<QualifyingSiteAdminFullModelAllOfWaitList>;
}
/**
 *
 * @export
 * @interface QualifyingSiteAdminFullModelAllOf
 */
export interface QualifyingSiteAdminFullModelAllOf {
  /**
   *
   * @type {number}
   * @memberof QualifyingSiteAdminFullModelAllOf
   */
  id?: number;
  /**
   *
   * @type {QualifyingSiteWaitListAvailable}
   * @memberof QualifyingSiteAdminFullModelAllOf
   */
  waitListAvailability?: QualifyingSiteWaitListAvailable;
  /**
   *
   * @type {Array<QualifyingSiteAdminFullModelAllOfWaitList>}
   * @memberof QualifyingSiteAdminFullModelAllOf
   */
  waitList?: Array<QualifyingSiteAdminFullModelAllOfWaitList>;
}
/**
 *
 * @export
 * @interface QualifyingSiteAdminFullModelAllOfApplication
 */
export interface QualifyingSiteAdminFullModelAllOfApplication {
  /**
   *
   * @type {number}
   * @memberof QualifyingSiteAdminFullModelAllOfApplication
   */
  id: number;
  /**
   *
   * @type {QualifyingSiteFetchModelApplicationPayment}
   * @memberof QualifyingSiteAdminFullModelAllOfApplication
   */
  payment?: QualifyingSiteFetchModelApplicationPayment;
  /**
   *
   * @type {QualifyingSiteFetchModelApplicationPrimaryContactInfo}
   * @memberof QualifyingSiteAdminFullModelAllOfApplication
   */
  primaryContactInfo: QualifyingSiteFetchModelApplicationPrimaryContactInfo;
  /**
   *
   * @type {ApplicationProgressionEnum}
   * @memberof QualifyingSiteAdminFullModelAllOfApplication
   */
  progression: ApplicationProgressionEnum;
}
/**
 *
 * @export
 * @interface QualifyingSiteAdminFullModelAllOfWaitList
 */
export interface QualifyingSiteAdminFullModelAllOfWaitList {
  /**
   *
   * @type {number}
   * @memberof QualifyingSiteAdminFullModelAllOfWaitList
   */
  priority?: number;
  /**
   *
   * @type {QualifyingSiteAdminFullModelAllOfApplication}
   * @memberof QualifyingSiteAdminFullModelAllOfWaitList
   */
  application: QualifyingSiteAdminFullModelAllOfApplication;
  /**
   *
   * @type {WaitListAlternateTypeEnum}
   * @memberof QualifyingSiteAdminFullModelAllOfWaitList
   */
  alternateType?: WaitListAlternateTypeEnum;
}
/**
 *
 * @export
 * @interface QualifyingSiteAdminLightWeightModel
 */
export interface QualifyingSiteAdminLightWeightModel {
  /**
   *
   * @type {number}
   * @memberof QualifyingSiteAdminLightWeightModel
   */
  id: number;
  /**
   *
   * @type {QualifyingLevelEnum}
   * @memberof QualifyingSiteAdminLightWeightModel
   */
  level: QualifyingLevelEnum;
  /**
   *
   * @type {PointOfContactAdminModel}
   * @memberof QualifyingSiteAdminLightWeightModel
   */
  pointOfContact: PointOfContactAdminModel;
  /**
   *
   * @type {QualifyingSiteSpotsAvailable}
   * @memberof QualifyingSiteAdminLightWeightModel
   */
  spotsAvailability?: QualifyingSiteSpotsAvailable;
  /**
   *
   * @type {QualifyingSiteWaitListAvailable}
   * @memberof QualifyingSiteAdminLightWeightModel
   */
  waitListAvailability?: QualifyingSiteWaitListAvailable;
  /**
   *
   * @type {number}
   * @memberof QualifyingSiteAdminLightWeightModel
   */
  waitListCapacity?: number;
  /**
   *
   * @type {Array<QualifyingSiteAdminLightWeightModelClub>}
   * @memberof QualifyingSiteAdminLightWeightModel
   */
  club: Array<QualifyingSiteAdminLightWeightModelClub>;
  /**
   *
   * @type {number}
   * @memberof QualifyingSiteAdminLightWeightModel
   */
  number?: number;
  /**
   *
   * @type {CountryAddModel}
   * @memberof QualifyingSiteAdminLightWeightModel
   */
  country: CountryAddModel;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteAdminLightWeightModel
   */
  city: string;
  /**
   *
   * @type {StateAddModel}
   * @memberof QualifyingSiteAdminLightWeightModel
   */
  state?: StateAddModel;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteAdminLightWeightModel
   */
  qualifyingDate: string;
  /**
   *
   * @type {QualifyingSiteStatusEnum}
   * @memberof QualifyingSiteAdminLightWeightModel
   */
  status: QualifyingSiteStatusEnum;
  /**
   *
   * @type {boolean}
   * @memberof QualifyingSiteAdminLightWeightModel
   */
  qualifyingDateIsTwoDays?: boolean;
}
/**
 *
 * @export
 * @interface QualifyingSiteAdminLightWeightModelClub
 */
export interface QualifyingSiteAdminLightWeightModelClub {
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteAdminLightWeightModelClub
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteAdminLightWeightModelClub
   */
  courseName?: string;
}
/**
 *
 * @export
 * @interface QualifyingSiteAdminListLightWeightModel
 */
export interface QualifyingSiteAdminListLightWeightModel {
  /**
   *
   * @type {Array<QualifyingSiteAdminLightWeightModel>}
   * @memberof QualifyingSiteAdminListLightWeightModel
   */
  items: Array<QualifyingSiteAdminLightWeightModel>;
  /**
   *
   * @type {number}
   * @memberof QualifyingSiteAdminListLightWeightModel
   */
  limit: number;
  /**
   *
   * @type {number}
   * @memberof QualifyingSiteAdminListLightWeightModel
   */
  start: number;
  /**
   *
   * @type {number}
   * @memberof QualifyingSiteAdminListLightWeightModel
   */
  total: number;
}
/**
 *
 * @export
 * @interface QualifyingSiteAdminListModel
 */
export interface QualifyingSiteAdminListModel {
  /**
   *
   * @type {Array<QualifyingSiteAdminFullModel>}
   * @memberof QualifyingSiteAdminListModel
   */
  items: Array<QualifyingSiteAdminFullModel>;
  /**
   *
   * @type {number}
   * @memberof QualifyingSiteAdminListModel
   */
  limit: number;
  /**
   *
   * @type {number}
   * @memberof QualifyingSiteAdminListModel
   */
  start: number;
  /**
   *
   * @type {number}
   * @memberof QualifyingSiteAdminListModel
   */
  total: number;
}
/**
 *
 * @export
 * @interface QualifyingSiteAdminModel
 */
export interface QualifyingSiteAdminModel {
  /**
   *
   * @type {QualifyingLevelEnum}
   * @memberof QualifyingSiteAdminModel
   */
  level: QualifyingLevelEnum;
  /**
   *
   * @type {number}
   * @memberof QualifyingSiteAdminModel
   */
  number?: number;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteAdminModel
   */
  city: string;
  /**
   *
   * @type {CountryAddModel}
   * @memberof QualifyingSiteAdminModel
   */
  country: CountryAddModel;
  /**
   *
   * @type {StateAddModel}
   * @memberof QualifyingSiteAdminModel
   */
  state?: StateAddModel;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteAdminModel
   */
  createdDate: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteAdminModel
   */
  fullCapacityDate?: string;
  /**
   *
   * @type {number}
   * @memberof QualifyingSiteAdminModel
   */
  capacity: number;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteAdminModel
   */
  practiceInfo: string;
  /**
   *
   * @type {QualifyingSiteSpotsAvailable}
   * @memberof QualifyingSiteAdminModel
   */
  spotsAvailability?: QualifyingSiteSpotsAvailable;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteAdminModel
   */
  qualifyingDate: string;
  /**
   *
   * @type {boolean}
   * @memberof QualifyingSiteAdminModel
   */
  qualifyingDateIsTwoDays?: boolean;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteAdminModel
   */
  qualifyingWebsite: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteAdminModel
   */
  rainDate: string;
  /**
   *
   * @type {boolean}
   * @memberof QualifyingSiteAdminModel
   */
  rainDateIsTwoDays?: boolean;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteAdminModel
   */
  closedDate?: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteAdminModel
   */
  submittedByFirstName: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteAdminModel
   */
  submittedByLastName: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteAdminModel
   */
  submittedByAGA: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteAdminModel
   */
  comments?: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteAdminModel
   */
  scoringUrl?: string;
  /**
   *
   * @type {QSiteCartsAvailableEnum}
   * @memberof QualifyingSiteAdminModel
   */
  cartsAvailable?: QSiteCartsAvailableEnum;
  /**
   *
   * @type {boolean}
   * @memberof QualifyingSiteAdminModel
   */
  pushCart?: boolean;
  /**
   *
   * @type {QSCourseAccessEnum}
   * @memberof QualifyingSiteAdminModel
   */
  courseAccess?: QSCourseAccessEnum;
  /**
   *
   * @type {boolean}
   * @memberof QualifyingSiteAdminModel
   */
  expertCertifiedRules?: boolean;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteAdminModel
   */
  expertCertifiedRulesName?: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteAdminModel
   */
  courseRating?: string;
  /**
   *
   * @type {boolean}
   * @memberof QualifyingSiteAdminModel
   */
  strokePolicyViolations?: boolean;
  /**
   *
   * @type {number}
   * @memberof QualifyingSiteAdminModel
   */
  waitListCapacity: number;
  /**
   *
   * @type {number}
   * @memberof QualifyingSiteAdminModel
   */
  id: number;
  /**
   *
   * @type {QualifyingSiteStatusEnum}
   * @memberof QualifyingSiteAdminModel
   */
  status: QualifyingSiteStatusEnum;
  /**
   *
   * @type {Array<ClubAdminModel>}
   * @memberof QualifyingSiteAdminModel
   */
  club: Array<ClubAdminModel>;
  /**
   *
   * @type {PointOfContactAdminModel}
   * @memberof QualifyingSiteAdminModel
   */
  pointOfContact: PointOfContactAdminModel;
  /**
   *
   * @type {QualifyingSiteAdminModelAllOfChampionship}
   * @memberof QualifyingSiteAdminModel
   */
  championship: QualifyingSiteAdminModelAllOfChampionship;
}
/**
 *
 * @export
 * @interface QualifyingSiteAdminModelAllOf
 */
export interface QualifyingSiteAdminModelAllOf {
  /**
   *
   * @type {Array<ClubAdminModel>}
   * @memberof QualifyingSiteAdminModelAllOf
   */
  club: Array<ClubAdminModel>;
  /**
   *
   * @type {PointOfContactAdminModel}
   * @memberof QualifyingSiteAdminModelAllOf
   */
  pointOfContact: PointOfContactAdminModel;
  /**
   *
   * @type {QualifyingSiteAdminModelAllOfChampionship}
   * @memberof QualifyingSiteAdminModelAllOf
   */
  championship: QualifyingSiteAdminModelAllOfChampionship;
}
/**
 *
 * @export
 * @interface QualifyingSiteAdminModelAllOfChampionship
 */
export interface QualifyingSiteAdminModelAllOfChampionship {
  /**
   *
   * @type {number}
   * @memberof QualifyingSiteAdminModelAllOfChampionship
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteAdminModelAllOfChampionship
   */
  title?: string;
  /**
   *
   * @type {number}
   * @memberof QualifyingSiteAdminModelAllOfChampionship
   */
  year?: number;
  /**
   *
   * @type {number}
   * @memberof QualifyingSiteAdminModelAllOfChampionship
   */
  waitlistFee?: number;
}
/**
 *
 * @export
 * @interface QualifyingSiteBaseModel
 */
export interface QualifyingSiteBaseModel {
  /**
   *
   * @type {QualifyingLevelEnum}
   * @memberof QualifyingSiteBaseModel
   */
  level: QualifyingLevelEnum;
  /**
   *
   * @type {number}
   * @memberof QualifyingSiteBaseModel
   */
  number?: number;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteBaseModel
   */
  city: string;
  /**
   *
   * @type {CountryAddModel}
   * @memberof QualifyingSiteBaseModel
   */
  country: CountryAddModel;
  /**
   *
   * @type {StateAddModel}
   * @memberof QualifyingSiteBaseModel
   */
  state?: StateAddModel;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteBaseModel
   */
  createdDate: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteBaseModel
   */
  fullCapacityDate?: string;
  /**
   *
   * @type {number}
   * @memberof QualifyingSiteBaseModel
   */
  capacity: number;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteBaseModel
   */
  practiceInfo: string;
  /**
   *
   * @type {QualifyingSiteSpotsAvailable}
   * @memberof QualifyingSiteBaseModel
   */
  spotsAvailability?: QualifyingSiteSpotsAvailable;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteBaseModel
   */
  qualifyingDate: string;
  /**
   *
   * @type {boolean}
   * @memberof QualifyingSiteBaseModel
   */
  qualifyingDateIsTwoDays?: boolean;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteBaseModel
   */
  qualifyingWebsite: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteBaseModel
   */
  rainDate: string;
  /**
   *
   * @type {boolean}
   * @memberof QualifyingSiteBaseModel
   */
  rainDateIsTwoDays?: boolean;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteBaseModel
   */
  closedDate?: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteBaseModel
   */
  submittedByFirstName: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteBaseModel
   */
  submittedByLastName: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteBaseModel
   */
  submittedByAGA: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteBaseModel
   */
  comments?: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteBaseModel
   */
  scoringUrl?: string;
  /**
   *
   * @type {QSiteCartsAvailableEnum}
   * @memberof QualifyingSiteBaseModel
   */
  cartsAvailable?: QSiteCartsAvailableEnum;
  /**
   *
   * @type {boolean}
   * @memberof QualifyingSiteBaseModel
   */
  pushCart?: boolean;
  /**
   *
   * @type {QSCourseAccessEnum}
   * @memberof QualifyingSiteBaseModel
   */
  courseAccess?: QSCourseAccessEnum;
  /**
   *
   * @type {boolean}
   * @memberof QualifyingSiteBaseModel
   */
  expertCertifiedRules?: boolean;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteBaseModel
   */
  expertCertifiedRulesName?: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteBaseModel
   */
  courseRating?: string;
  /**
   *
   * @type {boolean}
   * @memberof QualifyingSiteBaseModel
   */
  strokePolicyViolations?: boolean;
  /**
   *
   * @type {number}
   * @memberof QualifyingSiteBaseModel
   */
  waitListCapacity: number;
}
/**
 *
 * @export
 * @interface QualifyingSiteCommonModel
 */
export interface QualifyingSiteCommonModel {
  /**
   *
   * @type {QualifyingLevelEnum}
   * @memberof QualifyingSiteCommonModel
   */
  level: QualifyingLevelEnum;
  /**
   *
   * @type {number}
   * @memberof QualifyingSiteCommonModel
   */
  number?: number;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteCommonModel
   */
  city: string;
  /**
   *
   * @type {CountryAddModel}
   * @memberof QualifyingSiteCommonModel
   */
  country: CountryAddModel;
  /**
   *
   * @type {StateAddModel}
   * @memberof QualifyingSiteCommonModel
   */
  state?: StateAddModel;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteCommonModel
   */
  createdDate: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteCommonModel
   */
  fullCapacityDate?: string;
  /**
   *
   * @type {number}
   * @memberof QualifyingSiteCommonModel
   */
  capacity: number;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteCommonModel
   */
  practiceInfo: string;
  /**
   *
   * @type {QualifyingSiteSpotsAvailable}
   * @memberof QualifyingSiteCommonModel
   */
  spotsAvailability?: QualifyingSiteSpotsAvailable;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteCommonModel
   */
  qualifyingDate: string;
  /**
   *
   * @type {boolean}
   * @memberof QualifyingSiteCommonModel
   */
  qualifyingDateIsTwoDays?: boolean;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteCommonModel
   */
  qualifyingWebsite: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteCommonModel
   */
  rainDate: string;
  /**
   *
   * @type {boolean}
   * @memberof QualifyingSiteCommonModel
   */
  rainDateIsTwoDays?: boolean;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteCommonModel
   */
  closedDate?: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteCommonModel
   */
  submittedByFirstName: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteCommonModel
   */
  submittedByLastName: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteCommonModel
   */
  submittedByAGA: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteCommonModel
   */
  comments?: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteCommonModel
   */
  scoringUrl?: string;
  /**
   *
   * @type {QSiteCartsAvailableEnum}
   * @memberof QualifyingSiteCommonModel
   */
  cartsAvailable?: QSiteCartsAvailableEnum;
  /**
   *
   * @type {boolean}
   * @memberof QualifyingSiteCommonModel
   */
  pushCart?: boolean;
  /**
   *
   * @type {QSCourseAccessEnum}
   * @memberof QualifyingSiteCommonModel
   */
  courseAccess?: QSCourseAccessEnum;
  /**
   *
   * @type {boolean}
   * @memberof QualifyingSiteCommonModel
   */
  expertCertifiedRules?: boolean;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteCommonModel
   */
  expertCertifiedRulesName?: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteCommonModel
   */
  courseRating?: string;
  /**
   *
   * @type {boolean}
   * @memberof QualifyingSiteCommonModel
   */
  strokePolicyViolations?: boolean;
  /**
   *
   * @type {number}
   * @memberof QualifyingSiteCommonModel
   */
  waitListCapacity: number;
  /**
   *
   * @type {number}
   * @memberof QualifyingSiteCommonModel
   */
  id: number;
  /**
   *
   * @type {QualifyingSiteStatusEnum}
   * @memberof QualifyingSiteCommonModel
   */
  status: QualifyingSiteStatusEnum;
  /**
   *
   * @type {Array<ClubAdminModel>}
   * @memberof QualifyingSiteCommonModel
   */
  club?: Array<ClubAdminModel>;
}
/**
 *
 * @export
 * @interface QualifyingSiteCommonModelAllOf
 */
export interface QualifyingSiteCommonModelAllOf {
  /**
   *
   * @type {Array<ClubAdminModel>}
   * @memberof QualifyingSiteCommonModelAllOf
   */
  club?: Array<ClubAdminModel>;
}
/**
 *
 * @export
 * @interface QualifyingSiteEditModel
 */
export interface QualifyingSiteEditModel {
  /**
   *
   * @type {QualifyingLevelEnum}
   * @memberof QualifyingSiteEditModel
   */
  level: QualifyingLevelEnum;
  /**
   *
   * @type {number}
   * @memberof QualifyingSiteEditModel
   */
  number?: number;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteEditModel
   */
  city: string;
  /**
   *
   * @type {CountryAddModel}
   * @memberof QualifyingSiteEditModel
   */
  country: CountryAddModel;
  /**
   *
   * @type {StateAddModel}
   * @memberof QualifyingSiteEditModel
   */
  state?: StateAddModel;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteEditModel
   */
  createdDate: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteEditModel
   */
  fullCapacityDate?: string;
  /**
   *
   * @type {number}
   * @memberof QualifyingSiteEditModel
   */
  capacity: number;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteEditModel
   */
  practiceInfo: string;
  /**
   *
   * @type {QualifyingSiteSpotsAvailable}
   * @memberof QualifyingSiteEditModel
   */
  spotsAvailability?: QualifyingSiteSpotsAvailable;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteEditModel
   */
  qualifyingDate: string;
  /**
   *
   * @type {boolean}
   * @memberof QualifyingSiteEditModel
   */
  qualifyingDateIsTwoDays?: boolean;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteEditModel
   */
  qualifyingWebsite: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteEditModel
   */
  rainDate: string;
  /**
   *
   * @type {boolean}
   * @memberof QualifyingSiteEditModel
   */
  rainDateIsTwoDays?: boolean;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteEditModel
   */
  closedDate?: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteEditModel
   */
  submittedByFirstName: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteEditModel
   */
  submittedByLastName: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteEditModel
   */
  submittedByAGA: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteEditModel
   */
  comments?: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteEditModel
   */
  scoringUrl?: string;
  /**
   *
   * @type {QSiteCartsAvailableEnum}
   * @memberof QualifyingSiteEditModel
   */
  cartsAvailable?: QSiteCartsAvailableEnum;
  /**
   *
   * @type {boolean}
   * @memberof QualifyingSiteEditModel
   */
  pushCart?: boolean;
  /**
   *
   * @type {QSCourseAccessEnum}
   * @memberof QualifyingSiteEditModel
   */
  courseAccess?: QSCourseAccessEnum;
  /**
   *
   * @type {boolean}
   * @memberof QualifyingSiteEditModel
   */
  expertCertifiedRules?: boolean;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteEditModel
   */
  expertCertifiedRulesName?: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteEditModel
   */
  courseRating?: string;
  /**
   *
   * @type {boolean}
   * @memberof QualifyingSiteEditModel
   */
  strokePolicyViolations?: boolean;
  /**
   *
   * @type {number}
   * @memberof QualifyingSiteEditModel
   */
  waitListCapacity: number;
  /**
   *
   * @type {IdModel}
   * @memberof QualifyingSiteEditModel
   */
  championship: IdModel;
  /**
   *
   * @type {Array<IdModel>}
   * @memberof QualifyingSiteEditModel
   */
  club: Array<IdModel>;
  /**
   *
   * @type {IdModel}
   * @memberof QualifyingSiteEditModel
   */
  pointOfContact: IdModel;
  /**
   *
   * @type {number}
   * @memberof QualifyingSiteEditModel
   */
  id: number;
  /**
   *
   * @type {QualifyingSiteStatusEnum}
   * @memberof QualifyingSiteEditModel
   */
  status: QualifyingSiteStatusEnum;
  /**
   *
   * @type {Array<QualifyingSiteEditModelAllOfWaitList>}
   * @memberof QualifyingSiteEditModel
   */
  waitList?: Array<QualifyingSiteEditModelAllOfWaitList>;
}
/**
 *
 * @export
 * @interface QualifyingSiteEditModelAllOf
 */
export interface QualifyingSiteEditModelAllOf {
  /**
   *
   * @type {number}
   * @memberof QualifyingSiteEditModelAllOf
   */
  id: number;
  /**
   *
   * @type {QualifyingSiteStatusEnum}
   * @memberof QualifyingSiteEditModelAllOf
   */
  status: QualifyingSiteStatusEnum;
  /**
   *
   * @type {Array<QualifyingSiteEditModelAllOfWaitList>}
   * @memberof QualifyingSiteEditModelAllOf
   */
  waitList?: Array<QualifyingSiteEditModelAllOfWaitList>;
}
/**
 *
 * @export
 * @interface QualifyingSiteEditModelAllOfWaitList
 */
export interface QualifyingSiteEditModelAllOfWaitList {
  /**
   *
   * @type {IdModel}
   * @memberof QualifyingSiteEditModelAllOfWaitList
   */
  application: IdModel;
  /**
   *
   * @type {WaitListAlternateTypeEnum}
   * @memberof QualifyingSiteEditModelAllOfWaitList
   */
  alternateType?: WaitListAlternateTypeEnum;
}
/**
 *
 * @export
 * @interface QualifyingSiteFetchModel
 */
export interface QualifyingSiteFetchModel {
  /**
   *
   * @type {number}
   * @memberof QualifyingSiteFetchModel
   */
  id?: number;
  /**
   *
   * @type {QualifyingSiteStatusEnum}
   * @memberof QualifyingSiteFetchModel
   */
  status?: QualifyingSiteStatusEnum;
  /**
   *
   * @type {QualifyingLevelEnum}
   * @memberof QualifyingSiteFetchModel
   */
  level?: QualifyingLevelEnum;
  /**
   *
   * @type {number}
   * @memberof QualifyingSiteFetchModel
   */
  number?: number;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteFetchModel
   */
  city?: string;
  /**
   *
   * @type {CountryAddModel}
   * @memberof QualifyingSiteFetchModel
   */
  country?: CountryAddModel;
  /**
   *
   * @type {StateAddModel}
   * @memberof QualifyingSiteFetchModel
   */
  state?: StateAddModel;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteFetchModel
   */
  createdDate?: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteFetchModel
   */
  fullCapacityDate?: string;
  /**
   *
   * @type {number}
   * @memberof QualifyingSiteFetchModel
   */
  capacity?: number;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteFetchModel
   */
  practiceInfo?: string;
  /**
   *
   * @type {QualifyingSiteSpotsAvailable}
   * @memberof QualifyingSiteFetchModel
   */
  spotsAvailability?: QualifyingSiteSpotsAvailable;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteFetchModel
   */
  qualifyingDate?: string;
  /**
   *
   * @type {boolean}
   * @memberof QualifyingSiteFetchModel
   */
  qualifyingDateIsTwoDays?: boolean;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteFetchModel
   */
  qualifyingWebsite?: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteFetchModel
   */
  rainDate?: string;
  /**
   *
   * @type {boolean}
   * @memberof QualifyingSiteFetchModel
   */
  rainDateIsTwoDays?: boolean;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteFetchModel
   */
  closedDate?: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteFetchModel
   */
  submittedByFirstName?: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteFetchModel
   */
  submittedByLastName?: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteFetchModel
   */
  submittedByAGA?: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteFetchModel
   */
  comments?: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteFetchModel
   */
  scoringUrl?: string;
  /**
   *
   * @type {QSiteCartsAvailableEnum}
   * @memberof QualifyingSiteFetchModel
   */
  cartsAvailable?: QSiteCartsAvailableEnum;
  /**
   *
   * @type {boolean}
   * @memberof QualifyingSiteFetchModel
   */
  pushCart?: boolean;
  /**
   *
   * @type {QSCourseAccessEnum}
   * @memberof QualifyingSiteFetchModel
   */
  courseAccess?: QSCourseAccessEnum;
  /**
   *
   * @type {number}
   * @memberof QualifyingSiteFetchModel
   */
  waitListCapacity?: number;
  /**
   *
   * @type {QualifyingSiteWaitListAvailable}
   * @memberof QualifyingSiteFetchModel
   */
  waitListAvailability?: QualifyingSiteWaitListAvailable;
  /**
   *
   * @type {Array<QualifyingSiteFetchModelWaitList>}
   * @memberof QualifyingSiteFetchModel
   */
  waitList?: Array<QualifyingSiteFetchModelWaitList>;
  /**
   *
   * @type {QualifyingSiteFetchModelChampionship}
   * @memberof QualifyingSiteFetchModel
   */
  championship?: QualifyingSiteFetchModelChampionship;
  /**
   *
   * @type {Array<ClubAdminModel>}
   * @memberof QualifyingSiteFetchModel
   */
  club?: Array<ClubAdminModel>;
  /**
   *
   * @type {PointOfContactAdminModel}
   * @memberof QualifyingSiteFetchModel
   */
  pointOfContact?: PointOfContactAdminModel;
  /**
   *
   * @type {number}
   * @memberof QualifyingSiteFetchModel
   */
  rank?: number;
  /**
   *
   * @type {number}
   * @memberof QualifyingSiteFetchModel
   */
  slotReserved?: number;
  /**
   *
   * @type {number}
   * @memberof QualifyingSiteFetchModel
   */
  waitListSlotReserved?: number;
  /**
   *
   * @type {boolean}
   * @memberof QualifyingSiteFetchModel
   */
  expertCertifiedRules?: boolean;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteFetchModel
   */
  expertCertifiedRulesName?: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteFetchModel
   */
  courseRating?: string;
  /**
   *
   * @type {boolean}
   * @memberof QualifyingSiteFetchModel
   */
  strokePolicyViolations?: boolean;
}
/**
 *
 * @export
 * @interface QualifyingSiteFetchModelApplication
 */
export interface QualifyingSiteFetchModelApplication {
  /**
   *
   * @type {number}
   * @memberof QualifyingSiteFetchModelApplication
   */
  id: number;
  /**
   *
   * @type {QualifyingSiteFetchModelApplicationAudit}
   * @memberof QualifyingSiteFetchModelApplication
   */
  audit?: QualifyingSiteFetchModelApplicationAudit;
  /**
   *
   * @type {QualifyingSiteFetchModelApplicationPayment}
   * @memberof QualifyingSiteFetchModelApplication
   */
  payment?: QualifyingSiteFetchModelApplicationPayment;
  /**
   *
   * @type {QualifyingSiteFetchModelApplicationPrimaryContactInfo}
   * @memberof QualifyingSiteFetchModelApplication
   */
  primaryContactInfo: QualifyingSiteFetchModelApplicationPrimaryContactInfo;
  /**
   *
   * @type {ApplicationProgressionEnum}
   * @memberof QualifyingSiteFetchModelApplication
   */
  progression: ApplicationProgressionEnum;
  /**
   *
   * @type {IdModel}
   * @memberof QualifyingSiteFetchModelApplication
   */
  team?: IdModel;
}
/**
 *
 * @export
 * @interface QualifyingSiteFetchModelApplicationAudit
 */
export interface QualifyingSiteFetchModelApplicationAudit {
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteFetchModelApplicationAudit
   */
  dateCreated?: string;
}
/**
 *
 * @export
 * @interface QualifyingSiteFetchModelApplicationPayment
 */
export interface QualifyingSiteFetchModelApplicationPayment {
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteFetchModelApplicationPayment
   */
  auth_time?: string;
}
/**
 * use this as read-only
 * @export
 * @interface QualifyingSiteFetchModelApplicationPrimaryContactInfo
 */
export interface QualifyingSiteFetchModelApplicationPrimaryContactInfo {
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteFetchModelApplicationPrimaryContactInfo
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteFetchModelApplicationPrimaryContactInfo
   */
  lastName?: string;
}
/**
 *
 * @export
 * @interface QualifyingSiteFetchModelChampionship
 */
export interface QualifyingSiteFetchModelChampionship {
  /**
   *
   * @type {number}
   * @memberof QualifyingSiteFetchModelChampionship
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteFetchModelChampionship
   */
  title?: string;
  /**
   *
   * @type {number}
   * @memberof QualifyingSiteFetchModelChampionship
   */
  waitlistFee?: number;
}
/**
 *
 * @export
 * @interface QualifyingSiteFetchModelWaitList
 */
export interface QualifyingSiteFetchModelWaitList {
  /**
   *
   * @type {number}
   * @memberof QualifyingSiteFetchModelWaitList
   */
  priority?: number;
  /**
   *
   * @type {QualifyingSiteFetchModelApplication}
   * @memberof QualifyingSiteFetchModelWaitList
   */
  application: QualifyingSiteFetchModelApplication;
  /**
   *
   * @type {WaitListAlternateTypeEnum}
   * @memberof QualifyingSiteFetchModelWaitList
   */
  alternateType?: WaitListAlternateTypeEnum;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum QualifyingSiteFilters {
  LOCALQUALIFYING = 'LOCAL_QUALIFYING',
  FINALQUALIFYING = 'FINAL_QUALIFYING',
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  POCID = 'POC_ID',
  STATE = 'STATE',
  CARTSAVAILABLE = 'CARTS_AVAILABLE',
  AGAID = 'AGA_ID',
  CHAMPAGAID = 'CHAMP_AGA_ID',
}

/**
 *
 * @export
 * @enum {string}
 */
export enum QualifyingSiteFinalizationEnum {
  FINALIZED = 'FINALIZED',
  DISABLED = 'DISABLED',
}

/**
 *
 * @export
 * @interface QualifyingSiteLazyModel
 */
export interface QualifyingSiteLazyModel {
  /**
   *
   * @type {number}
   * @memberof QualifyingSiteLazyModel
   */
  id: number;
  /**
   *
   * @type {QualifyingLevelEnum}
   * @memberof QualifyingSiteLazyModel
   */
  level?: QualifyingLevelEnum;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteLazyModel
   */
  qualifyingDate?: string;
}
/**
 *
 * @export
 * @interface QualifyingSiteModel
 */
export interface QualifyingSiteModel {
  /**
   *
   * @type {QualifyingLevelEnum}
   * @memberof QualifyingSiteModel
   */
  level: QualifyingLevelEnum;
  /**
   *
   * @type {number}
   * @memberof QualifyingSiteModel
   */
  number?: number;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteModel
   */
  city: string;
  /**
   *
   * @type {CountryAddModel}
   * @memberof QualifyingSiteModel
   */
  country: CountryAddModel;
  /**
   *
   * @type {StateAddModel}
   * @memberof QualifyingSiteModel
   */
  state?: StateAddModel;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteModel
   */
  createdDate: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteModel
   */
  fullCapacityDate?: string;
  /**
   *
   * @type {number}
   * @memberof QualifyingSiteModel
   */
  capacity: number;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteModel
   */
  practiceInfo: string;
  /**
   *
   * @type {QualifyingSiteSpotsAvailable}
   * @memberof QualifyingSiteModel
   */
  spotsAvailability?: QualifyingSiteSpotsAvailable;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteModel
   */
  qualifyingDate: string;
  /**
   *
   * @type {boolean}
   * @memberof QualifyingSiteModel
   */
  qualifyingDateIsTwoDays?: boolean;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteModel
   */
  qualifyingWebsite: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteModel
   */
  rainDate: string;
  /**
   *
   * @type {boolean}
   * @memberof QualifyingSiteModel
   */
  rainDateIsTwoDays?: boolean;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteModel
   */
  closedDate?: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteModel
   */
  submittedByFirstName: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteModel
   */
  submittedByLastName: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteModel
   */
  submittedByAGA: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteModel
   */
  comments?: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteModel
   */
  scoringUrl?: string;
  /**
   *
   * @type {QSiteCartsAvailableEnum}
   * @memberof QualifyingSiteModel
   */
  cartsAvailable?: QSiteCartsAvailableEnum;
  /**
   *
   * @type {boolean}
   * @memberof QualifyingSiteModel
   */
  pushCart?: boolean;
  /**
   *
   * @type {QSCourseAccessEnum}
   * @memberof QualifyingSiteModel
   */
  courseAccess?: QSCourseAccessEnum;
  /**
   *
   * @type {boolean}
   * @memberof QualifyingSiteModel
   */
  expertCertifiedRules?: boolean;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteModel
   */
  expertCertifiedRulesName?: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteModel
   */
  courseRating?: string;
  /**
   *
   * @type {boolean}
   * @memberof QualifyingSiteModel
   */
  strokePolicyViolations?: boolean;
  /**
   *
   * @type {number}
   * @memberof QualifyingSiteModel
   */
  waitListCapacity: number;
  /**
   *
   * @type {number}
   * @memberof QualifyingSiteModel
   */
  id: number;
  /**
   *
   * @type {QualifyingSiteStatusEnum}
   * @memberof QualifyingSiteModel
   */
  status: QualifyingSiteStatusEnum;
}
/**
 *
 * @export
 * @interface QualifyingSiteModelAllOf
 */
export interface QualifyingSiteModelAllOf {
  /**
   *
   * @type {number}
   * @memberof QualifyingSiteModelAllOf
   */
  id: number;
  /**
   *
   * @type {QualifyingSiteStatusEnum}
   * @memberof QualifyingSiteModelAllOf
   */
  status: QualifyingSiteStatusEnum;
}
/**
 *
 * @export
 * @interface QualifyingSitePlayerFullModel
 */
export interface QualifyingSitePlayerFullModel {
  /**
   *
   * @type {QualifyingLevelEnum}
   * @memberof QualifyingSitePlayerFullModel
   */
  level: QualifyingLevelEnum;
  /**
   *
   * @type {number}
   * @memberof QualifyingSitePlayerFullModel
   */
  number?: number;
  /**
   *
   * @type {string}
   * @memberof QualifyingSitePlayerFullModel
   */
  city: string;
  /**
   *
   * @type {CountryAddModel}
   * @memberof QualifyingSitePlayerFullModel
   */
  country: CountryAddModel;
  /**
   *
   * @type {StateAddModel}
   * @memberof QualifyingSitePlayerFullModel
   */
  state?: StateAddModel;
  /**
   *
   * @type {string}
   * @memberof QualifyingSitePlayerFullModel
   */
  createdDate: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingSitePlayerFullModel
   */
  fullCapacityDate?: string;
  /**
   *
   * @type {number}
   * @memberof QualifyingSitePlayerFullModel
   */
  capacity: number;
  /**
   *
   * @type {string}
   * @memberof QualifyingSitePlayerFullModel
   */
  practiceInfo: string;
  /**
   *
   * @type {QualifyingSiteSpotsAvailable}
   * @memberof QualifyingSitePlayerFullModel
   */
  spotsAvailability?: QualifyingSiteSpotsAvailable;
  /**
   *
   * @type {string}
   * @memberof QualifyingSitePlayerFullModel
   */
  qualifyingDate: string;
  /**
   *
   * @type {boolean}
   * @memberof QualifyingSitePlayerFullModel
   */
  qualifyingDateIsTwoDays?: boolean;
  /**
   *
   * @type {string}
   * @memberof QualifyingSitePlayerFullModel
   */
  qualifyingWebsite: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingSitePlayerFullModel
   */
  rainDate: string;
  /**
   *
   * @type {boolean}
   * @memberof QualifyingSitePlayerFullModel
   */
  rainDateIsTwoDays?: boolean;
  /**
   *
   * @type {string}
   * @memberof QualifyingSitePlayerFullModel
   */
  closedDate?: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingSitePlayerFullModel
   */
  submittedByFirstName: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingSitePlayerFullModel
   */
  submittedByLastName: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingSitePlayerFullModel
   */
  submittedByAGA: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingSitePlayerFullModel
   */
  comments?: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingSitePlayerFullModel
   */
  scoringUrl?: string;
  /**
   *
   * @type {QSiteCartsAvailableEnum}
   * @memberof QualifyingSitePlayerFullModel
   */
  cartsAvailable?: QSiteCartsAvailableEnum;
  /**
   *
   * @type {boolean}
   * @memberof QualifyingSitePlayerFullModel
   */
  pushCart?: boolean;
  /**
   *
   * @type {QSCourseAccessEnum}
   * @memberof QualifyingSitePlayerFullModel
   */
  courseAccess?: QSCourseAccessEnum;
  /**
   *
   * @type {boolean}
   * @memberof QualifyingSitePlayerFullModel
   */
  expertCertifiedRules?: boolean;
  /**
   *
   * @type {string}
   * @memberof QualifyingSitePlayerFullModel
   */
  expertCertifiedRulesName?: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingSitePlayerFullModel
   */
  courseRating?: string;
  /**
   *
   * @type {boolean}
   * @memberof QualifyingSitePlayerFullModel
   */
  strokePolicyViolations?: boolean;
  /**
   *
   * @type {number}
   * @memberof QualifyingSitePlayerFullModel
   */
  waitListCapacity: number;
  /**
   *
   * @type {number}
   * @memberof QualifyingSitePlayerFullModel
   */
  id: number;
  /**
   *
   * @type {QualifyingSiteStatusEnum}
   * @memberof QualifyingSitePlayerFullModel
   */
  status: QualifyingSiteStatusEnum;
  /**
   *
   * @type {Array<ClubBaseModel>}
   * @memberof QualifyingSitePlayerFullModel
   */
  club: Array<ClubBaseModel>;
  /**
   *
   * @type {PointOfContactAdminModel}
   * @memberof QualifyingSitePlayerFullModel
   */
  pointOfContact?: PointOfContactAdminModel;
  /**
   *
   * @type {QualifyingSiteWaitListAvailable}
   * @memberof QualifyingSitePlayerFullModel
   */
  waitListAvailability?: QualifyingSiteWaitListAvailable;
  /**
   *
   * @type {Array<QualifyingSitePlayerFullModelAllOfWaitList>}
   * @memberof QualifyingSitePlayerFullModel
   */
  waitList?: Array<QualifyingSitePlayerFullModelAllOfWaitList>;
}
/**
 *
 * @export
 * @interface QualifyingSitePlayerFullModelAllOf
 */
export interface QualifyingSitePlayerFullModelAllOf {
  /**
   *
   * @type {QualifyingSiteWaitListAvailable}
   * @memberof QualifyingSitePlayerFullModelAllOf
   */
  waitListAvailability?: QualifyingSiteWaitListAvailable;
  /**
   *
   * @type {Array<QualifyingSitePlayerFullModelAllOfWaitList>}
   * @memberof QualifyingSitePlayerFullModelAllOf
   */
  waitList?: Array<QualifyingSitePlayerFullModelAllOfWaitList>;
}
/**
 *
 * @export
 * @interface QualifyingSitePlayerFullModelAllOfApplication
 */
export interface QualifyingSitePlayerFullModelAllOfApplication {
  /**
   *
   * @type {number}
   * @memberof QualifyingSitePlayerFullModelAllOfApplication
   */
  id: number;
  /**
   *
   * @type {ApplicationProgressionEnum}
   * @memberof QualifyingSitePlayerFullModelAllOfApplication
   */
  progression: ApplicationProgressionEnum;
}
/**
 *
 * @export
 * @interface QualifyingSitePlayerFullModelAllOfWaitList
 */
export interface QualifyingSitePlayerFullModelAllOfWaitList {
  /**
   *
   * @type {QualifyingSitePlayerFullModelAllOfApplication}
   * @memberof QualifyingSitePlayerFullModelAllOfWaitList
   */
  application: QualifyingSitePlayerFullModelAllOfApplication;
  /**
   *
   * @type {WaitListAlternateTypeEnum}
   * @memberof QualifyingSitePlayerFullModelAllOfWaitList
   */
  alternateType?: WaitListAlternateTypeEnum;
}
/**
 *
 * @export
 * @interface QualifyingSitePlayerListModel
 */
export interface QualifyingSitePlayerListModel {
  /**
   *
   * @type {Array<QualifyingSitePlayerFullModel>}
   * @memberof QualifyingSitePlayerListModel
   */
  items: Array<QualifyingSitePlayerFullModel>;
  /**
   *
   * @type {number}
   * @memberof QualifyingSitePlayerListModel
   */
  limit: number;
  /**
   *
   * @type {number}
   * @memberof QualifyingSitePlayerListModel
   */
  start: number;
  /**
   *
   * @type {number}
   * @memberof QualifyingSitePlayerListModel
   */
  total: number;
}
/**
 *
 * @export
 * @interface QualifyingSitePlayerModel
 */
export interface QualifyingSitePlayerModel {
  /**
   *
   * @type {QualifyingLevelEnum}
   * @memberof QualifyingSitePlayerModel
   */
  level: QualifyingLevelEnum;
  /**
   *
   * @type {number}
   * @memberof QualifyingSitePlayerModel
   */
  number?: number;
  /**
   *
   * @type {string}
   * @memberof QualifyingSitePlayerModel
   */
  city: string;
  /**
   *
   * @type {CountryAddModel}
   * @memberof QualifyingSitePlayerModel
   */
  country: CountryAddModel;
  /**
   *
   * @type {StateAddModel}
   * @memberof QualifyingSitePlayerModel
   */
  state?: StateAddModel;
  /**
   *
   * @type {string}
   * @memberof QualifyingSitePlayerModel
   */
  createdDate: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingSitePlayerModel
   */
  fullCapacityDate?: string;
  /**
   *
   * @type {number}
   * @memberof QualifyingSitePlayerModel
   */
  capacity: number;
  /**
   *
   * @type {string}
   * @memberof QualifyingSitePlayerModel
   */
  practiceInfo: string;
  /**
   *
   * @type {QualifyingSiteSpotsAvailable}
   * @memberof QualifyingSitePlayerModel
   */
  spotsAvailability?: QualifyingSiteSpotsAvailable;
  /**
   *
   * @type {string}
   * @memberof QualifyingSitePlayerModel
   */
  qualifyingDate: string;
  /**
   *
   * @type {boolean}
   * @memberof QualifyingSitePlayerModel
   */
  qualifyingDateIsTwoDays?: boolean;
  /**
   *
   * @type {string}
   * @memberof QualifyingSitePlayerModel
   */
  qualifyingWebsite: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingSitePlayerModel
   */
  rainDate: string;
  /**
   *
   * @type {boolean}
   * @memberof QualifyingSitePlayerModel
   */
  rainDateIsTwoDays?: boolean;
  /**
   *
   * @type {string}
   * @memberof QualifyingSitePlayerModel
   */
  closedDate?: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingSitePlayerModel
   */
  submittedByFirstName: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingSitePlayerModel
   */
  submittedByLastName: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingSitePlayerModel
   */
  submittedByAGA: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingSitePlayerModel
   */
  comments?: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingSitePlayerModel
   */
  scoringUrl?: string;
  /**
   *
   * @type {QSiteCartsAvailableEnum}
   * @memberof QualifyingSitePlayerModel
   */
  cartsAvailable?: QSiteCartsAvailableEnum;
  /**
   *
   * @type {boolean}
   * @memberof QualifyingSitePlayerModel
   */
  pushCart?: boolean;
  /**
   *
   * @type {QSCourseAccessEnum}
   * @memberof QualifyingSitePlayerModel
   */
  courseAccess?: QSCourseAccessEnum;
  /**
   *
   * @type {boolean}
   * @memberof QualifyingSitePlayerModel
   */
  expertCertifiedRules?: boolean;
  /**
   *
   * @type {string}
   * @memberof QualifyingSitePlayerModel
   */
  expertCertifiedRulesName?: string;
  /**
   *
   * @type {string}
   * @memberof QualifyingSitePlayerModel
   */
  courseRating?: string;
  /**
   *
   * @type {boolean}
   * @memberof QualifyingSitePlayerModel
   */
  strokePolicyViolations?: boolean;
  /**
   *
   * @type {number}
   * @memberof QualifyingSitePlayerModel
   */
  waitListCapacity: number;
  /**
   *
   * @type {number}
   * @memberof QualifyingSitePlayerModel
   */
  id: number;
  /**
   *
   * @type {QualifyingSiteStatusEnum}
   * @memberof QualifyingSitePlayerModel
   */
  status: QualifyingSiteStatusEnum;
  /**
   *
   * @type {Array<ClubBaseModel>}
   * @memberof QualifyingSitePlayerModel
   */
  club: Array<ClubBaseModel>;
  /**
   *
   * @type {PointOfContactAdminModel}
   * @memberof QualifyingSitePlayerModel
   */
  pointOfContact?: PointOfContactAdminModel;
}
/**
 *
 * @export
 * @interface QualifyingSitePlayerModelAllOf
 */
export interface QualifyingSitePlayerModelAllOf {
  /**
   *
   * @type {Array<ClubBaseModel>}
   * @memberof QualifyingSitePlayerModelAllOf
   */
  club: Array<ClubBaseModel>;
  /**
   *
   * @type {PointOfContactAdminModel}
   * @memberof QualifyingSitePlayerModelAllOf
   */
  pointOfContact?: PointOfContactAdminModel;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum QualifyingSiteReportListType {
  CURRENTPLAYERLIST = 'CURRENT_PLAYER_LIST',
  PLAYERLISTATCLOSE = 'PLAYER_LIST_AT_CLOSE',
  FINALPLAYERLIST = 'FINAL_PLAYER_LIST',
  WAITLISTEDPLAYERLIST = 'WAITLISTED_PLAYER_LIST',
  REPLACEMENTAFTERCLOSE = 'REPLACEMENT_AFTER_CLOSE',
}

/**
 *
 * @export
 * @interface QualifyingSiteReportModel
 */
export interface QualifyingSiteReportModel {
  /**
   *
   * @type {number}
   * @memberof QualifyingSiteReportModel
   */
  id?: number;
  /**
   *
   * @type {Array<QualifyingSiteReportModelWaitList>}
   * @memberof QualifyingSiteReportModel
   */
  waitList?: Array<QualifyingSiteReportModelWaitList>;
  /**
   *
   * @type {QualifyingSiteReportModelChampionship}
   * @memberof QualifyingSiteReportModel
   */
  championship?: QualifyingSiteReportModelChampionship;
}
/**
 *
 * @export
 * @interface QualifyingSiteReportModelChampionship
 */
export interface QualifyingSiteReportModelChampionship {
  /**
   *
   * @type {number}
   * @memberof QualifyingSiteReportModelChampionship
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof QualifyingSiteReportModelChampionship
   */
  title?: string;
}
/**
 *
 * @export
 * @interface QualifyingSiteReportModelWaitList
 */
export interface QualifyingSiteReportModelWaitList {
  /**
   *
   * @type {ApplicationAdminResponseModel}
   * @memberof QualifyingSiteReportModelWaitList
   */
  application: ApplicationAdminResponseModel;
}
/**
 *
 * @export
 * @interface QualifyingSiteSpotsAvailable
 */
export interface QualifyingSiteSpotsAvailable {
  /**
   *
   * @type {number}
   * @memberof QualifyingSiteSpotsAvailable
   */
  spotsAvailable?: number;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum QualifyingSiteStatusEnum {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  CLOSED = 'CLOSED',
}

/**
 *
 * @export
 * @interface QualifyingSiteWaitListAvailable
 */
export interface QualifyingSiteWaitListAvailable {
  /**
   *
   * @type {number}
   * @memberof QualifyingSiteWaitListAvailable
   */
  waitListAvailable?: number;
}
/**
 *
 * @export
 * @interface QualifyingSiteWithAppsFetchModel
 */
export interface QualifyingSiteWithAppsFetchModel {
  /**
   *
   * @type {number}
   * @memberof QualifyingSiteWithAppsFetchModel
   */
  id?: number;
  /**
   *
   * @type {Array<QualifyingSiteReportModelWaitList>}
   * @memberof QualifyingSiteWithAppsFetchModel
   */
  waitList?: Array<QualifyingSiteReportModelWaitList>;
  /**
   *
   * @type {QualifyingSiteReportModelChampionship}
   * @memberof QualifyingSiteWithAppsFetchModel
   */
  championship?: QualifyingSiteReportModelChampionship;
  /**
   *
   * @type {Array<QualifyingSiteWithAppsFetchModelAllOfApplications>}
   * @memberof QualifyingSiteWithAppsFetchModel
   */
  applications?: Array<QualifyingSiteWithAppsFetchModelAllOfApplications>;
}
/**
 *
 * @export
 * @interface QualifyingSiteWithAppsFetchModelAllOf
 */
export interface QualifyingSiteWithAppsFetchModelAllOf {
  /**
   *
   * @type {Array<QualifyingSiteWithAppsFetchModelAllOfApplications>}
   * @memberof QualifyingSiteWithAppsFetchModelAllOf
   */
  applications?: Array<QualifyingSiteWithAppsFetchModelAllOfApplications>;
}
/**
 *
 * @export
 * @interface QualifyingSiteWithAppsFetchModelAllOfApplications
 */
export interface QualifyingSiteWithAppsFetchModelAllOfApplications {
  /**
   *
   * @type {ApplicationAdminResponseModel}
   * @memberof QualifyingSiteWithAppsFetchModelAllOfApplications
   */
  application?: ApplicationAdminResponseModel;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum QualifyingStatusEnum {
  FIRSTSTAGEQUALIFYING = 'FIRST_STAGE_QUALIFYING',
  FIRSTSTAGEQUALIFYING1STALTERNATE = 'FIRST_STAGE_QUALIFYING_1ST_ALTERNATE',
  FIRSTSTAGEQUALIFYING2NDALTERNATE = 'FIRST_STAGE_QUALIFYING_2ND_ALTERNATE',
  FINALSTAGEQUALIFYING = 'FINAL_STAGE_QUALIFYING',
  FINALSTAGEFINALIST = 'FINAL_STAGE_FINALIST',
  FINALSTAGEFINALIST1STALTERNATE = 'FINAL_STAGE_FINALIST_1ST_ALTERNATE',
  FINALSTAGEFINALIST2NDALTERNATE = 'FINAL_STAGE_FINALIST_2ND_ALTERNATE',
  SINGLESTAGEQUALIFYING = 'SINGLE_STAGE_QUALIFYING',
  SINGLESTAGEFINALIST = 'SINGLE_STAGE_FINALIST',
  SINGLESTAGEFINALIST1STALTERNATE = 'SINGLE_STAGE_FINALIST_1ST_ALTERNATE',
  SINGLESTAGEFINALIST2NDALTERNATE = 'SINGLE_STAGE_FINALIST_2ND_ALTERNATE',
  DECLINEDSPOT = 'DECLINED_SPOT',
  STROKEPOLICY = 'STROKE_POLICY',
}

/**
 *
 * @export
 * @interface QueueReasonAdminModel
 */
export interface QueueReasonAdminModel {
  /**
   *
   * @type {number}
   * @memberof QueueReasonAdminModel
   */
  key: number;
  /**
   *
   * @type {string}
   * @memberof QueueReasonAdminModel
   */
  value: string;
}
/**
 * Used for saving/updating qualifying site rank
 * @export
 * @interface RankedQualifyingSiteModel
 */
export interface RankedQualifyingSiteModel {
  /**
   *
   * @type {number}
   * @memberof RankedQualifyingSiteModel
   */
  siteId: number;
  /**
   *
   * @type {number}
   * @memberof RankedQualifyingSiteModel
   */
  rank: number;
}
/**
 *
 * @export
 * @interface RedBookPageModel
 */
export interface RedBookPageModel {
  /**
   *
   * @type {Array<RedBookRowModel>}
   * @memberof RedBookPageModel
   */
  items: Array<RedBookRowModel>;
}
/**
 * Red Book presentation of qualifying site
 * @export
 * @interface RedBookRowModel
 */
export interface RedBookRowModel {
  /**
   *
   * @type {number}
   * @memberof RedBookRowModel
   */
  siteId: number;
  /**
   *
   * @type {number}
   * @memberof RedBookRowModel
   */
  rank: number;
  /**
   *
   * @type {string}
   * @memberof RedBookRowModel
   */
  site?: string;
  /**
   *
   * @type {Array<QualifierModel>}
   * @memberof RedBookRowModel
   */
  qualifiers?: Array<QualifierModel>;
}
/**
 *
 * @export
 * @interface RedBookRowModelAllOf
 */
export interface RedBookRowModelAllOf {
  /**
   *
   * @type {string}
   * @memberof RedBookRowModelAllOf
   */
  site?: string;
  /**
   *
   * @type {Array<QualifierModel>}
   * @memberof RedBookRowModelAllOf
   */
  qualifiers?: Array<QualifierModel>;
}
/**
 *
 * @export
 * @interface RefundPaymentAdminEditModel
 */
export interface RefundPaymentAdminEditModel {
  /**
   *
   * @type {number}
   * @memberof RefundPaymentAdminEditModel
   */
  appId?: number;
  /**
   *
   * @type {number}
   * @memberof RefundPaymentAdminEditModel
   */
  amount: number;
  /**
   *
   * @type {boolean}
   * @memberof RefundPaymentAdminEditModel
   */
  notifyPlayer?: boolean;
  /**
   *
   * @type {string}
   * @memberof RefundPaymentAdminEditModel
   */
  substitutePartnerEmail?: string;
  /**
   *
   * @type {boolean}
   * @memberof RefundPaymentAdminEditModel
   */
  sendNewLinkToPartner?: boolean;
  /**
   *
   * @type {string}
   * @memberof RefundPaymentAdminEditModel
   */
  comment: string;
}
/**
 *
 * @export
 * @interface RefundPaymentAdminEditModelAllOf
 */
export interface RefundPaymentAdminEditModelAllOf {
  /**
   *
   * @type {string}
   * @memberof RefundPaymentAdminEditModelAllOf
   */
  comment: string;
}
/**
 *
 * @export
 * @interface RefundPaymentAdminModel
 */
export interface RefundPaymentAdminModel {
  /**
   *
   * @type {number}
   * @memberof RefundPaymentAdminModel
   */
  appId?: number;
  /**
   *
   * @type {number}
   * @memberof RefundPaymentAdminModel
   */
  amount: number;
  /**
   *
   * @type {boolean}
   * @memberof RefundPaymentAdminModel
   */
  notifyPlayer?: boolean;
  /**
   *
   * @type {string}
   * @memberof RefundPaymentAdminModel
   */
  substitutePartnerEmail?: string;
  /**
   *
   * @type {boolean}
   * @memberof RefundPaymentAdminModel
   */
  sendNewLinkToPartner?: boolean;
  /**
   *
   * @type {string}
   * @memberof RefundPaymentAdminModel
   */
  comment: string;
  /**
   *
   * @type {Array<number>}
   * @memberof RefundPaymentAdminModel
   */
  appIds?: Array<number>;
  /**
   *
   * @type {ApplicationMultiFilterParamBeanModel}
   * @memberof RefundPaymentAdminModel
   */
  filter?: ApplicationMultiFilterParamBeanModel;
  /**
   *
   * @type {boolean}
   * @memberof RefundPaymentAdminModel
   */
  allowedWaitlistRefund?: boolean;
  /**
   *
   * @type {WithdrawalReasonEnum}
   * @memberof RefundPaymentAdminModel
   */
  withdrawalReason: WithdrawalReasonEnum;
  /**
   *
   * @type {RequestReceivedByEnum}
   * @memberof RefundPaymentAdminModel
   */
  requestReceivedBy: RequestReceivedByEnum;
}
/**
 *
 * @export
 * @interface RefundPaymentAdminModelAllOf
 */
export interface RefundPaymentAdminModelAllOf {
  /**
   *
   * @type {Array<number>}
   * @memberof RefundPaymentAdminModelAllOf
   */
  appIds?: Array<number>;
  /**
   *
   * @type {ApplicationMultiFilterParamBeanModel}
   * @memberof RefundPaymentAdminModelAllOf
   */
  filter?: ApplicationMultiFilterParamBeanModel;
  /**
   *
   * @type {boolean}
   * @memberof RefundPaymentAdminModelAllOf
   */
  sendNewLinkToPartner?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof RefundPaymentAdminModelAllOf
   */
  allowedWaitlistRefund?: boolean;
  /**
   *
   * @type {WithdrawalReasonEnum}
   * @memberof RefundPaymentAdminModelAllOf
   */
  withdrawalReason: WithdrawalReasonEnum;
  /**
   *
   * @type {RequestReceivedByEnum}
   * @memberof RefundPaymentAdminModelAllOf
   */
  requestReceivedBy: RequestReceivedByEnum;
}
/**
 *
 * @export
 * @interface RefundPaymentBaseModel
 */
export interface RefundPaymentBaseModel {
  /**
   *
   * @type {number}
   * @memberof RefundPaymentBaseModel
   */
  appId?: number;
  /**
   *
   * @type {number}
   * @memberof RefundPaymentBaseModel
   */
  amount: number;
  /**
   *
   * @type {boolean}
   * @memberof RefundPaymentBaseModel
   */
  notifyPlayer?: boolean;
  /**
   *
   * @type {string}
   * @memberof RefundPaymentBaseModel
   */
  substitutePartnerEmail?: string;
  /**
   *
   * @type {boolean}
   * @memberof RefundPaymentBaseModel
   */
  sendNewLinkToPartner?: boolean;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum RefundStatusEnum {
  TRANSMITTED = 'TRANSMITTED',
  PENDING = 'PENDING',
  FAILED = 'FAILED',
}

/**
 *
 * @export
 * @interface ReinstatementValidationModel
 */
export interface ReinstatementValidationModel {
  /**
   *
   * @type {number}
   * @memberof ReinstatementValidationModel
   */
  id: number;
  /**
   *
   * @type {boolean}
   * @memberof ReinstatementValidationModel
   */
  isChanged: boolean;
  /**
   *
   * @type {string}
   * @memberof ReinstatementValidationModel
   */
  message: string;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum RequestReceivedByEnum {
  INCOMINGCALL = 'INCOMING_CALL',
  OUTGOINGCALL = 'OUTGOING_CALL',
  INCOMINGEMAIL = 'INCOMING_EMAIL',
  OUTGOINGEMAIL = 'OUTGOING_EMAIL',
  OTHER = 'OTHER',
  PLAYER = 'PLAYER',
  SYSTEM = 'SYSTEM',
}

/**
 *
 * @export
 * @enum {string}
 */
export enum ResendEmailFilterEnum {
  CONFIRMATIONEMAIL = 'CONFIRMATION_EMAIL',
  WAITLISTCONFIRMATIONEMAIL = 'WAITLIST_CONFIRMATION_EMAIL',
  WAITLISTTEAMCONFIRMATIONEMAIL = 'WAITLIST_TEAM_CONFIRMATION_EMAIL',
  EXEMPTWAITLISTTEAMCONFIRMATIONEMAIL = 'EXEMPT_WAITLIST_TEAM_CONFIRMATION_EMAIL',
  TEAMCONFIRMATIONEMAIL = 'TEAM_CONFIRMATION_EMAIL',
  SECONDAPPLICANTLINKEMAIL = 'SECOND_APPLICANT_LINK_EMAIL',
  FINALCONFIRMATIONEMAIL = 'FINAL_CONFIRMATION_EMAIL',
  WITHDRAWEMAIL = 'WITHDRAW_EMAIL',
  QUALIFIEDALTERNATE = 'QUALIFIED_ALTERNATE',
  DECLINEDPAYMENT = 'DECLINED_PAYMENT',
  WAITLISTTOQUALIFYINGDECLINEDPAYMENTFEENOTPAID = 'WAITLIST_TO_QUALIFYING_DECLINED_PAYMENT_FEE_NOT_PAID',
  DECLINEDPAYMENTPARTNERNOTIFICATION = 'DECLINED_PAYMENT_PARTNER_NOTIFICATION',
  ENTRYFEEBALANCEDECLINEDPAYMENT = 'ENTRY_FEE_BALANCE_DECLINED_PAYMENT',
  PLAYERSURVEYEMAILFORQUALIFYING = 'PLAYER_SURVEY_EMAIL_FOR_QUALIFYING',
  PERFORMANCEVIOLATIONEMAIL = 'PERFORMANCE_VIOLATION_EMAIL',
}

/**
 *
 * @export
 * @interface ResendEmailRequestModel
 */
export interface ResendEmailRequestModel {
  /**
   *
   * @type {number}
   * @memberof ResendEmailRequestModel
   */
  appId: number;
  /**
   *
   * @type {ResendEmailFilterEnum}
   * @memberof ResendEmailRequestModel
   */
  type: ResendEmailFilterEnum;
}
/**
 *
 * @export
 * @interface ReserveSpotCreateModel
 */
export interface ReserveSpotCreateModel {
  /**
   *
   * @type {number}
   * @memberof ReserveSpotCreateModel
   */
  qualifyingSiteId: number;
  /**
   *
   * @type {WorkflowTypeEnum}
   * @memberof ReserveSpotCreateModel
   */
  workflowType: WorkflowTypeEnum;
  /**
   *
   * @type {number}
   * @memberof ReserveSpotCreateModel
   */
  reserveType: number;
}
/**
 *
 * @export
 * @interface ReserveSpotEditModel
 */
export interface ReserveSpotEditModel {
  /**
   *
   * @type {number}
   * @memberof ReserveSpotEditModel
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof ReserveSpotEditModel
   */
  paymentSessionId?: string;
}
/**
 *
 * @export
 * @interface ReserveSpotEditModelAllOf
 */
export interface ReserveSpotEditModelAllOf {
  /**
   *
   * @type {string}
   * @memberof ReserveSpotEditModelAllOf
   */
  paymentSessionId?: string;
}
/**
 *
 * @export
 * @interface ReserveSpotResponseModel
 */
export interface ReserveSpotResponseModel {
  /**
   *
   * @type {number}
   * @memberof ReserveSpotResponseModel
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof ReserveSpotResponseModel
   */
  expiryTime?: string;
  /**
   *
   * @type {number}
   * @memberof ReserveSpotResponseModel
   */
  initialReserveTime?: number;
  /**
   *
   * @type {number}
   * @memberof ReserveSpotResponseModel
   */
  paymentReserveTime?: number;
}
/**
 * Represents Review Step Player Appliance Model
 * @export
 * @interface ReviewStepPlayerApplianceModel
 */
export interface ReviewStepPlayerApplianceModel {
  /**
   *
   * @type {string}
   * @memberof ReviewStepPlayerApplianceModel
   */
  stepTitle?: string;
  /**
   *
   * @type {string}
   * @memberof ReviewStepPlayerApplianceModel
   */
  headText?: string;
  /**
   *
   * @type {string}
   * @memberof ReviewStepPlayerApplianceModel
   */
  certificationTitle?: string;
  /**
   *
   * @type {string}
   * @memberof ReviewStepPlayerApplianceModel
   */
  certificationText?: string;
}
/**
 *
 * @export
 * @interface ScoreUpdateRequestAdminModelScoring
 */
export interface ScoreUpdateRequestAdminModelScoring {
  /**
   *
   * @type {Array<ApplicationBaseRequestAdminModelScoring>}
   * @memberof ScoreUpdateRequestAdminModelScoring
   */
  data?: Array<ApplicationBaseRequestAdminModelScoring>;
}
/**
 *
 * @export
 * @interface ScoringListModel
 */
export interface ScoringListModel {
  /**
   *
   * @type {Array<ApplicationBaseAdminModelScoring>}
   * @memberof ScoringListModel
   */
  items: Array<ApplicationBaseAdminModelScoring>;
  /**
   *
   * @type {number}
   * @memberof ScoringListModel
   */
  limit: number;
  /**
   *
   * @type {number}
   * @memberof ScoringListModel
   */
  start: number;
  /**
   *
   * @type {number}
   * @memberof ScoringListModel
   */
  total: number;
}
/**
 * Represents Selection Tab Model
 * @export
 * @interface SelectionTabPlayerHomeModel
 */
export interface SelectionTabPlayerHomeModel {
  /**
   *
   * @type {string}
   * @memberof SelectionTabPlayerHomeModel
   */
  text?: string;
  /**
   *
   * @type {string}
   * @memberof SelectionTabPlayerHomeModel
   */
  hash?: string;
}
/**
 *
 * @export
 * @interface ServiceEmailTemplateParamBean
 */
export interface ServiceEmailTemplateParamBean {
  /**
   *
   * @type {EmailTemplateServiceTypeEnum}
   * @memberof ServiceEmailTemplateParamBean
   */
  serviceType: EmailTemplateServiceTypeEnum;
  /**
   *
   * @type {number}
   * @memberof ServiceEmailTemplateParamBean
   */
  champId?: number;
}
/**
 * Represents Side Applicant Model
 * @export
 * @interface SideApplicantModel
 */
export interface SideApplicantModel {
  /**
   *
   * @type {string}
   * @memberof SideApplicantModel
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof SideApplicantModel
   */
  lastName: string;
}
/**
 *
 * @export
 * @interface SocialNetworkModel
 */
export interface SocialNetworkModel {
  /**
   *
   * @type {string}
   * @memberof SocialNetworkModel
   */
  title?: string;
  /**
   *
   * @type {USGATargetEnum}
   * @memberof SocialNetworkModel
   */
  target?: USGATargetEnum;
  /**
   *
   * @type {string}
   * @memberof SocialNetworkModel
   */
  url?: string;
  /**
   *
   * @type {string}
   * @memberof SocialNetworkModel
   */
  network?: SocialNetworkModelNetworkEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum SocialNetworkModelNetworkEnum {
  Instagram = 'instagram',
  Twitter = 'twitter',
  Youtube = 'youtube',
  Facebook = 'facebook',
  Linkedin = 'linkedin',
  GooglePlus = 'google-plus',
}

/**
 *
 * @export
 * @enum {string}
 */
export enum SortingFilterEnum {
  APPIDASC = 'APP_ID_ASC',
  APPIDDESC = 'APP_ID_DESC',
  FIRSTNAMEASC = 'FIRST_NAME_ASC',
  FIRSTNAMEDESC = 'FIRST_NAME_DESC',
  LASTNAMEASC = 'LAST_NAME_ASC',
  LASTNAMEDESC = 'LAST_NAME_DESC',
  AGEASC = 'AGE_ASC',
  AGEDESC = 'AGE_DESC',
  HANDICAPINDEXASC = 'HANDICAP_INDEX_ASC',
  HANDICAPINDEXDESC = 'HANDICAP_INDEX_DESC',
  QUALIFYINGSTATUSASC = 'QUALIFYING_STATUS_ASC',
  QUALIFYINGSTATUSDESC = 'QUALIFYING_STATUS_DESC',
  APPLICATIONSTATUSASC = 'APPLICATION_STATUS_ASC',
  APPLICATIONSTATUSDESC = 'APPLICATION_STATUS_DESC',
  QUALIFYINGCITYASC = 'QUALIFYING_CITY_ASC',
  QUALIFYINGCITYDESC = 'QUALIFYING_CITY_DESC',
  QUALIFYINGSTATEASC = 'QUALIFYING_STATE_ASC',
  QUALIFYINGSTATEDESC = 'QUALIFYING_STATE_DESC',
  QUALIFYINGCOUNTRYASC = 'QUALIFYING_COUNTRY_ASC',
  QUALIFYINGCOUNTRYDESC = 'QUALIFYING_COUNTRY_DESC',
  QUALIFYINGDATEASC = 'QUALIFYING_DATE_ASC',
  QUALIFYINGDATEDESC = 'QUALIFYING_DATE_DESC',
  QUALIFYINGLOCATIONASC = 'QUALIFYING_LOCATION_ASC',
  QUALIFYINGLOCATIONDESC = 'QUALIFYING_LOCATION_DESC',
  FIRSTQUALIFYINGLOCATIONASC = 'FIRST_QUALIFYING_LOCATION_ASC',
  FIRSTQUALIFYINGLOCATIONDESC = 'FIRST_QUALIFYING_LOCATION_DESC',
  FINALQUALIFYINGLOCATIONASC = 'FINAL_QUALIFYING_LOCATION_ASC',
  FINALQUALIFYINGLOCATIONDESC = 'FINAL_QUALIFYING_LOCATION_DESC',
  SINGLEQUALIFYINGLOCATIONASC = 'SINGLE_QUALIFYING_LOCATION_ASC',
  SINGLEQUALIFYINGLOCATIONDESC = 'SINGLE_QUALIFYING_LOCATION_DESC',
  EMAILASC = 'EMAIL_ASC',
  EMAILDESC = 'EMAIL_DESC',
  EXEMPTIONASC = 'EXEMPTION_ASC',
  EXEMPTIONDESC = 'EXEMPTION_DESC',
  TEAMASC = 'TEAM_ASC',
  TEAMDESC = 'TEAM_DESC',
  PLAYERSTATUSASC = 'PLAYER_STATUS_ASC',
  PLAYERSTATUSDESC = 'PLAYER_STATUS_DESC',
  PLAYERIDASC = 'PLAYER_ID_ASC',
  PLAYERIDDESC = 'PLAYER_ID_DESC',
  CLUBIDASC = 'CLUB_ID_ASC',
  CLUBIDDESC = 'CLUB_ID_DESC',
  CLUBNAMEASC = 'CLUB_NAME_ASC',
  CLUBNAMEDESC = 'CLUB_NAME_DESC',
  CLUBCITYASC = 'CLUB_CITY_ASC',
  CLUBCITYDESC = 'CLUB_CITY_DESC',
  CLUBSTATEASC = 'CLUB_STATE_ASC',
  CLUBSTATEDESC = 'CLUB_STATE_DESC',
  CLUBCOUNTRYASC = 'CLUB_COUNTRY_ASC',
  CLUBCOUNTRYDESC = 'CLUB_COUNTRY_DESC',
  DAYSINCOMPLETESTATUSASC = 'DAYS_INCOMPLETE_STATUS_ASC',
  DAYSINCOMPLETESTATUSDESC = 'DAYS_INCOMPLETE_STATUS_DESC',
  ENTRYDATEASC = 'ENTRY_DATE_ASC',
  ENTRYDATEDESC = 'ENTRY_DATE_DESC',
  REVIEWDATEASC = 'REVIEW_DATE_ASC',
  REVIEWDATEDESC = 'REVIEW_DATE_DESC',
  DAYSWITHDRAWSTATUSASC = 'DAYS_WITHDRAW_STATUS_ASC',
  DAYSWITHDRAWSTATUSDESC = 'DAYS_WITHDRAW_STATUS_DESC',
  CHAMPIONSHIPSTARTDATEASC = 'CHAMPIONSHIP_START_DATE_ASC',
  CHAMPIONSHIPSTARTDATEDESC = 'CHAMPIONSHIP_START_DATE_DESC',
  DOBASC = 'DOB_ASC',
  DOBDESC = 'DOB_DESC',
  QUALIFYINGTYPEASC = 'QUALIFYING_TYPE_ASC',
  QUALIFYINGTYPEDESC = 'QUALIFYING_TYPE_DESC',
  SITENUMBERASC = 'SITE_NUMBER_ASC',
  SITENUMBERDESC = 'SITE_NUMBER_DESC',
  CLUBASC = 'CLUB_ASC',
  CLUBDESC = 'CLUB_DESC',
  STATEASC = 'STATE_ASC',
  STATEDESC = 'STATE_DESC',
  COUNTRYASC = 'COUNTRY_ASC',
  COUNTRYDESC = 'COUNTRY_DESC',
  DATEASC = 'DATE_ASC',
  DATEDESC = 'DATE_DESC',
  QUALIFYINGRAINDATEASC = 'QUALIFYING_RAIN_DATE_ASC',
  QUALIFYINGRAINDATEDESC = 'QUALIFYING_RAIN_DATE_DESC',
  QUALIFYINGSITEASC = 'QUALIFYING_SITE_ASC',
  QUALIFYINGSITEDESC = 'QUALIFYING_SITE_DESC',
  QUALIFYINGSITEDEFAULTSORT = 'QUALIFYING_SITE_DEFAULT_SORT',
  QUALIFYINGSITEUSOPENSORT = 'QUALIFYING_SITE_US_OPEN_SORT',
  AGAASC = 'AGA_ASC',
  AGADESC = 'AGA_DESC',
  SITESIZEASC = 'SITE_SIZE_ASC',
  SITESIZEDESC = 'SITE_SIZE_DESC',
  SPOTSAVAILABLEASC = 'SPOTS_AVAILABLE_ASC',
  SPOTSAVAILABLEDESC = 'SPOTS_AVAILABLE_DESC',
  STATUSASC = 'STATUS_ASC',
  STATUSDESC = 'STATUS_DESC',
  TITLEASC = 'TITLE_ASC',
  TITLEDESC = 'TITLE_DESC',
  TYPEASC = 'TYPE_ASC',
  TYPEDESC = 'TYPE_DESC',
  NUMBERASC = 'NUMBER_ASC',
  NUMBERDESC = 'NUMBER_DESC',
  LEVELASC = 'LEVEL_ASC',
  LEVELDESC = 'LEVEL_DESC',
  CITYASC = 'CITY_ASC',
  CITYDESC = 'CITY_DESC',
  USERIDASC = 'USER_ID_ASC',
  USERIDDESC = 'USER_ID_DESC',
  PRACTICEINFOASC = 'PRACTICE_INFO_ASC',
  PRACTICEINFODESC = 'PRACTICE_INFO_DESC',
  STATECOUNTRYASC = 'STATE_COUNTRY_ASC',
  STATECOUNTRYDESC = 'STATE_COUNTRY_DESC',
  SITENUMBERCLUBASC = 'SITE_NUMBER_CLUB_ASC',
  SITENUMBERCLUBDESC = 'SITE_NUMBER_CLUB_DESC',
  CHAMPIONSHIPNAMEASC = 'CHAMPIONSHIP_NAME_ASC',
  CHAMPIONSHIPNAMEDESC = 'CHAMPIONSHIP_NAME_DESC',
  DUPLICATEPROFILESDEFAULTSORT = 'DUPLICATE_PROFILES_DEFAULT_SORT',
  MESSAGEIDASC = 'MESSAGE_ID_ASC',
  MESSAGEIDDESC = 'MESSAGE_ID_DESC',
  MESSAGETYPEASC = 'MESSAGE_TYPE_ASC',
  MESSAGETYPEDESC = 'MESSAGE_TYPE_DESC',
  MESSAGESTARTDATEASC = 'MESSAGE_START_DATE_ASC',
  MESSAGESTARTDATEDESC = 'MESSAGE_START_DATE_DESC',
  MESSAGEEXPIRYDATEASC = 'MESSAGE_EXPIRY_DATE_ASC',
  MESSAGEEXPIRYDATEDESC = 'MESSAGE_EXPIRY_DATE_DESC',
  MESSAGEDATECREATEDASC = 'MESSAGE_DATE_CREATED_ASC',
  MESSAGEDATECREATEDDESC = 'MESSAGE_DATE_CREATED_DESC',
  MESSAGEPLAYERSASC = 'MESSAGE_PLAYERS_ASC',
  MESSAGEPLAYERSDESC = 'MESSAGE_PLAYERS_DESC',
  MESSAGETEXTASC = 'MESSAGE_TEXT_ASC',
  MESSAGETEXTDESC = 'MESSAGE_TEXT_DESC',
}

/**
 *
 * @export
 * @enum {string}
 */
export enum SpecificExemptionsEnum {
  SPECIAL = 'SPECIAL',
  WAGR = 'WAGR',
  OWGR = 'OWGR',
}

/**
 *
 * @export
 * @interface StaffChampionshipListModel
 */
export interface StaffChampionshipListModel {
  /**
   *
   * @type {Array<CommonEventCardsChampModel>}
   * @memberof StaffChampionshipListModel
   */
  items?: Array<CommonEventCardsChampModel>;
  /**
   *
   * @type {string}
   * @memberof StaffChampionshipListModel
   */
  pairingsPagePath?: string;
  /**
   *
   * @type {number}
   * @memberof StaffChampionshipListModel
   */
  totalCount?: number;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum StaffChampionshipReportsTypeEnum {
  CHAMPIONSHIPREDBOOKREPORT = 'CHAMPIONSHIP_RED_BOOK_REPORT',
  FINALIZEDCARDS = 'FINALIZED_CARDS',
}

/**
 *
 * @export
 * @interface StateAddModel
 */
export interface StateAddModel {
  /**
   *
   * @type {number}
   * @memberof StateAddModel
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof StateAddModel
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof StateAddModel
   */
  codeISO?: string;
  /**
   *
   * @type {StateTypeEnum}
   * @memberof StateAddModel
   */
  type?: StateTypeEnum;
}
/**
 *
 * @export
 * @interface StateListModel
 */
export interface StateListModel {
  /**
   *
   * @type {Array<StateModel>}
   * @memberof StateListModel
   */
  items: Array<StateModel>;
  /**
   *
   * @type {number}
   * @memberof StateListModel
   */
  limit: number;
  /**
   *
   * @type {number}
   * @memberof StateListModel
   */
  start: number;
  /**
   *
   * @type {number}
   * @memberof StateListModel
   */
  total: number;
}
/**
 *
 * @export
 * @interface StateModel
 */
export interface StateModel {
  /**
   *
   * @type {number}
   * @memberof StateModel
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof StateModel
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof StateModel
   */
  codeISO: string;
  /**
   *
   * @type {StateTypeEnum}
   * @memberof StateModel
   */
  type: StateTypeEnum;
  /**
   *
   * @type {number}
   * @memberof StateModel
   */
  countryId: number;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum StateTypeEnum {
  STATE = 'STATE',
  PROVINCE = 'PROVINCE',
}

/**
 *
 * @export
 * @interface StripePaymentDataEventModel
 */
export interface StripePaymentDataEventModel {
  /**
   *
   * @type {string}
   * @memberof StripePaymentDataEventModel
   */
  eventId: string;
  /**
   *
   * @type {string}
   * @memberof StripePaymentDataEventModel
   */
  eventType: string;
  /**
   *
   * @type {StripePaymentDataModel}
   * @memberof StripePaymentDataEventModel
   */
  paymentData?: StripePaymentDataModel;
}
/**
 *
 * @export
 * @interface StripePaymentDataEventModelAllOf
 */
export interface StripePaymentDataEventModelAllOf {
  /**
   *
   * @type {StripePaymentDataModel}
   * @memberof StripePaymentDataEventModelAllOf
   */
  paymentData?: StripePaymentDataModel;
}
/**
 *
 * @export
 * @interface StripePaymentDataModel
 */
export interface StripePaymentDataModel {
  /**
   *
   * @type {string}
   * @memberof StripePaymentDataModel
   */
  req_bill_to_forename?: string;
  /**
   *
   * @type {string}
   * @memberof StripePaymentDataModel
   */
  req_bill_to_surname?: string;
  /**
   *
   * @type {string}
   * @memberof StripePaymentDataModel
   */
  req_bill_to_address_line1?: string;
  /**
   *
   * @type {string}
   * @memberof StripePaymentDataModel
   */
  req_bill_to_address_line2?: string;
  /**
   *
   * @type {string}
   * @memberof StripePaymentDataModel
   */
  req_bill_to_address_country?: string;
  /**
   *
   * @type {string}
   * @memberof StripePaymentDataModel
   */
  req_bill_to_address_city?: string;
  /**
   *
   * @type {string}
   * @memberof StripePaymentDataModel
   */
  req_bill_to_address_state?: string;
  /**
   *
   * @type {string}
   * @memberof StripePaymentDataModel
   */
  req_bill_to_address_postal_code?: string;
  /**
   *
   * @type {string}
   * @memberof StripePaymentDataModel
   */
  req_bill_to_phone?: string;
  /**
   *
   * @type {string}
   * @memberof StripePaymentDataModel
   */
  req_bill_to_email?: string;
  /**
   *
   * @type {string}
   * @memberof StripePaymentDataModel
   */
  request_token?: string;
  /**
   *
   * @type {string}
   * @memberof StripePaymentDataModel
   */
  transaction_id?: string;
  /**
   *
   * @type {string}
   * @memberof StripePaymentDataModel
   */
  message?: string;
  /**
   *
   * @type {string}
   * @memberof StripePaymentDataModel
   */
  req_transaction_uuid?: string;
  /**
   *
   * @type {number}
   * @memberof StripePaymentDataModel
   */
  req_merchant_defined_data1?: number;
  /**
   *
   * @type {PaymentTypesEnum}
   * @memberof StripePaymentDataModel
   */
  req_merchant_defined_data4?: PaymentTypesEnum;
  /**
   *
   * @type {string}
   * @memberof StripePaymentDataModel
   */
  req_reference_number?: string;
  /**
   *
   * @type {string}
   * @memberof StripePaymentDataModel
   */
  req_card_type_selection_indicator?: string;
  /**
   *
   * @type {string}
   * @memberof StripePaymentDataModel
   */
  signed_field_names?: string;
  /**
   *
   * @type {string}
   * @memberof StripePaymentDataModel
   */
  raw?: string;
  /**
   *
   * @type {string}
   * @memberof StripePaymentDataModel
   */
  req_card_type?: string;
  /**
   *
   * @type {string}
   * @memberof StripePaymentDataModel
   */
  req_card_number?: string;
  /**
   *
   * @type {string}
   * @memberof StripePaymentDataModel
   */
  req_card_expiry_date?: string;
  /**
   *
   * @type {PaymentDecisionEnum}
   * @memberof StripePaymentDataModel
   */
  decision: PaymentDecisionEnum;
  /**
   *
   * @type {number}
   * @memberof StripePaymentDataModel
   */
  reason_code: number;
  /**
   *
   * @type {number}
   * @memberof StripePaymentDataModel
   */
  req_amount?: number;
  /**
   *
   * @type {string}
   * @memberof StripePaymentDataModel
   */
  auth_amount?: string;
  /**
   *
   * @type {string}
   * @memberof StripePaymentDataModel
   */
  auth_time?: string;
  /**
   *
   * @type {PaymentApplicationModel}
   * @memberof StripePaymentDataModel
   */
  ics_bill_application?: PaymentApplicationModel;
  /**
   *
   * @type {PaymentApplicationModel}
   * @memberof StripePaymentDataModel
   */
  ics_auth_application?: PaymentApplicationModel;
  /**
   *
   * @type {PaymentProcessorEnum}
   * @memberof StripePaymentDataModel
   */
  paymentProcessor: PaymentProcessorEnum;
  /**
   *
   * @type {string}
   * @memberof StripePaymentDataModel
   */
  customerId?: string;
  /**
   *
   * @type {number}
   * @memberof StripePaymentDataModel
   */
  playerId?: number;
  /**
   *
   * @type {boolean}
   * @memberof StripePaymentDataModel
   */
  workflowProcessingRequired?: boolean;
}
/**
 *
 * @export
 * @interface StripePaymentDataModelAllOf
 */
export interface StripePaymentDataModelAllOf {
  /**
   *
   * @type {PaymentProcessorEnum}
   * @memberof StripePaymentDataModelAllOf
   */
  paymentProcessor: PaymentProcessorEnum;
  /**
   *
   * @type {string}
   * @memberof StripePaymentDataModelAllOf
   */
  customerId?: string;
  /**
   *
   * @type {number}
   * @memberof StripePaymentDataModelAllOf
   */
  playerId?: number;
  /**
   *
   * @type {boolean}
   * @memberof StripePaymentDataModelAllOf
   */
  workflowProcessingRequired?: boolean;
}
/**
 *
 * @export
 * @interface StripeRefundDataEventModel
 */
export interface StripeRefundDataEventModel {
  /**
   *
   * @type {string}
   * @memberof StripeRefundDataEventModel
   */
  eventId: string;
  /**
   *
   * @type {string}
   * @memberof StripeRefundDataEventModel
   */
  eventType: string;
  /**
   *
   * @type {StripeRefundDataModel}
   * @memberof StripeRefundDataEventModel
   */
  refundData?: StripeRefundDataModel;
}
/**
 *
 * @export
 * @interface StripeRefundDataEventModelAllOf
 */
export interface StripeRefundDataEventModelAllOf {
  /**
   *
   * @type {StripeRefundDataModel}
   * @memberof StripeRefundDataEventModelAllOf
   */
  refundData?: StripeRefundDataModel;
}
/**
 *
 * @export
 * @interface StripeRefundDataModel
 */
export interface StripeRefundDataModel {
  /**
   *
   * @type {string}
   * @memberof StripeRefundDataModel
   */
  refundId?: string;
  /**
   *
   * @type {string}
   * @memberof StripeRefundDataModel
   */
  status?: string;
  /**
   *
   * @type {string}
   * @memberof StripeRefundDataModel
   */
  failedReason?: string;
  /**
   *
   * @type {string}
   * @memberof StripeRefundDataModel
   */
  chargeId?: string;
  /**
   *
   * @type {string}
   * @memberof StripeRefundDataModel
   */
  paymentIntentId?: string;
  /**
   *
   * @type {number}
   * @memberof StripeRefundDataModel
   */
  amount?: number;
  /**
   *
   * @type {number}
   * @memberof StripeRefundDataModel
   */
  appId?: number;
  /**
   *
   * @type {boolean}
   * @memberof StripeRefundDataModel
   */
  refundProcessingRequired?: boolean;
}
/**
 *
 * @export
 * @interface SubNavigationLinkModel
 */
export interface SubNavigationLinkModel {
  /**
   *
   * @type {string}
   * @memberof SubNavigationLinkModel
   */
  text?: string;
  /**
   *
   * @type {string}
   * @memberof SubNavigationLinkModel
   */
  url?: string;
  /**
   *
   * @type {USGATargetEnum}
   * @memberof SubNavigationLinkModel
   */
  target?: USGATargetEnum;
  /**
   *
   * @type {string}
   * @memberof SubNavigationLinkModel
   */
  alignment?: SubNavigationLinkModelAlignmentEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum SubNavigationLinkModelAlignmentEnum {
  Left = 'left',
  Center = 'center',
  Right = 'right',
}

/**
 *
 * @export
 * @interface SubNavigationLinkModelAllOf
 */
export interface SubNavigationLinkModelAllOf {
  /**
   *
   * @type {string}
   * @memberof SubNavigationLinkModelAllOf
   */
  alignment?: SubNavigationLinkModelAllOfAlignmentEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum SubNavigationLinkModelAllOfAlignmentEnum {
  Left = 'left',
  Center = 'center',
  Right = 'right',
}

/**
 *
 * @export
 * @interface TeamAdminRequestModel
 */
export interface TeamAdminRequestModel {
  /**
   *
   * @type {number}
   * @memberof TeamAdminRequestModel
   */
  id: number;
  /**
   *
   * @type {boolean}
   * @memberof TeamAdminRequestModel
   */
  complete: boolean;
  /**
   *
   * @type {AuditNewAdminModel}
   * @memberof TeamAdminRequestModel
   */
  audit: AuditNewAdminModel;
}
/**
 *
 * @export
 * @interface TeamAdminResponseModel
 */
export interface TeamAdminResponseModel {
  /**
   *
   * @type {boolean}
   * @memberof TeamAdminResponseModel
   */
  complete: boolean;
  /**
   *
   * @type {number}
   * @memberof TeamAdminResponseModel
   */
  id: number;
  /**
   *
   * @type {Array<ApplicationResponseModel>}
   * @memberof TeamAdminResponseModel
   */
  applications?: Array<ApplicationResponseModel>;
  /**
   *
   * @type {AuditModel}
   * @memberof TeamAdminResponseModel
   */
  audit: AuditModel;
}
/**
 * Represents Text Component
 * @export
 * @interface TextPlayerModel
 */
export interface TextPlayerModel {
  /**
   *
   * @type {string}
   * @memberof TextPlayerModel
   */
  text?: string;
  /**
   *
   * @type {boolean}
   * @memberof TextPlayerModel
   */
  dropcap?: boolean;
}
/**
 * Represents Two Columns Component
 * @export
 * @interface TwoColumnsLayoutModel
 */
export interface TwoColumnsLayoutModel {
  /**
   *
   * @type {string}
   * @memberof TwoColumnsLayoutModel
   */
  tabMode: TwoColumnsLayoutModelTabModeEnum;
  /**
   *
   * @type {string}
   * @memberof TwoColumnsLayoutModel
   */
  mainSide: TwoColumnsLayoutModelMainSideEnum;
  /**
   *
   * @type {string}
   * @memberof TwoColumnsLayoutModel
   */
  leftTitle?: string;
  /**
   *
   * @type {string}
   * @memberof TwoColumnsLayoutModel
   */
  rightTitle?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum TwoColumnsLayoutModelTabModeEnum {
  SHOW = 'SHOW',
  MERGE = 'MERGE',
  HIDDEN = 'HIDDEN',
}
/**
 * @export
 * @enum {string}
 */
export enum TwoColumnsLayoutModelMainSideEnum {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

/**
 * Represents Two Stage Open Player Appliance Component
 * @export
 * @interface TwoStageOpenAppliancePlayerModel
 */
export interface TwoStageOpenAppliancePlayerModel {
  /**
   *
   * @type {ApplicationHomeStepPlayerApplianceModel}
   * @memberof TwoStageOpenAppliancePlayerModel
   */
  applicationHome?: ApplicationHomeStepPlayerApplianceModel;
  /**
   *
   * @type {QualifyingExemptionStepPlayerApplianceModel}
   * @memberof TwoStageOpenAppliancePlayerModel
   */
  finalStageExemption?: QualifyingExemptionStepPlayerApplianceModel;
  /**
   *
   * @type {QualifyingExemptionStepPlayerApplianceModel}
   * @memberof TwoStageOpenAppliancePlayerModel
   */
  firstStageExemption?: QualifyingExemptionStepPlayerApplianceModel;
  /**
   *
   * @type {QualifyingLocationStepPlayerApplianceModel}
   * @memberof TwoStageOpenAppliancePlayerModel
   */
  firstStageQualifyingLocation?: QualifyingLocationStepPlayerApplianceModel;
  /**
   *
   * @type {QualifyingLocationStepPlayerApplianceModel}
   * @memberof TwoStageOpenAppliancePlayerModel
   */
  finalQualifyingLocation?: QualifyingLocationStepPlayerApplianceModel;
  /**
   *
   * @type {OpenEligibilityPlayerApplianceModel}
   * @memberof TwoStageOpenAppliancePlayerModel
   */
  eligibility?: OpenEligibilityPlayerApplianceModel;
  /**
   *
   * @type {ReviewStepPlayerApplianceModel}
   * @memberof TwoStageOpenAppliancePlayerModel
   */
  review?: ReviewStepPlayerApplianceModel;
  /**
   *
   * @type {IdModel}
   * @memberof TwoStageOpenAppliancePlayerModel
   */
  championship: IdModel;
}
/**
 * Represents US Amateur Player Appliance Component
 * @export
 * @interface USAmateurAppliancePlayerModel
 */
export interface USAmateurAppliancePlayerModel {
  /**
   *
   * @type {ApplicationHomeStepPlayerApplianceModel}
   * @memberof USAmateurAppliancePlayerModel
   */
  applicationHome?: ApplicationHomeStepPlayerApplianceModel;
  /**
   *
   * @type {AmateurStatusStepPlayerApplianceModel}
   * @memberof USAmateurAppliancePlayerModel
   */
  amateurStatus?: AmateurStatusStepPlayerApplianceModel;
  /**
   *
   * @type {QualifyingExemptionStepPlayerApplianceModel}
   * @memberof USAmateurAppliancePlayerModel
   */
  finalStageExemption?: QualifyingExemptionStepPlayerApplianceModel;
  /**
   *
   * @type {QualifyingExemptionStepPlayerApplianceModel}
   * @memberof USAmateurAppliancePlayerModel
   */
  firstStageExemption?: QualifyingExemptionStepPlayerApplianceModel;
  /**
   *
   * @type {QualifyingLocationStepPlayerApplianceModel}
   * @memberof USAmateurAppliancePlayerModel
   */
  firstStageQualifyingLocation?: QualifyingLocationStepPlayerApplianceModel;
  /**
   *
   * @type {QualifyingLocationStepPlayerApplianceModel}
   * @memberof USAmateurAppliancePlayerModel
   */
  finalQualifyingLocation?: QualifyingLocationStepPlayerApplianceModel;
  /**
   *
   * @type {EligibilityStepPlayerApplianceModel}
   * @memberof USAmateurAppliancePlayerModel
   */
  eligibility?: EligibilityStepPlayerApplianceModel;
  /**
   *
   * @type {ReviewStepPlayerApplianceModel}
   * @memberof USAmateurAppliancePlayerModel
   */
  review?: ReviewStepPlayerApplianceModel;
  /**
   *
   * @type {IdModel}
   * @memberof USAmateurAppliancePlayerModel
   */
  championship: IdModel;
}
/**
 *
 * @export
 * @interface USGACloudinaryImageModel
 */
export interface USGACloudinaryImageModel {
  /**
   *
   * @type {string}
   * @memberof USGACloudinaryImageModel
   */
  identifier?: string;
  /**
   *
   * @type {string}
   * @memberof USGACloudinaryImageModel
   */
  cropMode?: USGACloudinaryImageModelCropModeEnum;
  /**
   *
   * @type {string}
   * @memberof USGACloudinaryImageModel
   */
  gravity?: USGACloudinaryImageModelGravityEnum;
  /**
   *
   * @type {string}
   * @memberof USGACloudinaryImageModel
   */
  cloudName?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum USGACloudinaryImageModelCropModeEnum {
  Crop = 'crop',
  Pad = 'pad',
  Fill = 'fill',
  Fit = 'fit',
}
/**
 * @export
 * @enum {string}
 */
export enum USGACloudinaryImageModelGravityEnum {
  Center = 'center',
  Face = 'face',
}

/**
 *
 * @export
 * @enum {string}
 */
export enum USGATargetEnum {
  Same = 'same',
  New = 'new',
}

/**
 * Represents US-Open Player Appliance Component
 * @export
 * @interface USOpenAppliancePlayerModel
 */
export interface USOpenAppliancePlayerModel {
  /**
   *
   * @type {ApplicationHomeStepPlayerApplianceModel}
   * @memberof USOpenAppliancePlayerModel
   */
  applicationHome?: ApplicationHomeStepPlayerApplianceModel;
  /**
   *
   * @type {QualifyingExemptionStepPlayerApplianceModel}
   * @memberof USOpenAppliancePlayerModel
   */
  finalStageExemption?: QualifyingExemptionStepPlayerApplianceModel;
  /**
   *
   * @type {QualifyingExemptionStepPlayerApplianceModel}
   * @memberof USOpenAppliancePlayerModel
   */
  firstStageExemption?: QualifyingExemptionStepPlayerApplianceModel;
  /**
   *
   * @type {QualifyingLocationStepPlayerApplianceModel}
   * @memberof USOpenAppliancePlayerModel
   */
  firstStageQualifyingLocation?: QualifyingLocationStepPlayerApplianceModel;
  /**
   *
   * @type {QualifyingLocationStepPlayerApplianceModel}
   * @memberof USOpenAppliancePlayerModel
   */
  finalQualifyingLocation?: QualifyingLocationStepPlayerApplianceModel;
  /**
   *
   * @type {OpenEligibilityPlayerApplianceModel}
   * @memberof USOpenAppliancePlayerModel
   */
  eligibility?: OpenEligibilityPlayerApplianceModel;
  /**
   *
   * @type {ReviewStepPlayerApplianceModel}
   * @memberof USOpenAppliancePlayerModel
   */
  review?: ReviewStepPlayerApplianceModel;
  /**
   *
   * @type {IdModel}
   * @memberof USOpenAppliancePlayerModel
   */
  championship: IdModel;
}
/**
 *
 * @export
 * @interface UpcomingEventListModel
 */
export interface UpcomingEventListModel {
  /**
   *
   * @type {Array<UpcomingEventModel>}
   * @memberof UpcomingEventListModel
   */
  items: Array<UpcomingEventModel>;
  /**
   *
   * @type {number}
   * @memberof UpcomingEventListModel
   */
  limit: number;
  /**
   *
   * @type {number}
   * @memberof UpcomingEventListModel
   */
  start: number;
  /**
   *
   * @type {number}
   * @memberof UpcomingEventListModel
   */
  total: number;
}
/**
 *
 * @export
 * @interface UpcomingEventModel
 */
export interface UpcomingEventModel {
  /**
   *
   * @type {number}
   * @memberof UpcomingEventModel
   */
  id: number;
  /**
   *
   * @type {ChampionshipBaseModel}
   * @memberof UpcomingEventModel
   */
  championship: ChampionshipBaseModel;
  /**
   *
   * @type {Array<UpcomingEventModelExemption>}
   * @memberof UpcomingEventModel
   */
  exemption?: Array<UpcomingEventModelExemption>;
  /**
   *
   * @type {string}
   * @memberof UpcomingEventModel
   */
  additionalInfo?: string;
  /**
   *
   * @type {string}
   * @memberof UpcomingEventModel
   */
  pagePath?: string;
  /**
   *
   * @type {string}
   * @memberof UpcomingEventModel
   */
  withdrawPagePath?: string;
  /**
   *
   * @type {ApplicationProgressionEnum}
   * @memberof UpcomingEventModel
   */
  progression: ApplicationProgressionEnum;
  /**
   *
   * @type {PaymentDataPlayerModel}
   * @memberof UpcomingEventModel
   */
  payment?: PaymentDataPlayerModel;
  /**
   *
   * @type {Array<UpcomingEventModelQualifyingSites>}
   * @memberof UpcomingEventModel
   */
  qualifyingSites?: Array<UpcomingEventModelQualifyingSites>;
  /**
   *
   * @type {Array<UpcomingEventModelWaitList>}
   * @memberof UpcomingEventModel
   */
  waitList?: Array<UpcomingEventModelWaitList>;
  /**
   *
   * @type {WithdrawalResponseAdminModel}
   * @memberof UpcomingEventModel
   */
  withdrawal?: WithdrawalResponseAdminModel;
  /**
   *
   * @type {UpcomingEventModelTeam}
   * @memberof UpcomingEventModel
   */
  team?: UpcomingEventModelTeam;
}
/**
 *
 * @export
 * @interface UpcomingEventModelExemption
 */
export interface UpcomingEventModelExemption {
  /**
   *
   * @type {QualifyingLevelEnum}
   * @memberof UpcomingEventModelExemption
   */
  level: QualifyingLevelEnum;
  /**
   *
   * @type {number}
   * @memberof UpcomingEventModelExemption
   */
  number: number;
  /**
   *
   * @type {string}
   * @memberof UpcomingEventModelExemption
   */
  title: string;
  /**
   *
   * @type {boolean}
   * @memberof UpcomingEventModelExemption
   */
  requireQualifyingSelection: boolean;
}
/**
 *
 * @export
 * @interface UpcomingEventModelQualifyingSites
 */
export interface UpcomingEventModelQualifyingSites {
  /**
   *
   * @type {number}
   * @memberof UpcomingEventModelQualifyingSites
   */
  position: number;
  /**
   *
   * @type {QualifyingSiteFinalizationEnum}
   * @memberof UpcomingEventModelQualifyingSites
   */
  finalizedFinalQSite?: QualifyingSiteFinalizationEnum;
  /**
   *
   * @type {QualifyingSitePlayerModel}
   * @memberof UpcomingEventModelQualifyingSites
   */
  qualifyingSite?: QualifyingSitePlayerModel;
}
/**
 *
 * @export
 * @interface UpcomingEventModelTeam
 */
export interface UpcomingEventModelTeam {
  /**
   *
   * @type {Array<UpcomingEventModelTeamApplications>}
   * @memberof UpcomingEventModelTeam
   */
  applications?: Array<UpcomingEventModelTeamApplications>;
}
/**
 *
 * @export
 * @interface UpcomingEventModelTeamApplications
 */
export interface UpcomingEventModelTeamApplications {
  /**
   *
   * @type {number}
   * @memberof UpcomingEventModelTeamApplications
   */
  id: number;
  /**
   *
   * @type {ApplicationProgressionEnum}
   * @memberof UpcomingEventModelTeamApplications
   */
  progression: ApplicationProgressionEnum;
  /**
   *
   * @type {UpcomingEventModelTeamPrimaryContactInfo}
   * @memberof UpcomingEventModelTeamApplications
   */
  primaryContactInfo?: UpcomingEventModelTeamPrimaryContactInfo;
  /**
   *
   * @type {UpcomingEventModelTeamPrimaryContactInfo}
   * @memberof UpcomingEventModelTeamApplications
   */
  guardianContactInfo?: UpcomingEventModelTeamPrimaryContactInfo;
  /**
   *
   * @type {PaymentDataPlayerModel}
   * @memberof UpcomingEventModelTeamApplications
   */
  payment?: PaymentDataPlayerModel;
}
/**
 *
 * @export
 * @interface UpcomingEventModelTeamPrimaryContactInfo
 */
export interface UpcomingEventModelTeamPrimaryContactInfo {
  /**
   *
   * @type {string}
   * @memberof UpcomingEventModelTeamPrimaryContactInfo
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof UpcomingEventModelTeamPrimaryContactInfo
   */
  lastName?: string;
}
/**
 *
 * @export
 * @interface UpcomingEventModelWaitList
 */
export interface UpcomingEventModelWaitList {
  /**
   *
   * @type {QualifyingSitePlayerModel}
   * @memberof UpcomingEventModelWaitList
   */
  qualifyingSite: QualifyingSitePlayerModel;
  /**
   *
   * @type {WaitListAlternateTypeEnum}
   * @memberof UpcomingEventModelWaitList
   */
  alternateType?: WaitListAlternateTypeEnum;
  /**
   *
   * @type {number}
   * @memberof UpcomingEventModelWaitList
   */
  priority?: number;
}
/**
 * Represents Selection Tabs Component
 * @export
 * @interface UpcomingEventsPlayerHomeModel
 */
export interface UpcomingEventsPlayerHomeModel {
  /**
   *
   * @type {string}
   * @memberof UpcomingEventsPlayerHomeModel
   */
  withdrawTabHashParam?: string;
  /**
   *
   * @type {string}
   * @memberof UpcomingEventsPlayerHomeModel
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof UpcomingEventsPlayerHomeModel
   */
  description?: string;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum UploadFileProgressionEnum {
  NEW = 'NEW',
  READYTOPUBLISH = 'READY_TO_PUBLISH',
  PUBLISHED = 'PUBLISHED',
  MODIFIED = 'MODIFIED',
  INPROGRESS = 'IN_PROGRESS',
  FAILED = 'FAILED',
}

/**
 *
 * @export
 * @interface UploadFileStatusRequestAdminModel
 */
export interface UploadFileStatusRequestAdminModel {
  /**
   *
   * @type {Array<number>}
   * @memberof UploadFileStatusRequestAdminModel
   */
  ids?: Array<number>;
  /**
   *
   * @type {number}
   * @memberof UploadFileStatusRequestAdminModel
   */
  champId: number;
  /**
   *
   * @type {QualifyingResultActionEnum}
   * @memberof UploadFileStatusRequestAdminModel
   */
  actionType: QualifyingResultActionEnum;
}
/**
 *
 * @export
 * @interface UploadedFileAdminListModel
 */
export interface UploadedFileAdminListModel {
  /**
   *
   * @type {Array<UploadedFileAdminModel>}
   * @memberof UploadedFileAdminListModel
   */
  items: Array<UploadedFileAdminModel>;
  /**
   *
   * @type {number}
   * @memberof UploadedFileAdminListModel
   */
  limit: number;
  /**
   *
   * @type {number}
   * @memberof UploadedFileAdminListModel
   */
  start: number;
  /**
   *
   * @type {number}
   * @memberof UploadedFileAdminListModel
   */
  total: number;
  /**
   *
   * @type {UploadFileProgressionEnum}
   * @memberof UploadedFileAdminListModel
   */
  qrProgression?: UploadFileProgressionEnum;
  /**
   *
   * @type {boolean}
   * @memberof UploadedFileAdminListModel
   */
  canPublish?: boolean;
}
/**
 *
 * @export
 * @interface UploadedFileAdminModel
 */
export interface UploadedFileAdminModel {
  /**
   *
   * @type {number}
   * @memberof UploadedFileAdminModel
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof UploadedFileAdminModel
   */
  dateCreated: string;
  /**
   *
   * @type {string}
   * @memberof UploadedFileAdminModel
   */
  filename: string;
  /**
   *
   * @type {string}
   * @memberof UploadedFileAdminModel
   */
  mimeType: string;
  /**
   *
   * @type {string}
   * @memberof UploadedFileAdminModel
   */
  userId: string;
  /**
   *
   * @type {string}
   * @memberof UploadedFileAdminModel
   */
  email: string;
  /**
   *
   * @type {UploadedFileType}
   * @memberof UploadedFileAdminModel
   */
  dataType?: UploadedFileType;
  /**
   *
   * @type {UploadFileProgressionEnum}
   * @memberof UploadedFileAdminModel
   */
  progression?: UploadFileProgressionEnum;
  /**
   *
   * @type {ExemptionDeleteFetchModelApplication}
   * @memberof UploadedFileAdminModel
   */
  qualifyingSite?: ExemptionDeleteFetchModelApplication;
  /**
   *
   * @type {ExemptionDeleteFetchModelApplication}
   * @memberof UploadedFileAdminModel
   */
  application?: ExemptionDeleteFetchModelApplication;
  /**
   *
   * @type {QualifyingSiteAdminModel}
   * @memberof UploadedFileAdminModel
   */
  qs?: QualifyingSiteAdminModel;
  /**
   *
   * @type {string}
   * @memberof UploadedFileAdminModel
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof UploadedFileAdminModel
   */
  lastName?: string;
  /**
   *
   * @type {ApplicationBaseAdminModelScoringPrimaryContactInfo}
   * @memberof UploadedFileAdminModel
   */
  primaryContactInfo?: ApplicationBaseAdminModelScoringPrimaryContactInfo;
}
/**
 *
 * @export
 * @interface UploadedFileModel
 */
export interface UploadedFileModel {
  /**
   *
   * @type {string}
   * @memberof UploadedFileModel
   */
  filename: string;
  /**
   *
   * @type {string}
   * @memberof UploadedFileModel
   */
  data: string;
  /**
   *
   * @type {string}
   * @memberof UploadedFileModel
   */
  mimeType: string;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum UploadedFileType {
  QUALIFYINGRESULTS = 'QUALIFYING_RESULTS',
  LOCALQUALIFYINGRESULTS = 'LOCAL_QUALIFYING_RESULTS',
  FINALQUALIFYINGRESULTS = 'FINAL_QUALIFYING_RESULTS',
  CHAMPIONSHIPRESULTS = 'CHAMPIONSHIP_RESULTS',
  CHAMPIONSHIPINFOPDF = 'CHAMPIONSHIP_INFO_PDF',
  CLOSEDQUALIFYINGSITEREPORT = 'CLOSED_QUALIFYING_SITE_REPORT',
  CHAMPIONSHIPREDBOOKREPORT = 'CHAMPIONSHIP_RED_BOOK_REPORT',
  FINALIZEDCARDS = 'FINALIZED_CARDS',
  REPORTS = 'REPORTS',
  SENDEMAIL = 'SEND_EMAIL',
  ADAPTIVEOPEN = 'ADAPTIVE_OPEN',
  PERFORMANCEVIOLATIONREPORT = 'PERFORMANCE_VIOLATION_REPORT',
  BULKENTRYFEEBALANCEPAYMENT = 'BULK_ENTRY_FEE_BALANCE_PAYMENT',
}

/**
 *
 * @export
 * @interface UploadedFileWithDataAdminListModel
 */
export interface UploadedFileWithDataAdminListModel {
  /**
   *
   * @type {Array<UploadedFileWithDataAdminModel>}
   * @memberof UploadedFileWithDataAdminListModel
   */
  items: Array<UploadedFileWithDataAdminModel>;
}
/**
 *
 * @export
 * @interface UploadedFileWithDataAdminModel
 */
export interface UploadedFileWithDataAdminModel {
  /**
   *
   * @type {number}
   * @memberof UploadedFileWithDataAdminModel
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof UploadedFileWithDataAdminModel
   */
  dateCreated: string;
  /**
   *
   * @type {string}
   * @memberof UploadedFileWithDataAdminModel
   */
  filename: string;
  /**
   *
   * @type {string}
   * @memberof UploadedFileWithDataAdminModel
   */
  mimeType: string;
  /**
   *
   * @type {string}
   * @memberof UploadedFileWithDataAdminModel
   */
  userId: string;
  /**
   *
   * @type {string}
   * @memberof UploadedFileWithDataAdminModel
   */
  email: string;
  /**
   *
   * @type {UploadedFileType}
   * @memberof UploadedFileWithDataAdminModel
   */
  dataType?: UploadedFileType;
  /**
   *
   * @type {UploadFileProgressionEnum}
   * @memberof UploadedFileWithDataAdminModel
   */
  progression?: UploadFileProgressionEnum;
  /**
   *
   * @type {ExemptionDeleteFetchModelApplication}
   * @memberof UploadedFileWithDataAdminModel
   */
  qualifyingSite?: ExemptionDeleteFetchModelApplication;
  /**
   *
   * @type {ExemptionDeleteFetchModelApplication}
   * @memberof UploadedFileWithDataAdminModel
   */
  application?: ExemptionDeleteFetchModelApplication;
  /**
   *
   * @type {QualifyingSiteAdminModel}
   * @memberof UploadedFileWithDataAdminModel
   */
  qs?: QualifyingSiteAdminModel;
  /**
   *
   * @type {string}
   * @memberof UploadedFileWithDataAdminModel
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof UploadedFileWithDataAdminModel
   */
  lastName?: string;
  /**
   *
   * @type {ApplicationBaseAdminModelScoringPrimaryContactInfo}
   * @memberof UploadedFileWithDataAdminModel
   */
  primaryContactInfo?: ApplicationBaseAdminModelScoringPrimaryContactInfo;
  /**
   *
   * @type {string}
   * @memberof UploadedFileWithDataAdminModel
   */
  data: string;
}
/**
 *
 * @export
 * @interface UploadedFileWithDataAdminModelAllOf
 */
export interface UploadedFileWithDataAdminModelAllOf {
  /**
   *
   * @type {string}
   * @memberof UploadedFileWithDataAdminModelAllOf
   */
  data: string;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum UploadedPdfFileType {
  CHAMPIONSHIPINFOPDF = 'CHAMPIONSHIP_INFO_PDF',
  CHAMPIONSHIPREDBOOKREPORT = 'CHAMPIONSHIP_RED_BOOK_REPORT',
  FINALIZEDCARDS = 'FINALIZED_CARDS',
}

/**
 *
 * @export
 * @interface UserAdminListModel
 */
export interface UserAdminListModel {
  /**
   *
   * @type {Array<UserResponseModel>}
   * @memberof UserAdminListModel
   */
  items: Array<UserResponseModel>;
  /**
   *
   * @type {number}
   * @memberof UserAdminListModel
   */
  limit: number;
  /**
   *
   * @type {number}
   * @memberof UserAdminListModel
   */
  start: number;
  /**
   *
   * @type {number}
   * @memberof UserAdminListModel
   */
  total: number;
}
/**
 *
 * @export
 * @interface UserAuth0Model
 */
export interface UserAuth0Model {
  /**
   *
   * @type {number}
   * @memberof UserAuth0Model
   */
  agaId?: number;
  /**
   *
   * @type {Array<number>}
   * @memberof UserAuth0Model
   */
  championshipTypes?: Array<number>;
  /**
   *
   * @type {Array<string>}
   * @memberof UserAuth0Model
   */
  roles?: Array<string>;
}
/**
 *
 * @export
 * @interface UserCreateModel
 */
export interface UserCreateModel {
  /**
   *
   * @type {string}
   * @memberof UserCreateModel
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof UserCreateModel
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof UserCreateModel
   */
  lastName: string;
  /**
   *
   * @type {Array<Auth0UserRolesEnum>}
   * @memberof UserCreateModel
   */
  roles: Array<Auth0UserRolesEnum>;
  /**
   *
   * @type {number}
   * @memberof UserCreateModel
   */
  agaId?: number;
  /**
   *
   * @type {Array<number>}
   * @memberof UserCreateModel
   */
  championshipTypes?: Array<number>;
}
/**
 *
 * @export
 * @interface UserCreatePlayerModel
 */
export interface UserCreatePlayerModel {
  /**
   * http://emailregex.com/
   * @type {string}
   * @memberof UserCreatePlayerModel
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof UserCreatePlayerModel
   */
  password: string;
  /**
   *
   * @type {string}
   * @memberof UserCreatePlayerModel
   */
  dob: string;
  /**
   *
   * @type {PlayerStatusEnum}
   * @memberof UserCreatePlayerModel
   */
  playerStatus: PlayerStatusEnum;
  /**
   *
   * @type {string}
   * @memberof UserCreatePlayerModel
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof UserCreatePlayerModel
   */
  lastName: string;
}
/**
 *
 * @export
 * @interface UserLoginPlayerModel
 */
export interface UserLoginPlayerModel {
  /**
   *
   * @type {string}
   * @memberof UserLoginPlayerModel
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof UserLoginPlayerModel
   */
  password: string;
}
/**
 *
 * @export
 * @interface UserResponseModel
 */
export interface UserResponseModel {
  /**
   *
   * @type {string}
   * @memberof UserResponseModel
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof UserResponseModel
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof UserResponseModel
   */
  lastName: string;
  /**
   *
   * @type {Array<Auth0UserRolesEnum>}
   * @memberof UserResponseModel
   */
  roles: Array<Auth0UserRolesEnum>;
  /**
   *
   * @type {number}
   * @memberof UserResponseModel
   */
  agaId?: number;
  /**
   *
   * @type {Array<number>}
   * @memberof UserResponseModel
   */
  championshipTypes?: Array<number>;
  /**
   *
   * @type {string}
   * @memberof UserResponseModel
   */
  id: string;
  /**
   *
   * @type {boolean}
   * @memberof UserResponseModel
   */
  auth0EmailVerified?: boolean;
  /**
   *
   * @type {string}
   * @memberof UserResponseModel
   */
  aga?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof UserResponseModel
   */
  championships?: Array<string>;
}
/**
 *
 * @export
 * @interface UserResponseModelAllOf
 */
export interface UserResponseModelAllOf {
  /**
   *
   * @type {string}
   * @memberof UserResponseModelAllOf
   */
  id: string;
  /**
   *
   * @type {boolean}
   * @memberof UserResponseModelAllOf
   */
  auth0EmailVerified?: boolean;
  /**
   *
   * @type {string}
   * @memberof UserResponseModelAllOf
   */
  aga?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof UserResponseModelAllOf
   */
  championships?: Array<string>;
}
/**
 *
 * @export
 * @interface VoidRefundPaymentAdminModel
 */
export interface VoidRefundPaymentAdminModel {
  /**
   *
   * @type {number}
   * @memberof VoidRefundPaymentAdminModel
   */
  appId: number;
  /**
   *
   * @type {boolean}
   * @memberof VoidRefundPaymentAdminModel
   */
  notifyPlayer?: boolean;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum WaitListAlternateTypeEnum {
  FIRSTALTERNATE = 'FIRST_ALTERNATE',
  SECONDALTERNATE = 'SECOND_ALTERNATE',
  THIRDALTERNATE = 'THIRD_ALTERNATE',
}

/**
 *
 * @export
 * @interface WaitListResponseModel
 */
export interface WaitListResponseModel {
  /**
   *
   * @type {boolean}
   * @memberof WaitListResponseModel
   */
  isError?: boolean;
  /**
   *
   * @type {string}
   * @memberof WaitListResponseModel
   */
  errorMessage?: string;
  /**
   *
   * @type {number}
   * @memberof WaitListResponseModel
   */
  eventCode?: number;
}
/**
 *
 * @export
 * @interface WithdrawPaymentHistoryModel
 */
export interface WithdrawPaymentHistoryModel {
  /**
   *
   * @type {string}
   * @memberof WithdrawPaymentHistoryModel
   */
  authTime?: string;
  /**
   *
   * @type {PaymentTypesEnum}
   * @memberof WithdrawPaymentHistoryModel
   */
  reqMerchantDefinedData4?: PaymentTypesEnum;
  /**
   *
   * @type {string}
   * @memberof WithdrawPaymentHistoryModel
   */
  decision?: string;
  /**
   *
   * @type {string}
   * @memberof WithdrawPaymentHistoryModel
   */
  dateCreated?: string;
  /**
   *
   * @type {RefundStatusEnum}
   * @memberof WithdrawPaymentHistoryModel
   */
  status?: RefundStatusEnum;
  /**
   *
   * @type {string}
   * @memberof WithdrawPaymentHistoryModel
   */
  message?: string;
  /**
   *
   * @type {string}
   * @memberof WithdrawPaymentHistoryModel
   */
  transactionId?: string;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum WithdrawalReasonEnum {
  ILLNESS = 'ILLNESS',
  INJURY = 'INJURY',
  DUPLICATE = 'DUPLICATE',
  GOLFCONFLICT = 'GOLF_CONFLICT',
  BLACKLIST = 'BLACKLIST',
  WORKCONFLICT = 'WORK_CONFLICT',
  SCHOOLCONFLICT = 'SCHOOL_CONFLICT',
  OTHER = 'OTHER',
}

/**
 *
 * @export
 * @interface WithdrawalResponseAdminModel
 */
export interface WithdrawalResponseAdminModel {
  /**
   *
   * @type {number}
   * @memberof WithdrawalResponseAdminModel
   */
  appId: number;
  /**
   *
   * @type {string}
   * @memberof WithdrawalResponseAdminModel
   */
  dateCreated?: string;
  /**
   *
   * @type {string}
   * @memberof WithdrawalResponseAdminModel
   */
  dateProcessed?: string;
  /**
   *
   * @type {number}
   * @memberof WithdrawalResponseAdminModel
   */
  amount?: number;
  /**
   *
   * @type {PaymentTypesEnum}
   * @memberof WithdrawalResponseAdminModel
   */
  req_merchant_defined_data4?: PaymentTypesEnum;
  /**
   *
   * @type {WithdrawalReasonEnum}
   * @memberof WithdrawalResponseAdminModel
   */
  withdrawalReason: WithdrawalReasonEnum;
  /**
   *
   * @type {RequestReceivedByEnum}
   * @memberof WithdrawalResponseAdminModel
   */
  requestReceivedBy: RequestReceivedByEnum;
  /**
   *
   * @type {RefundStatusEnum}
   * @memberof WithdrawalResponseAdminModel
   */
  status: RefundStatusEnum;
  /**
   *
   * @type {string}
   * @memberof WithdrawalResponseAdminModel
   */
  comment: string;
  /**
   *
   * @type {string}
   * @memberof WithdrawalResponseAdminModel
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof WithdrawalResponseAdminModel
   */
  withdrawnBy: string;
  /**
   *
   * @type {boolean}
   * @memberof WithdrawalResponseAdminModel
   */
  isEdited?: boolean;
  /**
   *
   * @type {string}
   * @memberof WithdrawalResponseAdminModel
   */
  message?: string;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum WorkflowTypeEnum {
  AMATEUR = 'AMATEUR',
  USOPEN = 'USOPEN',
  OPEN = 'OPEN',
  FOURBALL = 'FOURBALL',
  ADAPTIVE = 'ADAPTIVE',
  USAMATEUR = 'USAMATEUR',
  TWOSTAGEOPEN = 'TWOSTAGEOPEN',
}

/**
 * AdminApi - axios parameter creator
 * @export
 */
export const AdminApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     * email template keys
     * @summary Returns the list of email template keys
     * @param {EmailTemplateTypeEnum} type Template Type
     * @param {EmailTemplateServiceTypeEnum} [serviceTemplateType] Service Template Type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminEmailTemplateKeysGet: async (
      type: EmailTemplateTypeEnum,
      serviceTemplateType?: EmailTemplateServiceTypeEnum,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'type' is not null or undefined
      if (type === null || type === undefined) {
        throw new RequiredError(
          'type',
          'Required parameter type was null or undefined when calling binChampadminAdminEmailTemplateKeysGet.'
        );
      }
      const localVarPath = `/bin/champadmin/admin/email/template/keys`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (serviceTemplateType !== undefined) {
        localVarQueryParameter['serviceTemplateType'] = serviceTemplateType;
      }

      if (type !== undefined) {
        localVarQueryParameter['type'] = type;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [limit]
     * @param {number} [start]
     * @param {string} [query] %template name%
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {EmailTemplateTypeEnum} [type]
     * @param {EmailTemplateServiceTypeEnum} [serviceType]
     * @param {number} [champId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminEmailTemplateListGet: async (
      limit?: number,
      start?: number,
      query?: string,
      sortingFilter?: SortingFilterEnum,
      type?: EmailTemplateTypeEnum,
      serviceType?: EmailTemplateServiceTypeEnum,
      champId?: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/admin/email/template/list`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start;
      }

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (sortingFilter !== undefined) {
        localVarQueryParameter['sortingFilter'] = sortingFilter;
      }

      if (type !== undefined) {
        localVarQueryParameter['type'] = type;
      }

      if (serviceType !== undefined) {
        localVarQueryParameter['serviceType'] = serviceType;
      }

      if (champId !== undefined) {
        localVarQueryParameter['champId'] = champId;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * message keys
     * @summary Returns the list of message  keys
     * @param {MessageTypeEnum} type Message Type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminMessageKeysGet: async (
      type: MessageTypeEnum,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'type' is not null or undefined
      if (type === null || type === undefined) {
        throw new RequiredError(
          'type',
          'Required parameter type was null or undefined when calling binChampadminAdminMessageKeysGet.'
        );
      }
      const localVarPath = `/bin/champadmin/admin/message/keys`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (type !== undefined) {
        localVarQueryParameter['type'] = type;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Email Template Service Type Enum
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    champAdminAdminEmailTemplateServiceTypeEnumGet: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/champAdmin/admin/email/template/service/type/enum`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns the list of championships sorted by position and filtered by year
     * @summary Get list of championships
     * @param {Array<number>} [year]
     * @param {number} [limit]
     * @param {number} [start]
     * @param {boolean} [qstatus]
     * @param {boolean} [needsReview]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getListOfChampionships: async (
      year?: Array<number>,
      limit?: number,
      start?: number,
      qstatus?: boolean,
      needsReview?: boolean,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/admin/championships`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (year) {
        localVarQueryParameter['year'] = year;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start;
      }

      if (qstatus !== undefined) {
        localVarQueryParameter['qstatus'] = qstatus;
      }

      if (needsReview !== undefined) {
        localVarQueryParameter['needsReview'] = needsReview;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns the list of championships sorted by position and filtered by year
     * @summary Get list of championships
     * @param {Array<number>} [year]
     * @param {number} [limit]
     * @param {number} [start]
     * @param {boolean} [qstatus]
     * @param {boolean} [needsReview]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getListOfChampionshipsLight: async (
      year?: Array<number>,
      limit?: number,
      start?: number,
      qstatus?: boolean,
      needsReview?: boolean,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/admin/championships/light`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (year) {
        localVarQueryParameter['year'] = year;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start;
      }

      if (qstatus !== undefined) {
        localVarQueryParameter['qstatus'] = qstatus;
      }

      if (needsReview !== undefined) {
        localVarQueryParameter['needsReview'] = needsReview;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AdminApi - functional programming interface
 * @export
 */
export const AdminApiFp = function(configuration?: Configuration) {
  return {
    /**
     * email template keys
     * @summary Returns the list of email template keys
     * @param {EmailTemplateTypeEnum} type Template Type
     * @param {EmailTemplateServiceTypeEnum} [serviceTemplateType] Service Template Type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminEmailTemplateKeysGet(
      type: EmailTemplateTypeEnum,
      serviceTemplateType?: EmailTemplateServiceTypeEnum,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailTemplateKeyList>> {
      const localVarAxiosArgs = await AdminApiAxiosParamCreator(
        configuration
      ).binChampadminAdminEmailTemplateKeysGet(type, serviceTemplateType, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} [limit]
     * @param {number} [start]
     * @param {string} [query] %template name%
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {EmailTemplateTypeEnum} [type]
     * @param {EmailTemplateServiceTypeEnum} [serviceType]
     * @param {number} [champId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminEmailTemplateListGet(
      limit?: number,
      start?: number,
      query?: string,
      sortingFilter?: SortingFilterEnum,
      type?: EmailTemplateTypeEnum,
      serviceType?: EmailTemplateServiceTypeEnum,
      champId?: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailTemplateListModel>> {
      const localVarAxiosArgs = await AdminApiAxiosParamCreator(
        configuration
      ).binChampadminAdminEmailTemplateListGet(
        limit,
        start,
        query,
        sortingFilter,
        type,
        serviceType,
        champId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * message keys
     * @summary Returns the list of message  keys
     * @param {MessageTypeEnum} type Message Type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminMessageKeysGet(
      type: MessageTypeEnum,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageKeyList>> {
      const localVarAxiosArgs = await AdminApiAxiosParamCreator(
        configuration
      ).binChampadminAdminMessageKeysGet(type, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Email Template Service Type Enum
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async champAdminAdminEmailTemplateServiceTypeEnumGet(
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailTemplateServiceTypeEnum>
    > {
      const localVarAxiosArgs = await AdminApiAxiosParamCreator(
        configuration
      ).champAdminAdminEmailTemplateServiceTypeEnumGet(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Returns the list of championships sorted by position and filtered by year
     * @summary Get list of championships
     * @param {Array<number>} [year]
     * @param {number} [limit]
     * @param {number} [start]
     * @param {boolean} [qstatus]
     * @param {boolean} [needsReview]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getListOfChampionships(
      year?: Array<number>,
      limit?: number,
      start?: number,
      qstatus?: boolean,
      needsReview?: boolean,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChampionshipAdminListModel>
    > {
      const localVarAxiosArgs = await AdminApiAxiosParamCreator(
        configuration
      ).getListOfChampionships(year, limit, start, qstatus, needsReview, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Returns the list of championships sorted by position and filtered by year
     * @summary Get list of championships
     * @param {Array<number>} [year]
     * @param {number} [limit]
     * @param {number} [start]
     * @param {boolean} [qstatus]
     * @param {boolean} [needsReview]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getListOfChampionshipsLight(
      year?: Array<number>,
      limit?: number,
      start?: number,
      qstatus?: boolean,
      needsReview?: boolean,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChampionshipLightweightListModel>
    > {
      const localVarAxiosArgs = await AdminApiAxiosParamCreator(
        configuration
      ).getListOfChampionshipsLight(year, limit, start, qstatus, needsReview, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * AdminApi - factory interface
 * @export
 */
export const AdminApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     * email template keys
     * @summary Returns the list of email template keys
     * @param {EmailTemplateTypeEnum} type Template Type
     * @param {EmailTemplateServiceTypeEnum} [serviceTemplateType] Service Template Type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminEmailTemplateKeysGet(
      type: EmailTemplateTypeEnum,
      serviceTemplateType?: EmailTemplateServiceTypeEnum,
      options?: any
    ): AxiosPromise<EmailTemplateKeyList> {
      return AdminApiFp(configuration)
        .binChampadminAdminEmailTemplateKeysGet(type, serviceTemplateType, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} [limit]
     * @param {number} [start]
     * @param {string} [query] %template name%
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {EmailTemplateTypeEnum} [type]
     * @param {EmailTemplateServiceTypeEnum} [serviceType]
     * @param {number} [champId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminEmailTemplateListGet(
      limit?: number,
      start?: number,
      query?: string,
      sortingFilter?: SortingFilterEnum,
      type?: EmailTemplateTypeEnum,
      serviceType?: EmailTemplateServiceTypeEnum,
      champId?: number,
      options?: any
    ): AxiosPromise<EmailTemplateListModel> {
      return AdminApiFp(configuration)
        .binChampadminAdminEmailTemplateListGet(
          limit,
          start,
          query,
          sortingFilter,
          type,
          serviceType,
          champId,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * message keys
     * @summary Returns the list of message  keys
     * @param {MessageTypeEnum} type Message Type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminMessageKeysGet(
      type: MessageTypeEnum,
      options?: any
    ): AxiosPromise<MessageKeyList> {
      return AdminApiFp(configuration)
        .binChampadminAdminMessageKeysGet(type, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Email Template Service Type Enum
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    champAdminAdminEmailTemplateServiceTypeEnumGet(
      options?: any
    ): AxiosPromise<EmailTemplateServiceTypeEnum> {
      return AdminApiFp(configuration)
        .champAdminAdminEmailTemplateServiceTypeEnumGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns the list of championships sorted by position and filtered by year
     * @summary Get list of championships
     * @param {Array<number>} [year]
     * @param {number} [limit]
     * @param {number} [start]
     * @param {boolean} [qstatus]
     * @param {boolean} [needsReview]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getListOfChampionships(
      year?: Array<number>,
      limit?: number,
      start?: number,
      qstatus?: boolean,
      needsReview?: boolean,
      options?: any
    ): AxiosPromise<ChampionshipAdminListModel> {
      return AdminApiFp(configuration)
        .getListOfChampionships(year, limit, start, qstatus, needsReview, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns the list of championships sorted by position and filtered by year
     * @summary Get list of championships
     * @param {Array<number>} [year]
     * @param {number} [limit]
     * @param {number} [start]
     * @param {boolean} [qstatus]
     * @param {boolean} [needsReview]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getListOfChampionshipsLight(
      year?: Array<number>,
      limit?: number,
      start?: number,
      qstatus?: boolean,
      needsReview?: boolean,
      options?: any
    ): AxiosPromise<ChampionshipLightweightListModel> {
      return AdminApiFp(configuration)
        .getListOfChampionshipsLight(year, limit, start, qstatus, needsReview, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * AdminApi - object-oriented interface
 * @export
 * @class AdminApi
 * @extends {BaseAPI}
 */
export class AdminApi extends BaseAPI {
  /**
   * email template keys
   * @summary Returns the list of email template keys
   * @param {EmailTemplateTypeEnum} type Template Type
   * @param {EmailTemplateServiceTypeEnum} [serviceTemplateType] Service Template Type
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public binChampadminAdminEmailTemplateKeysGet(
    type: EmailTemplateTypeEnum,
    serviceTemplateType?: EmailTemplateServiceTypeEnum,
    options?: any
  ) {
    return AdminApiFp(this.configuration)
      .binChampadminAdminEmailTemplateKeysGet(type, serviceTemplateType, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [limit]
   * @param {number} [start]
   * @param {string} [query] %template name%
   * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
   * @param {EmailTemplateTypeEnum} [type]
   * @param {EmailTemplateServiceTypeEnum} [serviceType]
   * @param {number} [champId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public binChampadminAdminEmailTemplateListGet(
    limit?: number,
    start?: number,
    query?: string,
    sortingFilter?: SortingFilterEnum,
    type?: EmailTemplateTypeEnum,
    serviceType?: EmailTemplateServiceTypeEnum,
    champId?: number,
    options?: any
  ) {
    return AdminApiFp(this.configuration)
      .binChampadminAdminEmailTemplateListGet(
        limit,
        start,
        query,
        sortingFilter,
        type,
        serviceType,
        champId,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * message keys
   * @summary Returns the list of message  keys
   * @param {MessageTypeEnum} type Message Type
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public binChampadminAdminMessageKeysGet(type: MessageTypeEnum, options?: any) {
    return AdminApiFp(this.configuration)
      .binChampadminAdminMessageKeysGet(type, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Email Template Service Type Enum
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public champAdminAdminEmailTemplateServiceTypeEnumGet(options?: any) {
    return AdminApiFp(this.configuration)
      .champAdminAdminEmailTemplateServiceTypeEnumGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns the list of championships sorted by position and filtered by year
   * @summary Get list of championships
   * @param {Array<number>} [year]
   * @param {number} [limit]
   * @param {number} [start]
   * @param {boolean} [qstatus]
   * @param {boolean} [needsReview]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public getListOfChampionships(
    year?: Array<number>,
    limit?: number,
    start?: number,
    qstatus?: boolean,
    needsReview?: boolean,
    options?: any
  ) {
    return AdminApiFp(this.configuration)
      .getListOfChampionships(year, limit, start, qstatus, needsReview, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns the list of championships sorted by position and filtered by year
   * @summary Get list of championships
   * @param {Array<number>} [year]
   * @param {number} [limit]
   * @param {number} [start]
   * @param {boolean} [qstatus]
   * @param {boolean} [needsReview]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public getListOfChampionshipsLight(
    year?: Array<number>,
    limit?: number,
    start?: number,
    qstatus?: boolean,
    needsReview?: boolean,
    options?: any
  ) {
    return AdminApiFp(this.configuration)
      .getListOfChampionshipsLight(year, limit, start, qstatus, needsReview, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Sendgrid send mail EP
     * @param {EmailTemplateWithAttachedFilesModel} [emailTemplateWithAttachedFilesModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiSendgridComV3MailSendPost: async (
      emailTemplateWithAttachedFilesModel?: EmailTemplateWithAttachedFilesModel,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/sendgrid/com/v3/mail/send`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof emailTemplateWithAttachedFilesModel !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(
            emailTemplateWithAttachedFilesModel !== undefined
              ? emailTemplateWithAttachedFilesModel
              : {}
          )
        : emailTemplateWithAttachedFilesModel || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Delete AGA by id
     * @summary Remove AGA by its id
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminAgaByIdDelete: async (
      id: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling binChampadminAdminAgaByIdDelete.'
        );
      }
      const localVarPath = `/bin/champadmin/admin/agaById`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (id !== undefined) {
        localVarQueryParameter['id'] = id;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns AGA by Id
     * @summary Get AGA by Id
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminAgaByIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling binChampadminAdminAgaByIdGet.'
        );
      }
      const localVarPath = `/bin/champadmin/admin/agaById`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (id !== undefined) {
        localVarQueryParameter['id'] = id;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns list of AGA
     * @summary Get list of AGA
     * @param {number} [limit]
     * @param {number} [start]
     * @param {string} [query] Optional search query to filter out AGA by names.
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminAgaGet: async (
      limit?: number,
      start?: number,
      query?: string,
      sortingFilter?: SortingFilterEnum,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/admin/aga`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start;
      }

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (sortingFilter !== undefined) {
        localVarQueryParameter['sortingFilter'] = sortingFilter;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Submit new AGA
     * @summary Save new AGA
     * @param {AgaBaseModel} [agaBaseModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminAgaPost: async (
      agaBaseModel?: AgaBaseModel,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/admin/aga`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof agaBaseModel !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(agaBaseModel !== undefined ? agaBaseModel : {})
        : agaBaseModel || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Updates AGA entry
     * @summary Update AGA
     * @param {AgaEditModel} [agaEditModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminAgaUpdatePost: async (
      agaEditModel?: AgaEditModel,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/admin/aga/update`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof agaEditModel !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(agaEditModel !== undefined ? agaEditModel : {})
        : agaEditModel || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns list of AGA
     * @summary Get filtered list of AGA Users
     * @param {number} [limit]
     * @param {number} [start]
     * @param {string} [query] Expression to filter entries (e.g. %word%)
     * @param {number} [champId]
     * @param {number} [year]
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminAgaUsersListGet: async (
      limit?: number,
      start?: number,
      query?: string,
      champId?: number,
      year?: number,
      sortingFilter?: SortingFilterEnum,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/admin/aga/users/list`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start;
      }

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (champId !== undefined) {
        localVarQueryParameter['champId'] = champId;
      }

      if (year !== undefined) {
        localVarQueryParameter['year'] = year;
      }

      if (sortingFilter !== undefined) {
        localVarQueryParameter['sortingFilter'] = sortingFilter;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Saves or updates player application
     * @summary Create new player application
     * @param {string} auth0Id Auth0Id of player to create application for
     * @param {ApplicationProgressionEnum} applicationStatus Target Application Status
     * @param {number} [appId] id of another player app to form team with
     * @param {ApplicationPlayerModel} [applicationPlayerModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminApplicationCreatePost: async (
      auth0Id: string,
      applicationStatus: ApplicationProgressionEnum,
      appId?: number,
      applicationPlayerModel?: ApplicationPlayerModel,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'auth0Id' is not null or undefined
      if (auth0Id === null || auth0Id === undefined) {
        throw new RequiredError(
          'auth0Id',
          'Required parameter auth0Id was null or undefined when calling binChampadminAdminApplicationCreatePost.'
        );
      }
      // verify required parameter 'applicationStatus' is not null or undefined
      if (applicationStatus === null || applicationStatus === undefined) {
        throw new RequiredError(
          'applicationStatus',
          'Required parameter applicationStatus was null or undefined when calling binChampadminAdminApplicationCreatePost.'
        );
      }
      const localVarPath = `/bin/champadmin/admin/application/create`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (appId !== undefined) {
        localVarQueryParameter['appId'] = appId;
      }

      if (auth0Id !== undefined) {
        localVarQueryParameter['auth0Id'] = auth0Id;
      }

      if (applicationStatus !== undefined) {
        localVarQueryParameter['applicationStatus'] = applicationStatus;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof applicationPlayerModel !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(applicationPlayerModel !== undefined ? applicationPlayerModel : {})
        : applicationPlayerModel || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Is used to get application data
     * @summary Get player application
     * @param {number} appId id of application to return application data for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminApplicationGet: async (
      appId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'appId' is not null or undefined
      if (appId === null || appId === undefined) {
        throw new RequiredError(
          'appId',
          'Required parameter appId was null or undefined when calling binChampadminAdminApplicationGet.'
        );
      }
      const localVarPath = `/bin/champadmin/admin/application`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (appId !== undefined) {
        localVarQueryParameter['appId'] = appId;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Updates player application
     * @summary Save player application
     * @param {ApplicationAdminRequestModel} [applicationAdminRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminApplicationPost: async (
      applicationAdminRequestModel?: ApplicationAdminRequestModel,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/admin/application`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof applicationAdminRequestModel !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(
            applicationAdminRequestModel !== undefined ? applicationAdminRequestModel : {}
          )
        : applicationAdminRequestModel || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get championship\'s applications summary
     * @param {number} champId id of championship to return applications summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminApplicationSummaryGet: async (
      champId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'champId' is not null or undefined
      if (champId === null || champId === undefined) {
        throw new RequiredError(
          'champId',
          'Required parameter champId was null or undefined when calling binChampadminAdminApplicationSummaryGet.'
        );
      }
      const localVarPath = `/bin/champadmin/admin/application/summary`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (champId !== undefined) {
        localVarQueryParameter['champId'] = champId;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns status that indicates was application updated or not
     * @summary Re-Validate application reinstatement
     * @param {number} id Id to verify by
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminApplicationValidateARGet: async (
      id: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling binChampadminAdminApplicationValidateARGet.'
        );
      }
      const localVarPath = `/bin/champadmin/admin/application/validateAR`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (id !== undefined) {
        localVarQueryParameter['id'] = id;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns multi filtered list of applications
     * @summary Get filtered list of applications
     * @param {number} champId Filter by championship id
     * @param {number} [limit]
     * @param {number} [start]
     * @param {string} [query] Optional full text search query
     * @param {Array<BlacklistStatusEnum>} [blacklistStatus]
     * @param {Array<ApplicationProgressionEnum>} [applicationStatus]
     * @param {Array<number>} [qualifyingSiteIds]
     * @param {Array<number>} [exemptionIds]
     * @param {QualifyingStatusEnum} [qualifyingStatus]
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminApplicationsBulkWithdrawGet: async (
      champId: number,
      limit?: number,
      start?: number,
      query?: string,
      blacklistStatus?: Array<BlacklistStatusEnum>,
      applicationStatus?: Array<ApplicationProgressionEnum>,
      qualifyingSiteIds?: Array<number>,
      exemptionIds?: Array<number>,
      qualifyingStatus?: QualifyingStatusEnum,
      sortingFilter?: SortingFilterEnum,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'champId' is not null or undefined
      if (champId === null || champId === undefined) {
        throw new RequiredError(
          'champId',
          'Required parameter champId was null or undefined when calling binChampadminAdminApplicationsBulkWithdrawGet.'
        );
      }
      const localVarPath = `/bin/champadmin/admin/applications/bulk/withdraw`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (champId !== undefined) {
        localVarQueryParameter['champId'] = champId;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start;
      }

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (blacklistStatus) {
        localVarQueryParameter['blacklistStatus'] = blacklistStatus;
      }

      if (applicationStatus) {
        localVarQueryParameter['applicationStatus'] = applicationStatus;
      }

      if (qualifyingSiteIds) {
        localVarQueryParameter['qualifyingSiteIds'] = qualifyingSiteIds;
      }

      if (exemptionIds) {
        localVarQueryParameter['exemptionIds'] = exemptionIds;
      }

      if (qualifyingStatus !== undefined) {
        localVarQueryParameter['qualifyingStatus'] = qualifyingStatus;
      }

      if (sortingFilter !== undefined) {
        localVarQueryParameter['sortingFilter'] = sortingFilter;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns list of applications
     * @summary Get filtered list of applications for custom email
     * @param {number} [limit]
     * @param {number} [start]
     * @param {number} [year] Optional Filter by year id
     * @param {number} [champId] Optional Filter by championship id
     * @param {string} [query] Optional First/Last Name search query
     * @param {string} [startDate] Optional start qualifying date range
     * @param {string} [endDate] Optional end qualifying date range
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {PlayerStatusEnum} [playerStatus] Optional Player Status Filter
     * @param {ApplicationProgressionEnum} [applicationStatus] Optional Application Status Filter
     * @param {QualifyingStatusEnum} [qualifyingStatus] Optional Application Status Filter
     * @param {string} [wagrRank] Optional WAGR Rank Filter
     * @param {boolean} [notablePlayer] Optional Notable Player Filter
     * @param {boolean} [adaRequest] Optional ADA Request Filter
     * @param {BlacklistStatusEnum} [blacklist] Optional Blacklist Filter
     * @param {boolean} [watchlist] Optional Watchlist Filter
     * @param {number} [age] Optional Age Filter
     * @param {string} [handicapIndex] Optional Handicap Index Filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminApplicationsCustomEmailGet: async (
      limit?: number,
      start?: number,
      year?: number,
      champId?: number,
      query?: string,
      startDate?: string,
      endDate?: string,
      sortingFilter?: SortingFilterEnum,
      playerStatus?: PlayerStatusEnum,
      applicationStatus?: ApplicationProgressionEnum,
      qualifyingStatus?: QualifyingStatusEnum,
      wagrRank?: string,
      notablePlayer?: boolean,
      adaRequest?: boolean,
      blacklist?: BlacklistStatusEnum,
      watchlist?: boolean,
      age?: number,
      handicapIndex?: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/admin/applications/custom/email`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start;
      }

      if (year !== undefined) {
        localVarQueryParameter['year'] = year;
      }

      if (champId !== undefined) {
        localVarQueryParameter['champId'] = champId;
      }

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (startDate !== undefined) {
        localVarQueryParameter['startDate'] =
          (startDate as any) instanceof Date
            ? (startDate as any).toISOString().substr(0, 10)
            : startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['endDate'] =
          (endDate as any) instanceof Date ? (endDate as any).toISOString().substr(0, 10) : endDate;
      }

      if (sortingFilter !== undefined) {
        localVarQueryParameter['sortingFilter'] = sortingFilter;
      }

      if (playerStatus !== undefined) {
        localVarQueryParameter['playerStatus'] = playerStatus;
      }

      if (applicationStatus !== undefined) {
        localVarQueryParameter['applicationStatus'] = applicationStatus;
      }

      if (qualifyingStatus !== undefined) {
        localVarQueryParameter['qualifyingStatus'] = qualifyingStatus;
      }

      if (wagrRank !== undefined) {
        localVarQueryParameter['wagrRank'] = wagrRank;
      }

      if (notablePlayer !== undefined) {
        localVarQueryParameter['notablePlayer'] = notablePlayer;
      }

      if (adaRequest !== undefined) {
        localVarQueryParameter['adaRequest'] = adaRequest;
      }

      if (blacklist !== undefined) {
        localVarQueryParameter['blacklist'] = blacklist;
      }

      if (watchlist !== undefined) {
        localVarQueryParameter['watchlist'] = watchlist;
      }

      if (age !== undefined) {
        localVarQueryParameter['age'] = age;
      }

      if (handicapIndex !== undefined) {
        localVarQueryParameter['handicapIndex'] = handicapIndex;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns list of applications
     * @summary Get filtered list of applications for final confirmation
     * @param {number} champId Filter by championship id
     * @param {ApplicationFinalConfirmationBulkFilterEnum} playerFilter Plyer filter
     * @param {number} [limit]
     * @param {number} [start]
     * @param {string} [query] Optional First/Last Name search query
     * @param {string} [startDate] Optional start qualifying date range
     * @param {string} [endDate] Optional end qualifying date range
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminApplicationsFinalConfirmationGet: async (
      champId: number,
      playerFilter: ApplicationFinalConfirmationBulkFilterEnum,
      limit?: number,
      start?: number,
      query?: string,
      startDate?: string,
      endDate?: string,
      sortingFilter?: SortingFilterEnum,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'champId' is not null or undefined
      if (champId === null || champId === undefined) {
        throw new RequiredError(
          'champId',
          'Required parameter champId was null or undefined when calling binChampadminAdminApplicationsFinalConfirmationGet.'
        );
      }
      // verify required parameter 'playerFilter' is not null or undefined
      if (playerFilter === null || playerFilter === undefined) {
        throw new RequiredError(
          'playerFilter',
          'Required parameter playerFilter was null or undefined when calling binChampadminAdminApplicationsFinalConfirmationGet.'
        );
      }
      const localVarPath = `/bin/champadmin/admin/applications/final/confirmation`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start;
      }

      if (champId !== undefined) {
        localVarQueryParameter['champId'] = champId;
      }

      if (playerFilter !== undefined) {
        localVarQueryParameter['playerFilter'] = playerFilter;
      }

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (startDate !== undefined) {
        localVarQueryParameter['startDate'] =
          (startDate as any) instanceof Date
            ? (startDate as any).toISOString().substr(0, 10)
            : startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['endDate'] =
          (endDate as any) instanceof Date ? (endDate as any).toISOString().substr(0, 10) : endDate;
      }

      if (sortingFilter !== undefined) {
        localVarQueryParameter['sortingFilter'] = sortingFilter;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns list of applications
     * @summary Get filtered list of applications
     * @param {number} year Championship year
     * @param {ApplicationFilters} filter
     * @param {number} [champId] Optional filter by championship id
     * @param {number} [limit]
     * @param {number} [start]
     * @param {string} [query] Optional full text search query
     * @param {string} [filterValue]
     * @param {string} [disabilityTypeFilter] Disability Type
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminApplicationsGet: async (
      year: number,
      filter: ApplicationFilters,
      champId?: number,
      limit?: number,
      start?: number,
      query?: string,
      filterValue?: string,
      disabilityTypeFilter?: string,
      sortingFilter?: SortingFilterEnum,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'year' is not null or undefined
      if (year === null || year === undefined) {
        throw new RequiredError(
          'year',
          'Required parameter year was null or undefined when calling binChampadminAdminApplicationsGet.'
        );
      }
      // verify required parameter 'filter' is not null or undefined
      if (filter === null || filter === undefined) {
        throw new RequiredError(
          'filter',
          'Required parameter filter was null or undefined when calling binChampadminAdminApplicationsGet.'
        );
      }
      const localVarPath = `/bin/champadmin/admin/applications`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (champId !== undefined) {
        localVarQueryParameter['champId'] = champId;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start;
      }

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (year !== undefined) {
        localVarQueryParameter['year'] = year;
      }

      if (filter !== undefined) {
        localVarQueryParameter['filter'] = filter;
      }

      if (filterValue !== undefined) {
        localVarQueryParameter['filterValue'] = filterValue;
      }

      if (disabilityTypeFilter !== undefined) {
        localVarQueryParameter['disabilityTypeFilter'] = disabilityTypeFilter;
      }

      if (sortingFilter !== undefined) {
        localVarQueryParameter['sortingFilter'] = sortingFilter;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns list of applications for player
     * @summary Get filtered list of applications for player history
     * @param {number} playerId filter by player id
     * @param {number} [champId] Optional filter by championship id
     * @param {string} [query] Optional full text search query
     * @param {number} [year] Championship year
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminApplicationsHistoryGet: async (
      playerId: number,
      champId?: number,
      query?: string,
      year?: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'playerId' is not null or undefined
      if (playerId === null || playerId === undefined) {
        throw new RequiredError(
          'playerId',
          'Required parameter playerId was null or undefined when calling binChampadminAdminApplicationsHistoryGet.'
        );
      }
      const localVarPath = `/bin/champadmin/admin/applications/history`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (champId !== undefined) {
        localVarQueryParameter['champId'] = champId;
      }

      if (playerId !== undefined) {
        localVarQueryParameter['playerId'] = playerId;
      }

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (year !== undefined) {
        localVarQueryParameter['year'] = year;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns list of applications
     * @summary Get filtered list of applications for new email
     * @param {number} champId Filter by championship id
     * @param {number} [limit]
     * @param {number} [start]
     * @param {string} [query] Optional First/Last Name search query
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {PlayerStatusEnum} [playerStatus] Optional Player Status Filter
     * @param {ApplicationProgressionEnum} [applicationStatus] Optional Application Status Filter
     * @param {QualifyingStatusEnum} [qualifyingStatus] Optional Application Status Filter
     * @param {string} [wagrRank] Optional WAGR Rank Filter
     * @param {boolean} [notablePlayer] Optional Notable Player Filter
     * @param {boolean} [adaRequest] Optional ADA Request Filter
     * @param {BlacklistStatusEnum} [blacklist] Optional Blacklist Filter
     * @param {boolean} [watchlist] Optional Watchlist Filter
     * @param {number} [age] Optional Age Filter
     * @param {string} [handicapIndex] Optional Handicap Index Filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminApplicationsNewEmailGet: async (
      champId: number,
      limit?: number,
      start?: number,
      query?: string,
      sortingFilter?: SortingFilterEnum,
      playerStatus?: PlayerStatusEnum,
      applicationStatus?: ApplicationProgressionEnum,
      qualifyingStatus?: QualifyingStatusEnum,
      wagrRank?: string,
      notablePlayer?: boolean,
      adaRequest?: boolean,
      blacklist?: BlacklistStatusEnum,
      watchlist?: boolean,
      age?: number,
      handicapIndex?: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'champId' is not null or undefined
      if (champId === null || champId === undefined) {
        throw new RequiredError(
          'champId',
          'Required parameter champId was null or undefined when calling binChampadminAdminApplicationsNewEmailGet.'
        );
      }
      const localVarPath = `/bin/champadmin/admin/applications/new/email`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start;
      }

      if (champId !== undefined) {
        localVarQueryParameter['champId'] = champId;
      }

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (sortingFilter !== undefined) {
        localVarQueryParameter['sortingFilter'] = sortingFilter;
      }

      if (playerStatus !== undefined) {
        localVarQueryParameter['playerStatus'] = playerStatus;
      }

      if (applicationStatus !== undefined) {
        localVarQueryParameter['applicationStatus'] = applicationStatus;
      }

      if (qualifyingStatus !== undefined) {
        localVarQueryParameter['qualifyingStatus'] = qualifyingStatus;
      }

      if (wagrRank !== undefined) {
        localVarQueryParameter['wagrRank'] = wagrRank;
      }

      if (notablePlayer !== undefined) {
        localVarQueryParameter['notablePlayer'] = notablePlayer;
      }

      if (adaRequest !== undefined) {
        localVarQueryParameter['adaRequest'] = adaRequest;
      }

      if (blacklist !== undefined) {
        localVarQueryParameter['blacklist'] = blacklist;
      }

      if (watchlist !== undefined) {
        localVarQueryParameter['watchlist'] = watchlist;
      }

      if (age !== undefined) {
        localVarQueryParameter['age'] = age;
      }

      if (handicapIndex !== undefined) {
        localVarQueryParameter['handicapIndex'] = handicapIndex;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns application
     * @summary Get next filtered application
     * @param {number} year Championship year
     * @param {number} fromId id of current entry to get next from it
     * @param {ApplicationFilters} filter
     * @param {number} [champId] Optional filter by championship id
     * @param {SortingFilterEnum} [sortingFilter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminApplicationsNextGet: async (
      year: number,
      fromId: number,
      filter: ApplicationFilters,
      champId?: number,
      sortingFilter?: SortingFilterEnum,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'year' is not null or undefined
      if (year === null || year === undefined) {
        throw new RequiredError(
          'year',
          'Required parameter year was null or undefined when calling binChampadminAdminApplicationsNextGet.'
        );
      }
      // verify required parameter 'fromId' is not null or undefined
      if (fromId === null || fromId === undefined) {
        throw new RequiredError(
          'fromId',
          'Required parameter fromId was null or undefined when calling binChampadminAdminApplicationsNextGet.'
        );
      }
      // verify required parameter 'filter' is not null or undefined
      if (filter === null || filter === undefined) {
        throw new RequiredError(
          'filter',
          'Required parameter filter was null or undefined when calling binChampadminAdminApplicationsNextGet.'
        );
      }
      const localVarPath = `/bin/champadmin/admin/applications/next`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (champId !== undefined) {
        localVarQueryParameter['champId'] = champId;
      }

      if (year !== undefined) {
        localVarQueryParameter['year'] = year;
      }

      if (fromId !== undefined) {
        localVarQueryParameter['fromId'] = fromId;
      }

      if (filter !== undefined) {
        localVarQueryParameter['filter'] = filter;
      }

      if (sortingFilter !== undefined) {
        localVarQueryParameter['sortingFilter'] = sortingFilter;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns list of applications
     * @summary Get filtered list of applications for qualified and alternate bulk
     * @param {number} champId Filter by championship id
     * @param {number} [limit]
     * @param {number} [start]
     * @param {string} [query] Optional First/Last Name search query
     * @param {string} [startDate] Optional start qualifying date range
     * @param {string} [endDate] Optional end qualifying date range
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {ApplicationFilters} [filter]
     * @param {string} [filterValue]
     * @param {BulkQualifyingStatusEnum} [bulkQualifyingStatus] Optional complex filtering
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminApplicationsQualifiedAlternateBulkGet: async (
      champId: number,
      limit?: number,
      start?: number,
      query?: string,
      startDate?: string,
      endDate?: string,
      sortingFilter?: SortingFilterEnum,
      filter?: ApplicationFilters,
      filterValue?: string,
      bulkQualifyingStatus?: BulkQualifyingStatusEnum,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'champId' is not null or undefined
      if (champId === null || champId === undefined) {
        throw new RequiredError(
          'champId',
          'Required parameter champId was null or undefined when calling binChampadminAdminApplicationsQualifiedAlternateBulkGet.'
        );
      }
      const localVarPath = `/bin/champadmin/admin/applications/qualified/alternate/bulk`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start;
      }

      if (champId !== undefined) {
        localVarQueryParameter['champId'] = champId;
      }

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (startDate !== undefined) {
        localVarQueryParameter['startDate'] =
          (startDate as any) instanceof Date
            ? (startDate as any).toISOString().substr(0, 10)
            : startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['endDate'] =
          (endDate as any) instanceof Date ? (endDate as any).toISOString().substr(0, 10) : endDate;
      }

      if (sortingFilter !== undefined) {
        localVarQueryParameter['sortingFilter'] = sortingFilter;
      }

      if (filter !== undefined) {
        localVarQueryParameter['filter'] = filter;
      }

      if (filterValue !== undefined) {
        localVarQueryParameter['filterValue'] = filterValue;
      }

      if (bulkQualifyingStatus !== undefined) {
        localVarQueryParameter['bulkQualifyingStatus'] = bulkQualifyingStatus;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * List of applications with their respective waitlist positions.
     * @summary Get a list of applications ids with their respective waitlist positions.
     * @param {Array<number>} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminApplicationsWaitlistPositionGet: async (
      id: Array<number>,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling binChampadminAdminApplicationsWaitlistPositionGet.'
        );
      }
      const localVarPath = `/bin/champadmin/admin/applications/waitlist/position`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (id) {
        localVarQueryParameter['id'] = id;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns list of applications
     * @summary Get filtered list of applications to withdraw incomplete sides
     * @param {number} champId Filter by championship id
     * @param {number} [limit]
     * @param {number} [start]
     * @param {string} [query] Optional First/Last Name search query
     * @param {string} [startDate] Optional start qualifying date range
     * @param {string} [endDate] Optional end qualifying date range
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminApplicationsWithdrawIncompleteBulkGet: async (
      champId: number,
      limit?: number,
      start?: number,
      query?: string,
      startDate?: string,
      endDate?: string,
      sortingFilter?: SortingFilterEnum,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'champId' is not null or undefined
      if (champId === null || champId === undefined) {
        throw new RequiredError(
          'champId',
          'Required parameter champId was null or undefined when calling binChampadminAdminApplicationsWithdrawIncompleteBulkGet.'
        );
      }
      const localVarPath = `/bin/champadmin/admin/applications/withdraw/incomplete/bulk`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start;
      }

      if (champId !== undefined) {
        localVarQueryParameter['champId'] = champId;
      }

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (startDate !== undefined) {
        localVarQueryParameter['startDate'] =
          (startDate as any) instanceof Date
            ? (startDate as any).toISOString().substr(0, 10)
            : startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['endDate'] =
          (endDate as any) instanceof Date ? (endDate as any).toISOString().substr(0, 10) : endDate;
      }

      if (sortingFilter !== undefined) {
        localVarQueryParameter['sortingFilter'] = sortingFilter;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Download info file by champ id
     * @summary Get activity log
     * @param {string} id
     * @param {AuditEntityType} entityType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminAuditGet: async (
      id: string,
      entityType: AuditEntityType,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling binChampadminAdminAuditGet.'
        );
      }
      // verify required parameter 'entityType' is not null or undefined
      if (entityType === null || entityType === undefined) {
        throw new RequiredError(
          'entityType',
          'Required parameter entityType was null or undefined when calling binChampadminAdminAuditGet.'
        );
      }
      const localVarPath = `/bin/champadmin/admin/audit`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (id !== undefined) {
        localVarQueryParameter['id'] = id;
      }

      if (entityType !== undefined) {
        localVarQueryParameter['entityType'] = entityType;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns multi filtered list of applications for bulk entry fee balance
     * @summary Get filtered list of applications for bulk entry fee balance
     * @param {number} [champId] Required championship id
     * @param {number} [limit]
     * @param {number} [start]
     * @param {string} [query] Optional full text search query
     * @param {Array<ApplicationFilters>} [filters]
     * @param {Array<number>} [qsIds]
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminBulkEntryFeeBalanceGet: async (
      champId?: number,
      limit?: number,
      start?: number,
      query?: string,
      filters?: Array<ApplicationFilters>,
      qsIds?: Array<number>,
      sortingFilter?: SortingFilterEnum,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/admin/bulk/entryFeeBalance`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (champId !== undefined) {
        localVarQueryParameter['champId'] = champId;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start;
      }

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (filters) {
        localVarQueryParameter['filters'] = filters;
      }

      if (qsIds) {
        localVarQueryParameter['qsIds'] = qsIds;
      }

      if (sortingFilter !== undefined) {
        localVarQueryParameter['sortingFilter'] = sortingFilter;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * List applications to pay EntryFeeBalance
     * @summary ids
     * @param {BulkEntryFeeRequestAdminModel} [bulkEntryFeeRequestAdminModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminBulkEntryFeeBalancePaymentPost: async (
      bulkEntryFeeRequestAdminModel?: BulkEntryFeeRequestAdminModel,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/admin/bulk/entryFeeBalance/payment`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof bulkEntryFeeRequestAdminModel !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(
            bulkEntryFeeRequestAdminModel !== undefined ? bulkEntryFeeRequestAdminModel : {}
          )
        : bulkEntryFeeRequestAdminModel || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns Champiobship Type by id
     * @summary Get Champ-type by id
     * @param {number} id id of championship-type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminChampTypeGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling binChampadminAdminChampTypeGet.'
        );
      }
      const localVarPath = `/bin/champadmin/admin/champ-type`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (id !== undefined) {
        localVarQueryParameter['id'] = id;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns list of Champiobship Types
     * @summary Get list of Champ-types
     * @param {number} [limit]
     * @param {number} [start]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminChampTypeListGet: async (
      limit?: number,
      start?: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/admin/champ-type-list`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Add championship
     * @summary Add championship
     * @param {ChampionshipAddModel} [championshipAddModel] Add championship
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminChampionshipAddPost: async (
      championshipAddModel?: ChampionshipAddModel,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/admin/championship/add`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof championshipAddModel !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(championshipAddModel !== undefined ? championshipAddModel : {})
        : championshipAddModel || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Editing championship
     * @summary Editing championship
     * @param {ChampionshipEditModel} [championshipEditModel] Edit championship
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminChampionshipEditPost: async (
      championshipEditModel?: ChampionshipEditModel,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/admin/championship/edit`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof championshipEditModel !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(championshipEditModel !== undefined ? championshipEditModel : {})
        : championshipEditModel || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Red Book entries
     * @param {number} champId
     * @param {number} limit
     * @param {number} start
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminChampionshipRedbookGet: async (
      champId: number,
      limit: number,
      start: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'champId' is not null or undefined
      if (champId === null || champId === undefined) {
        throw new RequiredError(
          'champId',
          'Required parameter champId was null or undefined when calling binChampadminAdminChampionshipRedbookGet.'
        );
      }
      // verify required parameter 'limit' is not null or undefined
      if (limit === null || limit === undefined) {
        throw new RequiredError(
          'limit',
          'Required parameter limit was null or undefined when calling binChampadminAdminChampionshipRedbookGet.'
        );
      }
      // verify required parameter 'start' is not null or undefined
      if (start === null || start === undefined) {
        throw new RequiredError(
          'start',
          'Required parameter start was null or undefined when calling binChampadminAdminChampionshipRedbookGet.'
        );
      }
      const localVarPath = `/bin/champadmin/admin/championship/redbook`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (champId !== undefined) {
        localVarQueryParameter['champId'] = champId;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Reorder Red Book entries
     * @param {Array<RankedQualifyingSiteModel>} [rankedQualifyingSiteModel] A list of assigned ranks for qualifying sites
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminChampionshipRedbookPost: async (
      rankedQualifyingSiteModel?: Array<RankedQualifyingSiteModel>,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/admin/championship/redbook`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof rankedQualifyingSiteModel !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(rankedQualifyingSiteModel !== undefined ? rankedQualifyingSiteModel : {})
        : rankedQualifyingSiteModel || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns list of uploaded files
     * @summary Get list of uploaded files
     * @param {number} [limit] Per page
     * @param {number} [start] Starting position
     * @param {string} [champId]
     * @param {string} [applicationId]
     * @param {string} [query]
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminChampionshipsFilesGet: async (
      limit?: number,
      start?: number,
      champId?: string,
      applicationId?: string,
      query?: string,
      sortingFilter?: SortingFilterEnum,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/admin/championships/files`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start;
      }

      if (champId !== undefined) {
        localVarQueryParameter['champId'] = champId;
      }

      if (applicationId !== undefined) {
        localVarQueryParameter['applicationId'] = applicationId;
      }

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (sortingFilter !== undefined) {
        localVarQueryParameter['sortingFilter'] = sortingFilter;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Repositions championships in order their ids were submitted
     * @summary Reposition championships
     * @param {Array<number>} [requestBody] List of championship ids in new order for some specific year
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminChampionshipsPositionPost: async (
      requestBody?: Array<number>,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/admin/championships/position`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof requestBody !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(requestBody !== undefined ? requestBody : {})
        : requestBody || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Is used to get championship data by its id
     * @summary Get championship info by id
     * @param {number} id id of championship
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminChampionshipsQueryGet: async (
      id: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling binChampadminAdminChampionshipsQueryGet.'
        );
      }
      const localVarPath = `/bin/champadmin/admin/championships/query`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (id !== undefined) {
        localVarQueryParameter['id'] = id;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get the list of images in cloudinary using its Search API. Has a rate limit of 5k requests per cloudinary account.
     * @param {number} limit Number of images to  return
     * @param {string} [query] Query passed to cloudinary API, see https://cloudinary.com/documentation/search_api for reference
     * @param {string} [start] Next cursor value to search from
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminCloudinaryListGet: async (
      limit: number,
      query?: string,
      start?: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'limit' is not null or undefined
      if (limit === null || limit === undefined) {
        throw new RequiredError(
          'limit',
          'Required parameter limit was null or undefined when calling binChampadminAdminCloudinaryListGet.'
        );
      }
      const localVarPath = `/bin/champadmin/admin/cloudinary/list`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Delete club by id
     * @summary Remove club by its id
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminClubDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling binChampadminAdminClubDelete.'
        );
      }
      const localVarPath = `/bin/champadmin/admin/club`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (id !== undefined) {
        localVarQueryParameter['id'] = id;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns list of clubs
     * @summary Get list of clubs
     * @param {number} [limit]
     * @param {number} [start]
     * @param {string} [query] Expression to filter entries (e.g. %word%)
     * @param {number} [champId]
     * @param {number} [year]
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {string} [countryFilter]
     * @param {string} [stateFilter]
     * @param {string} [cityFilter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminClubGet: async (
      limit?: number,
      start?: number,
      query?: string,
      champId?: number,
      year?: number,
      sortingFilter?: SortingFilterEnum,
      countryFilter?: string,
      stateFilter?: string,
      cityFilter?: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/admin/club`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start;
      }

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (champId !== undefined) {
        localVarQueryParameter['champId'] = champId;
      }

      if (year !== undefined) {
        localVarQueryParameter['year'] = year;
      }

      if (sortingFilter !== undefined) {
        localVarQueryParameter['sortingFilter'] = sortingFilter;
      }

      if (countryFilter !== undefined) {
        localVarQueryParameter['countryFilter'] = countryFilter;
      }

      if (stateFilter !== undefined) {
        localVarQueryParameter['stateFilter'] = stateFilter;
      }

      if (cityFilter !== undefined) {
        localVarQueryParameter['cityFilter'] = cityFilter;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Add new club
     * @summary Add new club
     * @param {ClubAdminAddModel} [clubAdminAddModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminClubPost: async (
      clubAdminAddModel?: ClubAdminAddModel,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/admin/club`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof clubAdminAddModel !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(clubAdminAddModel !== undefined ? clubAdminAddModel : {})
        : clubAdminAddModel || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Is used to get club data by its id
     * @summary Get club info by id
     * @param {number} id id of club
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminClubQueryGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling binChampadminAdminClubQueryGet.'
        );
      }
      const localVarPath = `/bin/champadmin/admin/club/query`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (id !== undefined) {
        localVarQueryParameter['id'] = id;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Updates club
     * @summary Update club
     * @param {ClubAdminModel} [clubAdminModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminClubUpdatePost: async (
      clubAdminModel?: ClubAdminModel,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/admin/club/update`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof clubAdminModel !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(clubAdminModel !== undefined ? clubAdminModel : {})
        : clubAdminModel || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * parameters are used only for \'select all\' functionality
     * @summary Bulk email to clubs
     * @param {string} [query] Expression to filter entries (e.g. %word%)
     * @param {number} [champId]
     * @param {number} [year]
     * @param {ClubFilters} [filter]
     * @param {string} [filterValue]
     * @param {CustomBulkEmailRequestModel} [customBulkEmailRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminEmailBulkClubPost: async (
      query?: string,
      champId?: number,
      year?: number,
      filter?: ClubFilters,
      filterValue?: string,
      customBulkEmailRequestModel?: CustomBulkEmailRequestModel,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/admin/email/bulk/club`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (champId !== undefined) {
        localVarQueryParameter['champId'] = champId;
      }

      if (year !== undefined) {
        localVarQueryParameter['year'] = year;
      }

      if (filter !== undefined) {
        localVarQueryParameter['filter'] = filter;
      }

      if (filterValue !== undefined) {
        localVarQueryParameter['filterValue'] = filterValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof customBulkEmailRequestModel !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(
            customBulkEmailRequestModel !== undefined ? customBulkEmailRequestModel : {}
          )
        : customBulkEmailRequestModel || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Custom Bulk Email to Applicants
     * @param {CustomBulkEmailRequestModel} [customBulkEmailRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminEmailBulkCustomPost: async (
      customBulkEmailRequestModel?: CustomBulkEmailRequestModel,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/admin/email/bulk/custom`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof customBulkEmailRequestModel !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(
            customBulkEmailRequestModel !== undefined ? customBulkEmailRequestModel : {}
          )
        : customBulkEmailRequestModel || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * parameters are used only for \'select all\' functionality
     * @summary Custom Bulk Email to Applicants
     * @param {Array<number>} [year] Optional Filter by year id
     * @param {Array<number>} [champTypeId] Optional Filter by ChampionshipType Model id
     * @param {string} [query] Optional search query parameter
     * @param {boolean} [twoYearParticipation] Optional Filter to find Two Year Participation
     * @param {boolean} [excludeApplied] Optional Exclude Applied Players Filter
     * @param {boolean} [exempt] Optional Filter to exclude exempt players
     * @param {PlayerStatusEnum} [playerStatus] Optional Player Status Filter
     * @param {string} [wagrRank] Optional WAGR Rank Filter
     * @param {boolean} [adaRequest] Optional ADA Request Filter, true - include, false - exclude
     * @param {boolean} [blacklist] Optional Blacklist Filter, true - include, false - exclude
     * @param {boolean} [watchlist] Optional Watchlist Filter, true - include, false - exclude
     * @param {number} [age] Optional Filter by Player\&#39;s age
     * @param {string} [handicapIndex] Optional Handicap Index Filter
     * @param {Array<number>} [qualifyingSiteId] Optional qualifying site Ids
     * @param {boolean} [applicationAccepted] Optional filter for accepted or q applications
     * @param {Array<number>} [waitlistId] Optional Filter by Waitlist QS id
     * @param {Array<number>} [excludeWaitlistId] Optional Filter exclude by Waitlist QS id
     * @param {boolean} [waitlistAll] Optional Filter to find All waitlisted player by champType &amp; year
     * @param {boolean} [excludeWaitlistAll] Optional Filter to exclude All waitlisted player by champType &amp; year
     * @param {boolean} [excludeBlacklistAll] Optional Filter to exclude All blacklisted player by champType &amp; year
     * @param {Array<BlacklistStatusEnum>} [excludeBlacklistStatus]
     * @param {QualifyingStatusEnum} [qualifyingStatus] Optional Application Qualifying Status Filter
     * @param {CustomBulkEmailProfileRequestModel} [customBulkEmailProfileRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminEmailBulkCustomProfilePost: async (
      year?: Array<number>,
      champTypeId?: Array<number>,
      query?: string,
      twoYearParticipation?: boolean,
      excludeApplied?: boolean,
      exempt?: boolean,
      playerStatus?: PlayerStatusEnum,
      wagrRank?: string,
      adaRequest?: boolean,
      blacklist?: boolean,
      watchlist?: boolean,
      age?: number,
      handicapIndex?: string,
      qualifyingSiteId?: Array<number>,
      applicationAccepted?: boolean,
      waitlistId?: Array<number>,
      excludeWaitlistId?: Array<number>,
      waitlistAll?: boolean,
      excludeWaitlistAll?: boolean,
      excludeBlacklistAll?: boolean,
      excludeBlacklistStatus?: Array<BlacklistStatusEnum>,
      qualifyingStatus?: QualifyingStatusEnum,
      customBulkEmailProfileRequestModel?: CustomBulkEmailProfileRequestModel,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/admin/email/bulk/custom/profile`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (year) {
        localVarQueryParameter['year'] = year;
      }

      if (champTypeId) {
        localVarQueryParameter['champTypeId'] = champTypeId;
      }

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (twoYearParticipation !== undefined) {
        localVarQueryParameter['twoYearParticipation'] = twoYearParticipation;
      }

      if (excludeApplied !== undefined) {
        localVarQueryParameter['excludeApplied'] = excludeApplied;
      }

      if (exempt !== undefined) {
        localVarQueryParameter['exempt'] = exempt;
      }

      if (playerStatus !== undefined) {
        localVarQueryParameter['playerStatus'] = playerStatus;
      }

      if (wagrRank !== undefined) {
        localVarQueryParameter['wagrRank'] = wagrRank;
      }

      if (adaRequest !== undefined) {
        localVarQueryParameter['adaRequest'] = adaRequest;
      }

      if (blacklist !== undefined) {
        localVarQueryParameter['blacklist'] = blacklist;
      }

      if (watchlist !== undefined) {
        localVarQueryParameter['watchlist'] = watchlist;
      }

      if (age !== undefined) {
        localVarQueryParameter['age'] = age;
      }

      if (handicapIndex !== undefined) {
        localVarQueryParameter['handicapIndex'] = handicapIndex;
      }

      if (qualifyingSiteId) {
        localVarQueryParameter['qualifyingSiteId'] = qualifyingSiteId;
      }

      if (applicationAccepted !== undefined) {
        localVarQueryParameter['applicationAccepted'] = applicationAccepted;
      }

      if (waitlistId) {
        localVarQueryParameter['waitlistId'] = waitlistId;
      }

      if (excludeWaitlistId) {
        localVarQueryParameter['excludeWaitlistId'] = excludeWaitlistId;
      }

      if (waitlistAll !== undefined) {
        localVarQueryParameter['waitlistAll'] = waitlistAll;
      }

      if (excludeWaitlistAll !== undefined) {
        localVarQueryParameter['excludeWaitlistAll'] = excludeWaitlistAll;
      }

      if (excludeBlacklistAll !== undefined) {
        localVarQueryParameter['excludeBlacklistAll'] = excludeBlacklistAll;
      }

      if (excludeBlacklistStatus) {
        localVarQueryParameter['excludeBlacklistStatus'] = excludeBlacklistStatus;
      }

      if (qualifyingStatus !== undefined) {
        localVarQueryParameter['qualifyingStatus'] = qualifyingStatus;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof customBulkEmailProfileRequestModel !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(
            customBulkEmailProfileRequestModel !== undefined
              ? customBulkEmailProfileRequestModel
              : {}
          )
        : customBulkEmailProfileRequestModel || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * parameters are used only for \'select all\' functionality
     * @summary Exemptions Applicants bulk email
     * @param {number} champId Filter by championship id
     * @param {string} [query] Optional First/Last Name search query
     * @param {IdListModel} [idListModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminEmailBulkExemptAppPost: async (
      champId: number,
      query?: string,
      idListModel?: IdListModel,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'champId' is not null or undefined
      if (champId === null || champId === undefined) {
        throw new RequiredError(
          'champId',
          'Required parameter champId was null or undefined when calling binChampadminAdminEmailBulkExemptAppPost.'
        );
      }
      const localVarPath = `/bin/champadmin/admin/email/bulk/exempt/app`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (champId !== undefined) {
        localVarQueryParameter['champId'] = champId;
      }

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof idListModel !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(idListModel !== undefined ? idListModel : {})
        : idListModel || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns list of players
     * @summary Get list of exempt players
     * @param {number} champId
     * @param {number} [limit] Per page
     * @param {number} [start] Page number
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {string} [query]
     * @param {boolean} [excludeBlacklist]
     * @param {boolean} [excludeWatchlist]
     * @param {boolean} [excludeApplied]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminEmailBulkExemptPlayersGet: async (
      champId: number,
      limit?: number,
      start?: number,
      sortingFilter?: SortingFilterEnum,
      query?: string,
      excludeBlacklist?: boolean,
      excludeWatchlist?: boolean,
      excludeApplied?: boolean,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'champId' is not null or undefined
      if (champId === null || champId === undefined) {
        throw new RequiredError(
          'champId',
          'Required parameter champId was null or undefined when calling binChampadminAdminEmailBulkExemptPlayersGet.'
        );
      }
      const localVarPath = `/bin/champadmin/admin/email/bulk/exempt/players`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start;
      }

      if (sortingFilter !== undefined) {
        localVarQueryParameter['sortingFilter'] = sortingFilter;
      }

      if (champId !== undefined) {
        localVarQueryParameter['champId'] = champId;
      }

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (excludeBlacklist !== undefined) {
        localVarQueryParameter['excludeBlacklist'] = excludeBlacklist;
      }

      if (excludeWatchlist !== undefined) {
        localVarQueryParameter['excludeWatchlist'] = excludeWatchlist;
      }

      if (excludeApplied !== undefined) {
        localVarQueryParameter['excludeApplied'] = excludeApplied;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Send buld email to exempt players
     * @param {number} champId
     * @param {number} [limit] Per page
     * @param {number} [start] Page number
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {string} [query]
     * @param {boolean} [excludeBlacklist]
     * @param {boolean} [excludeWatchlist]
     * @param {boolean} [excludeApplied]
     * @param {IdListModel} [idListModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminEmailBulkExemptPlayersPost: async (
      champId: number,
      limit?: number,
      start?: number,
      sortingFilter?: SortingFilterEnum,
      query?: string,
      excludeBlacklist?: boolean,
      excludeWatchlist?: boolean,
      excludeApplied?: boolean,
      idListModel?: IdListModel,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'champId' is not null or undefined
      if (champId === null || champId === undefined) {
        throw new RequiredError(
          'champId',
          'Required parameter champId was null or undefined when calling binChampadminAdminEmailBulkExemptPlayersPost.'
        );
      }
      const localVarPath = `/bin/champadmin/admin/email/bulk/exempt/players`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start;
      }

      if (sortingFilter !== undefined) {
        localVarQueryParameter['sortingFilter'] = sortingFilter;
      }

      if (champId !== undefined) {
        localVarQueryParameter['champId'] = champId;
      }

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (excludeBlacklist !== undefined) {
        localVarQueryParameter['excludeBlacklist'] = excludeBlacklist;
      }

      if (excludeWatchlist !== undefined) {
        localVarQueryParameter['excludeWatchlist'] = excludeWatchlist;
      }

      if (excludeApplied !== undefined) {
        localVarQueryParameter['excludeApplied'] = excludeApplied;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof idListModel !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(idListModel !== undefined ? idListModel : {})
        : idListModel || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * parameters are used only for \'select all\' functionality
     * @summary Final confirmation bulk email to Applicants
     * @param {number} champId Filter by championship id
     * @param {ApplicationFinalConfirmationBulkFilterEnum} playerFilter Plyer filter
     * @param {string} [query] Optional First/Last Name search query
     * @param {string} [startDate] Optional start qualifying date range
     * @param {string} [endDate] Optional end qualifying date range
     * @param {FinalConfirmationEmailRequestModel} [finalConfirmationEmailRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminEmailBulkFinalConfirmationPost: async (
      champId: number,
      playerFilter: ApplicationFinalConfirmationBulkFilterEnum,
      query?: string,
      startDate?: string,
      endDate?: string,
      finalConfirmationEmailRequestModel?: FinalConfirmationEmailRequestModel,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'champId' is not null or undefined
      if (champId === null || champId === undefined) {
        throw new RequiredError(
          'champId',
          'Required parameter champId was null or undefined when calling binChampadminAdminEmailBulkFinalConfirmationPost.'
        );
      }
      // verify required parameter 'playerFilter' is not null or undefined
      if (playerFilter === null || playerFilter === undefined) {
        throw new RequiredError(
          'playerFilter',
          'Required parameter playerFilter was null or undefined when calling binChampadminAdminEmailBulkFinalConfirmationPost.'
        );
      }
      const localVarPath = `/bin/champadmin/admin/email/bulk/final/confirmation`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (champId !== undefined) {
        localVarQueryParameter['champId'] = champId;
      }

      if (playerFilter !== undefined) {
        localVarQueryParameter['playerFilter'] = playerFilter;
      }

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (startDate !== undefined) {
        localVarQueryParameter['startDate'] =
          (startDate as any) instanceof Date
            ? (startDate as any).toISOString().substr(0, 10)
            : startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['endDate'] =
          (endDate as any) instanceof Date ? (endDate as any).toISOString().substr(0, 10) : endDate;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof finalConfirmationEmailRequestModel !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(
            finalConfirmationEmailRequestModel !== undefined
              ? finalConfirmationEmailRequestModel
              : {}
          )
        : finalConfirmationEmailRequestModel || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * parameters are used only for \'select all\' functionality
     * @summary Bulk email to incomplete sides
     * @param {number} champId Filter by championship id
     * @param {string} [query] Optional First/Last Name search query
     * @param {string} [startDate] Optional start qualifying date range
     * @param {string} [endDate] Optional end qualifying date range
     * @param {IncompleteSidesEmailRequestModel} [incompleteSidesEmailRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminEmailBulkIncompleteSidesPost: async (
      champId: number,
      query?: string,
      startDate?: string,
      endDate?: string,
      incompleteSidesEmailRequestModel?: IncompleteSidesEmailRequestModel,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'champId' is not null or undefined
      if (champId === null || champId === undefined) {
        throw new RequiredError(
          'champId',
          'Required parameter champId was null or undefined when calling binChampadminAdminEmailBulkIncompleteSidesPost.'
        );
      }
      const localVarPath = `/bin/champadmin/admin/email/bulk/incomplete/sides`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (champId !== undefined) {
        localVarQueryParameter['champId'] = champId;
      }

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (startDate !== undefined) {
        localVarQueryParameter['startDate'] =
          (startDate as any) instanceof Date
            ? (startDate as any).toISOString().substr(0, 10)
            : startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['endDate'] =
          (endDate as any) instanceof Date ? (endDate as any).toISOString().substr(0, 10) : endDate;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof incompleteSidesEmailRequestModel !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(
            incompleteSidesEmailRequestModel !== undefined ? incompleteSidesEmailRequestModel : {}
          )
        : incompleteSidesEmailRequestModel || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary New Custom Bulk Email to Players
     * @param {NewCustomBulkEmailRequestModel} [newCustomBulkEmailRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminEmailBulkNewCustomPlayersPost: async (
      newCustomBulkEmailRequestModel?: NewCustomBulkEmailRequestModel,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/admin/email/bulk/new/custom/players`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof newCustomBulkEmailRequestModel !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(
            newCustomBulkEmailRequestModel !== undefined ? newCustomBulkEmailRequestModel : {}
          )
        : newCustomBulkEmailRequestModel || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary New Custom Bulk Email to Applicants
     * @param {NewCustomBulkEmailRequestModel} [newCustomBulkEmailRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminEmailBulkNewCustomPost: async (
      newCustomBulkEmailRequestModel?: NewCustomBulkEmailRequestModel,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/admin/email/bulk/new/custom`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof newCustomBulkEmailRequestModel !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(
            newCustomBulkEmailRequestModel !== undefined ? newCustomBulkEmailRequestModel : {}
          )
        : newCustomBulkEmailRequestModel || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * parameters are used only for \'select all\' functionality
     * @summary Bulk email to Officials
     * @param {string} [query] Expression to filter entries (e.g. %word%)
     * @param {number} [champId]
     * @param {number} [year]
     * @param {OfficialsListEmailRequestModel} [officialsListEmailRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminEmailBulkOfficialsPost: async (
      query?: string,
      champId?: number,
      year?: number,
      officialsListEmailRequestModel?: OfficialsListEmailRequestModel,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/admin/email/bulk/officials`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (champId !== undefined) {
        localVarQueryParameter['champId'] = champId;
      }

      if (year !== undefined) {
        localVarQueryParameter['year'] = year;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof officialsListEmailRequestModel !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(
            officialsListEmailRequestModel !== undefined ? officialsListEmailRequestModel : {}
          )
        : officialsListEmailRequestModel || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Bulk email with partner link
     * @param {PartnerLinkEmailRequestModel} [partnerLinkEmailRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminEmailBulkPartnerLinkPost: async (
      partnerLinkEmailRequestModel?: PartnerLinkEmailRequestModel,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/admin/email/bulk/partner-link`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof partnerLinkEmailRequestModel !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(
            partnerLinkEmailRequestModel !== undefined ? partnerLinkEmailRequestModel : {}
          )
        : partnerLinkEmailRequestModel || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * parameters are used only for \'select all\' functionality
     * @summary Qualified Alternate bulk email to Applicants
     * @param {number} champId Filter by championship id
     * @param {string} [query] Optional First/Last Name search query
     * @param {string} [startDate] Optional start qualifying date range
     * @param {string} [endDate] Optional end qualifying date range
     * @param {ApplicationFilters} [filter]
     * @param {string} [filterValue]
     * @param {BulkQualifyingStatusEnum} [bulkQualifyingStatus] Optional complex filtering
     * @param {IdListModel} [idListModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminEmailBulkQualifiedAlternatePost: async (
      champId: number,
      query?: string,
      startDate?: string,
      endDate?: string,
      filter?: ApplicationFilters,
      filterValue?: string,
      bulkQualifyingStatus?: BulkQualifyingStatusEnum,
      idListModel?: IdListModel,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'champId' is not null or undefined
      if (champId === null || champId === undefined) {
        throw new RequiredError(
          'champId',
          'Required parameter champId was null or undefined when calling binChampadminAdminEmailBulkQualifiedAlternatePost.'
        );
      }
      const localVarPath = `/bin/champadmin/admin/email/bulk/qualified/alternate`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (champId !== undefined) {
        localVarQueryParameter['champId'] = champId;
      }

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (startDate !== undefined) {
        localVarQueryParameter['startDate'] =
          (startDate as any) instanceof Date
            ? (startDate as any).toISOString().substr(0, 10)
            : startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['endDate'] =
          (endDate as any) instanceof Date ? (endDate as any).toISOString().substr(0, 10) : endDate;
      }

      if (filter !== undefined) {
        localVarQueryParameter['filter'] = filter;
      }

      if (filterValue !== undefined) {
        localVarQueryParameter['filterValue'] = filterValue;
      }

      if (bulkQualifyingStatus !== undefined) {
        localVarQueryParameter['bulkQualifyingStatus'] = bulkQualifyingStatus;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof idListModel !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(idListModel !== undefined ? idListModel : {})
        : idListModel || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * parameters are used only for \'select all\' functionality
     * @summary Withdraw and bulk email incomplete applicants
     * @param {number} champId Filter by championship id
     * @param {string} [query] Optional First/Last Name search query
     * @param {string} [startDate] Optional start qualifying date range
     * @param {string} [endDate] Optional end qualifying date range
     * @param {IdListModel} [idListModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminEmailBulkWithdrawIncompletePost: async (
      champId: number,
      query?: string,
      startDate?: string,
      endDate?: string,
      idListModel?: IdListModel,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'champId' is not null or undefined
      if (champId === null || champId === undefined) {
        throw new RequiredError(
          'champId',
          'Required parameter champId was null or undefined when calling binChampadminAdminEmailBulkWithdrawIncompletePost.'
        );
      }
      const localVarPath = `/bin/champadmin/admin/email/bulk/withdraw/incomplete`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (champId !== undefined) {
        localVarQueryParameter['champId'] = champId;
      }

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (startDate !== undefined) {
        localVarQueryParameter['startDate'] =
          (startDate as any) instanceof Date
            ? (startDate as any).toISOString().substr(0, 10)
            : startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['endDate'] =
          (endDate as any) instanceof Date ? (endDate as any).toISOString().substr(0, 10) : endDate;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof idListModel !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(idListModel !== undefined ? idListModel : {})
        : idListModel || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Resend email
     * @param {ResendEmailRequestModel} [resendEmailRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminEmailResendPost: async (
      resendEmailRequestModel?: ResendEmailRequestModel,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/admin/email/resend`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof resendEmailRequestModel !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(resendEmailRequestModel !== undefined ? resendEmailRequestModel : {})
        : resendEmailRequestModel || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete email template
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminEmailTemplateDelete: async (
      id: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling binChampadminAdminEmailTemplateDelete.'
        );
      }
      const localVarPath = `/bin/champadmin/admin/email/template`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (id !== undefined) {
        localVarQueryParameter['id'] = id;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Edit email template
     * @param {EmailTemplateEditModel} [emailTemplateEditModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminEmailTemplateEditPost: async (
      emailTemplateEditModel?: EmailTemplateEditModel,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/admin/email/template/edit`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof emailTemplateEditModel !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(emailTemplateEditModel !== undefined ? emailTemplateEditModel : {})
        : emailTemplateEditModel || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns Champiobship Type by id
     * @summary Get email template by id
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminEmailTemplateGet: async (
      id: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling binChampadminAdminEmailTemplateGet.'
        );
      }
      const localVarPath = `/bin/champadmin/admin/email/template`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (id !== undefined) {
        localVarQueryParameter['id'] = id;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Add new email template
     * @summary Create new email template
     * @param {EmailTemplateRequestModel} [emailTemplateRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminEmailTemplatePost: async (
      emailTemplateRequestModel?: EmailTemplateRequestModel,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/admin/email/template`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof emailTemplateRequestModel !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(emailTemplateRequestModel !== undefined ? emailTemplateRequestModel : {})
        : emailTemplateRequestModel || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get email template by service-type and Champ Id
     * @param {EmailTemplateServiceTypeEnum} serviceType
     * @param {number} [champId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminEmailTemplateServiceTypeGet: async (
      serviceType: EmailTemplateServiceTypeEnum,
      champId?: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'serviceType' is not null or undefined
      if (serviceType === null || serviceType === undefined) {
        throw new RequiredError(
          'serviceType',
          'Required parameter serviceType was null or undefined when calling binChampadminAdminEmailTemplateServiceTypeGet.'
        );
      }
      const localVarPath = `/bin/champadmin/admin/email/template/service-type`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (serviceType !== undefined) {
        localVarQueryParameter['serviceType'] = serviceType;
      }

      if (champId !== undefined) {
        localVarQueryParameter['champId'] = champId;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns list of applications
     * @summary Get filtered list of applications for exemptions bulk
     * @param {number} champId Filter by championship id
     * @param {number} [limit]
     * @param {number} [start]
     * @param {string} [query] Optional First/Last Name search query
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminExemptApplicationsGet: async (
      champId: number,
      limit?: number,
      start?: number,
      query?: string,
      sortingFilter?: SortingFilterEnum,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'champId' is not null or undefined
      if (champId === null || champId === undefined) {
        throw new RequiredError(
          'champId',
          'Required parameter champId was null or undefined when calling binChampadminAdminExemptApplicationsGet.'
        );
      }
      const localVarPath = `/bin/champadmin/admin/exempt/applications`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start;
      }

      if (champId !== undefined) {
        localVarQueryParameter['champId'] = champId;
      }

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (sortingFilter !== undefined) {
        localVarQueryParameter['sortingFilter'] = sortingFilter;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Delete exemption by id
     * @summary Remove exemption by its id
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminExemptionDelete: async (
      id: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling binChampadminAdminExemptionDelete.'
        );
      }
      const localVarPath = `/bin/champadmin/admin/exemption`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (id !== undefined) {
        localVarQueryParameter['id'] = id;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Edit already existing exemption
     * @summary Edit exemption
     * @param {ExemptionAdminEditModel} [exemptionAdminEditModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminExemptionEditPost: async (
      exemptionAdminEditModel?: ExemptionAdminEditModel,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/admin/exemption/edit`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof exemptionAdminEditModel !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(exemptionAdminEditModel !== undefined ? exemptionAdminEditModel : {})
        : exemptionAdminEditModel || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns list of exemptions
     * @summary Get list of exemptions by championship Id
     * @param {number} champId
     * @param {number} [limit]
     * @param {number} [start]
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {QualifyingLevelEnum} [filter] Optional Filter
     * @param {string} [query] Optional search query parameter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminExemptionGet: async (
      champId: number,
      limit?: number,
      start?: number,
      sortingFilter?: SortingFilterEnum,
      filter?: QualifyingLevelEnum,
      query?: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'champId' is not null or undefined
      if (champId === null || champId === undefined) {
        throw new RequiredError(
          'champId',
          'Required parameter champId was null or undefined when calling binChampadminAdminExemptionGet.'
        );
      }
      const localVarPath = `/bin/champadmin/admin/exemption`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (champId !== undefined) {
        localVarQueryParameter['champId'] = champId;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start;
      }

      if (sortingFilter !== undefined) {
        localVarQueryParameter['sortingFilter'] = sortingFilter;
      }

      if (filter !== undefined) {
        localVarQueryParameter['filter'] = filter;
      }

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns list of exemptions
     * @summary Get list of exemptions by championship Id
     * @param {number} champId
     * @param {number} [limit]
     * @param {number} [start]
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {QualifyingLevelEnum} [filter] Optional Filter
     * @param {string} [query] Optional search query parameter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminExemptionLightWeightGet: async (
      champId: number,
      limit?: number,
      start?: number,
      sortingFilter?: SortingFilterEnum,
      filter?: QualifyingLevelEnum,
      query?: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'champId' is not null or undefined
      if (champId === null || champId === undefined) {
        throw new RequiredError(
          'champId',
          'Required parameter champId was null or undefined when calling binChampadminAdminExemptionLightWeightGet.'
        );
      }
      const localVarPath = `/bin/champadmin/admin/exemption/light/weight`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (champId !== undefined) {
        localVarQueryParameter['champId'] = champId;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start;
      }

      if (sortingFilter !== undefined) {
        localVarQueryParameter['sortingFilter'] = sortingFilter;
      }

      if (filter !== undefined) {
        localVarQueryParameter['filter'] = filter;
      }

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Add new exemption to championship
     * @summary Add new exemption to championship
     * @param {ExemptionAdminAddModel} [exemptionAdminAddModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminExemptionPost: async (
      exemptionAdminAddModel?: ExemptionAdminAddModel,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/admin/exemption`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof exemptionAdminAddModel !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(exemptionAdminAddModel !== undefined ? exemptionAdminAddModel : {})
        : exemptionAdminAddModel || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Is used to get exemption data by its id
     * @summary Get exemption info by id
     * @param {number} id id of exemption
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminExemptionQueryGet: async (
      id: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling binChampadminAdminExemptionQueryGet.'
        );
      }
      const localVarPath = `/bin/champadmin/admin/exemption/query`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (id !== undefined) {
        localVarQueryParameter['id'] = id;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Import exemptions
     * @summary Import exemptions
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminExemptionUploadPost: async (
      file?: any,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/admin/exemption/upload`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new FormData();

      if (file !== undefined) {
        localVarFormParams.append('file', file as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Delete file by id
     * @summary Remove file by its id
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminFileDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling binChampadminAdminFileDelete.'
        );
      }
      const localVarPath = `/bin/champadmin/admin/file`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (id !== undefined) {
        localVarQueryParameter['id'] = id;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Download file by id
     * @summary Download file by id
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminFileGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling binChampadminAdminFileGet.'
        );
      }
      const localVarPath = `/bin/champadmin/admin/file`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (id !== undefined) {
        localVarQueryParameter['id'] = id;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Download files by id list
     * @summary Download files by id list
     * @param {Array<number>} id Required id list parameter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminFileListGet: async (
      id: Array<number>,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling binChampadminAdminFileListGet.'
        );
      }
      const localVarPath = `/bin/champadmin/admin/file/list`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (id) {
        localVarQueryParameter['id'] = id;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get handicap player by handicap id
     * @param {string} handicapId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminHandicapGet: async (
      handicapId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'handicapId' is not null or undefined
      if (handicapId === null || handicapId === undefined) {
        throw new RequiredError(
          'handicapId',
          'Required parameter handicapId was null or undefined when calling binChampadminAdminHandicapGet.'
        );
      }
      const localVarPath = `/bin/champadmin/admin/handicap`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (handicapId !== undefined) {
        localVarQueryParameter['handicapId'] = handicapId;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns list of players
     * @summary Get list of incomplete sides
     * @param {number} champId Filter by championship id
     * @param {number} [limit]
     * @param {number} [start]
     * @param {string} [query] Optional First/Last Name search query
     * @param {string} [startDate] Optional start qualifying date range
     * @param {string} [endDate] Optional end qualifying date range
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminIncompleteSidesGet: async (
      champId: number,
      limit?: number,
      start?: number,
      query?: string,
      startDate?: string,
      endDate?: string,
      sortingFilter?: SortingFilterEnum,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'champId' is not null or undefined
      if (champId === null || champId === undefined) {
        throw new RequiredError(
          'champId',
          'Required parameter champId was null or undefined when calling binChampadminAdminIncompleteSidesGet.'
        );
      }
      const localVarPath = `/bin/champadmin/admin/incomplete/sides`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start;
      }

      if (champId !== undefined) {
        localVarQueryParameter['champId'] = champId;
      }

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (startDate !== undefined) {
        localVarQueryParameter['startDate'] =
          (startDate as any) instanceof Date
            ? (startDate as any).toISOString().substr(0, 10)
            : startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['endDate'] =
          (endDate as any) instanceof Date ? (endDate as any).toISOString().substr(0, 10) : endDate;
      }

      if (sortingFilter !== undefined) {
        localVarQueryParameter['sortingFilter'] = sortingFilter;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get unsubscribe page absolute link
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminLinksUnsubscribeGet: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/admin/links/unsubscribe`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Delete exemption by id
     * @summary Remove exemption by its id
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminMessageDelete: async (
      id: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling binChampadminAdminMessageDelete.'
        );
      }
      const localVarPath = `/bin/champadmin/admin/message`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (id !== undefined) {
        localVarQueryParameter['id'] = id;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns list of messages
     * @summary Get list of messages
     * @param {number} [limit]
     * @param {number} [start]
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {MessageTypeEnum} [messageType] Optional filter by message type
     * @param {boolean} [dismissible] Optional filter by current date and \&#39;dateRangeEnabled\&#39;
     * @param {boolean} [dateRangeEnabled] Optional filter by \&#39;dateRangeEnabled\&#39; flag
     * @param {number} [playerId] Filter by player id
     * @param {string} [query] Optional search query parameter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminMessageGet: async (
      limit?: number,
      start?: number,
      sortingFilter?: SortingFilterEnum,
      messageType?: MessageTypeEnum,
      dismissible?: boolean,
      dateRangeEnabled?: boolean,
      playerId?: number,
      query?: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/admin/message`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start;
      }

      if (sortingFilter !== undefined) {
        localVarQueryParameter['sortingFilter'] = sortingFilter;
      }

      if (messageType !== undefined) {
        localVarQueryParameter['messageType'] = messageType;
      }

      if (dismissible !== undefined) {
        localVarQueryParameter['dismissible'] = dismissible;
      }

      if (dateRangeEnabled !== undefined) {
        localVarQueryParameter['dateRangeEnabled'] = dateRangeEnabled;
      }

      if (playerId !== undefined) {
        localVarQueryParameter['playerId'] = playerId;
      }

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Add new message
     * @summary Add new message
     * @param {MessageAddModel} [messageAddModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminMessagePost: async (
      messageAddModel?: MessageAddModel,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/admin/message`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof messageAddModel !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(messageAddModel !== undefined ? messageAddModel : {})
        : messageAddModel || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Is used to get message data by its id
     * @summary Get message by id
     * @param {number} id id of the message
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminMessageQueryGet: async (
      id: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling binChampadminAdminMessageQueryGet.'
        );
      }
      const localVarPath = `/bin/champadmin/admin/message/query`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (id !== undefined) {
        localVarQueryParameter['id'] = id;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Updates message
     * @summary Update message
     * @param {MessageEditModel} [messageEditModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminMessageUpdatePost: async (
      messageEditModel?: MessageEditModel,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/admin/message/update`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof messageEditModel !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(messageEditModel !== undefined ? messageEditModel : {})
        : messageEditModel || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns Champiobship Type by id
     * @summary Get Applications Pairs by ChampId
     * @param {number} champId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminPairGet: async (champId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'champId' is not null or undefined
      if (champId === null || champId === undefined) {
        throw new RequiredError(
          'champId',
          'Required parameter champId was null or undefined when calling binChampadminAdminPairGet.'
        );
      }
      const localVarPath = `/bin/champadmin/admin/pair`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (champId !== undefined) {
        localVarQueryParameter['champId'] = champId;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns list of pairs
     * @summary Get filtered list of pairs
     * @param {number} limit
     * @param {number} start
     * @param {number} playerId
     * @param {string} [query] Optional search query
     * @param {PairSortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminPairHistoryGet: async (
      limit: number,
      start: number,
      playerId: number,
      query?: string,
      sortingFilter?: PairSortingFilterEnum,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'limit' is not null or undefined
      if (limit === null || limit === undefined) {
        throw new RequiredError(
          'limit',
          'Required parameter limit was null or undefined when calling binChampadminAdminPairHistoryGet.'
        );
      }
      // verify required parameter 'start' is not null or undefined
      if (start === null || start === undefined) {
        throw new RequiredError(
          'start',
          'Required parameter start was null or undefined when calling binChampadminAdminPairHistoryGet.'
        );
      }
      // verify required parameter 'playerId' is not null or undefined
      if (playerId === null || playerId === undefined) {
        throw new RequiredError(
          'playerId',
          'Required parameter playerId was null or undefined when calling binChampadminAdminPairHistoryGet.'
        );
      }
      const localVarPath = `/bin/champadmin/admin/pair/history`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start;
      }

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (sortingFilter !== undefined) {
        localVarQueryParameter['sortingFilter'] = sortingFilter;
      }

      if (playerId !== undefined) {
        localVarQueryParameter['playerId'] = playerId;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create/Update Applications Pairs
     * @param {PairListRequestModel} [pairListRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminPairPost: async (
      pairListRequestModel?: PairListRequestModel,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/admin/pair`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof pairListRequestModel !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(pairListRequestModel !== undefined ? pairListRequestModel : {})
        : pairListRequestModel || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Refunds payment
     * @summary Refunds payment
     * @param {RefundPaymentAdminEditModel} [refundPaymentAdminEditModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminPaymentEditRefundPost: async (
      refundPaymentAdminEditModel?: RefundPaymentAdminEditModel,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/admin/payment/edit/refund`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof refundPaymentAdminEditModel !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(
            refundPaymentAdminEditModel !== undefined ? refundPaymentAdminEditModel : {}
          )
        : refundPaymentAdminEditModel || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Refunds payment
     * @summary Refunds payment
     * @param {FourBallRefundPaymentAdminModel} [fourBallRefundPaymentAdminModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminPaymentFourBallRefundPost: async (
      fourBallRefundPaymentAdminModel?: FourBallRefundPaymentAdminModel,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/admin/payment/four-ball-refund`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof fourBallRefundPaymentAdminModel !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(
            fourBallRefundPaymentAdminModel !== undefined ? fourBallRefundPaymentAdminModel : {}
          )
        : fourBallRefundPaymentAdminModel || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get form data to make post request to payment processor
     * @param {number} appId Application id
     * @param {string} referrer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminPaymentGet: async (
      appId: number,
      referrer: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'appId' is not null or undefined
      if (appId === null || appId === undefined) {
        throw new RequiredError(
          'appId',
          'Required parameter appId was null or undefined when calling binChampadminAdminPaymentGet.'
        );
      }
      // verify required parameter 'referrer' is not null or undefined
      if (referrer === null || referrer === undefined) {
        throw new RequiredError(
          'referrer',
          'Required parameter referrer was null or undefined when calling binChampadminAdminPaymentGet.'
        );
      }
      const localVarPath = `/bin/champadmin/admin/payment`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (appId !== undefined) {
        localVarQueryParameter['appId'] = appId;
      }

      if (referrer !== undefined) {
        localVarQueryParameter['referrer'] = referrer;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Refunds payment
     * @summary Refunds payment
     * @param {RefundPaymentAdminModel} [refundPaymentAdminModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminPaymentRefundPost: async (
      refundPaymentAdminModel?: RefundPaymentAdminModel,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/admin/payment/refund`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof refundPaymentAdminModel !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(refundPaymentAdminModel !== undefined ? refundPaymentAdminModel : {})
        : refundPaymentAdminModel || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Voids Refund payment
     * @summary Voids Refund payment
     * @param {VoidRefundPaymentAdminModel} [voidRefundPaymentAdminModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminPaymentRefundVoidPost: async (
      voidRefundPaymentAdminModel?: VoidRefundPaymentAdminModel,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/admin/payment/refund/void`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof voidRefundPaymentAdminModel !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(
            voidRefundPaymentAdminModel !== undefined ? voidRefundPaymentAdminModel : {}
          )
        : voidRefundPaymentAdminModel || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns list of applications
     * @summary Get filtered list of applications for custom email
     * @param {number} [limit]
     * @param {number} [start]
     * @param {Array<number>} [year] Optional Filter by year id
     * @param {Array<number>} [champTypeId] Optional Filter by ChampionshipType Model id
     * @param {string} [query] Optional search query parameter
     * @param {boolean} [twoYearParticipation] Optional Filter to find Two Year Participation
     * @param {boolean} [excludeApplied] Optional Exclude Applied Players Filter
     * @param {boolean} [exempt] Optional Filter to exclude exempt players
     * @param {PlayerStatusEnum} [playerStatus] Optional Player Status Filter
     * @param {string} [wagrRank] Optional WAGR Rank Filter
     * @param {boolean} [adaRequest] Optional ADA Request Filter, true - include, false - exclude
     * @param {boolean} [blacklist] Optional Blacklist Filter, true - include, false - exclude
     * @param {boolean} [watchlist] Optional Watchlist Filter, true - include, false - exclude
     * @param {number} [age] Optional Filter by Player\&#39;s age
     * @param {string} [handicapIndex] Optional Handicap Index Filter
     * @param {Array<number>} [qualifyingSiteId] Optional qualifying site Ids
     * @param {boolean} [applicationAccepted] Optional filter for accepted or q applications
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {EmailTemplateTypeEnum} [templType]
     * @param {number} [templId]
     * @param {Array<number>} [waitlistId] Optional Filter by Waitlist QS id
     * @param {Array<number>} [excludeWaitlistId] Optional Filter exclude by Waitlist QS id
     * @param {boolean} [waitlistAll] Optional Filter to find All waitlisted player by champType &amp; year
     * @param {boolean} [excludeWaitlistAll] Optional Filter to exclude All waitlisted player by champType &amp; year
     * @param {boolean} [excludeBlacklistAll] Optional Filter to exclude All blacklisted player by champType &amp; year
     * @param {Array<BlacklistStatusEnum>} [excludeBlacklistStatus]
     * @param {QualifyingStatusEnum} [qualifyingStatus] Optional Application Qualifying Status Filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminPlayersCustomEmailGet: async (
      limit?: number,
      start?: number,
      year?: Array<number>,
      champTypeId?: Array<number>,
      query?: string,
      twoYearParticipation?: boolean,
      excludeApplied?: boolean,
      exempt?: boolean,
      playerStatus?: PlayerStatusEnum,
      wagrRank?: string,
      adaRequest?: boolean,
      blacklist?: boolean,
      watchlist?: boolean,
      age?: number,
      handicapIndex?: string,
      qualifyingSiteId?: Array<number>,
      applicationAccepted?: boolean,
      sortingFilter?: SortingFilterEnum,
      templType?: EmailTemplateTypeEnum,
      templId?: number,
      waitlistId?: Array<number>,
      excludeWaitlistId?: Array<number>,
      waitlistAll?: boolean,
      excludeWaitlistAll?: boolean,
      excludeBlacklistAll?: boolean,
      excludeBlacklistStatus?: Array<BlacklistStatusEnum>,
      qualifyingStatus?: QualifyingStatusEnum,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/admin/players/custom/email`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start;
      }

      if (year) {
        localVarQueryParameter['year'] = year;
      }

      if (champTypeId) {
        localVarQueryParameter['champTypeId'] = champTypeId;
      }

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (twoYearParticipation !== undefined) {
        localVarQueryParameter['twoYearParticipation'] = twoYearParticipation;
      }

      if (excludeApplied !== undefined) {
        localVarQueryParameter['excludeApplied'] = excludeApplied;
      }

      if (exempt !== undefined) {
        localVarQueryParameter['exempt'] = exempt;
      }

      if (playerStatus !== undefined) {
        localVarQueryParameter['playerStatus'] = playerStatus;
      }

      if (wagrRank !== undefined) {
        localVarQueryParameter['wagrRank'] = wagrRank;
      }

      if (adaRequest !== undefined) {
        localVarQueryParameter['adaRequest'] = adaRequest;
      }

      if (blacklist !== undefined) {
        localVarQueryParameter['blacklist'] = blacklist;
      }

      if (watchlist !== undefined) {
        localVarQueryParameter['watchlist'] = watchlist;
      }

      if (age !== undefined) {
        localVarQueryParameter['age'] = age;
      }

      if (handicapIndex !== undefined) {
        localVarQueryParameter['handicapIndex'] = handicapIndex;
      }

      if (qualifyingSiteId) {
        localVarQueryParameter['qualifyingSiteId'] = qualifyingSiteId;
      }

      if (applicationAccepted !== undefined) {
        localVarQueryParameter['applicationAccepted'] = applicationAccepted;
      }

      if (sortingFilter !== undefined) {
        localVarQueryParameter['sortingFilter'] = sortingFilter;
      }

      if (templType !== undefined) {
        localVarQueryParameter['templType'] = templType;
      }

      if (templId !== undefined) {
        localVarQueryParameter['templId'] = templId;
      }

      if (waitlistId) {
        localVarQueryParameter['waitlistId'] = waitlistId;
      }

      if (excludeWaitlistId) {
        localVarQueryParameter['excludeWaitlistId'] = excludeWaitlistId;
      }

      if (waitlistAll !== undefined) {
        localVarQueryParameter['waitlistAll'] = waitlistAll;
      }

      if (excludeWaitlistAll !== undefined) {
        localVarQueryParameter['excludeWaitlistAll'] = excludeWaitlistAll;
      }

      if (excludeBlacklistAll !== undefined) {
        localVarQueryParameter['excludeBlacklistAll'] = excludeBlacklistAll;
      }

      if (excludeBlacklistStatus) {
        localVarQueryParameter['excludeBlacklistStatus'] = excludeBlacklistStatus;
      }

      if (qualifyingStatus !== undefined) {
        localVarQueryParameter['qualifyingStatus'] = qualifyingStatus;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Delete point of contact by id
     * @summary Remove point of contact by its id
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminPocDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling binChampadminAdminPocDelete.'
        );
      }
      const localVarPath = `/bin/champadmin/admin/poc`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (id !== undefined) {
        localVarQueryParameter['id'] = id;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns list of points of contact
     * @summary Get list of points of contact
     * @param {number} [limit]
     * @param {number} [start]
     * @param {string} [query] Expression to filter entries (e.g. %word%)
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminPocGet: async (
      limit?: number,
      start?: number,
      query?: string,
      sortingFilter?: SortingFilterEnum,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/admin/poc`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start;
      }

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (sortingFilter !== undefined) {
        localVarQueryParameter['sortingFilter'] = sortingFilter;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Add new point of contact
     * @summary Add new point of contact
     * @param {PointOfContactAdminAddModel} [pointOfContactAdminAddModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminPocPost: async (
      pointOfContactAdminAddModel?: PointOfContactAdminAddModel,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/admin/poc`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof pointOfContactAdminAddModel !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(
            pointOfContactAdminAddModel !== undefined ? pointOfContactAdminAddModel : {}
          )
        : pointOfContactAdminAddModel || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Is used to get poc data by its id
     * @summary Get poc info by id
     * @param {number} id id of poc
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminPocQueryGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling binChampadminAdminPocQueryGet.'
        );
      }
      const localVarPath = `/bin/champadmin/admin/poc/query`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (id !== undefined) {
        localVarQueryParameter['id'] = id;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Updates point of contact
     * @summary Update point of contact
     * @param {PointOfContactAdminEditModel} [pointOfContactAdminEditModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminPocUpdatePost: async (
      pointOfContactAdminEditModel?: PointOfContactAdminEditModel,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/admin/poc/update`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof pointOfContactAdminEditModel !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(
            pointOfContactAdminEditModel !== undefined ? pointOfContactAdminEditModel : {}
          )
        : pointOfContactAdminEditModel || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get duplicated player\'s profile quantity
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminProfileDuplicateAutoMergeGet: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/admin/profile/duplicate/auto-merge`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Auto-merge players\' profiles
     * @summary Auto-merge players\' profiles
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminProfileDuplicateAutoMergePost: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/admin/profile/duplicate/auto-merge`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Is used to get player\'s profile duplicates
     * @summary Get player\'s profile duplicates
     * @param {string} id id of profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminProfileDuplicateGet: async (
      id: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling binChampadminAdminProfileDuplicateGet.'
        );
      }
      const localVarPath = `/bin/champadmin/admin/profile/duplicate`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (id !== undefined) {
        localVarQueryParameter['id'] = id;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Updates player profile
     * @summary Merge player\'s profiles
     * @param {IdListModel} [idListModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminProfileDuplicatePost: async (
      idListModel?: IdListModel,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/admin/profile/duplicate`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof idListModel !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(idListModel !== undefined ? idListModel : {})
        : idListModel || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get status list of requested subscriptions
     * @summary Get status list of requested subscriptions
     * @param {Array<EmailSubscriptionType>} [types]
     * @param {number} [playerId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminProfileEmailSubscriptionsGet: async (
      types?: Array<EmailSubscriptionType>,
      playerId?: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/admin/profile/email/subscriptions`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (types) {
        localVarQueryParameter['types'] = types;
      }

      if (playerId !== undefined) {
        localVarQueryParameter['playerId'] = playerId;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update subscriptions
     * @summary Update subscriptions
     * @param {PlayerEmailSubscriptionsStatus} [playerEmailSubscriptionsStatus]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminProfileEmailSubscriptionsPost: async (
      playerEmailSubscriptionsStatus?: PlayerEmailSubscriptionsStatus,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/admin/profile/email/subscriptions`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof playerEmailSubscriptionsStatus !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(
            playerEmailSubscriptionsStatus !== undefined ? playerEmailSubscriptionsStatus : {}
          )
        : playerEmailSubscriptionsStatus || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Is used to get profile data
     * @summary Get player profile
     * @param {string} id id of profile to return data for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminProfileGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling binChampadminAdminProfileGet.'
        );
      }
      const localVarPath = `/bin/champadmin/admin/profile`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (id !== undefined) {
        localVarQueryParameter['id'] = id;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Is used to get profile data
     * @summary [GraphQL] Get player profile
     * @param {string} id id of profile to return data for
     * @param {Array<string>} [fields] required fields to fetch, e.g. \&quot;aaa\&quot;, \&quot;bbb.ccc.ddd\&quot;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminProfileGraphqlGet: async (
      id: string,
      fields?: Array<string>,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling binChampadminAdminProfileGraphqlGet.'
        );
      }
      const localVarPath = `/bin/champadmin/admin/profile/graphql`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (id !== undefined) {
        localVarQueryParameter['id'] = id;
      }

      if (fields) {
        localVarQueryParameter['fields'] = fields;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns list of players
     * @summary Get list of players
     * @param {number} [limit] Per page
     * @param {number} [start] Page number
     * @param {string} [query] Query parameter to filter users based on: User Id, First Name, Last Name, Player Status, Date of Birth (e.g. query&#x3D;value*)
     * @param {PlayerFilters} [filter]
     * @param {string} [filterValue]
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {Array<number>} [champId] Optional Filter by champ id
     * @param {Array<number>} [year]
     * @param {Array<number>} [siteId] Optional Filter by qualifying site id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminProfileListGet: async (
      limit?: number,
      start?: number,
      query?: string,
      filter?: PlayerFilters,
      filterValue?: string,
      sortingFilter?: SortingFilterEnum,
      champId?: Array<number>,
      year?: Array<number>,
      siteId?: Array<number>,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/admin/profile/list`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start;
      }

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (filter !== undefined) {
        localVarQueryParameter['filter'] = filter;
      }

      if (filterValue !== undefined) {
        localVarQueryParameter['filterValue'] = filterValue;
      }

      if (sortingFilter !== undefined) {
        localVarQueryParameter['sortingFilter'] = sortingFilter;
      }

      if (champId) {
        localVarQueryParameter['champId'] = champId;
      }

      if (year) {
        localVarQueryParameter['year'] = year;
      }

      if (siteId) {
        localVarQueryParameter['siteId'] = siteId;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns list of players
     * @summary [GraphQL] Get list of players
     * @param {number} [limit] Per page
     * @param {number} [start] Page number
     * @param {string} [query] Query parameter to filter users based on: User Id, First Name, Last Name, Player Status, Date of Birth (e.g. query&#x3D;value*)
     * @param {PlayerFilters} [filter]
     * @param {string} [filterValue]
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {Array<number>} [champId] Optional Filter by champ id
     * @param {Array<number>} [year]
     * @param {Array<number>} [siteId] Optional Filter by qualifying site id
     * @param {Array<string>} [fields] required fields to fetch, e.g. \&quot;aaa\&quot;, \&quot;bbb.ccc.ddd\&quot;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminProfileListGraphqlGet: async (
      limit?: number,
      start?: number,
      query?: string,
      filter?: PlayerFilters,
      filterValue?: string,
      sortingFilter?: SortingFilterEnum,
      champId?: Array<number>,
      year?: Array<number>,
      siteId?: Array<number>,
      fields?: Array<string>,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/admin/profile/list/graphql`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start;
      }

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (filter !== undefined) {
        localVarQueryParameter['filter'] = filter;
      }

      if (filterValue !== undefined) {
        localVarQueryParameter['filterValue'] = filterValue;
      }

      if (sortingFilter !== undefined) {
        localVarQueryParameter['sortingFilter'] = sortingFilter;
      }

      if (champId) {
        localVarQueryParameter['champId'] = champId;
      }

      if (year) {
        localVarQueryParameter['year'] = year;
      }

      if (siteId) {
        localVarQueryParameter['siteId'] = siteId;
      }

      if (fields) {
        localVarQueryParameter['fields'] = fields;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Updates managed players
     * @summary Update managed players
     * @param {ProfileManagedPlayerAdminModel} [profileManagedPlayerAdminModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminProfileManagedplayerPost: async (
      profileManagedPlayerAdminModel?: ProfileManagedPlayerAdminModel,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/admin/profile/managedplayer`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof profileManagedPlayerAdminModel !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(
            profileManagedPlayerAdminModel !== undefined ? profileManagedPlayerAdminModel : {}
          )
        : profileManagedPlayerAdminModel || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns list of players
     * @summary Get list of players
     * @param {number} [limit] Per page
     * @param {number} [start] Page number
     * @param {string} [query] Query parameter to filter users based on: User Id, First Name, Last Name, Player Status, Date of Birth (e.g. query&#x3D;value*)
     * @param {PlayerFilters} [filter]
     * @param {string} [filterValue]
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {Array<number>} [champId] Optional Filter by champ id
     * @param {Array<number>} [year]
     * @param {Array<number>} [champTypeId] Optional Filter by champ type id
     * @param {string} [blackList] Black list
     * @param {Array<number>} [siteId] Optional Filter by qualifying site id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminProfileMessageListGet: async (
      limit?: number,
      start?: number,
      query?: string,
      filter?: PlayerFilters,
      filterValue?: string,
      sortingFilter?: SortingFilterEnum,
      champId?: Array<number>,
      year?: Array<number>,
      champTypeId?: Array<number>,
      blackList?: string,
      siteId?: Array<number>,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/admin/profile/message/list`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start;
      }

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (filter !== undefined) {
        localVarQueryParameter['filter'] = filter;
      }

      if (filterValue !== undefined) {
        localVarQueryParameter['filterValue'] = filterValue;
      }

      if (sortingFilter !== undefined) {
        localVarQueryParameter['sortingFilter'] = sortingFilter;
      }

      if (champId) {
        localVarQueryParameter['champId'] = champId;
      }

      if (year) {
        localVarQueryParameter['year'] = year;
      }

      if (champTypeId) {
        localVarQueryParameter['champTypeId'] = champTypeId;
      }

      if (blackList !== undefined) {
        localVarQueryParameter['blackList'] = blackList;
      }

      if (siteId) {
        localVarQueryParameter['siteId'] = siteId;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Updates player profile
     * @summary Save/update player profile
     * @param {ProfileAdminRequestModel} [profileAdminRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminProfilePost: async (
      profileAdminRequestModel?: ProfileAdminRequestModel,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/admin/profile`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof profileAdminRequestModel !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(profileAdminRequestModel !== undefined ? profileAdminRequestModel : {})
        : profileAdminRequestModel || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get profiles quantity
     * @param {PlayerFilters} [filter]
     * @param {string} [filterValue]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminProfileQuantityGet: async (
      filter?: PlayerFilters,
      filterValue?: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/admin/profile/quantity`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (filter !== undefined) {
        localVarQueryParameter['filter'] = filter;
      }

      if (filterValue !== undefined) {
        localVarQueryParameter['filterValue'] = filterValue;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Closes QS
     * @summary Provides zip file for download with reports with Applications of QS
     * @param {number} champId
     * @param {QualifyingSiteFilters} [filter]
     * @param {IdListModel} [idListModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminQualifyingsiteApplicationsDownloadPost: async (
      champId: number,
      filter?: QualifyingSiteFilters,
      idListModel?: IdListModel,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'champId' is not null or undefined
      if (champId === null || champId === undefined) {
        throw new RequiredError(
          'champId',
          'Required parameter champId was null or undefined when calling binChampadminAdminQualifyingsiteApplicationsDownloadPost.'
        );
      }
      const localVarPath = `/bin/champadmin/admin/qualifyingsite/applications/download`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (champId !== undefined) {
        localVarQueryParameter['champId'] = champId;
      }

      if (filter !== undefined) {
        localVarQueryParameter['filter'] = filter;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof idListModel !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(idListModel !== undefined ? idListModel : {})
        : idListModel || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Auto-assign QS number
     * @summary Auto-assign QS number
     * @param {number} champId
     * @param {QualifyingLevelEnum} level
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminQualifyingsiteAutoAssignPost: async (
      champId: number,
      level: QualifyingLevelEnum,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'champId' is not null or undefined
      if (champId === null || champId === undefined) {
        throw new RequiredError(
          'champId',
          'Required parameter champId was null or undefined when calling binChampadminAdminQualifyingsiteAutoAssignPost.'
        );
      }
      // verify required parameter 'level' is not null or undefined
      if (level === null || level === undefined) {
        throw new RequiredError(
          'level',
          'Required parameter level was null or undefined when calling binChampadminAdminQualifyingsiteAutoAssignPost.'
        );
      }
      const localVarPath = `/bin/champadmin/admin/qualifyingsite/auto_assign`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (champId !== undefined) {
        localVarQueryParameter['champId'] = champId;
      }

      if (level !== undefined) {
        localVarQueryParameter['level'] = level;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns list of qualifying sites
     * @summary Get list of qualifying sites by championship Ids
     * @param {Array<number>} champId Filter by champ id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminQualifyingsiteChampsGet: async (
      champId: Array<number>,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'champId' is not null or undefined
      if (champId === null || champId === undefined) {
        throw new RequiredError(
          'champId',
          'Required parameter champId was null or undefined when calling binChampadminAdminQualifyingsiteChampsGet.'
        );
      }
      const localVarPath = `/bin/champadmin/admin/qualifyingsite/champs`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (champId) {
        localVarQueryParameter['champId'] = champId;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns list of qualifying sites by champ type ids
     * @summary Get list of qualifying sites by champ type id
     * @param {Array<number>} champTypeId Champ Type Ids
     * @param {Array<number>} year Champ Type Ids
     * @param {number} [limit]
     * @param {number} [start]
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminQualifyingsiteChamptypeidGet: async (
      champTypeId: Array<number>,
      year: Array<number>,
      limit?: number,
      start?: number,
      sortingFilter?: SortingFilterEnum,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'champTypeId' is not null or undefined
      if (champTypeId === null || champTypeId === undefined) {
        throw new RequiredError(
          'champTypeId',
          'Required parameter champTypeId was null or undefined when calling binChampadminAdminQualifyingsiteChamptypeidGet.'
        );
      }
      // verify required parameter 'year' is not null or undefined
      if (year === null || year === undefined) {
        throw new RequiredError(
          'year',
          'Required parameter year was null or undefined when calling binChampadminAdminQualifyingsiteChamptypeidGet.'
        );
      }
      const localVarPath = `/bin/champadmin/admin/qualifyingsite/champtypeid`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start;
      }

      if (champTypeId) {
        localVarQueryParameter['champTypeId'] = champTypeId;
      }

      if (sortingFilter !== undefined) {
        localVarQueryParameter['sortingFilter'] = sortingFilter;
      }

      if (year) {
        localVarQueryParameter['year'] = year;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Checks whether it is possible to create a \'QualifyingSite\' with such data.
     * @summary Check if a combination of ChampId+Level+Number is still free
     * @param {number} champId
     * @param {string} level
     * @param {number} number
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminQualifyingsiteCheckGet: async (
      champId: number,
      level: string,
      number: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'champId' is not null or undefined
      if (champId === null || champId === undefined) {
        throw new RequiredError(
          'champId',
          'Required parameter champId was null or undefined when calling binChampadminAdminQualifyingsiteCheckGet.'
        );
      }
      // verify required parameter 'level' is not null or undefined
      if (level === null || level === undefined) {
        throw new RequiredError(
          'level',
          'Required parameter level was null or undefined when calling binChampadminAdminQualifyingsiteCheckGet.'
        );
      }
      // verify required parameter 'number' is not null or undefined
      if (number === null || number === undefined) {
        throw new RequiredError(
          'number',
          'Required parameter number was null or undefined when calling binChampadminAdminQualifyingsiteCheckGet.'
        );
      }
      const localVarPath = `/bin/champadmin/admin/qualifyingsite/check`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (champId !== undefined) {
        localVarQueryParameter['champId'] = champId;
      }

      if (level !== undefined) {
        localVarQueryParameter['level'] = level;
      }

      if (number !== undefined) {
        localVarQueryParameter['number'] = number;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Closes QS
     * @summary Closes selected qualifying sites and provides zip file for download with reports
     * @param {IdListModel} [idListModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminQualifyingsiteClosePost: async (
      idListModel?: IdListModel,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/admin/qualifyingsite/close`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof idListModel !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(idListModel !== undefined ? idListModel : {})
        : idListModel || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Delete qualifying site by id
     * @summary Remove qualifying site by its id
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminQualifyingsiteDelete: async (
      id: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling binChampadminAdminQualifyingsiteDelete.'
        );
      }
      const localVarPath = `/bin/champadmin/admin/qualifyingsite`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (id !== undefined) {
        localVarQueryParameter['id'] = id;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns list of qualifying sites
     * @summary Get list of qualifying sites by championship Id
     * @param {number} champId
     * @param {number} [limit]
     * @param {number} [start]
     * @param {QualifyingSiteFilters} [filter]
     * @param {string} [filterValue]
     * @param {string} [startDate] Optional start date range
     * @param {string} [endDate] Optional end date range
     * @param {string} [query]
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminQualifyingsiteGet: async (
      champId: number,
      limit?: number,
      start?: number,
      filter?: QualifyingSiteFilters,
      filterValue?: string,
      startDate?: string,
      endDate?: string,
      query?: string,
      sortingFilter?: SortingFilterEnum,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'champId' is not null or undefined
      if (champId === null || champId === undefined) {
        throw new RequiredError(
          'champId',
          'Required parameter champId was null or undefined when calling binChampadminAdminQualifyingsiteGet.'
        );
      }
      const localVarPath = `/bin/champadmin/admin/qualifyingsite`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start;
      }

      if (champId !== undefined) {
        localVarQueryParameter['champId'] = champId;
      }

      if (filter !== undefined) {
        localVarQueryParameter['filter'] = filter;
      }

      if (filterValue !== undefined) {
        localVarQueryParameter['filterValue'] = filterValue;
      }

      if (startDate !== undefined) {
        localVarQueryParameter['startDate'] =
          (startDate as any) instanceof Date
            ? (startDate as any).toISOString().substr(0, 10)
            : startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['endDate'] =
          (endDate as any) instanceof Date ? (endDate as any).toISOString().substr(0, 10) : endDate;
      }

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (sortingFilter !== undefined) {
        localVarQueryParameter['sortingFilter'] = sortingFilter;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns list of qualifying sites
     * @summary Get list of qualifying sites by championship Id
     * @param {number} champId
     * @param {number} [limit]
     * @param {number} [start]
     * @param {QualifyingSiteFilters} [filter]
     * @param {string} [filterValue]
     * @param {string} [startDate] Optional start date range
     * @param {string} [endDate] Optional end date range
     * @param {string} [query]
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminQualifyingsiteLightWeightGet: async (
      champId: number,
      limit?: number,
      start?: number,
      filter?: QualifyingSiteFilters,
      filterValue?: string,
      startDate?: string,
      endDate?: string,
      query?: string,
      sortingFilter?: SortingFilterEnum,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'champId' is not null or undefined
      if (champId === null || champId === undefined) {
        throw new RequiredError(
          'champId',
          'Required parameter champId was null or undefined when calling binChampadminAdminQualifyingsiteLightWeightGet.'
        );
      }
      const localVarPath = `/bin/champadmin/admin/qualifyingsite/light/weight`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start;
      }

      if (champId !== undefined) {
        localVarQueryParameter['champId'] = champId;
      }

      if (filter !== undefined) {
        localVarQueryParameter['filter'] = filter;
      }

      if (filterValue !== undefined) {
        localVarQueryParameter['filterValue'] = filterValue;
      }

      if (startDate !== undefined) {
        localVarQueryParameter['startDate'] =
          (startDate as any) instanceof Date
            ? (startDate as any).toISOString().substr(0, 10)
            : startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['endDate'] =
          (endDate as any) instanceof Date ? (endDate as any).toISOString().substr(0, 10) : endDate;
      }

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (sortingFilter !== undefined) {
        localVarQueryParameter['sortingFilter'] = sortingFilter;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Add new qualifying site to championship
     * @summary Add new qualifying site to championship
     * @param {QualifyingSiteAddModel} [qualifyingSiteAddModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminQualifyingsitePost: async (
      qualifyingSiteAddModel?: QualifyingSiteAddModel,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/admin/qualifyingsite`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof qualifyingSiteAddModel !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(qualifyingSiteAddModel !== undefined ? qualifyingSiteAddModel : {})
        : qualifyingSiteAddModel || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Is used to get qualifying site data by its id
     * @summary Get qualifying site info by id
     * @param {number} id id of qualifying site
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminQualifyingsiteQueryGet: async (
      id: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling binChampadminAdminQualifyingsiteQueryGet.'
        );
      }
      const localVarPath = `/bin/champadmin/admin/qualifyingsite/query`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (id !== undefined) {
        localVarQueryParameter['id'] = id;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns list of qualifying sites uploaded results
     * @summary Get list of qualifying sites results by file id
     * @param {number} [limit]
     * @param {number} [start]
     * @param {number} [fileId]
     * @param {string} [query]
     * @param {UploadFileProgressionEnum} [status]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminQualifyingsiteResultDetailGet: async (
      limit?: number,
      start?: number,
      fileId?: number,
      query?: string,
      status?: UploadFileProgressionEnum,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/admin/qualifyingsite/result/detail`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start;
      }

      if (fileId !== undefined) {
        localVarQueryParameter['fileId'] = fileId;
      }

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (status !== undefined) {
        localVarQueryParameter['status'] = status;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Add new qualifying site to championship
     * @summary Update
     * @param {ScoreUpdateRequestAdminModelScoring} [scoreUpdateRequestAdminModelScoring]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminQualifyingsiteResultDetailPost: async (
      scoreUpdateRequestAdminModelScoring?: ScoreUpdateRequestAdminModelScoring,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/admin/qualifyingsite/result/detail`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof scoreUpdateRequestAdminModelScoring !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(
            scoreUpdateRequestAdminModelScoring !== undefined
              ? scoreUpdateRequestAdminModelScoring
              : {}
          )
        : scoreUpdateRequestAdminModelScoring || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns list of qualifying sites uploaded results
     * @summary Get list of qualifying sites results by qualifying site id
     * @param {number} champId
     * @param {number} [limit]
     * @param {number} [start]
     * @param {number} [qualifyingSiteId]
     * @param {string} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminQualifyingsiteResultListGet: async (
      champId: number,
      limit?: number,
      start?: number,
      qualifyingSiteId?: number,
      query?: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'champId' is not null or undefined
      if (champId === null || champId === undefined) {
        throw new RequiredError(
          'champId',
          'Required parameter champId was null or undefined when calling binChampadminAdminQualifyingsiteResultListGet.'
        );
      }
      const localVarPath = `/bin/champadmin/admin/qualifyingsite/result/list`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start;
      }

      if (champId !== undefined) {
        localVarQueryParameter['champId'] = champId;
      }

      if (qualifyingSiteId !== undefined) {
        localVarQueryParameter['qualifyingSiteId'] = qualifyingSiteId;
      }

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update
     * @param {UploadFileStatusRequestAdminModel} [uploadFileStatusRequestAdminModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminQualifyingsiteResultListPost: async (
      uploadFileStatusRequestAdminModel?: UploadFileStatusRequestAdminModel,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/admin/qualifyingsite/result/list`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof uploadFileStatusRequestAdminModel !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(
            uploadFileStatusRequestAdminModel !== undefined ? uploadFileStatusRequestAdminModel : {}
          )
        : uploadFileStatusRequestAdminModel || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Updates point of contact
     * @summary Update qualifying site
     * @param {QualifyingSiteEditModel} [qualifyingSiteEditModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminQualifyingsiteUpdatePost: async (
      qualifyingSiteEditModel?: QualifyingSiteEditModel,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/admin/qualifyingsite/update`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof qualifyingSiteEditModel !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(qualifyingSiteEditModel !== undefined ? qualifyingSiteEditModel : {})
        : qualifyingSiteEditModel || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns list of Q reasons
     * @summary Get list of queue reasons
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminQueueReasonGet: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/admin/queue-reason`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Download ada report
     * @summary Download ada report
     * @param {number} [champId] Optional filter by championship id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminReportsAdaGet: async (
      champId?: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/admin/reports/ada`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (champId !== undefined) {
        localVarQueryParameter['champId'] = champId;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Download anti-doping report
     * @summary Download anti-doping report
     * @param {number} [champId] Optional filter by championship id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminReportsAntidopingGet: async (
      champId?: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/admin/reports/antidoping`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (champId !== undefined) {
        localVarQueryParameter['champId'] = champId;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Download list of applications
     * @summary Download filtered list of applications
     * @param {number} champId Optional filter by championship id
     * @param {number} year Championship year
     * @param {ApplicationFilters} filter
     * @param {number} [limit]
     * @param {number} [start]
     * @param {string} [query] Optional full text search query
     * @param {string} [filterValue]
     * @param {string} [disabilityTypeFilter] Disability Type
     * @param {Array<number>} [appId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminReportsApplicationsGet: async (
      champId: number,
      year: number,
      filter: ApplicationFilters,
      limit?: number,
      start?: number,
      query?: string,
      filterValue?: string,
      disabilityTypeFilter?: string,
      appId?: Array<number>,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'champId' is not null or undefined
      if (champId === null || champId === undefined) {
        throw new RequiredError(
          'champId',
          'Required parameter champId was null or undefined when calling binChampadminAdminReportsApplicationsGet.'
        );
      }
      // verify required parameter 'year' is not null or undefined
      if (year === null || year === undefined) {
        throw new RequiredError(
          'year',
          'Required parameter year was null or undefined when calling binChampadminAdminReportsApplicationsGet.'
        );
      }
      // verify required parameter 'filter' is not null or undefined
      if (filter === null || filter === undefined) {
        throw new RequiredError(
          'filter',
          'Required parameter filter was null or undefined when calling binChampadminAdminReportsApplicationsGet.'
        );
      }
      const localVarPath = `/bin/champadmin/admin/reports/applications`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (champId !== undefined) {
        localVarQueryParameter['champId'] = champId;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start;
      }

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (year !== undefined) {
        localVarQueryParameter['year'] = year;
      }

      if (filter !== undefined) {
        localVarQueryParameter['filter'] = filter;
      }

      if (filterValue !== undefined) {
        localVarQueryParameter['filterValue'] = filterValue;
      }

      if (disabilityTypeFilter !== undefined) {
        localVarQueryParameter['disabilityTypeFilter'] = disabilityTypeFilter;
      }

      if (appId) {
        localVarQueryParameter['appId'] = appId;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Download blacklist report
     * @summary Download Blacklist across all champ report
     * @param {number} [champId] Optional filter by championship id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminReportsBlacklistGet: async (
      champId?: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/admin/reports/blacklist`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (champId !== undefined) {
        localVarQueryParameter['champId'] = champId;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Download close of entries data snapshot report
     * @summary Download close of entries data snapshot report
     * @param {number} [champId] Optional filter by championship id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminReportsCloseOfEntriesGet: async (
      champId?: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/admin/reports/close_of_entries`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (champId !== undefined) {
        localVarQueryParameter['champId'] = champId;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Download demographics report
     * @summary Download demographics report
     * @param {number} [champId] Optional filter by championship id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminReportsDemographicsGet: async (
      champId?: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/admin/reports/demographics`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (champId !== undefined) {
        localVarQueryParameter['champId'] = champId;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Download report by access key
     * @summary Download report by access key
     * @param {string} id
     * @param {boolean} [inline] Optional flag, set to true in order to get inlined response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminReportsDownloadGet: async (
      id: string,
      inline?: boolean,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling binChampadminAdminReportsDownloadGet.'
        );
      }
      const localVarPath = `/bin/champadmin/admin/reports/download`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (id !== undefined) {
        localVarQueryParameter['id'] = id;
      }

      if (inline !== undefined) {
        localVarQueryParameter['inline'] = inline;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Download duplicate applications report
     * @summary Download duplicate applications report
     * @param {number} [champId] Optional filter by championship id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminReportsDuplicatesGet: async (
      champId?: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/admin/reports/duplicates`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (champId !== undefined) {
        localVarQueryParameter['champId'] = champId;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Download exemption report
     * @summary Download \'Exemptions Chosen on Application Report\' report
     * @param {number} [champId] Optional filter by championship id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminReportsExemptionChosenGet: async (
      champId?: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/admin/reports/exemption_chosen`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (champId !== undefined) {
        localVarQueryParameter['champId'] = champId;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Download exemption report
     * @summary Download \'Exemptions Across All Championships\' report
     * @param {number} [champId] Optional filter by championship id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminReportsExemptionGet: async (
      champId?: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/admin/reports/exemption`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (champId !== undefined) {
        localVarQueryParameter['champId'] = champId;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Download notable players report
     * @summary Download notable players report
     * @param {number} [champId] Optional filter by championship id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminReportsNotablePlayersGet: async (
      champId?: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/admin/reports/notable_players`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (champId !== undefined) {
        localVarQueryParameter['champId'] = champId;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Download list of profiles
     * @summary Download filtered list of profiles
     * @param {number} [limit] Per page
     * @param {number} [start] Page number
     * @param {string} [query] Query parameter to filter users based on: User Id, First Name, Last Name, Player Status, Date of Birth (e.g. query&#x3D;value*)
     * @param {PlayerFilters} [filter]
     * @param {string} [filterValue]
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {Array<number>} [playerId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminReportsProfilesGet: async (
      limit?: number,
      start?: number,
      query?: string,
      filter?: PlayerFilters,
      filterValue?: string,
      sortingFilter?: SortingFilterEnum,
      playerId?: Array<number>,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/admin/reports/profiles`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start;
      }

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (filter !== undefined) {
        localVarQueryParameter['filter'] = filter;
      }

      if (filterValue !== undefined) {
        localVarQueryParameter['filterValue'] = filterValue;
      }

      if (sortingFilter !== undefined) {
        localVarQueryParameter['sortingFilter'] = sortingFilter;
      }

      if (playerId) {
        localVarQueryParameter['playerId'] = playerId;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Download qualifying sites report
     * @summary Download qualifying sites report
     * @param {number} [champId] Optional filter by championship id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminReportsQualifyingSitesGet: async (
      champId?: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/admin/reports/qualifying_sites`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (champId !== undefined) {
        localVarQueryParameter['champId'] = champId;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Download list of QS
     * @summary Download filtered list of QS
     * @param {number} champId
     * @param {number} [limit]
     * @param {number} [start]
     * @param {QualifyingSiteFilters} [filter]
     * @param {string} [filterValue]
     * @param {string} [startDate] Optional start date range
     * @param {string} [endDate] Optional end date range
     * @param {string} [query]
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {Array<number>} [id] Optional QS id multiple filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminReportsQualifyingsiteDownloadGet: async (
      champId: number,
      limit?: number,
      start?: number,
      filter?: QualifyingSiteFilters,
      filterValue?: string,
      startDate?: string,
      endDate?: string,
      query?: string,
      sortingFilter?: SortingFilterEnum,
      id?: Array<number>,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'champId' is not null or undefined
      if (champId === null || champId === undefined) {
        throw new RequiredError(
          'champId',
          'Required parameter champId was null or undefined when calling binChampadminAdminReportsQualifyingsiteDownloadGet.'
        );
      }
      const localVarPath = `/bin/champadmin/admin/reports/qualifyingsite_download`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start;
      }

      if (champId !== undefined) {
        localVarQueryParameter['champId'] = champId;
      }

      if (filter !== undefined) {
        localVarQueryParameter['filter'] = filter;
      }

      if (filterValue !== undefined) {
        localVarQueryParameter['filterValue'] = filterValue;
      }

      if (startDate !== undefined) {
        localVarQueryParameter['startDate'] =
          (startDate as any) instanceof Date
            ? (startDate as any).toISOString().substr(0, 10)
            : startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['endDate'] =
          (endDate as any) instanceof Date ? (endDate as any).toISOString().substr(0, 10) : endDate;
      }

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (sortingFilter !== undefined) {
        localVarQueryParameter['sortingFilter'] = sortingFilter;
      }

      if (id) {
        localVarQueryParameter['id'] = id;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Download refund applications report
     * @summary Download refund applications report
     * @param {number} [champId] Optional filter by championship id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminReportsRefundGet: async (
      champId?: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/admin/reports/refund`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (champId !== undefined) {
        localVarQueryParameter['champId'] = champId;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Download customer service report
     * @summary Download customer service report
     * @param {string} from
     * @param {string} to
     * @param {number} [champId] Championship ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminReportsServiceGet: async (
      from: string,
      to: string,
      champId?: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'from' is not null or undefined
      if (from === null || from === undefined) {
        throw new RequiredError(
          'from',
          'Required parameter from was null or undefined when calling binChampadminAdminReportsServiceGet.'
        );
      }
      // verify required parameter 'to' is not null or undefined
      if (to === null || to === undefined) {
        throw new RequiredError(
          'to',
          'Required parameter to was null or undefined when calling binChampadminAdminReportsServiceGet.'
        );
      }
      const localVarPath = `/bin/champadmin/admin/reports/service`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (champId !== undefined) {
        localVarQueryParameter['champId'] = champId;
      }

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString().substr(0, 10) : from;
      }

      if (to !== undefined) {
        localVarQueryParameter['to'] =
          (to as any) instanceof Date ? (to as any).toISOString().substr(0, 10) : to;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Download watchlist report
     * @summary Download Watchlist across all champ report
     * @param {number} [champId] Optional filter by championship id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminReportsWatchlistGet: async (
      champId?: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/admin/reports/watchlist`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (champId !== undefined) {
        localVarQueryParameter['champId'] = champId;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Download filtered list of withdrawn applications
     * @summary Download filtered list of withdrawn applications
     * @param {number} champId championship id
     * @param {IdListModel} [idListModel] Optional qualifying site ids to filter by
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminReportsWithdrawalPost: async (
      champId: number,
      idListModel?: IdListModel,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'champId' is not null or undefined
      if (champId === null || champId === undefined) {
        throw new RequiredError(
          'champId',
          'Required parameter champId was null or undefined when calling binChampadminAdminReportsWithdrawalPost.'
        );
      }
      const localVarPath = `/bin/champadmin/admin/reports/withdrawal`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (champId !== undefined) {
        localVarQueryParameter['champId'] = champId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof idListModel !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(idListModel !== undefined ? idListModel : {})
        : idListModel || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Download withdrawn applications report
     * @summary Download withdrawn applications report
     * @param {number} [champId] Optional filter by championship id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminReportsWithdrawnGet: async (
      champId?: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/admin/reports/withdrawn`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (champId !== undefined) {
        localVarQueryParameter['champId'] = champId;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Updates player team
     * @summary Update team
     * @param {CreateTeamAdminModel} [createTeamAdminModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminTeamCreatePost: async (
      createTeamAdminModel?: CreateTeamAdminModel,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/admin/team/create`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof createTeamAdminModel !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(createTeamAdminModel !== undefined ? createTeamAdminModel : {})
        : createTeamAdminModel || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Is used to get team data
     * @summary Get player team
     * @param {string} id id of team to return data for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminTeamGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling binChampadminAdminTeamGet.'
        );
      }
      const localVarPath = `/bin/champadmin/admin/team`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (id !== undefined) {
        localVarQueryParameter['id'] = id;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Updates player team
     * @summary Update team
     * @param {TeamAdminRequestModel} [teamAdminRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminTeamPost: async (
      teamAdminRequestModel?: TeamAdminRequestModel,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/admin/team`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof teamAdminRequestModel !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(teamAdminRequestModel !== undefined ? teamAdminRequestModel : {})
        : teamAdminRequestModel || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Upload Application Files
     * @param {number} champId Id of championship we uploading data for
     * @param {number} applicationId Id of application we uploading data for
     * @param {UploadedFileType} fileType
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminUploadApplicationFilePost: async (
      champId: number,
      applicationId: number,
      fileType: UploadedFileType,
      file?: any,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'champId' is not null or undefined
      if (champId === null || champId === undefined) {
        throw new RequiredError(
          'champId',
          'Required parameter champId was null or undefined when calling binChampadminAdminUploadApplicationFilePost.'
        );
      }
      // verify required parameter 'applicationId' is not null or undefined
      if (applicationId === null || applicationId === undefined) {
        throw new RequiredError(
          'applicationId',
          'Required parameter applicationId was null or undefined when calling binChampadminAdminUploadApplicationFilePost.'
        );
      }
      // verify required parameter 'fileType' is not null or undefined
      if (fileType === null || fileType === undefined) {
        throw new RequiredError(
          'fileType',
          'Required parameter fileType was null or undefined when calling binChampadminAdminUploadApplicationFilePost.'
        );
      }
      const localVarPath = `/bin/champadmin/admin/upload/application/file`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new FormData();

      if (champId !== undefined) {
        localVarQueryParameter['champId'] = champId;
      }

      if (applicationId !== undefined) {
        localVarQueryParameter['applicationId'] = applicationId;
      }

      if (fileType !== undefined) {
        localVarQueryParameter['fileType'] = fileType;
      }

      if (file !== undefined) {
        localVarFormParams.append('file', file as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create user
     * @param {UserCreateModel} [userCreateModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminUserCreatePost: async (
      userCreateModel?: UserCreateModel,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/admin/user/create`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof userCreateModel !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(userCreateModel !== undefined ? userCreateModel : {})
        : userCreateModel || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Delete user by id
     * @summary Remove user by its id
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminUserDelete: async (
      userId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          'userId',
          'Required parameter userId was null or undefined when calling binChampadminAdminUserDelete.'
        );
      }
      const localVarPath = `/bin/champadmin/admin/user`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (userId !== undefined) {
        localVarQueryParameter['userId'] = userId;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Is used to get user data
     * @summary Get user
     * @param {string} userId subscriber id of user to return user data for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminUserGet: async (userId: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          'userId',
          'Required parameter userId was null or undefined when calling binChampadminAdminUserGet.'
        );
      }
      const localVarPath = `/bin/champadmin/admin/user`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (userId !== undefined) {
        localVarQueryParameter['userId'] = userId;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns list of users
     * @summary Get list of users
     * @param {number} [limit] Per page
     * @param {number} [start] Starting position, value must be a multiple of limit parameter
     * @param {string} [query] Expression to filter users in lucene format (e.g. name:Val*)
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminUserListGet: async (
      limit?: number,
      start?: number,
      query?: string,
      sortingFilter?: SortingFilterEnum,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/admin/user/list`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start;
      }

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (sortingFilter !== undefined) {
        localVarQueryParameter['sortingFilter'] = sortingFilter;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Is used to get user data
     * @summary Get user
     * @param {string} userEmail user email to send reset password link
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminUserPasswordResetGet: async (
      userEmail: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'userEmail' is not null or undefined
      if (userEmail === null || userEmail === undefined) {
        throw new RequiredError(
          'userEmail',
          'Required parameter userEmail was null or undefined when calling binChampadminAdminUserPasswordResetGet.'
        );
      }
      const localVarPath = `/bin/champadmin/admin/user/password/reset`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (userEmail !== undefined) {
        localVarQueryParameter['userEmail'] = userEmail;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Updates user
     * @summary Update user
     * @param {UserResponseModel} [userResponseModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminUserPost: async (
      userResponseModel?: UserResponseModel,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/admin/user`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof userResponseModel !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(userResponseModel !== undefined ? userResponseModel : {})
        : userResponseModel || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Is used to update user data by auth0SearchQuery
     * @summary Update User type by utility
     * @param {string} id Champ type id to add for user type
     * @param {string} auth0SearchQuery User auth0 Search Query
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminUtilityUserGet: async (
      id: string,
      auth0SearchQuery: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling binChampadminAdminUtilityUserGet.'
        );
      }
      // verify required parameter 'auth0SearchQuery' is not null or undefined
      if (auth0SearchQuery === null || auth0SearchQuery === undefined) {
        throw new RequiredError(
          'auth0SearchQuery',
          'Required parameter auth0SearchQuery was null or undefined when calling binChampadminAdminUtilityUserGet.'
        );
      }
      const localVarPath = `/bin/champadmin/admin/utility/user`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (id !== undefined) {
        localVarQueryParameter['id'] = id;
      }

      if (auth0SearchQuery !== undefined) {
        localVarQueryParameter['auth0SearchQuery'] = auth0SearchQuery;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns list of Champiobship Types
     * @summary Get list of Champ-types
     * @param {number} [limit]
     * @param {number} [start]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminCommonChampTypeListGet: async (
      limit?: number,
      start?: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/common/champ-type-list`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns Championship by Id
     * @summary Get Championship by Id
     * @param {number} champId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminCommonChampionshipGet: async (
      champId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'champId' is not null or undefined
      if (champId === null || champId === undefined) {
        throw new RequiredError(
          'champId',
          'Required parameter champId was null or undefined when calling binChampadminCommonChampionshipGet.'
        );
      }
      const localVarPath = `/bin/champadmin/common/championship`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (champId !== undefined) {
        localVarQueryParameter['champId'] = champId;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Download info file by champ id
     * @summary Download info file by champ id
     * @param {number} champId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminCommonChampionshipInfoDownloadGet: async (
      champId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'champId' is not null or undefined
      if (champId === null || champId === undefined) {
        throw new RequiredError(
          'champId',
          'Required parameter champId was null or undefined when calling binChampadminCommonChampionshipInfoDownloadGet.'
        );
      }
      const localVarPath = `/bin/champadmin/common/championship/info/download`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (champId !== undefined) {
        localVarQueryParameter['champId'] = champId;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns the list of championship years
     * @summary Get list of years for championships
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminCommonChampionshipsYearsGet: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/common/championships/years`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns country by Id
     * @summary Get country by Id
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminCommonCountryGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling binChampadminCommonCountryGet.'
        );
      }
      const localVarPath = `/bin/champadmin/common/country`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (id !== undefined) {
        localVarQueryParameter['id'] = id;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns list of countries
     * @summary Get list of countries
     * @param {number} [limit]
     * @param {number} [start]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminCommonCountryListGet: async (
      limit?: number,
      start?: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/common/country/list`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns list of exemptions
     * @summary Get list of exemptions by championship Id and applicaton Id
     * @param {number} [champId]
     * @param {number} [appId]
     * @param {number} [limit]
     * @param {number} [start]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminCommonExemptionGet: async (
      champId?: number,
      appId?: number,
      limit?: number,
      start?: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/common/exemption`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (champId !== undefined) {
        localVarQueryParameter['champId'] = champId;
      }

      if (appId !== undefined) {
        localVarQueryParameter['appId'] = appId;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns USGA Footer Component
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminCommonFooterGet: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/common/footer`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns USGA Global Navigation Component
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminCommonNavigationGet: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/common/navigation`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Impersonation check
     * @summary Check if current user is impersonating other player
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminCommonProfileLinkImpersonationGet: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/common/profile/link/impersonation`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns list of approved qualifying sites
     * @summary Get list of approved qualifying sites by championship Id
     * @param {Array<number>} [ids] Optionally filter by ids
     * @param {number} [champId]
     * @param {number} [limit]
     * @param {number} [start]
     * @param {number} [appId]
     * @param {string} [query]
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {string} [startDate] Optional start date range
     * @param {string} [endDate] Optional end date range
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminCommonQualifyingsiteGet: async (
      ids?: Array<number>,
      champId?: number,
      limit?: number,
      start?: number,
      appId?: number,
      query?: string,
      sortingFilter?: SortingFilterEnum,
      startDate?: string,
      endDate?: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/common/qualifyingsite`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (ids) {
        localVarQueryParameter['ids'] = ids;
      }

      if (champId !== undefined) {
        localVarQueryParameter['champId'] = champId;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start;
      }

      if (appId !== undefined) {
        localVarQueryParameter['appId'] = appId;
      }

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (sortingFilter !== undefined) {
        localVarQueryParameter['sortingFilter'] = sortingFilter;
      }

      if (startDate !== undefined) {
        localVarQueryParameter['startDate'] =
          (startDate as any) instanceof Date
            ? (startDate as any).toISOString().substr(0, 10)
            : startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['endDate'] =
          (endDate as any) instanceof Date ? (endDate as any).toISOString().substr(0, 10) : endDate;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns state by Id
     * @summary Get state by Id
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminCommonStateGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling binChampadminCommonStateGet.'
        );
      }
      const localVarPath = `/bin/champadmin/common/state`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (id !== undefined) {
        localVarQueryParameter['id'] = id;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns list of states
     * @summary Get list of states
     * @param {StateTypeEnum} type
     * @param {number} [limit]
     * @param {number} [start]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminCommonStateListGet: async (
      type: StateTypeEnum,
      limit?: number,
      start?: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'type' is not null or undefined
      if (type === null || type === undefined) {
        throw new RequiredError(
          'type',
          'Required parameter type was null or undefined when calling binChampadminCommonStateListGet.'
        );
      }
      const localVarPath = `/bin/champadmin/common/state/list`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start;
      }

      if (type !== undefined) {
        localVarQueryParameter['type'] = type;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Stripe webhook handler servlet
     * @param {object} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminCommonStripeWebhookPost: async (
      body?: object,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/common/stripe/webhook`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof body !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(body !== undefined ? body : {})
        : body || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Check if email is already in use
     * @param {string} userEmail
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminCommonUserCheckGet: async (
      userEmail: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'userEmail' is not null or undefined
      if (userEmail === null || userEmail === undefined) {
        throw new RequiredError(
          'userEmail',
          'Required parameter userEmail was null or undefined when calling binChampadminCommonUserCheckGet.'
        );
      }
      const localVarPath = `/bin/champadmin/common/user/check`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (userEmail !== undefined) {
        localVarQueryParameter['userEmail'] = userEmail;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Login player
     * @param {UserLoginPlayerModel} [userLoginPlayerModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminCommonUserLoginPost: async (
      userLoginPlayerModel?: UserLoginPlayerModel,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/common/user/login`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof userLoginPlayerModel !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(userLoginPlayerModel !== undefined ? userLoginPlayerModel : {})
        : userLoginPlayerModel || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * reset password endpoint
     * @summary Reset password
     * @param {string} userEmail user email to send reset password link
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminCommonUserPasswordResetGet: async (
      userEmail: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'userEmail' is not null or undefined
      if (userEmail === null || userEmail === undefined) {
        throw new RequiredError(
          'userEmail',
          'Required parameter userEmail was null or undefined when calling binChampadminCommonUserPasswordResetGet.'
        );
      }
      const localVarPath = `/bin/champadmin/common/user/password/reset`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (userEmail !== undefined) {
        localVarQueryParameter['userEmail'] = userEmail;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create player
     * @param {UserCreatePlayerModel} [userCreatePlayerModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminCommonUserRegisterPost: async (
      userCreatePlayerModel?: UserCreatePlayerModel,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/common/user/register`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof userCreatePlayerModel !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(userCreatePlayerModel !== undefined ? userCreatePlayerModel : {})
        : userCreatePlayerModel || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns AGA by Id
     * @summary Get AGA by Id
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminOfficialAgaByIdGet: async (
      id: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling binChampadminOfficialAgaByIdGet.'
        );
      }
      const localVarPath = `/bin/champadmin/official/agaById`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (id !== undefined) {
        localVarQueryParameter['id'] = id;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns AGA by User Id taken from token
     * @summary Get AGA by User Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminOfficialAgaByUserIdGet: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/official/agaByUserId`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns list of AGA
     * @summary Get list of AGA
     * @param {number} [limit]
     * @param {number} [start]
     * @param {string} [query] Optional search query to filter out AGA by names.
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminOfficialAgaGet: async (
      limit?: number,
      start?: number,
      query?: string,
      sortingFilter?: SortingFilterEnum,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/official/aga`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start;
      }

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (sortingFilter !== undefined) {
        localVarQueryParameter['sortingFilter'] = sortingFilter;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Download list of applications
     * @summary Download withrawn list of applications for specific QS
     * @param {number} qualifyingSiteId Qualifying site ID
     * @param {string} authorization authorization token
     * @param {string} [listType]
     * @param {IdListModel} [idListModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminOfficialApplicationsDownloadQsPost: async (
      qualifyingSiteId: number,
      authorization: string,
      listType?: string,
      idListModel?: IdListModel,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'qualifyingSiteId' is not null or undefined
      if (qualifyingSiteId === null || qualifyingSiteId === undefined) {
        throw new RequiredError(
          'qualifyingSiteId',
          'Required parameter qualifyingSiteId was null or undefined when calling binChampadminOfficialApplicationsDownloadQsPost.'
        );
      }
      // verify required parameter 'authorization' is not null or undefined
      if (authorization === null || authorization === undefined) {
        throw new RequiredError(
          'authorization',
          'Required parameter authorization was null or undefined when calling binChampadminOfficialApplicationsDownloadQsPost.'
        );
      }
      const localVarPath = `/bin/champadmin/official/applications/download/qs`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (qualifyingSiteId !== undefined) {
        localVarQueryParameter['qualifyingSiteId'] = qualifyingSiteId;
      }

      if (authorization !== undefined) {
        localVarQueryParameter['authorization'] = authorization;
      }

      if (listType !== undefined) {
        localVarQueryParameter['listType'] = listType;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof idListModel !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(idListModel !== undefined ? idListModel : {})
        : idListModel || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns list of applications
     * @summary Get filtered list of applications
     * @param {number} year Championship year
     * @param {ApplicationFilters} filter
     * @param {number} [champId] Optional filter by championship id
     * @param {number} [limit]
     * @param {number} [start]
     * @param {string} [query] Optional full text search query
     * @param {string} [filterValue]
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminOfficialApplicationsGet: async (
      year: number,
      filter: ApplicationFilters,
      champId?: number,
      limit?: number,
      start?: number,
      query?: string,
      filterValue?: string,
      sortingFilter?: SortingFilterEnum,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'year' is not null or undefined
      if (year === null || year === undefined) {
        throw new RequiredError(
          'year',
          'Required parameter year was null or undefined when calling binChampadminOfficialApplicationsGet.'
        );
      }
      // verify required parameter 'filter' is not null or undefined
      if (filter === null || filter === undefined) {
        throw new RequiredError(
          'filter',
          'Required parameter filter was null or undefined when calling binChampadminOfficialApplicationsGet.'
        );
      }
      const localVarPath = `/bin/champadmin/official/applications`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (champId !== undefined) {
        localVarQueryParameter['champId'] = champId;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start;
      }

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (year !== undefined) {
        localVarQueryParameter['year'] = year;
      }

      if (filter !== undefined) {
        localVarQueryParameter['filter'] = filter;
      }

      if (filterValue !== undefined) {
        localVarQueryParameter['filterValue'] = filterValue;
      }

      if (sortingFilter !== undefined) {
        localVarQueryParameter['sortingFilter'] = sortingFilter;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns a list of calendar cards based on assigned championship types.
     * @summary Get calendar cards based on assigned championship types.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminOfficialCalendarGet: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/official/calendar`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns list of Champiobship Types
     * @summary Get list of Champ-types
     * @param {number} [limit]
     * @param {number} [start]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminOfficialChampTypeListGet: async (
      limit?: number,
      start?: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/official/champ-type-list`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns a boolean value based on the entry reviewed or not.
     * @summary Return whether entry is reviewed or not
     * @param {string} [query]
     * @param {number} [year]
     * @param {WorkflowTypeEnum} [champType]
     * @param {number} [champTypeId]
     * @param {Array<string>} [fields] required fields to fetch, e.g. \&quot;aaa\&quot;, \&quot;bbb.ccc.ddd\&quot;
     * @param {boolean} [active] indicates if champ is active
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminOfficialChampionshipAllEntriesReviewedGet: async (
      query?: string,
      year?: number,
      champType?: WorkflowTypeEnum,
      champTypeId?: number,
      fields?: Array<string>,
      active?: boolean,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/official/championship/allEntriesReviewed`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (year !== undefined) {
        localVarQueryParameter['year'] = year;
      }

      if (champType !== undefined) {
        localVarQueryParameter['champType'] = champType;
      }

      if (champTypeId !== undefined) {
        localVarQueryParameter['champTypeId'] = champTypeId;
      }

      if (fields) {
        localVarQueryParameter['fields'] = fields;
      }

      if (active !== undefined) {
        localVarQueryParameter['active'] = active;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns a list of championships based on assigned championship types.
     * @summary Get championships list based on assigned championship types.
     * @param {string} [query]
     * @param {number} [year]
     * @param {WorkflowTypeEnum} [champType]
     * @param {number} [champTypeId]
     * @param {boolean} [active] indicates if champ is active
     * @param {number} [start]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminOfficialChampionshipsGet: async (
      query?: string,
      year?: number,
      champType?: WorkflowTypeEnum,
      champTypeId?: number,
      active?: boolean,
      start?: number,
      limit?: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/official/championships`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (year !== undefined) {
        localVarQueryParameter['year'] = year;
      }

      if (champType !== undefined) {
        localVarQueryParameter['champType'] = champType;
      }

      if (champTypeId !== undefined) {
        localVarQueryParameter['champTypeId'] = champTypeId;
      }

      if (active !== undefined) {
        localVarQueryParameter['active'] = active;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns a list of championships based on assigned championship types.
     * @summary [GraphQL]Get championships list based on assigned championship types.
     * @param {string} [query]
     * @param {number} [year]
     * @param {WorkflowTypeEnum} [champType]
     * @param {number} [champTypeId]
     * @param {Array<string>} [fields] required fields to fetch, e.g. \&quot;aaa\&quot;, \&quot;bbb.ccc.ddd\&quot;
     * @param {boolean} [active] indicates if champ is active
     * @param {number} [start]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminOfficialChampionshipsGraphqlGet: async (
      query?: string,
      year?: number,
      champType?: WorkflowTypeEnum,
      champTypeId?: number,
      fields?: Array<string>,
      active?: boolean,
      start?: number,
      limit?: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/official/championships/graphql`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (year !== undefined) {
        localVarQueryParameter['year'] = year;
      }

      if (champType !== undefined) {
        localVarQueryParameter['champType'] = champType;
      }

      if (champTypeId !== undefined) {
        localVarQueryParameter['champTypeId'] = champTypeId;
      }

      if (fields) {
        localVarQueryParameter['fields'] = fields;
      }

      if (active !== undefined) {
        localVarQueryParameter['active'] = active;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns list of clubs
     * @summary Get list of clubs
     * @param {number} [limit]
     * @param {number} [start]
     * @param {string} [query] Expression to filter entries (e.g. %word%)
     * @param {number} [champId]
     * @param {number} [year]
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {string} [countryFilter]
     * @param {string} [stateFilter]
     * @param {string} [cityFilter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminOfficialClubGet: async (
      limit?: number,
      start?: number,
      query?: string,
      champId?: number,
      year?: number,
      sortingFilter?: SortingFilterEnum,
      countryFilter?: string,
      stateFilter?: string,
      cityFilter?: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/official/club`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start;
      }

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (champId !== undefined) {
        localVarQueryParameter['champId'] = champId;
      }

      if (year !== undefined) {
        localVarQueryParameter['year'] = year;
      }

      if (sortingFilter !== undefined) {
        localVarQueryParameter['sortingFilter'] = sortingFilter;
      }

      if (countryFilter !== undefined) {
        localVarQueryParameter['countryFilter'] = countryFilter;
      }

      if (stateFilter !== undefined) {
        localVarQueryParameter['stateFilter'] = stateFilter;
      }

      if (cityFilter !== undefined) {
        localVarQueryParameter['cityFilter'] = cityFilter;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Add new club
     * @summary Add new club
     * @param {ClubAdminAddModel} [clubAdminAddModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminOfficialClubPost: async (
      clubAdminAddModel?: ClubAdminAddModel,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/official/club`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof clubAdminAddModel !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(clubAdminAddModel !== undefined ? clubAdminAddModel : {})
        : clubAdminAddModel || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Is used to get club data by its id
     * @summary Get club info by id
     * @param {number} id id of club
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminOfficialClubQueryGet: async (
      id: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling binChampadminOfficialClubQueryGet.'
        );
      }
      const localVarPath = `/bin/champadmin/official/club/query`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (id !== undefined) {
        localVarQueryParameter['id'] = id;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Updates club
     * @summary Update club
     * @param {ClubAdminModel} [clubAdminModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminOfficialClubUpdatePost: async (
      clubAdminModel?: ClubAdminModel,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/official/club/update`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof clubAdminModel !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(clubAdminModel !== undefined ? clubAdminModel : {})
        : clubAdminModel || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns list of points of contact
     * @summary Get list of points of contact
     * @param {number} [limit]
     * @param {number} [start]
     * @param {string} [query] Expression to filter entries (e.g. %word%)
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminOfficialPocGet: async (
      limit?: number,
      start?: number,
      query?: string,
      sortingFilter?: SortingFilterEnum,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/official/poc`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start;
      }

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (sortingFilter !== undefined) {
        localVarQueryParameter['sortingFilter'] = sortingFilter;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Add new point of contact
     * @summary Add new point of contact
     * @param {PointOfContactAdminAddModel} [pointOfContactAdminAddModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminOfficialPocPost: async (
      pointOfContactAdminAddModel?: PointOfContactAdminAddModel,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/official/poc`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof pointOfContactAdminAddModel !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(
            pointOfContactAdminAddModel !== undefined ? pointOfContactAdminAddModel : {}
          )
        : pointOfContactAdminAddModel || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Is used to get poc data by its id
     * @summary Get poc info by id
     * @param {number} id id of poc
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminOfficialPocQueryGet: async (
      id: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling binChampadminOfficialPocQueryGet.'
        );
      }
      const localVarPath = `/bin/champadmin/official/poc/query`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (id !== undefined) {
        localVarQueryParameter['id'] = id;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Updates point of contact
     * @summary Update point of contact
     * @param {PointOfContactAdminEditModel} [pointOfContactAdminEditModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminOfficialPocUpdatePost: async (
      pointOfContactAdminEditModel?: PointOfContactAdminEditModel,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/official/poc/update`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof pointOfContactAdminEditModel !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(
            pointOfContactAdminEditModel !== undefined ? pointOfContactAdminEditModel : {}
          )
        : pointOfContactAdminEditModel || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Qualifying Site Player List
     * @param {number} qualifyingSiteId id of Qualifying Site
     * @param {QualifyingSiteReportListType} listType type of list to get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminOfficialQualifyingSitePlayerListGet: async (
      qualifyingSiteId: number,
      listType: QualifyingSiteReportListType,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'qualifyingSiteId' is not null or undefined
      if (qualifyingSiteId === null || qualifyingSiteId === undefined) {
        throw new RequiredError(
          'qualifyingSiteId',
          'Required parameter qualifyingSiteId was null or undefined when calling binChampadminOfficialQualifyingSitePlayerListGet.'
        );
      }
      // verify required parameter 'listType' is not null or undefined
      if (listType === null || listType === undefined) {
        throw new RequiredError(
          'listType',
          'Required parameter listType was null or undefined when calling binChampadminOfficialQualifyingSitePlayerListGet.'
        );
      }
      const localVarPath = `/bin/champadmin/official/qualifying-site/player/list`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (qualifyingSiteId !== undefined) {
        localVarQueryParameter['qualifyingSiteId'] = qualifyingSiteId;
      }

      if (listType !== undefined) {
        localVarQueryParameter['listType'] = listType;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Checks whether it is possible to create a \'QualifyingSite\' with such data.
     * @summary Check if a combination of ChampId+Level+Number is still free
     * @param {number} champId
     * @param {string} level
     * @param {number} number
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminOfficialQualifyingsiteCheckGet: async (
      champId: number,
      level: string,
      number: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'champId' is not null or undefined
      if (champId === null || champId === undefined) {
        throw new RequiredError(
          'champId',
          'Required parameter champId was null or undefined when calling binChampadminOfficialQualifyingsiteCheckGet.'
        );
      }
      // verify required parameter 'level' is not null or undefined
      if (level === null || level === undefined) {
        throw new RequiredError(
          'level',
          'Required parameter level was null or undefined when calling binChampadminOfficialQualifyingsiteCheckGet.'
        );
      }
      // verify required parameter 'number' is not null or undefined
      if (number === null || number === undefined) {
        throw new RequiredError(
          'number',
          'Required parameter number was null or undefined when calling binChampadminOfficialQualifyingsiteCheckGet.'
        );
      }
      const localVarPath = `/bin/champadmin/official/qualifyingsite/check`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (champId !== undefined) {
        localVarQueryParameter['champId'] = champId;
      }

      if (level !== undefined) {
        localVarQueryParameter['level'] = level;
      }

      if (number !== undefined) {
        localVarQueryParameter['number'] = number;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns list of qualifying sites
     * @summary Get list of qualifying sites by championship Id
     * @param {number} champId
     * @param {number} [limit]
     * @param {number} [start]
     * @param {QualifyingSiteFilters} [filter]
     * @param {string} [filterValue]
     * @param {string} [startDate] Optional start date range
     * @param {string} [endDate] Optional end date range
     * @param {string} [query]
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminOfficialQualifyingsiteGet: async (
      champId: number,
      limit?: number,
      start?: number,
      filter?: QualifyingSiteFilters,
      filterValue?: string,
      startDate?: string,
      endDate?: string,
      query?: string,
      sortingFilter?: SortingFilterEnum,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'champId' is not null or undefined
      if (champId === null || champId === undefined) {
        throw new RequiredError(
          'champId',
          'Required parameter champId was null or undefined when calling binChampadminOfficialQualifyingsiteGet.'
        );
      }
      const localVarPath = `/bin/champadmin/official/qualifyingsite`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start;
      }

      if (champId !== undefined) {
        localVarQueryParameter['champId'] = champId;
      }

      if (filter !== undefined) {
        localVarQueryParameter['filter'] = filter;
      }

      if (filterValue !== undefined) {
        localVarQueryParameter['filterValue'] = filterValue;
      }

      if (startDate !== undefined) {
        localVarQueryParameter['startDate'] =
          (startDate as any) instanceof Date
            ? (startDate as any).toISOString().substr(0, 10)
            : startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['endDate'] =
          (endDate as any) instanceof Date ? (endDate as any).toISOString().substr(0, 10) : endDate;
      }

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (sortingFilter !== undefined) {
        localVarQueryParameter['sortingFilter'] = sortingFilter;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Add new qualifying site to championship
     * @summary Add new qualifying site to championship
     * @param {QualifyingSiteAddModel} [qualifyingSiteAddModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminOfficialQualifyingsitePost: async (
      qualifyingSiteAddModel?: QualifyingSiteAddModel,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/official/qualifyingsite`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof qualifyingSiteAddModel !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(qualifyingSiteAddModel !== undefined ? qualifyingSiteAddModel : {})
        : qualifyingSiteAddModel || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Is used to get qualifying site data by its id
     * @summary Get qualifying site info by id
     * @param {number} id id of qualifying site
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminOfficialQualifyingsiteQueryGet: async (
      id: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling binChampadminOfficialQualifyingsiteQueryGet.'
        );
      }
      const localVarPath = `/bin/champadmin/official/qualifyingsite/query`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (id !== undefined) {
        localVarQueryParameter['id'] = id;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Updates QS
     * @summary Update qualifying site
     * @param {QualifyingSiteEditModel} [qualifyingSiteEditModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminOfficialQualifyingsiteUpdatePost: async (
      qualifyingSiteEditModel?: QualifyingSiteEditModel,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/official/qualifyingsite/update`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof qualifyingSiteEditModel !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(qualifyingSiteEditModel !== undefined ? qualifyingSiteEditModel : {})
        : qualifyingSiteEditModel || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Download report by access key
     * @summary Download report by access key
     * @param {string} id
     * @param {boolean} [inline] Optional flag, set to true in order to get inlined response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminOfficialReportsDownloadGet: async (
      id: string,
      inline?: boolean,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling binChampadminOfficialReportsDownloadGet.'
        );
      }
      const localVarPath = `/bin/champadmin/official/reports/download`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (id !== undefined) {
        localVarQueryParameter['id'] = id;
      }

      if (inline !== undefined) {
        localVarQueryParameter['inline'] = inline;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Is used to get saved user\'s data of specific championship
     * @summary Get player application
     * @param {number} champId Id of championship to return application data for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminPlayerApplicationGet: async (
      champId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'champId' is not null or undefined
      if (champId === null || champId === undefined) {
        throw new RequiredError(
          'champId',
          'Required parameter champId was null or undefined when calling binChampadminPlayerApplicationGet.'
        );
      }
      const localVarPath = `/bin/champadmin/player/application`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (champId !== undefined) {
        localVarQueryParameter['champId'] = champId;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Saves or updates player application
     * @summary Save player application
     * @param {number} [appId] id of another player to form team with
     * @param {ApplicationPlayerModel} [applicationPlayerModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminPlayerApplicationPost: async (
      appId?: number,
      applicationPlayerModel?: ApplicationPlayerModel,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/player/application`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (appId !== undefined) {
        localVarQueryParameter['appId'] = appId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof applicationPlayerModel !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(applicationPlayerModel !== undefined ? applicationPlayerModel : {})
        : applicationPlayerModel || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get application data by id
     * @param {number} appId id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminPlayerApplicationQueryGet: async (
      appId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'appId' is not null or undefined
      if (appId === null || appId === undefined) {
        throw new RequiredError(
          'appId',
          'Required parameter appId was null or undefined when calling binChampadminPlayerApplicationQueryGet.'
        );
      }
      const localVarPath = `/bin/champadmin/player/application/query`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (appId !== undefined) {
        localVarQueryParameter['appId'] = appId;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Is used to get simplified application data
     * @summary Get basic player application data (for four-ball)
     * @param {number} appId id of application to return application data for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminPlayerApplicationSimplifiedGet: async (
      appId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'appId' is not null or undefined
      if (appId === null || appId === undefined) {
        throw new RequiredError(
          'appId',
          'Required parameter appId was null or undefined when calling binChampadminPlayerApplicationSimplifiedGet.'
        );
      }
      const localVarPath = `/bin/champadmin/player/application/simplified`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (appId !== undefined) {
        localVarQueryParameter['appId'] = appId;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Upload Application Files
     * @param {number} champId Id of championship we uploading data for
     * @param {UploadedFileType} fileType
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminPlayerApplicationUploadPost: async (
      champId: number,
      fileType: UploadedFileType,
      file?: any,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'champId' is not null or undefined
      if (champId === null || champId === undefined) {
        throw new RequiredError(
          'champId',
          'Required parameter champId was null or undefined when calling binChampadminPlayerApplicationUploadPost.'
        );
      }
      // verify required parameter 'fileType' is not null or undefined
      if (fileType === null || fileType === undefined) {
        throw new RequiredError(
          'fileType',
          'Required parameter fileType was null or undefined when calling binChampadminPlayerApplicationUploadPost.'
        );
      }
      const localVarPath = `/bin/champadmin/player/application/upload`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new FormData();

      if (champId !== undefined) {
        localVarQueryParameter['champId'] = champId;
      }

      if (fileType !== undefined) {
        localVarQueryParameter['fileType'] = fileType;
      }

      if (file !== undefined) {
        localVarFormParams.append('file', file as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Is used to get a list of applied applications.
     * @summary Returns list of apps player has applied to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminPlayerApplicationWithdrawGet: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/player/application/withdraw`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Submit withdrawal request
     * @summary Submit withdrawal request
     * @param {ApplicationWithdrawRequestModel} [applicationWithdrawRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminPlayerApplicationWithdrawPost: async (
      applicationWithdrawRequestModel?: ApplicationWithdrawRequestModel,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/player/application/withdraw`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof applicationWithdrawRequestModel !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(
            applicationWithdrawRequestModel !== undefined ? applicationWithdrawRequestModel : {}
          )
        : applicationWithdrawRequestModel || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns list of applications for player
     * @summary Get filtered list of applications for player history
     * @param {WorkflowTypeEnum} [workflowType] Optional filter by championship type
     * @param {number} [year] Championship year
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {number} [champTypeId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminPlayerApplicationsHistoryGet: async (
      workflowType?: WorkflowTypeEnum,
      year?: number,
      sortingFilter?: SortingFilterEnum,
      champTypeId?: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/player/applications/history`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (workflowType !== undefined) {
        localVarQueryParameter['workflowType'] = workflowType;
      }

      if (year !== undefined) {
        localVarQueryParameter['year'] = year;
      }

      if (sortingFilter !== undefined) {
        localVarQueryParameter['sortingFilter'] = sortingFilter;
      }

      if (champTypeId !== undefined) {
        localVarQueryParameter['champTypeId'] = champTypeId;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns list of ids for championships with existing applications in status not DRAFT
     * @summary Get list of paid championships
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminPlayerChampionshipsGet: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/player/championships`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get status list of requested subscriptions
     * @summary Get status list of requested subscriptions
     * @param {Array<EmailSubscriptionType>} [types]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminPlayerEmailSubscriptionsGet: async (
      types?: Array<EmailSubscriptionType>,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/player/email/subscriptions`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (types) {
        localVarQueryParameter['types'] = types;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update subscriptions
     * @summary Update subscriptions
     * @param {EmailSubscriptionsRequestModel} [emailSubscriptionsRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminPlayerEmailSubscriptionsPost: async (
      emailSubscriptionsRequestModel?: EmailSubscriptionsRequestModel,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/player/email/subscriptions`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof emailSubscriptionsRequestModel !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(
            emailSubscriptionsRequestModel !== undefined ? emailSubscriptionsRequestModel : {}
          )
        : emailSubscriptionsRequestModel || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Is used to check availability of the handicap service.
     * @summary Check handicap service.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminPlayerHandicapCheckGet: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/player/handicap_check`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get handicap player by handicap id
     * @param {string} handicapId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminPlayerHandicapGet: async (
      handicapId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'handicapId' is not null or undefined
      if (handicapId === null || handicapId === undefined) {
        throw new RequiredError(
          'handicapId',
          'Required parameter handicapId was null or undefined when calling binChampadminPlayerHandicapGet.'
        );
      }
      const localVarPath = `/bin/champadmin/player/handicap`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (handicapId !== undefined) {
        localVarQueryParameter['handicapId'] = handicapId;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns list of messages
     * @summary Get list of messages by current user Id
     * @param {number} [limit]
     * @param {number} [start]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminPlayerMessageGet: async (
      limit?: number,
      start?: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/player/message`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Hide message for player
     * @summary Hide specific message for particular player
     * @param {MessageHideRequestModel} [messageHideRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminPlayerMessageHidePost: async (
      messageHideRequestModel?: MessageHideRequestModel,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/player/message/hide`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof messageHideRequestModel !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(messageHideRequestModel !== undefined ? messageHideRequestModel : {})
        : messageHideRequestModel || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get form data to make post request to Payment processor
     * @param {number} appId Application id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminPlayerPaymentGet: async (
      appId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'appId' is not null or undefined
      if (appId === null || appId === undefined) {
        throw new RequiredError(
          'appId',
          'Required parameter appId was null or undefined when calling binChampadminPlayerPaymentGet.'
        );
      }
      const localVarPath = `/bin/champadmin/player/payment`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (appId !== undefined) {
        localVarQueryParameter['appId'] = appId;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Is used to get profile data
     * @summary Get player profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminPlayerProfileGet: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/player/profile`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Accept request to manage profile
     * @summary Accept request to manage profile
     * @param {ProfileLinkRequestModel} [profileLinkRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminPlayerProfileLinkAcceptPost: async (
      profileLinkRequestModel?: ProfileLinkRequestModel,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/player/profile/link/accept`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof profileLinkRequestModel !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(profileLinkRequestModel !== undefined ? profileLinkRequestModel : {})
        : profileLinkRequestModel || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Send request to start impersonation session
     * @summary Start impersonation session
     * @param {ProfileLinkRequestModel} [profileLinkRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminPlayerProfileLinkImpersonationPost: async (
      profileLinkRequestModel?: ProfileLinkRequestModel,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/player/profile/link/impersonation`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof profileLinkRequestModel !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(profileLinkRequestModel !== undefined ? profileLinkRequestModel : {})
        : profileLinkRequestModel || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Send request to stop impersonation session
     * @summary Stop impersonation session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminPlayerProfileLinkImpersonationStopPost: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/player/profile/link/impersonation/stop`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Impersonation check
     * @summary Return impersonator profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminPlayerProfileLinkImpersonatorGet: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/player/profile/link/impersonator`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update player profile
     * @summary Update managed profile
     * @param {ProfilePlayerResponseModel} [profilePlayerResponseModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminPlayerProfileLinkManagePost: async (
      profilePlayerResponseModel?: ProfilePlayerResponseModel,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/player/profile/link/manage`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof profilePlayerResponseModel !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(profilePlayerResponseModel !== undefined ? profilePlayerResponseModel : {})
        : profilePlayerResponseModel || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Send request to add managed profile
     * @summary Send request to add managed profile
     * @param {EmailModel} [emailModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminPlayerProfileLinkRequestPost: async (
      emailModel?: EmailModel,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/player/profile/link/request`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof emailModel !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(emailModel !== undefined ? emailModel : {})
        : emailModel || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Updates player profile
     * @summary Save/update player profile
     * @param {ProfilePlayerRequestModel} [profilePlayerRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminPlayerProfilePost: async (
      profilePlayerRequestModel?: ProfilePlayerRequestModel,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/player/profile`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof profilePlayerRequestModel !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(profilePlayerRequestModel !== undefined ? profilePlayerRequestModel : {})
        : profilePlayerRequestModel || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Accept request to manage profile
     * @summary Unlink player profile
     * @param {ProfileUnlinkRequestModel} [profileUnlinkRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminPlayerProfileUnlinkPost: async (
      profileUnlinkRequestModel?: ProfileUnlinkRequestModel,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/player/profile/unlink`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof profileUnlinkRequestModel !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(profileUnlinkRequestModel !== undefined ? profileUnlinkRequestModel : {})
        : profileUnlinkRequestModel || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Is used to get qualifying site data by its id
     * @summary Get qualifying site info by id
     * @param {number} id id of qualifying site
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminPlayerQualifyingsiteQueryGet: async (
      id: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling binChampadminPlayerQualifyingsiteQueryGet.'
        );
      }
      const localVarPath = `/bin/champadmin/player/qualifyingsite/query`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (id !== undefined) {
        localVarQueryParameter['id'] = id;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Is used to Release all Reserved spot data
     * @summary Release All Reserves a spot
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminPlayerReleaseAllReserveSpotGet: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/player/release/all/reserve_spot`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Is used to Release Reserved spot data by its id
     * @summary Release Reserves a spot info by id
     * @param {number} id id of reserved slot
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminPlayerReleaseReserveSpotGet: async (
      id: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling binChampadminPlayerReleaseReserveSpotGet.'
        );
      }
      const localVarPath = `/bin/champadmin/player/release/reserve_spot`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (id !== undefined) {
        localVarQueryParameter['id'] = id;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Edit a reserved spot
     * @param {ReserveSpotEditModel} [reserveSpotEditModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminPlayerReserveSpotEditPost: async (
      reserveSpotEditModel?: ReserveSpotEditModel,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/player/reserve_spot/edit`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof reserveSpotEditModel !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(reserveSpotEditModel !== undefined ? reserveSpotEditModel : {})
        : reserveSpotEditModel || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Reserves a spot
     * @param {ReserveSpotCreateModel} [reserveSpotCreateModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminPlayerReserveSpotPost: async (
      reserveSpotCreateModel?: ReserveSpotCreateModel,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/player/reserve_spot`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof reserveSpotCreateModel !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(reserveSpotCreateModel !== undefined ? reserveSpotCreateModel : {})
        : reserveSpotCreateModel || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get list of upcoming events
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminPlayerUpcomingEventsGet: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/player/upcoming/events`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary AR Request for player
     * @param {ARPlayerRequestModel} [aRPlayerRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminSecureArequestPlayerPost: async (
      aRPlayerRequestModel?: ARPlayerRequestModel,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/secure/arequest/player`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof aRPlayerRequestModel !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(aRPlayerRequestModel !== undefined ? aRPlayerRequestModel : {})
        : aRPlayerRequestModel || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Download list of applications
     * @summary Download filtered list of applications
     * @param {number} year Championship year
     * @param {ApplicationFilters} filter
     * @param {number} [champId] Optional filter by championship id
     * @param {number} [limit]
     * @param {number} [start]
     * @param {string} [query] Optional full text search query
     * @param {string} [filterValue]
     * @param {IdListModel} [idListModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminStaffApplicationsDownloadPost: async (
      year: number,
      filter: ApplicationFilters,
      champId?: number,
      limit?: number,
      start?: number,
      query?: string,
      filterValue?: string,
      idListModel?: IdListModel,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'year' is not null or undefined
      if (year === null || year === undefined) {
        throw new RequiredError(
          'year',
          'Required parameter year was null or undefined when calling binChampadminStaffApplicationsDownloadPost.'
        );
      }
      // verify required parameter 'filter' is not null or undefined
      if (filter === null || filter === undefined) {
        throw new RequiredError(
          'filter',
          'Required parameter filter was null or undefined when calling binChampadminStaffApplicationsDownloadPost.'
        );
      }
      const localVarPath = `/bin/champadmin/staff/applications/download`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (champId !== undefined) {
        localVarQueryParameter['champId'] = champId;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start;
      }

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (year !== undefined) {
        localVarQueryParameter['year'] = year;
      }

      if (filter !== undefined) {
        localVarQueryParameter['filter'] = filter;
      }

      if (filterValue !== undefined) {
        localVarQueryParameter['filterValue'] = filterValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof idListModel !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(idListModel !== undefined ? idListModel : {})
        : idListModel || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns list of applications
     * @summary Get filtered list of applications
     * @param {number} year Championship year
     * @param {ApplicationFilters} filter
     * @param {number} [champId] Optional filter by championship id
     * @param {number} [limit]
     * @param {number} [start]
     * @param {string} [query] Optional full text search query
     * @param {string} [disabilityTypeFilter]
     * @param {string} [filterValue]
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminStaffApplicationsGet: async (
      year: number,
      filter: ApplicationFilters,
      champId?: number,
      limit?: number,
      start?: number,
      query?: string,
      disabilityTypeFilter?: string,
      filterValue?: string,
      sortingFilter?: SortingFilterEnum,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'year' is not null or undefined
      if (year === null || year === undefined) {
        throw new RequiredError(
          'year',
          'Required parameter year was null or undefined when calling binChampadminStaffApplicationsGet.'
        );
      }
      // verify required parameter 'filter' is not null or undefined
      if (filter === null || filter === undefined) {
        throw new RequiredError(
          'filter',
          'Required parameter filter was null or undefined when calling binChampadminStaffApplicationsGet.'
        );
      }
      const localVarPath = `/bin/champadmin/staff/applications`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (champId !== undefined) {
        localVarQueryParameter['champId'] = champId;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start;
      }

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (year !== undefined) {
        localVarQueryParameter['year'] = year;
      }

      if (filter !== undefined) {
        localVarQueryParameter['filter'] = filter;
      }

      if (disabilityTypeFilter !== undefined) {
        localVarQueryParameter['disabilityTypeFilter'] = disabilityTypeFilter;
      }

      if (filterValue !== undefined) {
        localVarQueryParameter['filterValue'] = filterValue;
      }

      if (sortingFilter !== undefined) {
        localVarQueryParameter['sortingFilter'] = sortingFilter;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns list of Champiobship Types
     * @summary Get list of Champ-types
     * @param {number} [limit]
     * @param {number} [start]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminStaffChampTypeListGet: async (
      limit?: number,
      start?: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/staff/champ-type-list`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Downloading reports
     * @summary Downloading ZIP file with championship reports
     * @param {number} champId
     * @param {StaffChampionshipReportsTypeEnum} reportType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminStaffChampionshipReportDownloadGet: async (
      champId: number,
      reportType: StaffChampionshipReportsTypeEnum,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'champId' is not null or undefined
      if (champId === null || champId === undefined) {
        throw new RequiredError(
          'champId',
          'Required parameter champId was null or undefined when calling binChampadminStaffChampionshipReportDownloadGet.'
        );
      }
      // verify required parameter 'reportType' is not null or undefined
      if (reportType === null || reportType === undefined) {
        throw new RequiredError(
          'reportType',
          'Required parameter reportType was null or undefined when calling binChampadminStaffChampionshipReportDownloadGet.'
        );
      }
      const localVarPath = `/bin/champadmin/staff/championship/report/download`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (champId !== undefined) {
        localVarQueryParameter['champId'] = champId;
      }

      if (reportType !== undefined) {
        localVarQueryParameter['reportType'] = reportType;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns a list of championships based on assigned championship types.
     * @summary Get championships list based on assigned championship types.
     * @param {string} [query]
     * @param {number} [year]
     * @param {WorkflowTypeEnum} [champType]
     * @param {number} [champTypeId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminStaffChampionshipsGet: async (
      query?: string,
      year?: number,
      champType?: WorkflowTypeEnum,
      champTypeId?: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/staff/championships`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (year !== undefined) {
        localVarQueryParameter['year'] = year;
      }

      if (champType !== undefined) {
        localVarQueryParameter['champType'] = champType;
      }

      if (champTypeId !== undefined) {
        localVarQueryParameter['champTypeId'] = champTypeId;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns a list of championships based on assigned championship types.
     * @summary [GraphQL] Get championships list based on assigned championship types.
     * @param {string} [query]
     * @param {number} [year]
     * @param {WorkflowTypeEnum} [champType]
     * @param {number} [champTypeId]
     * @param {Array<string>} [fields] required fields to fetch, e.g. \&quot;aaa\&quot;, \&quot;bbb.ccc.ddd\&quot;
     * @param {boolean} [active] indicates if champ is active
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminStaffChampionshipsGraphqlGet: async (
      query?: string,
      year?: number,
      champType?: WorkflowTypeEnum,
      champTypeId?: number,
      fields?: Array<string>,
      active?: boolean,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/staff/championships/graphql`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (year !== undefined) {
        localVarQueryParameter['year'] = year;
      }

      if (champType !== undefined) {
        localVarQueryParameter['champType'] = champType;
      }

      if (champTypeId !== undefined) {
        localVarQueryParameter['champTypeId'] = champTypeId;
      }

      if (fields) {
        localVarQueryParameter['fields'] = fields;
      }

      if (active !== undefined) {
        localVarQueryParameter['active'] = active;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Is used to get championship data by its id
     * @summary Get championship info by id
     * @param {number} id id of championship
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminStaffChampionshipsQueryGet: async (
      id: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling binChampadminStaffChampionshipsQueryGet.'
        );
      }
      const localVarPath = `/bin/champadmin/staff/championships/query`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (id !== undefined) {
        localVarQueryParameter['id'] = id;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns list of exemptions
     * @summary Get list of exemptions by championship Id
     * @param {number} champId
     * @param {number} [limit]
     * @param {number} [start]
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {QualifyingLevelEnum} [filter] Optional Filter
     * @param {string} [query] Optional search query parameter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminStaffExemptionGet: async (
      champId: number,
      limit?: number,
      start?: number,
      sortingFilter?: SortingFilterEnum,
      filter?: QualifyingLevelEnum,
      query?: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'champId' is not null or undefined
      if (champId === null || champId === undefined) {
        throw new RequiredError(
          'champId',
          'Required parameter champId was null or undefined when calling binChampadminStaffExemptionGet.'
        );
      }
      const localVarPath = `/bin/champadmin/staff/exemption`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (champId !== undefined) {
        localVarQueryParameter['champId'] = champId;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start;
      }

      if (sortingFilter !== undefined) {
        localVarQueryParameter['sortingFilter'] = sortingFilter;
      }

      if (filter !== undefined) {
        localVarQueryParameter['filter'] = filter;
      }

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Download file by id
     * @summary Download file by id
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminStaffFileGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling binChampadminStaffFileGet.'
        );
      }
      const localVarPath = `/bin/champadmin/staff/file`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (id !== undefined) {
        localVarQueryParameter['id'] = id;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns Champiobship Type by id
     * @summary Get Applications Pairs by ChampId
     * @param {number} champId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminStaffPairGet: async (champId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'champId' is not null or undefined
      if (champId === null || champId === undefined) {
        throw new RequiredError(
          'champId',
          'Required parameter champId was null or undefined when calling binChampadminStaffPairGet.'
        );
      }
      const localVarPath = `/bin/champadmin/staff/pair`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (champId !== undefined) {
        localVarQueryParameter['champId'] = champId;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns list of pairs
     * @summary Get filtered list of pairs
     * @param {number} limit
     * @param {number} start
     * @param {number} playerId
     * @param {string} [query] Optional search query
     * @param {PairSortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminStaffPairHistoryGet: async (
      limit: number,
      start: number,
      playerId: number,
      query?: string,
      sortingFilter?: PairSortingFilterEnum,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'limit' is not null or undefined
      if (limit === null || limit === undefined) {
        throw new RequiredError(
          'limit',
          'Required parameter limit was null or undefined when calling binChampadminStaffPairHistoryGet.'
        );
      }
      // verify required parameter 'start' is not null or undefined
      if (start === null || start === undefined) {
        throw new RequiredError(
          'start',
          'Required parameter start was null or undefined when calling binChampadminStaffPairHistoryGet.'
        );
      }
      // verify required parameter 'playerId' is not null or undefined
      if (playerId === null || playerId === undefined) {
        throw new RequiredError(
          'playerId',
          'Required parameter playerId was null or undefined when calling binChampadminStaffPairHistoryGet.'
        );
      }
      const localVarPath = `/bin/champadmin/staff/pair/history`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start;
      }

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (sortingFilter !== undefined) {
        localVarQueryParameter['sortingFilter'] = sortingFilter;
      }

      if (playerId !== undefined) {
        localVarQueryParameter['playerId'] = playerId;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create/Update Applications Pairs
     * @param {PairListRequestModel} [pairListRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminStaffPairPost: async (
      pairListRequestModel?: PairListRequestModel,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/staff/pair`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof pairListRequestModel !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(pairListRequestModel !== undefined ? pairListRequestModel : {})
        : pairListRequestModel || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns list of qualifying sites
     * @summary Get list of qualifying sites by championship Id
     * @param {number} champId
     * @param {number} [limit]
     * @param {number} [start]
     * @param {QualifyingSiteFilters} [filter]
     * @param {string} [filterValue]
     * @param {string} [startDate] Optional start date range
     * @param {string} [endDate] Optional end date range
     * @param {string} [query]
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminStaffQualifyingsiteGet: async (
      champId: number,
      limit?: number,
      start?: number,
      filter?: QualifyingSiteFilters,
      filterValue?: string,
      startDate?: string,
      endDate?: string,
      query?: string,
      sortingFilter?: SortingFilterEnum,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'champId' is not null or undefined
      if (champId === null || champId === undefined) {
        throw new RequiredError(
          'champId',
          'Required parameter champId was null or undefined when calling binChampadminStaffQualifyingsiteGet.'
        );
      }
      const localVarPath = `/bin/champadmin/staff/qualifyingsite`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start;
      }

      if (champId !== undefined) {
        localVarQueryParameter['champId'] = champId;
      }

      if (filter !== undefined) {
        localVarQueryParameter['filter'] = filter;
      }

      if (filterValue !== undefined) {
        localVarQueryParameter['filterValue'] = filterValue;
      }

      if (startDate !== undefined) {
        localVarQueryParameter['startDate'] =
          (startDate as any) instanceof Date
            ? (startDate as any).toISOString().substr(0, 10)
            : startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['endDate'] =
          (endDate as any) instanceof Date ? (endDate as any).toISOString().substr(0, 10) : endDate;
      }

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (sortingFilter !== undefined) {
        localVarQueryParameter['sortingFilter'] = sortingFilter;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Download customer service report
     * @summary Red Book report
     * @param {number} champId Championship ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminStaffRedbookReportGet: async (
      champId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'champId' is not null or undefined
      if (champId === null || champId === undefined) {
        throw new RequiredError(
          'champId',
          'Required parameter champId was null or undefined when calling binChampadminStaffRedbookReportGet.'
        );
      }
      const localVarPath = `/bin/champadmin/staff/redbook/report`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (champId !== undefined) {
        localVarQueryParameter['champId'] = champId;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Download report by access key
     * @summary Download report by access key
     * @param {string} id
     * @param {boolean} [inline] Optional flag, set to true in order to get inlined response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminStaffReportsDownloadGet: async (
      id: string,
      inline?: boolean,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling binChampadminStaffReportsDownloadGet.'
        );
      }
      const localVarPath = `/bin/champadmin/staff/reports/download`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (id !== undefined) {
        localVarQueryParameter['id'] = id;
      }

      if (inline !== undefined) {
        localVarQueryParameter['inline'] = inline;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns Back To Official Home Page Button Component
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    champAdminComponentsContentOfficialsBackToHomePageButtonModelJsonGet: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/champAdmin/components/content/officials/back-to-home-page-button.model.json`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Dummy
     * @summary Dummy
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    champAdminComponentsDummyFetchModelsGet: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/champAdmin/components/dummy/fetch_models`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Dummy
     * @summary Dummy
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    champAdminComponentsDummyLinkerGet: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/champAdmin/components/dummy/linker`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Dummy
     * @summary Dummy
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    champAdminComponentsDummyValidateLinkerGet: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/champAdmin/components/dummy/validate_linker`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Dummy
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    champAdminServiceWaitlistResponeDummyLinkerGet: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/champAdmin/service/waitlist/respone/dummy/linker`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get list of available options for Resend Email dropdown
     * @summary Get list of available options for Resend Email dropdown
     * @param {number} appId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBinChampadminAdminEmailResend: async (
      appId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'appId' is not null or undefined
      if (appId === null || appId === undefined) {
        throw new RequiredError(
          'appId',
          'Required parameter appId was null or undefined when calling getBinChampadminAdminEmailResend.'
        );
      }
      const localVarPath = `/bin/champadmin/admin/email/resend`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (appId !== undefined) {
        localVarQueryParameter['appId'] = appId;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * get-bin-champadmin-admin-lastmod
     * @summary Get last modified info
     * @param {EntitiesTypeEnum} entityName
     * @param {Array<number>} idList
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBinChampadminAdminLastmod: async (
      entityName: EntitiesTypeEnum,
      idList: Array<number>,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'entityName' is not null or undefined
      if (entityName === null || entityName === undefined) {
        throw new RequiredError(
          'entityName',
          'Required parameter entityName was null or undefined when calling getBinChampadminAdminLastmod.'
        );
      }
      // verify required parameter 'idList' is not null or undefined
      if (idList === null || idList === undefined) {
        throw new RequiredError(
          'idList',
          'Required parameter idList was null or undefined when calling getBinChampadminAdminLastmod.'
        );
      }
      const localVarPath = `/bin/champadmin/admin/last_modified`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (entityName !== undefined) {
        localVarQueryParameter['entityName'] = entityName;
      }

      if (idList) {
        localVarQueryParameter['idList'] = idList;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Upload Championship PDF Files
     * @param {number} champId Id of championship we uploading data for
     * @param {UploadedPdfFileType} fileType
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postBinChampadminAdminChampionshipPdfUpload: async (
      champId: number,
      fileType: UploadedPdfFileType,
      file?: any,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'champId' is not null or undefined
      if (champId === null || champId === undefined) {
        throw new RequiredError(
          'champId',
          'Required parameter champId was null or undefined when calling postBinChampadminAdminChampionshipPdfUpload.'
        );
      }
      // verify required parameter 'fileType' is not null or undefined
      if (fileType === null || fileType === undefined) {
        throw new RequiredError(
          'fileType',
          'Required parameter fileType was null or undefined when calling postBinChampadminAdminChampionshipPdfUpload.'
        );
      }
      const localVarPath = `/bin/champadmin/admin/championship/pdf/upload`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new FormData();

      if (champId !== undefined) {
        localVarQueryParameter['champId'] = champId;
      }

      if (fileType !== undefined) {
        localVarQueryParameter['fileType'] = fileType;
      }

      if (file !== undefined) {
        localVarFormParams.append('file', file as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Upload Championship Results
     * @param {number} champId Id of championship we uploading data for
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postBinChampadminAdminChampionshipScoringUpload: async (
      champId: number,
      file?: any,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'champId' is not null or undefined
      if (champId === null || champId === undefined) {
        throw new RequiredError(
          'champId',
          'Required parameter champId was null or undefined when calling postBinChampadminAdminChampionshipScoringUpload.'
        );
      }
      const localVarPath = `/bin/champadmin/admin/championship/scoring/upload`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new FormData();

      if (champId !== undefined) {
        localVarQueryParameter['champId'] = champId;
      }

      if (file !== undefined) {
        localVarFormParams.append('file', file as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Upload Championship Violation data
     * @param {number} champId Id of championship we uploading data for
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postBinChampadminAdminChampionshipViolationUpload: async (
      champId: number,
      file?: any,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'champId' is not null or undefined
      if (champId === null || champId === undefined) {
        throw new RequiredError(
          'champId',
          'Required parameter champId was null or undefined when calling postBinChampadminAdminChampionshipViolationUpload.'
        );
      }
      const localVarPath = `/bin/champadmin/admin/championship/blacklist/upload`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new FormData();

      if (champId !== undefined) {
        localVarQueryParameter['champId'] = champId;
      }

      if (file !== undefined) {
        localVarFormParams.append('file', file as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Upload Final Qualifying Results (.xls files)
     * @param {number} champId Id of championship we uploading data for
     * @param {number} [qsId] Id of qualifying site we uploading data for
     * @param {boolean} [isAgaPage] Optional flag, identify when request from AGA page
     * @param {string} [scoringUrl] Optional field(required on AGA page), for QS scoringUrl
     * @param {string} [courseRating] Optional field(required on AGA page), for QS courseRating
     * @param {string} [expertCertifiedRulesName] Optional field, for QS expertCertifiedRulesName, used on AGA page
     * @param {boolean} [expertCertifiedRules] Optional field, for QS expertCertifiedRules, used on AGA page
     * @param {boolean} [strokePolicyViolations] Optional field, for QS strokePolicyViolations, used on AGA page
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postBinChampadminOfficialQualifyingFinalScoringUpload: async (
      champId: number,
      qsId?: number,
      isAgaPage?: boolean,
      scoringUrl?: string,
      courseRating?: string,
      expertCertifiedRulesName?: string,
      expertCertifiedRules?: boolean,
      strokePolicyViolations?: boolean,
      file?: any,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'champId' is not null or undefined
      if (champId === null || champId === undefined) {
        throw new RequiredError(
          'champId',
          'Required parameter champId was null or undefined when calling postBinChampadminOfficialQualifyingFinalScoringUpload.'
        );
      }
      const localVarPath = `/bin/champadmin/official/qualifying/final/scoring/upload`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new FormData();

      if (champId !== undefined) {
        localVarQueryParameter['champId'] = champId;
      }

      if (qsId !== undefined) {
        localVarQueryParameter['qsId'] = qsId;
      }

      if (isAgaPage !== undefined) {
        localVarQueryParameter['isAgaPage'] = isAgaPage;
      }

      if (scoringUrl !== undefined) {
        localVarQueryParameter['scoringUrl'] = scoringUrl;
      }

      if (courseRating !== undefined) {
        localVarQueryParameter['courseRating'] = courseRating;
      }

      if (expertCertifiedRulesName !== undefined) {
        localVarQueryParameter['expertCertifiedRulesName'] = expertCertifiedRulesName;
      }

      if (expertCertifiedRules !== undefined) {
        localVarQueryParameter['expertCertifiedRules'] = expertCertifiedRules;
      }

      if (strokePolicyViolations !== undefined) {
        localVarQueryParameter['strokePolicyViolations'] = strokePolicyViolations;
      }

      if (file !== undefined) {
        localVarFormParams.append('file', file as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Upload Local Qualifying Results (.xls files)
     * @param {number} champId Id of championship we uploading data for
     * @param {number} [qsId] Id of qualifying site we uploading data for
     * @param {boolean} [isAgaPage] Optional flag, identify when request from AGA page
     * @param {string} [scoringUrl] Optional field(required on AGA page), for QS scoringUrl
     * @param {string} [courseRating] Optional field(required on AGA page), for QS courseRating
     * @param {string} [expertCertifiedRulesName] Optional field, for QS expertCertifiedRulesName, used on AGA page
     * @param {boolean} [expertCertifiedRules] Optional field, for QS expertCertifiedRules, used on AGA page
     * @param {boolean} [strokePolicyViolations] Optional field, for QS strokePolicyViolations, used on AGA page
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postBinChampadminOfficialQualifyingLocalScoringUpload: async (
      champId: number,
      qsId?: number,
      isAgaPage?: boolean,
      scoringUrl?: string,
      courseRating?: string,
      expertCertifiedRulesName?: string,
      expertCertifiedRules?: boolean,
      strokePolicyViolations?: boolean,
      file?: any,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'champId' is not null or undefined
      if (champId === null || champId === undefined) {
        throw new RequiredError(
          'champId',
          'Required parameter champId was null or undefined when calling postBinChampadminOfficialQualifyingLocalScoringUpload.'
        );
      }
      const localVarPath = `/bin/champadmin/official/qualifying/local/scoring/upload`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new FormData();

      if (champId !== undefined) {
        localVarQueryParameter['champId'] = champId;
      }

      if (qsId !== undefined) {
        localVarQueryParameter['qsId'] = qsId;
      }

      if (isAgaPage !== undefined) {
        localVarQueryParameter['isAgaPage'] = isAgaPage;
      }

      if (scoringUrl !== undefined) {
        localVarQueryParameter['scoringUrl'] = scoringUrl;
      }

      if (courseRating !== undefined) {
        localVarQueryParameter['courseRating'] = courseRating;
      }

      if (expertCertifiedRulesName !== undefined) {
        localVarQueryParameter['expertCertifiedRulesName'] = expertCertifiedRulesName;
      }

      if (expertCertifiedRules !== undefined) {
        localVarQueryParameter['expertCertifiedRules'] = expertCertifiedRules;
      }

      if (strokePolicyViolations !== undefined) {
        localVarQueryParameter['strokePolicyViolations'] = strokePolicyViolations;
      }

      if (file !== undefined) {
        localVarFormParams.append('file', file as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Upload Qualifying Results (.xls files)
     * @param {number} champId Id of championship we uploading data for
     * @param {number} [qsId] Id of qualifying site we uploading data for
     * @param {boolean} [isAgaPage] Optional flag, identify when request from AGA page
     * @param {string} [scoringUrl] Optional field(required on AGA page), for QS scoringUrl
     * @param {string} [courseRating] Optional field(required on AGA page), for QS courseRating
     * @param {string} [expertCertifiedRulesName] Optional field, for QS expertCertifiedRulesName, used on AGA page
     * @param {boolean} [expertCertifiedRules] Optional field, for QS expertCertifiedRules, used on AGA page
     * @param {boolean} [strokePolicyViolations] Optional field, for QS strokePolicyViolations, used on AGA page
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postBinChampadminOfficialQualifyingScoringUpload: async (
      champId: number,
      qsId?: number,
      isAgaPage?: boolean,
      scoringUrl?: string,
      courseRating?: string,
      expertCertifiedRulesName?: string,
      expertCertifiedRules?: boolean,
      strokePolicyViolations?: boolean,
      file?: any,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'champId' is not null or undefined
      if (champId === null || champId === undefined) {
        throw new RequiredError(
          'champId',
          'Required parameter champId was null or undefined when calling postBinChampadminOfficialQualifyingScoringUpload.'
        );
      }
      const localVarPath = `/bin/champadmin/official/qualifying/scoring/upload`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new FormData();

      if (champId !== undefined) {
        localVarQueryParameter['champId'] = champId;
      }

      if (qsId !== undefined) {
        localVarQueryParameter['qsId'] = qsId;
      }

      if (isAgaPage !== undefined) {
        localVarQueryParameter['isAgaPage'] = isAgaPage;
      }

      if (scoringUrl !== undefined) {
        localVarQueryParameter['scoringUrl'] = scoringUrl;
      }

      if (courseRating !== undefined) {
        localVarQueryParameter['courseRating'] = courseRating;
      }

      if (expertCertifiedRulesName !== undefined) {
        localVarQueryParameter['expertCertifiedRulesName'] = expertCertifiedRulesName;
      }

      if (expertCertifiedRules !== undefined) {
        localVarQueryParameter['expertCertifiedRules'] = expertCertifiedRules;
      }

      if (strokePolicyViolations !== undefined) {
        localVarQueryParameter['strokePolicyViolations'] = strokePolicyViolations;
      }

      if (file !== undefined) {
        localVarFormParams.append('file', file as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Sendgrid send mail EP
     * @param {EmailTemplateWithAttachedFilesModel} [emailTemplateWithAttachedFilesModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiSendgridComV3MailSendPost(
      emailTemplateWithAttachedFilesModel?: EmailTemplateWithAttachedFilesModel,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).apiSendgridComV3MailSendPost(emailTemplateWithAttachedFilesModel, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Delete AGA by id
     * @summary Remove AGA by its id
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminAgaByIdDelete(
      id: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminAgaByIdDelete(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Returns AGA by Id
     * @summary Get AGA by Id
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminAgaByIdGet(
      id: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgaEditModel>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminAgaByIdGet(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Returns list of AGA
     * @summary Get list of AGA
     * @param {number} [limit]
     * @param {number} [start]
     * @param {string} [query] Optional search query to filter out AGA by names.
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminAgaGet(
      limit?: number,
      start?: number,
      query?: string,
      sortingFilter?: SortingFilterEnum,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgaAdminListModel>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminAgaGet(limit, start, query, sortingFilter, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Submit new AGA
     * @summary Save new AGA
     * @param {AgaBaseModel} [agaBaseModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminAgaPost(
      agaBaseModel?: AgaBaseModel,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgaEditModel>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminAgaPost(agaBaseModel, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Updates AGA entry
     * @summary Update AGA
     * @param {AgaEditModel} [agaEditModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminAgaUpdatePost(
      agaEditModel?: AgaEditModel,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgaEditModel>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminAgaUpdatePost(agaEditModel, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Returns list of AGA
     * @summary Get filtered list of AGA Users
     * @param {number} [limit]
     * @param {number} [start]
     * @param {string} [query] Expression to filter entries (e.g. %word%)
     * @param {number} [champId]
     * @param {number} [year]
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminAgaUsersListGet(
      limit?: number,
      start?: number,
      query?: string,
      champId?: number,
      year?: number,
      sortingFilter?: SortingFilterEnum,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgaUserAdminListModel>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminAgaUsersListGet(
        limit,
        start,
        query,
        champId,
        year,
        sortingFilter,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Saves or updates player application
     * @summary Create new player application
     * @param {string} auth0Id Auth0Id of player to create application for
     * @param {ApplicationProgressionEnum} applicationStatus Target Application Status
     * @param {number} [appId] id of another player app to form team with
     * @param {ApplicationPlayerModel} [applicationPlayerModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminApplicationCreatePost(
      auth0Id: string,
      applicationStatus: ApplicationProgressionEnum,
      appId?: number,
      applicationPlayerModel?: ApplicationPlayerModel,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResponseModel>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminApplicationCreatePost(
        auth0Id,
        applicationStatus,
        appId,
        applicationPlayerModel,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Is used to get application data
     * @summary Get player application
     * @param {number} appId id of application to return application data for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminApplicationGet(
      appId: number,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<ApplicationPaymentHistoryAdminResponseModel>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminApplicationGet(appId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Updates player application
     * @summary Save player application
     * @param {ApplicationAdminRequestModel} [applicationAdminRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminApplicationPost(
      applicationAdminRequestModel?: ApplicationAdminRequestModel,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationAdminResponseModel>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminApplicationPost(applicationAdminRequestModel, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get championship\'s applications summary
     * @param {number} champId id of championship to return applications summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminApplicationSummaryGet(
      champId: number,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationAdminSummaryModel>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminApplicationSummaryGet(champId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Returns status that indicates was application updated or not
     * @summary Re-Validate application reinstatement
     * @param {number} id Id to verify by
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminApplicationValidateARGet(
      id: number,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReinstatementValidationModel>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminApplicationValidateARGet(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Returns multi filtered list of applications
     * @summary Get filtered list of applications
     * @param {number} champId Filter by championship id
     * @param {number} [limit]
     * @param {number} [start]
     * @param {string} [query] Optional full text search query
     * @param {Array<BlacklistStatusEnum>} [blacklistStatus]
     * @param {Array<ApplicationProgressionEnum>} [applicationStatus]
     * @param {Array<number>} [qualifyingSiteIds]
     * @param {Array<number>} [exemptionIds]
     * @param {QualifyingStatusEnum} [qualifyingStatus]
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminApplicationsBulkWithdrawGet(
      champId: number,
      limit?: number,
      start?: number,
      query?: string,
      blacklistStatus?: Array<BlacklistStatusEnum>,
      applicationStatus?: Array<ApplicationProgressionEnum>,
      qualifyingSiteIds?: Array<number>,
      exemptionIds?: Array<number>,
      qualifyingStatus?: QualifyingStatusEnum,
      sortingFilter?: SortingFilterEnum,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationAdminListModel>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminApplicationsBulkWithdrawGet(
        champId,
        limit,
        start,
        query,
        blacklistStatus,
        applicationStatus,
        qualifyingSiteIds,
        exemptionIds,
        qualifyingStatus,
        sortingFilter,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Returns list of applications
     * @summary Get filtered list of applications for custom email
     * @param {number} [limit]
     * @param {number} [start]
     * @param {number} [year] Optional Filter by year id
     * @param {number} [champId] Optional Filter by championship id
     * @param {string} [query] Optional First/Last Name search query
     * @param {string} [startDate] Optional start qualifying date range
     * @param {string} [endDate] Optional end qualifying date range
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {PlayerStatusEnum} [playerStatus] Optional Player Status Filter
     * @param {ApplicationProgressionEnum} [applicationStatus] Optional Application Status Filter
     * @param {QualifyingStatusEnum} [qualifyingStatus] Optional Application Status Filter
     * @param {string} [wagrRank] Optional WAGR Rank Filter
     * @param {boolean} [notablePlayer] Optional Notable Player Filter
     * @param {boolean} [adaRequest] Optional ADA Request Filter
     * @param {BlacklistStatusEnum} [blacklist] Optional Blacklist Filter
     * @param {boolean} [watchlist] Optional Watchlist Filter
     * @param {number} [age] Optional Age Filter
     * @param {string} [handicapIndex] Optional Handicap Index Filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminApplicationsCustomEmailGet(
      limit?: number,
      start?: number,
      year?: number,
      champId?: number,
      query?: string,
      startDate?: string,
      endDate?: string,
      sortingFilter?: SortingFilterEnum,
      playerStatus?: PlayerStatusEnum,
      applicationStatus?: ApplicationProgressionEnum,
      qualifyingStatus?: QualifyingStatusEnum,
      wagrRank?: string,
      notablePlayer?: boolean,
      adaRequest?: boolean,
      blacklist?: BlacklistStatusEnum,
      watchlist?: boolean,
      age?: number,
      handicapIndex?: string,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationAdminListModel>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminApplicationsCustomEmailGet(
        limit,
        start,
        year,
        champId,
        query,
        startDate,
        endDate,
        sortingFilter,
        playerStatus,
        applicationStatus,
        qualifyingStatus,
        wagrRank,
        notablePlayer,
        adaRequest,
        blacklist,
        watchlist,
        age,
        handicapIndex,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Returns list of applications
     * @summary Get filtered list of applications for final confirmation
     * @param {number} champId Filter by championship id
     * @param {ApplicationFinalConfirmationBulkFilterEnum} playerFilter Plyer filter
     * @param {number} [limit]
     * @param {number} [start]
     * @param {string} [query] Optional First/Last Name search query
     * @param {string} [startDate] Optional start qualifying date range
     * @param {string} [endDate] Optional end qualifying date range
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminApplicationsFinalConfirmationGet(
      champId: number,
      playerFilter: ApplicationFinalConfirmationBulkFilterEnum,
      limit?: number,
      start?: number,
      query?: string,
      startDate?: string,
      endDate?: string,
      sortingFilter?: SortingFilterEnum,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationAdminListModel>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminApplicationsFinalConfirmationGet(
        champId,
        playerFilter,
        limit,
        start,
        query,
        startDate,
        endDate,
        sortingFilter,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Returns list of applications
     * @summary Get filtered list of applications
     * @param {number} year Championship year
     * @param {ApplicationFilters} filter
     * @param {number} [champId] Optional filter by championship id
     * @param {number} [limit]
     * @param {number} [start]
     * @param {string} [query] Optional full text search query
     * @param {string} [filterValue]
     * @param {string} [disabilityTypeFilter] Disability Type
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminApplicationsGet(
      year: number,
      filter: ApplicationFilters,
      champId?: number,
      limit?: number,
      start?: number,
      query?: string,
      filterValue?: string,
      disabilityTypeFilter?: string,
      sortingFilter?: SortingFilterEnum,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<ApplicationLightweightAdminListModel>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminApplicationsGet(
        year,
        filter,
        champId,
        limit,
        start,
        query,
        filterValue,
        disabilityTypeFilter,
        sortingFilter,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Returns list of applications for player
     * @summary Get filtered list of applications for player history
     * @param {number} playerId filter by player id
     * @param {number} [champId] Optional filter by championship id
     * @param {string} [query] Optional full text search query
     * @param {number} [year] Championship year
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminApplicationsHistoryGet(
      playerId: number,
      champId?: number,
      query?: string,
      year?: number,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationHistoryListModel>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminApplicationsHistoryGet(playerId, champId, query, year, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Returns list of applications
     * @summary Get filtered list of applications for new email
     * @param {number} champId Filter by championship id
     * @param {number} [limit]
     * @param {number} [start]
     * @param {string} [query] Optional First/Last Name search query
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {PlayerStatusEnum} [playerStatus] Optional Player Status Filter
     * @param {ApplicationProgressionEnum} [applicationStatus] Optional Application Status Filter
     * @param {QualifyingStatusEnum} [qualifyingStatus] Optional Application Status Filter
     * @param {string} [wagrRank] Optional WAGR Rank Filter
     * @param {boolean} [notablePlayer] Optional Notable Player Filter
     * @param {boolean} [adaRequest] Optional ADA Request Filter
     * @param {BlacklistStatusEnum} [blacklist] Optional Blacklist Filter
     * @param {boolean} [watchlist] Optional Watchlist Filter
     * @param {number} [age] Optional Age Filter
     * @param {string} [handicapIndex] Optional Handicap Index Filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminApplicationsNewEmailGet(
      champId: number,
      limit?: number,
      start?: number,
      query?: string,
      sortingFilter?: SortingFilterEnum,
      playerStatus?: PlayerStatusEnum,
      applicationStatus?: ApplicationProgressionEnum,
      qualifyingStatus?: QualifyingStatusEnum,
      wagrRank?: string,
      notablePlayer?: boolean,
      adaRequest?: boolean,
      blacklist?: BlacklistStatusEnum,
      watchlist?: boolean,
      age?: number,
      handicapIndex?: string,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationAdminListModel>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminApplicationsNewEmailGet(
        champId,
        limit,
        start,
        query,
        sortingFilter,
        playerStatus,
        applicationStatus,
        qualifyingStatus,
        wagrRank,
        notablePlayer,
        adaRequest,
        blacklist,
        watchlist,
        age,
        handicapIndex,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Returns application
     * @summary Get next filtered application
     * @param {number} year Championship year
     * @param {number} fromId id of current entry to get next from it
     * @param {ApplicationFilters} filter
     * @param {number} [champId] Optional filter by championship id
     * @param {SortingFilterEnum} [sortingFilter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminApplicationsNextGet(
      year: number,
      fromId: number,
      filter: ApplicationFilters,
      champId?: number,
      sortingFilter?: SortingFilterEnum,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationAdminResponseModel>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminApplicationsNextGet(
        year,
        fromId,
        filter,
        champId,
        sortingFilter,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Returns list of applications
     * @summary Get filtered list of applications for qualified and alternate bulk
     * @param {number} champId Filter by championship id
     * @param {number} [limit]
     * @param {number} [start]
     * @param {string} [query] Optional First/Last Name search query
     * @param {string} [startDate] Optional start qualifying date range
     * @param {string} [endDate] Optional end qualifying date range
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {ApplicationFilters} [filter]
     * @param {string} [filterValue]
     * @param {BulkQualifyingStatusEnum} [bulkQualifyingStatus] Optional complex filtering
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminApplicationsQualifiedAlternateBulkGet(
      champId: number,
      limit?: number,
      start?: number,
      query?: string,
      startDate?: string,
      endDate?: string,
      sortingFilter?: SortingFilterEnum,
      filter?: ApplicationFilters,
      filterValue?: string,
      bulkQualifyingStatus?: BulkQualifyingStatusEnum,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationAdminListModel>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminApplicationsQualifiedAlternateBulkGet(
        champId,
        limit,
        start,
        query,
        startDate,
        endDate,
        sortingFilter,
        filter,
        filterValue,
        bulkQualifyingStatus,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * List of applications with their respective waitlist positions.
     * @summary Get a list of applications ids with their respective waitlist positions.
     * @param {Array<number>} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminApplicationsWaitlistPositionGet(
      id: Array<number>,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<ApplicationWaitlistPositionListModel>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminApplicationsWaitlistPositionGet(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Returns list of applications
     * @summary Get filtered list of applications to withdraw incomplete sides
     * @param {number} champId Filter by championship id
     * @param {number} [limit]
     * @param {number} [start]
     * @param {string} [query] Optional First/Last Name search query
     * @param {string} [startDate] Optional start qualifying date range
     * @param {string} [endDate] Optional end qualifying date range
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminApplicationsWithdrawIncompleteBulkGet(
      champId: number,
      limit?: number,
      start?: number,
      query?: string,
      startDate?: string,
      endDate?: string,
      sortingFilter?: SortingFilterEnum,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationAdminListModel>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminApplicationsWithdrawIncompleteBulkGet(
        champId,
        limit,
        start,
        query,
        startDate,
        endDate,
        sortingFilter,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Download info file by champ id
     * @summary Get activity log
     * @param {string} id
     * @param {AuditEntityType} entityType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminAuditGet(
      id: string,
      entityType: AuditEntityType,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ActivityLogAdminModel>>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminAuditGet(id, entityType, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Returns multi filtered list of applications for bulk entry fee balance
     * @summary Get filtered list of applications for bulk entry fee balance
     * @param {number} [champId] Required championship id
     * @param {number} [limit]
     * @param {number} [start]
     * @param {string} [query] Optional full text search query
     * @param {Array<ApplicationFilters>} [filters]
     * @param {Array<number>} [qsIds]
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminBulkEntryFeeBalanceGet(
      champId?: number,
      limit?: number,
      start?: number,
      query?: string,
      filters?: Array<ApplicationFilters>,
      qsIds?: Array<number>,
      sortingFilter?: SortingFilterEnum,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<ApplicationLightweightAdminListModel>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminBulkEntryFeeBalanceGet(
        champId,
        limit,
        start,
        query,
        filters,
        qsIds,
        sortingFilter,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * List applications to pay EntryFeeBalance
     * @summary ids
     * @param {BulkEntryFeeRequestAdminModel} [bulkEntryFeeRequestAdminModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminBulkEntryFeeBalancePaymentPost(
      bulkEntryFeeRequestAdminModel?: BulkEntryFeeRequestAdminModel,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminBulkEntryFeeBalancePaymentPost(bulkEntryFeeRequestAdminModel, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Returns Champiobship Type by id
     * @summary Get Champ-type by id
     * @param {number} id id of championship-type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminChampTypeGet(
      id: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChampionshipTypeModel>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminChampTypeGet(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Returns list of Champiobship Types
     * @summary Get list of Champ-types
     * @param {number} [limit]
     * @param {number} [start]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminChampTypeListGet(
      limit?: number,
      start?: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChampTypeListModel>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminChampTypeListGet(limit, start, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Add championship
     * @summary Add championship
     * @param {ChampionshipAddModel} [championshipAddModel] Add championship
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminChampionshipAddPost(
      championshipAddModel?: ChampionshipAddModel,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChampionshipAddModel>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminChampionshipAddPost(championshipAddModel, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Editing championship
     * @summary Editing championship
     * @param {ChampionshipEditModel} [championshipEditModel] Edit championship
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminChampionshipEditPost(
      championshipEditModel?: ChampionshipEditModel,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChampionshipAdminModel>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminChampionshipEditPost(championshipEditModel, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get Red Book entries
     * @param {number} champId
     * @param {number} limit
     * @param {number} start
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminChampionshipRedbookGet(
      champId: number,
      limit: number,
      start: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RedBookPageModel>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminChampionshipRedbookGet(champId, limit, start, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Reorder Red Book entries
     * @param {Array<RankedQualifyingSiteModel>} [rankedQualifyingSiteModel] A list of assigned ranks for qualifying sites
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminChampionshipRedbookPost(
      rankedQualifyingSiteModel?: Array<RankedQualifyingSiteModel>,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminChampionshipRedbookPost(rankedQualifyingSiteModel, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Returns list of uploaded files
     * @summary Get list of uploaded files
     * @param {number} [limit] Per page
     * @param {number} [start] Starting position
     * @param {string} [champId]
     * @param {string} [applicationId]
     * @param {string} [query]
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminChampionshipsFilesGet(
      limit?: number,
      start?: number,
      champId?: string,
      applicationId?: string,
      query?: string,
      sortingFilter?: SortingFilterEnum,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadedFileAdminListModel>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminChampionshipsFilesGet(
        limit,
        start,
        champId,
        applicationId,
        query,
        sortingFilter,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Repositions championships in order their ids were submitted
     * @summary Reposition championships
     * @param {Array<number>} [requestBody] List of championship ids in new order for some specific year
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminChampionshipsPositionPost(
      requestBody?: Array<number>,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminChampionshipsPositionPost(requestBody, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Is used to get championship data by its id
     * @summary Get championship info by id
     * @param {number} id id of championship
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminChampionshipsQueryGet(
      id: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChampionshipAdminModel>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminChampionshipsQueryGet(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Get the list of images in cloudinary using its Search API. Has a rate limit of 5k requests per cloudinary account.
     * @param {number} limit Number of images to  return
     * @param {string} [query] Query passed to cloudinary API, see https://cloudinary.com/documentation/search_api for reference
     * @param {string} [start] Next cursor value to search from
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminCloudinaryListGet(
      limit: number,
      query?: string,
      start?: string,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CloudinaryImagesAdminListModel>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminCloudinaryListGet(limit, query, start, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Delete club by id
     * @summary Remove club by its id
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminClubDelete(
      id: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminClubDelete(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Returns list of clubs
     * @summary Get list of clubs
     * @param {number} [limit]
     * @param {number} [start]
     * @param {string} [query] Expression to filter entries (e.g. %word%)
     * @param {number} [champId]
     * @param {number} [year]
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {string} [countryFilter]
     * @param {string} [stateFilter]
     * @param {string} [cityFilter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminClubGet(
      limit?: number,
      start?: number,
      query?: string,
      champId?: number,
      year?: number,
      sortingFilter?: SortingFilterEnum,
      countryFilter?: string,
      stateFilter?: string,
      cityFilter?: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClubAdminListModel>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminClubGet(
        limit,
        start,
        query,
        champId,
        year,
        sortingFilter,
        countryFilter,
        stateFilter,
        cityFilter,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Add new club
     * @summary Add new club
     * @param {ClubAdminAddModel} [clubAdminAddModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminClubPost(
      clubAdminAddModel?: ClubAdminAddModel,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClubAdminModel>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminClubPost(clubAdminAddModel, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Is used to get club data by its id
     * @summary Get club info by id
     * @param {number} id id of club
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminClubQueryGet(
      id: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClubAdminModel>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminClubQueryGet(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Updates club
     * @summary Update club
     * @param {ClubAdminModel} [clubAdminModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminClubUpdatePost(
      clubAdminModel?: ClubAdminModel,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminClubUpdatePost(clubAdminModel, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * parameters are used only for \'select all\' functionality
     * @summary Bulk email to clubs
     * @param {string} [query] Expression to filter entries (e.g. %word%)
     * @param {number} [champId]
     * @param {number} [year]
     * @param {ClubFilters} [filter]
     * @param {string} [filterValue]
     * @param {CustomBulkEmailRequestModel} [customBulkEmailRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminEmailBulkClubPost(
      query?: string,
      champId?: number,
      year?: number,
      filter?: ClubFilters,
      filterValue?: string,
      customBulkEmailRequestModel?: CustomBulkEmailRequestModel,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminEmailBulkClubPost(
        query,
        champId,
        year,
        filter,
        filterValue,
        customBulkEmailRequestModel,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Custom Bulk Email to Applicants
     * @param {CustomBulkEmailRequestModel} [customBulkEmailRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminEmailBulkCustomPost(
      customBulkEmailRequestModel?: CustomBulkEmailRequestModel,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminEmailBulkCustomPost(customBulkEmailRequestModel, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * parameters are used only for \'select all\' functionality
     * @summary Custom Bulk Email to Applicants
     * @param {Array<number>} [year] Optional Filter by year id
     * @param {Array<number>} [champTypeId] Optional Filter by ChampionshipType Model id
     * @param {string} [query] Optional search query parameter
     * @param {boolean} [twoYearParticipation] Optional Filter to find Two Year Participation
     * @param {boolean} [excludeApplied] Optional Exclude Applied Players Filter
     * @param {boolean} [exempt] Optional Filter to exclude exempt players
     * @param {PlayerStatusEnum} [playerStatus] Optional Player Status Filter
     * @param {string} [wagrRank] Optional WAGR Rank Filter
     * @param {boolean} [adaRequest] Optional ADA Request Filter, true - include, false - exclude
     * @param {boolean} [blacklist] Optional Blacklist Filter, true - include, false - exclude
     * @param {boolean} [watchlist] Optional Watchlist Filter, true - include, false - exclude
     * @param {number} [age] Optional Filter by Player\&#39;s age
     * @param {string} [handicapIndex] Optional Handicap Index Filter
     * @param {Array<number>} [qualifyingSiteId] Optional qualifying site Ids
     * @param {boolean} [applicationAccepted] Optional filter for accepted or q applications
     * @param {Array<number>} [waitlistId] Optional Filter by Waitlist QS id
     * @param {Array<number>} [excludeWaitlistId] Optional Filter exclude by Waitlist QS id
     * @param {boolean} [waitlistAll] Optional Filter to find All waitlisted player by champType &amp; year
     * @param {boolean} [excludeWaitlistAll] Optional Filter to exclude All waitlisted player by champType &amp; year
     * @param {boolean} [excludeBlacklistAll] Optional Filter to exclude All blacklisted player by champType &amp; year
     * @param {Array<BlacklistStatusEnum>} [excludeBlacklistStatus]
     * @param {QualifyingStatusEnum} [qualifyingStatus] Optional Application Qualifying Status Filter
     * @param {CustomBulkEmailProfileRequestModel} [customBulkEmailProfileRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminEmailBulkCustomProfilePost(
      year?: Array<number>,
      champTypeId?: Array<number>,
      query?: string,
      twoYearParticipation?: boolean,
      excludeApplied?: boolean,
      exempt?: boolean,
      playerStatus?: PlayerStatusEnum,
      wagrRank?: string,
      adaRequest?: boolean,
      blacklist?: boolean,
      watchlist?: boolean,
      age?: number,
      handicapIndex?: string,
      qualifyingSiteId?: Array<number>,
      applicationAccepted?: boolean,
      waitlistId?: Array<number>,
      excludeWaitlistId?: Array<number>,
      waitlistAll?: boolean,
      excludeWaitlistAll?: boolean,
      excludeBlacklistAll?: boolean,
      excludeBlacklistStatus?: Array<BlacklistStatusEnum>,
      qualifyingStatus?: QualifyingStatusEnum,
      customBulkEmailProfileRequestModel?: CustomBulkEmailProfileRequestModel,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminEmailBulkCustomProfilePost(
        year,
        champTypeId,
        query,
        twoYearParticipation,
        excludeApplied,
        exempt,
        playerStatus,
        wagrRank,
        adaRequest,
        blacklist,
        watchlist,
        age,
        handicapIndex,
        qualifyingSiteId,
        applicationAccepted,
        waitlistId,
        excludeWaitlistId,
        waitlistAll,
        excludeWaitlistAll,
        excludeBlacklistAll,
        excludeBlacklistStatus,
        qualifyingStatus,
        customBulkEmailProfileRequestModel,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * parameters are used only for \'select all\' functionality
     * @summary Exemptions Applicants bulk email
     * @param {number} champId Filter by championship id
     * @param {string} [query] Optional First/Last Name search query
     * @param {IdListModel} [idListModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminEmailBulkExemptAppPost(
      champId: number,
      query?: string,
      idListModel?: IdListModel,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminEmailBulkExemptAppPost(champId, query, idListModel, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Returns list of players
     * @summary Get list of exempt players
     * @param {number} champId
     * @param {number} [limit] Per page
     * @param {number} [start] Page number
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {string} [query]
     * @param {boolean} [excludeBlacklist]
     * @param {boolean} [excludeWatchlist]
     * @param {boolean} [excludeApplied]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminEmailBulkExemptPlayersGet(
      champId: number,
      limit?: number,
      start?: number,
      sortingFilter?: SortingFilterEnum,
      query?: string,
      excludeBlacklist?: boolean,
      excludeWatchlist?: boolean,
      excludeApplied?: boolean,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileAdminListModel>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminEmailBulkExemptPlayersGet(
        champId,
        limit,
        start,
        sortingFilter,
        query,
        excludeBlacklist,
        excludeWatchlist,
        excludeApplied,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Send buld email to exempt players
     * @param {number} champId
     * @param {number} [limit] Per page
     * @param {number} [start] Page number
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {string} [query]
     * @param {boolean} [excludeBlacklist]
     * @param {boolean} [excludeWatchlist]
     * @param {boolean} [excludeApplied]
     * @param {IdListModel} [idListModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminEmailBulkExemptPlayersPost(
      champId: number,
      limit?: number,
      start?: number,
      sortingFilter?: SortingFilterEnum,
      query?: string,
      excludeBlacklist?: boolean,
      excludeWatchlist?: boolean,
      excludeApplied?: boolean,
      idListModel?: IdListModel,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminEmailBulkExemptPlayersPost(
        champId,
        limit,
        start,
        sortingFilter,
        query,
        excludeBlacklist,
        excludeWatchlist,
        excludeApplied,
        idListModel,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * parameters are used only for \'select all\' functionality
     * @summary Final confirmation bulk email to Applicants
     * @param {number} champId Filter by championship id
     * @param {ApplicationFinalConfirmationBulkFilterEnum} playerFilter Plyer filter
     * @param {string} [query] Optional First/Last Name search query
     * @param {string} [startDate] Optional start qualifying date range
     * @param {string} [endDate] Optional end qualifying date range
     * @param {FinalConfirmationEmailRequestModel} [finalConfirmationEmailRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminEmailBulkFinalConfirmationPost(
      champId: number,
      playerFilter: ApplicationFinalConfirmationBulkFilterEnum,
      query?: string,
      startDate?: string,
      endDate?: string,
      finalConfirmationEmailRequestModel?: FinalConfirmationEmailRequestModel,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminEmailBulkFinalConfirmationPost(
        champId,
        playerFilter,
        query,
        startDate,
        endDate,
        finalConfirmationEmailRequestModel,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * parameters are used only for \'select all\' functionality
     * @summary Bulk email to incomplete sides
     * @param {number} champId Filter by championship id
     * @param {string} [query] Optional First/Last Name search query
     * @param {string} [startDate] Optional start qualifying date range
     * @param {string} [endDate] Optional end qualifying date range
     * @param {IncompleteSidesEmailRequestModel} [incompleteSidesEmailRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminEmailBulkIncompleteSidesPost(
      champId: number,
      query?: string,
      startDate?: string,
      endDate?: string,
      incompleteSidesEmailRequestModel?: IncompleteSidesEmailRequestModel,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminEmailBulkIncompleteSidesPost(
        champId,
        query,
        startDate,
        endDate,
        incompleteSidesEmailRequestModel,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary New Custom Bulk Email to Players
     * @param {NewCustomBulkEmailRequestModel} [newCustomBulkEmailRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminEmailBulkNewCustomPlayersPost(
      newCustomBulkEmailRequestModel?: NewCustomBulkEmailRequestModel,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminEmailBulkNewCustomPlayersPost(newCustomBulkEmailRequestModel, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary New Custom Bulk Email to Applicants
     * @param {NewCustomBulkEmailRequestModel} [newCustomBulkEmailRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminEmailBulkNewCustomPost(
      newCustomBulkEmailRequestModel?: NewCustomBulkEmailRequestModel,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminEmailBulkNewCustomPost(newCustomBulkEmailRequestModel, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * parameters are used only for \'select all\' functionality
     * @summary Bulk email to Officials
     * @param {string} [query] Expression to filter entries (e.g. %word%)
     * @param {number} [champId]
     * @param {number} [year]
     * @param {OfficialsListEmailRequestModel} [officialsListEmailRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminEmailBulkOfficialsPost(
      query?: string,
      champId?: number,
      year?: number,
      officialsListEmailRequestModel?: OfficialsListEmailRequestModel,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminEmailBulkOfficialsPost(
        query,
        champId,
        year,
        officialsListEmailRequestModel,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Bulk email with partner link
     * @param {PartnerLinkEmailRequestModel} [partnerLinkEmailRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminEmailBulkPartnerLinkPost(
      partnerLinkEmailRequestModel?: PartnerLinkEmailRequestModel,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminEmailBulkPartnerLinkPost(partnerLinkEmailRequestModel, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * parameters are used only for \'select all\' functionality
     * @summary Qualified Alternate bulk email to Applicants
     * @param {number} champId Filter by championship id
     * @param {string} [query] Optional First/Last Name search query
     * @param {string} [startDate] Optional start qualifying date range
     * @param {string} [endDate] Optional end qualifying date range
     * @param {ApplicationFilters} [filter]
     * @param {string} [filterValue]
     * @param {BulkQualifyingStatusEnum} [bulkQualifyingStatus] Optional complex filtering
     * @param {IdListModel} [idListModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminEmailBulkQualifiedAlternatePost(
      champId: number,
      query?: string,
      startDate?: string,
      endDate?: string,
      filter?: ApplicationFilters,
      filterValue?: string,
      bulkQualifyingStatus?: BulkQualifyingStatusEnum,
      idListModel?: IdListModel,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminEmailBulkQualifiedAlternatePost(
        champId,
        query,
        startDate,
        endDate,
        filter,
        filterValue,
        bulkQualifyingStatus,
        idListModel,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * parameters are used only for \'select all\' functionality
     * @summary Withdraw and bulk email incomplete applicants
     * @param {number} champId Filter by championship id
     * @param {string} [query] Optional First/Last Name search query
     * @param {string} [startDate] Optional start qualifying date range
     * @param {string} [endDate] Optional end qualifying date range
     * @param {IdListModel} [idListModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminEmailBulkWithdrawIncompletePost(
      champId: number,
      query?: string,
      startDate?: string,
      endDate?: string,
      idListModel?: IdListModel,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminEmailBulkWithdrawIncompletePost(
        champId,
        query,
        startDate,
        endDate,
        idListModel,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Resend email
     * @param {ResendEmailRequestModel} [resendEmailRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminEmailResendPost(
      resendEmailRequestModel?: ResendEmailRequestModel,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminEmailResendPost(resendEmailRequestModel, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Delete email template
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminEmailTemplateDelete(
      id: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminEmailTemplateDelete(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Edit email template
     * @param {EmailTemplateEditModel} [emailTemplateEditModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminEmailTemplateEditPost(
      emailTemplateEditModel?: EmailTemplateEditModel,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailTemplateEditModel>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminEmailTemplateEditPost(emailTemplateEditModel, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Returns Champiobship Type by id
     * @summary Get email template by id
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminEmailTemplateGet(
      id: number,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailTemplateFullResponseModel>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminEmailTemplateGet(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Add new email template
     * @summary Create new email template
     * @param {EmailTemplateRequestModel} [emailTemplateRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminEmailTemplatePost(
      emailTemplateRequestModel?: EmailTemplateRequestModel,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailTemplateEditModel>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminEmailTemplatePost(emailTemplateRequestModel, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get email template by service-type and Champ Id
     * @param {EmailTemplateServiceTypeEnum} serviceType
     * @param {number} [champId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminEmailTemplateServiceTypeGet(
      serviceType: EmailTemplateServiceTypeEnum,
      champId?: number,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailTemplateFullResponseModel>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminEmailTemplateServiceTypeGet(serviceType, champId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Returns list of applications
     * @summary Get filtered list of applications for exemptions bulk
     * @param {number} champId Filter by championship id
     * @param {number} [limit]
     * @param {number} [start]
     * @param {string} [query] Optional First/Last Name search query
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminExemptApplicationsGet(
      champId: number,
      limit?: number,
      start?: number,
      query?: string,
      sortingFilter?: SortingFilterEnum,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationAdminListModel>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminExemptApplicationsGet(
        champId,
        limit,
        start,
        query,
        sortingFilter,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Delete exemption by id
     * @summary Remove exemption by its id
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminExemptionDelete(
      id: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminExemptionDelete(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Edit already existing exemption
     * @summary Edit exemption
     * @param {ExemptionAdminEditModel} [exemptionAdminEditModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminExemptionEditPost(
      exemptionAdminEditModel?: ExemptionAdminEditModel,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExemptionAdminEditModel>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminExemptionEditPost(exemptionAdminEditModel, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Returns list of exemptions
     * @summary Get list of exemptions by championship Id
     * @param {number} champId
     * @param {number} [limit]
     * @param {number} [start]
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {QualifyingLevelEnum} [filter] Optional Filter
     * @param {string} [query] Optional search query parameter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminExemptionGet(
      champId: number,
      limit?: number,
      start?: number,
      sortingFilter?: SortingFilterEnum,
      filter?: QualifyingLevelEnum,
      query?: string,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExemptionAdminListModel>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminExemptionGet(
        champId,
        limit,
        start,
        sortingFilter,
        filter,
        query,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Returns list of exemptions
     * @summary Get list of exemptions by championship Id
     * @param {number} champId
     * @param {number} [limit]
     * @param {number} [start]
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {QualifyingLevelEnum} [filter] Optional Filter
     * @param {string} [query] Optional search query parameter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminExemptionLightWeightGet(
      champId: number,
      limit?: number,
      start?: number,
      sortingFilter?: SortingFilterEnum,
      filter?: QualifyingLevelEnum,
      query?: string,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExemptionAdminListLightWeightModel>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminExemptionLightWeightGet(
        champId,
        limit,
        start,
        sortingFilter,
        filter,
        query,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Add new exemption to championship
     * @summary Add new exemption to championship
     * @param {ExemptionAdminAddModel} [exemptionAdminAddModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminExemptionPost(
      exemptionAdminAddModel?: ExemptionAdminAddModel,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExemptionAdminEditModel>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminExemptionPost(exemptionAdminAddModel, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Is used to get exemption data by its id
     * @summary Get exemption info by id
     * @param {number} id id of exemption
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminExemptionQueryGet(
      id: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExemptionModel>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminExemptionQueryGet(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Import exemptions
     * @summary Import exemptions
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminExemptionUploadPost(
      file?: any,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminExemptionUploadPost(file, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Delete file by id
     * @summary Remove file by its id
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminFileDelete(
      id: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminFileDelete(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Download file by id
     * @summary Download file by id
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminFileGet(
      id: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminFileGet(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Download files by id list
     * @summary Download files by id list
     * @param {Array<number>} id Required id list parameter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminFileListGet(
      id: Array<number>,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminFileListGet(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get handicap player by handicap id
     * @param {string} handicapId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminHandicapGet(
      handicapId: string,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HandicapPlayerModel>>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminHandicapGet(handicapId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Returns list of players
     * @summary Get list of incomplete sides
     * @param {number} champId Filter by championship id
     * @param {number} [limit]
     * @param {number} [start]
     * @param {string} [query] Optional First/Last Name search query
     * @param {string} [startDate] Optional start qualifying date range
     * @param {string} [endDate] Optional end qualifying date range
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminIncompleteSidesGet(
      champId: number,
      limit?: number,
      start?: number,
      query?: string,
      startDate?: string,
      endDate?: string,
      sortingFilter?: SortingFilterEnum,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncompleteSideListModel>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminIncompleteSidesGet(
        champId,
        limit,
        start,
        query,
        startDate,
        endDate,
        sortingFilter,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get unsubscribe page absolute link
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminLinksUnsubscribeGet(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminLinksUnsubscribeGet(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Delete exemption by id
     * @summary Remove exemption by its id
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminMessageDelete(
      id: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminMessageDelete(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Returns list of messages
     * @summary Get list of messages
     * @param {number} [limit]
     * @param {number} [start]
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {MessageTypeEnum} [messageType] Optional filter by message type
     * @param {boolean} [dismissible] Optional filter by current date and \&#39;dateRangeEnabled\&#39;
     * @param {boolean} [dateRangeEnabled] Optional filter by \&#39;dateRangeEnabled\&#39; flag
     * @param {number} [playerId] Filter by player id
     * @param {string} [query] Optional search query parameter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminMessageGet(
      limit?: number,
      start?: number,
      sortingFilter?: SortingFilterEnum,
      messageType?: MessageTypeEnum,
      dismissible?: boolean,
      dateRangeEnabled?: boolean,
      playerId?: number,
      query?: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageListModel>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminMessageGet(
        limit,
        start,
        sortingFilter,
        messageType,
        dismissible,
        dateRangeEnabled,
        playerId,
        query,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Add new message
     * @summary Add new message
     * @param {MessageAddModel} [messageAddModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminMessagePost(
      messageAddModel?: MessageAddModel,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageEditModel>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminMessagePost(messageAddModel, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Is used to get message data by its id
     * @summary Get message by id
     * @param {number} id id of the message
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminMessageQueryGet(
      id: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageAdminModel>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminMessageQueryGet(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Updates message
     * @summary Update message
     * @param {MessageEditModel} [messageEditModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminMessageUpdatePost(
      messageEditModel?: MessageEditModel,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageAdminModel>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminMessageUpdatePost(messageEditModel, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Returns Champiobship Type by id
     * @summary Get Applications Pairs by ChampId
     * @param {number} champId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminPairGet(
      champId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PairListResponseModel>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminPairGet(champId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Returns list of pairs
     * @summary Get filtered list of pairs
     * @param {number} limit
     * @param {number} start
     * @param {number} playerId
     * @param {string} [query] Optional search query
     * @param {PairSortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminPairHistoryGet(
      limit: number,
      start: number,
      playerId: number,
      query?: string,
      sortingFilter?: PairSortingFilterEnum,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PairHistoryListModel>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminPairHistoryGet(limit, start, playerId, query, sortingFilter, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Create/Update Applications Pairs
     * @param {PairListRequestModel} [pairListRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminPairPost(
      pairListRequestModel?: PairListRequestModel,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminPairPost(pairListRequestModel, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Refunds payment
     * @summary Refunds payment
     * @param {RefundPaymentAdminEditModel} [refundPaymentAdminEditModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminPaymentEditRefundPost(
      refundPaymentAdminEditModel?: RefundPaymentAdminEditModel,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationRefundResponseModel>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminPaymentEditRefundPost(refundPaymentAdminEditModel, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Refunds payment
     * @summary Refunds payment
     * @param {FourBallRefundPaymentAdminModel} [fourBallRefundPaymentAdminModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminPaymentFourBallRefundPost(
      fourBallRefundPaymentAdminModel?: FourBallRefundPaymentAdminModel,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationRefundListModel>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminPaymentFourBallRefundPost(fourBallRefundPaymentAdminModel, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Get form data to make post request to payment processor
     * @param {number} appId Application id
     * @param {string} referrer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminPaymentGet(
      appId: number,
      referrer: string,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentDataPlayerResponseModel>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminPaymentGet(appId, referrer, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Refunds payment
     * @summary Refunds payment
     * @param {RefundPaymentAdminModel} [refundPaymentAdminModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminPaymentRefundPost(
      refundPaymentAdminModel?: RefundPaymentAdminModel,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationRefundListModel>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminPaymentRefundPost(refundPaymentAdminModel, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Voids Refund payment
     * @summary Voids Refund payment
     * @param {VoidRefundPaymentAdminModel} [voidRefundPaymentAdminModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminPaymentRefundVoidPost(
      voidRefundPaymentAdminModel?: VoidRefundPaymentAdminModel,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminPaymentRefundVoidPost(voidRefundPaymentAdminModel, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Returns list of applications
     * @summary Get filtered list of applications for custom email
     * @param {number} [limit]
     * @param {number} [start]
     * @param {Array<number>} [year] Optional Filter by year id
     * @param {Array<number>} [champTypeId] Optional Filter by ChampionshipType Model id
     * @param {string} [query] Optional search query parameter
     * @param {boolean} [twoYearParticipation] Optional Filter to find Two Year Participation
     * @param {boolean} [excludeApplied] Optional Exclude Applied Players Filter
     * @param {boolean} [exempt] Optional Filter to exclude exempt players
     * @param {PlayerStatusEnum} [playerStatus] Optional Player Status Filter
     * @param {string} [wagrRank] Optional WAGR Rank Filter
     * @param {boolean} [adaRequest] Optional ADA Request Filter, true - include, false - exclude
     * @param {boolean} [blacklist] Optional Blacklist Filter, true - include, false - exclude
     * @param {boolean} [watchlist] Optional Watchlist Filter, true - include, false - exclude
     * @param {number} [age] Optional Filter by Player\&#39;s age
     * @param {string} [handicapIndex] Optional Handicap Index Filter
     * @param {Array<number>} [qualifyingSiteId] Optional qualifying site Ids
     * @param {boolean} [applicationAccepted] Optional filter for accepted or q applications
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {EmailTemplateTypeEnum} [templType]
     * @param {number} [templId]
     * @param {Array<number>} [waitlistId] Optional Filter by Waitlist QS id
     * @param {Array<number>} [excludeWaitlistId] Optional Filter exclude by Waitlist QS id
     * @param {boolean} [waitlistAll] Optional Filter to find All waitlisted player by champType &amp; year
     * @param {boolean} [excludeWaitlistAll] Optional Filter to exclude All waitlisted player by champType &amp; year
     * @param {boolean} [excludeBlacklistAll] Optional Filter to exclude All blacklisted player by champType &amp; year
     * @param {Array<BlacklistStatusEnum>} [excludeBlacklistStatus]
     * @param {QualifyingStatusEnum} [qualifyingStatus] Optional Application Qualifying Status Filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminPlayersCustomEmailGet(
      limit?: number,
      start?: number,
      year?: Array<number>,
      champTypeId?: Array<number>,
      query?: string,
      twoYearParticipation?: boolean,
      excludeApplied?: boolean,
      exempt?: boolean,
      playerStatus?: PlayerStatusEnum,
      wagrRank?: string,
      adaRequest?: boolean,
      blacklist?: boolean,
      watchlist?: boolean,
      age?: number,
      handicapIndex?: string,
      qualifyingSiteId?: Array<number>,
      applicationAccepted?: boolean,
      sortingFilter?: SortingFilterEnum,
      templType?: EmailTemplateTypeEnum,
      templId?: number,
      waitlistId?: Array<number>,
      excludeWaitlistId?: Array<number>,
      waitlistAll?: boolean,
      excludeWaitlistAll?: boolean,
      excludeBlacklistAll?: boolean,
      excludeBlacklistStatus?: Array<BlacklistStatusEnum>,
      qualifyingStatus?: QualifyingStatusEnum,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileAdminListModel>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminPlayersCustomEmailGet(
        limit,
        start,
        year,
        champTypeId,
        query,
        twoYearParticipation,
        excludeApplied,
        exempt,
        playerStatus,
        wagrRank,
        adaRequest,
        blacklist,
        watchlist,
        age,
        handicapIndex,
        qualifyingSiteId,
        applicationAccepted,
        sortingFilter,
        templType,
        templId,
        waitlistId,
        excludeWaitlistId,
        waitlistAll,
        excludeWaitlistAll,
        excludeBlacklistAll,
        excludeBlacklistStatus,
        qualifyingStatus,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Delete point of contact by id
     * @summary Remove point of contact by its id
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminPocDelete(
      id: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminPocDelete(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Returns list of points of contact
     * @summary Get list of points of contact
     * @param {number} [limit]
     * @param {number} [start]
     * @param {string} [query] Expression to filter entries (e.g. %word%)
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminPocGet(
      limit?: number,
      start?: number,
      query?: string,
      sortingFilter?: SortingFilterEnum,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PointOfContactAdminListModel>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminPocGet(limit, start, query, sortingFilter, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Add new point of contact
     * @summary Add new point of contact
     * @param {PointOfContactAdminAddModel} [pointOfContactAdminAddModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminPocPost(
      pointOfContactAdminAddModel?: PointOfContactAdminAddModel,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PointOfContactAdminModel>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminPocPost(pointOfContactAdminAddModel, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Is used to get poc data by its id
     * @summary Get poc info by id
     * @param {number} id id of poc
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminPocQueryGet(
      id: number,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PointOfContactAdminModel>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminPocQueryGet(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Updates point of contact
     * @summary Update point of contact
     * @param {PointOfContactAdminEditModel} [pointOfContactAdminEditModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminPocUpdatePost(
      pointOfContactAdminEditModel?: PointOfContactAdminEditModel,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PointOfContactAdminEditModel>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminPocUpdatePost(pointOfContactAdminEditModel, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get duplicated player\'s profile quantity
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminProfileDuplicateAutoMergeGet(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminProfileDuplicateAutoMergeGet(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Auto-merge players\' profiles
     * @summary Auto-merge players\' profiles
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminProfileDuplicateAutoMergePost(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileAdminListModel>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminProfileDuplicateAutoMergePost(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Is used to get player\'s profile duplicates
     * @summary Get player\'s profile duplicates
     * @param {string} id id of profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminProfileDuplicateGet(
      id: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileAdminListModel>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminProfileDuplicateGet(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Updates player profile
     * @summary Merge player\'s profiles
     * @param {IdListModel} [idListModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminProfileDuplicatePost(
      idListModel?: IdListModel,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileAdminModel>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminProfileDuplicatePost(idListModel, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Get status list of requested subscriptions
     * @summary Get status list of requested subscriptions
     * @param {Array<EmailSubscriptionType>} [types]
     * @param {number} [playerId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminProfileEmailSubscriptionsGet(
      types?: Array<EmailSubscriptionType>,
      playerId?: number,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailSubscriptionsStatusList>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminProfileEmailSubscriptionsGet(types, playerId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Update subscriptions
     * @summary Update subscriptions
     * @param {PlayerEmailSubscriptionsStatus} [playerEmailSubscriptionsStatus]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminProfileEmailSubscriptionsPost(
      playerEmailSubscriptionsStatus?: PlayerEmailSubscriptionsStatus,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminProfileEmailSubscriptionsPost(playerEmailSubscriptionsStatus, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Is used to get profile data
     * @summary Get player profile
     * @param {string} id id of profile to return data for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminProfileGet(
      id: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileAdminModel>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminProfileGet(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Is used to get profile data
     * @summary [GraphQL] Get player profile
     * @param {string} id id of profile to return data for
     * @param {Array<string>} [fields] required fields to fetch, e.g. \&quot;aaa\&quot;, \&quot;bbb.ccc.ddd\&quot;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminProfileGraphqlGet(
      id: string,
      fields?: Array<string>,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminProfileGraphqlGet(id, fields, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Returns list of players
     * @summary Get list of players
     * @param {number} [limit] Per page
     * @param {number} [start] Page number
     * @param {string} [query] Query parameter to filter users based on: User Id, First Name, Last Name, Player Status, Date of Birth (e.g. query&#x3D;value*)
     * @param {PlayerFilters} [filter]
     * @param {string} [filterValue]
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {Array<number>} [champId] Optional Filter by champ id
     * @param {Array<number>} [year]
     * @param {Array<number>} [siteId] Optional Filter by qualifying site id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminProfileListGet(
      limit?: number,
      start?: number,
      query?: string,
      filter?: PlayerFilters,
      filterValue?: string,
      sortingFilter?: SortingFilterEnum,
      champId?: Array<number>,
      year?: Array<number>,
      siteId?: Array<number>,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileAdminListModel>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminProfileListGet(
        limit,
        start,
        query,
        filter,
        filterValue,
        sortingFilter,
        champId,
        year,
        siteId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Returns list of players
     * @summary [GraphQL] Get list of players
     * @param {number} [limit] Per page
     * @param {number} [start] Page number
     * @param {string} [query] Query parameter to filter users based on: User Id, First Name, Last Name, Player Status, Date of Birth (e.g. query&#x3D;value*)
     * @param {PlayerFilters} [filter]
     * @param {string} [filterValue]
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {Array<number>} [champId] Optional Filter by champ id
     * @param {Array<number>} [year]
     * @param {Array<number>} [siteId] Optional Filter by qualifying site id
     * @param {Array<string>} [fields] required fields to fetch, e.g. \&quot;aaa\&quot;, \&quot;bbb.ccc.ddd\&quot;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminProfileListGraphqlGet(
      limit?: number,
      start?: number,
      query?: string,
      filter?: PlayerFilters,
      filterValue?: string,
      sortingFilter?: SortingFilterEnum,
      champId?: Array<number>,
      year?: Array<number>,
      siteId?: Array<number>,
      fields?: Array<string>,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminProfileListGraphqlGet(
        limit,
        start,
        query,
        filter,
        filterValue,
        sortingFilter,
        champId,
        year,
        siteId,
        fields,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Updates managed players
     * @summary Update managed players
     * @param {ProfileManagedPlayerAdminModel} [profileManagedPlayerAdminModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminProfileManagedplayerPost(
      profileManagedPlayerAdminModel?: ProfileManagedPlayerAdminModel,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminProfileManagedplayerPost(profileManagedPlayerAdminModel, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Returns list of players
     * @summary Get list of players
     * @param {number} [limit] Per page
     * @param {number} [start] Page number
     * @param {string} [query] Query parameter to filter users based on: User Id, First Name, Last Name, Player Status, Date of Birth (e.g. query&#x3D;value*)
     * @param {PlayerFilters} [filter]
     * @param {string} [filterValue]
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {Array<number>} [champId] Optional Filter by champ id
     * @param {Array<number>} [year]
     * @param {Array<number>} [champTypeId] Optional Filter by champ type id
     * @param {string} [blackList] Black list
     * @param {Array<number>} [siteId] Optional Filter by qualifying site id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminProfileMessageListGet(
      limit?: number,
      start?: number,
      query?: string,
      filter?: PlayerFilters,
      filterValue?: string,
      sortingFilter?: SortingFilterEnum,
      champId?: Array<number>,
      year?: Array<number>,
      champTypeId?: Array<number>,
      blackList?: string,
      siteId?: Array<number>,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileAdminListModel>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminProfileMessageListGet(
        limit,
        start,
        query,
        filter,
        filterValue,
        sortingFilter,
        champId,
        year,
        champTypeId,
        blackList,
        siteId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Updates player profile
     * @summary Save/update player profile
     * @param {ProfileAdminRequestModel} [profileAdminRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminProfilePost(
      profileAdminRequestModel?: ProfileAdminRequestModel,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileAdminModel>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminProfilePost(profileAdminRequestModel, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get profiles quantity
     * @param {PlayerFilters} [filter]
     * @param {string} [filterValue]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminProfileQuantityGet(
      filter?: PlayerFilters,
      filterValue?: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminProfileQuantityGet(filter, filterValue, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Closes QS
     * @summary Provides zip file for download with reports with Applications of QS
     * @param {number} champId
     * @param {QualifyingSiteFilters} [filter]
     * @param {IdListModel} [idListModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminQualifyingsiteApplicationsDownloadPost(
      champId: number,
      filter?: QualifyingSiteFilters,
      idListModel?: IdListModel,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminQualifyingsiteApplicationsDownloadPost(
        champId,
        filter,
        idListModel,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Auto-assign QS number
     * @summary Auto-assign QS number
     * @param {number} champId
     * @param {QualifyingLevelEnum} level
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminQualifyingsiteAutoAssignPost(
      champId: number,
      level: QualifyingLevelEnum,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminQualifyingsiteAutoAssignPost(champId, level, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Returns list of qualifying sites
     * @summary Get list of qualifying sites by championship Ids
     * @param {Array<number>} champId Filter by champ id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminQualifyingsiteChampsGet(
      champId: Array<number>,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<QualifyingSiteAdminListModel>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminQualifyingsiteChampsGet(champId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Returns list of qualifying sites by champ type ids
     * @summary Get list of qualifying sites by champ type id
     * @param {Array<number>} champTypeId Champ Type Ids
     * @param {Array<number>} year Champ Type Ids
     * @param {number} [limit]
     * @param {number} [start]
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminQualifyingsiteChamptypeidGet(
      champTypeId: Array<number>,
      year: Array<number>,
      limit?: number,
      start?: number,
      sortingFilter?: SortingFilterEnum,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<QualifyingSiteAdminListModel>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminQualifyingsiteChamptypeidGet(
        champTypeId,
        year,
        limit,
        start,
        sortingFilter,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Checks whether it is possible to create a \'QualifyingSite\' with such data.
     * @summary Check if a combination of ChampId+Level+Number is still free
     * @param {number} champId
     * @param {string} level
     * @param {number} number
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminQualifyingsiteCheckGet(
      champId: number,
      level: string,
      number: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminQualifyingsiteCheckGet(champId, level, number, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Closes QS
     * @summary Closes selected qualifying sites and provides zip file for download with reports
     * @param {IdListModel} [idListModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminQualifyingsiteClosePost(
      idListModel?: IdListModel,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminQualifyingsiteClosePost(idListModel, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Delete qualifying site by id
     * @summary Remove qualifying site by its id
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminQualifyingsiteDelete(
      id: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminQualifyingsiteDelete(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Returns list of qualifying sites
     * @summary Get list of qualifying sites by championship Id
     * @param {number} champId
     * @param {number} [limit]
     * @param {number} [start]
     * @param {QualifyingSiteFilters} [filter]
     * @param {string} [filterValue]
     * @param {string} [startDate] Optional start date range
     * @param {string} [endDate] Optional end date range
     * @param {string} [query]
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminQualifyingsiteGet(
      champId: number,
      limit?: number,
      start?: number,
      filter?: QualifyingSiteFilters,
      filterValue?: string,
      startDate?: string,
      endDate?: string,
      query?: string,
      sortingFilter?: SortingFilterEnum,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<QualifyingSiteAdminListModel>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminQualifyingsiteGet(
        champId,
        limit,
        start,
        filter,
        filterValue,
        startDate,
        endDate,
        query,
        sortingFilter,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Returns list of qualifying sites
     * @summary Get list of qualifying sites by championship Id
     * @param {number} champId
     * @param {number} [limit]
     * @param {number} [start]
     * @param {QualifyingSiteFilters} [filter]
     * @param {string} [filterValue]
     * @param {string} [startDate] Optional start date range
     * @param {string} [endDate] Optional end date range
     * @param {string} [query]
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminQualifyingsiteLightWeightGet(
      champId: number,
      limit?: number,
      start?: number,
      filter?: QualifyingSiteFilters,
      filterValue?: string,
      startDate?: string,
      endDate?: string,
      query?: string,
      sortingFilter?: SortingFilterEnum,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<QualifyingSiteAdminListLightWeightModel>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminQualifyingsiteLightWeightGet(
        champId,
        limit,
        start,
        filter,
        filterValue,
        startDate,
        endDate,
        query,
        sortingFilter,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Add new qualifying site to championship
     * @summary Add new qualifying site to championship
     * @param {QualifyingSiteAddModel} [qualifyingSiteAddModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminQualifyingsitePost(
      qualifyingSiteAddModel?: QualifyingSiteAddModel,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<QualifyingSiteAdminModel>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminQualifyingsitePost(qualifyingSiteAddModel, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Is used to get qualifying site data by its id
     * @summary Get qualifying site info by id
     * @param {number} id id of qualifying site
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminQualifyingsiteQueryGet(
      id: number,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<QualifyingSiteFetchModel>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminQualifyingsiteQueryGet(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Returns list of qualifying sites uploaded results
     * @summary Get list of qualifying sites results by file id
     * @param {number} [limit]
     * @param {number} [start]
     * @param {number} [fileId]
     * @param {string} [query]
     * @param {UploadFileProgressionEnum} [status]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminQualifyingsiteResultDetailGet(
      limit?: number,
      start?: number,
      fileId?: number,
      query?: string,
      status?: UploadFileProgressionEnum,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScoringListModel>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminQualifyingsiteResultDetailGet(
        limit,
        start,
        fileId,
        query,
        status,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Add new qualifying site to championship
     * @summary Update
     * @param {ScoreUpdateRequestAdminModelScoring} [scoreUpdateRequestAdminModelScoring]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminQualifyingsiteResultDetailPost(
      scoreUpdateRequestAdminModelScoring?: ScoreUpdateRequestAdminModelScoring,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationBaseAdminModelScoring>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminQualifyingsiteResultDetailPost(
        scoreUpdateRequestAdminModelScoring,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Returns list of qualifying sites uploaded results
     * @summary Get list of qualifying sites results by qualifying site id
     * @param {number} champId
     * @param {number} [limit]
     * @param {number} [start]
     * @param {number} [qualifyingSiteId]
     * @param {string} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminQualifyingsiteResultListGet(
      champId: number,
      limit?: number,
      start?: number,
      qualifyingSiteId?: number,
      query?: string,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadedFileAdminListModel>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminQualifyingsiteResultListGet(
        champId,
        limit,
        start,
        qualifyingSiteId,
        query,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Update
     * @param {UploadFileStatusRequestAdminModel} [uploadFileStatusRequestAdminModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminQualifyingsiteResultListPost(
      uploadFileStatusRequestAdminModel?: UploadFileStatusRequestAdminModel,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminQualifyingsiteResultListPost(uploadFileStatusRequestAdminModel, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Updates point of contact
     * @summary Update qualifying site
     * @param {QualifyingSiteEditModel} [qualifyingSiteEditModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminQualifyingsiteUpdatePost(
      qualifyingSiteEditModel?: QualifyingSiteEditModel,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminQualifyingsiteUpdatePost(qualifyingSiteEditModel, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Returns list of Q reasons
     * @summary Get list of queue reasons
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminQueueReasonGet(
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<QueueReasonAdminModel>>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminQueueReasonGet(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Download ada report
     * @summary Download ada report
     * @param {number} [champId] Optional filter by championship id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminReportsAdaGet(
      champId?: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminReportsAdaGet(champId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Download anti-doping report
     * @summary Download anti-doping report
     * @param {number} [champId] Optional filter by championship id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminReportsAntidopingGet(
      champId?: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminReportsAntidopingGet(champId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Download list of applications
     * @summary Download filtered list of applications
     * @param {number} champId Optional filter by championship id
     * @param {number} year Championship year
     * @param {ApplicationFilters} filter
     * @param {number} [limit]
     * @param {number} [start]
     * @param {string} [query] Optional full text search query
     * @param {string} [filterValue]
     * @param {string} [disabilityTypeFilter] Disability Type
     * @param {Array<number>} [appId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminReportsApplicationsGet(
      champId: number,
      year: number,
      filter: ApplicationFilters,
      limit?: number,
      start?: number,
      query?: string,
      filterValue?: string,
      disabilityTypeFilter?: string,
      appId?: Array<number>,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminReportsApplicationsGet(
        champId,
        year,
        filter,
        limit,
        start,
        query,
        filterValue,
        disabilityTypeFilter,
        appId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Download blacklist report
     * @summary Download Blacklist across all champ report
     * @param {number} [champId] Optional filter by championship id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminReportsBlacklistGet(
      champId?: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminReportsBlacklistGet(champId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Download close of entries data snapshot report
     * @summary Download close of entries data snapshot report
     * @param {number} [champId] Optional filter by championship id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminReportsCloseOfEntriesGet(
      champId?: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminReportsCloseOfEntriesGet(champId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Download demographics report
     * @summary Download demographics report
     * @param {number} [champId] Optional filter by championship id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminReportsDemographicsGet(
      champId?: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminReportsDemographicsGet(champId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Download report by access key
     * @summary Download report by access key
     * @param {string} id
     * @param {boolean} [inline] Optional flag, set to true in order to get inlined response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminReportsDownloadGet(
      id: string,
      inline?: boolean,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminReportsDownloadGet(id, inline, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Download duplicate applications report
     * @summary Download duplicate applications report
     * @param {number} [champId] Optional filter by championship id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminReportsDuplicatesGet(
      champId?: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminReportsDuplicatesGet(champId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Download exemption report
     * @summary Download \'Exemptions Chosen on Application Report\' report
     * @param {number} [champId] Optional filter by championship id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminReportsExemptionChosenGet(
      champId?: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminReportsExemptionChosenGet(champId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Download exemption report
     * @summary Download \'Exemptions Across All Championships\' report
     * @param {number} [champId] Optional filter by championship id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminReportsExemptionGet(
      champId?: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminReportsExemptionGet(champId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Download notable players report
     * @summary Download notable players report
     * @param {number} [champId] Optional filter by championship id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminReportsNotablePlayersGet(
      champId?: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminReportsNotablePlayersGet(champId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Download list of profiles
     * @summary Download filtered list of profiles
     * @param {number} [limit] Per page
     * @param {number} [start] Page number
     * @param {string} [query] Query parameter to filter users based on: User Id, First Name, Last Name, Player Status, Date of Birth (e.g. query&#x3D;value*)
     * @param {PlayerFilters} [filter]
     * @param {string} [filterValue]
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {Array<number>} [playerId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminReportsProfilesGet(
      limit?: number,
      start?: number,
      query?: string,
      filter?: PlayerFilters,
      filterValue?: string,
      sortingFilter?: SortingFilterEnum,
      playerId?: Array<number>,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminReportsProfilesGet(
        limit,
        start,
        query,
        filter,
        filterValue,
        sortingFilter,
        playerId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Download qualifying sites report
     * @summary Download qualifying sites report
     * @param {number} [champId] Optional filter by championship id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminReportsQualifyingSitesGet(
      champId?: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminReportsQualifyingSitesGet(champId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Download list of QS
     * @summary Download filtered list of QS
     * @param {number} champId
     * @param {number} [limit]
     * @param {number} [start]
     * @param {QualifyingSiteFilters} [filter]
     * @param {string} [filterValue]
     * @param {string} [startDate] Optional start date range
     * @param {string} [endDate] Optional end date range
     * @param {string} [query]
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {Array<number>} [id] Optional QS id multiple filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminReportsQualifyingsiteDownloadGet(
      champId: number,
      limit?: number,
      start?: number,
      filter?: QualifyingSiteFilters,
      filterValue?: string,
      startDate?: string,
      endDate?: string,
      query?: string,
      sortingFilter?: SortingFilterEnum,
      id?: Array<number>,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminReportsQualifyingsiteDownloadGet(
        champId,
        limit,
        start,
        filter,
        filterValue,
        startDate,
        endDate,
        query,
        sortingFilter,
        id,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Download refund applications report
     * @summary Download refund applications report
     * @param {number} [champId] Optional filter by championship id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminReportsRefundGet(
      champId?: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminReportsRefundGet(champId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Download customer service report
     * @summary Download customer service report
     * @param {string} from
     * @param {string} to
     * @param {number} [champId] Championship ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminReportsServiceGet(
      from: string,
      to: string,
      champId?: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminReportsServiceGet(from, to, champId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Download watchlist report
     * @summary Download Watchlist across all champ report
     * @param {number} [champId] Optional filter by championship id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminReportsWatchlistGet(
      champId?: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminReportsWatchlistGet(champId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Download filtered list of withdrawn applications
     * @summary Download filtered list of withdrawn applications
     * @param {number} champId championship id
     * @param {IdListModel} [idListModel] Optional qualifying site ids to filter by
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminReportsWithdrawalPost(
      champId: number,
      idListModel?: IdListModel,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminReportsWithdrawalPost(champId, idListModel, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Download withdrawn applications report
     * @summary Download withdrawn applications report
     * @param {number} [champId] Optional filter by championship id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminReportsWithdrawnGet(
      champId?: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminReportsWithdrawnGet(champId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Updates player team
     * @summary Update team
     * @param {CreateTeamAdminModel} [createTeamAdminModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminTeamCreatePost(
      createTeamAdminModel?: CreateTeamAdminModel,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdModel>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminTeamCreatePost(createTeamAdminModel, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Is used to get team data
     * @summary Get player team
     * @param {string} id id of team to return data for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminTeamGet(
      id: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamAdminResponseModel>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminTeamGet(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Updates player team
     * @summary Update team
     * @param {TeamAdminRequestModel} [teamAdminRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminTeamPost(
      teamAdminRequestModel?: TeamAdminRequestModel,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamAdminResponseModel>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminTeamPost(teamAdminRequestModel, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Upload Application Files
     * @param {number} champId Id of championship we uploading data for
     * @param {number} applicationId Id of application we uploading data for
     * @param {UploadedFileType} fileType
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminUploadApplicationFilePost(
      champId: number,
      applicationId: number,
      fileType: UploadedFileType,
      file?: any,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminUploadApplicationFilePost(
        champId,
        applicationId,
        fileType,
        file,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Create user
     * @param {UserCreateModel} [userCreateModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminUserCreatePost(
      userCreateModel?: UserCreateModel,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponseModel>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminUserCreatePost(userCreateModel, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Delete user by id
     * @summary Remove user by its id
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminUserDelete(
      userId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminUserDelete(userId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Is used to get user data
     * @summary Get user
     * @param {string} userId subscriber id of user to return user data for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminUserGet(
      userId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponseModel>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminUserGet(userId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Returns list of users
     * @summary Get list of users
     * @param {number} [limit] Per page
     * @param {number} [start] Starting position, value must be a multiple of limit parameter
     * @param {string} [query] Expression to filter users in lucene format (e.g. name:Val*)
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminUserListGet(
      limit?: number,
      start?: number,
      query?: string,
      sortingFilter?: SortingFilterEnum,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAdminListModel>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminUserListGet(limit, start, query, sortingFilter, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Is used to get user data
     * @summary Get user
     * @param {string} userEmail user email to send reset password link
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminUserPasswordResetGet(
      userEmail: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminUserPasswordResetGet(userEmail, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Updates user
     * @summary Update user
     * @param {UserResponseModel} [userResponseModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminUserPost(
      userResponseModel?: UserResponseModel,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponseModel>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminUserPost(userResponseModel, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Is used to update user data by auth0SearchQuery
     * @summary Update User type by utility
     * @param {string} id Champ type id to add for user type
     * @param {string} auth0SearchQuery User auth0 Search Query
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminAdminUtilityUserGet(
      id: string,
      auth0SearchQuery: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminAdminUtilityUserGet(id, auth0SearchQuery, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Returns list of Champiobship Types
     * @summary Get list of Champ-types
     * @param {number} [limit]
     * @param {number} [start]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminCommonChampTypeListGet(
      limit?: number,
      start?: number,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChampTypeTitleIdListModel>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminCommonChampTypeListGet(limit, start, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Returns Championship by Id
     * @summary Get Championship by Id
     * @param {number} champId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminCommonChampionshipGet(
      champId: number,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChampionshipPlayerModel>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminCommonChampionshipGet(champId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Download info file by champ id
     * @summary Download info file by champ id
     * @param {number} champId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminCommonChampionshipInfoDownloadGet(
      champId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminCommonChampionshipInfoDownloadGet(champId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Returns the list of championship years
     * @summary Get list of years for championships
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminCommonChampionshipsYearsGet(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<number>>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminCommonChampionshipsYearsGet(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Returns country by Id
     * @summary Get country by Id
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminCommonCountryGet(
      id: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CountryModel>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminCommonCountryGet(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Returns list of countries
     * @summary Get list of countries
     * @param {number} [limit]
     * @param {number} [start]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminCommonCountryListGet(
      limit?: number,
      start?: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CountryListModel>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminCommonCountryListGet(limit, start, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Returns list of exemptions
     * @summary Get list of exemptions by championship Id and applicaton Id
     * @param {number} [champId]
     * @param {number} [appId]
     * @param {number} [limit]
     * @param {number} [start]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminCommonExemptionGet(
      champId?: number,
      appId?: number,
      limit?: number,
      start?: number,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExemptionPlayerListModel>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminCommonExemptionGet(champId, appId, limit, start, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Returns USGA Footer Component
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminCommonFooterGet(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FooterModel>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminCommonFooterGet(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Returns USGA Global Navigation Component
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminCommonNavigationGet(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GlobalNavigationModel>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminCommonNavigationGet(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Impersonation check
     * @summary Check if current user is impersonating other player
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminCommonProfileLinkImpersonationGet(
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<ProfilePlayerPersonalDataWithIdResponseModel>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminCommonProfileLinkImpersonationGet(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Returns list of approved qualifying sites
     * @summary Get list of approved qualifying sites by championship Id
     * @param {Array<number>} [ids] Optionally filter by ids
     * @param {number} [champId]
     * @param {number} [limit]
     * @param {number} [start]
     * @param {number} [appId]
     * @param {string} [query]
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {string} [startDate] Optional start date range
     * @param {string} [endDate] Optional end date range
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminCommonQualifyingsiteGet(
      ids?: Array<number>,
      champId?: number,
      limit?: number,
      start?: number,
      appId?: number,
      query?: string,
      sortingFilter?: SortingFilterEnum,
      startDate?: string,
      endDate?: string,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<QualifyingSitePlayerListModel>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminCommonQualifyingsiteGet(
        ids,
        champId,
        limit,
        start,
        appId,
        query,
        sortingFilter,
        startDate,
        endDate,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Returns state by Id
     * @summary Get state by Id
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminCommonStateGet(
      id: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StateModel>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminCommonStateGet(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Returns list of states
     * @summary Get list of states
     * @param {StateTypeEnum} type
     * @param {number} [limit]
     * @param {number} [start]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminCommonStateListGet(
      type: StateTypeEnum,
      limit?: number,
      start?: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StateListModel>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminCommonStateListGet(type, limit, start, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Stripe webhook handler servlet
     * @param {object} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminCommonStripeWebhookPost(
      body?: object,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminCommonStripeWebhookPost(body, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Check if email is already in use
     * @param {string} userEmail
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminCommonUserCheckGet(
      userEmail: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminCommonUserCheckGet(userEmail, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Login player
     * @param {UserLoginPlayerModel} [userLoginPlayerModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminCommonUserLoginPost(
      userLoginPlayerModel?: UserLoginPlayerModel,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminCommonUserLoginPost(userLoginPlayerModel, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * reset password endpoint
     * @summary Reset password
     * @param {string} userEmail user email to send reset password link
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminCommonUserPasswordResetGet(
      userEmail: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminCommonUserPasswordResetGet(userEmail, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Create player
     * @param {UserCreatePlayerModel} [userCreatePlayerModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminCommonUserRegisterPost(
      userCreatePlayerModel?: UserCreatePlayerModel,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminCommonUserRegisterPost(userCreatePlayerModel, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Returns AGA by Id
     * @summary Get AGA by Id
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminOfficialAgaByIdGet(
      id: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgaEditModel>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminOfficialAgaByIdGet(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Returns AGA by User Id taken from token
     * @summary Get AGA by User Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminOfficialAgaByUserIdGet(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgaEditModel>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminOfficialAgaByUserIdGet(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Returns list of AGA
     * @summary Get list of AGA
     * @param {number} [limit]
     * @param {number} [start]
     * @param {string} [query] Optional search query to filter out AGA by names.
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminOfficialAgaGet(
      limit?: number,
      start?: number,
      query?: string,
      sortingFilter?: SortingFilterEnum,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgaAdminListModel>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminOfficialAgaGet(limit, start, query, sortingFilter, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Download list of applications
     * @summary Download withrawn list of applications for specific QS
     * @param {number} qualifyingSiteId Qualifying site ID
     * @param {string} authorization authorization token
     * @param {string} [listType]
     * @param {IdListModel} [idListModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminOfficialApplicationsDownloadQsPost(
      qualifyingSiteId: number,
      authorization: string,
      listType?: string,
      idListModel?: IdListModel,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminOfficialApplicationsDownloadQsPost(
        qualifyingSiteId,
        authorization,
        listType,
        idListModel,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Returns list of applications
     * @summary Get filtered list of applications
     * @param {number} year Championship year
     * @param {ApplicationFilters} filter
     * @param {number} [champId] Optional filter by championship id
     * @param {number} [limit]
     * @param {number} [start]
     * @param {string} [query] Optional full text search query
     * @param {string} [filterValue]
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminOfficialApplicationsGet(
      year: number,
      filter: ApplicationFilters,
      champId?: number,
      limit?: number,
      start?: number,
      query?: string,
      filterValue?: string,
      sortingFilter?: SortingFilterEnum,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationAdminListModel>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminOfficialApplicationsGet(
        year,
        filter,
        champId,
        limit,
        start,
        query,
        filterValue,
        sortingFilter,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Returns a list of calendar cards based on assigned championship types.
     * @summary Get calendar cards based on assigned championship types.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminOfficialCalendarGet(
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfficialsCalendarListModel>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminOfficialCalendarGet(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Returns list of Champiobship Types
     * @summary Get list of Champ-types
     * @param {number} [limit]
     * @param {number} [start]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminOfficialChampTypeListGet(
      limit?: number,
      start?: number,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChampTypeTitleIdListModel>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminOfficialChampTypeListGet(limit, start, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Returns a boolean value based on the entry reviewed or not.
     * @summary Return whether entry is reviewed or not
     * @param {string} [query]
     * @param {number} [year]
     * @param {WorkflowTypeEnum} [champType]
     * @param {number} [champTypeId]
     * @param {Array<string>} [fields] required fields to fetch, e.g. \&quot;aaa\&quot;, \&quot;bbb.ccc.ddd\&quot;
     * @param {boolean} [active] indicates if champ is active
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminOfficialChampionshipAllEntriesReviewedGet(
      query?: string,
      year?: number,
      champType?: WorkflowTypeEnum,
      champTypeId?: number,
      fields?: Array<string>,
      active?: boolean,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminOfficialChampionshipAllEntriesReviewedGet(
        query,
        year,
        champType,
        champTypeId,
        fields,
        active,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Returns a list of championships based on assigned championship types.
     * @summary Get championships list based on assigned championship types.
     * @param {string} [query]
     * @param {number} [year]
     * @param {WorkflowTypeEnum} [champType]
     * @param {number} [champTypeId]
     * @param {boolean} [active] indicates if champ is active
     * @param {number} [start]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminOfficialChampionshipsGet(
      query?: string,
      year?: number,
      champType?: WorkflowTypeEnum,
      champTypeId?: number,
      active?: boolean,
      start?: number,
      limit?: number,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffChampionshipListModel>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminOfficialChampionshipsGet(
        query,
        year,
        champType,
        champTypeId,
        active,
        start,
        limit,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Returns a list of championships based on assigned championship types.
     * @summary [GraphQL]Get championships list based on assigned championship types.
     * @param {string} [query]
     * @param {number} [year]
     * @param {WorkflowTypeEnum} [champType]
     * @param {number} [champTypeId]
     * @param {Array<string>} [fields] required fields to fetch, e.g. \&quot;aaa\&quot;, \&quot;bbb.ccc.ddd\&quot;
     * @param {boolean} [active] indicates if champ is active
     * @param {number} [start]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminOfficialChampionshipsGraphqlGet(
      query?: string,
      year?: number,
      champType?: WorkflowTypeEnum,
      champTypeId?: number,
      fields?: Array<string>,
      active?: boolean,
      start?: number,
      limit?: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminOfficialChampionshipsGraphqlGet(
        query,
        year,
        champType,
        champTypeId,
        fields,
        active,
        start,
        limit,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Returns list of clubs
     * @summary Get list of clubs
     * @param {number} [limit]
     * @param {number} [start]
     * @param {string} [query] Expression to filter entries (e.g. %word%)
     * @param {number} [champId]
     * @param {number} [year]
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {string} [countryFilter]
     * @param {string} [stateFilter]
     * @param {string} [cityFilter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminOfficialClubGet(
      limit?: number,
      start?: number,
      query?: string,
      champId?: number,
      year?: number,
      sortingFilter?: SortingFilterEnum,
      countryFilter?: string,
      stateFilter?: string,
      cityFilter?: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClubAdminListModel>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminOfficialClubGet(
        limit,
        start,
        query,
        champId,
        year,
        sortingFilter,
        countryFilter,
        stateFilter,
        cityFilter,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Add new club
     * @summary Add new club
     * @param {ClubAdminAddModel} [clubAdminAddModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminOfficialClubPost(
      clubAdminAddModel?: ClubAdminAddModel,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClubAdminModel>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminOfficialClubPost(clubAdminAddModel, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Is used to get club data by its id
     * @summary Get club info by id
     * @param {number} id id of club
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminOfficialClubQueryGet(
      id: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClubAdminModel>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminOfficialClubQueryGet(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Updates club
     * @summary Update club
     * @param {ClubAdminModel} [clubAdminModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminOfficialClubUpdatePost(
      clubAdminModel?: ClubAdminModel,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminOfficialClubUpdatePost(clubAdminModel, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Returns list of points of contact
     * @summary Get list of points of contact
     * @param {number} [limit]
     * @param {number} [start]
     * @param {string} [query] Expression to filter entries (e.g. %word%)
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminOfficialPocGet(
      limit?: number,
      start?: number,
      query?: string,
      sortingFilter?: SortingFilterEnum,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PointOfContactAdminListModel>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminOfficialPocGet(limit, start, query, sortingFilter, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Add new point of contact
     * @summary Add new point of contact
     * @param {PointOfContactAdminAddModel} [pointOfContactAdminAddModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminOfficialPocPost(
      pointOfContactAdminAddModel?: PointOfContactAdminAddModel,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PointOfContactAdminModel>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminOfficialPocPost(pointOfContactAdminAddModel, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Is used to get poc data by its id
     * @summary Get poc info by id
     * @param {number} id id of poc
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminOfficialPocQueryGet(
      id: number,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PointOfContactAdminModel>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminOfficialPocQueryGet(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Updates point of contact
     * @summary Update point of contact
     * @param {PointOfContactAdminEditModel} [pointOfContactAdminEditModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminOfficialPocUpdatePost(
      pointOfContactAdminEditModel?: PointOfContactAdminEditModel,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PointOfContactAdminEditModel>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminOfficialPocUpdatePost(pointOfContactAdminEditModel, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Qualifying Site Player List
     * @param {number} qualifyingSiteId id of Qualifying Site
     * @param {QualifyingSiteReportListType} listType type of list to get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminOfficialQualifyingSitePlayerListGet(
      qualifyingSiteId: number,
      listType: QualifyingSiteReportListType,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminOfficialQualifyingSitePlayerListGet(qualifyingSiteId, listType, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Checks whether it is possible to create a \'QualifyingSite\' with such data.
     * @summary Check if a combination of ChampId+Level+Number is still free
     * @param {number} champId
     * @param {string} level
     * @param {number} number
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminOfficialQualifyingsiteCheckGet(
      champId: number,
      level: string,
      number: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminOfficialQualifyingsiteCheckGet(champId, level, number, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Returns list of qualifying sites
     * @summary Get list of qualifying sites by championship Id
     * @param {number} champId
     * @param {number} [limit]
     * @param {number} [start]
     * @param {QualifyingSiteFilters} [filter]
     * @param {string} [filterValue]
     * @param {string} [startDate] Optional start date range
     * @param {string} [endDate] Optional end date range
     * @param {string} [query]
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminOfficialQualifyingsiteGet(
      champId: number,
      limit?: number,
      start?: number,
      filter?: QualifyingSiteFilters,
      filterValue?: string,
      startDate?: string,
      endDate?: string,
      query?: string,
      sortingFilter?: SortingFilterEnum,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<QualifyingSiteAdminListModel>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminOfficialQualifyingsiteGet(
        champId,
        limit,
        start,
        filter,
        filterValue,
        startDate,
        endDate,
        query,
        sortingFilter,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Add new qualifying site to championship
     * @summary Add new qualifying site to championship
     * @param {QualifyingSiteAddModel} [qualifyingSiteAddModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminOfficialQualifyingsitePost(
      qualifyingSiteAddModel?: QualifyingSiteAddModel,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<QualifyingSiteAdminModel>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminOfficialQualifyingsitePost(qualifyingSiteAddModel, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Is used to get qualifying site data by its id
     * @summary Get qualifying site info by id
     * @param {number} id id of qualifying site
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminOfficialQualifyingsiteQueryGet(
      id: number,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<QualifyingSiteAdminModel>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminOfficialQualifyingsiteQueryGet(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Updates QS
     * @summary Update qualifying site
     * @param {QualifyingSiteEditModel} [qualifyingSiteEditModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminOfficialQualifyingsiteUpdatePost(
      qualifyingSiteEditModel?: QualifyingSiteEditModel,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminOfficialQualifyingsiteUpdatePost(qualifyingSiteEditModel, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Download report by access key
     * @summary Download report by access key
     * @param {string} id
     * @param {boolean} [inline] Optional flag, set to true in order to get inlined response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminOfficialReportsDownloadGet(
      id: string,
      inline?: boolean,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminOfficialReportsDownloadGet(id, inline, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Is used to get saved user\'s data of specific championship
     * @summary Get player application
     * @param {number} champId Id of championship to return application data for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminPlayerApplicationGet(
      champId: number,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResponseModel>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminPlayerApplicationGet(champId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Saves or updates player application
     * @summary Save player application
     * @param {number} [appId] id of another player to form team with
     * @param {ApplicationPlayerModel} [applicationPlayerModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminPlayerApplicationPost(
      appId?: number,
      applicationPlayerModel?: ApplicationPlayerModel,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResponseModel>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminPlayerApplicationPost(appId, applicationPlayerModel, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get application data by id
     * @param {number} appId id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminPlayerApplicationQueryGet(
      appId: number,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<ApplicationPaymentHistoryResponseModel>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminPlayerApplicationQueryGet(appId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Is used to get simplified application data
     * @summary Get basic player application data (for four-ball)
     * @param {number} appId id of application to return application data for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminPlayerApplicationSimplifiedGet(
      appId: number,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationAnonResponseModel>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminPlayerApplicationSimplifiedGet(appId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Upload Application Files
     * @param {number} champId Id of championship we uploading data for
     * @param {UploadedFileType} fileType
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminPlayerApplicationUploadPost(
      champId: number,
      fileType: UploadedFileType,
      file?: any,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminPlayerApplicationUploadPost(champId, fileType, file, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Is used to get a list of applied applications.
     * @summary Returns list of apps player has applied to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminPlayerApplicationWithdrawGet(
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationAppliedListModel>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminPlayerApplicationWithdrawGet(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Submit withdrawal request
     * @summary Submit withdrawal request
     * @param {ApplicationWithdrawRequestModel} [applicationWithdrawRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminPlayerApplicationWithdrawPost(
      applicationWithdrawRequestModel?: ApplicationWithdrawRequestModel,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationWithdrawResponseModel>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminPlayerApplicationWithdrawPost(applicationWithdrawRequestModel, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Returns list of applications for player
     * @summary Get filtered list of applications for player history
     * @param {WorkflowTypeEnum} [workflowType] Optional filter by championship type
     * @param {number} [year] Championship year
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {number} [champTypeId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminPlayerApplicationsHistoryGet(
      workflowType?: WorkflowTypeEnum,
      year?: number,
      sortingFilter?: SortingFilterEnum,
      champTypeId?: number,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationHistoryListModel>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminPlayerApplicationsHistoryGet(
        workflowType,
        year,
        sortingFilter,
        champTypeId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Returns list of ids for championships with existing applications in status not DRAFT
     * @summary Get list of paid championships
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminPlayerChampionshipsGet(
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChampionshipPlayerInfoModel>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminPlayerChampionshipsGet(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Get status list of requested subscriptions
     * @summary Get status list of requested subscriptions
     * @param {Array<EmailSubscriptionType>} [types]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminPlayerEmailSubscriptionsGet(
      types?: Array<EmailSubscriptionType>,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailSubscriptionsStatusList>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminPlayerEmailSubscriptionsGet(types, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Update subscriptions
     * @summary Update subscriptions
     * @param {EmailSubscriptionsRequestModel} [emailSubscriptionsRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminPlayerEmailSubscriptionsPost(
      emailSubscriptionsRequestModel?: EmailSubscriptionsRequestModel,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminPlayerEmailSubscriptionsPost(emailSubscriptionsRequestModel, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Is used to check availability of the handicap service.
     * @summary Check handicap service.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminPlayerHandicapCheckGet(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminPlayerHandicapCheckGet(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get handicap player by handicap id
     * @param {string} handicapId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminPlayerHandicapGet(
      handicapId: string,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HandicapPlayerModel>>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminPlayerHandicapGet(handicapId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Returns list of messages
     * @summary Get list of messages by current user Id
     * @param {number} [limit]
     * @param {number} [start]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminPlayerMessageGet(
      limit?: number,
      start?: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessagePlayerListModel>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminPlayerMessageGet(limit, start, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Hide message for player
     * @summary Hide specific message for particular player
     * @param {MessageHideRequestModel} [messageHideRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminPlayerMessageHidePost(
      messageHideRequestModel?: MessageHideRequestModel,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminPlayerMessageHidePost(messageHideRequestModel, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Get form data to make post request to Payment processor
     * @param {number} appId Application id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminPlayerPaymentGet(
      appId: number,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentDataPlayerResponseModel>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminPlayerPaymentGet(appId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Is used to get profile data
     * @summary Get player profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminPlayerProfileGet(
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LinkedProfilePlayerResponseModel>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminPlayerProfileGet(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Accept request to manage profile
     * @summary Accept request to manage profile
     * @param {ProfileLinkRequestModel} [profileLinkRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminPlayerProfileLinkAcceptPost(
      profileLinkRequestModel?: ProfileLinkRequestModel,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminPlayerProfileLinkAcceptPost(profileLinkRequestModel, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Send request to start impersonation session
     * @summary Start impersonation session
     * @param {ProfileLinkRequestModel} [profileLinkRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminPlayerProfileLinkImpersonationPost(
      profileLinkRequestModel?: ProfileLinkRequestModel,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImpersonationCookieModel>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminPlayerProfileLinkImpersonationPost(profileLinkRequestModel, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Send request to stop impersonation session
     * @summary Stop impersonation session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminPlayerProfileLinkImpersonationStopPost(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagePathModel>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminPlayerProfileLinkImpersonationStopPost(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Impersonation check
     * @summary Return impersonator profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminPlayerProfileLinkImpersonatorGet(
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<ProfilePlayerPersonalDataWithIdResponseModel>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminPlayerProfileLinkImpersonatorGet(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Update player profile
     * @summary Update managed profile
     * @param {ProfilePlayerResponseModel} [profilePlayerResponseModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminPlayerProfileLinkManagePost(
      profilePlayerResponseModel?: ProfilePlayerResponseModel,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfilePlayerResponseModel>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminPlayerProfileLinkManagePost(profilePlayerResponseModel, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Send request to add managed profile
     * @summary Send request to add managed profile
     * @param {EmailModel} [emailModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminPlayerProfileLinkRequestPost(
      emailModel?: EmailModel,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminPlayerProfileLinkRequestPost(emailModel, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Updates player profile
     * @summary Save/update player profile
     * @param {ProfilePlayerRequestModel} [profilePlayerRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminPlayerProfilePost(
      profilePlayerRequestModel?: ProfilePlayerRequestModel,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LinkedProfilePlayerResponseModel>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminPlayerProfilePost(profilePlayerRequestModel, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Accept request to manage profile
     * @summary Unlink player profile
     * @param {ProfileUnlinkRequestModel} [profileUnlinkRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminPlayerProfileUnlinkPost(
      profileUnlinkRequestModel?: ProfileUnlinkRequestModel,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminPlayerProfileUnlinkPost(profileUnlinkRequestModel, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Is used to get qualifying site data by its id
     * @summary Get qualifying site info by id
     * @param {number} id id of qualifying site
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminPlayerQualifyingsiteQueryGet(
      id: number,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<QualifyingSitePlayerFullModel>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminPlayerQualifyingsiteQueryGet(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Is used to Release all Reserved spot data
     * @summary Release All Reserves a spot
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminPlayerReleaseAllReserveSpotGet(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminPlayerReleaseAllReserveSpotGet(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Is used to Release Reserved spot data by its id
     * @summary Release Reserves a spot info by id
     * @param {number} id id of reserved slot
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminPlayerReleaseReserveSpotGet(
      id: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminPlayerReleaseReserveSpotGet(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Edit a reserved spot
     * @param {ReserveSpotEditModel} [reserveSpotEditModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminPlayerReserveSpotEditPost(
      reserveSpotEditModel?: ReserveSpotEditModel,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReserveSpotResponseModel>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminPlayerReserveSpotEditPost(reserveSpotEditModel, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Reserves a spot
     * @param {ReserveSpotCreateModel} [reserveSpotCreateModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminPlayerReserveSpotPost(
      reserveSpotCreateModel?: ReserveSpotCreateModel,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReserveSpotResponseModel>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminPlayerReserveSpotPost(reserveSpotCreateModel, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get list of upcoming events
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminPlayerUpcomingEventsGet(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpcomingEventListModel>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminPlayerUpcomingEventsGet(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary AR Request for player
     * @param {ARPlayerRequestModel} [aRPlayerRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminSecureArequestPlayerPost(
      aRPlayerRequestModel?: ARPlayerRequestModel,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminSecureArequestPlayerPost(aRPlayerRequestModel, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Download list of applications
     * @summary Download filtered list of applications
     * @param {number} year Championship year
     * @param {ApplicationFilters} filter
     * @param {number} [champId] Optional filter by championship id
     * @param {number} [limit]
     * @param {number} [start]
     * @param {string} [query] Optional full text search query
     * @param {string} [filterValue]
     * @param {IdListModel} [idListModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminStaffApplicationsDownloadPost(
      year: number,
      filter: ApplicationFilters,
      champId?: number,
      limit?: number,
      start?: number,
      query?: string,
      filterValue?: string,
      idListModel?: IdListModel,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminStaffApplicationsDownloadPost(
        year,
        filter,
        champId,
        limit,
        start,
        query,
        filterValue,
        idListModel,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Returns list of applications
     * @summary Get filtered list of applications
     * @param {number} year Championship year
     * @param {ApplicationFilters} filter
     * @param {number} [champId] Optional filter by championship id
     * @param {number} [limit]
     * @param {number} [start]
     * @param {string} [query] Optional full text search query
     * @param {string} [disabilityTypeFilter]
     * @param {string} [filterValue]
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminStaffApplicationsGet(
      year: number,
      filter: ApplicationFilters,
      champId?: number,
      limit?: number,
      start?: number,
      query?: string,
      disabilityTypeFilter?: string,
      filterValue?: string,
      sortingFilter?: SortingFilterEnum,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationAdminListModel>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminStaffApplicationsGet(
        year,
        filter,
        champId,
        limit,
        start,
        query,
        disabilityTypeFilter,
        filterValue,
        sortingFilter,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Returns list of Champiobship Types
     * @summary Get list of Champ-types
     * @param {number} [limit]
     * @param {number} [start]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminStaffChampTypeListGet(
      limit?: number,
      start?: number,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChampTypeTitleIdListModel>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminStaffChampTypeListGet(limit, start, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Downloading reports
     * @summary Downloading ZIP file with championship reports
     * @param {number} champId
     * @param {StaffChampionshipReportsTypeEnum} reportType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminStaffChampionshipReportDownloadGet(
      champId: number,
      reportType: StaffChampionshipReportsTypeEnum,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminStaffChampionshipReportDownloadGet(champId, reportType, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Returns a list of championships based on assigned championship types.
     * @summary Get championships list based on assigned championship types.
     * @param {string} [query]
     * @param {number} [year]
     * @param {WorkflowTypeEnum} [champType]
     * @param {number} [champTypeId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminStaffChampionshipsGet(
      query?: string,
      year?: number,
      champType?: WorkflowTypeEnum,
      champTypeId?: number,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffChampionshipListModel>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminStaffChampionshipsGet(query, year, champType, champTypeId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Returns a list of championships based on assigned championship types.
     * @summary [GraphQL] Get championships list based on assigned championship types.
     * @param {string} [query]
     * @param {number} [year]
     * @param {WorkflowTypeEnum} [champType]
     * @param {number} [champTypeId]
     * @param {Array<string>} [fields] required fields to fetch, e.g. \&quot;aaa\&quot;, \&quot;bbb.ccc.ddd\&quot;
     * @param {boolean} [active] indicates if champ is active
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminStaffChampionshipsGraphqlGet(
      query?: string,
      year?: number,
      champType?: WorkflowTypeEnum,
      champTypeId?: number,
      fields?: Array<string>,
      active?: boolean,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminStaffChampionshipsGraphqlGet(
        query,
        year,
        champType,
        champTypeId,
        fields,
        active,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Is used to get championship data by its id
     * @summary Get championship info by id
     * @param {number} id id of championship
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminStaffChampionshipsQueryGet(
      id: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChampionshipAdminModel>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminStaffChampionshipsQueryGet(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Returns list of exemptions
     * @summary Get list of exemptions by championship Id
     * @param {number} champId
     * @param {number} [limit]
     * @param {number} [start]
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {QualifyingLevelEnum} [filter] Optional Filter
     * @param {string} [query] Optional search query parameter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminStaffExemptionGet(
      champId: number,
      limit?: number,
      start?: number,
      sortingFilter?: SortingFilterEnum,
      filter?: QualifyingLevelEnum,
      query?: string,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExemptionAdminListModel>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminStaffExemptionGet(
        champId,
        limit,
        start,
        sortingFilter,
        filter,
        query,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Download file by id
     * @summary Download file by id
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminStaffFileGet(
      id: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminStaffFileGet(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Returns Champiobship Type by id
     * @summary Get Applications Pairs by ChampId
     * @param {number} champId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminStaffPairGet(
      champId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PairListResponseModel>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminStaffPairGet(champId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Returns list of pairs
     * @summary Get filtered list of pairs
     * @param {number} limit
     * @param {number} start
     * @param {number} playerId
     * @param {string} [query] Optional search query
     * @param {PairSortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminStaffPairHistoryGet(
      limit: number,
      start: number,
      playerId: number,
      query?: string,
      sortingFilter?: PairSortingFilterEnum,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PairHistoryListModel>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminStaffPairHistoryGet(limit, start, playerId, query, sortingFilter, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Create/Update Applications Pairs
     * @param {PairListRequestModel} [pairListRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminStaffPairPost(
      pairListRequestModel?: PairListRequestModel,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminStaffPairPost(pairListRequestModel, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Returns list of qualifying sites
     * @summary Get list of qualifying sites by championship Id
     * @param {number} champId
     * @param {number} [limit]
     * @param {number} [start]
     * @param {QualifyingSiteFilters} [filter]
     * @param {string} [filterValue]
     * @param {string} [startDate] Optional start date range
     * @param {string} [endDate] Optional end date range
     * @param {string} [query]
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminStaffQualifyingsiteGet(
      champId: number,
      limit?: number,
      start?: number,
      filter?: QualifyingSiteFilters,
      filterValue?: string,
      startDate?: string,
      endDate?: string,
      query?: string,
      sortingFilter?: SortingFilterEnum,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<QualifyingSiteAdminListModel>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminStaffQualifyingsiteGet(
        champId,
        limit,
        start,
        filter,
        filterValue,
        startDate,
        endDate,
        query,
        sortingFilter,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Download customer service report
     * @summary Red Book report
     * @param {number} champId Championship ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminStaffRedbookReportGet(
      champId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminStaffRedbookReportGet(champId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Download report by access key
     * @summary Download report by access key
     * @param {string} id
     * @param {boolean} [inline] Optional flag, set to true in order to get inlined response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminStaffReportsDownloadGet(
      id: string,
      inline?: boolean,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).binChampadminStaffReportsDownloadGet(id, inline, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Returns Back To Official Home Page Button Component
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async champAdminComponentsContentOfficialsBackToHomePageButtonModelJsonGet(
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BackToListOfficialHomeModel>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).champAdminComponentsContentOfficialsBackToHomePageButtonModelJsonGet(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Dummy
     * @summary Dummy
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async champAdminComponentsDummyFetchModelsGet(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2002>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).champAdminComponentsDummyFetchModelsGet(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Dummy
     * @summary Dummy
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async champAdminComponentsDummyLinkerGet(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).champAdminComponentsDummyLinkerGet(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Dummy
     * @summary Dummy
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async champAdminComponentsDummyValidateLinkerGet(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2001>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).champAdminComponentsDummyValidateLinkerGet(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Dummy
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async champAdminServiceWaitlistResponeDummyLinkerGet(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WaitListResponseModel>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).champAdminServiceWaitlistResponeDummyLinkerGet(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Get list of available options for Resend Email dropdown
     * @summary Get list of available options for Resend Email dropdown
     * @param {number} appId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBinChampadminAdminEmailResend(
      appId: number,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResendEmailFilterEnum>>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).getBinChampadminAdminEmailResend(appId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * get-bin-champadmin-admin-lastmod
     * @summary Get last modified info
     * @param {EntitiesTypeEnum} entityName
     * @param {Array<number>} idList
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBinChampadminAdminLastmod(
      entityName: EntitiesTypeEnum,
      idList: Array<number>,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LastModifiedResponseModel>>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).getBinChampadminAdminLastmod(entityName, idList, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Upload Championship PDF Files
     * @param {number} champId Id of championship we uploading data for
     * @param {UploadedPdfFileType} fileType
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postBinChampadminAdminChampionshipPdfUpload(
      champId: number,
      fileType: UploadedPdfFileType,
      file?: any,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).postBinChampadminAdminChampionshipPdfUpload(champId, fileType, file, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Upload Championship Results
     * @param {number} champId Id of championship we uploading data for
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postBinChampadminAdminChampionshipScoringUpload(
      champId: number,
      file?: any,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).postBinChampadminAdminChampionshipScoringUpload(champId, file, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Upload Championship Violation data
     * @param {number} champId Id of championship we uploading data for
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postBinChampadminAdminChampionshipViolationUpload(
      champId: number,
      file?: any,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).postBinChampadminAdminChampionshipViolationUpload(champId, file, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Upload Final Qualifying Results (.xls files)
     * @param {number} champId Id of championship we uploading data for
     * @param {number} [qsId] Id of qualifying site we uploading data for
     * @param {boolean} [isAgaPage] Optional flag, identify when request from AGA page
     * @param {string} [scoringUrl] Optional field(required on AGA page), for QS scoringUrl
     * @param {string} [courseRating] Optional field(required on AGA page), for QS courseRating
     * @param {string} [expertCertifiedRulesName] Optional field, for QS expertCertifiedRulesName, used on AGA page
     * @param {boolean} [expertCertifiedRules] Optional field, for QS expertCertifiedRules, used on AGA page
     * @param {boolean} [strokePolicyViolations] Optional field, for QS strokePolicyViolations, used on AGA page
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postBinChampadminOfficialQualifyingFinalScoringUpload(
      champId: number,
      qsId?: number,
      isAgaPage?: boolean,
      scoringUrl?: string,
      courseRating?: string,
      expertCertifiedRulesName?: string,
      expertCertifiedRules?: boolean,
      strokePolicyViolations?: boolean,
      file?: any,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).postBinChampadminOfficialQualifyingFinalScoringUpload(
        champId,
        qsId,
        isAgaPage,
        scoringUrl,
        courseRating,
        expertCertifiedRulesName,
        expertCertifiedRules,
        strokePolicyViolations,
        file,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Upload Local Qualifying Results (.xls files)
     * @param {number} champId Id of championship we uploading data for
     * @param {number} [qsId] Id of qualifying site we uploading data for
     * @param {boolean} [isAgaPage] Optional flag, identify when request from AGA page
     * @param {string} [scoringUrl] Optional field(required on AGA page), for QS scoringUrl
     * @param {string} [courseRating] Optional field(required on AGA page), for QS courseRating
     * @param {string} [expertCertifiedRulesName] Optional field, for QS expertCertifiedRulesName, used on AGA page
     * @param {boolean} [expertCertifiedRules] Optional field, for QS expertCertifiedRules, used on AGA page
     * @param {boolean} [strokePolicyViolations] Optional field, for QS strokePolicyViolations, used on AGA page
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postBinChampadminOfficialQualifyingLocalScoringUpload(
      champId: number,
      qsId?: number,
      isAgaPage?: boolean,
      scoringUrl?: string,
      courseRating?: string,
      expertCertifiedRulesName?: string,
      expertCertifiedRules?: boolean,
      strokePolicyViolations?: boolean,
      file?: any,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).postBinChampadminOfficialQualifyingLocalScoringUpload(
        champId,
        qsId,
        isAgaPage,
        scoringUrl,
        courseRating,
        expertCertifiedRulesName,
        expertCertifiedRules,
        strokePolicyViolations,
        file,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Upload Qualifying Results (.xls files)
     * @param {number} champId Id of championship we uploading data for
     * @param {number} [qsId] Id of qualifying site we uploading data for
     * @param {boolean} [isAgaPage] Optional flag, identify when request from AGA page
     * @param {string} [scoringUrl] Optional field(required on AGA page), for QS scoringUrl
     * @param {string} [courseRating] Optional field(required on AGA page), for QS courseRating
     * @param {string} [expertCertifiedRulesName] Optional field, for QS expertCertifiedRulesName, used on AGA page
     * @param {boolean} [expertCertifiedRules] Optional field, for QS expertCertifiedRules, used on AGA page
     * @param {boolean} [strokePolicyViolations] Optional field, for QS strokePolicyViolations, used on AGA page
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postBinChampadminOfficialQualifyingScoringUpload(
      champId: number,
      qsId?: number,
      isAgaPage?: boolean,
      scoringUrl?: string,
      courseRating?: string,
      expertCertifiedRulesName?: string,
      expertCertifiedRules?: boolean,
      strokePolicyViolations?: boolean,
      file?: any,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration
      ).postBinChampadminOfficialQualifyingScoringUpload(
        champId,
        qsId,
        isAgaPage,
        scoringUrl,
        courseRating,
        expertCertifiedRulesName,
        expertCertifiedRules,
        strokePolicyViolations,
        file,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Sendgrid send mail EP
     * @param {EmailTemplateWithAttachedFilesModel} [emailTemplateWithAttachedFilesModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiSendgridComV3MailSendPost(
      emailTemplateWithAttachedFilesModel?: EmailTemplateWithAttachedFilesModel,
      options?: any
    ): AxiosPromise<void> {
      return DefaultApiFp(configuration)
        .apiSendgridComV3MailSendPost(emailTemplateWithAttachedFilesModel, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Delete AGA by id
     * @summary Remove AGA by its id
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminAgaByIdDelete(id: number, options?: any): AxiosPromise<void> {
      return DefaultApiFp(configuration)
        .binChampadminAdminAgaByIdDelete(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns AGA by Id
     * @summary Get AGA by Id
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminAgaByIdGet(id: number, options?: any): AxiosPromise<AgaEditModel> {
      return DefaultApiFp(configuration)
        .binChampadminAdminAgaByIdGet(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns list of AGA
     * @summary Get list of AGA
     * @param {number} [limit]
     * @param {number} [start]
     * @param {string} [query] Optional search query to filter out AGA by names.
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminAgaGet(
      limit?: number,
      start?: number,
      query?: string,
      sortingFilter?: SortingFilterEnum,
      options?: any
    ): AxiosPromise<AgaAdminListModel> {
      return DefaultApiFp(configuration)
        .binChampadminAdminAgaGet(limit, start, query, sortingFilter, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Submit new AGA
     * @summary Save new AGA
     * @param {AgaBaseModel} [agaBaseModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminAgaPost(
      agaBaseModel?: AgaBaseModel,
      options?: any
    ): AxiosPromise<AgaEditModel> {
      return DefaultApiFp(configuration)
        .binChampadminAdminAgaPost(agaBaseModel, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Updates AGA entry
     * @summary Update AGA
     * @param {AgaEditModel} [agaEditModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminAgaUpdatePost(
      agaEditModel?: AgaEditModel,
      options?: any
    ): AxiosPromise<AgaEditModel> {
      return DefaultApiFp(configuration)
        .binChampadminAdminAgaUpdatePost(agaEditModel, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns list of AGA
     * @summary Get filtered list of AGA Users
     * @param {number} [limit]
     * @param {number} [start]
     * @param {string} [query] Expression to filter entries (e.g. %word%)
     * @param {number} [champId]
     * @param {number} [year]
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminAgaUsersListGet(
      limit?: number,
      start?: number,
      query?: string,
      champId?: number,
      year?: number,
      sortingFilter?: SortingFilterEnum,
      options?: any
    ): AxiosPromise<AgaUserAdminListModel> {
      return DefaultApiFp(configuration)
        .binChampadminAdminAgaUsersListGet(
          limit,
          start,
          query,
          champId,
          year,
          sortingFilter,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Saves or updates player application
     * @summary Create new player application
     * @param {string} auth0Id Auth0Id of player to create application for
     * @param {ApplicationProgressionEnum} applicationStatus Target Application Status
     * @param {number} [appId] id of another player app to form team with
     * @param {ApplicationPlayerModel} [applicationPlayerModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminApplicationCreatePost(
      auth0Id: string,
      applicationStatus: ApplicationProgressionEnum,
      appId?: number,
      applicationPlayerModel?: ApplicationPlayerModel,
      options?: any
    ): AxiosPromise<ApplicationResponseModel> {
      return DefaultApiFp(configuration)
        .binChampadminAdminApplicationCreatePost(
          auth0Id,
          applicationStatus,
          appId,
          applicationPlayerModel,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Is used to get application data
     * @summary Get player application
     * @param {number} appId id of application to return application data for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminApplicationGet(
      appId: number,
      options?: any
    ): AxiosPromise<ApplicationPaymentHistoryAdminResponseModel> {
      return DefaultApiFp(configuration)
        .binChampadminAdminApplicationGet(appId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Updates player application
     * @summary Save player application
     * @param {ApplicationAdminRequestModel} [applicationAdminRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminApplicationPost(
      applicationAdminRequestModel?: ApplicationAdminRequestModel,
      options?: any
    ): AxiosPromise<ApplicationAdminResponseModel> {
      return DefaultApiFp(configuration)
        .binChampadminAdminApplicationPost(applicationAdminRequestModel, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get championship\'s applications summary
     * @param {number} champId id of championship to return applications summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminApplicationSummaryGet(
      champId: number,
      options?: any
    ): AxiosPromise<ApplicationAdminSummaryModel> {
      return DefaultApiFp(configuration)
        .binChampadminAdminApplicationSummaryGet(champId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns status that indicates was application updated or not
     * @summary Re-Validate application reinstatement
     * @param {number} id Id to verify by
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminApplicationValidateARGet(
      id: number,
      options?: any
    ): AxiosPromise<ReinstatementValidationModel> {
      return DefaultApiFp(configuration)
        .binChampadminAdminApplicationValidateARGet(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns multi filtered list of applications
     * @summary Get filtered list of applications
     * @param {number} champId Filter by championship id
     * @param {number} [limit]
     * @param {number} [start]
     * @param {string} [query] Optional full text search query
     * @param {Array<BlacklistStatusEnum>} [blacklistStatus]
     * @param {Array<ApplicationProgressionEnum>} [applicationStatus]
     * @param {Array<number>} [qualifyingSiteIds]
     * @param {Array<number>} [exemptionIds]
     * @param {QualifyingStatusEnum} [qualifyingStatus]
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminApplicationsBulkWithdrawGet(
      champId: number,
      limit?: number,
      start?: number,
      query?: string,
      blacklistStatus?: Array<BlacklistStatusEnum>,
      applicationStatus?: Array<ApplicationProgressionEnum>,
      qualifyingSiteIds?: Array<number>,
      exemptionIds?: Array<number>,
      qualifyingStatus?: QualifyingStatusEnum,
      sortingFilter?: SortingFilterEnum,
      options?: any
    ): AxiosPromise<ApplicationAdminListModel> {
      return DefaultApiFp(configuration)
        .binChampadminAdminApplicationsBulkWithdrawGet(
          champId,
          limit,
          start,
          query,
          blacklistStatus,
          applicationStatus,
          qualifyingSiteIds,
          exemptionIds,
          qualifyingStatus,
          sortingFilter,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns list of applications
     * @summary Get filtered list of applications for custom email
     * @param {number} [limit]
     * @param {number} [start]
     * @param {number} [year] Optional Filter by year id
     * @param {number} [champId] Optional Filter by championship id
     * @param {string} [query] Optional First/Last Name search query
     * @param {string} [startDate] Optional start qualifying date range
     * @param {string} [endDate] Optional end qualifying date range
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {PlayerStatusEnum} [playerStatus] Optional Player Status Filter
     * @param {ApplicationProgressionEnum} [applicationStatus] Optional Application Status Filter
     * @param {QualifyingStatusEnum} [qualifyingStatus] Optional Application Status Filter
     * @param {string} [wagrRank] Optional WAGR Rank Filter
     * @param {boolean} [notablePlayer] Optional Notable Player Filter
     * @param {boolean} [adaRequest] Optional ADA Request Filter
     * @param {BlacklistStatusEnum} [blacklist] Optional Blacklist Filter
     * @param {boolean} [watchlist] Optional Watchlist Filter
     * @param {number} [age] Optional Age Filter
     * @param {string} [handicapIndex] Optional Handicap Index Filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminApplicationsCustomEmailGet(
      limit?: number,
      start?: number,
      year?: number,
      champId?: number,
      query?: string,
      startDate?: string,
      endDate?: string,
      sortingFilter?: SortingFilterEnum,
      playerStatus?: PlayerStatusEnum,
      applicationStatus?: ApplicationProgressionEnum,
      qualifyingStatus?: QualifyingStatusEnum,
      wagrRank?: string,
      notablePlayer?: boolean,
      adaRequest?: boolean,
      blacklist?: BlacklistStatusEnum,
      watchlist?: boolean,
      age?: number,
      handicapIndex?: string,
      options?: any
    ): AxiosPromise<ApplicationAdminListModel> {
      return DefaultApiFp(configuration)
        .binChampadminAdminApplicationsCustomEmailGet(
          limit,
          start,
          year,
          champId,
          query,
          startDate,
          endDate,
          sortingFilter,
          playerStatus,
          applicationStatus,
          qualifyingStatus,
          wagrRank,
          notablePlayer,
          adaRequest,
          blacklist,
          watchlist,
          age,
          handicapIndex,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns list of applications
     * @summary Get filtered list of applications for final confirmation
     * @param {number} champId Filter by championship id
     * @param {ApplicationFinalConfirmationBulkFilterEnum} playerFilter Plyer filter
     * @param {number} [limit]
     * @param {number} [start]
     * @param {string} [query] Optional First/Last Name search query
     * @param {string} [startDate] Optional start qualifying date range
     * @param {string} [endDate] Optional end qualifying date range
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminApplicationsFinalConfirmationGet(
      champId: number,
      playerFilter: ApplicationFinalConfirmationBulkFilterEnum,
      limit?: number,
      start?: number,
      query?: string,
      startDate?: string,
      endDate?: string,
      sortingFilter?: SortingFilterEnum,
      options?: any
    ): AxiosPromise<ApplicationAdminListModel> {
      return DefaultApiFp(configuration)
        .binChampadminAdminApplicationsFinalConfirmationGet(
          champId,
          playerFilter,
          limit,
          start,
          query,
          startDate,
          endDate,
          sortingFilter,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns list of applications
     * @summary Get filtered list of applications
     * @param {number} year Championship year
     * @param {ApplicationFilters} filter
     * @param {number} [champId] Optional filter by championship id
     * @param {number} [limit]
     * @param {number} [start]
     * @param {string} [query] Optional full text search query
     * @param {string} [filterValue]
     * @param {string} [disabilityTypeFilter] Disability Type
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminApplicationsGet(
      year: number,
      filter: ApplicationFilters,
      champId?: number,
      limit?: number,
      start?: number,
      query?: string,
      filterValue?: string,
      disabilityTypeFilter?: string,
      sortingFilter?: SortingFilterEnum,
      options?: any
    ): AxiosPromise<ApplicationLightweightAdminListModel> {
      return DefaultApiFp(configuration)
        .binChampadminAdminApplicationsGet(
          year,
          filter,
          champId,
          limit,
          start,
          query,
          filterValue,
          disabilityTypeFilter,
          sortingFilter,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns list of applications for player
     * @summary Get filtered list of applications for player history
     * @param {number} playerId filter by player id
     * @param {number} [champId] Optional filter by championship id
     * @param {string} [query] Optional full text search query
     * @param {number} [year] Championship year
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminApplicationsHistoryGet(
      playerId: number,
      champId?: number,
      query?: string,
      year?: number,
      options?: any
    ): AxiosPromise<ApplicationHistoryListModel> {
      return DefaultApiFp(configuration)
        .binChampadminAdminApplicationsHistoryGet(playerId, champId, query, year, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns list of applications
     * @summary Get filtered list of applications for new email
     * @param {number} champId Filter by championship id
     * @param {number} [limit]
     * @param {number} [start]
     * @param {string} [query] Optional First/Last Name search query
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {PlayerStatusEnum} [playerStatus] Optional Player Status Filter
     * @param {ApplicationProgressionEnum} [applicationStatus] Optional Application Status Filter
     * @param {QualifyingStatusEnum} [qualifyingStatus] Optional Application Status Filter
     * @param {string} [wagrRank] Optional WAGR Rank Filter
     * @param {boolean} [notablePlayer] Optional Notable Player Filter
     * @param {boolean} [adaRequest] Optional ADA Request Filter
     * @param {BlacklistStatusEnum} [blacklist] Optional Blacklist Filter
     * @param {boolean} [watchlist] Optional Watchlist Filter
     * @param {number} [age] Optional Age Filter
     * @param {string} [handicapIndex] Optional Handicap Index Filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminApplicationsNewEmailGet(
      champId: number,
      limit?: number,
      start?: number,
      query?: string,
      sortingFilter?: SortingFilterEnum,
      playerStatus?: PlayerStatusEnum,
      applicationStatus?: ApplicationProgressionEnum,
      qualifyingStatus?: QualifyingStatusEnum,
      wagrRank?: string,
      notablePlayer?: boolean,
      adaRequest?: boolean,
      blacklist?: BlacklistStatusEnum,
      watchlist?: boolean,
      age?: number,
      handicapIndex?: string,
      options?: any
    ): AxiosPromise<ApplicationAdminListModel> {
      return DefaultApiFp(configuration)
        .binChampadminAdminApplicationsNewEmailGet(
          champId,
          limit,
          start,
          query,
          sortingFilter,
          playerStatus,
          applicationStatus,
          qualifyingStatus,
          wagrRank,
          notablePlayer,
          adaRequest,
          blacklist,
          watchlist,
          age,
          handicapIndex,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns application
     * @summary Get next filtered application
     * @param {number} year Championship year
     * @param {number} fromId id of current entry to get next from it
     * @param {ApplicationFilters} filter
     * @param {number} [champId] Optional filter by championship id
     * @param {SortingFilterEnum} [sortingFilter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminApplicationsNextGet(
      year: number,
      fromId: number,
      filter: ApplicationFilters,
      champId?: number,
      sortingFilter?: SortingFilterEnum,
      options?: any
    ): AxiosPromise<ApplicationAdminResponseModel> {
      return DefaultApiFp(configuration)
        .binChampadminAdminApplicationsNextGet(
          year,
          fromId,
          filter,
          champId,
          sortingFilter,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns list of applications
     * @summary Get filtered list of applications for qualified and alternate bulk
     * @param {number} champId Filter by championship id
     * @param {number} [limit]
     * @param {number} [start]
     * @param {string} [query] Optional First/Last Name search query
     * @param {string} [startDate] Optional start qualifying date range
     * @param {string} [endDate] Optional end qualifying date range
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {ApplicationFilters} [filter]
     * @param {string} [filterValue]
     * @param {BulkQualifyingStatusEnum} [bulkQualifyingStatus] Optional complex filtering
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminApplicationsQualifiedAlternateBulkGet(
      champId: number,
      limit?: number,
      start?: number,
      query?: string,
      startDate?: string,
      endDate?: string,
      sortingFilter?: SortingFilterEnum,
      filter?: ApplicationFilters,
      filterValue?: string,
      bulkQualifyingStatus?: BulkQualifyingStatusEnum,
      options?: any
    ): AxiosPromise<ApplicationAdminListModel> {
      return DefaultApiFp(configuration)
        .binChampadminAdminApplicationsQualifiedAlternateBulkGet(
          champId,
          limit,
          start,
          query,
          startDate,
          endDate,
          sortingFilter,
          filter,
          filterValue,
          bulkQualifyingStatus,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * List of applications with their respective waitlist positions.
     * @summary Get a list of applications ids with their respective waitlist positions.
     * @param {Array<number>} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminApplicationsWaitlistPositionGet(
      id: Array<number>,
      options?: any
    ): AxiosPromise<ApplicationWaitlistPositionListModel> {
      return DefaultApiFp(configuration)
        .binChampadminAdminApplicationsWaitlistPositionGet(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns list of applications
     * @summary Get filtered list of applications to withdraw incomplete sides
     * @param {number} champId Filter by championship id
     * @param {number} [limit]
     * @param {number} [start]
     * @param {string} [query] Optional First/Last Name search query
     * @param {string} [startDate] Optional start qualifying date range
     * @param {string} [endDate] Optional end qualifying date range
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminApplicationsWithdrawIncompleteBulkGet(
      champId: number,
      limit?: number,
      start?: number,
      query?: string,
      startDate?: string,
      endDate?: string,
      sortingFilter?: SortingFilterEnum,
      options?: any
    ): AxiosPromise<ApplicationAdminListModel> {
      return DefaultApiFp(configuration)
        .binChampadminAdminApplicationsWithdrawIncompleteBulkGet(
          champId,
          limit,
          start,
          query,
          startDate,
          endDate,
          sortingFilter,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Download info file by champ id
     * @summary Get activity log
     * @param {string} id
     * @param {AuditEntityType} entityType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminAuditGet(
      id: string,
      entityType: AuditEntityType,
      options?: any
    ): AxiosPromise<Array<ActivityLogAdminModel>> {
      return DefaultApiFp(configuration)
        .binChampadminAdminAuditGet(id, entityType, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns multi filtered list of applications for bulk entry fee balance
     * @summary Get filtered list of applications for bulk entry fee balance
     * @param {number} [champId] Required championship id
     * @param {number} [limit]
     * @param {number} [start]
     * @param {string} [query] Optional full text search query
     * @param {Array<ApplicationFilters>} [filters]
     * @param {Array<number>} [qsIds]
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminBulkEntryFeeBalanceGet(
      champId?: number,
      limit?: number,
      start?: number,
      query?: string,
      filters?: Array<ApplicationFilters>,
      qsIds?: Array<number>,
      sortingFilter?: SortingFilterEnum,
      options?: any
    ): AxiosPromise<ApplicationLightweightAdminListModel> {
      return DefaultApiFp(configuration)
        .binChampadminAdminBulkEntryFeeBalanceGet(
          champId,
          limit,
          start,
          query,
          filters,
          qsIds,
          sortingFilter,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * List applications to pay EntryFeeBalance
     * @summary ids
     * @param {BulkEntryFeeRequestAdminModel} [bulkEntryFeeRequestAdminModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminBulkEntryFeeBalancePaymentPost(
      bulkEntryFeeRequestAdminModel?: BulkEntryFeeRequestAdminModel,
      options?: any
    ): AxiosPromise<string> {
      return DefaultApiFp(configuration)
        .binChampadminAdminBulkEntryFeeBalancePaymentPost(bulkEntryFeeRequestAdminModel, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns Champiobship Type by id
     * @summary Get Champ-type by id
     * @param {number} id id of championship-type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminChampTypeGet(id: number, options?: any): AxiosPromise<ChampionshipTypeModel> {
      return DefaultApiFp(configuration)
        .binChampadminAdminChampTypeGet(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns list of Champiobship Types
     * @summary Get list of Champ-types
     * @param {number} [limit]
     * @param {number} [start]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminChampTypeListGet(
      limit?: number,
      start?: number,
      options?: any
    ): AxiosPromise<ChampTypeListModel> {
      return DefaultApiFp(configuration)
        .binChampadminAdminChampTypeListGet(limit, start, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Add championship
     * @summary Add championship
     * @param {ChampionshipAddModel} [championshipAddModel] Add championship
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminChampionshipAddPost(
      championshipAddModel?: ChampionshipAddModel,
      options?: any
    ): AxiosPromise<ChampionshipAddModel> {
      return DefaultApiFp(configuration)
        .binChampadminAdminChampionshipAddPost(championshipAddModel, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Editing championship
     * @summary Editing championship
     * @param {ChampionshipEditModel} [championshipEditModel] Edit championship
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminChampionshipEditPost(
      championshipEditModel?: ChampionshipEditModel,
      options?: any
    ): AxiosPromise<ChampionshipAdminModel> {
      return DefaultApiFp(configuration)
        .binChampadminAdminChampionshipEditPost(championshipEditModel, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Red Book entries
     * @param {number} champId
     * @param {number} limit
     * @param {number} start
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminChampionshipRedbookGet(
      champId: number,
      limit: number,
      start: number,
      options?: any
    ): AxiosPromise<RedBookPageModel> {
      return DefaultApiFp(configuration)
        .binChampadminAdminChampionshipRedbookGet(champId, limit, start, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Reorder Red Book entries
     * @param {Array<RankedQualifyingSiteModel>} [rankedQualifyingSiteModel] A list of assigned ranks for qualifying sites
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminChampionshipRedbookPost(
      rankedQualifyingSiteModel?: Array<RankedQualifyingSiteModel>,
      options?: any
    ): AxiosPromise<boolean> {
      return DefaultApiFp(configuration)
        .binChampadminAdminChampionshipRedbookPost(rankedQualifyingSiteModel, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns list of uploaded files
     * @summary Get list of uploaded files
     * @param {number} [limit] Per page
     * @param {number} [start] Starting position
     * @param {string} [champId]
     * @param {string} [applicationId]
     * @param {string} [query]
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminChampionshipsFilesGet(
      limit?: number,
      start?: number,
      champId?: string,
      applicationId?: string,
      query?: string,
      sortingFilter?: SortingFilterEnum,
      options?: any
    ): AxiosPromise<UploadedFileAdminListModel> {
      return DefaultApiFp(configuration)
        .binChampadminAdminChampionshipsFilesGet(
          limit,
          start,
          champId,
          applicationId,
          query,
          sortingFilter,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Repositions championships in order their ids were submitted
     * @summary Reposition championships
     * @param {Array<number>} [requestBody] List of championship ids in new order for some specific year
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminChampionshipsPositionPost(
      requestBody?: Array<number>,
      options?: any
    ): AxiosPromise<void> {
      return DefaultApiFp(configuration)
        .binChampadminAdminChampionshipsPositionPost(requestBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Is used to get championship data by its id
     * @summary Get championship info by id
     * @param {number} id id of championship
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminChampionshipsQueryGet(
      id: number,
      options?: any
    ): AxiosPromise<ChampionshipAdminModel> {
      return DefaultApiFp(configuration)
        .binChampadminAdminChampionshipsQueryGet(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get the list of images in cloudinary using its Search API. Has a rate limit of 5k requests per cloudinary account.
     * @param {number} limit Number of images to  return
     * @param {string} [query] Query passed to cloudinary API, see https://cloudinary.com/documentation/search_api for reference
     * @param {string} [start] Next cursor value to search from
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminCloudinaryListGet(
      limit: number,
      query?: string,
      start?: string,
      options?: any
    ): AxiosPromise<CloudinaryImagesAdminListModel> {
      return DefaultApiFp(configuration)
        .binChampadminAdminCloudinaryListGet(limit, query, start, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Delete club by id
     * @summary Remove club by its id
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminClubDelete(id: number, options?: any): AxiosPromise<void> {
      return DefaultApiFp(configuration)
        .binChampadminAdminClubDelete(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns list of clubs
     * @summary Get list of clubs
     * @param {number} [limit]
     * @param {number} [start]
     * @param {string} [query] Expression to filter entries (e.g. %word%)
     * @param {number} [champId]
     * @param {number} [year]
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {string} [countryFilter]
     * @param {string} [stateFilter]
     * @param {string} [cityFilter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminClubGet(
      limit?: number,
      start?: number,
      query?: string,
      champId?: number,
      year?: number,
      sortingFilter?: SortingFilterEnum,
      countryFilter?: string,
      stateFilter?: string,
      cityFilter?: string,
      options?: any
    ): AxiosPromise<ClubAdminListModel> {
      return DefaultApiFp(configuration)
        .binChampadminAdminClubGet(
          limit,
          start,
          query,
          champId,
          year,
          sortingFilter,
          countryFilter,
          stateFilter,
          cityFilter,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Add new club
     * @summary Add new club
     * @param {ClubAdminAddModel} [clubAdminAddModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminClubPost(
      clubAdminAddModel?: ClubAdminAddModel,
      options?: any
    ): AxiosPromise<ClubAdminModel> {
      return DefaultApiFp(configuration)
        .binChampadminAdminClubPost(clubAdminAddModel, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Is used to get club data by its id
     * @summary Get club info by id
     * @param {number} id id of club
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminClubQueryGet(id: number, options?: any): AxiosPromise<ClubAdminModel> {
      return DefaultApiFp(configuration)
        .binChampadminAdminClubQueryGet(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Updates club
     * @summary Update club
     * @param {ClubAdminModel} [clubAdminModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminClubUpdatePost(
      clubAdminModel?: ClubAdminModel,
      options?: any
    ): AxiosPromise<void> {
      return DefaultApiFp(configuration)
        .binChampadminAdminClubUpdatePost(clubAdminModel, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * parameters are used only for \'select all\' functionality
     * @summary Bulk email to clubs
     * @param {string} [query] Expression to filter entries (e.g. %word%)
     * @param {number} [champId]
     * @param {number} [year]
     * @param {ClubFilters} [filter]
     * @param {string} [filterValue]
     * @param {CustomBulkEmailRequestModel} [customBulkEmailRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminEmailBulkClubPost(
      query?: string,
      champId?: number,
      year?: number,
      filter?: ClubFilters,
      filterValue?: string,
      customBulkEmailRequestModel?: CustomBulkEmailRequestModel,
      options?: any
    ): AxiosPromise<string> {
      return DefaultApiFp(configuration)
        .binChampadminAdminEmailBulkClubPost(
          query,
          champId,
          year,
          filter,
          filterValue,
          customBulkEmailRequestModel,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Custom Bulk Email to Applicants
     * @param {CustomBulkEmailRequestModel} [customBulkEmailRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminEmailBulkCustomPost(
      customBulkEmailRequestModel?: CustomBulkEmailRequestModel,
      options?: any
    ): AxiosPromise<string> {
      return DefaultApiFp(configuration)
        .binChampadminAdminEmailBulkCustomPost(customBulkEmailRequestModel, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * parameters are used only for \'select all\' functionality
     * @summary Custom Bulk Email to Applicants
     * @param {Array<number>} [year] Optional Filter by year id
     * @param {Array<number>} [champTypeId] Optional Filter by ChampionshipType Model id
     * @param {string} [query] Optional search query parameter
     * @param {boolean} [twoYearParticipation] Optional Filter to find Two Year Participation
     * @param {boolean} [excludeApplied] Optional Exclude Applied Players Filter
     * @param {boolean} [exempt] Optional Filter to exclude exempt players
     * @param {PlayerStatusEnum} [playerStatus] Optional Player Status Filter
     * @param {string} [wagrRank] Optional WAGR Rank Filter
     * @param {boolean} [adaRequest] Optional ADA Request Filter, true - include, false - exclude
     * @param {boolean} [blacklist] Optional Blacklist Filter, true - include, false - exclude
     * @param {boolean} [watchlist] Optional Watchlist Filter, true - include, false - exclude
     * @param {number} [age] Optional Filter by Player\&#39;s age
     * @param {string} [handicapIndex] Optional Handicap Index Filter
     * @param {Array<number>} [qualifyingSiteId] Optional qualifying site Ids
     * @param {boolean} [applicationAccepted] Optional filter for accepted or q applications
     * @param {Array<number>} [waitlistId] Optional Filter by Waitlist QS id
     * @param {Array<number>} [excludeWaitlistId] Optional Filter exclude by Waitlist QS id
     * @param {boolean} [waitlistAll] Optional Filter to find All waitlisted player by champType &amp; year
     * @param {boolean} [excludeWaitlistAll] Optional Filter to exclude All waitlisted player by champType &amp; year
     * @param {boolean} [excludeBlacklistAll] Optional Filter to exclude All blacklisted player by champType &amp; year
     * @param {Array<BlacklistStatusEnum>} [excludeBlacklistStatus]
     * @param {QualifyingStatusEnum} [qualifyingStatus] Optional Application Qualifying Status Filter
     * @param {CustomBulkEmailProfileRequestModel} [customBulkEmailProfileRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminEmailBulkCustomProfilePost(
      year?: Array<number>,
      champTypeId?: Array<number>,
      query?: string,
      twoYearParticipation?: boolean,
      excludeApplied?: boolean,
      exempt?: boolean,
      playerStatus?: PlayerStatusEnum,
      wagrRank?: string,
      adaRequest?: boolean,
      blacklist?: boolean,
      watchlist?: boolean,
      age?: number,
      handicapIndex?: string,
      qualifyingSiteId?: Array<number>,
      applicationAccepted?: boolean,
      waitlistId?: Array<number>,
      excludeWaitlistId?: Array<number>,
      waitlistAll?: boolean,
      excludeWaitlistAll?: boolean,
      excludeBlacklistAll?: boolean,
      excludeBlacklistStatus?: Array<BlacklistStatusEnum>,
      qualifyingStatus?: QualifyingStatusEnum,
      customBulkEmailProfileRequestModel?: CustomBulkEmailProfileRequestModel,
      options?: any
    ): AxiosPromise<string> {
      return DefaultApiFp(configuration)
        .binChampadminAdminEmailBulkCustomProfilePost(
          year,
          champTypeId,
          query,
          twoYearParticipation,
          excludeApplied,
          exempt,
          playerStatus,
          wagrRank,
          adaRequest,
          blacklist,
          watchlist,
          age,
          handicapIndex,
          qualifyingSiteId,
          applicationAccepted,
          waitlistId,
          excludeWaitlistId,
          waitlistAll,
          excludeWaitlistAll,
          excludeBlacklistAll,
          excludeBlacklistStatus,
          qualifyingStatus,
          customBulkEmailProfileRequestModel,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * parameters are used only for \'select all\' functionality
     * @summary Exemptions Applicants bulk email
     * @param {number} champId Filter by championship id
     * @param {string} [query] Optional First/Last Name search query
     * @param {IdListModel} [idListModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminEmailBulkExemptAppPost(
      champId: number,
      query?: string,
      idListModel?: IdListModel,
      options?: any
    ): AxiosPromise<string> {
      return DefaultApiFp(configuration)
        .binChampadminAdminEmailBulkExemptAppPost(champId, query, idListModel, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns list of players
     * @summary Get list of exempt players
     * @param {number} champId
     * @param {number} [limit] Per page
     * @param {number} [start] Page number
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {string} [query]
     * @param {boolean} [excludeBlacklist]
     * @param {boolean} [excludeWatchlist]
     * @param {boolean} [excludeApplied]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminEmailBulkExemptPlayersGet(
      champId: number,
      limit?: number,
      start?: number,
      sortingFilter?: SortingFilterEnum,
      query?: string,
      excludeBlacklist?: boolean,
      excludeWatchlist?: boolean,
      excludeApplied?: boolean,
      options?: any
    ): AxiosPromise<ProfileAdminListModel> {
      return DefaultApiFp(configuration)
        .binChampadminAdminEmailBulkExemptPlayersGet(
          champId,
          limit,
          start,
          sortingFilter,
          query,
          excludeBlacklist,
          excludeWatchlist,
          excludeApplied,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Send buld email to exempt players
     * @param {number} champId
     * @param {number} [limit] Per page
     * @param {number} [start] Page number
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {string} [query]
     * @param {boolean} [excludeBlacklist]
     * @param {boolean} [excludeWatchlist]
     * @param {boolean} [excludeApplied]
     * @param {IdListModel} [idListModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminEmailBulkExemptPlayersPost(
      champId: number,
      limit?: number,
      start?: number,
      sortingFilter?: SortingFilterEnum,
      query?: string,
      excludeBlacklist?: boolean,
      excludeWatchlist?: boolean,
      excludeApplied?: boolean,
      idListModel?: IdListModel,
      options?: any
    ): AxiosPromise<string> {
      return DefaultApiFp(configuration)
        .binChampadminAdminEmailBulkExemptPlayersPost(
          champId,
          limit,
          start,
          sortingFilter,
          query,
          excludeBlacklist,
          excludeWatchlist,
          excludeApplied,
          idListModel,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * parameters are used only for \'select all\' functionality
     * @summary Final confirmation bulk email to Applicants
     * @param {number} champId Filter by championship id
     * @param {ApplicationFinalConfirmationBulkFilterEnum} playerFilter Plyer filter
     * @param {string} [query] Optional First/Last Name search query
     * @param {string} [startDate] Optional start qualifying date range
     * @param {string} [endDate] Optional end qualifying date range
     * @param {FinalConfirmationEmailRequestModel} [finalConfirmationEmailRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminEmailBulkFinalConfirmationPost(
      champId: number,
      playerFilter: ApplicationFinalConfirmationBulkFilterEnum,
      query?: string,
      startDate?: string,
      endDate?: string,
      finalConfirmationEmailRequestModel?: FinalConfirmationEmailRequestModel,
      options?: any
    ): AxiosPromise<string> {
      return DefaultApiFp(configuration)
        .binChampadminAdminEmailBulkFinalConfirmationPost(
          champId,
          playerFilter,
          query,
          startDate,
          endDate,
          finalConfirmationEmailRequestModel,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * parameters are used only for \'select all\' functionality
     * @summary Bulk email to incomplete sides
     * @param {number} champId Filter by championship id
     * @param {string} [query] Optional First/Last Name search query
     * @param {string} [startDate] Optional start qualifying date range
     * @param {string} [endDate] Optional end qualifying date range
     * @param {IncompleteSidesEmailRequestModel} [incompleteSidesEmailRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminEmailBulkIncompleteSidesPost(
      champId: number,
      query?: string,
      startDate?: string,
      endDate?: string,
      incompleteSidesEmailRequestModel?: IncompleteSidesEmailRequestModel,
      options?: any
    ): AxiosPromise<string> {
      return DefaultApiFp(configuration)
        .binChampadminAdminEmailBulkIncompleteSidesPost(
          champId,
          query,
          startDate,
          endDate,
          incompleteSidesEmailRequestModel,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary New Custom Bulk Email to Players
     * @param {NewCustomBulkEmailRequestModel} [newCustomBulkEmailRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminEmailBulkNewCustomPlayersPost(
      newCustomBulkEmailRequestModel?: NewCustomBulkEmailRequestModel,
      options?: any
    ): AxiosPromise<string> {
      return DefaultApiFp(configuration)
        .binChampadminAdminEmailBulkNewCustomPlayersPost(newCustomBulkEmailRequestModel, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary New Custom Bulk Email to Applicants
     * @param {NewCustomBulkEmailRequestModel} [newCustomBulkEmailRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminEmailBulkNewCustomPost(
      newCustomBulkEmailRequestModel?: NewCustomBulkEmailRequestModel,
      options?: any
    ): AxiosPromise<string> {
      return DefaultApiFp(configuration)
        .binChampadminAdminEmailBulkNewCustomPost(newCustomBulkEmailRequestModel, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * parameters are used only for \'select all\' functionality
     * @summary Bulk email to Officials
     * @param {string} [query] Expression to filter entries (e.g. %word%)
     * @param {number} [champId]
     * @param {number} [year]
     * @param {OfficialsListEmailRequestModel} [officialsListEmailRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminEmailBulkOfficialsPost(
      query?: string,
      champId?: number,
      year?: number,
      officialsListEmailRequestModel?: OfficialsListEmailRequestModel,
      options?: any
    ): AxiosPromise<string> {
      return DefaultApiFp(configuration)
        .binChampadminAdminEmailBulkOfficialsPost(
          query,
          champId,
          year,
          officialsListEmailRequestModel,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Bulk email with partner link
     * @param {PartnerLinkEmailRequestModel} [partnerLinkEmailRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminEmailBulkPartnerLinkPost(
      partnerLinkEmailRequestModel?: PartnerLinkEmailRequestModel,
      options?: any
    ): AxiosPromise<void> {
      return DefaultApiFp(configuration)
        .binChampadminAdminEmailBulkPartnerLinkPost(partnerLinkEmailRequestModel, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * parameters are used only for \'select all\' functionality
     * @summary Qualified Alternate bulk email to Applicants
     * @param {number} champId Filter by championship id
     * @param {string} [query] Optional First/Last Name search query
     * @param {string} [startDate] Optional start qualifying date range
     * @param {string} [endDate] Optional end qualifying date range
     * @param {ApplicationFilters} [filter]
     * @param {string} [filterValue]
     * @param {BulkQualifyingStatusEnum} [bulkQualifyingStatus] Optional complex filtering
     * @param {IdListModel} [idListModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminEmailBulkQualifiedAlternatePost(
      champId: number,
      query?: string,
      startDate?: string,
      endDate?: string,
      filter?: ApplicationFilters,
      filterValue?: string,
      bulkQualifyingStatus?: BulkQualifyingStatusEnum,
      idListModel?: IdListModel,
      options?: any
    ): AxiosPromise<string> {
      return DefaultApiFp(configuration)
        .binChampadminAdminEmailBulkQualifiedAlternatePost(
          champId,
          query,
          startDate,
          endDate,
          filter,
          filterValue,
          bulkQualifyingStatus,
          idListModel,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * parameters are used only for \'select all\' functionality
     * @summary Withdraw and bulk email incomplete applicants
     * @param {number} champId Filter by championship id
     * @param {string} [query] Optional First/Last Name search query
     * @param {string} [startDate] Optional start qualifying date range
     * @param {string} [endDate] Optional end qualifying date range
     * @param {IdListModel} [idListModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminEmailBulkWithdrawIncompletePost(
      champId: number,
      query?: string,
      startDate?: string,
      endDate?: string,
      idListModel?: IdListModel,
      options?: any
    ): AxiosPromise<string> {
      return DefaultApiFp(configuration)
        .binChampadminAdminEmailBulkWithdrawIncompletePost(
          champId,
          query,
          startDate,
          endDate,
          idListModel,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Resend email
     * @param {ResendEmailRequestModel} [resendEmailRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminEmailResendPost(
      resendEmailRequestModel?: ResendEmailRequestModel,
      options?: any
    ): AxiosPromise<void> {
      return DefaultApiFp(configuration)
        .binChampadminAdminEmailResendPost(resendEmailRequestModel, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Delete email template
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminEmailTemplateDelete(id: number, options?: any): AxiosPromise<void> {
      return DefaultApiFp(configuration)
        .binChampadminAdminEmailTemplateDelete(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Edit email template
     * @param {EmailTemplateEditModel} [emailTemplateEditModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminEmailTemplateEditPost(
      emailTemplateEditModel?: EmailTemplateEditModel,
      options?: any
    ): AxiosPromise<EmailTemplateEditModel> {
      return DefaultApiFp(configuration)
        .binChampadminAdminEmailTemplateEditPost(emailTemplateEditModel, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns Champiobship Type by id
     * @summary Get email template by id
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminEmailTemplateGet(
      id: number,
      options?: any
    ): AxiosPromise<EmailTemplateFullResponseModel> {
      return DefaultApiFp(configuration)
        .binChampadminAdminEmailTemplateGet(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Add new email template
     * @summary Create new email template
     * @param {EmailTemplateRequestModel} [emailTemplateRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminEmailTemplatePost(
      emailTemplateRequestModel?: EmailTemplateRequestModel,
      options?: any
    ): AxiosPromise<EmailTemplateEditModel> {
      return DefaultApiFp(configuration)
        .binChampadminAdminEmailTemplatePost(emailTemplateRequestModel, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get email template by service-type and Champ Id
     * @param {EmailTemplateServiceTypeEnum} serviceType
     * @param {number} [champId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminEmailTemplateServiceTypeGet(
      serviceType: EmailTemplateServiceTypeEnum,
      champId?: number,
      options?: any
    ): AxiosPromise<EmailTemplateFullResponseModel> {
      return DefaultApiFp(configuration)
        .binChampadminAdminEmailTemplateServiceTypeGet(serviceType, champId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns list of applications
     * @summary Get filtered list of applications for exemptions bulk
     * @param {number} champId Filter by championship id
     * @param {number} [limit]
     * @param {number} [start]
     * @param {string} [query] Optional First/Last Name search query
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminExemptApplicationsGet(
      champId: number,
      limit?: number,
      start?: number,
      query?: string,
      sortingFilter?: SortingFilterEnum,
      options?: any
    ): AxiosPromise<ApplicationAdminListModel> {
      return DefaultApiFp(configuration)
        .binChampadminAdminExemptApplicationsGet(
          champId,
          limit,
          start,
          query,
          sortingFilter,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Delete exemption by id
     * @summary Remove exemption by its id
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminExemptionDelete(id: number, options?: any): AxiosPromise<void> {
      return DefaultApiFp(configuration)
        .binChampadminAdminExemptionDelete(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Edit already existing exemption
     * @summary Edit exemption
     * @param {ExemptionAdminEditModel} [exemptionAdminEditModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminExemptionEditPost(
      exemptionAdminEditModel?: ExemptionAdminEditModel,
      options?: any
    ): AxiosPromise<ExemptionAdminEditModel> {
      return DefaultApiFp(configuration)
        .binChampadminAdminExemptionEditPost(exemptionAdminEditModel, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns list of exemptions
     * @summary Get list of exemptions by championship Id
     * @param {number} champId
     * @param {number} [limit]
     * @param {number} [start]
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {QualifyingLevelEnum} [filter] Optional Filter
     * @param {string} [query] Optional search query parameter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminExemptionGet(
      champId: number,
      limit?: number,
      start?: number,
      sortingFilter?: SortingFilterEnum,
      filter?: QualifyingLevelEnum,
      query?: string,
      options?: any
    ): AxiosPromise<ExemptionAdminListModel> {
      return DefaultApiFp(configuration)
        .binChampadminAdminExemptionGet(
          champId,
          limit,
          start,
          sortingFilter,
          filter,
          query,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns list of exemptions
     * @summary Get list of exemptions by championship Id
     * @param {number} champId
     * @param {number} [limit]
     * @param {number} [start]
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {QualifyingLevelEnum} [filter] Optional Filter
     * @param {string} [query] Optional search query parameter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminExemptionLightWeightGet(
      champId: number,
      limit?: number,
      start?: number,
      sortingFilter?: SortingFilterEnum,
      filter?: QualifyingLevelEnum,
      query?: string,
      options?: any
    ): AxiosPromise<ExemptionAdminListLightWeightModel> {
      return DefaultApiFp(configuration)
        .binChampadminAdminExemptionLightWeightGet(
          champId,
          limit,
          start,
          sortingFilter,
          filter,
          query,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Add new exemption to championship
     * @summary Add new exemption to championship
     * @param {ExemptionAdminAddModel} [exemptionAdminAddModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminExemptionPost(
      exemptionAdminAddModel?: ExemptionAdminAddModel,
      options?: any
    ): AxiosPromise<ExemptionAdminEditModel> {
      return DefaultApiFp(configuration)
        .binChampadminAdminExemptionPost(exemptionAdminAddModel, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Is used to get exemption data by its id
     * @summary Get exemption info by id
     * @param {number} id id of exemption
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminExemptionQueryGet(id: number, options?: any): AxiosPromise<ExemptionModel> {
      return DefaultApiFp(configuration)
        .binChampadminAdminExemptionQueryGet(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Import exemptions
     * @summary Import exemptions
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminExemptionUploadPost(file?: any, options?: any): AxiosPromise<string> {
      return DefaultApiFp(configuration)
        .binChampadminAdminExemptionUploadPost(file, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Delete file by id
     * @summary Remove file by its id
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminFileDelete(id: number, options?: any): AxiosPromise<void> {
      return DefaultApiFp(configuration)
        .binChampadminAdminFileDelete(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Download file by id
     * @summary Download file by id
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminFileGet(id: number, options?: any): AxiosPromise<void> {
      return DefaultApiFp(configuration)
        .binChampadminAdminFileGet(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Download files by id list
     * @summary Download files by id list
     * @param {Array<number>} id Required id list parameter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminFileListGet(id: Array<number>, options?: any): AxiosPromise<void> {
      return DefaultApiFp(configuration)
        .binChampadminAdminFileListGet(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get handicap player by handicap id
     * @param {string} handicapId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminHandicapGet(
      handicapId: string,
      options?: any
    ): AxiosPromise<Array<HandicapPlayerModel>> {
      return DefaultApiFp(configuration)
        .binChampadminAdminHandicapGet(handicapId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns list of players
     * @summary Get list of incomplete sides
     * @param {number} champId Filter by championship id
     * @param {number} [limit]
     * @param {number} [start]
     * @param {string} [query] Optional First/Last Name search query
     * @param {string} [startDate] Optional start qualifying date range
     * @param {string} [endDate] Optional end qualifying date range
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminIncompleteSidesGet(
      champId: number,
      limit?: number,
      start?: number,
      query?: string,
      startDate?: string,
      endDate?: string,
      sortingFilter?: SortingFilterEnum,
      options?: any
    ): AxiosPromise<IncompleteSideListModel> {
      return DefaultApiFp(configuration)
        .binChampadminAdminIncompleteSidesGet(
          champId,
          limit,
          start,
          query,
          startDate,
          endDate,
          sortingFilter,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get unsubscribe page absolute link
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminLinksUnsubscribeGet(options?: any): AxiosPromise<string> {
      return DefaultApiFp(configuration)
        .binChampadminAdminLinksUnsubscribeGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Delete exemption by id
     * @summary Remove exemption by its id
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminMessageDelete(id: number, options?: any): AxiosPromise<void> {
      return DefaultApiFp(configuration)
        .binChampadminAdminMessageDelete(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns list of messages
     * @summary Get list of messages
     * @param {number} [limit]
     * @param {number} [start]
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {MessageTypeEnum} [messageType] Optional filter by message type
     * @param {boolean} [dismissible] Optional filter by current date and \&#39;dateRangeEnabled\&#39;
     * @param {boolean} [dateRangeEnabled] Optional filter by \&#39;dateRangeEnabled\&#39; flag
     * @param {number} [playerId] Filter by player id
     * @param {string} [query] Optional search query parameter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminMessageGet(
      limit?: number,
      start?: number,
      sortingFilter?: SortingFilterEnum,
      messageType?: MessageTypeEnum,
      dismissible?: boolean,
      dateRangeEnabled?: boolean,
      playerId?: number,
      query?: string,
      options?: any
    ): AxiosPromise<MessageListModel> {
      return DefaultApiFp(configuration)
        .binChampadminAdminMessageGet(
          limit,
          start,
          sortingFilter,
          messageType,
          dismissible,
          dateRangeEnabled,
          playerId,
          query,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Add new message
     * @summary Add new message
     * @param {MessageAddModel} [messageAddModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminMessagePost(
      messageAddModel?: MessageAddModel,
      options?: any
    ): AxiosPromise<MessageEditModel> {
      return DefaultApiFp(configuration)
        .binChampadminAdminMessagePost(messageAddModel, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Is used to get message data by its id
     * @summary Get message by id
     * @param {number} id id of the message
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminMessageQueryGet(id: number, options?: any): AxiosPromise<MessageAdminModel> {
      return DefaultApiFp(configuration)
        .binChampadminAdminMessageQueryGet(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Updates message
     * @summary Update message
     * @param {MessageEditModel} [messageEditModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminMessageUpdatePost(
      messageEditModel?: MessageEditModel,
      options?: any
    ): AxiosPromise<MessageAdminModel> {
      return DefaultApiFp(configuration)
        .binChampadminAdminMessageUpdatePost(messageEditModel, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns Champiobship Type by id
     * @summary Get Applications Pairs by ChampId
     * @param {number} champId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminPairGet(champId: number, options?: any): AxiosPromise<PairListResponseModel> {
      return DefaultApiFp(configuration)
        .binChampadminAdminPairGet(champId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns list of pairs
     * @summary Get filtered list of pairs
     * @param {number} limit
     * @param {number} start
     * @param {number} playerId
     * @param {string} [query] Optional search query
     * @param {PairSortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminPairHistoryGet(
      limit: number,
      start: number,
      playerId: number,
      query?: string,
      sortingFilter?: PairSortingFilterEnum,
      options?: any
    ): AxiosPromise<PairHistoryListModel> {
      return DefaultApiFp(configuration)
        .binChampadminAdminPairHistoryGet(limit, start, playerId, query, sortingFilter, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Create/Update Applications Pairs
     * @param {PairListRequestModel} [pairListRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminPairPost(
      pairListRequestModel?: PairListRequestModel,
      options?: any
    ): AxiosPromise<void> {
      return DefaultApiFp(configuration)
        .binChampadminAdminPairPost(pairListRequestModel, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Refunds payment
     * @summary Refunds payment
     * @param {RefundPaymentAdminEditModel} [refundPaymentAdminEditModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminPaymentEditRefundPost(
      refundPaymentAdminEditModel?: RefundPaymentAdminEditModel,
      options?: any
    ): AxiosPromise<ApplicationRefundResponseModel> {
      return DefaultApiFp(configuration)
        .binChampadminAdminPaymentEditRefundPost(refundPaymentAdminEditModel, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Refunds payment
     * @summary Refunds payment
     * @param {FourBallRefundPaymentAdminModel} [fourBallRefundPaymentAdminModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminPaymentFourBallRefundPost(
      fourBallRefundPaymentAdminModel?: FourBallRefundPaymentAdminModel,
      options?: any
    ): AxiosPromise<ApplicationRefundListModel> {
      return DefaultApiFp(configuration)
        .binChampadminAdminPaymentFourBallRefundPost(fourBallRefundPaymentAdminModel, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get form data to make post request to payment processor
     * @param {number} appId Application id
     * @param {string} referrer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminPaymentGet(
      appId: number,
      referrer: string,
      options?: any
    ): AxiosPromise<PaymentDataPlayerResponseModel> {
      return DefaultApiFp(configuration)
        .binChampadminAdminPaymentGet(appId, referrer, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Refunds payment
     * @summary Refunds payment
     * @param {RefundPaymentAdminModel} [refundPaymentAdminModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminPaymentRefundPost(
      refundPaymentAdminModel?: RefundPaymentAdminModel,
      options?: any
    ): AxiosPromise<ApplicationRefundListModel> {
      return DefaultApiFp(configuration)
        .binChampadminAdminPaymentRefundPost(refundPaymentAdminModel, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Voids Refund payment
     * @summary Voids Refund payment
     * @param {VoidRefundPaymentAdminModel} [voidRefundPaymentAdminModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminPaymentRefundVoidPost(
      voidRefundPaymentAdminModel?: VoidRefundPaymentAdminModel,
      options?: any
    ): AxiosPromise<void> {
      return DefaultApiFp(configuration)
        .binChampadminAdminPaymentRefundVoidPost(voidRefundPaymentAdminModel, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns list of applications
     * @summary Get filtered list of applications for custom email
     * @param {number} [limit]
     * @param {number} [start]
     * @param {Array<number>} [year] Optional Filter by year id
     * @param {Array<number>} [champTypeId] Optional Filter by ChampionshipType Model id
     * @param {string} [query] Optional search query parameter
     * @param {boolean} [twoYearParticipation] Optional Filter to find Two Year Participation
     * @param {boolean} [excludeApplied] Optional Exclude Applied Players Filter
     * @param {boolean} [exempt] Optional Filter to exclude exempt players
     * @param {PlayerStatusEnum} [playerStatus] Optional Player Status Filter
     * @param {string} [wagrRank] Optional WAGR Rank Filter
     * @param {boolean} [adaRequest] Optional ADA Request Filter, true - include, false - exclude
     * @param {boolean} [blacklist] Optional Blacklist Filter, true - include, false - exclude
     * @param {boolean} [watchlist] Optional Watchlist Filter, true - include, false - exclude
     * @param {number} [age] Optional Filter by Player\&#39;s age
     * @param {string} [handicapIndex] Optional Handicap Index Filter
     * @param {Array<number>} [qualifyingSiteId] Optional qualifying site Ids
     * @param {boolean} [applicationAccepted] Optional filter for accepted or q applications
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {EmailTemplateTypeEnum} [templType]
     * @param {number} [templId]
     * @param {Array<number>} [waitlistId] Optional Filter by Waitlist QS id
     * @param {Array<number>} [excludeWaitlistId] Optional Filter exclude by Waitlist QS id
     * @param {boolean} [waitlistAll] Optional Filter to find All waitlisted player by champType &amp; year
     * @param {boolean} [excludeWaitlistAll] Optional Filter to exclude All waitlisted player by champType &amp; year
     * @param {boolean} [excludeBlacklistAll] Optional Filter to exclude All blacklisted player by champType &amp; year
     * @param {Array<BlacklistStatusEnum>} [excludeBlacklistStatus]
     * @param {QualifyingStatusEnum} [qualifyingStatus] Optional Application Qualifying Status Filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminPlayersCustomEmailGet(
      limit?: number,
      start?: number,
      year?: Array<number>,
      champTypeId?: Array<number>,
      query?: string,
      twoYearParticipation?: boolean,
      excludeApplied?: boolean,
      exempt?: boolean,
      playerStatus?: PlayerStatusEnum,
      wagrRank?: string,
      adaRequest?: boolean,
      blacklist?: boolean,
      watchlist?: boolean,
      age?: number,
      handicapIndex?: string,
      qualifyingSiteId?: Array<number>,
      applicationAccepted?: boolean,
      sortingFilter?: SortingFilterEnum,
      templType?: EmailTemplateTypeEnum,
      templId?: number,
      waitlistId?: Array<number>,
      excludeWaitlistId?: Array<number>,
      waitlistAll?: boolean,
      excludeWaitlistAll?: boolean,
      excludeBlacklistAll?: boolean,
      excludeBlacklistStatus?: Array<BlacklistStatusEnum>,
      qualifyingStatus?: QualifyingStatusEnum,
      options?: any
    ): AxiosPromise<ProfileAdminListModel> {
      return DefaultApiFp(configuration)
        .binChampadminAdminPlayersCustomEmailGet(
          limit,
          start,
          year,
          champTypeId,
          query,
          twoYearParticipation,
          excludeApplied,
          exempt,
          playerStatus,
          wagrRank,
          adaRequest,
          blacklist,
          watchlist,
          age,
          handicapIndex,
          qualifyingSiteId,
          applicationAccepted,
          sortingFilter,
          templType,
          templId,
          waitlistId,
          excludeWaitlistId,
          waitlistAll,
          excludeWaitlistAll,
          excludeBlacklistAll,
          excludeBlacklistStatus,
          qualifyingStatus,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Delete point of contact by id
     * @summary Remove point of contact by its id
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminPocDelete(id: number, options?: any): AxiosPromise<void> {
      return DefaultApiFp(configuration)
        .binChampadminAdminPocDelete(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns list of points of contact
     * @summary Get list of points of contact
     * @param {number} [limit]
     * @param {number} [start]
     * @param {string} [query] Expression to filter entries (e.g. %word%)
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminPocGet(
      limit?: number,
      start?: number,
      query?: string,
      sortingFilter?: SortingFilterEnum,
      options?: any
    ): AxiosPromise<PointOfContactAdminListModel> {
      return DefaultApiFp(configuration)
        .binChampadminAdminPocGet(limit, start, query, sortingFilter, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Add new point of contact
     * @summary Add new point of contact
     * @param {PointOfContactAdminAddModel} [pointOfContactAdminAddModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminPocPost(
      pointOfContactAdminAddModel?: PointOfContactAdminAddModel,
      options?: any
    ): AxiosPromise<PointOfContactAdminModel> {
      return DefaultApiFp(configuration)
        .binChampadminAdminPocPost(pointOfContactAdminAddModel, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Is used to get poc data by its id
     * @summary Get poc info by id
     * @param {number} id id of poc
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminPocQueryGet(
      id: number,
      options?: any
    ): AxiosPromise<PointOfContactAdminModel> {
      return DefaultApiFp(configuration)
        .binChampadminAdminPocQueryGet(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Updates point of contact
     * @summary Update point of contact
     * @param {PointOfContactAdminEditModel} [pointOfContactAdminEditModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminPocUpdatePost(
      pointOfContactAdminEditModel?: PointOfContactAdminEditModel,
      options?: any
    ): AxiosPromise<PointOfContactAdminEditModel> {
      return DefaultApiFp(configuration)
        .binChampadminAdminPocUpdatePost(pointOfContactAdminEditModel, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get duplicated player\'s profile quantity
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminProfileDuplicateAutoMergeGet(options?: any): AxiosPromise<number> {
      return DefaultApiFp(configuration)
        .binChampadminAdminProfileDuplicateAutoMergeGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Auto-merge players\' profiles
     * @summary Auto-merge players\' profiles
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminProfileDuplicateAutoMergePost(
      options?: any
    ): AxiosPromise<ProfileAdminListModel> {
      return DefaultApiFp(configuration)
        .binChampadminAdminProfileDuplicateAutoMergePost(options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Is used to get player\'s profile duplicates
     * @summary Get player\'s profile duplicates
     * @param {string} id id of profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminProfileDuplicateGet(
      id: string,
      options?: any
    ): AxiosPromise<ProfileAdminListModel> {
      return DefaultApiFp(configuration)
        .binChampadminAdminProfileDuplicateGet(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Updates player profile
     * @summary Merge player\'s profiles
     * @param {IdListModel} [idListModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminProfileDuplicatePost(
      idListModel?: IdListModel,
      options?: any
    ): AxiosPromise<ProfileAdminModel> {
      return DefaultApiFp(configuration)
        .binChampadminAdminProfileDuplicatePost(idListModel, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get status list of requested subscriptions
     * @summary Get status list of requested subscriptions
     * @param {Array<EmailSubscriptionType>} [types]
     * @param {number} [playerId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminProfileEmailSubscriptionsGet(
      types?: Array<EmailSubscriptionType>,
      playerId?: number,
      options?: any
    ): AxiosPromise<EmailSubscriptionsStatusList> {
      return DefaultApiFp(configuration)
        .binChampadminAdminProfileEmailSubscriptionsGet(types, playerId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Update subscriptions
     * @summary Update subscriptions
     * @param {PlayerEmailSubscriptionsStatus} [playerEmailSubscriptionsStatus]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminProfileEmailSubscriptionsPost(
      playerEmailSubscriptionsStatus?: PlayerEmailSubscriptionsStatus,
      options?: any
    ): AxiosPromise<void> {
      return DefaultApiFp(configuration)
        .binChampadminAdminProfileEmailSubscriptionsPost(playerEmailSubscriptionsStatus, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Is used to get profile data
     * @summary Get player profile
     * @param {string} id id of profile to return data for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminProfileGet(id: string, options?: any): AxiosPromise<ProfileAdminModel> {
      return DefaultApiFp(configuration)
        .binChampadminAdminProfileGet(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Is used to get profile data
     * @summary [GraphQL] Get player profile
     * @param {string} id id of profile to return data for
     * @param {Array<string>} [fields] required fields to fetch, e.g. \&quot;aaa\&quot;, \&quot;bbb.ccc.ddd\&quot;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminProfileGraphqlGet(
      id: string,
      fields?: Array<string>,
      options?: any
    ): AxiosPromise<object> {
      return DefaultApiFp(configuration)
        .binChampadminAdminProfileGraphqlGet(id, fields, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns list of players
     * @summary Get list of players
     * @param {number} [limit] Per page
     * @param {number} [start] Page number
     * @param {string} [query] Query parameter to filter users based on: User Id, First Name, Last Name, Player Status, Date of Birth (e.g. query&#x3D;value*)
     * @param {PlayerFilters} [filter]
     * @param {string} [filterValue]
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {Array<number>} [champId] Optional Filter by champ id
     * @param {Array<number>} [year]
     * @param {Array<number>} [siteId] Optional Filter by qualifying site id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminProfileListGet(
      limit?: number,
      start?: number,
      query?: string,
      filter?: PlayerFilters,
      filterValue?: string,
      sortingFilter?: SortingFilterEnum,
      champId?: Array<number>,
      year?: Array<number>,
      siteId?: Array<number>,
      options?: any
    ): AxiosPromise<ProfileAdminListModel> {
      return DefaultApiFp(configuration)
        .binChampadminAdminProfileListGet(
          limit,
          start,
          query,
          filter,
          filterValue,
          sortingFilter,
          champId,
          year,
          siteId,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns list of players
     * @summary [GraphQL] Get list of players
     * @param {number} [limit] Per page
     * @param {number} [start] Page number
     * @param {string} [query] Query parameter to filter users based on: User Id, First Name, Last Name, Player Status, Date of Birth (e.g. query&#x3D;value*)
     * @param {PlayerFilters} [filter]
     * @param {string} [filterValue]
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {Array<number>} [champId] Optional Filter by champ id
     * @param {Array<number>} [year]
     * @param {Array<number>} [siteId] Optional Filter by qualifying site id
     * @param {Array<string>} [fields] required fields to fetch, e.g. \&quot;aaa\&quot;, \&quot;bbb.ccc.ddd\&quot;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminProfileListGraphqlGet(
      limit?: number,
      start?: number,
      query?: string,
      filter?: PlayerFilters,
      filterValue?: string,
      sortingFilter?: SortingFilterEnum,
      champId?: Array<number>,
      year?: Array<number>,
      siteId?: Array<number>,
      fields?: Array<string>,
      options?: any
    ): AxiosPromise<object> {
      return DefaultApiFp(configuration)
        .binChampadminAdminProfileListGraphqlGet(
          limit,
          start,
          query,
          filter,
          filterValue,
          sortingFilter,
          champId,
          year,
          siteId,
          fields,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Updates managed players
     * @summary Update managed players
     * @param {ProfileManagedPlayerAdminModel} [profileManagedPlayerAdminModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminProfileManagedplayerPost(
      profileManagedPlayerAdminModel?: ProfileManagedPlayerAdminModel,
      options?: any
    ): AxiosPromise<void> {
      return DefaultApiFp(configuration)
        .binChampadminAdminProfileManagedplayerPost(profileManagedPlayerAdminModel, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns list of players
     * @summary Get list of players
     * @param {number} [limit] Per page
     * @param {number} [start] Page number
     * @param {string} [query] Query parameter to filter users based on: User Id, First Name, Last Name, Player Status, Date of Birth (e.g. query&#x3D;value*)
     * @param {PlayerFilters} [filter]
     * @param {string} [filterValue]
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {Array<number>} [champId] Optional Filter by champ id
     * @param {Array<number>} [year]
     * @param {Array<number>} [champTypeId] Optional Filter by champ type id
     * @param {string} [blackList] Black list
     * @param {Array<number>} [siteId] Optional Filter by qualifying site id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminProfileMessageListGet(
      limit?: number,
      start?: number,
      query?: string,
      filter?: PlayerFilters,
      filterValue?: string,
      sortingFilter?: SortingFilterEnum,
      champId?: Array<number>,
      year?: Array<number>,
      champTypeId?: Array<number>,
      blackList?: string,
      siteId?: Array<number>,
      options?: any
    ): AxiosPromise<ProfileAdminListModel> {
      return DefaultApiFp(configuration)
        .binChampadminAdminProfileMessageListGet(
          limit,
          start,
          query,
          filter,
          filterValue,
          sortingFilter,
          champId,
          year,
          champTypeId,
          blackList,
          siteId,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Updates player profile
     * @summary Save/update player profile
     * @param {ProfileAdminRequestModel} [profileAdminRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminProfilePost(
      profileAdminRequestModel?: ProfileAdminRequestModel,
      options?: any
    ): AxiosPromise<ProfileAdminModel> {
      return DefaultApiFp(configuration)
        .binChampadminAdminProfilePost(profileAdminRequestModel, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get profiles quantity
     * @param {PlayerFilters} [filter]
     * @param {string} [filterValue]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminProfileQuantityGet(
      filter?: PlayerFilters,
      filterValue?: string,
      options?: any
    ): AxiosPromise<number> {
      return DefaultApiFp(configuration)
        .binChampadminAdminProfileQuantityGet(filter, filterValue, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Closes QS
     * @summary Provides zip file for download with reports with Applications of QS
     * @param {number} champId
     * @param {QualifyingSiteFilters} [filter]
     * @param {IdListModel} [idListModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminQualifyingsiteApplicationsDownloadPost(
      champId: number,
      filter?: QualifyingSiteFilters,
      idListModel?: IdListModel,
      options?: any
    ): AxiosPromise<void> {
      return DefaultApiFp(configuration)
        .binChampadminAdminQualifyingsiteApplicationsDownloadPost(
          champId,
          filter,
          idListModel,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Auto-assign QS number
     * @summary Auto-assign QS number
     * @param {number} champId
     * @param {QualifyingLevelEnum} level
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminQualifyingsiteAutoAssignPost(
      champId: number,
      level: QualifyingLevelEnum,
      options?: any
    ): AxiosPromise<void> {
      return DefaultApiFp(configuration)
        .binChampadminAdminQualifyingsiteAutoAssignPost(champId, level, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns list of qualifying sites
     * @summary Get list of qualifying sites by championship Ids
     * @param {Array<number>} champId Filter by champ id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminQualifyingsiteChampsGet(
      champId: Array<number>,
      options?: any
    ): AxiosPromise<QualifyingSiteAdminListModel> {
      return DefaultApiFp(configuration)
        .binChampadminAdminQualifyingsiteChampsGet(champId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns list of qualifying sites by champ type ids
     * @summary Get list of qualifying sites by champ type id
     * @param {Array<number>} champTypeId Champ Type Ids
     * @param {Array<number>} year Champ Type Ids
     * @param {number} [limit]
     * @param {number} [start]
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminQualifyingsiteChamptypeidGet(
      champTypeId: Array<number>,
      year: Array<number>,
      limit?: number,
      start?: number,
      sortingFilter?: SortingFilterEnum,
      options?: any
    ): AxiosPromise<QualifyingSiteAdminListModel> {
      return DefaultApiFp(configuration)
        .binChampadminAdminQualifyingsiteChamptypeidGet(
          champTypeId,
          year,
          limit,
          start,
          sortingFilter,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Checks whether it is possible to create a \'QualifyingSite\' with such data.
     * @summary Check if a combination of ChampId+Level+Number is still free
     * @param {number} champId
     * @param {string} level
     * @param {number} number
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminQualifyingsiteCheckGet(
      champId: number,
      level: string,
      number: number,
      options?: any
    ): AxiosPromise<void> {
      return DefaultApiFp(configuration)
        .binChampadminAdminQualifyingsiteCheckGet(champId, level, number, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Closes QS
     * @summary Closes selected qualifying sites and provides zip file for download with reports
     * @param {IdListModel} [idListModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminQualifyingsiteClosePost(
      idListModel?: IdListModel,
      options?: any
    ): AxiosPromise<void> {
      return DefaultApiFp(configuration)
        .binChampadminAdminQualifyingsiteClosePost(idListModel, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Delete qualifying site by id
     * @summary Remove qualifying site by its id
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminQualifyingsiteDelete(id: number, options?: any): AxiosPromise<void> {
      return DefaultApiFp(configuration)
        .binChampadminAdminQualifyingsiteDelete(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns list of qualifying sites
     * @summary Get list of qualifying sites by championship Id
     * @param {number} champId
     * @param {number} [limit]
     * @param {number} [start]
     * @param {QualifyingSiteFilters} [filter]
     * @param {string} [filterValue]
     * @param {string} [startDate] Optional start date range
     * @param {string} [endDate] Optional end date range
     * @param {string} [query]
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminQualifyingsiteGet(
      champId: number,
      limit?: number,
      start?: number,
      filter?: QualifyingSiteFilters,
      filterValue?: string,
      startDate?: string,
      endDate?: string,
      query?: string,
      sortingFilter?: SortingFilterEnum,
      options?: any
    ): AxiosPromise<QualifyingSiteAdminListModel> {
      return DefaultApiFp(configuration)
        .binChampadminAdminQualifyingsiteGet(
          champId,
          limit,
          start,
          filter,
          filterValue,
          startDate,
          endDate,
          query,
          sortingFilter,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns list of qualifying sites
     * @summary Get list of qualifying sites by championship Id
     * @param {number} champId
     * @param {number} [limit]
     * @param {number} [start]
     * @param {QualifyingSiteFilters} [filter]
     * @param {string} [filterValue]
     * @param {string} [startDate] Optional start date range
     * @param {string} [endDate] Optional end date range
     * @param {string} [query]
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminQualifyingsiteLightWeightGet(
      champId: number,
      limit?: number,
      start?: number,
      filter?: QualifyingSiteFilters,
      filterValue?: string,
      startDate?: string,
      endDate?: string,
      query?: string,
      sortingFilter?: SortingFilterEnum,
      options?: any
    ): AxiosPromise<QualifyingSiteAdminListLightWeightModel> {
      return DefaultApiFp(configuration)
        .binChampadminAdminQualifyingsiteLightWeightGet(
          champId,
          limit,
          start,
          filter,
          filterValue,
          startDate,
          endDate,
          query,
          sortingFilter,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Add new qualifying site to championship
     * @summary Add new qualifying site to championship
     * @param {QualifyingSiteAddModel} [qualifyingSiteAddModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminQualifyingsitePost(
      qualifyingSiteAddModel?: QualifyingSiteAddModel,
      options?: any
    ): AxiosPromise<QualifyingSiteAdminModel> {
      return DefaultApiFp(configuration)
        .binChampadminAdminQualifyingsitePost(qualifyingSiteAddModel, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Is used to get qualifying site data by its id
     * @summary Get qualifying site info by id
     * @param {number} id id of qualifying site
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminQualifyingsiteQueryGet(
      id: number,
      options?: any
    ): AxiosPromise<QualifyingSiteFetchModel> {
      return DefaultApiFp(configuration)
        .binChampadminAdminQualifyingsiteQueryGet(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns list of qualifying sites uploaded results
     * @summary Get list of qualifying sites results by file id
     * @param {number} [limit]
     * @param {number} [start]
     * @param {number} [fileId]
     * @param {string} [query]
     * @param {UploadFileProgressionEnum} [status]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminQualifyingsiteResultDetailGet(
      limit?: number,
      start?: number,
      fileId?: number,
      query?: string,
      status?: UploadFileProgressionEnum,
      options?: any
    ): AxiosPromise<ScoringListModel> {
      return DefaultApiFp(configuration)
        .binChampadminAdminQualifyingsiteResultDetailGet(
          limit,
          start,
          fileId,
          query,
          status,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Add new qualifying site to championship
     * @summary Update
     * @param {ScoreUpdateRequestAdminModelScoring} [scoreUpdateRequestAdminModelScoring]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminQualifyingsiteResultDetailPost(
      scoreUpdateRequestAdminModelScoring?: ScoreUpdateRequestAdminModelScoring,
      options?: any
    ): AxiosPromise<ApplicationBaseAdminModelScoring> {
      return DefaultApiFp(configuration)
        .binChampadminAdminQualifyingsiteResultDetailPost(
          scoreUpdateRequestAdminModelScoring,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns list of qualifying sites uploaded results
     * @summary Get list of qualifying sites results by qualifying site id
     * @param {number} champId
     * @param {number} [limit]
     * @param {number} [start]
     * @param {number} [qualifyingSiteId]
     * @param {string} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminQualifyingsiteResultListGet(
      champId: number,
      limit?: number,
      start?: number,
      qualifyingSiteId?: number,
      query?: string,
      options?: any
    ): AxiosPromise<UploadedFileAdminListModel> {
      return DefaultApiFp(configuration)
        .binChampadminAdminQualifyingsiteResultListGet(
          champId,
          limit,
          start,
          qualifyingSiteId,
          query,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update
     * @param {UploadFileStatusRequestAdminModel} [uploadFileStatusRequestAdminModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminQualifyingsiteResultListPost(
      uploadFileStatusRequestAdminModel?: UploadFileStatusRequestAdminModel,
      options?: any
    ): AxiosPromise<void> {
      return DefaultApiFp(configuration)
        .binChampadminAdminQualifyingsiteResultListPost(uploadFileStatusRequestAdminModel, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Updates point of contact
     * @summary Update qualifying site
     * @param {QualifyingSiteEditModel} [qualifyingSiteEditModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminQualifyingsiteUpdatePost(
      qualifyingSiteEditModel?: QualifyingSiteEditModel,
      options?: any
    ): AxiosPromise<void> {
      return DefaultApiFp(configuration)
        .binChampadminAdminQualifyingsiteUpdatePost(qualifyingSiteEditModel, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns list of Q reasons
     * @summary Get list of queue reasons
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminQueueReasonGet(options?: any): AxiosPromise<Array<QueueReasonAdminModel>> {
      return DefaultApiFp(configuration)
        .binChampadminAdminQueueReasonGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Download ada report
     * @summary Download ada report
     * @param {number} [champId] Optional filter by championship id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminReportsAdaGet(champId?: number, options?: any): AxiosPromise<string> {
      return DefaultApiFp(configuration)
        .binChampadminAdminReportsAdaGet(champId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Download anti-doping report
     * @summary Download anti-doping report
     * @param {number} [champId] Optional filter by championship id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminReportsAntidopingGet(champId?: number, options?: any): AxiosPromise<string> {
      return DefaultApiFp(configuration)
        .binChampadminAdminReportsAntidopingGet(champId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Download list of applications
     * @summary Download filtered list of applications
     * @param {number} champId Optional filter by championship id
     * @param {number} year Championship year
     * @param {ApplicationFilters} filter
     * @param {number} [limit]
     * @param {number} [start]
     * @param {string} [query] Optional full text search query
     * @param {string} [filterValue]
     * @param {string} [disabilityTypeFilter] Disability Type
     * @param {Array<number>} [appId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminReportsApplicationsGet(
      champId: number,
      year: number,
      filter: ApplicationFilters,
      limit?: number,
      start?: number,
      query?: string,
      filterValue?: string,
      disabilityTypeFilter?: string,
      appId?: Array<number>,
      options?: any
    ): AxiosPromise<void> {
      return DefaultApiFp(configuration)
        .binChampadminAdminReportsApplicationsGet(
          champId,
          year,
          filter,
          limit,
          start,
          query,
          filterValue,
          disabilityTypeFilter,
          appId,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Download blacklist report
     * @summary Download Blacklist across all champ report
     * @param {number} [champId] Optional filter by championship id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminReportsBlacklistGet(champId?: number, options?: any): AxiosPromise<string> {
      return DefaultApiFp(configuration)
        .binChampadminAdminReportsBlacklistGet(champId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Download close of entries data snapshot report
     * @summary Download close of entries data snapshot report
     * @param {number} [champId] Optional filter by championship id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminReportsCloseOfEntriesGet(
      champId?: number,
      options?: any
    ): AxiosPromise<string> {
      return DefaultApiFp(configuration)
        .binChampadminAdminReportsCloseOfEntriesGet(champId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Download demographics report
     * @summary Download demographics report
     * @param {number} [champId] Optional filter by championship id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminReportsDemographicsGet(
      champId?: number,
      options?: any
    ): AxiosPromise<string> {
      return DefaultApiFp(configuration)
        .binChampadminAdminReportsDemographicsGet(champId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Download report by access key
     * @summary Download report by access key
     * @param {string} id
     * @param {boolean} [inline] Optional flag, set to true in order to get inlined response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminReportsDownloadGet(
      id: string,
      inline?: boolean,
      options?: any
    ): AxiosPromise<void> {
      return DefaultApiFp(configuration)
        .binChampadminAdminReportsDownloadGet(id, inline, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Download duplicate applications report
     * @summary Download duplicate applications report
     * @param {number} [champId] Optional filter by championship id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminReportsDuplicatesGet(champId?: number, options?: any): AxiosPromise<string> {
      return DefaultApiFp(configuration)
        .binChampadminAdminReportsDuplicatesGet(champId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Download exemption report
     * @summary Download \'Exemptions Chosen on Application Report\' report
     * @param {number} [champId] Optional filter by championship id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminReportsExemptionChosenGet(
      champId?: number,
      options?: any
    ): AxiosPromise<string> {
      return DefaultApiFp(configuration)
        .binChampadminAdminReportsExemptionChosenGet(champId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Download exemption report
     * @summary Download \'Exemptions Across All Championships\' report
     * @param {number} [champId] Optional filter by championship id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminReportsExemptionGet(champId?: number, options?: any): AxiosPromise<string> {
      return DefaultApiFp(configuration)
        .binChampadminAdminReportsExemptionGet(champId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Download notable players report
     * @summary Download notable players report
     * @param {number} [champId] Optional filter by championship id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminReportsNotablePlayersGet(
      champId?: number,
      options?: any
    ): AxiosPromise<string> {
      return DefaultApiFp(configuration)
        .binChampadminAdminReportsNotablePlayersGet(champId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Download list of profiles
     * @summary Download filtered list of profiles
     * @param {number} [limit] Per page
     * @param {number} [start] Page number
     * @param {string} [query] Query parameter to filter users based on: User Id, First Name, Last Name, Player Status, Date of Birth (e.g. query&#x3D;value*)
     * @param {PlayerFilters} [filter]
     * @param {string} [filterValue]
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {Array<number>} [playerId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminReportsProfilesGet(
      limit?: number,
      start?: number,
      query?: string,
      filter?: PlayerFilters,
      filterValue?: string,
      sortingFilter?: SortingFilterEnum,
      playerId?: Array<number>,
      options?: any
    ): AxiosPromise<void> {
      return DefaultApiFp(configuration)
        .binChampadminAdminReportsProfilesGet(
          limit,
          start,
          query,
          filter,
          filterValue,
          sortingFilter,
          playerId,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Download qualifying sites report
     * @summary Download qualifying sites report
     * @param {number} [champId] Optional filter by championship id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminReportsQualifyingSitesGet(
      champId?: number,
      options?: any
    ): AxiosPromise<string> {
      return DefaultApiFp(configuration)
        .binChampadminAdminReportsQualifyingSitesGet(champId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Download list of QS
     * @summary Download filtered list of QS
     * @param {number} champId
     * @param {number} [limit]
     * @param {number} [start]
     * @param {QualifyingSiteFilters} [filter]
     * @param {string} [filterValue]
     * @param {string} [startDate] Optional start date range
     * @param {string} [endDate] Optional end date range
     * @param {string} [query]
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {Array<number>} [id] Optional QS id multiple filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminReportsQualifyingsiteDownloadGet(
      champId: number,
      limit?: number,
      start?: number,
      filter?: QualifyingSiteFilters,
      filterValue?: string,
      startDate?: string,
      endDate?: string,
      query?: string,
      sortingFilter?: SortingFilterEnum,
      id?: Array<number>,
      options?: any
    ): AxiosPromise<void> {
      return DefaultApiFp(configuration)
        .binChampadminAdminReportsQualifyingsiteDownloadGet(
          champId,
          limit,
          start,
          filter,
          filterValue,
          startDate,
          endDate,
          query,
          sortingFilter,
          id,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Download refund applications report
     * @summary Download refund applications report
     * @param {number} [champId] Optional filter by championship id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminReportsRefundGet(champId?: number, options?: any): AxiosPromise<string> {
      return DefaultApiFp(configuration)
        .binChampadminAdminReportsRefundGet(champId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Download customer service report
     * @summary Download customer service report
     * @param {string} from
     * @param {string} to
     * @param {number} [champId] Championship ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminReportsServiceGet(
      from: string,
      to: string,
      champId?: number,
      options?: any
    ): AxiosPromise<string> {
      return DefaultApiFp(configuration)
        .binChampadminAdminReportsServiceGet(from, to, champId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Download watchlist report
     * @summary Download Watchlist across all champ report
     * @param {number} [champId] Optional filter by championship id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminReportsWatchlistGet(champId?: number, options?: any): AxiosPromise<string> {
      return DefaultApiFp(configuration)
        .binChampadminAdminReportsWatchlistGet(champId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Download filtered list of withdrawn applications
     * @summary Download filtered list of withdrawn applications
     * @param {number} champId championship id
     * @param {IdListModel} [idListModel] Optional qualifying site ids to filter by
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminReportsWithdrawalPost(
      champId: number,
      idListModel?: IdListModel,
      options?: any
    ): AxiosPromise<void> {
      return DefaultApiFp(configuration)
        .binChampadminAdminReportsWithdrawalPost(champId, idListModel, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Download withdrawn applications report
     * @summary Download withdrawn applications report
     * @param {number} [champId] Optional filter by championship id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminReportsWithdrawnGet(champId?: number, options?: any): AxiosPromise<string> {
      return DefaultApiFp(configuration)
        .binChampadminAdminReportsWithdrawnGet(champId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Updates player team
     * @summary Update team
     * @param {CreateTeamAdminModel} [createTeamAdminModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminTeamCreatePost(
      createTeamAdminModel?: CreateTeamAdminModel,
      options?: any
    ): AxiosPromise<IdModel> {
      return DefaultApiFp(configuration)
        .binChampadminAdminTeamCreatePost(createTeamAdminModel, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Is used to get team data
     * @summary Get player team
     * @param {string} id id of team to return data for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminTeamGet(id: string, options?: any): AxiosPromise<TeamAdminResponseModel> {
      return DefaultApiFp(configuration)
        .binChampadminAdminTeamGet(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Updates player team
     * @summary Update team
     * @param {TeamAdminRequestModel} [teamAdminRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminTeamPost(
      teamAdminRequestModel?: TeamAdminRequestModel,
      options?: any
    ): AxiosPromise<TeamAdminResponseModel> {
      return DefaultApiFp(configuration)
        .binChampadminAdminTeamPost(teamAdminRequestModel, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Upload Application Files
     * @param {number} champId Id of championship we uploading data for
     * @param {number} applicationId Id of application we uploading data for
     * @param {UploadedFileType} fileType
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminUploadApplicationFilePost(
      champId: number,
      applicationId: number,
      fileType: UploadedFileType,
      file?: any,
      options?: any
    ): AxiosPromise<string> {
      return DefaultApiFp(configuration)
        .binChampadminAdminUploadApplicationFilePost(
          champId,
          applicationId,
          fileType,
          file,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Create user
     * @param {UserCreateModel} [userCreateModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminUserCreatePost(
      userCreateModel?: UserCreateModel,
      options?: any
    ): AxiosPromise<UserResponseModel> {
      return DefaultApiFp(configuration)
        .binChampadminAdminUserCreatePost(userCreateModel, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Delete user by id
     * @summary Remove user by its id
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminUserDelete(userId: string, options?: any): AxiosPromise<void> {
      return DefaultApiFp(configuration)
        .binChampadminAdminUserDelete(userId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Is used to get user data
     * @summary Get user
     * @param {string} userId subscriber id of user to return user data for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminUserGet(userId: string, options?: any): AxiosPromise<UserResponseModel> {
      return DefaultApiFp(configuration)
        .binChampadminAdminUserGet(userId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns list of users
     * @summary Get list of users
     * @param {number} [limit] Per page
     * @param {number} [start] Starting position, value must be a multiple of limit parameter
     * @param {string} [query] Expression to filter users in lucene format (e.g. name:Val*)
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminUserListGet(
      limit?: number,
      start?: number,
      query?: string,
      sortingFilter?: SortingFilterEnum,
      options?: any
    ): AxiosPromise<UserAdminListModel> {
      return DefaultApiFp(configuration)
        .binChampadminAdminUserListGet(limit, start, query, sortingFilter, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Is used to get user data
     * @summary Get user
     * @param {string} userEmail user email to send reset password link
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminUserPasswordResetGet(userEmail: string, options?: any): AxiosPromise<void> {
      return DefaultApiFp(configuration)
        .binChampadminAdminUserPasswordResetGet(userEmail, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Updates user
     * @summary Update user
     * @param {UserResponseModel} [userResponseModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminUserPost(
      userResponseModel?: UserResponseModel,
      options?: any
    ): AxiosPromise<UserResponseModel> {
      return DefaultApiFp(configuration)
        .binChampadminAdminUserPost(userResponseModel, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Is used to update user data by auth0SearchQuery
     * @summary Update User type by utility
     * @param {string} id Champ type id to add for user type
     * @param {string} auth0SearchQuery User auth0 Search Query
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminAdminUtilityUserGet(
      id: string,
      auth0SearchQuery: string,
      options?: any
    ): AxiosPromise<void> {
      return DefaultApiFp(configuration)
        .binChampadminAdminUtilityUserGet(id, auth0SearchQuery, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns list of Champiobship Types
     * @summary Get list of Champ-types
     * @param {number} [limit]
     * @param {number} [start]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminCommonChampTypeListGet(
      limit?: number,
      start?: number,
      options?: any
    ): AxiosPromise<ChampTypeTitleIdListModel> {
      return DefaultApiFp(configuration)
        .binChampadminCommonChampTypeListGet(limit, start, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns Championship by Id
     * @summary Get Championship by Id
     * @param {number} champId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminCommonChampionshipGet(
      champId: number,
      options?: any
    ): AxiosPromise<ChampionshipPlayerModel> {
      return DefaultApiFp(configuration)
        .binChampadminCommonChampionshipGet(champId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Download info file by champ id
     * @summary Download info file by champ id
     * @param {number} champId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminCommonChampionshipInfoDownloadGet(
      champId: number,
      options?: any
    ): AxiosPromise<void> {
      return DefaultApiFp(configuration)
        .binChampadminCommonChampionshipInfoDownloadGet(champId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns the list of championship years
     * @summary Get list of years for championships
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminCommonChampionshipsYearsGet(options?: any): AxiosPromise<Array<number>> {
      return DefaultApiFp(configuration)
        .binChampadminCommonChampionshipsYearsGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns country by Id
     * @summary Get country by Id
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminCommonCountryGet(id: number, options?: any): AxiosPromise<CountryModel> {
      return DefaultApiFp(configuration)
        .binChampadminCommonCountryGet(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns list of countries
     * @summary Get list of countries
     * @param {number} [limit]
     * @param {number} [start]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminCommonCountryListGet(
      limit?: number,
      start?: number,
      options?: any
    ): AxiosPromise<CountryListModel> {
      return DefaultApiFp(configuration)
        .binChampadminCommonCountryListGet(limit, start, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns list of exemptions
     * @summary Get list of exemptions by championship Id and applicaton Id
     * @param {number} [champId]
     * @param {number} [appId]
     * @param {number} [limit]
     * @param {number} [start]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminCommonExemptionGet(
      champId?: number,
      appId?: number,
      limit?: number,
      start?: number,
      options?: any
    ): AxiosPromise<ExemptionPlayerListModel> {
      return DefaultApiFp(configuration)
        .binChampadminCommonExemptionGet(champId, appId, limit, start, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Returns USGA Footer Component
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminCommonFooterGet(options?: any): AxiosPromise<FooterModel> {
      return DefaultApiFp(configuration)
        .binChampadminCommonFooterGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Returns USGA Global Navigation Component
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminCommonNavigationGet(options?: any): AxiosPromise<GlobalNavigationModel> {
      return DefaultApiFp(configuration)
        .binChampadminCommonNavigationGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Impersonation check
     * @summary Check if current user is impersonating other player
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminCommonProfileLinkImpersonationGet(
      options?: any
    ): AxiosPromise<ProfilePlayerPersonalDataWithIdResponseModel> {
      return DefaultApiFp(configuration)
        .binChampadminCommonProfileLinkImpersonationGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns list of approved qualifying sites
     * @summary Get list of approved qualifying sites by championship Id
     * @param {Array<number>} [ids] Optionally filter by ids
     * @param {number} [champId]
     * @param {number} [limit]
     * @param {number} [start]
     * @param {number} [appId]
     * @param {string} [query]
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {string} [startDate] Optional start date range
     * @param {string} [endDate] Optional end date range
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminCommonQualifyingsiteGet(
      ids?: Array<number>,
      champId?: number,
      limit?: number,
      start?: number,
      appId?: number,
      query?: string,
      sortingFilter?: SortingFilterEnum,
      startDate?: string,
      endDate?: string,
      options?: any
    ): AxiosPromise<QualifyingSitePlayerListModel> {
      return DefaultApiFp(configuration)
        .binChampadminCommonQualifyingsiteGet(
          ids,
          champId,
          limit,
          start,
          appId,
          query,
          sortingFilter,
          startDate,
          endDate,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns state by Id
     * @summary Get state by Id
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminCommonStateGet(id: number, options?: any): AxiosPromise<StateModel> {
      return DefaultApiFp(configuration)
        .binChampadminCommonStateGet(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns list of states
     * @summary Get list of states
     * @param {StateTypeEnum} type
     * @param {number} [limit]
     * @param {number} [start]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminCommonStateListGet(
      type: StateTypeEnum,
      limit?: number,
      start?: number,
      options?: any
    ): AxiosPromise<StateListModel> {
      return DefaultApiFp(configuration)
        .binChampadminCommonStateListGet(type, limit, start, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Stripe webhook handler servlet
     * @param {object} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminCommonStripeWebhookPost(body?: object, options?: any): AxiosPromise<void> {
      return DefaultApiFp(configuration)
        .binChampadminCommonStripeWebhookPost(body, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Check if email is already in use
     * @param {string} userEmail
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminCommonUserCheckGet(userEmail: string, options?: any): AxiosPromise<void> {
      return DefaultApiFp(configuration)
        .binChampadminCommonUserCheckGet(userEmail, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Login player
     * @param {UserLoginPlayerModel} [userLoginPlayerModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminCommonUserLoginPost(
      userLoginPlayerModel?: UserLoginPlayerModel,
      options?: any
    ): AxiosPromise<void> {
      return DefaultApiFp(configuration)
        .binChampadminCommonUserLoginPost(userLoginPlayerModel, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * reset password endpoint
     * @summary Reset password
     * @param {string} userEmail user email to send reset password link
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminCommonUserPasswordResetGet(userEmail: string, options?: any): AxiosPromise<void> {
      return DefaultApiFp(configuration)
        .binChampadminCommonUserPasswordResetGet(userEmail, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Create player
     * @param {UserCreatePlayerModel} [userCreatePlayerModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminCommonUserRegisterPost(
      userCreatePlayerModel?: UserCreatePlayerModel,
      options?: any
    ): AxiosPromise<void> {
      return DefaultApiFp(configuration)
        .binChampadminCommonUserRegisterPost(userCreatePlayerModel, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns AGA by Id
     * @summary Get AGA by Id
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminOfficialAgaByIdGet(id: number, options?: any): AxiosPromise<AgaEditModel> {
      return DefaultApiFp(configuration)
        .binChampadminOfficialAgaByIdGet(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns AGA by User Id taken from token
     * @summary Get AGA by User Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminOfficialAgaByUserIdGet(options?: any): AxiosPromise<AgaEditModel> {
      return DefaultApiFp(configuration)
        .binChampadminOfficialAgaByUserIdGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns list of AGA
     * @summary Get list of AGA
     * @param {number} [limit]
     * @param {number} [start]
     * @param {string} [query] Optional search query to filter out AGA by names.
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminOfficialAgaGet(
      limit?: number,
      start?: number,
      query?: string,
      sortingFilter?: SortingFilterEnum,
      options?: any
    ): AxiosPromise<AgaAdminListModel> {
      return DefaultApiFp(configuration)
        .binChampadminOfficialAgaGet(limit, start, query, sortingFilter, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Download list of applications
     * @summary Download withrawn list of applications for specific QS
     * @param {number} qualifyingSiteId Qualifying site ID
     * @param {string} authorization authorization token
     * @param {string} [listType]
     * @param {IdListModel} [idListModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminOfficialApplicationsDownloadQsPost(
      qualifyingSiteId: number,
      authorization: string,
      listType?: string,
      idListModel?: IdListModel,
      options?: any
    ): AxiosPromise<void> {
      return DefaultApiFp(configuration)
        .binChampadminOfficialApplicationsDownloadQsPost(
          qualifyingSiteId,
          authorization,
          listType,
          idListModel,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns list of applications
     * @summary Get filtered list of applications
     * @param {number} year Championship year
     * @param {ApplicationFilters} filter
     * @param {number} [champId] Optional filter by championship id
     * @param {number} [limit]
     * @param {number} [start]
     * @param {string} [query] Optional full text search query
     * @param {string} [filterValue]
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminOfficialApplicationsGet(
      year: number,
      filter: ApplicationFilters,
      champId?: number,
      limit?: number,
      start?: number,
      query?: string,
      filterValue?: string,
      sortingFilter?: SortingFilterEnum,
      options?: any
    ): AxiosPromise<ApplicationAdminListModel> {
      return DefaultApiFp(configuration)
        .binChampadminOfficialApplicationsGet(
          year,
          filter,
          champId,
          limit,
          start,
          query,
          filterValue,
          sortingFilter,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns a list of calendar cards based on assigned championship types.
     * @summary Get calendar cards based on assigned championship types.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminOfficialCalendarGet(options?: any): AxiosPromise<OfficialsCalendarListModel> {
      return DefaultApiFp(configuration)
        .binChampadminOfficialCalendarGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns list of Champiobship Types
     * @summary Get list of Champ-types
     * @param {number} [limit]
     * @param {number} [start]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminOfficialChampTypeListGet(
      limit?: number,
      start?: number,
      options?: any
    ): AxiosPromise<ChampTypeTitleIdListModel> {
      return DefaultApiFp(configuration)
        .binChampadminOfficialChampTypeListGet(limit, start, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns a boolean value based on the entry reviewed or not.
     * @summary Return whether entry is reviewed or not
     * @param {string} [query]
     * @param {number} [year]
     * @param {WorkflowTypeEnum} [champType]
     * @param {number} [champTypeId]
     * @param {Array<string>} [fields] required fields to fetch, e.g. \&quot;aaa\&quot;, \&quot;bbb.ccc.ddd\&quot;
     * @param {boolean} [active] indicates if champ is active
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminOfficialChampionshipAllEntriesReviewedGet(
      query?: string,
      year?: number,
      champType?: WorkflowTypeEnum,
      champTypeId?: number,
      fields?: Array<string>,
      active?: boolean,
      options?: any
    ): AxiosPromise<object> {
      return DefaultApiFp(configuration)
        .binChampadminOfficialChampionshipAllEntriesReviewedGet(
          query,
          year,
          champType,
          champTypeId,
          fields,
          active,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns a list of championships based on assigned championship types.
     * @summary Get championships list based on assigned championship types.
     * @param {string} [query]
     * @param {number} [year]
     * @param {WorkflowTypeEnum} [champType]
     * @param {number} [champTypeId]
     * @param {boolean} [active] indicates if champ is active
     * @param {number} [start]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminOfficialChampionshipsGet(
      query?: string,
      year?: number,
      champType?: WorkflowTypeEnum,
      champTypeId?: number,
      active?: boolean,
      start?: number,
      limit?: number,
      options?: any
    ): AxiosPromise<StaffChampionshipListModel> {
      return DefaultApiFp(configuration)
        .binChampadminOfficialChampionshipsGet(
          query,
          year,
          champType,
          champTypeId,
          active,
          start,
          limit,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns a list of championships based on assigned championship types.
     * @summary [GraphQL]Get championships list based on assigned championship types.
     * @param {string} [query]
     * @param {number} [year]
     * @param {WorkflowTypeEnum} [champType]
     * @param {number} [champTypeId]
     * @param {Array<string>} [fields] required fields to fetch, e.g. \&quot;aaa\&quot;, \&quot;bbb.ccc.ddd\&quot;
     * @param {boolean} [active] indicates if champ is active
     * @param {number} [start]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminOfficialChampionshipsGraphqlGet(
      query?: string,
      year?: number,
      champType?: WorkflowTypeEnum,
      champTypeId?: number,
      fields?: Array<string>,
      active?: boolean,
      start?: number,
      limit?: number,
      options?: any
    ): AxiosPromise<object> {
      return DefaultApiFp(configuration)
        .binChampadminOfficialChampionshipsGraphqlGet(
          query,
          year,
          champType,
          champTypeId,
          fields,
          active,
          start,
          limit,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns list of clubs
     * @summary Get list of clubs
     * @param {number} [limit]
     * @param {number} [start]
     * @param {string} [query] Expression to filter entries (e.g. %word%)
     * @param {number} [champId]
     * @param {number} [year]
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {string} [countryFilter]
     * @param {string} [stateFilter]
     * @param {string} [cityFilter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminOfficialClubGet(
      limit?: number,
      start?: number,
      query?: string,
      champId?: number,
      year?: number,
      sortingFilter?: SortingFilterEnum,
      countryFilter?: string,
      stateFilter?: string,
      cityFilter?: string,
      options?: any
    ): AxiosPromise<ClubAdminListModel> {
      return DefaultApiFp(configuration)
        .binChampadminOfficialClubGet(
          limit,
          start,
          query,
          champId,
          year,
          sortingFilter,
          countryFilter,
          stateFilter,
          cityFilter,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Add new club
     * @summary Add new club
     * @param {ClubAdminAddModel} [clubAdminAddModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminOfficialClubPost(
      clubAdminAddModel?: ClubAdminAddModel,
      options?: any
    ): AxiosPromise<ClubAdminModel> {
      return DefaultApiFp(configuration)
        .binChampadminOfficialClubPost(clubAdminAddModel, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Is used to get club data by its id
     * @summary Get club info by id
     * @param {number} id id of club
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminOfficialClubQueryGet(id: number, options?: any): AxiosPromise<ClubAdminModel> {
      return DefaultApiFp(configuration)
        .binChampadminOfficialClubQueryGet(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Updates club
     * @summary Update club
     * @param {ClubAdminModel} [clubAdminModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminOfficialClubUpdatePost(
      clubAdminModel?: ClubAdminModel,
      options?: any
    ): AxiosPromise<void> {
      return DefaultApiFp(configuration)
        .binChampadminOfficialClubUpdatePost(clubAdminModel, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns list of points of contact
     * @summary Get list of points of contact
     * @param {number} [limit]
     * @param {number} [start]
     * @param {string} [query] Expression to filter entries (e.g. %word%)
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminOfficialPocGet(
      limit?: number,
      start?: number,
      query?: string,
      sortingFilter?: SortingFilterEnum,
      options?: any
    ): AxiosPromise<PointOfContactAdminListModel> {
      return DefaultApiFp(configuration)
        .binChampadminOfficialPocGet(limit, start, query, sortingFilter, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Add new point of contact
     * @summary Add new point of contact
     * @param {PointOfContactAdminAddModel} [pointOfContactAdminAddModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminOfficialPocPost(
      pointOfContactAdminAddModel?: PointOfContactAdminAddModel,
      options?: any
    ): AxiosPromise<PointOfContactAdminModel> {
      return DefaultApiFp(configuration)
        .binChampadminOfficialPocPost(pointOfContactAdminAddModel, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Is used to get poc data by its id
     * @summary Get poc info by id
     * @param {number} id id of poc
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminOfficialPocQueryGet(
      id: number,
      options?: any
    ): AxiosPromise<PointOfContactAdminModel> {
      return DefaultApiFp(configuration)
        .binChampadminOfficialPocQueryGet(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Updates point of contact
     * @summary Update point of contact
     * @param {PointOfContactAdminEditModel} [pointOfContactAdminEditModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminOfficialPocUpdatePost(
      pointOfContactAdminEditModel?: PointOfContactAdminEditModel,
      options?: any
    ): AxiosPromise<PointOfContactAdminEditModel> {
      return DefaultApiFp(configuration)
        .binChampadminOfficialPocUpdatePost(pointOfContactAdminEditModel, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Qualifying Site Player List
     * @param {number} qualifyingSiteId id of Qualifying Site
     * @param {QualifyingSiteReportListType} listType type of list to get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminOfficialQualifyingSitePlayerListGet(
      qualifyingSiteId: number,
      listType: QualifyingSiteReportListType,
      options?: any
    ): AxiosPromise<void> {
      return DefaultApiFp(configuration)
        .binChampadminOfficialQualifyingSitePlayerListGet(qualifyingSiteId, listType, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Checks whether it is possible to create a \'QualifyingSite\' with such data.
     * @summary Check if a combination of ChampId+Level+Number is still free
     * @param {number} champId
     * @param {string} level
     * @param {number} number
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminOfficialQualifyingsiteCheckGet(
      champId: number,
      level: string,
      number: number,
      options?: any
    ): AxiosPromise<void> {
      return DefaultApiFp(configuration)
        .binChampadminOfficialQualifyingsiteCheckGet(champId, level, number, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns list of qualifying sites
     * @summary Get list of qualifying sites by championship Id
     * @param {number} champId
     * @param {number} [limit]
     * @param {number} [start]
     * @param {QualifyingSiteFilters} [filter]
     * @param {string} [filterValue]
     * @param {string} [startDate] Optional start date range
     * @param {string} [endDate] Optional end date range
     * @param {string} [query]
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminOfficialQualifyingsiteGet(
      champId: number,
      limit?: number,
      start?: number,
      filter?: QualifyingSiteFilters,
      filterValue?: string,
      startDate?: string,
      endDate?: string,
      query?: string,
      sortingFilter?: SortingFilterEnum,
      options?: any
    ): AxiosPromise<QualifyingSiteAdminListModel> {
      return DefaultApiFp(configuration)
        .binChampadminOfficialQualifyingsiteGet(
          champId,
          limit,
          start,
          filter,
          filterValue,
          startDate,
          endDate,
          query,
          sortingFilter,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Add new qualifying site to championship
     * @summary Add new qualifying site to championship
     * @param {QualifyingSiteAddModel} [qualifyingSiteAddModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminOfficialQualifyingsitePost(
      qualifyingSiteAddModel?: QualifyingSiteAddModel,
      options?: any
    ): AxiosPromise<QualifyingSiteAdminModel> {
      return DefaultApiFp(configuration)
        .binChampadminOfficialQualifyingsitePost(qualifyingSiteAddModel, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Is used to get qualifying site data by its id
     * @summary Get qualifying site info by id
     * @param {number} id id of qualifying site
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminOfficialQualifyingsiteQueryGet(
      id: number,
      options?: any
    ): AxiosPromise<QualifyingSiteAdminModel> {
      return DefaultApiFp(configuration)
        .binChampadminOfficialQualifyingsiteQueryGet(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Updates QS
     * @summary Update qualifying site
     * @param {QualifyingSiteEditModel} [qualifyingSiteEditModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminOfficialQualifyingsiteUpdatePost(
      qualifyingSiteEditModel?: QualifyingSiteEditModel,
      options?: any
    ): AxiosPromise<void> {
      return DefaultApiFp(configuration)
        .binChampadminOfficialQualifyingsiteUpdatePost(qualifyingSiteEditModel, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Download report by access key
     * @summary Download report by access key
     * @param {string} id
     * @param {boolean} [inline] Optional flag, set to true in order to get inlined response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminOfficialReportsDownloadGet(
      id: string,
      inline?: boolean,
      options?: any
    ): AxiosPromise<void> {
      return DefaultApiFp(configuration)
        .binChampadminOfficialReportsDownloadGet(id, inline, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Is used to get saved user\'s data of specific championship
     * @summary Get player application
     * @param {number} champId Id of championship to return application data for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminPlayerApplicationGet(
      champId: number,
      options?: any
    ): AxiosPromise<ApplicationResponseModel> {
      return DefaultApiFp(configuration)
        .binChampadminPlayerApplicationGet(champId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Saves or updates player application
     * @summary Save player application
     * @param {number} [appId] id of another player to form team with
     * @param {ApplicationPlayerModel} [applicationPlayerModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminPlayerApplicationPost(
      appId?: number,
      applicationPlayerModel?: ApplicationPlayerModel,
      options?: any
    ): AxiosPromise<ApplicationResponseModel> {
      return DefaultApiFp(configuration)
        .binChampadminPlayerApplicationPost(appId, applicationPlayerModel, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get application data by id
     * @param {number} appId id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminPlayerApplicationQueryGet(
      appId: number,
      options?: any
    ): AxiosPromise<ApplicationPaymentHistoryResponseModel> {
      return DefaultApiFp(configuration)
        .binChampadminPlayerApplicationQueryGet(appId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Is used to get simplified application data
     * @summary Get basic player application data (for four-ball)
     * @param {number} appId id of application to return application data for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminPlayerApplicationSimplifiedGet(
      appId: number,
      options?: any
    ): AxiosPromise<ApplicationAnonResponseModel> {
      return DefaultApiFp(configuration)
        .binChampadminPlayerApplicationSimplifiedGet(appId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Upload Application Files
     * @param {number} champId Id of championship we uploading data for
     * @param {UploadedFileType} fileType
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminPlayerApplicationUploadPost(
      champId: number,
      fileType: UploadedFileType,
      file?: any,
      options?: any
    ): AxiosPromise<string> {
      return DefaultApiFp(configuration)
        .binChampadminPlayerApplicationUploadPost(champId, fileType, file, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Is used to get a list of applied applications.
     * @summary Returns list of apps player has applied to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminPlayerApplicationWithdrawGet(
      options?: any
    ): AxiosPromise<ApplicationAppliedListModel> {
      return DefaultApiFp(configuration)
        .binChampadminPlayerApplicationWithdrawGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Submit withdrawal request
     * @summary Submit withdrawal request
     * @param {ApplicationWithdrawRequestModel} [applicationWithdrawRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminPlayerApplicationWithdrawPost(
      applicationWithdrawRequestModel?: ApplicationWithdrawRequestModel,
      options?: any
    ): AxiosPromise<ApplicationWithdrawResponseModel> {
      return DefaultApiFp(configuration)
        .binChampadminPlayerApplicationWithdrawPost(applicationWithdrawRequestModel, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns list of applications for player
     * @summary Get filtered list of applications for player history
     * @param {WorkflowTypeEnum} [workflowType] Optional filter by championship type
     * @param {number} [year] Championship year
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {number} [champTypeId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminPlayerApplicationsHistoryGet(
      workflowType?: WorkflowTypeEnum,
      year?: number,
      sortingFilter?: SortingFilterEnum,
      champTypeId?: number,
      options?: any
    ): AxiosPromise<ApplicationHistoryListModel> {
      return DefaultApiFp(configuration)
        .binChampadminPlayerApplicationsHistoryGet(
          workflowType,
          year,
          sortingFilter,
          champTypeId,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns list of ids for championships with existing applications in status not DRAFT
     * @summary Get list of paid championships
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminPlayerChampionshipsGet(options?: any): AxiosPromise<ChampionshipPlayerInfoModel> {
      return DefaultApiFp(configuration)
        .binChampadminPlayerChampionshipsGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get status list of requested subscriptions
     * @summary Get status list of requested subscriptions
     * @param {Array<EmailSubscriptionType>} [types]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminPlayerEmailSubscriptionsGet(
      types?: Array<EmailSubscriptionType>,
      options?: any
    ): AxiosPromise<EmailSubscriptionsStatusList> {
      return DefaultApiFp(configuration)
        .binChampadminPlayerEmailSubscriptionsGet(types, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Update subscriptions
     * @summary Update subscriptions
     * @param {EmailSubscriptionsRequestModel} [emailSubscriptionsRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminPlayerEmailSubscriptionsPost(
      emailSubscriptionsRequestModel?: EmailSubscriptionsRequestModel,
      options?: any
    ): AxiosPromise<void> {
      return DefaultApiFp(configuration)
        .binChampadminPlayerEmailSubscriptionsPost(emailSubscriptionsRequestModel, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Is used to check availability of the handicap service.
     * @summary Check handicap service.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminPlayerHandicapCheckGet(options?: any): AxiosPromise<void> {
      return DefaultApiFp(configuration)
        .binChampadminPlayerHandicapCheckGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get handicap player by handicap id
     * @param {string} handicapId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminPlayerHandicapGet(
      handicapId: string,
      options?: any
    ): AxiosPromise<Array<HandicapPlayerModel>> {
      return DefaultApiFp(configuration)
        .binChampadminPlayerHandicapGet(handicapId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns list of messages
     * @summary Get list of messages by current user Id
     * @param {number} [limit]
     * @param {number} [start]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminPlayerMessageGet(
      limit?: number,
      start?: number,
      options?: any
    ): AxiosPromise<MessagePlayerListModel> {
      return DefaultApiFp(configuration)
        .binChampadminPlayerMessageGet(limit, start, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Hide message for player
     * @summary Hide specific message for particular player
     * @param {MessageHideRequestModel} [messageHideRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminPlayerMessageHidePost(
      messageHideRequestModel?: MessageHideRequestModel,
      options?: any
    ): AxiosPromise<void> {
      return DefaultApiFp(configuration)
        .binChampadminPlayerMessageHidePost(messageHideRequestModel, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get form data to make post request to Payment processor
     * @param {number} appId Application id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminPlayerPaymentGet(
      appId: number,
      options?: any
    ): AxiosPromise<PaymentDataPlayerResponseModel> {
      return DefaultApiFp(configuration)
        .binChampadminPlayerPaymentGet(appId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Is used to get profile data
     * @summary Get player profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminPlayerProfileGet(options?: any): AxiosPromise<LinkedProfilePlayerResponseModel> {
      return DefaultApiFp(configuration)
        .binChampadminPlayerProfileGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Accept request to manage profile
     * @summary Accept request to manage profile
     * @param {ProfileLinkRequestModel} [profileLinkRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminPlayerProfileLinkAcceptPost(
      profileLinkRequestModel?: ProfileLinkRequestModel,
      options?: any
    ): AxiosPromise<void> {
      return DefaultApiFp(configuration)
        .binChampadminPlayerProfileLinkAcceptPost(profileLinkRequestModel, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Send request to start impersonation session
     * @summary Start impersonation session
     * @param {ProfileLinkRequestModel} [profileLinkRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminPlayerProfileLinkImpersonationPost(
      profileLinkRequestModel?: ProfileLinkRequestModel,
      options?: any
    ): AxiosPromise<ImpersonationCookieModel> {
      return DefaultApiFp(configuration)
        .binChampadminPlayerProfileLinkImpersonationPost(profileLinkRequestModel, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Send request to stop impersonation session
     * @summary Stop impersonation session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminPlayerProfileLinkImpersonationStopPost(
      options?: any
    ): AxiosPromise<PagePathModel> {
      return DefaultApiFp(configuration)
        .binChampadminPlayerProfileLinkImpersonationStopPost(options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Impersonation check
     * @summary Return impersonator profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminPlayerProfileLinkImpersonatorGet(
      options?: any
    ): AxiosPromise<ProfilePlayerPersonalDataWithIdResponseModel> {
      return DefaultApiFp(configuration)
        .binChampadminPlayerProfileLinkImpersonatorGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Update player profile
     * @summary Update managed profile
     * @param {ProfilePlayerResponseModel} [profilePlayerResponseModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminPlayerProfileLinkManagePost(
      profilePlayerResponseModel?: ProfilePlayerResponseModel,
      options?: any
    ): AxiosPromise<ProfilePlayerResponseModel> {
      return DefaultApiFp(configuration)
        .binChampadminPlayerProfileLinkManagePost(profilePlayerResponseModel, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Send request to add managed profile
     * @summary Send request to add managed profile
     * @param {EmailModel} [emailModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminPlayerProfileLinkRequestPost(
      emailModel?: EmailModel,
      options?: any
    ): AxiosPromise<void> {
      return DefaultApiFp(configuration)
        .binChampadminPlayerProfileLinkRequestPost(emailModel, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Updates player profile
     * @summary Save/update player profile
     * @param {ProfilePlayerRequestModel} [profilePlayerRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminPlayerProfilePost(
      profilePlayerRequestModel?: ProfilePlayerRequestModel,
      options?: any
    ): AxiosPromise<LinkedProfilePlayerResponseModel> {
      return DefaultApiFp(configuration)
        .binChampadminPlayerProfilePost(profilePlayerRequestModel, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Accept request to manage profile
     * @summary Unlink player profile
     * @param {ProfileUnlinkRequestModel} [profileUnlinkRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminPlayerProfileUnlinkPost(
      profileUnlinkRequestModel?: ProfileUnlinkRequestModel,
      options?: any
    ): AxiosPromise<void> {
      return DefaultApiFp(configuration)
        .binChampadminPlayerProfileUnlinkPost(profileUnlinkRequestModel, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Is used to get qualifying site data by its id
     * @summary Get qualifying site info by id
     * @param {number} id id of qualifying site
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminPlayerQualifyingsiteQueryGet(
      id: number,
      options?: any
    ): AxiosPromise<QualifyingSitePlayerFullModel> {
      return DefaultApiFp(configuration)
        .binChampadminPlayerQualifyingsiteQueryGet(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Is used to Release all Reserved spot data
     * @summary Release All Reserves a spot
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminPlayerReleaseAllReserveSpotGet(options?: any): AxiosPromise<void> {
      return DefaultApiFp(configuration)
        .binChampadminPlayerReleaseAllReserveSpotGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Is used to Release Reserved spot data by its id
     * @summary Release Reserves a spot info by id
     * @param {number} id id of reserved slot
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminPlayerReleaseReserveSpotGet(id: number, options?: any): AxiosPromise<void> {
      return DefaultApiFp(configuration)
        .binChampadminPlayerReleaseReserveSpotGet(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Edit a reserved spot
     * @param {ReserveSpotEditModel} [reserveSpotEditModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminPlayerReserveSpotEditPost(
      reserveSpotEditModel?: ReserveSpotEditModel,
      options?: any
    ): AxiosPromise<ReserveSpotResponseModel> {
      return DefaultApiFp(configuration)
        .binChampadminPlayerReserveSpotEditPost(reserveSpotEditModel, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Reserves a spot
     * @param {ReserveSpotCreateModel} [reserveSpotCreateModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminPlayerReserveSpotPost(
      reserveSpotCreateModel?: ReserveSpotCreateModel,
      options?: any
    ): AxiosPromise<ReserveSpotResponseModel> {
      return DefaultApiFp(configuration)
        .binChampadminPlayerReserveSpotPost(reserveSpotCreateModel, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get list of upcoming events
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminPlayerUpcomingEventsGet(options?: any): AxiosPromise<UpcomingEventListModel> {
      return DefaultApiFp(configuration)
        .binChampadminPlayerUpcomingEventsGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary AR Request for player
     * @param {ARPlayerRequestModel} [aRPlayerRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminSecureArequestPlayerPost(
      aRPlayerRequestModel?: ARPlayerRequestModel,
      options?: any
    ): AxiosPromise<void> {
      return DefaultApiFp(configuration)
        .binChampadminSecureArequestPlayerPost(aRPlayerRequestModel, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Download list of applications
     * @summary Download filtered list of applications
     * @param {number} year Championship year
     * @param {ApplicationFilters} filter
     * @param {number} [champId] Optional filter by championship id
     * @param {number} [limit]
     * @param {number} [start]
     * @param {string} [query] Optional full text search query
     * @param {string} [filterValue]
     * @param {IdListModel} [idListModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminStaffApplicationsDownloadPost(
      year: number,
      filter: ApplicationFilters,
      champId?: number,
      limit?: number,
      start?: number,
      query?: string,
      filterValue?: string,
      idListModel?: IdListModel,
      options?: any
    ): AxiosPromise<void> {
      return DefaultApiFp(configuration)
        .binChampadminStaffApplicationsDownloadPost(
          year,
          filter,
          champId,
          limit,
          start,
          query,
          filterValue,
          idListModel,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns list of applications
     * @summary Get filtered list of applications
     * @param {number} year Championship year
     * @param {ApplicationFilters} filter
     * @param {number} [champId] Optional filter by championship id
     * @param {number} [limit]
     * @param {number} [start]
     * @param {string} [query] Optional full text search query
     * @param {string} [disabilityTypeFilter]
     * @param {string} [filterValue]
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminStaffApplicationsGet(
      year: number,
      filter: ApplicationFilters,
      champId?: number,
      limit?: number,
      start?: number,
      query?: string,
      disabilityTypeFilter?: string,
      filterValue?: string,
      sortingFilter?: SortingFilterEnum,
      options?: any
    ): AxiosPromise<ApplicationAdminListModel> {
      return DefaultApiFp(configuration)
        .binChampadminStaffApplicationsGet(
          year,
          filter,
          champId,
          limit,
          start,
          query,
          disabilityTypeFilter,
          filterValue,
          sortingFilter,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns list of Champiobship Types
     * @summary Get list of Champ-types
     * @param {number} [limit]
     * @param {number} [start]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminStaffChampTypeListGet(
      limit?: number,
      start?: number,
      options?: any
    ): AxiosPromise<ChampTypeTitleIdListModel> {
      return DefaultApiFp(configuration)
        .binChampadminStaffChampTypeListGet(limit, start, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Downloading reports
     * @summary Downloading ZIP file with championship reports
     * @param {number} champId
     * @param {StaffChampionshipReportsTypeEnum} reportType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminStaffChampionshipReportDownloadGet(
      champId: number,
      reportType: StaffChampionshipReportsTypeEnum,
      options?: any
    ): AxiosPromise<void> {
      return DefaultApiFp(configuration)
        .binChampadminStaffChampionshipReportDownloadGet(champId, reportType, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns a list of championships based on assigned championship types.
     * @summary Get championships list based on assigned championship types.
     * @param {string} [query]
     * @param {number} [year]
     * @param {WorkflowTypeEnum} [champType]
     * @param {number} [champTypeId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminStaffChampionshipsGet(
      query?: string,
      year?: number,
      champType?: WorkflowTypeEnum,
      champTypeId?: number,
      options?: any
    ): AxiosPromise<StaffChampionshipListModel> {
      return DefaultApiFp(configuration)
        .binChampadminStaffChampionshipsGet(query, year, champType, champTypeId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns a list of championships based on assigned championship types.
     * @summary [GraphQL] Get championships list based on assigned championship types.
     * @param {string} [query]
     * @param {number} [year]
     * @param {WorkflowTypeEnum} [champType]
     * @param {number} [champTypeId]
     * @param {Array<string>} [fields] required fields to fetch, e.g. \&quot;aaa\&quot;, \&quot;bbb.ccc.ddd\&quot;
     * @param {boolean} [active] indicates if champ is active
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminStaffChampionshipsGraphqlGet(
      query?: string,
      year?: number,
      champType?: WorkflowTypeEnum,
      champTypeId?: number,
      fields?: Array<string>,
      active?: boolean,
      options?: any
    ): AxiosPromise<object> {
      return DefaultApiFp(configuration)
        .binChampadminStaffChampionshipsGraphqlGet(
          query,
          year,
          champType,
          champTypeId,
          fields,
          active,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Is used to get championship data by its id
     * @summary Get championship info by id
     * @param {number} id id of championship
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminStaffChampionshipsQueryGet(
      id: number,
      options?: any
    ): AxiosPromise<ChampionshipAdminModel> {
      return DefaultApiFp(configuration)
        .binChampadminStaffChampionshipsQueryGet(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns list of exemptions
     * @summary Get list of exemptions by championship Id
     * @param {number} champId
     * @param {number} [limit]
     * @param {number} [start]
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {QualifyingLevelEnum} [filter] Optional Filter
     * @param {string} [query] Optional search query parameter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminStaffExemptionGet(
      champId: number,
      limit?: number,
      start?: number,
      sortingFilter?: SortingFilterEnum,
      filter?: QualifyingLevelEnum,
      query?: string,
      options?: any
    ): AxiosPromise<ExemptionAdminListModel> {
      return DefaultApiFp(configuration)
        .binChampadminStaffExemptionGet(
          champId,
          limit,
          start,
          sortingFilter,
          filter,
          query,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Download file by id
     * @summary Download file by id
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminStaffFileGet(id: number, options?: any): AxiosPromise<void> {
      return DefaultApiFp(configuration)
        .binChampadminStaffFileGet(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns Champiobship Type by id
     * @summary Get Applications Pairs by ChampId
     * @param {number} champId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminStaffPairGet(champId: number, options?: any): AxiosPromise<PairListResponseModel> {
      return DefaultApiFp(configuration)
        .binChampadminStaffPairGet(champId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns list of pairs
     * @summary Get filtered list of pairs
     * @param {number} limit
     * @param {number} start
     * @param {number} playerId
     * @param {string} [query] Optional search query
     * @param {PairSortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminStaffPairHistoryGet(
      limit: number,
      start: number,
      playerId: number,
      query?: string,
      sortingFilter?: PairSortingFilterEnum,
      options?: any
    ): AxiosPromise<PairHistoryListModel> {
      return DefaultApiFp(configuration)
        .binChampadminStaffPairHistoryGet(limit, start, playerId, query, sortingFilter, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Create/Update Applications Pairs
     * @param {PairListRequestModel} [pairListRequestModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminStaffPairPost(
      pairListRequestModel?: PairListRequestModel,
      options?: any
    ): AxiosPromise<void> {
      return DefaultApiFp(configuration)
        .binChampadminStaffPairPost(pairListRequestModel, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns list of qualifying sites
     * @summary Get list of qualifying sites by championship Id
     * @param {number} champId
     * @param {number} [limit]
     * @param {number} [start]
     * @param {QualifyingSiteFilters} [filter]
     * @param {string} [filterValue]
     * @param {string} [startDate] Optional start date range
     * @param {string} [endDate] Optional end date range
     * @param {string} [query]
     * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminStaffQualifyingsiteGet(
      champId: number,
      limit?: number,
      start?: number,
      filter?: QualifyingSiteFilters,
      filterValue?: string,
      startDate?: string,
      endDate?: string,
      query?: string,
      sortingFilter?: SortingFilterEnum,
      options?: any
    ): AxiosPromise<QualifyingSiteAdminListModel> {
      return DefaultApiFp(configuration)
        .binChampadminStaffQualifyingsiteGet(
          champId,
          limit,
          start,
          filter,
          filterValue,
          startDate,
          endDate,
          query,
          sortingFilter,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Download customer service report
     * @summary Red Book report
     * @param {number} champId Championship ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminStaffRedbookReportGet(champId: number, options?: any): AxiosPromise<string> {
      return DefaultApiFp(configuration)
        .binChampadminStaffRedbookReportGet(champId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Download report by access key
     * @summary Download report by access key
     * @param {string} id
     * @param {boolean} [inline] Optional flag, set to true in order to get inlined response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminStaffReportsDownloadGet(
      id: string,
      inline?: boolean,
      options?: any
    ): AxiosPromise<void> {
      return DefaultApiFp(configuration)
        .binChampadminStaffReportsDownloadGet(id, inline, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Returns Back To Official Home Page Button Component
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    champAdminComponentsContentOfficialsBackToHomePageButtonModelJsonGet(
      options?: any
    ): AxiosPromise<BackToListOfficialHomeModel> {
      return DefaultApiFp(configuration)
        .champAdminComponentsContentOfficialsBackToHomePageButtonModelJsonGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Dummy
     * @summary Dummy
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    champAdminComponentsDummyFetchModelsGet(options?: any): AxiosPromise<InlineResponse2002> {
      return DefaultApiFp(configuration)
        .champAdminComponentsDummyFetchModelsGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Dummy
     * @summary Dummy
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    champAdminComponentsDummyLinkerGet(options?: any): AxiosPromise<InlineResponse200> {
      return DefaultApiFp(configuration)
        .champAdminComponentsDummyLinkerGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Dummy
     * @summary Dummy
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    champAdminComponentsDummyValidateLinkerGet(options?: any): AxiosPromise<InlineResponse2001> {
      return DefaultApiFp(configuration)
        .champAdminComponentsDummyValidateLinkerGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Dummy
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    champAdminServiceWaitlistResponeDummyLinkerGet(
      options?: any
    ): AxiosPromise<WaitListResponseModel> {
      return DefaultApiFp(configuration)
        .champAdminServiceWaitlistResponeDummyLinkerGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get list of available options for Resend Email dropdown
     * @summary Get list of available options for Resend Email dropdown
     * @param {number} appId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBinChampadminAdminEmailResend(
      appId: number,
      options?: any
    ): AxiosPromise<Array<ResendEmailFilterEnum>> {
      return DefaultApiFp(configuration)
        .getBinChampadminAdminEmailResend(appId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * get-bin-champadmin-admin-lastmod
     * @summary Get last modified info
     * @param {EntitiesTypeEnum} entityName
     * @param {Array<number>} idList
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBinChampadminAdminLastmod(
      entityName: EntitiesTypeEnum,
      idList: Array<number>,
      options?: any
    ): AxiosPromise<Array<LastModifiedResponseModel>> {
      return DefaultApiFp(configuration)
        .getBinChampadminAdminLastmod(entityName, idList, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Upload Championship PDF Files
     * @param {number} champId Id of championship we uploading data for
     * @param {UploadedPdfFileType} fileType
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postBinChampadminAdminChampionshipPdfUpload(
      champId: number,
      fileType: UploadedPdfFileType,
      file?: any,
      options?: any
    ): AxiosPromise<string> {
      return DefaultApiFp(configuration)
        .postBinChampadminAdminChampionshipPdfUpload(champId, fileType, file, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Upload Championship Results
     * @param {number} champId Id of championship we uploading data for
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postBinChampadminAdminChampionshipScoringUpload(
      champId: number,
      file?: any,
      options?: any
    ): AxiosPromise<Array<string>> {
      return DefaultApiFp(configuration)
        .postBinChampadminAdminChampionshipScoringUpload(champId, file, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Upload Championship Violation data
     * @param {number} champId Id of championship we uploading data for
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postBinChampadminAdminChampionshipViolationUpload(
      champId: number,
      file?: any,
      options?: any
    ): AxiosPromise<Array<string>> {
      return DefaultApiFp(configuration)
        .postBinChampadminAdminChampionshipViolationUpload(champId, file, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Upload Final Qualifying Results (.xls files)
     * @param {number} champId Id of championship we uploading data for
     * @param {number} [qsId] Id of qualifying site we uploading data for
     * @param {boolean} [isAgaPage] Optional flag, identify when request from AGA page
     * @param {string} [scoringUrl] Optional field(required on AGA page), for QS scoringUrl
     * @param {string} [courseRating] Optional field(required on AGA page), for QS courseRating
     * @param {string} [expertCertifiedRulesName] Optional field, for QS expertCertifiedRulesName, used on AGA page
     * @param {boolean} [expertCertifiedRules] Optional field, for QS expertCertifiedRules, used on AGA page
     * @param {boolean} [strokePolicyViolations] Optional field, for QS strokePolicyViolations, used on AGA page
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postBinChampadminOfficialQualifyingFinalScoringUpload(
      champId: number,
      qsId?: number,
      isAgaPage?: boolean,
      scoringUrl?: string,
      courseRating?: string,
      expertCertifiedRulesName?: string,
      expertCertifiedRules?: boolean,
      strokePolicyViolations?: boolean,
      file?: any,
      options?: any
    ): AxiosPromise<Array<string>> {
      return DefaultApiFp(configuration)
        .postBinChampadminOfficialQualifyingFinalScoringUpload(
          champId,
          qsId,
          isAgaPage,
          scoringUrl,
          courseRating,
          expertCertifiedRulesName,
          expertCertifiedRules,
          strokePolicyViolations,
          file,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Upload Local Qualifying Results (.xls files)
     * @param {number} champId Id of championship we uploading data for
     * @param {number} [qsId] Id of qualifying site we uploading data for
     * @param {boolean} [isAgaPage] Optional flag, identify when request from AGA page
     * @param {string} [scoringUrl] Optional field(required on AGA page), for QS scoringUrl
     * @param {string} [courseRating] Optional field(required on AGA page), for QS courseRating
     * @param {string} [expertCertifiedRulesName] Optional field, for QS expertCertifiedRulesName, used on AGA page
     * @param {boolean} [expertCertifiedRules] Optional field, for QS expertCertifiedRules, used on AGA page
     * @param {boolean} [strokePolicyViolations] Optional field, for QS strokePolicyViolations, used on AGA page
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postBinChampadminOfficialQualifyingLocalScoringUpload(
      champId: number,
      qsId?: number,
      isAgaPage?: boolean,
      scoringUrl?: string,
      courseRating?: string,
      expertCertifiedRulesName?: string,
      expertCertifiedRules?: boolean,
      strokePolicyViolations?: boolean,
      file?: any,
      options?: any
    ): AxiosPromise<Array<string>> {
      return DefaultApiFp(configuration)
        .postBinChampadminOfficialQualifyingLocalScoringUpload(
          champId,
          qsId,
          isAgaPage,
          scoringUrl,
          courseRating,
          expertCertifiedRulesName,
          expertCertifiedRules,
          strokePolicyViolations,
          file,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Upload Qualifying Results (.xls files)
     * @param {number} champId Id of championship we uploading data for
     * @param {number} [qsId] Id of qualifying site we uploading data for
     * @param {boolean} [isAgaPage] Optional flag, identify when request from AGA page
     * @param {string} [scoringUrl] Optional field(required on AGA page), for QS scoringUrl
     * @param {string} [courseRating] Optional field(required on AGA page), for QS courseRating
     * @param {string} [expertCertifiedRulesName] Optional field, for QS expertCertifiedRulesName, used on AGA page
     * @param {boolean} [expertCertifiedRules] Optional field, for QS expertCertifiedRules, used on AGA page
     * @param {boolean} [strokePolicyViolations] Optional field, for QS strokePolicyViolations, used on AGA page
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postBinChampadminOfficialQualifyingScoringUpload(
      champId: number,
      qsId?: number,
      isAgaPage?: boolean,
      scoringUrl?: string,
      courseRating?: string,
      expertCertifiedRulesName?: string,
      expertCertifiedRules?: boolean,
      strokePolicyViolations?: boolean,
      file?: any,
      options?: any
    ): AxiosPromise<Array<string>> {
      return DefaultApiFp(configuration)
        .postBinChampadminOfficialQualifyingScoringUpload(
          champId,
          qsId,
          isAgaPage,
          scoringUrl,
          courseRating,
          expertCertifiedRulesName,
          expertCertifiedRules,
          strokePolicyViolations,
          file,
          options
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
  /**
   *
   * @summary Sendgrid send mail EP
   * @param {EmailTemplateWithAttachedFilesModel} [emailTemplateWithAttachedFilesModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public apiSendgridComV3MailSendPost(
    emailTemplateWithAttachedFilesModel?: EmailTemplateWithAttachedFilesModel,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .apiSendgridComV3MailSendPost(emailTemplateWithAttachedFilesModel, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Delete AGA by id
   * @summary Remove AGA by its id
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminAgaByIdDelete(id: number, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminAgaByIdDelete(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns AGA by Id
   * @summary Get AGA by Id
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminAgaByIdGet(id: number, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminAgaByIdGet(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns list of AGA
   * @summary Get list of AGA
   * @param {number} [limit]
   * @param {number} [start]
   * @param {string} [query] Optional search query to filter out AGA by names.
   * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminAgaGet(
    limit?: number,
    start?: number,
    query?: string,
    sortingFilter?: SortingFilterEnum,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminAgaGet(limit, start, query, sortingFilter, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Submit new AGA
   * @summary Save new AGA
   * @param {AgaBaseModel} [agaBaseModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminAgaPost(agaBaseModel?: AgaBaseModel, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminAgaPost(agaBaseModel, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Updates AGA entry
   * @summary Update AGA
   * @param {AgaEditModel} [agaEditModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminAgaUpdatePost(agaEditModel?: AgaEditModel, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminAgaUpdatePost(agaEditModel, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns list of AGA
   * @summary Get filtered list of AGA Users
   * @param {number} [limit]
   * @param {number} [start]
   * @param {string} [query] Expression to filter entries (e.g. %word%)
   * @param {number} [champId]
   * @param {number} [year]
   * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminAgaUsersListGet(
    limit?: number,
    start?: number,
    query?: string,
    champId?: number,
    year?: number,
    sortingFilter?: SortingFilterEnum,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminAgaUsersListGet(limit, start, query, champId, year, sortingFilter, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Saves or updates player application
   * @summary Create new player application
   * @param {string} auth0Id Auth0Id of player to create application for
   * @param {ApplicationProgressionEnum} applicationStatus Target Application Status
   * @param {number} [appId] id of another player app to form team with
   * @param {ApplicationPlayerModel} [applicationPlayerModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminApplicationCreatePost(
    auth0Id: string,
    applicationStatus: ApplicationProgressionEnum,
    appId?: number,
    applicationPlayerModel?: ApplicationPlayerModel,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminApplicationCreatePost(
        auth0Id,
        applicationStatus,
        appId,
        applicationPlayerModel,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Is used to get application data
   * @summary Get player application
   * @param {number} appId id of application to return application data for
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminApplicationGet(appId: number, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminApplicationGet(appId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Updates player application
   * @summary Save player application
   * @param {ApplicationAdminRequestModel} [applicationAdminRequestModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminApplicationPost(
    applicationAdminRequestModel?: ApplicationAdminRequestModel,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminApplicationPost(applicationAdminRequestModel, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get championship\'s applications summary
   * @param {number} champId id of championship to return applications summary
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminApplicationSummaryGet(champId: number, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminApplicationSummaryGet(champId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns status that indicates was application updated or not
   * @summary Re-Validate application reinstatement
   * @param {number} id Id to verify by
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminApplicationValidateARGet(id: number, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminApplicationValidateARGet(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns multi filtered list of applications
   * @summary Get filtered list of applications
   * @param {number} champId Filter by championship id
   * @param {number} [limit]
   * @param {number} [start]
   * @param {string} [query] Optional full text search query
   * @param {Array<BlacklistStatusEnum>} [blacklistStatus]
   * @param {Array<ApplicationProgressionEnum>} [applicationStatus]
   * @param {Array<number>} [qualifyingSiteIds]
   * @param {Array<number>} [exemptionIds]
   * @param {QualifyingStatusEnum} [qualifyingStatus]
   * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminApplicationsBulkWithdrawGet(
    champId: number,
    limit?: number,
    start?: number,
    query?: string,
    blacklistStatus?: Array<BlacklistStatusEnum>,
    applicationStatus?: Array<ApplicationProgressionEnum>,
    qualifyingSiteIds?: Array<number>,
    exemptionIds?: Array<number>,
    qualifyingStatus?: QualifyingStatusEnum,
    sortingFilter?: SortingFilterEnum,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminApplicationsBulkWithdrawGet(
        champId,
        limit,
        start,
        query,
        blacklistStatus,
        applicationStatus,
        qualifyingSiteIds,
        exemptionIds,
        qualifyingStatus,
        sortingFilter,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns list of applications
   * @summary Get filtered list of applications for custom email
   * @param {number} [limit]
   * @param {number} [start]
   * @param {number} [year] Optional Filter by year id
   * @param {number} [champId] Optional Filter by championship id
   * @param {string} [query] Optional First/Last Name search query
   * @param {string} [startDate] Optional start qualifying date range
   * @param {string} [endDate] Optional end qualifying date range
   * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
   * @param {PlayerStatusEnum} [playerStatus] Optional Player Status Filter
   * @param {ApplicationProgressionEnum} [applicationStatus] Optional Application Status Filter
   * @param {QualifyingStatusEnum} [qualifyingStatus] Optional Application Status Filter
   * @param {string} [wagrRank] Optional WAGR Rank Filter
   * @param {boolean} [notablePlayer] Optional Notable Player Filter
   * @param {boolean} [adaRequest] Optional ADA Request Filter
   * @param {BlacklistStatusEnum} [blacklist] Optional Blacklist Filter
   * @param {boolean} [watchlist] Optional Watchlist Filter
   * @param {number} [age] Optional Age Filter
   * @param {string} [handicapIndex] Optional Handicap Index Filter
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminApplicationsCustomEmailGet(
    limit?: number,
    start?: number,
    year?: number,
    champId?: number,
    query?: string,
    startDate?: string,
    endDate?: string,
    sortingFilter?: SortingFilterEnum,
    playerStatus?: PlayerStatusEnum,
    applicationStatus?: ApplicationProgressionEnum,
    qualifyingStatus?: QualifyingStatusEnum,
    wagrRank?: string,
    notablePlayer?: boolean,
    adaRequest?: boolean,
    blacklist?: BlacklistStatusEnum,
    watchlist?: boolean,
    age?: number,
    handicapIndex?: string,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminApplicationsCustomEmailGet(
        limit,
        start,
        year,
        champId,
        query,
        startDate,
        endDate,
        sortingFilter,
        playerStatus,
        applicationStatus,
        qualifyingStatus,
        wagrRank,
        notablePlayer,
        adaRequest,
        blacklist,
        watchlist,
        age,
        handicapIndex,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns list of applications
   * @summary Get filtered list of applications for final confirmation
   * @param {number} champId Filter by championship id
   * @param {ApplicationFinalConfirmationBulkFilterEnum} playerFilter Plyer filter
   * @param {number} [limit]
   * @param {number} [start]
   * @param {string} [query] Optional First/Last Name search query
   * @param {string} [startDate] Optional start qualifying date range
   * @param {string} [endDate] Optional end qualifying date range
   * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminApplicationsFinalConfirmationGet(
    champId: number,
    playerFilter: ApplicationFinalConfirmationBulkFilterEnum,
    limit?: number,
    start?: number,
    query?: string,
    startDate?: string,
    endDate?: string,
    sortingFilter?: SortingFilterEnum,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminApplicationsFinalConfirmationGet(
        champId,
        playerFilter,
        limit,
        start,
        query,
        startDate,
        endDate,
        sortingFilter,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns list of applications
   * @summary Get filtered list of applications
   * @param {number} year Championship year
   * @param {ApplicationFilters} filter
   * @param {number} [champId] Optional filter by championship id
   * @param {number} [limit]
   * @param {number} [start]
   * @param {string} [query] Optional full text search query
   * @param {string} [filterValue]
   * @param {string} [disabilityTypeFilter] Disability Type
   * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminApplicationsGet(
    year: number,
    filter: ApplicationFilters,
    champId?: number,
    limit?: number,
    start?: number,
    query?: string,
    filterValue?: string,
    disabilityTypeFilter?: string,
    sortingFilter?: SortingFilterEnum,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminApplicationsGet(
        year,
        filter,
        champId,
        limit,
        start,
        query,
        filterValue,
        disabilityTypeFilter,
        sortingFilter,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns list of applications for player
   * @summary Get filtered list of applications for player history
   * @param {number} playerId filter by player id
   * @param {number} [champId] Optional filter by championship id
   * @param {string} [query] Optional full text search query
   * @param {number} [year] Championship year
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminApplicationsHistoryGet(
    playerId: number,
    champId?: number,
    query?: string,
    year?: number,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminApplicationsHistoryGet(playerId, champId, query, year, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns list of applications
   * @summary Get filtered list of applications for new email
   * @param {number} champId Filter by championship id
   * @param {number} [limit]
   * @param {number} [start]
   * @param {string} [query] Optional First/Last Name search query
   * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
   * @param {PlayerStatusEnum} [playerStatus] Optional Player Status Filter
   * @param {ApplicationProgressionEnum} [applicationStatus] Optional Application Status Filter
   * @param {QualifyingStatusEnum} [qualifyingStatus] Optional Application Status Filter
   * @param {string} [wagrRank] Optional WAGR Rank Filter
   * @param {boolean} [notablePlayer] Optional Notable Player Filter
   * @param {boolean} [adaRequest] Optional ADA Request Filter
   * @param {BlacklistStatusEnum} [blacklist] Optional Blacklist Filter
   * @param {boolean} [watchlist] Optional Watchlist Filter
   * @param {number} [age] Optional Age Filter
   * @param {string} [handicapIndex] Optional Handicap Index Filter
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminApplicationsNewEmailGet(
    champId: number,
    limit?: number,
    start?: number,
    query?: string,
    sortingFilter?: SortingFilterEnum,
    playerStatus?: PlayerStatusEnum,
    applicationStatus?: ApplicationProgressionEnum,
    qualifyingStatus?: QualifyingStatusEnum,
    wagrRank?: string,
    notablePlayer?: boolean,
    adaRequest?: boolean,
    blacklist?: BlacklistStatusEnum,
    watchlist?: boolean,
    age?: number,
    handicapIndex?: string,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminApplicationsNewEmailGet(
        champId,
        limit,
        start,
        query,
        sortingFilter,
        playerStatus,
        applicationStatus,
        qualifyingStatus,
        wagrRank,
        notablePlayer,
        adaRequest,
        blacklist,
        watchlist,
        age,
        handicapIndex,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns application
   * @summary Get next filtered application
   * @param {number} year Championship year
   * @param {number} fromId id of current entry to get next from it
   * @param {ApplicationFilters} filter
   * @param {number} [champId] Optional filter by championship id
   * @param {SortingFilterEnum} [sortingFilter]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminApplicationsNextGet(
    year: number,
    fromId: number,
    filter: ApplicationFilters,
    champId?: number,
    sortingFilter?: SortingFilterEnum,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminApplicationsNextGet(year, fromId, filter, champId, sortingFilter, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns list of applications
   * @summary Get filtered list of applications for qualified and alternate bulk
   * @param {number} champId Filter by championship id
   * @param {number} [limit]
   * @param {number} [start]
   * @param {string} [query] Optional First/Last Name search query
   * @param {string} [startDate] Optional start qualifying date range
   * @param {string} [endDate] Optional end qualifying date range
   * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
   * @param {ApplicationFilters} [filter]
   * @param {string} [filterValue]
   * @param {BulkQualifyingStatusEnum} [bulkQualifyingStatus] Optional complex filtering
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminApplicationsQualifiedAlternateBulkGet(
    champId: number,
    limit?: number,
    start?: number,
    query?: string,
    startDate?: string,
    endDate?: string,
    sortingFilter?: SortingFilterEnum,
    filter?: ApplicationFilters,
    filterValue?: string,
    bulkQualifyingStatus?: BulkQualifyingStatusEnum,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminApplicationsQualifiedAlternateBulkGet(
        champId,
        limit,
        start,
        query,
        startDate,
        endDate,
        sortingFilter,
        filter,
        filterValue,
        bulkQualifyingStatus,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * List of applications with their respective waitlist positions.
   * @summary Get a list of applications ids with their respective waitlist positions.
   * @param {Array<number>} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminApplicationsWaitlistPositionGet(id: Array<number>, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminApplicationsWaitlistPositionGet(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns list of applications
   * @summary Get filtered list of applications to withdraw incomplete sides
   * @param {number} champId Filter by championship id
   * @param {number} [limit]
   * @param {number} [start]
   * @param {string} [query] Optional First/Last Name search query
   * @param {string} [startDate] Optional start qualifying date range
   * @param {string} [endDate] Optional end qualifying date range
   * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminApplicationsWithdrawIncompleteBulkGet(
    champId: number,
    limit?: number,
    start?: number,
    query?: string,
    startDate?: string,
    endDate?: string,
    sortingFilter?: SortingFilterEnum,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminApplicationsWithdrawIncompleteBulkGet(
        champId,
        limit,
        start,
        query,
        startDate,
        endDate,
        sortingFilter,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Download info file by champ id
   * @summary Get activity log
   * @param {string} id
   * @param {AuditEntityType} entityType
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminAuditGet(id: string, entityType: AuditEntityType, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminAuditGet(id, entityType, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns multi filtered list of applications for bulk entry fee balance
   * @summary Get filtered list of applications for bulk entry fee balance
   * @param {number} [champId] Required championship id
   * @param {number} [limit]
   * @param {number} [start]
   * @param {string} [query] Optional full text search query
   * @param {Array<ApplicationFilters>} [filters]
   * @param {Array<number>} [qsIds]
   * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminBulkEntryFeeBalanceGet(
    champId?: number,
    limit?: number,
    start?: number,
    query?: string,
    filters?: Array<ApplicationFilters>,
    qsIds?: Array<number>,
    sortingFilter?: SortingFilterEnum,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminBulkEntryFeeBalanceGet(
        champId,
        limit,
        start,
        query,
        filters,
        qsIds,
        sortingFilter,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * List applications to pay EntryFeeBalance
   * @summary ids
   * @param {BulkEntryFeeRequestAdminModel} [bulkEntryFeeRequestAdminModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminBulkEntryFeeBalancePaymentPost(
    bulkEntryFeeRequestAdminModel?: BulkEntryFeeRequestAdminModel,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminBulkEntryFeeBalancePaymentPost(bulkEntryFeeRequestAdminModel, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns Champiobship Type by id
   * @summary Get Champ-type by id
   * @param {number} id id of championship-type
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminChampTypeGet(id: number, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminChampTypeGet(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns list of Champiobship Types
   * @summary Get list of Champ-types
   * @param {number} [limit]
   * @param {number} [start]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminChampTypeListGet(limit?: number, start?: number, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminChampTypeListGet(limit, start, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Add championship
   * @summary Add championship
   * @param {ChampionshipAddModel} [championshipAddModel] Add championship
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminChampionshipAddPost(
    championshipAddModel?: ChampionshipAddModel,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminChampionshipAddPost(championshipAddModel, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Editing championship
   * @summary Editing championship
   * @param {ChampionshipEditModel} [championshipEditModel] Edit championship
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminChampionshipEditPost(
    championshipEditModel?: ChampionshipEditModel,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminChampionshipEditPost(championshipEditModel, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Red Book entries
   * @param {number} champId
   * @param {number} limit
   * @param {number} start
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminChampionshipRedbookGet(
    champId: number,
    limit: number,
    start: number,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminChampionshipRedbookGet(champId, limit, start, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Reorder Red Book entries
   * @param {Array<RankedQualifyingSiteModel>} [rankedQualifyingSiteModel] A list of assigned ranks for qualifying sites
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminChampionshipRedbookPost(
    rankedQualifyingSiteModel?: Array<RankedQualifyingSiteModel>,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminChampionshipRedbookPost(rankedQualifyingSiteModel, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns list of uploaded files
   * @summary Get list of uploaded files
   * @param {number} [limit] Per page
   * @param {number} [start] Starting position
   * @param {string} [champId]
   * @param {string} [applicationId]
   * @param {string} [query]
   * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminChampionshipsFilesGet(
    limit?: number,
    start?: number,
    champId?: string,
    applicationId?: string,
    query?: string,
    sortingFilter?: SortingFilterEnum,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminChampionshipsFilesGet(
        limit,
        start,
        champId,
        applicationId,
        query,
        sortingFilter,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Repositions championships in order their ids were submitted
   * @summary Reposition championships
   * @param {Array<number>} [requestBody] List of championship ids in new order for some specific year
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminChampionshipsPositionPost(requestBody?: Array<number>, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminChampionshipsPositionPost(requestBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Is used to get championship data by its id
   * @summary Get championship info by id
   * @param {number} id id of championship
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminChampionshipsQueryGet(id: number, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminChampionshipsQueryGet(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get the list of images in cloudinary using its Search API. Has a rate limit of 5k requests per cloudinary account.
   * @param {number} limit Number of images to  return
   * @param {string} [query] Query passed to cloudinary API, see https://cloudinary.com/documentation/search_api for reference
   * @param {string} [start] Next cursor value to search from
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminCloudinaryListGet(
    limit: number,
    query?: string,
    start?: string,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminCloudinaryListGet(limit, query, start, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Delete club by id
   * @summary Remove club by its id
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminClubDelete(id: number, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminClubDelete(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns list of clubs
   * @summary Get list of clubs
   * @param {number} [limit]
   * @param {number} [start]
   * @param {string} [query] Expression to filter entries (e.g. %word%)
   * @param {number} [champId]
   * @param {number} [year]
   * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
   * @param {string} [countryFilter]
   * @param {string} [stateFilter]
   * @param {string} [cityFilter]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminClubGet(
    limit?: number,
    start?: number,
    query?: string,
    champId?: number,
    year?: number,
    sortingFilter?: SortingFilterEnum,
    countryFilter?: string,
    stateFilter?: string,
    cityFilter?: string,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminClubGet(
        limit,
        start,
        query,
        champId,
        year,
        sortingFilter,
        countryFilter,
        stateFilter,
        cityFilter,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Add new club
   * @summary Add new club
   * @param {ClubAdminAddModel} [clubAdminAddModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminClubPost(clubAdminAddModel?: ClubAdminAddModel, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminClubPost(clubAdminAddModel, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Is used to get club data by its id
   * @summary Get club info by id
   * @param {number} id id of club
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminClubQueryGet(id: number, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminClubQueryGet(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Updates club
   * @summary Update club
   * @param {ClubAdminModel} [clubAdminModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminClubUpdatePost(clubAdminModel?: ClubAdminModel, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminClubUpdatePost(clubAdminModel, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * parameters are used only for \'select all\' functionality
   * @summary Bulk email to clubs
   * @param {string} [query] Expression to filter entries (e.g. %word%)
   * @param {number} [champId]
   * @param {number} [year]
   * @param {ClubFilters} [filter]
   * @param {string} [filterValue]
   * @param {CustomBulkEmailRequestModel} [customBulkEmailRequestModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminEmailBulkClubPost(
    query?: string,
    champId?: number,
    year?: number,
    filter?: ClubFilters,
    filterValue?: string,
    customBulkEmailRequestModel?: CustomBulkEmailRequestModel,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminEmailBulkClubPost(
        query,
        champId,
        year,
        filter,
        filterValue,
        customBulkEmailRequestModel,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Custom Bulk Email to Applicants
   * @param {CustomBulkEmailRequestModel} [customBulkEmailRequestModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminEmailBulkCustomPost(
    customBulkEmailRequestModel?: CustomBulkEmailRequestModel,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminEmailBulkCustomPost(customBulkEmailRequestModel, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * parameters are used only for \'select all\' functionality
   * @summary Custom Bulk Email to Applicants
   * @param {Array<number>} [year] Optional Filter by year id
   * @param {Array<number>} [champTypeId] Optional Filter by ChampionshipType Model id
   * @param {string} [query] Optional search query parameter
   * @param {boolean} [twoYearParticipation] Optional Filter to find Two Year Participation
   * @param {boolean} [excludeApplied] Optional Exclude Applied Players Filter
   * @param {boolean} [exempt] Optional Filter to exclude exempt players
   * @param {PlayerStatusEnum} [playerStatus] Optional Player Status Filter
   * @param {string} [wagrRank] Optional WAGR Rank Filter
   * @param {boolean} [adaRequest] Optional ADA Request Filter, true - include, false - exclude
   * @param {boolean} [blacklist] Optional Blacklist Filter, true - include, false - exclude
   * @param {boolean} [watchlist] Optional Watchlist Filter, true - include, false - exclude
   * @param {number} [age] Optional Filter by Player\&#39;s age
   * @param {string} [handicapIndex] Optional Handicap Index Filter
   * @param {Array<number>} [qualifyingSiteId] Optional qualifying site Ids
   * @param {boolean} [applicationAccepted] Optional filter for accepted or q applications
   * @param {Array<number>} [waitlistId] Optional Filter by Waitlist QS id
   * @param {Array<number>} [excludeWaitlistId] Optional Filter exclude by Waitlist QS id
   * @param {boolean} [waitlistAll] Optional Filter to find All waitlisted player by champType &amp; year
   * @param {boolean} [excludeWaitlistAll] Optional Filter to exclude All waitlisted player by champType &amp; year
   * @param {boolean} [excludeBlacklistAll] Optional Filter to exclude All blacklisted player by champType &amp; year
   * @param {Array<BlacklistStatusEnum>} [excludeBlacklistStatus]
   * @param {QualifyingStatusEnum} [qualifyingStatus] Optional Application Qualifying Status Filter
   * @param {CustomBulkEmailProfileRequestModel} [customBulkEmailProfileRequestModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminEmailBulkCustomProfilePost(
    year?: Array<number>,
    champTypeId?: Array<number>,
    query?: string,
    twoYearParticipation?: boolean,
    excludeApplied?: boolean,
    exempt?: boolean,
    playerStatus?: PlayerStatusEnum,
    wagrRank?: string,
    adaRequest?: boolean,
    blacklist?: boolean,
    watchlist?: boolean,
    age?: number,
    handicapIndex?: string,
    qualifyingSiteId?: Array<number>,
    applicationAccepted?: boolean,
    waitlistId?: Array<number>,
    excludeWaitlistId?: Array<number>,
    waitlistAll?: boolean,
    excludeWaitlistAll?: boolean,
    excludeBlacklistAll?: boolean,
    excludeBlacklistStatus?: Array<BlacklistStatusEnum>,
    qualifyingStatus?: QualifyingStatusEnum,
    customBulkEmailProfileRequestModel?: CustomBulkEmailProfileRequestModel,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminEmailBulkCustomProfilePost(
        year,
        champTypeId,
        query,
        twoYearParticipation,
        excludeApplied,
        exempt,
        playerStatus,
        wagrRank,
        adaRequest,
        blacklist,
        watchlist,
        age,
        handicapIndex,
        qualifyingSiteId,
        applicationAccepted,
        waitlistId,
        excludeWaitlistId,
        waitlistAll,
        excludeWaitlistAll,
        excludeBlacklistAll,
        excludeBlacklistStatus,
        qualifyingStatus,
        customBulkEmailProfileRequestModel,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * parameters are used only for \'select all\' functionality
   * @summary Exemptions Applicants bulk email
   * @param {number} champId Filter by championship id
   * @param {string} [query] Optional First/Last Name search query
   * @param {IdListModel} [idListModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminEmailBulkExemptAppPost(
    champId: number,
    query?: string,
    idListModel?: IdListModel,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminEmailBulkExemptAppPost(champId, query, idListModel, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns list of players
   * @summary Get list of exempt players
   * @param {number} champId
   * @param {number} [limit] Per page
   * @param {number} [start] Page number
   * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
   * @param {string} [query]
   * @param {boolean} [excludeBlacklist]
   * @param {boolean} [excludeWatchlist]
   * @param {boolean} [excludeApplied]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminEmailBulkExemptPlayersGet(
    champId: number,
    limit?: number,
    start?: number,
    sortingFilter?: SortingFilterEnum,
    query?: string,
    excludeBlacklist?: boolean,
    excludeWatchlist?: boolean,
    excludeApplied?: boolean,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminEmailBulkExemptPlayersGet(
        champId,
        limit,
        start,
        sortingFilter,
        query,
        excludeBlacklist,
        excludeWatchlist,
        excludeApplied,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Send buld email to exempt players
   * @param {number} champId
   * @param {number} [limit] Per page
   * @param {number} [start] Page number
   * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
   * @param {string} [query]
   * @param {boolean} [excludeBlacklist]
   * @param {boolean} [excludeWatchlist]
   * @param {boolean} [excludeApplied]
   * @param {IdListModel} [idListModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminEmailBulkExemptPlayersPost(
    champId: number,
    limit?: number,
    start?: number,
    sortingFilter?: SortingFilterEnum,
    query?: string,
    excludeBlacklist?: boolean,
    excludeWatchlist?: boolean,
    excludeApplied?: boolean,
    idListModel?: IdListModel,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminEmailBulkExemptPlayersPost(
        champId,
        limit,
        start,
        sortingFilter,
        query,
        excludeBlacklist,
        excludeWatchlist,
        excludeApplied,
        idListModel,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * parameters are used only for \'select all\' functionality
   * @summary Final confirmation bulk email to Applicants
   * @param {number} champId Filter by championship id
   * @param {ApplicationFinalConfirmationBulkFilterEnum} playerFilter Plyer filter
   * @param {string} [query] Optional First/Last Name search query
   * @param {string} [startDate] Optional start qualifying date range
   * @param {string} [endDate] Optional end qualifying date range
   * @param {FinalConfirmationEmailRequestModel} [finalConfirmationEmailRequestModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminEmailBulkFinalConfirmationPost(
    champId: number,
    playerFilter: ApplicationFinalConfirmationBulkFilterEnum,
    query?: string,
    startDate?: string,
    endDate?: string,
    finalConfirmationEmailRequestModel?: FinalConfirmationEmailRequestModel,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminEmailBulkFinalConfirmationPost(
        champId,
        playerFilter,
        query,
        startDate,
        endDate,
        finalConfirmationEmailRequestModel,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * parameters are used only for \'select all\' functionality
   * @summary Bulk email to incomplete sides
   * @param {number} champId Filter by championship id
   * @param {string} [query] Optional First/Last Name search query
   * @param {string} [startDate] Optional start qualifying date range
   * @param {string} [endDate] Optional end qualifying date range
   * @param {IncompleteSidesEmailRequestModel} [incompleteSidesEmailRequestModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminEmailBulkIncompleteSidesPost(
    champId: number,
    query?: string,
    startDate?: string,
    endDate?: string,
    incompleteSidesEmailRequestModel?: IncompleteSidesEmailRequestModel,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminEmailBulkIncompleteSidesPost(
        champId,
        query,
        startDate,
        endDate,
        incompleteSidesEmailRequestModel,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary New Custom Bulk Email to Players
   * @param {NewCustomBulkEmailRequestModel} [newCustomBulkEmailRequestModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminEmailBulkNewCustomPlayersPost(
    newCustomBulkEmailRequestModel?: NewCustomBulkEmailRequestModel,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminEmailBulkNewCustomPlayersPost(newCustomBulkEmailRequestModel, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary New Custom Bulk Email to Applicants
   * @param {NewCustomBulkEmailRequestModel} [newCustomBulkEmailRequestModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminEmailBulkNewCustomPost(
    newCustomBulkEmailRequestModel?: NewCustomBulkEmailRequestModel,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminEmailBulkNewCustomPost(newCustomBulkEmailRequestModel, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * parameters are used only for \'select all\' functionality
   * @summary Bulk email to Officials
   * @param {string} [query] Expression to filter entries (e.g. %word%)
   * @param {number} [champId]
   * @param {number} [year]
   * @param {OfficialsListEmailRequestModel} [officialsListEmailRequestModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminEmailBulkOfficialsPost(
    query?: string,
    champId?: number,
    year?: number,
    officialsListEmailRequestModel?: OfficialsListEmailRequestModel,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminEmailBulkOfficialsPost(
        query,
        champId,
        year,
        officialsListEmailRequestModel,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Bulk email with partner link
   * @param {PartnerLinkEmailRequestModel} [partnerLinkEmailRequestModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminEmailBulkPartnerLinkPost(
    partnerLinkEmailRequestModel?: PartnerLinkEmailRequestModel,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminEmailBulkPartnerLinkPost(partnerLinkEmailRequestModel, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * parameters are used only for \'select all\' functionality
   * @summary Qualified Alternate bulk email to Applicants
   * @param {number} champId Filter by championship id
   * @param {string} [query] Optional First/Last Name search query
   * @param {string} [startDate] Optional start qualifying date range
   * @param {string} [endDate] Optional end qualifying date range
   * @param {ApplicationFilters} [filter]
   * @param {string} [filterValue]
   * @param {BulkQualifyingStatusEnum} [bulkQualifyingStatus] Optional complex filtering
   * @param {IdListModel} [idListModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminEmailBulkQualifiedAlternatePost(
    champId: number,
    query?: string,
    startDate?: string,
    endDate?: string,
    filter?: ApplicationFilters,
    filterValue?: string,
    bulkQualifyingStatus?: BulkQualifyingStatusEnum,
    idListModel?: IdListModel,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminEmailBulkQualifiedAlternatePost(
        champId,
        query,
        startDate,
        endDate,
        filter,
        filterValue,
        bulkQualifyingStatus,
        idListModel,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * parameters are used only for \'select all\' functionality
   * @summary Withdraw and bulk email incomplete applicants
   * @param {number} champId Filter by championship id
   * @param {string} [query] Optional First/Last Name search query
   * @param {string} [startDate] Optional start qualifying date range
   * @param {string} [endDate] Optional end qualifying date range
   * @param {IdListModel} [idListModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminEmailBulkWithdrawIncompletePost(
    champId: number,
    query?: string,
    startDate?: string,
    endDate?: string,
    idListModel?: IdListModel,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminEmailBulkWithdrawIncompletePost(
        champId,
        query,
        startDate,
        endDate,
        idListModel,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Resend email
   * @param {ResendEmailRequestModel} [resendEmailRequestModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminEmailResendPost(
    resendEmailRequestModel?: ResendEmailRequestModel,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminEmailResendPost(resendEmailRequestModel, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete email template
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminEmailTemplateDelete(id: number, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminEmailTemplateDelete(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Edit email template
   * @param {EmailTemplateEditModel} [emailTemplateEditModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminEmailTemplateEditPost(
    emailTemplateEditModel?: EmailTemplateEditModel,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminEmailTemplateEditPost(emailTemplateEditModel, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns Champiobship Type by id
   * @summary Get email template by id
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminEmailTemplateGet(id: number, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminEmailTemplateGet(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Add new email template
   * @summary Create new email template
   * @param {EmailTemplateRequestModel} [emailTemplateRequestModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminEmailTemplatePost(
    emailTemplateRequestModel?: EmailTemplateRequestModel,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminEmailTemplatePost(emailTemplateRequestModel, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get email template by service-type and Champ Id
   * @param {EmailTemplateServiceTypeEnum} serviceType
   * @param {number} [champId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminEmailTemplateServiceTypeGet(
    serviceType: EmailTemplateServiceTypeEnum,
    champId?: number,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminEmailTemplateServiceTypeGet(serviceType, champId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns list of applications
   * @summary Get filtered list of applications for exemptions bulk
   * @param {number} champId Filter by championship id
   * @param {number} [limit]
   * @param {number} [start]
   * @param {string} [query] Optional First/Last Name search query
   * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminExemptApplicationsGet(
    champId: number,
    limit?: number,
    start?: number,
    query?: string,
    sortingFilter?: SortingFilterEnum,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminExemptApplicationsGet(champId, limit, start, query, sortingFilter, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Delete exemption by id
   * @summary Remove exemption by its id
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminExemptionDelete(id: number, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminExemptionDelete(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Edit already existing exemption
   * @summary Edit exemption
   * @param {ExemptionAdminEditModel} [exemptionAdminEditModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminExemptionEditPost(
    exemptionAdminEditModel?: ExemptionAdminEditModel,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminExemptionEditPost(exemptionAdminEditModel, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns list of exemptions
   * @summary Get list of exemptions by championship Id
   * @param {number} champId
   * @param {number} [limit]
   * @param {number} [start]
   * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
   * @param {QualifyingLevelEnum} [filter] Optional Filter
   * @param {string} [query] Optional search query parameter
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminExemptionGet(
    champId: number,
    limit?: number,
    start?: number,
    sortingFilter?: SortingFilterEnum,
    filter?: QualifyingLevelEnum,
    query?: string,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminExemptionGet(champId, limit, start, sortingFilter, filter, query, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns list of exemptions
   * @summary Get list of exemptions by championship Id
   * @param {number} champId
   * @param {number} [limit]
   * @param {number} [start]
   * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
   * @param {QualifyingLevelEnum} [filter] Optional Filter
   * @param {string} [query] Optional search query parameter
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminExemptionLightWeightGet(
    champId: number,
    limit?: number,
    start?: number,
    sortingFilter?: SortingFilterEnum,
    filter?: QualifyingLevelEnum,
    query?: string,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminExemptionLightWeightGet(
        champId,
        limit,
        start,
        sortingFilter,
        filter,
        query,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Add new exemption to championship
   * @summary Add new exemption to championship
   * @param {ExemptionAdminAddModel} [exemptionAdminAddModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminExemptionPost(
    exemptionAdminAddModel?: ExemptionAdminAddModel,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminExemptionPost(exemptionAdminAddModel, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Is used to get exemption data by its id
   * @summary Get exemption info by id
   * @param {number} id id of exemption
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminExemptionQueryGet(id: number, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminExemptionQueryGet(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Import exemptions
   * @summary Import exemptions
   * @param {any} [file]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminExemptionUploadPost(file?: any, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminExemptionUploadPost(file, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Delete file by id
   * @summary Remove file by its id
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminFileDelete(id: number, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminFileDelete(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Download file by id
   * @summary Download file by id
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminFileGet(id: number, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminFileGet(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Download files by id list
   * @summary Download files by id list
   * @param {Array<number>} id Required id list parameter
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminFileListGet(id: Array<number>, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminFileListGet(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get handicap player by handicap id
   * @param {string} handicapId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminHandicapGet(handicapId: string, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminHandicapGet(handicapId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns list of players
   * @summary Get list of incomplete sides
   * @param {number} champId Filter by championship id
   * @param {number} [limit]
   * @param {number} [start]
   * @param {string} [query] Optional First/Last Name search query
   * @param {string} [startDate] Optional start qualifying date range
   * @param {string} [endDate] Optional end qualifying date range
   * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminIncompleteSidesGet(
    champId: number,
    limit?: number,
    start?: number,
    query?: string,
    startDate?: string,
    endDate?: string,
    sortingFilter?: SortingFilterEnum,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminIncompleteSidesGet(
        champId,
        limit,
        start,
        query,
        startDate,
        endDate,
        sortingFilter,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get unsubscribe page absolute link
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminLinksUnsubscribeGet(options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminLinksUnsubscribeGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Delete exemption by id
   * @summary Remove exemption by its id
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminMessageDelete(id: number, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminMessageDelete(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns list of messages
   * @summary Get list of messages
   * @param {number} [limit]
   * @param {number} [start]
   * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
   * @param {MessageTypeEnum} [messageType] Optional filter by message type
   * @param {boolean} [dismissible] Optional filter by current date and \&#39;dateRangeEnabled\&#39;
   * @param {boolean} [dateRangeEnabled] Optional filter by \&#39;dateRangeEnabled\&#39; flag
   * @param {number} [playerId] Filter by player id
   * @param {string} [query] Optional search query parameter
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminMessageGet(
    limit?: number,
    start?: number,
    sortingFilter?: SortingFilterEnum,
    messageType?: MessageTypeEnum,
    dismissible?: boolean,
    dateRangeEnabled?: boolean,
    playerId?: number,
    query?: string,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminMessageGet(
        limit,
        start,
        sortingFilter,
        messageType,
        dismissible,
        dateRangeEnabled,
        playerId,
        query,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Add new message
   * @summary Add new message
   * @param {MessageAddModel} [messageAddModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminMessagePost(messageAddModel?: MessageAddModel, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminMessagePost(messageAddModel, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Is used to get message data by its id
   * @summary Get message by id
   * @param {number} id id of the message
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminMessageQueryGet(id: number, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminMessageQueryGet(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Updates message
   * @summary Update message
   * @param {MessageEditModel} [messageEditModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminMessageUpdatePost(messageEditModel?: MessageEditModel, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminMessageUpdatePost(messageEditModel, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns Champiobship Type by id
   * @summary Get Applications Pairs by ChampId
   * @param {number} champId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminPairGet(champId: number, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminPairGet(champId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns list of pairs
   * @summary Get filtered list of pairs
   * @param {number} limit
   * @param {number} start
   * @param {number} playerId
   * @param {string} [query] Optional search query
   * @param {PairSortingFilterEnum} [sortingFilter] Optional Sorting Filter
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminPairHistoryGet(
    limit: number,
    start: number,
    playerId: number,
    query?: string,
    sortingFilter?: PairSortingFilterEnum,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminPairHistoryGet(limit, start, playerId, query, sortingFilter, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create/Update Applications Pairs
   * @param {PairListRequestModel} [pairListRequestModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminPairPost(pairListRequestModel?: PairListRequestModel, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminPairPost(pairListRequestModel, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Refunds payment
   * @summary Refunds payment
   * @param {RefundPaymentAdminEditModel} [refundPaymentAdminEditModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminPaymentEditRefundPost(
    refundPaymentAdminEditModel?: RefundPaymentAdminEditModel,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminPaymentEditRefundPost(refundPaymentAdminEditModel, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Refunds payment
   * @summary Refunds payment
   * @param {FourBallRefundPaymentAdminModel} [fourBallRefundPaymentAdminModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminPaymentFourBallRefundPost(
    fourBallRefundPaymentAdminModel?: FourBallRefundPaymentAdminModel,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminPaymentFourBallRefundPost(fourBallRefundPaymentAdminModel, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get form data to make post request to payment processor
   * @param {number} appId Application id
   * @param {string} referrer
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminPaymentGet(appId: number, referrer: string, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminPaymentGet(appId, referrer, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Refunds payment
   * @summary Refunds payment
   * @param {RefundPaymentAdminModel} [refundPaymentAdminModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminPaymentRefundPost(
    refundPaymentAdminModel?: RefundPaymentAdminModel,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminPaymentRefundPost(refundPaymentAdminModel, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Voids Refund payment
   * @summary Voids Refund payment
   * @param {VoidRefundPaymentAdminModel} [voidRefundPaymentAdminModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminPaymentRefundVoidPost(
    voidRefundPaymentAdminModel?: VoidRefundPaymentAdminModel,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminPaymentRefundVoidPost(voidRefundPaymentAdminModel, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns list of applications
   * @summary Get filtered list of applications for custom email
   * @param {number} [limit]
   * @param {number} [start]
   * @param {Array<number>} [year] Optional Filter by year id
   * @param {Array<number>} [champTypeId] Optional Filter by ChampionshipType Model id
   * @param {string} [query] Optional search query parameter
   * @param {boolean} [twoYearParticipation] Optional Filter to find Two Year Participation
   * @param {boolean} [excludeApplied] Optional Exclude Applied Players Filter
   * @param {boolean} [exempt] Optional Filter to exclude exempt players
   * @param {PlayerStatusEnum} [playerStatus] Optional Player Status Filter
   * @param {string} [wagrRank] Optional WAGR Rank Filter
   * @param {boolean} [adaRequest] Optional ADA Request Filter, true - include, false - exclude
   * @param {boolean} [blacklist] Optional Blacklist Filter, true - include, false - exclude
   * @param {boolean} [watchlist] Optional Watchlist Filter, true - include, false - exclude
   * @param {number} [age] Optional Filter by Player\&#39;s age
   * @param {string} [handicapIndex] Optional Handicap Index Filter
   * @param {Array<number>} [qualifyingSiteId] Optional qualifying site Ids
   * @param {boolean} [applicationAccepted] Optional filter for accepted or q applications
   * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
   * @param {EmailTemplateTypeEnum} [templType]
   * @param {number} [templId]
   * @param {Array<number>} [waitlistId] Optional Filter by Waitlist QS id
   * @param {Array<number>} [excludeWaitlistId] Optional Filter exclude by Waitlist QS id
   * @param {boolean} [waitlistAll] Optional Filter to find All waitlisted player by champType &amp; year
   * @param {boolean} [excludeWaitlistAll] Optional Filter to exclude All waitlisted player by champType &amp; year
   * @param {boolean} [excludeBlacklistAll] Optional Filter to exclude All blacklisted player by champType &amp; year
   * @param {Array<BlacklistStatusEnum>} [excludeBlacklistStatus]
   * @param {QualifyingStatusEnum} [qualifyingStatus] Optional Application Qualifying Status Filter
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminPlayersCustomEmailGet(
    limit?: number,
    start?: number,
    year?: Array<number>,
    champTypeId?: Array<number>,
    query?: string,
    twoYearParticipation?: boolean,
    excludeApplied?: boolean,
    exempt?: boolean,
    playerStatus?: PlayerStatusEnum,
    wagrRank?: string,
    adaRequest?: boolean,
    blacklist?: boolean,
    watchlist?: boolean,
    age?: number,
    handicapIndex?: string,
    qualifyingSiteId?: Array<number>,
    applicationAccepted?: boolean,
    sortingFilter?: SortingFilterEnum,
    templType?: EmailTemplateTypeEnum,
    templId?: number,
    waitlistId?: Array<number>,
    excludeWaitlistId?: Array<number>,
    waitlistAll?: boolean,
    excludeWaitlistAll?: boolean,
    excludeBlacklistAll?: boolean,
    excludeBlacklistStatus?: Array<BlacklistStatusEnum>,
    qualifyingStatus?: QualifyingStatusEnum,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminPlayersCustomEmailGet(
        limit,
        start,
        year,
        champTypeId,
        query,
        twoYearParticipation,
        excludeApplied,
        exempt,
        playerStatus,
        wagrRank,
        adaRequest,
        blacklist,
        watchlist,
        age,
        handicapIndex,
        qualifyingSiteId,
        applicationAccepted,
        sortingFilter,
        templType,
        templId,
        waitlistId,
        excludeWaitlistId,
        waitlistAll,
        excludeWaitlistAll,
        excludeBlacklistAll,
        excludeBlacklistStatus,
        qualifyingStatus,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Delete point of contact by id
   * @summary Remove point of contact by its id
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminPocDelete(id: number, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminPocDelete(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns list of points of contact
   * @summary Get list of points of contact
   * @param {number} [limit]
   * @param {number} [start]
   * @param {string} [query] Expression to filter entries (e.g. %word%)
   * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminPocGet(
    limit?: number,
    start?: number,
    query?: string,
    sortingFilter?: SortingFilterEnum,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminPocGet(limit, start, query, sortingFilter, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Add new point of contact
   * @summary Add new point of contact
   * @param {PointOfContactAdminAddModel} [pointOfContactAdminAddModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminPocPost(
    pointOfContactAdminAddModel?: PointOfContactAdminAddModel,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminPocPost(pointOfContactAdminAddModel, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Is used to get poc data by its id
   * @summary Get poc info by id
   * @param {number} id id of poc
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminPocQueryGet(id: number, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminPocQueryGet(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Updates point of contact
   * @summary Update point of contact
   * @param {PointOfContactAdminEditModel} [pointOfContactAdminEditModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminPocUpdatePost(
    pointOfContactAdminEditModel?: PointOfContactAdminEditModel,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminPocUpdatePost(pointOfContactAdminEditModel, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get duplicated player\'s profile quantity
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminProfileDuplicateAutoMergeGet(options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminProfileDuplicateAutoMergeGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Auto-merge players\' profiles
   * @summary Auto-merge players\' profiles
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminProfileDuplicateAutoMergePost(options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminProfileDuplicateAutoMergePost(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Is used to get player\'s profile duplicates
   * @summary Get player\'s profile duplicates
   * @param {string} id id of profile
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminProfileDuplicateGet(id: string, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminProfileDuplicateGet(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Updates player profile
   * @summary Merge player\'s profiles
   * @param {IdListModel} [idListModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminProfileDuplicatePost(idListModel?: IdListModel, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminProfileDuplicatePost(idListModel, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get status list of requested subscriptions
   * @summary Get status list of requested subscriptions
   * @param {Array<EmailSubscriptionType>} [types]
   * @param {number} [playerId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminProfileEmailSubscriptionsGet(
    types?: Array<EmailSubscriptionType>,
    playerId?: number,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminProfileEmailSubscriptionsGet(types, playerId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Update subscriptions
   * @summary Update subscriptions
   * @param {PlayerEmailSubscriptionsStatus} [playerEmailSubscriptionsStatus]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminProfileEmailSubscriptionsPost(
    playerEmailSubscriptionsStatus?: PlayerEmailSubscriptionsStatus,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminProfileEmailSubscriptionsPost(playerEmailSubscriptionsStatus, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Is used to get profile data
   * @summary Get player profile
   * @param {string} id id of profile to return data for
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminProfileGet(id: string, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminProfileGet(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Is used to get profile data
   * @summary [GraphQL] Get player profile
   * @param {string} id id of profile to return data for
   * @param {Array<string>} [fields] required fields to fetch, e.g. \&quot;aaa\&quot;, \&quot;bbb.ccc.ddd\&quot;
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminProfileGraphqlGet(id: string, fields?: Array<string>, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminProfileGraphqlGet(id, fields, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns list of players
   * @summary Get list of players
   * @param {number} [limit] Per page
   * @param {number} [start] Page number
   * @param {string} [query] Query parameter to filter users based on: User Id, First Name, Last Name, Player Status, Date of Birth (e.g. query&#x3D;value*)
   * @param {PlayerFilters} [filter]
   * @param {string} [filterValue]
   * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
   * @param {Array<number>} [champId] Optional Filter by champ id
   * @param {Array<number>} [year]
   * @param {Array<number>} [siteId] Optional Filter by qualifying site id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminProfileListGet(
    limit?: number,
    start?: number,
    query?: string,
    filter?: PlayerFilters,
    filterValue?: string,
    sortingFilter?: SortingFilterEnum,
    champId?: Array<number>,
    year?: Array<number>,
    siteId?: Array<number>,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminProfileListGet(
        limit,
        start,
        query,
        filter,
        filterValue,
        sortingFilter,
        champId,
        year,
        siteId,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns list of players
   * @summary [GraphQL] Get list of players
   * @param {number} [limit] Per page
   * @param {number} [start] Page number
   * @param {string} [query] Query parameter to filter users based on: User Id, First Name, Last Name, Player Status, Date of Birth (e.g. query&#x3D;value*)
   * @param {PlayerFilters} [filter]
   * @param {string} [filterValue]
   * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
   * @param {Array<number>} [champId] Optional Filter by champ id
   * @param {Array<number>} [year]
   * @param {Array<number>} [siteId] Optional Filter by qualifying site id
   * @param {Array<string>} [fields] required fields to fetch, e.g. \&quot;aaa\&quot;, \&quot;bbb.ccc.ddd\&quot;
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminProfileListGraphqlGet(
    limit?: number,
    start?: number,
    query?: string,
    filter?: PlayerFilters,
    filterValue?: string,
    sortingFilter?: SortingFilterEnum,
    champId?: Array<number>,
    year?: Array<number>,
    siteId?: Array<number>,
    fields?: Array<string>,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminProfileListGraphqlGet(
        limit,
        start,
        query,
        filter,
        filterValue,
        sortingFilter,
        champId,
        year,
        siteId,
        fields,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Updates managed players
   * @summary Update managed players
   * @param {ProfileManagedPlayerAdminModel} [profileManagedPlayerAdminModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminProfileManagedplayerPost(
    profileManagedPlayerAdminModel?: ProfileManagedPlayerAdminModel,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminProfileManagedplayerPost(profileManagedPlayerAdminModel, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns list of players
   * @summary Get list of players
   * @param {number} [limit] Per page
   * @param {number} [start] Page number
   * @param {string} [query] Query parameter to filter users based on: User Id, First Name, Last Name, Player Status, Date of Birth (e.g. query&#x3D;value*)
   * @param {PlayerFilters} [filter]
   * @param {string} [filterValue]
   * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
   * @param {Array<number>} [champId] Optional Filter by champ id
   * @param {Array<number>} [year]
   * @param {Array<number>} [champTypeId] Optional Filter by champ type id
   * @param {string} [blackList] Black list
   * @param {Array<number>} [siteId] Optional Filter by qualifying site id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminProfileMessageListGet(
    limit?: number,
    start?: number,
    query?: string,
    filter?: PlayerFilters,
    filterValue?: string,
    sortingFilter?: SortingFilterEnum,
    champId?: Array<number>,
    year?: Array<number>,
    champTypeId?: Array<number>,
    blackList?: string,
    siteId?: Array<number>,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminProfileMessageListGet(
        limit,
        start,
        query,
        filter,
        filterValue,
        sortingFilter,
        champId,
        year,
        champTypeId,
        blackList,
        siteId,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Updates player profile
   * @summary Save/update player profile
   * @param {ProfileAdminRequestModel} [profileAdminRequestModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminProfilePost(
    profileAdminRequestModel?: ProfileAdminRequestModel,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminProfilePost(profileAdminRequestModel, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get profiles quantity
   * @param {PlayerFilters} [filter]
   * @param {string} [filterValue]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminProfileQuantityGet(
    filter?: PlayerFilters,
    filterValue?: string,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminProfileQuantityGet(filter, filterValue, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Closes QS
   * @summary Provides zip file for download with reports with Applications of QS
   * @param {number} champId
   * @param {QualifyingSiteFilters} [filter]
   * @param {IdListModel} [idListModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminQualifyingsiteApplicationsDownloadPost(
    champId: number,
    filter?: QualifyingSiteFilters,
    idListModel?: IdListModel,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminQualifyingsiteApplicationsDownloadPost(
        champId,
        filter,
        idListModel,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Auto-assign QS number
   * @summary Auto-assign QS number
   * @param {number} champId
   * @param {QualifyingLevelEnum} level
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminQualifyingsiteAutoAssignPost(
    champId: number,
    level: QualifyingLevelEnum,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminQualifyingsiteAutoAssignPost(champId, level, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns list of qualifying sites
   * @summary Get list of qualifying sites by championship Ids
   * @param {Array<number>} champId Filter by champ id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminQualifyingsiteChampsGet(champId: Array<number>, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminQualifyingsiteChampsGet(champId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns list of qualifying sites by champ type ids
   * @summary Get list of qualifying sites by champ type id
   * @param {Array<number>} champTypeId Champ Type Ids
   * @param {Array<number>} year Champ Type Ids
   * @param {number} [limit]
   * @param {number} [start]
   * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminQualifyingsiteChamptypeidGet(
    champTypeId: Array<number>,
    year: Array<number>,
    limit?: number,
    start?: number,
    sortingFilter?: SortingFilterEnum,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminQualifyingsiteChamptypeidGet(
        champTypeId,
        year,
        limit,
        start,
        sortingFilter,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Checks whether it is possible to create a \'QualifyingSite\' with such data.
   * @summary Check if a combination of ChampId+Level+Number is still free
   * @param {number} champId
   * @param {string} level
   * @param {number} number
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminQualifyingsiteCheckGet(
    champId: number,
    level: string,
    number: number,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminQualifyingsiteCheckGet(champId, level, number, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Closes QS
   * @summary Closes selected qualifying sites and provides zip file for download with reports
   * @param {IdListModel} [idListModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminQualifyingsiteClosePost(idListModel?: IdListModel, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminQualifyingsiteClosePost(idListModel, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Delete qualifying site by id
   * @summary Remove qualifying site by its id
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminQualifyingsiteDelete(id: number, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminQualifyingsiteDelete(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns list of qualifying sites
   * @summary Get list of qualifying sites by championship Id
   * @param {number} champId
   * @param {number} [limit]
   * @param {number} [start]
   * @param {QualifyingSiteFilters} [filter]
   * @param {string} [filterValue]
   * @param {string} [startDate] Optional start date range
   * @param {string} [endDate] Optional end date range
   * @param {string} [query]
   * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminQualifyingsiteGet(
    champId: number,
    limit?: number,
    start?: number,
    filter?: QualifyingSiteFilters,
    filterValue?: string,
    startDate?: string,
    endDate?: string,
    query?: string,
    sortingFilter?: SortingFilterEnum,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminQualifyingsiteGet(
        champId,
        limit,
        start,
        filter,
        filterValue,
        startDate,
        endDate,
        query,
        sortingFilter,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns list of qualifying sites
   * @summary Get list of qualifying sites by championship Id
   * @param {number} champId
   * @param {number} [limit]
   * @param {number} [start]
   * @param {QualifyingSiteFilters} [filter]
   * @param {string} [filterValue]
   * @param {string} [startDate] Optional start date range
   * @param {string} [endDate] Optional end date range
   * @param {string} [query]
   * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminQualifyingsiteLightWeightGet(
    champId: number,
    limit?: number,
    start?: number,
    filter?: QualifyingSiteFilters,
    filterValue?: string,
    startDate?: string,
    endDate?: string,
    query?: string,
    sortingFilter?: SortingFilterEnum,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminQualifyingsiteLightWeightGet(
        champId,
        limit,
        start,
        filter,
        filterValue,
        startDate,
        endDate,
        query,
        sortingFilter,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Add new qualifying site to championship
   * @summary Add new qualifying site to championship
   * @param {QualifyingSiteAddModel} [qualifyingSiteAddModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminQualifyingsitePost(
    qualifyingSiteAddModel?: QualifyingSiteAddModel,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminQualifyingsitePost(qualifyingSiteAddModel, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Is used to get qualifying site data by its id
   * @summary Get qualifying site info by id
   * @param {number} id id of qualifying site
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminQualifyingsiteQueryGet(id: number, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminQualifyingsiteQueryGet(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns list of qualifying sites uploaded results
   * @summary Get list of qualifying sites results by file id
   * @param {number} [limit]
   * @param {number} [start]
   * @param {number} [fileId]
   * @param {string} [query]
   * @param {UploadFileProgressionEnum} [status]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminQualifyingsiteResultDetailGet(
    limit?: number,
    start?: number,
    fileId?: number,
    query?: string,
    status?: UploadFileProgressionEnum,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminQualifyingsiteResultDetailGet(limit, start, fileId, query, status, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Add new qualifying site to championship
   * @summary Update
   * @param {ScoreUpdateRequestAdminModelScoring} [scoreUpdateRequestAdminModelScoring]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminQualifyingsiteResultDetailPost(
    scoreUpdateRequestAdminModelScoring?: ScoreUpdateRequestAdminModelScoring,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminQualifyingsiteResultDetailPost(
        scoreUpdateRequestAdminModelScoring,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns list of qualifying sites uploaded results
   * @summary Get list of qualifying sites results by qualifying site id
   * @param {number} champId
   * @param {number} [limit]
   * @param {number} [start]
   * @param {number} [qualifyingSiteId]
   * @param {string} [query]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminQualifyingsiteResultListGet(
    champId: number,
    limit?: number,
    start?: number,
    qualifyingSiteId?: number,
    query?: string,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminQualifyingsiteResultListGet(
        champId,
        limit,
        start,
        qualifyingSiteId,
        query,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update
   * @param {UploadFileStatusRequestAdminModel} [uploadFileStatusRequestAdminModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminQualifyingsiteResultListPost(
    uploadFileStatusRequestAdminModel?: UploadFileStatusRequestAdminModel,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminQualifyingsiteResultListPost(uploadFileStatusRequestAdminModel, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Updates point of contact
   * @summary Update qualifying site
   * @param {QualifyingSiteEditModel} [qualifyingSiteEditModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminQualifyingsiteUpdatePost(
    qualifyingSiteEditModel?: QualifyingSiteEditModel,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminQualifyingsiteUpdatePost(qualifyingSiteEditModel, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns list of Q reasons
   * @summary Get list of queue reasons
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminQueueReasonGet(options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminQueueReasonGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Download ada report
   * @summary Download ada report
   * @param {number} [champId] Optional filter by championship id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminReportsAdaGet(champId?: number, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminReportsAdaGet(champId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Download anti-doping report
   * @summary Download anti-doping report
   * @param {number} [champId] Optional filter by championship id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminReportsAntidopingGet(champId?: number, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminReportsAntidopingGet(champId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Download list of applications
   * @summary Download filtered list of applications
   * @param {number} champId Optional filter by championship id
   * @param {number} year Championship year
   * @param {ApplicationFilters} filter
   * @param {number} [limit]
   * @param {number} [start]
   * @param {string} [query] Optional full text search query
   * @param {string} [filterValue]
   * @param {string} [disabilityTypeFilter] Disability Type
   * @param {Array<number>} [appId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminReportsApplicationsGet(
    champId: number,
    year: number,
    filter: ApplicationFilters,
    limit?: number,
    start?: number,
    query?: string,
    filterValue?: string,
    disabilityTypeFilter?: string,
    appId?: Array<number>,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminReportsApplicationsGet(
        champId,
        year,
        filter,
        limit,
        start,
        query,
        filterValue,
        disabilityTypeFilter,
        appId,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Download blacklist report
   * @summary Download Blacklist across all champ report
   * @param {number} [champId] Optional filter by championship id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminReportsBlacklistGet(champId?: number, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminReportsBlacklistGet(champId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Download close of entries data snapshot report
   * @summary Download close of entries data snapshot report
   * @param {number} [champId] Optional filter by championship id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminReportsCloseOfEntriesGet(champId?: number, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminReportsCloseOfEntriesGet(champId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Download demographics report
   * @summary Download demographics report
   * @param {number} [champId] Optional filter by championship id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminReportsDemographicsGet(champId?: number, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminReportsDemographicsGet(champId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Download report by access key
   * @summary Download report by access key
   * @param {string} id
   * @param {boolean} [inline] Optional flag, set to true in order to get inlined response.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminReportsDownloadGet(id: string, inline?: boolean, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminReportsDownloadGet(id, inline, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Download duplicate applications report
   * @summary Download duplicate applications report
   * @param {number} [champId] Optional filter by championship id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminReportsDuplicatesGet(champId?: number, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminReportsDuplicatesGet(champId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Download exemption report
   * @summary Download \'Exemptions Chosen on Application Report\' report
   * @param {number} [champId] Optional filter by championship id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminReportsExemptionChosenGet(champId?: number, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminReportsExemptionChosenGet(champId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Download exemption report
   * @summary Download \'Exemptions Across All Championships\' report
   * @param {number} [champId] Optional filter by championship id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminReportsExemptionGet(champId?: number, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminReportsExemptionGet(champId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Download notable players report
   * @summary Download notable players report
   * @param {number} [champId] Optional filter by championship id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminReportsNotablePlayersGet(champId?: number, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminReportsNotablePlayersGet(champId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Download list of profiles
   * @summary Download filtered list of profiles
   * @param {number} [limit] Per page
   * @param {number} [start] Page number
   * @param {string} [query] Query parameter to filter users based on: User Id, First Name, Last Name, Player Status, Date of Birth (e.g. query&#x3D;value*)
   * @param {PlayerFilters} [filter]
   * @param {string} [filterValue]
   * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
   * @param {Array<number>} [playerId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminReportsProfilesGet(
    limit?: number,
    start?: number,
    query?: string,
    filter?: PlayerFilters,
    filterValue?: string,
    sortingFilter?: SortingFilterEnum,
    playerId?: Array<number>,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminReportsProfilesGet(
        limit,
        start,
        query,
        filter,
        filterValue,
        sortingFilter,
        playerId,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Download qualifying sites report
   * @summary Download qualifying sites report
   * @param {number} [champId] Optional filter by championship id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminReportsQualifyingSitesGet(champId?: number, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminReportsQualifyingSitesGet(champId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Download list of QS
   * @summary Download filtered list of QS
   * @param {number} champId
   * @param {number} [limit]
   * @param {number} [start]
   * @param {QualifyingSiteFilters} [filter]
   * @param {string} [filterValue]
   * @param {string} [startDate] Optional start date range
   * @param {string} [endDate] Optional end date range
   * @param {string} [query]
   * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
   * @param {Array<number>} [id] Optional QS id multiple filter
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminReportsQualifyingsiteDownloadGet(
    champId: number,
    limit?: number,
    start?: number,
    filter?: QualifyingSiteFilters,
    filterValue?: string,
    startDate?: string,
    endDate?: string,
    query?: string,
    sortingFilter?: SortingFilterEnum,
    id?: Array<number>,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminReportsQualifyingsiteDownloadGet(
        champId,
        limit,
        start,
        filter,
        filterValue,
        startDate,
        endDate,
        query,
        sortingFilter,
        id,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Download refund applications report
   * @summary Download refund applications report
   * @param {number} [champId] Optional filter by championship id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminReportsRefundGet(champId?: number, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminReportsRefundGet(champId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Download customer service report
   * @summary Download customer service report
   * @param {string} from
   * @param {string} to
   * @param {number} [champId] Championship ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminReportsServiceGet(
    from: string,
    to: string,
    champId?: number,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminReportsServiceGet(from, to, champId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Download watchlist report
   * @summary Download Watchlist across all champ report
   * @param {number} [champId] Optional filter by championship id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminReportsWatchlistGet(champId?: number, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminReportsWatchlistGet(champId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Download filtered list of withdrawn applications
   * @summary Download filtered list of withdrawn applications
   * @param {number} champId championship id
   * @param {IdListModel} [idListModel] Optional qualifying site ids to filter by
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminReportsWithdrawalPost(
    champId: number,
    idListModel?: IdListModel,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminReportsWithdrawalPost(champId, idListModel, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Download withdrawn applications report
   * @summary Download withdrawn applications report
   * @param {number} [champId] Optional filter by championship id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminReportsWithdrawnGet(champId?: number, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminReportsWithdrawnGet(champId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Updates player team
   * @summary Update team
   * @param {CreateTeamAdminModel} [createTeamAdminModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminTeamCreatePost(
    createTeamAdminModel?: CreateTeamAdminModel,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminTeamCreatePost(createTeamAdminModel, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Is used to get team data
   * @summary Get player team
   * @param {string} id id of team to return data for
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminTeamGet(id: string, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminTeamGet(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Updates player team
   * @summary Update team
   * @param {TeamAdminRequestModel} [teamAdminRequestModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminTeamPost(teamAdminRequestModel?: TeamAdminRequestModel, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminTeamPost(teamAdminRequestModel, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Upload Application Files
   * @param {number} champId Id of championship we uploading data for
   * @param {number} applicationId Id of application we uploading data for
   * @param {UploadedFileType} fileType
   * @param {any} [file]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminUploadApplicationFilePost(
    champId: number,
    applicationId: number,
    fileType: UploadedFileType,
    file?: any,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminUploadApplicationFilePost(champId, applicationId, fileType, file, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create user
   * @param {UserCreateModel} [userCreateModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminUserCreatePost(userCreateModel?: UserCreateModel, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminUserCreatePost(userCreateModel, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Delete user by id
   * @summary Remove user by its id
   * @param {string} userId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminUserDelete(userId: string, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminUserDelete(userId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Is used to get user data
   * @summary Get user
   * @param {string} userId subscriber id of user to return user data for
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminUserGet(userId: string, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminUserGet(userId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns list of users
   * @summary Get list of users
   * @param {number} [limit] Per page
   * @param {number} [start] Starting position, value must be a multiple of limit parameter
   * @param {string} [query] Expression to filter users in lucene format (e.g. name:Val*)
   * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminUserListGet(
    limit?: number,
    start?: number,
    query?: string,
    sortingFilter?: SortingFilterEnum,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminUserListGet(limit, start, query, sortingFilter, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Is used to get user data
   * @summary Get user
   * @param {string} userEmail user email to send reset password link
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminUserPasswordResetGet(userEmail: string, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminUserPasswordResetGet(userEmail, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Updates user
   * @summary Update user
   * @param {UserResponseModel} [userResponseModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminUserPost(userResponseModel?: UserResponseModel, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminUserPost(userResponseModel, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Is used to update user data by auth0SearchQuery
   * @summary Update User type by utility
   * @param {string} id Champ type id to add for user type
   * @param {string} auth0SearchQuery User auth0 Search Query
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminAdminUtilityUserGet(id: string, auth0SearchQuery: string, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminAdminUtilityUserGet(id, auth0SearchQuery, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns list of Champiobship Types
   * @summary Get list of Champ-types
   * @param {number} [limit]
   * @param {number} [start]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminCommonChampTypeListGet(limit?: number, start?: number, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminCommonChampTypeListGet(limit, start, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns Championship by Id
   * @summary Get Championship by Id
   * @param {number} champId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminCommonChampionshipGet(champId: number, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminCommonChampionshipGet(champId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Download info file by champ id
   * @summary Download info file by champ id
   * @param {number} champId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminCommonChampionshipInfoDownloadGet(champId: number, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminCommonChampionshipInfoDownloadGet(champId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns the list of championship years
   * @summary Get list of years for championships
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminCommonChampionshipsYearsGet(options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminCommonChampionshipsYearsGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns country by Id
   * @summary Get country by Id
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminCommonCountryGet(id: number, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminCommonCountryGet(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns list of countries
   * @summary Get list of countries
   * @param {number} [limit]
   * @param {number} [start]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminCommonCountryListGet(limit?: number, start?: number, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminCommonCountryListGet(limit, start, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns list of exemptions
   * @summary Get list of exemptions by championship Id and applicaton Id
   * @param {number} [champId]
   * @param {number} [appId]
   * @param {number} [limit]
   * @param {number} [start]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminCommonExemptionGet(
    champId?: number,
    appId?: number,
    limit?: number,
    start?: number,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminCommonExemptionGet(champId, appId, limit, start, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns USGA Footer Component
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminCommonFooterGet(options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminCommonFooterGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns USGA Global Navigation Component
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminCommonNavigationGet(options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminCommonNavigationGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Impersonation check
   * @summary Check if current user is impersonating other player
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminCommonProfileLinkImpersonationGet(options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminCommonProfileLinkImpersonationGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns list of approved qualifying sites
   * @summary Get list of approved qualifying sites by championship Id
   * @param {Array<number>} [ids] Optionally filter by ids
   * @param {number} [champId]
   * @param {number} [limit]
   * @param {number} [start]
   * @param {number} [appId]
   * @param {string} [query]
   * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
   * @param {string} [startDate] Optional start date range
   * @param {string} [endDate] Optional end date range
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminCommonQualifyingsiteGet(
    ids?: Array<number>,
    champId?: number,
    limit?: number,
    start?: number,
    appId?: number,
    query?: string,
    sortingFilter?: SortingFilterEnum,
    startDate?: string,
    endDate?: string,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminCommonQualifyingsiteGet(
        ids,
        champId,
        limit,
        start,
        appId,
        query,
        sortingFilter,
        startDate,
        endDate,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns state by Id
   * @summary Get state by Id
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminCommonStateGet(id: number, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminCommonStateGet(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns list of states
   * @summary Get list of states
   * @param {StateTypeEnum} type
   * @param {number} [limit]
   * @param {number} [start]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminCommonStateListGet(
    type: StateTypeEnum,
    limit?: number,
    start?: number,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminCommonStateListGet(type, limit, start, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Stripe webhook handler servlet
   * @param {object} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminCommonStripeWebhookPost(body?: object, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminCommonStripeWebhookPost(body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Check if email is already in use
   * @param {string} userEmail
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminCommonUserCheckGet(userEmail: string, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminCommonUserCheckGet(userEmail, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Login player
   * @param {UserLoginPlayerModel} [userLoginPlayerModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminCommonUserLoginPost(
    userLoginPlayerModel?: UserLoginPlayerModel,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminCommonUserLoginPost(userLoginPlayerModel, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * reset password endpoint
   * @summary Reset password
   * @param {string} userEmail user email to send reset password link
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminCommonUserPasswordResetGet(userEmail: string, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminCommonUserPasswordResetGet(userEmail, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create player
   * @param {UserCreatePlayerModel} [userCreatePlayerModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminCommonUserRegisterPost(
    userCreatePlayerModel?: UserCreatePlayerModel,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminCommonUserRegisterPost(userCreatePlayerModel, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns AGA by Id
   * @summary Get AGA by Id
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminOfficialAgaByIdGet(id: number, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminOfficialAgaByIdGet(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns AGA by User Id taken from token
   * @summary Get AGA by User Id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminOfficialAgaByUserIdGet(options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminOfficialAgaByUserIdGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns list of AGA
   * @summary Get list of AGA
   * @param {number} [limit]
   * @param {number} [start]
   * @param {string} [query] Optional search query to filter out AGA by names.
   * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminOfficialAgaGet(
    limit?: number,
    start?: number,
    query?: string,
    sortingFilter?: SortingFilterEnum,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminOfficialAgaGet(limit, start, query, sortingFilter, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Download list of applications
   * @summary Download withrawn list of applications for specific QS
   * @param {number} qualifyingSiteId Qualifying site ID
   * @param {string} authorization authorization token
   * @param {string} [listType]
   * @param {IdListModel} [idListModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminOfficialApplicationsDownloadQsPost(
    qualifyingSiteId: number,
    authorization: string,
    listType?: string,
    idListModel?: IdListModel,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminOfficialApplicationsDownloadQsPost(
        qualifyingSiteId,
        authorization,
        listType,
        idListModel,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns list of applications
   * @summary Get filtered list of applications
   * @param {number} year Championship year
   * @param {ApplicationFilters} filter
   * @param {number} [champId] Optional filter by championship id
   * @param {number} [limit]
   * @param {number} [start]
   * @param {string} [query] Optional full text search query
   * @param {string} [filterValue]
   * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminOfficialApplicationsGet(
    year: number,
    filter: ApplicationFilters,
    champId?: number,
    limit?: number,
    start?: number,
    query?: string,
    filterValue?: string,
    sortingFilter?: SortingFilterEnum,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminOfficialApplicationsGet(
        year,
        filter,
        champId,
        limit,
        start,
        query,
        filterValue,
        sortingFilter,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns a list of calendar cards based on assigned championship types.
   * @summary Get calendar cards based on assigned championship types.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminOfficialCalendarGet(options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminOfficialCalendarGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns list of Champiobship Types
   * @summary Get list of Champ-types
   * @param {number} [limit]
   * @param {number} [start]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminOfficialChampTypeListGet(limit?: number, start?: number, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminOfficialChampTypeListGet(limit, start, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns a boolean value based on the entry reviewed or not.
   * @summary Return whether entry is reviewed or not
   * @param {string} [query]
   * @param {number} [year]
   * @param {WorkflowTypeEnum} [champType]
   * @param {number} [champTypeId]
   * @param {Array<string>} [fields] required fields to fetch, e.g. \&quot;aaa\&quot;, \&quot;bbb.ccc.ddd\&quot;
   * @param {boolean} [active] indicates if champ is active
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminOfficialChampionshipAllEntriesReviewedGet(
    query?: string,
    year?: number,
    champType?: WorkflowTypeEnum,
    champTypeId?: number,
    fields?: Array<string>,
    active?: boolean,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminOfficialChampionshipAllEntriesReviewedGet(
        query,
        year,
        champType,
        champTypeId,
        fields,
        active,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns a list of championships based on assigned championship types.
   * @summary Get championships list based on assigned championship types.
   * @param {string} [query]
   * @param {number} [year]
   * @param {WorkflowTypeEnum} [champType]
   * @param {number} [champTypeId]
   * @param {boolean} [active] indicates if champ is active
   * @param {number} [start]
   * @param {number} [limit]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminOfficialChampionshipsGet(
    query?: string,
    year?: number,
    champType?: WorkflowTypeEnum,
    champTypeId?: number,
    active?: boolean,
    start?: number,
    limit?: number,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminOfficialChampionshipsGet(
        query,
        year,
        champType,
        champTypeId,
        active,
        start,
        limit,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns a list of championships based on assigned championship types.
   * @summary [GraphQL]Get championships list based on assigned championship types.
   * @param {string} [query]
   * @param {number} [year]
   * @param {WorkflowTypeEnum} [champType]
   * @param {number} [champTypeId]
   * @param {Array<string>} [fields] required fields to fetch, e.g. \&quot;aaa\&quot;, \&quot;bbb.ccc.ddd\&quot;
   * @param {boolean} [active] indicates if champ is active
   * @param {number} [start]
   * @param {number} [limit]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminOfficialChampionshipsGraphqlGet(
    query?: string,
    year?: number,
    champType?: WorkflowTypeEnum,
    champTypeId?: number,
    fields?: Array<string>,
    active?: boolean,
    start?: number,
    limit?: number,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminOfficialChampionshipsGraphqlGet(
        query,
        year,
        champType,
        champTypeId,
        fields,
        active,
        start,
        limit,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns list of clubs
   * @summary Get list of clubs
   * @param {number} [limit]
   * @param {number} [start]
   * @param {string} [query] Expression to filter entries (e.g. %word%)
   * @param {number} [champId]
   * @param {number} [year]
   * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
   * @param {string} [countryFilter]
   * @param {string} [stateFilter]
   * @param {string} [cityFilter]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminOfficialClubGet(
    limit?: number,
    start?: number,
    query?: string,
    champId?: number,
    year?: number,
    sortingFilter?: SortingFilterEnum,
    countryFilter?: string,
    stateFilter?: string,
    cityFilter?: string,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminOfficialClubGet(
        limit,
        start,
        query,
        champId,
        year,
        sortingFilter,
        countryFilter,
        stateFilter,
        cityFilter,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Add new club
   * @summary Add new club
   * @param {ClubAdminAddModel} [clubAdminAddModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminOfficialClubPost(clubAdminAddModel?: ClubAdminAddModel, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminOfficialClubPost(clubAdminAddModel, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Is used to get club data by its id
   * @summary Get club info by id
   * @param {number} id id of club
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminOfficialClubQueryGet(id: number, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminOfficialClubQueryGet(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Updates club
   * @summary Update club
   * @param {ClubAdminModel} [clubAdminModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminOfficialClubUpdatePost(clubAdminModel?: ClubAdminModel, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminOfficialClubUpdatePost(clubAdminModel, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns list of points of contact
   * @summary Get list of points of contact
   * @param {number} [limit]
   * @param {number} [start]
   * @param {string} [query] Expression to filter entries (e.g. %word%)
   * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminOfficialPocGet(
    limit?: number,
    start?: number,
    query?: string,
    sortingFilter?: SortingFilterEnum,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminOfficialPocGet(limit, start, query, sortingFilter, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Add new point of contact
   * @summary Add new point of contact
   * @param {PointOfContactAdminAddModel} [pointOfContactAdminAddModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminOfficialPocPost(
    pointOfContactAdminAddModel?: PointOfContactAdminAddModel,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminOfficialPocPost(pointOfContactAdminAddModel, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Is used to get poc data by its id
   * @summary Get poc info by id
   * @param {number} id id of poc
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminOfficialPocQueryGet(id: number, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminOfficialPocQueryGet(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Updates point of contact
   * @summary Update point of contact
   * @param {PointOfContactAdminEditModel} [pointOfContactAdminEditModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminOfficialPocUpdatePost(
    pointOfContactAdminEditModel?: PointOfContactAdminEditModel,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminOfficialPocUpdatePost(pointOfContactAdminEditModel, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Qualifying Site Player List
   * @param {number} qualifyingSiteId id of Qualifying Site
   * @param {QualifyingSiteReportListType} listType type of list to get
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminOfficialQualifyingSitePlayerListGet(
    qualifyingSiteId: number,
    listType: QualifyingSiteReportListType,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminOfficialQualifyingSitePlayerListGet(qualifyingSiteId, listType, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Checks whether it is possible to create a \'QualifyingSite\' with such data.
   * @summary Check if a combination of ChampId+Level+Number is still free
   * @param {number} champId
   * @param {string} level
   * @param {number} number
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminOfficialQualifyingsiteCheckGet(
    champId: number,
    level: string,
    number: number,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminOfficialQualifyingsiteCheckGet(champId, level, number, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns list of qualifying sites
   * @summary Get list of qualifying sites by championship Id
   * @param {number} champId
   * @param {number} [limit]
   * @param {number} [start]
   * @param {QualifyingSiteFilters} [filter]
   * @param {string} [filterValue]
   * @param {string} [startDate] Optional start date range
   * @param {string} [endDate] Optional end date range
   * @param {string} [query]
   * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminOfficialQualifyingsiteGet(
    champId: number,
    limit?: number,
    start?: number,
    filter?: QualifyingSiteFilters,
    filterValue?: string,
    startDate?: string,
    endDate?: string,
    query?: string,
    sortingFilter?: SortingFilterEnum,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminOfficialQualifyingsiteGet(
        champId,
        limit,
        start,
        filter,
        filterValue,
        startDate,
        endDate,
        query,
        sortingFilter,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Add new qualifying site to championship
   * @summary Add new qualifying site to championship
   * @param {QualifyingSiteAddModel} [qualifyingSiteAddModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminOfficialQualifyingsitePost(
    qualifyingSiteAddModel?: QualifyingSiteAddModel,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminOfficialQualifyingsitePost(qualifyingSiteAddModel, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Is used to get qualifying site data by its id
   * @summary Get qualifying site info by id
   * @param {number} id id of qualifying site
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminOfficialQualifyingsiteQueryGet(id: number, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminOfficialQualifyingsiteQueryGet(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Updates QS
   * @summary Update qualifying site
   * @param {QualifyingSiteEditModel} [qualifyingSiteEditModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminOfficialQualifyingsiteUpdatePost(
    qualifyingSiteEditModel?: QualifyingSiteEditModel,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminOfficialQualifyingsiteUpdatePost(qualifyingSiteEditModel, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Download report by access key
   * @summary Download report by access key
   * @param {string} id
   * @param {boolean} [inline] Optional flag, set to true in order to get inlined response.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminOfficialReportsDownloadGet(id: string, inline?: boolean, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminOfficialReportsDownloadGet(id, inline, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Is used to get saved user\'s data of specific championship
   * @summary Get player application
   * @param {number} champId Id of championship to return application data for
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminPlayerApplicationGet(champId: number, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminPlayerApplicationGet(champId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Saves or updates player application
   * @summary Save player application
   * @param {number} [appId] id of another player to form team with
   * @param {ApplicationPlayerModel} [applicationPlayerModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminPlayerApplicationPost(
    appId?: number,
    applicationPlayerModel?: ApplicationPlayerModel,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminPlayerApplicationPost(appId, applicationPlayerModel, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get application data by id
   * @param {number} appId id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminPlayerApplicationQueryGet(appId: number, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminPlayerApplicationQueryGet(appId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Is used to get simplified application data
   * @summary Get basic player application data (for four-ball)
   * @param {number} appId id of application to return application data for
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminPlayerApplicationSimplifiedGet(appId: number, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminPlayerApplicationSimplifiedGet(appId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Upload Application Files
   * @param {number} champId Id of championship we uploading data for
   * @param {UploadedFileType} fileType
   * @param {any} [file]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminPlayerApplicationUploadPost(
    champId: number,
    fileType: UploadedFileType,
    file?: any,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminPlayerApplicationUploadPost(champId, fileType, file, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Is used to get a list of applied applications.
   * @summary Returns list of apps player has applied to
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminPlayerApplicationWithdrawGet(options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminPlayerApplicationWithdrawGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Submit withdrawal request
   * @summary Submit withdrawal request
   * @param {ApplicationWithdrawRequestModel} [applicationWithdrawRequestModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminPlayerApplicationWithdrawPost(
    applicationWithdrawRequestModel?: ApplicationWithdrawRequestModel,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminPlayerApplicationWithdrawPost(applicationWithdrawRequestModel, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns list of applications for player
   * @summary Get filtered list of applications for player history
   * @param {WorkflowTypeEnum} [workflowType] Optional filter by championship type
   * @param {number} [year] Championship year
   * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
   * @param {number} [champTypeId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminPlayerApplicationsHistoryGet(
    workflowType?: WorkflowTypeEnum,
    year?: number,
    sortingFilter?: SortingFilterEnum,
    champTypeId?: number,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminPlayerApplicationsHistoryGet(
        workflowType,
        year,
        sortingFilter,
        champTypeId,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns list of ids for championships with existing applications in status not DRAFT
   * @summary Get list of paid championships
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminPlayerChampionshipsGet(options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminPlayerChampionshipsGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get status list of requested subscriptions
   * @summary Get status list of requested subscriptions
   * @param {Array<EmailSubscriptionType>} [types]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminPlayerEmailSubscriptionsGet(
    types?: Array<EmailSubscriptionType>,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminPlayerEmailSubscriptionsGet(types, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Update subscriptions
   * @summary Update subscriptions
   * @param {EmailSubscriptionsRequestModel} [emailSubscriptionsRequestModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminPlayerEmailSubscriptionsPost(
    emailSubscriptionsRequestModel?: EmailSubscriptionsRequestModel,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminPlayerEmailSubscriptionsPost(emailSubscriptionsRequestModel, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Is used to check availability of the handicap service.
   * @summary Check handicap service.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminPlayerHandicapCheckGet(options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminPlayerHandicapCheckGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get handicap player by handicap id
   * @param {string} handicapId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminPlayerHandicapGet(handicapId: string, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminPlayerHandicapGet(handicapId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns list of messages
   * @summary Get list of messages by current user Id
   * @param {number} [limit]
   * @param {number} [start]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminPlayerMessageGet(limit?: number, start?: number, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminPlayerMessageGet(limit, start, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Hide message for player
   * @summary Hide specific message for particular player
   * @param {MessageHideRequestModel} [messageHideRequestModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminPlayerMessageHidePost(
    messageHideRequestModel?: MessageHideRequestModel,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminPlayerMessageHidePost(messageHideRequestModel, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get form data to make post request to Payment processor
   * @param {number} appId Application id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminPlayerPaymentGet(appId: number, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminPlayerPaymentGet(appId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Is used to get profile data
   * @summary Get player profile
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminPlayerProfileGet(options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminPlayerProfileGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Accept request to manage profile
   * @summary Accept request to manage profile
   * @param {ProfileLinkRequestModel} [profileLinkRequestModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminPlayerProfileLinkAcceptPost(
    profileLinkRequestModel?: ProfileLinkRequestModel,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminPlayerProfileLinkAcceptPost(profileLinkRequestModel, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Send request to start impersonation session
   * @summary Start impersonation session
   * @param {ProfileLinkRequestModel} [profileLinkRequestModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminPlayerProfileLinkImpersonationPost(
    profileLinkRequestModel?: ProfileLinkRequestModel,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminPlayerProfileLinkImpersonationPost(profileLinkRequestModel, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Send request to stop impersonation session
   * @summary Stop impersonation session
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminPlayerProfileLinkImpersonationStopPost(options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminPlayerProfileLinkImpersonationStopPost(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Impersonation check
   * @summary Return impersonator profile
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminPlayerProfileLinkImpersonatorGet(options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminPlayerProfileLinkImpersonatorGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Update player profile
   * @summary Update managed profile
   * @param {ProfilePlayerResponseModel} [profilePlayerResponseModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminPlayerProfileLinkManagePost(
    profilePlayerResponseModel?: ProfilePlayerResponseModel,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminPlayerProfileLinkManagePost(profilePlayerResponseModel, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Send request to add managed profile
   * @summary Send request to add managed profile
   * @param {EmailModel} [emailModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminPlayerProfileLinkRequestPost(emailModel?: EmailModel, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminPlayerProfileLinkRequestPost(emailModel, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Updates player profile
   * @summary Save/update player profile
   * @param {ProfilePlayerRequestModel} [profilePlayerRequestModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminPlayerProfilePost(
    profilePlayerRequestModel?: ProfilePlayerRequestModel,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminPlayerProfilePost(profilePlayerRequestModel, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Accept request to manage profile
   * @summary Unlink player profile
   * @param {ProfileUnlinkRequestModel} [profileUnlinkRequestModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminPlayerProfileUnlinkPost(
    profileUnlinkRequestModel?: ProfileUnlinkRequestModel,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminPlayerProfileUnlinkPost(profileUnlinkRequestModel, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Is used to get qualifying site data by its id
   * @summary Get qualifying site info by id
   * @param {number} id id of qualifying site
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminPlayerQualifyingsiteQueryGet(id: number, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminPlayerQualifyingsiteQueryGet(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Is used to Release all Reserved spot data
   * @summary Release All Reserves a spot
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminPlayerReleaseAllReserveSpotGet(options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminPlayerReleaseAllReserveSpotGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Is used to Release Reserved spot data by its id
   * @summary Release Reserves a spot info by id
   * @param {number} id id of reserved slot
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminPlayerReleaseReserveSpotGet(id: number, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminPlayerReleaseReserveSpotGet(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Edit a reserved spot
   * @param {ReserveSpotEditModel} [reserveSpotEditModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminPlayerReserveSpotEditPost(
    reserveSpotEditModel?: ReserveSpotEditModel,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminPlayerReserveSpotEditPost(reserveSpotEditModel, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Reserves a spot
   * @param {ReserveSpotCreateModel} [reserveSpotCreateModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminPlayerReserveSpotPost(
    reserveSpotCreateModel?: ReserveSpotCreateModel,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminPlayerReserveSpotPost(reserveSpotCreateModel, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get list of upcoming events
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminPlayerUpcomingEventsGet(options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminPlayerUpcomingEventsGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary AR Request for player
   * @param {ARPlayerRequestModel} [aRPlayerRequestModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminSecureArequestPlayerPost(
    aRPlayerRequestModel?: ARPlayerRequestModel,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminSecureArequestPlayerPost(aRPlayerRequestModel, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Download list of applications
   * @summary Download filtered list of applications
   * @param {number} year Championship year
   * @param {ApplicationFilters} filter
   * @param {number} [champId] Optional filter by championship id
   * @param {number} [limit]
   * @param {number} [start]
   * @param {string} [query] Optional full text search query
   * @param {string} [filterValue]
   * @param {IdListModel} [idListModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminStaffApplicationsDownloadPost(
    year: number,
    filter: ApplicationFilters,
    champId?: number,
    limit?: number,
    start?: number,
    query?: string,
    filterValue?: string,
    idListModel?: IdListModel,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminStaffApplicationsDownloadPost(
        year,
        filter,
        champId,
        limit,
        start,
        query,
        filterValue,
        idListModel,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns list of applications
   * @summary Get filtered list of applications
   * @param {number} year Championship year
   * @param {ApplicationFilters} filter
   * @param {number} [champId] Optional filter by championship id
   * @param {number} [limit]
   * @param {number} [start]
   * @param {string} [query] Optional full text search query
   * @param {string} [disabilityTypeFilter]
   * @param {string} [filterValue]
   * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminStaffApplicationsGet(
    year: number,
    filter: ApplicationFilters,
    champId?: number,
    limit?: number,
    start?: number,
    query?: string,
    disabilityTypeFilter?: string,
    filterValue?: string,
    sortingFilter?: SortingFilterEnum,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminStaffApplicationsGet(
        year,
        filter,
        champId,
        limit,
        start,
        query,
        disabilityTypeFilter,
        filterValue,
        sortingFilter,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns list of Champiobship Types
   * @summary Get list of Champ-types
   * @param {number} [limit]
   * @param {number} [start]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminStaffChampTypeListGet(limit?: number, start?: number, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminStaffChampTypeListGet(limit, start, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Downloading reports
   * @summary Downloading ZIP file with championship reports
   * @param {number} champId
   * @param {StaffChampionshipReportsTypeEnum} reportType
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminStaffChampionshipReportDownloadGet(
    champId: number,
    reportType: StaffChampionshipReportsTypeEnum,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminStaffChampionshipReportDownloadGet(champId, reportType, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns a list of championships based on assigned championship types.
   * @summary Get championships list based on assigned championship types.
   * @param {string} [query]
   * @param {number} [year]
   * @param {WorkflowTypeEnum} [champType]
   * @param {number} [champTypeId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminStaffChampionshipsGet(
    query?: string,
    year?: number,
    champType?: WorkflowTypeEnum,
    champTypeId?: number,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminStaffChampionshipsGet(query, year, champType, champTypeId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns a list of championships based on assigned championship types.
   * @summary [GraphQL] Get championships list based on assigned championship types.
   * @param {string} [query]
   * @param {number} [year]
   * @param {WorkflowTypeEnum} [champType]
   * @param {number} [champTypeId]
   * @param {Array<string>} [fields] required fields to fetch, e.g. \&quot;aaa\&quot;, \&quot;bbb.ccc.ddd\&quot;
   * @param {boolean} [active] indicates if champ is active
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminStaffChampionshipsGraphqlGet(
    query?: string,
    year?: number,
    champType?: WorkflowTypeEnum,
    champTypeId?: number,
    fields?: Array<string>,
    active?: boolean,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminStaffChampionshipsGraphqlGet(
        query,
        year,
        champType,
        champTypeId,
        fields,
        active,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Is used to get championship data by its id
   * @summary Get championship info by id
   * @param {number} id id of championship
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminStaffChampionshipsQueryGet(id: number, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminStaffChampionshipsQueryGet(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns list of exemptions
   * @summary Get list of exemptions by championship Id
   * @param {number} champId
   * @param {number} [limit]
   * @param {number} [start]
   * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
   * @param {QualifyingLevelEnum} [filter] Optional Filter
   * @param {string} [query] Optional search query parameter
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminStaffExemptionGet(
    champId: number,
    limit?: number,
    start?: number,
    sortingFilter?: SortingFilterEnum,
    filter?: QualifyingLevelEnum,
    query?: string,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminStaffExemptionGet(champId, limit, start, sortingFilter, filter, query, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Download file by id
   * @summary Download file by id
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminStaffFileGet(id: number, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminStaffFileGet(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns Champiobship Type by id
   * @summary Get Applications Pairs by ChampId
   * @param {number} champId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminStaffPairGet(champId: number, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminStaffPairGet(champId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns list of pairs
   * @summary Get filtered list of pairs
   * @param {number} limit
   * @param {number} start
   * @param {number} playerId
   * @param {string} [query] Optional search query
   * @param {PairSortingFilterEnum} [sortingFilter] Optional Sorting Filter
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminStaffPairHistoryGet(
    limit: number,
    start: number,
    playerId: number,
    query?: string,
    sortingFilter?: PairSortingFilterEnum,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminStaffPairHistoryGet(limit, start, playerId, query, sortingFilter, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create/Update Applications Pairs
   * @param {PairListRequestModel} [pairListRequestModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminStaffPairPost(pairListRequestModel?: PairListRequestModel, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminStaffPairPost(pairListRequestModel, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns list of qualifying sites
   * @summary Get list of qualifying sites by championship Id
   * @param {number} champId
   * @param {number} [limit]
   * @param {number} [start]
   * @param {QualifyingSiteFilters} [filter]
   * @param {string} [filterValue]
   * @param {string} [startDate] Optional start date range
   * @param {string} [endDate] Optional end date range
   * @param {string} [query]
   * @param {SortingFilterEnum} [sortingFilter] Optional Sorting Filter
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminStaffQualifyingsiteGet(
    champId: number,
    limit?: number,
    start?: number,
    filter?: QualifyingSiteFilters,
    filterValue?: string,
    startDate?: string,
    endDate?: string,
    query?: string,
    sortingFilter?: SortingFilterEnum,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .binChampadminStaffQualifyingsiteGet(
        champId,
        limit,
        start,
        filter,
        filterValue,
        startDate,
        endDate,
        query,
        sortingFilter,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Download customer service report
   * @summary Red Book report
   * @param {number} champId Championship ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminStaffRedbookReportGet(champId: number, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminStaffRedbookReportGet(champId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Download report by access key
   * @summary Download report by access key
   * @param {string} id
   * @param {boolean} [inline] Optional flag, set to true in order to get inlined response.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public binChampadminStaffReportsDownloadGet(id: string, inline?: boolean, options?: any) {
    return DefaultApiFp(this.configuration)
      .binChampadminStaffReportsDownloadGet(id, inline, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns Back To Official Home Page Button Component
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public champAdminComponentsContentOfficialsBackToHomePageButtonModelJsonGet(options?: any) {
    return DefaultApiFp(this.configuration)
      .champAdminComponentsContentOfficialsBackToHomePageButtonModelJsonGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Dummy
   * @summary Dummy
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public champAdminComponentsDummyFetchModelsGet(options?: any) {
    return DefaultApiFp(this.configuration)
      .champAdminComponentsDummyFetchModelsGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Dummy
   * @summary Dummy
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public champAdminComponentsDummyLinkerGet(options?: any) {
    return DefaultApiFp(this.configuration)
      .champAdminComponentsDummyLinkerGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Dummy
   * @summary Dummy
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public champAdminComponentsDummyValidateLinkerGet(options?: any) {
    return DefaultApiFp(this.configuration)
      .champAdminComponentsDummyValidateLinkerGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Dummy
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public champAdminServiceWaitlistResponeDummyLinkerGet(options?: any) {
    return DefaultApiFp(this.configuration)
      .champAdminServiceWaitlistResponeDummyLinkerGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get list of available options for Resend Email dropdown
   * @summary Get list of available options for Resend Email dropdown
   * @param {number} appId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getBinChampadminAdminEmailResend(appId: number, options?: any) {
    return DefaultApiFp(this.configuration)
      .getBinChampadminAdminEmailResend(appId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * get-bin-champadmin-admin-lastmod
   * @summary Get last modified info
   * @param {EntitiesTypeEnum} entityName
   * @param {Array<number>} idList
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getBinChampadminAdminLastmod(
    entityName: EntitiesTypeEnum,
    idList: Array<number>,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .getBinChampadminAdminLastmod(entityName, idList, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Upload Championship PDF Files
   * @param {number} champId Id of championship we uploading data for
   * @param {UploadedPdfFileType} fileType
   * @param {any} [file]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public postBinChampadminAdminChampionshipPdfUpload(
    champId: number,
    fileType: UploadedPdfFileType,
    file?: any,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .postBinChampadminAdminChampionshipPdfUpload(champId, fileType, file, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Upload Championship Results
   * @param {number} champId Id of championship we uploading data for
   * @param {any} [file]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public postBinChampadminAdminChampionshipScoringUpload(
    champId: number,
    file?: any,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .postBinChampadminAdminChampionshipScoringUpload(champId, file, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Upload Championship Violation data
   * @param {number} champId Id of championship we uploading data for
   * @param {any} [file]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public postBinChampadminAdminChampionshipViolationUpload(
    champId: number,
    file?: any,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .postBinChampadminAdminChampionshipViolationUpload(champId, file, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Upload Final Qualifying Results (.xls files)
   * @param {number} champId Id of championship we uploading data for
   * @param {number} [qsId] Id of qualifying site we uploading data for
   * @param {boolean} [isAgaPage] Optional flag, identify when request from AGA page
   * @param {string} [scoringUrl] Optional field(required on AGA page), for QS scoringUrl
   * @param {string} [courseRating] Optional field(required on AGA page), for QS courseRating
   * @param {string} [expertCertifiedRulesName] Optional field, for QS expertCertifiedRulesName, used on AGA page
   * @param {boolean} [expertCertifiedRules] Optional field, for QS expertCertifiedRules, used on AGA page
   * @param {boolean} [strokePolicyViolations] Optional field, for QS strokePolicyViolations, used on AGA page
   * @param {any} [file]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public postBinChampadminOfficialQualifyingFinalScoringUpload(
    champId: number,
    qsId?: number,
    isAgaPage?: boolean,
    scoringUrl?: string,
    courseRating?: string,
    expertCertifiedRulesName?: string,
    expertCertifiedRules?: boolean,
    strokePolicyViolations?: boolean,
    file?: any,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .postBinChampadminOfficialQualifyingFinalScoringUpload(
        champId,
        qsId,
        isAgaPage,
        scoringUrl,
        courseRating,
        expertCertifiedRulesName,
        expertCertifiedRules,
        strokePolicyViolations,
        file,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Upload Local Qualifying Results (.xls files)
   * @param {number} champId Id of championship we uploading data for
   * @param {number} [qsId] Id of qualifying site we uploading data for
   * @param {boolean} [isAgaPage] Optional flag, identify when request from AGA page
   * @param {string} [scoringUrl] Optional field(required on AGA page), for QS scoringUrl
   * @param {string} [courseRating] Optional field(required on AGA page), for QS courseRating
   * @param {string} [expertCertifiedRulesName] Optional field, for QS expertCertifiedRulesName, used on AGA page
   * @param {boolean} [expertCertifiedRules] Optional field, for QS expertCertifiedRules, used on AGA page
   * @param {boolean} [strokePolicyViolations] Optional field, for QS strokePolicyViolations, used on AGA page
   * @param {any} [file]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public postBinChampadminOfficialQualifyingLocalScoringUpload(
    champId: number,
    qsId?: number,
    isAgaPage?: boolean,
    scoringUrl?: string,
    courseRating?: string,
    expertCertifiedRulesName?: string,
    expertCertifiedRules?: boolean,
    strokePolicyViolations?: boolean,
    file?: any,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .postBinChampadminOfficialQualifyingLocalScoringUpload(
        champId,
        qsId,
        isAgaPage,
        scoringUrl,
        courseRating,
        expertCertifiedRulesName,
        expertCertifiedRules,
        strokePolicyViolations,
        file,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Upload Qualifying Results (.xls files)
   * @param {number} champId Id of championship we uploading data for
   * @param {number} [qsId] Id of qualifying site we uploading data for
   * @param {boolean} [isAgaPage] Optional flag, identify when request from AGA page
   * @param {string} [scoringUrl] Optional field(required on AGA page), for QS scoringUrl
   * @param {string} [courseRating] Optional field(required on AGA page), for QS courseRating
   * @param {string} [expertCertifiedRulesName] Optional field, for QS expertCertifiedRulesName, used on AGA page
   * @param {boolean} [expertCertifiedRules] Optional field, for QS expertCertifiedRules, used on AGA page
   * @param {boolean} [strokePolicyViolations] Optional field, for QS strokePolicyViolations, used on AGA page
   * @param {any} [file]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public postBinChampadminOfficialQualifyingScoringUpload(
    champId: number,
    qsId?: number,
    isAgaPage?: boolean,
    scoringUrl?: string,
    courseRating?: string,
    expertCertifiedRulesName?: string,
    expertCertifiedRules?: boolean,
    strokePolicyViolations?: boolean,
    file?: any,
    options?: any
  ) {
    return DefaultApiFp(this.configuration)
      .postBinChampadminOfficialQualifyingScoringUpload(
        champId,
        qsId,
        isAgaPage,
        scoringUrl,
        courseRating,
        expertCertifiedRulesName,
        expertCertifiedRules,
        strokePolicyViolations,
        file,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * PlayerApi - axios parameter creator
 * @export
 */
export const PlayerApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Returns the list of championships
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminCommonChampionshipsGet: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/common/championships`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary [GraphQL] Returns the list of championships
     * @param {Array<string>} [fields] required fields to fetch, e.g. \&quot;aaa\&quot;, \&quot;bbb.ccc.ddd\&quot;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminCommonChampionshipsGraphqlGet: async (
      fields?: Array<string>,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/bin/champadmin/common/championships/graphql`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (fields) {
        localVarQueryParameter['fields'] = fields;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns Banner Player Application Component
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    champAdminComponentsContentApplicationBannerModelJsonGet: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/champAdmin/components/content/application/banner.model.json`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns Banner Player Home Component
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    champAdminComponentsContentHomeBannerModelJsonGet: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/champAdmin/components/content/home/banner.model.json`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns Links Block Component
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    champAdminComponentsContentHomeLinksBlockModelJsonGet: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/champAdmin/components/content/home/links-block.model.json`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns Selection-Tab Player Home Component
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    champAdminComponentsContentHomeSelectionTabModelJsonGet: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/champAdmin/components/content/home/selection-tab.model.json`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    champAdminComponentsContentHomeUpcomingEventsModelJsonGet: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/champAdmin/components/content/home/upcomingEvents.model.json`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns Officials Calendar Component
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    champAdminComponentsContentOfficialsCalendarModelJsonGet: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/champAdmin/components/content/officials/calendar.model.json`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns Officials Qualifying Site Component
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    champAdminComponentsContentOfficialsQualifyingSiteModelJsonGet: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/champAdmin/components/content/officials/qualifying-site.model.json`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns Amateur Player Appliance Component
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    champAdminComponentsContentPlayerChampionshipAdaptiveApplianceModelJsonGet: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/champAdmin/components/content/player/championship/adaptive-appliance.model.json`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns Amateur Player Appliance Component
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    champAdminComponentsContentPlayerChampionshipAmateurApplianceModelJsonGet: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/champAdmin/components/content/player/championship/amateur-appliance.model.json`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns Application Notifications Component
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    champAdminComponentsContentPlayerChampionshipAppNotificationsModelJsonGet: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/champAdmin/components/content/player/championship/app-notifications.model.json`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns Back To List Button Component
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    champAdminComponentsContentPlayerChampionshipBackToListButtonModelJsonGet: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/champAdmin/components/content/player/championship/back-to-list-button.model.json`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns Four Ball Player Appliance Component
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    champAdminComponentsContentPlayerChampionshipFourballApplianceModelJsonGet: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/champAdmin/components/content/player/championship/fourball-appliance.model.json`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns Link Button Component
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    champAdminComponentsContentPlayerChampionshipLinkButtonModelJsonGet: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/champAdmin/components/content/player/championship/link-button.model.json`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns Open Player Appliance Component
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    champAdminComponentsContentPlayerChampionshipOpenApplianceModelJsonGet: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/champAdmin/components/content/player/championship/open-appliance.model.json`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns Two Columns Component
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    champAdminComponentsContentPlayerChampionshipTwoColumnsModelJsonGet: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/champAdmin/components/content/player/championship/two-columns.model.json`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns Two Stage Open Player Appliance Component
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    champAdminComponentsContentPlayerChampionshipTwostageopenApplianceModelJsonGet: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/champAdmin/components/content/player/championship/twostageopen-appliance.model.json`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns US Amateur Player Appliance Component
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    champAdminComponentsContentPlayerChampionshipUsamateurApplianceModelJsonGet: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/champAdmin/components/content/player/championship/usamateur-appliance.model.json`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns US-Open Player Appliance Component
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    champAdminComponentsContentPlayerChampionshipUsopenApplianceModelJsonGet: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/champAdmin/components/content/player/championship/usopen-appliance.model.json`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns Link Profiles Confirmation Component
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    champAdminComponentsContentPlayerConfirmationLinkProfilesModelJsonGet: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/champAdmin/components/content/player/confirmation/link-profiles.model.json`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns Confirmation Component
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    champAdminComponentsContentPlayerConfirmationModelJsonGet: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/champAdmin/components/content/player/confirmation.model.json`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * see https://github.com/adobe/aem-core-wcm-components/tree/master/content/src/content/jcr_root/apps/core/wcm/components/download/v1/download for details
     * @summary Returns Download Component
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    champAdminComponentsContentPlayerDownloadModelJsonGet: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/champAdmin/components/content/player/download.model.json`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns Head Article Component
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    champAdminComponentsContentPlayerHeadArticleModelJsonGet: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/champAdmin/components/content/player/head-article.model.json`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns Payment Issue Component
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    champAdminComponentsContentPlayerPaymentIssuesModelJsonGet: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/champAdmin/components/content/player/payment/issues.model.json`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns Payment Component
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    champAdminComponentsContentPlayerPaymentModelJsonGet: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/champAdmin/components/content/player/payment.model.json`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns Text Component
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    champAdminComponentsContentPlayerTextModelJsonGet: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/champAdmin/components/content/player/text.model.json`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PlayerApi - functional programming interface
 * @export
 */
export const PlayerApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Returns the list of championships
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminCommonChampionshipsGet(
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventCardsPlayerHomeModel>
    > {
      const localVarAxiosArgs = await PlayerApiAxiosParamCreator(
        configuration
      ).binChampadminCommonChampionshipsGet(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary [GraphQL] Returns the list of championships
     * @param {Array<string>} [fields] required fields to fetch, e.g. \&quot;aaa\&quot;, \&quot;bbb.ccc.ddd\&quot;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async binChampadminCommonChampionshipsGraphqlGet(
      fields?: Array<string>,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await PlayerApiAxiosParamCreator(
        configuration
      ).binChampadminCommonChampionshipsGraphqlGet(fields, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Returns Banner Player Application Component
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async champAdminComponentsContentApplicationBannerModelJsonGet(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdModel>> {
      const localVarAxiosArgs = await PlayerApiAxiosParamCreator(
        configuration
      ).champAdminComponentsContentApplicationBannerModelJsonGet(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Returns Banner Player Home Component
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async champAdminComponentsContentHomeBannerModelJsonGet(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BannerPlayerHomeModel>> {
      const localVarAxiosArgs = await PlayerApiAxiosParamCreator(
        configuration
      ).champAdminComponentsContentHomeBannerModelJsonGet(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Returns Links Block Component
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async champAdminComponentsContentHomeLinksBlockModelJsonGet(
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LinksBlockPlayerHomeModel>
    > {
      const localVarAxiosArgs = await PlayerApiAxiosParamCreator(
        configuration
      ).champAdminComponentsContentHomeLinksBlockModelJsonGet(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Returns Selection-Tab Player Home Component
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async champAdminComponentsContentHomeSelectionTabModelJsonGet(
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SelectionTabPlayerHomeModel>
    > {
      const localVarAxiosArgs = await PlayerApiAxiosParamCreator(
        configuration
      ).champAdminComponentsContentHomeSelectionTabModelJsonGet(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async champAdminComponentsContentHomeUpcomingEventsModelJsonGet(
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpcomingEventsPlayerHomeModel>
    > {
      const localVarAxiosArgs = await PlayerApiAxiosParamCreator(
        configuration
      ).champAdminComponentsContentHomeUpcomingEventsModelJsonGet(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Returns Officials Calendar Component
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async champAdminComponentsContentOfficialsCalendarModelJsonGet(
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfficialsCalendarHomeModel>
    > {
      const localVarAxiosArgs = await PlayerApiAxiosParamCreator(
        configuration
      ).champAdminComponentsContentOfficialsCalendarModelJsonGet(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Returns Officials Qualifying Site Component
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async champAdminComponentsContentOfficialsQualifyingSiteModelJsonGet(
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfficialsQualifyingSiteModel>
    > {
      const localVarAxiosArgs = await PlayerApiAxiosParamCreator(
        configuration
      ).champAdminComponentsContentOfficialsQualifyingSiteModelJsonGet(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Returns Amateur Player Appliance Component
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async champAdminComponentsContentPlayerChampionshipAdaptiveApplianceModelJsonGet(
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdaptiveAppliancePlayerModel>
    > {
      const localVarAxiosArgs = await PlayerApiAxiosParamCreator(
        configuration
      ).champAdminComponentsContentPlayerChampionshipAdaptiveApplianceModelJsonGet(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Returns Amateur Player Appliance Component
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async champAdminComponentsContentPlayerChampionshipAmateurApplianceModelJsonGet(
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AmateurAppliancePlayerModel>
    > {
      const localVarAxiosArgs = await PlayerApiAxiosParamCreator(
        configuration
      ).champAdminComponentsContentPlayerChampionshipAmateurApplianceModelJsonGet(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Returns Application Notifications Component
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async champAdminComponentsContentPlayerChampionshipAppNotificationsModelJsonGet(
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationNotificationsChampModel>
    > {
      const localVarAxiosArgs = await PlayerApiAxiosParamCreator(
        configuration
      ).champAdminComponentsContentPlayerChampionshipAppNotificationsModelJsonGet(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Returns Back To List Button Component
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async champAdminComponentsContentPlayerChampionshipBackToListButtonModelJsonGet(
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BackToListPlayerHomeModel>
    > {
      const localVarAxiosArgs = await PlayerApiAxiosParamCreator(
        configuration
      ).champAdminComponentsContentPlayerChampionshipBackToListButtonModelJsonGet(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Returns Four Ball Player Appliance Component
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async champAdminComponentsContentPlayerChampionshipFourballApplianceModelJsonGet(
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FourBallAppliancePlayerModel>
    > {
      const localVarAxiosArgs = await PlayerApiAxiosParamCreator(
        configuration
      ).champAdminComponentsContentPlayerChampionshipFourballApplianceModelJsonGet(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Returns Link Button Component
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async champAdminComponentsContentPlayerChampionshipLinkButtonModelJsonGet(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LinkButtonModel>> {
      const localVarAxiosArgs = await PlayerApiAxiosParamCreator(
        configuration
      ).champAdminComponentsContentPlayerChampionshipLinkButtonModelJsonGet(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Returns Open Player Appliance Component
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async champAdminComponentsContentPlayerChampionshipOpenApplianceModelJsonGet(
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpenAppliancePlayerModel>
    > {
      const localVarAxiosArgs = await PlayerApiAxiosParamCreator(
        configuration
      ).champAdminComponentsContentPlayerChampionshipOpenApplianceModelJsonGet(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Returns Two Columns Component
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async champAdminComponentsContentPlayerChampionshipTwoColumnsModelJsonGet(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TwoColumnsLayoutModel>> {
      const localVarAxiosArgs = await PlayerApiAxiosParamCreator(
        configuration
      ).champAdminComponentsContentPlayerChampionshipTwoColumnsModelJsonGet(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Returns Two Stage Open Player Appliance Component
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async champAdminComponentsContentPlayerChampionshipTwostageopenApplianceModelJsonGet(
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TwoStageOpenAppliancePlayerModel>
    > {
      const localVarAxiosArgs = await PlayerApiAxiosParamCreator(
        configuration
      ).champAdminComponentsContentPlayerChampionshipTwostageopenApplianceModelJsonGet(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Returns US Amateur Player Appliance Component
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async champAdminComponentsContentPlayerChampionshipUsamateurApplianceModelJsonGet(
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<USAmateurAppliancePlayerModel>
    > {
      const localVarAxiosArgs = await PlayerApiAxiosParamCreator(
        configuration
      ).champAdminComponentsContentPlayerChampionshipUsamateurApplianceModelJsonGet(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Returns US-Open Player Appliance Component
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async champAdminComponentsContentPlayerChampionshipUsopenApplianceModelJsonGet(
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<USOpenAppliancePlayerModel>
    > {
      const localVarAxiosArgs = await PlayerApiAxiosParamCreator(
        configuration
      ).champAdminComponentsContentPlayerChampionshipUsopenApplianceModelJsonGet(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Returns Link Profiles Confirmation Component
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async champAdminComponentsContentPlayerConfirmationLinkProfilesModelJsonGet(
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<LinkProfilesConfirmationPlayerModel>
    > {
      const localVarAxiosArgs = await PlayerApiAxiosParamCreator(
        configuration
      ).champAdminComponentsContentPlayerConfirmationLinkProfilesModelJsonGet(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Returns Confirmation Component
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async champAdminComponentsContentPlayerConfirmationModelJsonGet(
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConfirmationPlayerModel>
    > {
      const localVarAxiosArgs = await PlayerApiAxiosParamCreator(
        configuration
      ).champAdminComponentsContentPlayerConfirmationModelJsonGet(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * see https://github.com/adobe/aem-core-wcm-components/tree/master/content/src/content/jcr_root/apps/core/wcm/components/download/v1/download for details
     * @summary Returns Download Component
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async champAdminComponentsContentPlayerDownloadModelJsonGet(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DownloadPlayerModel>> {
      const localVarAxiosArgs = await PlayerApiAxiosParamCreator(
        configuration
      ).champAdminComponentsContentPlayerDownloadModelJsonGet(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Returns Head Article Component
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async champAdminComponentsContentPlayerHeadArticleModelJsonGet(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HeadPlayerArticleModel>> {
      const localVarAxiosArgs = await PlayerApiAxiosParamCreator(
        configuration
      ).champAdminComponentsContentPlayerHeadArticleModelJsonGet(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Returns Payment Issue Component
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async champAdminComponentsContentPlayerPaymentIssuesModelJsonGet(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentIssuesModel>> {
      const localVarAxiosArgs = await PlayerApiAxiosParamCreator(
        configuration
      ).champAdminComponentsContentPlayerPaymentIssuesModelJsonGet(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Returns Payment Component
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async champAdminComponentsContentPlayerPaymentModelJsonGet(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentModel>> {
      const localVarAxiosArgs = await PlayerApiAxiosParamCreator(
        configuration
      ).champAdminComponentsContentPlayerPaymentModelJsonGet(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Returns Text Component
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async champAdminComponentsContentPlayerTextModelJsonGet(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TextPlayerModel>> {
      const localVarAxiosArgs = await PlayerApiAxiosParamCreator(
        configuration
      ).champAdminComponentsContentPlayerTextModelJsonGet(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * PlayerApi - factory interface
 * @export
 */
export const PlayerApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Returns the list of championships
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminCommonChampionshipsGet(options?: any): AxiosPromise<EventCardsPlayerHomeModel> {
      return PlayerApiFp(configuration)
        .binChampadminCommonChampionshipsGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary [GraphQL] Returns the list of championships
     * @param {Array<string>} [fields] required fields to fetch, e.g. \&quot;aaa\&quot;, \&quot;bbb.ccc.ddd\&quot;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    binChampadminCommonChampionshipsGraphqlGet(
      fields?: Array<string>,
      options?: any
    ): AxiosPromise<object> {
      return PlayerApiFp(configuration)
        .binChampadminCommonChampionshipsGraphqlGet(fields, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Returns Banner Player Application Component
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    champAdminComponentsContentApplicationBannerModelJsonGet(options?: any): AxiosPromise<IdModel> {
      return PlayerApiFp(configuration)
        .champAdminComponentsContentApplicationBannerModelJsonGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Returns Banner Player Home Component
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    champAdminComponentsContentHomeBannerModelJsonGet(
      options?: any
    ): AxiosPromise<BannerPlayerHomeModel> {
      return PlayerApiFp(configuration)
        .champAdminComponentsContentHomeBannerModelJsonGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Returns Links Block Component
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    champAdminComponentsContentHomeLinksBlockModelJsonGet(
      options?: any
    ): AxiosPromise<LinksBlockPlayerHomeModel> {
      return PlayerApiFp(configuration)
        .champAdminComponentsContentHomeLinksBlockModelJsonGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Returns Selection-Tab Player Home Component
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    champAdminComponentsContentHomeSelectionTabModelJsonGet(
      options?: any
    ): AxiosPromise<SelectionTabPlayerHomeModel> {
      return PlayerApiFp(configuration)
        .champAdminComponentsContentHomeSelectionTabModelJsonGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    champAdminComponentsContentHomeUpcomingEventsModelJsonGet(
      options?: any
    ): AxiosPromise<UpcomingEventsPlayerHomeModel> {
      return PlayerApiFp(configuration)
        .champAdminComponentsContentHomeUpcomingEventsModelJsonGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Returns Officials Calendar Component
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    champAdminComponentsContentOfficialsCalendarModelJsonGet(
      options?: any
    ): AxiosPromise<OfficialsCalendarHomeModel> {
      return PlayerApiFp(configuration)
        .champAdminComponentsContentOfficialsCalendarModelJsonGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Returns Officials Qualifying Site Component
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    champAdminComponentsContentOfficialsQualifyingSiteModelJsonGet(
      options?: any
    ): AxiosPromise<OfficialsQualifyingSiteModel> {
      return PlayerApiFp(configuration)
        .champAdminComponentsContentOfficialsQualifyingSiteModelJsonGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Returns Amateur Player Appliance Component
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    champAdminComponentsContentPlayerChampionshipAdaptiveApplianceModelJsonGet(
      options?: any
    ): AxiosPromise<AdaptiveAppliancePlayerModel> {
      return PlayerApiFp(configuration)
        .champAdminComponentsContentPlayerChampionshipAdaptiveApplianceModelJsonGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Returns Amateur Player Appliance Component
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    champAdminComponentsContentPlayerChampionshipAmateurApplianceModelJsonGet(
      options?: any
    ): AxiosPromise<AmateurAppliancePlayerModel> {
      return PlayerApiFp(configuration)
        .champAdminComponentsContentPlayerChampionshipAmateurApplianceModelJsonGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Returns Application Notifications Component
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    champAdminComponentsContentPlayerChampionshipAppNotificationsModelJsonGet(
      options?: any
    ): AxiosPromise<ApplicationNotificationsChampModel> {
      return PlayerApiFp(configuration)
        .champAdminComponentsContentPlayerChampionshipAppNotificationsModelJsonGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Returns Back To List Button Component
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    champAdminComponentsContentPlayerChampionshipBackToListButtonModelJsonGet(
      options?: any
    ): AxiosPromise<BackToListPlayerHomeModel> {
      return PlayerApiFp(configuration)
        .champAdminComponentsContentPlayerChampionshipBackToListButtonModelJsonGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Returns Four Ball Player Appliance Component
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    champAdminComponentsContentPlayerChampionshipFourballApplianceModelJsonGet(
      options?: any
    ): AxiosPromise<FourBallAppliancePlayerModel> {
      return PlayerApiFp(configuration)
        .champAdminComponentsContentPlayerChampionshipFourballApplianceModelJsonGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Returns Link Button Component
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    champAdminComponentsContentPlayerChampionshipLinkButtonModelJsonGet(
      options?: any
    ): AxiosPromise<LinkButtonModel> {
      return PlayerApiFp(configuration)
        .champAdminComponentsContentPlayerChampionshipLinkButtonModelJsonGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Returns Open Player Appliance Component
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    champAdminComponentsContentPlayerChampionshipOpenApplianceModelJsonGet(
      options?: any
    ): AxiosPromise<OpenAppliancePlayerModel> {
      return PlayerApiFp(configuration)
        .champAdminComponentsContentPlayerChampionshipOpenApplianceModelJsonGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Returns Two Columns Component
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    champAdminComponentsContentPlayerChampionshipTwoColumnsModelJsonGet(
      options?: any
    ): AxiosPromise<TwoColumnsLayoutModel> {
      return PlayerApiFp(configuration)
        .champAdminComponentsContentPlayerChampionshipTwoColumnsModelJsonGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Returns Two Stage Open Player Appliance Component
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    champAdminComponentsContentPlayerChampionshipTwostageopenApplianceModelJsonGet(
      options?: any
    ): AxiosPromise<TwoStageOpenAppliancePlayerModel> {
      return PlayerApiFp(configuration)
        .champAdminComponentsContentPlayerChampionshipTwostageopenApplianceModelJsonGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Returns US Amateur Player Appliance Component
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    champAdminComponentsContentPlayerChampionshipUsamateurApplianceModelJsonGet(
      options?: any
    ): AxiosPromise<USAmateurAppliancePlayerModel> {
      return PlayerApiFp(configuration)
        .champAdminComponentsContentPlayerChampionshipUsamateurApplianceModelJsonGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Returns US-Open Player Appliance Component
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    champAdminComponentsContentPlayerChampionshipUsopenApplianceModelJsonGet(
      options?: any
    ): AxiosPromise<USOpenAppliancePlayerModel> {
      return PlayerApiFp(configuration)
        .champAdminComponentsContentPlayerChampionshipUsopenApplianceModelJsonGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Returns Link Profiles Confirmation Component
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    champAdminComponentsContentPlayerConfirmationLinkProfilesModelJsonGet(
      options?: any
    ): AxiosPromise<LinkProfilesConfirmationPlayerModel> {
      return PlayerApiFp(configuration)
        .champAdminComponentsContentPlayerConfirmationLinkProfilesModelJsonGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Returns Confirmation Component
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    champAdminComponentsContentPlayerConfirmationModelJsonGet(
      options?: any
    ): AxiosPromise<ConfirmationPlayerModel> {
      return PlayerApiFp(configuration)
        .champAdminComponentsContentPlayerConfirmationModelJsonGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     * see https://github.com/adobe/aem-core-wcm-components/tree/master/content/src/content/jcr_root/apps/core/wcm/components/download/v1/download for details
     * @summary Returns Download Component
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    champAdminComponentsContentPlayerDownloadModelJsonGet(
      options?: any
    ): AxiosPromise<DownloadPlayerModel> {
      return PlayerApiFp(configuration)
        .champAdminComponentsContentPlayerDownloadModelJsonGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Returns Head Article Component
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    champAdminComponentsContentPlayerHeadArticleModelJsonGet(
      options?: any
    ): AxiosPromise<HeadPlayerArticleModel> {
      return PlayerApiFp(configuration)
        .champAdminComponentsContentPlayerHeadArticleModelJsonGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Returns Payment Issue Component
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    champAdminComponentsContentPlayerPaymentIssuesModelJsonGet(
      options?: any
    ): AxiosPromise<PaymentIssuesModel> {
      return PlayerApiFp(configuration)
        .champAdminComponentsContentPlayerPaymentIssuesModelJsonGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Returns Payment Component
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    champAdminComponentsContentPlayerPaymentModelJsonGet(
      options?: any
    ): AxiosPromise<PaymentModel> {
      return PlayerApiFp(configuration)
        .champAdminComponentsContentPlayerPaymentModelJsonGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Returns Text Component
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    champAdminComponentsContentPlayerTextModelJsonGet(
      options?: any
    ): AxiosPromise<TextPlayerModel> {
      return PlayerApiFp(configuration)
        .champAdminComponentsContentPlayerTextModelJsonGet(options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * PlayerApi - object-oriented interface
 * @export
 * @class PlayerApi
 * @extends {BaseAPI}
 */
export class PlayerApi extends BaseAPI {
  /**
   *
   * @summary Returns the list of championships
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlayerApi
   */
  public binChampadminCommonChampionshipsGet(options?: any) {
    return PlayerApiFp(this.configuration)
      .binChampadminCommonChampionshipsGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary [GraphQL] Returns the list of championships
   * @param {Array<string>} [fields] required fields to fetch, e.g. \&quot;aaa\&quot;, \&quot;bbb.ccc.ddd\&quot;
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlayerApi
   */
  public binChampadminCommonChampionshipsGraphqlGet(fields?: Array<string>, options?: any) {
    return PlayerApiFp(this.configuration)
      .binChampadminCommonChampionshipsGraphqlGet(fields, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns Banner Player Application Component
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlayerApi
   */
  public champAdminComponentsContentApplicationBannerModelJsonGet(options?: any) {
    return PlayerApiFp(this.configuration)
      .champAdminComponentsContentApplicationBannerModelJsonGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns Banner Player Home Component
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlayerApi
   */
  public champAdminComponentsContentHomeBannerModelJsonGet(options?: any) {
    return PlayerApiFp(this.configuration)
      .champAdminComponentsContentHomeBannerModelJsonGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns Links Block Component
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlayerApi
   */
  public champAdminComponentsContentHomeLinksBlockModelJsonGet(options?: any) {
    return PlayerApiFp(this.configuration)
      .champAdminComponentsContentHomeLinksBlockModelJsonGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns Selection-Tab Player Home Component
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlayerApi
   */
  public champAdminComponentsContentHomeSelectionTabModelJsonGet(options?: any) {
    return PlayerApiFp(this.configuration)
      .champAdminComponentsContentHomeSelectionTabModelJsonGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlayerApi
   */
  public champAdminComponentsContentHomeUpcomingEventsModelJsonGet(options?: any) {
    return PlayerApiFp(this.configuration)
      .champAdminComponentsContentHomeUpcomingEventsModelJsonGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns Officials Calendar Component
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlayerApi
   */
  public champAdminComponentsContentOfficialsCalendarModelJsonGet(options?: any) {
    return PlayerApiFp(this.configuration)
      .champAdminComponentsContentOfficialsCalendarModelJsonGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns Officials Qualifying Site Component
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlayerApi
   */
  public champAdminComponentsContentOfficialsQualifyingSiteModelJsonGet(options?: any) {
    return PlayerApiFp(this.configuration)
      .champAdminComponentsContentOfficialsQualifyingSiteModelJsonGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns Amateur Player Appliance Component
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlayerApi
   */
  public champAdminComponentsContentPlayerChampionshipAdaptiveApplianceModelJsonGet(options?: any) {
    return PlayerApiFp(this.configuration)
      .champAdminComponentsContentPlayerChampionshipAdaptiveApplianceModelJsonGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns Amateur Player Appliance Component
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlayerApi
   */
  public champAdminComponentsContentPlayerChampionshipAmateurApplianceModelJsonGet(options?: any) {
    return PlayerApiFp(this.configuration)
      .champAdminComponentsContentPlayerChampionshipAmateurApplianceModelJsonGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns Application Notifications Component
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlayerApi
   */
  public champAdminComponentsContentPlayerChampionshipAppNotificationsModelJsonGet(options?: any) {
    return PlayerApiFp(this.configuration)
      .champAdminComponentsContentPlayerChampionshipAppNotificationsModelJsonGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns Back To List Button Component
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlayerApi
   */
  public champAdminComponentsContentPlayerChampionshipBackToListButtonModelJsonGet(options?: any) {
    return PlayerApiFp(this.configuration)
      .champAdminComponentsContentPlayerChampionshipBackToListButtonModelJsonGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns Four Ball Player Appliance Component
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlayerApi
   */
  public champAdminComponentsContentPlayerChampionshipFourballApplianceModelJsonGet(options?: any) {
    return PlayerApiFp(this.configuration)
      .champAdminComponentsContentPlayerChampionshipFourballApplianceModelJsonGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns Link Button Component
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlayerApi
   */
  public champAdminComponentsContentPlayerChampionshipLinkButtonModelJsonGet(options?: any) {
    return PlayerApiFp(this.configuration)
      .champAdminComponentsContentPlayerChampionshipLinkButtonModelJsonGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns Open Player Appliance Component
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlayerApi
   */
  public champAdminComponentsContentPlayerChampionshipOpenApplianceModelJsonGet(options?: any) {
    return PlayerApiFp(this.configuration)
      .champAdminComponentsContentPlayerChampionshipOpenApplianceModelJsonGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns Two Columns Component
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlayerApi
   */
  public champAdminComponentsContentPlayerChampionshipTwoColumnsModelJsonGet(options?: any) {
    return PlayerApiFp(this.configuration)
      .champAdminComponentsContentPlayerChampionshipTwoColumnsModelJsonGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns Two Stage Open Player Appliance Component
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlayerApi
   */
  public champAdminComponentsContentPlayerChampionshipTwostageopenApplianceModelJsonGet(
    options?: any
  ) {
    return PlayerApiFp(this.configuration)
      .champAdminComponentsContentPlayerChampionshipTwostageopenApplianceModelJsonGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns US Amateur Player Appliance Component
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlayerApi
   */
  public champAdminComponentsContentPlayerChampionshipUsamateurApplianceModelJsonGet(
    options?: any
  ) {
    return PlayerApiFp(this.configuration)
      .champAdminComponentsContentPlayerChampionshipUsamateurApplianceModelJsonGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns US-Open Player Appliance Component
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlayerApi
   */
  public champAdminComponentsContentPlayerChampionshipUsopenApplianceModelJsonGet(options?: any) {
    return PlayerApiFp(this.configuration)
      .champAdminComponentsContentPlayerChampionshipUsopenApplianceModelJsonGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns Link Profiles Confirmation Component
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlayerApi
   */
  public champAdminComponentsContentPlayerConfirmationLinkProfilesModelJsonGet(options?: any) {
    return PlayerApiFp(this.configuration)
      .champAdminComponentsContentPlayerConfirmationLinkProfilesModelJsonGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns Confirmation Component
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlayerApi
   */
  public champAdminComponentsContentPlayerConfirmationModelJsonGet(options?: any) {
    return PlayerApiFp(this.configuration)
      .champAdminComponentsContentPlayerConfirmationModelJsonGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * see https://github.com/adobe/aem-core-wcm-components/tree/master/content/src/content/jcr_root/apps/core/wcm/components/download/v1/download for details
   * @summary Returns Download Component
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlayerApi
   */
  public champAdminComponentsContentPlayerDownloadModelJsonGet(options?: any) {
    return PlayerApiFp(this.configuration)
      .champAdminComponentsContentPlayerDownloadModelJsonGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns Head Article Component
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlayerApi
   */
  public champAdminComponentsContentPlayerHeadArticleModelJsonGet(options?: any) {
    return PlayerApiFp(this.configuration)
      .champAdminComponentsContentPlayerHeadArticleModelJsonGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns Payment Issue Component
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlayerApi
   */
  public champAdminComponentsContentPlayerPaymentIssuesModelJsonGet(options?: any) {
    return PlayerApiFp(this.configuration)
      .champAdminComponentsContentPlayerPaymentIssuesModelJsonGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns Payment Component
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlayerApi
   */
  public champAdminComponentsContentPlayerPaymentModelJsonGet(options?: any) {
    return PlayerApiFp(this.configuration)
      .champAdminComponentsContentPlayerPaymentModelJsonGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns Text Component
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlayerApi
   */
  public champAdminComponentsContentPlayerTextModelJsonGet(options?: any) {
    return PlayerApiFp(this.configuration)
      .champAdminComponentsContentPlayerTextModelJsonGet(options)
      .then((request) => request(this.axios, this.basePath));
  }
}
