import * as React from 'react';
import { EditorContext } from '@adobe/cq-react-editable-components';

interface IDocumentTitleProps {
  title: string;
}

export const DocumentTitle = ({
  title,
  children,
}: React.PropsWithChildren<IDocumentTitleProps>) => {
  const isInEditor = React.useContext(EditorContext);
  React.useEffect(() => {
    if (isInEditor) {
      return;
    }
    document.title = title;
  }, [isInEditor, title]);

  React.useEffect(() => {
    if (typeof window !== undefined) {
      window.onunload = () => {};
    }
  }, []);
  return <>{children}</>;
};
