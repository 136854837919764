import { ResendEmailFilterEnum } from '@usga/champadmin-api';
import { convertEnumName } from '@usga/modules';

export const mapResendEmailFilterEnum = (emailFilter: ResendEmailFilterEnum) => {
  switch (emailFilter) {
    case ResendEmailFilterEnum.CONFIRMATIONEMAIL:
      return 'Confirmation Email';
    case ResendEmailFilterEnum.FINALCONFIRMATIONEMAIL:
      return 'Final Confirmation Email';
    case ResendEmailFilterEnum.QUALIFIEDALTERNATE:
      return 'Qualifying Status for Finalist or Alternate';
    case ResendEmailFilterEnum.WITHDRAWEMAIL:
      return 'Withdraw Email';
    case ResendEmailFilterEnum.ENTRYFEEBALANCEDECLINEDPAYMENT:
      return 'Entry Fee Balance Declined Payment';
    case ResendEmailFilterEnum.TEAMCONFIRMATIONEMAIL:
      return 'Team Confirmation Email';
    case ResendEmailFilterEnum.SECONDAPPLICANTLINKEMAIL:
      return 'Second Applicant Link Email';
    case ResendEmailFilterEnum.WAITLISTCONFIRMATIONEMAIL:
      return 'Waitlist Confirmation Email';
    case ResendEmailFilterEnum.WAITLISTTEAMCONFIRMATIONEMAIL:
      return 'Waitlist Team Confirmation Email';
    case ResendEmailFilterEnum.EXEMPTWAITLISTTEAMCONFIRMATIONEMAIL:
      return 'Team Exemption + Waitlist Confirmation Email';
    case ResendEmailFilterEnum.DECLINEDPAYMENTPARTNERNOTIFICATION:
      return 'Declined Payment Partner Notification';
    case ResendEmailFilterEnum.PLAYERSURVEYEMAILFORQUALIFYING:
      return 'Player Survey Email For Qualifying';
    case ResendEmailFilterEnum.WAITLISTTOQUALIFYINGDECLINEDPAYMENTFEENOTPAID:
      return 'Waitlist to Qualifying Declined Payment Fee not Paid';
    default:
      return convertEnumName(emailFilter);
  }
};
